import { Button, Popup, Toast, LoadingFF } from "app/shared";
import { UploadedFileList } from "app/shared/forms/UploadedFileList";
import i18next from "i18next";
import { UploadTask } from "models";
import React, { Component } from "react";
import { Trans } from "react-i18next";
import { Box, Flex, Text } from "rebass";
import { checkFileMimeType, checkFileSize } from "utils/upload";
import {
  createTask,
  deleteDocument,
  submitTask,
  uploadDocuments
} from "../services";
import { UploadFileItem } from "./models";
import { FlickrUpload, LocalComputerUpload } from "./uploadProviders";

const ACCEPTED_FILE_TYPES = ["image/tiff", "image/jpeg"];
const ACCEPTED_FILE_SIZE = 20 * 1024 * 1024; // 20MB
const UPLOAD_WAIT_TIMEOUT = 1000;
const MAXIMUM_UPLOAD_FILES = 100;

interface Props {
  task?: UploadTask;
  onComplete: (task: UploadTask) => void;
  onUploadError: () => void;
}

interface State {
  disabled: boolean;
  files: UploadFileItem[];
  task?: UploadTask;
}

export class UploadContent extends Component<Props, State> {
  interval?: number;

  constructor(props: Props) {
    super(props);

    this.state = {
      disabled: false,
      files: [],
      task: props.task
    };
  }

  componentWillUnmount() {
    if (this.interval) {
      clearTimeout(this.interval);
      this.interval = undefined;
    }
  }

  private async uploadDocuments() {
    clearTimeout(this.interval); // Remove current timeout
    const queuedFiles = this.state.files.filter(
      it => it.status === "queued" && it.errors.length === 0
    );

    if (queuedFiles.length > 0) {
      try {
        // Create task (resume if already exists one)
        const task = this.state.task
          ? this.state.task
          : await createTask().then(task => {
              this.setState({ task }); // save in state in case of error
              return task;
            });

        // Upload current file
        const currentFile = queuedFiles[0];
        this.replaceFile(currentFile.id, {
          ...currentFile,
          status: "uploading"
        });
        await uploadDocuments(task.id, [currentFile.file])
          .then(task => {
            this.replaceFile(currentFile.id, {
              ...currentFile,
              documentId: task?.[0]?.id,
              status: "uploaded"
            });
            return task;
          })
          .catch(error => {
            this.replaceFile(currentFile.id, {
              ...currentFile,
              status: "canceled",
              errors: [error.message]
            });
            throw error; // throw error again so task is not submitted
          });
      } catch (error) {
        Toast.apiError(error);
      }
    }

    // Upload next document in queue
    if (queuedFiles.length > 1) {
      this.interval = setTimeout(
        () => this.uploadDocuments(),
        UPLOAD_WAIT_TIMEOUT
      );
    } else {
      Toast.success({
        title: {
          key: "documents.submitContent.uploadContent.filesUploadedToast"
        }
      });
    }
  }

  private async submitTask() {
    const { task } = this.state;
    // Upload task
    if (task) {
      this.setState({ disabled: true }, async () => {
        try {
          // Submit task
          await submitTask(task.id);

          // Go to next step
          Toast.success({
            title: {
              key:
                "documents.submitContent.uploadContent.uploadConfirmationToast"
            }
          });

          this.setState({ disabled: false }, () => {
            this.props.onComplete(task);
          });
        } catch (error) {
          Toast.apiError(error);
          this.setState({ disabled: false }, () => {
            this.props.onUploadError();
          });
        }
      });
    }
  }

  private addFiles(blobs: File[], source: string) {
    if (blobs.length <= MAXIMUM_UPLOAD_FILES) {
      const files: UploadFileItem[] = blobs.map(file => {
        const errors = [
          ...checkFileSize(file, ACCEPTED_FILE_SIZE),
          ...checkFileMimeType(file, ACCEPTED_FILE_TYPES)
        ];
        const thumbnail = URL.createObjectURL(file);

        return {
          id: thumbnail,
          status: "queued",
          file,
          thumbnail,
          source,
          errors,
          documentId: undefined
        };
      });

      // Filter out repeated files
      const updatedFiles = files.filter(
        newFile =>
          !this.state.files.find(existingFile => {
            const sameName = newFile.file.name === existingFile.file.name;
            const sameType = newFile.file.type === existingFile.file.type;
            const sameSize = newFile.file.size === existingFile.file.size;

            return sameName && sameType && sameSize;
          })
      );

      // Set and upload files
      this.setState(
        {
          files: this.state.files.concat(updatedFiles)
        },
        () => this.uploadDocuments()
      );
    } else {
      Toast.error({
        title: { key: "documents.submitContent.uploadContent.maxFilesSelected" }
      });
    }
  }

  private removeFile = (id: string, documentId?: number) => {
    const files = this.state.files.filter(it => it.id !== id);
    this.setState({ files });

    if (documentId) {
      this.onDocumentDelete(documentId);
    }
  };

  private replaceFile(id: string, file: UploadFileItem) {
    const files = this.state.files.map(it => (it.id === id ? { ...file } : it));
    this.setState({ files });
  }

  /**
   * Prevent page close when there are pending files
   */
  componentDidUpdate = () => {
    const pendingFiles = this.state.files.filter(
      it => it.status === "queued" || it.status === "uploading"
    );

    if (pendingFiles.length) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined as any;
    }
  };

  private onDocumentDelete = (documentId?: number) => {
    if (documentId) {
      this.setState({ disabled: false }, async () => {
        deleteDocument([documentId])
          .then(() => {
            Toast.success({
              title: { key: "documents.fillInformation.documentDeleteToast" }
            });
          })
          .catch(error => {
            console.log("error", error);
            Toast.error({
              title: { key: "documents.fillInformation.networkError" }
            });
          });
      });
    }
  };

  render() {
    const { task, files, disabled } = this.state;

    const uploadedFilesLength =
      files.filter(it => it.status === "uploaded").length +
      (task?.pending ?? 0);
    const pendingFilesLength = files.filter(
      it => it.status === "queued" || it.status === "uploading"
    ).length;
    const submitDisabled =
      disabled || uploadedFilesLength === 0 || pendingFilesLength > 0;

    return (
      <Flex alignItems="center" justifyContent="center" flexDirection="column">
        <Box
          width="100%"
          sx={{
            border: "1px solid #000"
          }}>
          <Box pt={3} sx={{ borderBottom: "1px solid black" }}>
            <Text
              fontSize={3}
              textAlign="center"
              mb={3}
              sx={{ textTransform: "uppercase", letterSpacing: 2 }}>
              <Trans i18nKey="documents.submitContent.uploadContent.header" />
            </Text>
            <Flex justifyContent="center" width="100%">
              <Box width="30%">
                <FlickrUpload
                  onFileDrop={files =>
                    this.addFiles(
                      files,
                      i18next.t("documents.submitContent.uploadContent.flickr")
                    )
                  }
                  disabled={disabled}
                />
              </Box>
            </Flex>
          </Box>
          {task && task.pending > 0 && (
            <Box my={3}>
              <Text fontSize={4} textAlign="center">
                {i18next.t(
                  "documents.submitContent.uploadContent.resumeUploadTask",
                  { count: task?.pending }
                )}
              </Text>
            </Box>
          )}
          <Box p={3}>
            <LocalComputerUpload
              accept={"image/jpeg,image/jpg,image/tiff"}
              onFileDrop={files =>
                this.addFiles(
                  files,
                  i18next.t(
                    "documents.submitContent.uploadContent.localComputer"
                  )
                )
              }
              disabled={disabled}
            />
          </Box>
          {files.length > 0 && (
            <Box p={3}>
              <UploadedFileList
                files={files}
                showFileRemove={file =>
                  file.status === "queued" ||
                  file.status === "canceled" ||
                  file.status === "uploaded"
                }
                onRemoveFile={this.removeFile}
              />
            </Box>
          )}
        </Box>
        <Popup
          onOpen={() => {
            this.submitTask();
          }}
          closeOnDocumentClick={false}
          closeOnEscape={false}
          hideCloseButton={true}
          disableNavigation={true}
          transparent={true}
          trigger={
            <Button
              display="block"
              variant="blue"
              width={["70%", "30%"]}
              disabled={submitDisabled}
              p={3}
              my={6}>
              <Text variant="caps">
                <Trans i18nKey="documents.submitContent.uploadContent.continue" />
              </Text>
            </Button>
          }
          heading={""}
          hideActionButtons={true}>
          <>
            <LoadingFF
              width="300px"
              m="auto"
              loadingText={true}
              showIcon={true}
            />
            <Text fontSize={3} mt={6} textAlign="center" variant="grotText">
              <Trans i18nKey="documents.submitContent.uploadContent.processingText1" />
            </Text>
            <Text fontSize={3} mt={4} textAlign="center" variant="grotText">
              <Trans i18nKey="documents.submitContent.uploadContent.processingText2" />
            </Text>
          </>
        </Popup>
      </Flex>
    );
  }
}
