import React from "react";
import AuthUserContext from "./context";
import { withFirebase, Firebase } from "../firebase/index";
import { AuthUserContextProps } from "./context";
import { AuthUser } from "models";

interface Props {
  firebase: Firebase;
}

const withAuthentication = (Component: React.ComponentType) => {
  class WithAuthentication extends React.Component<
    Props,
    AuthUserContextProps
  > {
    listener: any;

    constructor(props: Props) {
      super(props);

      const authUser = localStorage.getItem("authUser");

      this.state = {
        authUser: authUser ? JSON.parse(authUser) : undefined,
        authLoaded: false
      };
    }

    componentDidMount() {
      this.listener = this.props.firebase.auth.onAuthStateChanged(
        user => {
          if (user) {
            user.getIdTokenResult().then(idTokenResult => {
              const authRoles = idTokenResult.claims.roles
                ? idTokenResult.claims.roles[0]
                : [];
              const authUser: AuthUser = { uid: user.uid, roles: authRoles };
              localStorage.setItem("authUser", JSON.stringify(authUser));
              this.setState({ authUser, authLoaded: true });
            });
          } else {
            localStorage.removeItem("authUser");
            this.setState({ authUser: undefined, authLoaded: true });
          }
        },
        () => {
          localStorage.removeItem("authUser");
          this.setState({ authUser: undefined, authLoaded: true });
        }
      );
    }

    componentWillUnmount() {
      this.listener();
    }

    render() {
      const { authLoaded } = this.state;

      return (
        <AuthUserContext.Provider value={this.state}>
          {authLoaded && <Component {...this.props} />}
        </AuthUserContext.Provider>
      );
    }
  }

  return withFirebase(WithAuthentication);
};

export default withAuthentication;
