import React, { useState } from "react";
import { Trans } from "react-i18next";
import { Box, Flex, Text, Heading } from "rebass";
import { Graph, Point } from "app/shared/graph";
import { FormattedDate, CollapsableButton } from "app/shared";
import { RelationDocument } from "../models";
import { DocumentView } from "app/documents";
import { DocumentThumbnail } from "models";

interface Props {
  document: RelationDocument;
  graphData: Point[];
  documentsList: DocumentThumbnail[];
}

export const ContentPerformance: React.FC<Props> = ({
  document,
  graphData,
  documentsList
}) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  return (
    <Box>
      <Flex
        alignItems="center"
        onClick={() => setIsCollapsed(!isCollapsed)}
        p={2}
        sx={{
          border: "1px solid #000",
          borderTop: 0,
          cursor: "pointer"
        }}>
        <Heading mr={2}>
          <Trans i18nKey="account.insights.contentPerformance.image" />{" "}
          {document.id}
        </Heading>
        <CollapsableButton collapsed={isCollapsed} />
      </Flex>
      <Box
        height="5px"
        sx={{
          border: "1px solid #000",
          borderTop: 0
        }}
      />
      {!isCollapsed && (
        <Flex
          flexDirection={["column", "row"]}
          sx={{
            border: "1px solid #000",
            borderTop: 0
          }}>
          <Flex
            flexDirection="column"
            p={2}
            width={["100%", "30%"]}
            sx={{
              borderRight: [0, "1px solid #000"],
              borderBottom: ["1px solid #000", 0]
            }}>
            <Text fontSize={0} mb={2} variant="caps">
              <Trans i18nKey="account.insights.contentPerformance.media" />
            </Text>
            <DocumentView
              document={{
                id: document.id,
                thumbnail: document.thumbnail
              }}
              documentsList={documentsList}
              variant="flex"
              width="100%"
              height="100%"
              minHeight="300px"
            />
          </Flex>
          <Flex flexDirection="column" width={["100%", "70%"]}>
            <Flex
              flexDirection={["column", "row"]}
              sx={{
                borderBottom: "1px solid #000",
                "& > *": {
                  width: ["100%", "calc(100% / 5)"],
                  borderRight: [0, "1px solid #000"],
                  borderBottom: ["1px solid #000", 0]
                },
                "& > :last-child": {
                  borderRight: 0,
                  borderBottom: 0
                }
              }}>
              {[
                "createdAt",
                "sales",
                "earnings",
                "views",
                "likes",
                "shares"
              ].map(title => (
                <Box key={title} p={2}>
                  <Text fontSize={0} mb={2} variant="caps">
                    <Trans
                      i18nKey={`account.insights.contentPerformance.${title}`}
                    />
                  </Text>
                  <Text fontWeight="bold">
                    {title === "createdAt" ? (
                      <FormattedDate format="number" date={document[title]} />
                    ) : (
                      document[title]
                    )}
                    {title === "earnings" && "€"}
                  </Text>
                </Box>
              ))}
            </Flex>
            <Text p={2} fontSize={0} mb={2} variant="caps">
              <Trans i18nKey="account.insights.contentPerformance.salesTrends" />
            </Text>
            <Flex
              alignItems="center"
              p={2}
              sx={{
                flexGrow: 1
              }}>
              <Graph data={graphData} lineColor="#0000FF" dotActive={false} />
            </Flex>
          </Flex>
        </Flex>
      )}
    </Box>
  );
};
