import { FollowMessageButton } from "../FollowMessageButton";
import { PhotosListSearch } from "app/search/documents/PhotosListSearch";
import { getRaw } from "app/search/utils";
import { BackgroundImage, Button, LoadingFF, LocationLabel } from "app/shared";
import { DocumentThumbnail, User, UserThumbnail } from "models";
import React, { useState } from "react";
import { Box, Flex, Text } from "rebass";
import { UserRelationInfo } from "../models";
import { UserPicture } from "../UserPicture";
import { SwiperView } from "app/shared/swiperView";
import { useMediaQueryContext } from "styles/context";
import { FollowButton } from "../FollowButton";
import debounce from "lodash.debounce";

interface Props {
  user: UserThumbnail;
  info?: UserRelationInfo;
  onClick?: () => void;
  maxPhotoResults?: number;
  height?: string | string[];
  isCollapsed?: boolean;
  titleFontSize?: number | number[];
  textBottomFontSize?: number | number[];
  textButtonFontSize?: number | number[];
  customPaddingTopFollowButton?: number | string;
  customPaddingBottomFollowButton?: number | string;
  userPictureSize?: string | string[];
  largeThumbnail?: boolean;
}

export const UserView: React.FC<Props> = ({
  user,
  info,
  onClick,
  maxPhotoResults,
  height,
  isCollapsed,
  titleFontSize,
  textBottomFontSize,
  textButtonFontSize,
  customPaddingTopFollowButton,
  customPaddingBottomFollowButton,
  userPictureSize,
  largeThumbnail,
  ...rest
}) => {
  const [documentList, setDocumentList] = useState<
    DocumentThumbnail[] | undefined
  >(undefined);

  const [documentListLoaded, setDocumentListLoaded] = useState(false);

  const { isSmallMedium } = useMediaQueryContext();

  let slides: JSX.Element[];
  if (documentList) {
    slides = documentList.map((el, index) => (
      <Box width="100%" height={height}>
        <Button
          width="100%"
          height={height}
          variant="transparent"
          sx={{
            ":hover": {
              opacity: "1 !important"
            }
          }}
          href={`/users/${user.id}`}>
          <BackgroundImage
            key={documentList[index].id}
            src={
              largeThumbnail
                ? documentList[index].thumbnail.replace("small", "large")
                : documentList[index].thumbnail
            }
            width="100%"
            sx={{
              zIndex: -2
            }}
          />
        </Button>
      </Box>
    ));
  }

  return (
    <>
      <Flex flexDirection="column" {...(rest as any)}>
        <Box width={["100%"]} sx={{ border: 1 }}>
          <Flex
            width="100%"
            height={height}
            sx={{ flexGrow: 1, transition: "0.5s" }}>
            <PhotosListSearch<DocumentThumbnail>
              filters={[{ field: "uid", values: [user.id], type: "any" }]}
              mapResults={(results: any[]) =>
                results.map(result => ({
                  id: parseInt(getRaw(result, "real_id") ?? "0", 10),
                  thumbnail: getRaw(result, "thumbnail") ?? "",
                  height: parseInt(getRaw(result, "height") ?? "1", 10),
                  width: parseInt(getRaw(result, "width") ?? "1", 10)
                }))
              }
              resultsPerPage={maxPhotoResults}
              maxResults={maxPhotoResults}
              view={documents => {
                if (documentList === undefined) {
                  setDocumentList(documents);
                  debounce(() => {
                    setDocumentListLoaded(true);
                  }, 3000)();
                }
                return (
                  <>
                    {documentList && (
                      <>
                        <Flex flexDirection="column" width="100%" height="100%">
                          <Box
                            className="noCustomBullets"
                            sx={{
                              flexGrow: 1,
                              position: "relative"
                            }}
                            width="100%"
                            height="100%">
                            <SwiperView
                              height={height}
                              slides={slides}
                              initialSlide={0}
                              navigation={
                                documentList.length > 1 && !isSmallMedium
                                  ? true
                                  : false
                              }
                            />
                            <Box
                              width="100%"
                              sx={{
                                position: "absolute",
                                zIndex: -1,
                                bottom: "33%"
                              }}>
                              {!documentListLoaded && (
                                <LoadingFF showIcon={true} hideBar={true} />
                              )}
                            </Box>
                          </Box>
                        </Flex>
                      </>
                    )}
                  </>
                );
              }}
              noResultsView={
                <Flex
                  flexDirection="column"
                  width="100%"
                  height="100%"
                  sx={{ flexGrow: 1 }}>
                  <BackgroundImage sx={{ border: 0 }} height="100%" />
                </Flex>
              }
            />
          </Flex>
          <Flex flexDirection="row" width="100%" sx={{ borderTop: 1 }}>
            {user && (
              <Flex sx={{ borderRight: 1 }}>
                <Flex width="100%" height="100%" flexDirection="column">
                  <Button width="100%" height="100%" href={`/users/${user.id}`}>
                    <UserPicture
                      picture={user.picture}
                      verifiedIconSize={isCollapsed ? 24 : 20}
                      userVerified={user.status}
                      width="100%"
                      height={
                        userPictureSize ??
                        (isCollapsed ? ["70px", "75px"] : ["70px", "65px"])
                      }
                    />
                  </Button>
                  <FollowMessageButton
                    user={{ id: user.id, professionalName: user.name } as User}
                    width="100%"
                    donationIcon={true}
                    followBorderLeft={0}
                    followBorderRight={0}
                    borderBottom={0}
                    hideFollowButton={true}
                    sx={{ borderBottom: "none !important" }}
                  />
                </Flex>
              </Flex>
            )}
            <Flex flexDirection="column" width="100%" sx={{ flexGrow: 1 }}>
              <Flex width="100%" pt={2}>
                <Button width="100%" href={`/users/${user.id}`} pl={2}>
                  <Text
                    width="100%"
                    sx={{
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textTransform: "uppercase"
                    }}
                    fontSize={titleFontSize ?? [2, 2, 2, 2, 3]}
                    variant="grotText">
                    {user.name.length > 18
                      ? user.name
                          .split(" ")
                          .slice(0, 2)
                          .join(" ")
                      : user.name}
                  </Text>
                  {user && (
                    <LocationLabel
                      fontSize={textBottomFontSize ?? [0, 0, 1]}
                      pt={2}
                      variant="grotText"
                      city={user.city}
                      country={user.country}
                      hideIfNoLocation={true}
                    />
                  )}
                </Button>
              </Flex>
              <Flex alignItems="flex-end" height="100%" width="100%">
                <FollowButton
                  width="100%"
                  uid={user.id}
                  info={info}
                  pt={["", customPaddingTopFollowButton ?? "9px"]}
                  pb={["", customPaddingBottomFollowButton ?? "9px"]}
                  fontSize={textButtonFontSize}
                  sx={{
                    borderBottom: 0,
                    borderRight: 0,
                    borderLeft: 0
                  }}
                />
              </Flex>
            </Flex>
          </Flex>
        </Box>
      </Flex>
    </>
  );
};

UserView.defaultProps = {
  maxPhotoResults: 10,
  height: "225px"
};
