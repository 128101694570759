import { LocationInputSearch } from "app/account/editMyInfo/LocationInputSearch";
import { MultiSelectSearch } from "app/search/shared";
import { getRaw } from "app/search/utils";
import { Button, Input, Label, Tabs, Tooltip } from "app/shared";
import { getCommonTranslation } from "app/shared/translations/utils";
import { AuthUserContext } from "authentication/session";
import i18next from "i18next";
import { Collection, Privacy } from "models";
import React, { useEffect, useState } from "react";
import { Trans } from "react-i18next";
import { Box, BoxProps, Flex } from "rebass";
import { getCollection } from "./services";

interface Props extends BoxProps {
  onCompleted: (collection: Collection) => void;
  onCreating: (collection: Collection) => void;
  collectionCreated?: Collection;
}

export const CollectionSelect: React.FC<Props> = ({
  onCompleted,
  onCreating,
  collectionCreated,
  ...rest
}) => {
  const INITAL_COLLECTION: Collection = {
    id: -1,
    createdAt: new Date().toISOString(),
    privacy: "public",
    uid: "",
    owner: { id: "", name: "" },
    title: "",
    description: "",
    shareWithLink: false,
    city: "",
    country: undefined,
    totalDocuments: 0,
    documents: []
  };
  const [showFields, setShowFields] = useState(
    collectionCreated ? true : false
  );
  const [collection, setCollection] = useState<Collection>(
    collectionCreated ? collectionCreated : INITAL_COLLECTION
  );

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCollection({
      ...collection,
      [event.target.name]: event.target.value
    });
  };

  const handleOnPrivacyChange = (privacy: Privacy, shareWithLink?: boolean) => {
    setCollection({
      ...collection,
      privacy: privacy as Privacy,
      shareWithLink:
        shareWithLink !== undefined
          ? shareWithLink
          : (collection?.shareWithLink as boolean)
    });
  };

  const handleOnCityChange = (country: string, city?: string) => {
    setCollection({
      ...collection,
      city: city,
      country: country
    });
  };

  useEffect(() => {
    onCreating(collection);
  }, [collection]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <AuthUserContext.Consumer>
      {({ authUser }) =>
        authUser && (
          <Box {...(rest as any)}>
            <Box
              px={3}
              py={1}
              mx={[1, 0]}
              sx={{ border: "1px solid #000", borderBottom: "none" }}>
              <Flex alignItems="center">
                <Label
                  variant="grotTextCaps"
                  i18n="documents.documentEditor.collections"
                />
                <Tooltip
                  ml={1}
                  i18nKey={`documents.documentEditor.collectionsTooltip`}
                />
              </Flex>
            </Box>
            <Box
              p={[1, 1, 2, 3]}
              mb={3}
              mx={[1, 0]}
              sx={{
                border: "1px solid #000"
              }}>
              <Flex justifyContent="center" flexDirection={["column", "row"]}>
                <Flex
                  mr={[0, 4]}
                  width={["100%", "50%"]}
                  justifyContent="center"
                  alignItems="flex-end">
                  <Box width="100%">
                    <MultiSelectSearch
                      fullBorder={true}
                      filters={[
                        {
                          field: "class_name",
                          values: ["collection"],
                          type: "any"
                        },
                        { field: "uid", values: [authUser.uid], type: "any" }
                      ]}
                      resultFields={{
                        real_id: { raw: {} },
                        privacy: { raw: {} },
                        uid: { raw: {} },
                        title: { raw: {} }
                      }}
                      isMulti={false}
                      defaultValues={
                        collection.id > 0
                          ? [
                              {
                                value: `${collection.id}`,
                                label: `${collection.title}`
                              }
                            ]
                          : undefined
                      }
                      mapResults={results =>
                        results.map((result: any) => ({
                          value: getRaw(result, "real_id"),
                          label: getRaw(result, "title")
                        }))
                      }
                      onChange={options => {
                        if (options.length) {
                          const option = options[0];
                          getCollection(parseInt(option.value, 10)).then(
                            collection => {
                              const collectionChanged: Collection = collection;
                              setCollection(collectionChanged);
                              onCompleted(collectionChanged);
                              setShowFields(true);
                            }
                          );
                        }
                      }}
                    />
                  </Box>
                </Flex>
                <Flex
                  mt={[2, 0]}
                  width={["100%", "50%"]}
                  alignItems="flex-end"
                  justifyContent="center">
                  <Button
                    width="100%"
                    onClick={() => {
                      setShowFields(true);
                      setCollection(INITAL_COLLECTION);
                      onCompleted(INITAL_COLLECTION);
                    }}>
                    <Trans i18nKey="documents.documentEditor.addCollection" />
                  </Button>
                </Flex>
              </Flex>
              {showFields && (
                <>
                  <Flex alignItems="center" mt={4}>
                    <Label
                      variant="grotTextCaps"
                      fontSize={0}
                      i18n="collections.collectionEditPopup.collectionName"
                    />
                    <Tooltip
                      i18nKey="collections.collectionEditPopup.collectionNameTooltip"
                      ml={1}
                    />
                  </Flex>
                  <Input
                    name="title"
                    type="text"
                    placeholder={i18next.t(
                      "collections.collectionEditPopup.collectionNamePlaceholder"
                    )}
                    onChange={handleOnChange}
                    value={collection?.title}
                    mb={3}
                  />
                  <Flex alignItems="center">
                    <Label
                      variant="grotTextCaps"
                      fontSize={0}
                      i18n="collections.collectionEditPopup.collectionDescription"
                    />
                    <Tooltip
                      i18nKey="collections.collectionEditPopup.collectionDescriptionTooltip"
                      ml={1}
                    />
                  </Flex>
                  <Input
                    name="description"
                    type="text"
                    placeholder={i18next.t(
                      "collections.collectionEditPopup.collectionDescriptionPlaceholder"
                    )}
                    onChange={handleOnChange}
                    value={collection?.description}
                    mb={3}
                  />
                  <Flex alignItems="center">
                    <Label
                      variant="grotTextCaps"
                      fontSize={0}
                      i18n="collections.collectionEditPopup.collectionLocation"
                    />
                    <Tooltip
                      i18nKey="collections.collectionEditPopup.collectionLocationTooltip"
                      ml={1}
                    />
                  </Flex>
                  <LocationInputSearch
                    name="city"
                    value={[
                      collection?.city,
                      collection?.country &&
                        getCommonTranslation(collection.country)
                    ]
                      .filter(it => it)
                      .join(", ")}
                    placeholder={i18next.t(
                      "collections.collectionEditPopup.collectionLocationPlaceholder"
                    )}
                    onLocationChange={(city, country) => {
                      if (country?.length) {
                        handleOnCityChange(country, city);
                      }
                    }}
                    disabled={false}
                    variant={false ? "input.borderless" : undefined}
                    mb={3}
                  />
                  <Flex mx={[0, -2]}>
                    <Tabs
                      activeName={
                        collection?.privacy === "public"
                          ? "public"
                          : collection?.privacy === "private" &&
                            collection.shareWithLink === false
                          ? "private"
                          : collection?.privacy === "private" &&
                            collection.shareWithLink === true
                          ? "exclusive"
                          : collection?.privacy
                      }
                      flexBasis={["32%"]}
                      fontSize={[1, 3]}
                      sx={{
                        "&:": {
                          textTransform: "lowercase !important",
                          marginRight: -4
                        }
                      }}
                      tabs={[
                        {
                          name: "public",
                          label: i18next.t(
                            "collections.collectionEditPopup.public"
                          ),
                          icon: "Globe",
                          onClick: () => handleOnPrivacyChange("public")
                        },
                        {
                          name: "private",
                          label: i18next.t(
                            "collections.collectionEditPopup.private"
                          ),
                          icon: "Lock2",
                          onClick: () => handleOnPrivacyChange("private", false)
                        },
                        {
                          name: "exclusive",
                          label: i18next.t(
                            "collections.collectionEditPopup.exclusive"
                          ),
                          icon: "Star",
                          onClick: () => handleOnPrivacyChange("private", true)
                        }
                      ]}
                    />
                  </Flex>
                </>
              )}
            </Box>
          </Box>
        )
      }
    </AuthUserContext.Consumer>
  );
};
