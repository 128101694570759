import { UserPicture } from "app/account";
import { Button, GridLabel, TextLink } from "app/shared";
import { User } from "models";
import React from "react";
import { Trans } from "react-i18next";
import { Box, Flex, Text } from "rebass";

interface Props {
  documentId: number;
  user: User;
  description?: string;
  trackClickThrough?: () => void;
}

const truncateString = (
  str: string,
  num: number,
  documentId: number,
  onClick?: () => void
): React.ReactElement => {
  if (str.length <= num) {
    return <Text fontSize={[1, 2, 3]}>{str}</Text>;
  }
  return (
    <Text fontSize={[1, 2, 3]}>
      {str.slice(0, num)}
      <TextLink
        i18n={"documents.documentGalleryInfo.threeDots"}
        href={`/documents/${documentId}`}
        onClick={onClick}
      />
    </Text>
  );
};

export const DocumentGalleryInfo: React.FC<Props> = ({
  documentId,
  user,
  description,
  trackClickThrough
}) => (
  <Flex py={1} flexDirection="column">
    <Flex flexDirection="row" sx={{ border: 1, borderColor: "black.0" }}>
      <Box p={[0, 1, 2]} sx={{ borderRight: 1, borderColor: "black.0" }}>
        <Button
          href={`/users/${user.id}`}
          display="grid"
          sx={{ cursor: "pointer" }}>
          <UserPicture
            picture={user.picture}
            height={["48px", "56px", "72px"]}
          />
        </Button>
      </Box>
      <Flex
        py={[0, 1, 2]}
        px={[1, 2, 3]}
        flexDirection="column"
        sx={{
          borderRight: description ? 1 : 0,
          borderColor: "black.0"
        }}>
        <GridLabel i18nKey="documents.documentGalleryInfo.user" />
        <Text py={[0, 1, 2]} fontSize={[0, 1, 4, 8]} variant="grotTextCaps">
          <TextLink
            text={user.professionalName}
            href={`/users/${user.id}`}
            color="black.0"
          />
        </Text>
      </Flex>

      {description && (
        <Flex
          py={[0, 1, 2]}
          px={[1, 2, 3]}
          flexDirection="column"
          sx={{ flexGrow: 1 }}>
          <GridLabel i18nKey="documents.documentGalleryInfo.description" />
          <Text py={[0, 1, 2]} fontSize={[0, 1, 4, 6]} variant="grotTextCaps">
            {truncateString(description, 140, documentId, trackClickThrough)}
          </Text>
        </Flex>
      )}
    </Flex>

    <Button
      href={`/documents/${documentId}`}
      onClick={trackClickThrough}
      variant="link.button.black"
      display="block">
      <Text fontSize={[0, 1, 3]}>
        <Trans i18nKey="documents.documentGalleryInfo.viewDetail" />
      </Text>
    </Button>
  </Flex>
);
