import { PhotosListSearch } from "app/search/documents/PhotosListSearch";
import { getRaw } from "app/search/utils";
import { DocumentThumbnail } from "models";
import React from "react";
import { Trans } from "react-i18next";
import { Box, Text, Flex } from "rebass";
import { Button } from "app/shared";

interface Props {
  order: "recent";
  anchor?: string;
  marginTop?: number | number[];
}

const ExplorePhotosParser: React.FC<Props> = ({ order, anchor, marginTop }) => (
  <Box id={anchor} marginTop={marginTop}>
    <Flex
      alignItems="center"
      justifyContent="space-between"
      mb={3}
      pb={[2, 2, 0]}
      sx={{ borderBottom: 1, borderColor: "black.0" }}>
      <Text fontSize={[3, 8, 14]} variant="grotTextCaps" fontWeight="bold">
        <Trans i18nKey="pages.parser.explorePhotosParser.explorePhotos" />
      </Text>
      <Button
        ml={3}
        variant="link.button"
        sx={{ borderColor: "black.0" }}
        href="/search/document">
        <Trans i18nKey="pages.parser.explorePhotosParser.viewAll" />
      </Button>
    </Flex>
    <PhotosListSearch<DocumentThumbnail>
      scrollListenerEnabled={false}
      sortField={order === "recent" ? "id" : undefined}
      sortDirection={order === "recent" ? "desc" : undefined}
      resultFields={[
        { uid: { raw: {} } },
        { owner: { raw: {} } },
        { city: { raw: {} } },
        { country: { raw: {} } },
        { taken_at: { raw: {} } }
      ]}
      mapResults={(results: any[]) =>
        results.map(result => ({
          id: parseInt(getRaw(result, "real_id") ?? "0", 10),
          thumbnail: getRaw(result, "thumbnail") ?? "",
          width: parseInt(getRaw(result, "width") ?? "1", 10),
          height: parseInt(getRaw(result, "height") ?? "1", 10),
          city: getRaw(result, "city"),
          country: getRaw(result, "country"),
          takenAt: getRaw(result, "taken_at"),
          owner: {
            id: getRaw(result, "uid") ?? "",
            name: getRaw(result, "owner") ?? ""
          }
        }))
      }
      noResultsView={<></>}
    />
  </Box>
);

ExplorePhotosParser.defaultProps = {
  marginTop: 0
};

export default ExplorePhotosParser;
