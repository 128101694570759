import { InputPlaceSearch } from "app/shared";
import React from "react";
import { BoxProps } from "rebass";
import { getAddressComponentByTypes } from "app/shared/maps/utils/geocoding";

interface Props extends BoxProps {
  name: string;
  onLocationChange: (
    city?: string,
    country?: string,
    lat?: number,
    lng?: number
  ) => void;
  value?: string;
  labelI18n?: string;
  errorI18n?: string;
  placeholder?: string;
  disabled?: boolean;
  variant?: string;
  infoI18n?: string;
  labelFontSize?: string | number | string[] | number[];
}

export const LocationInputSearch: React.FC<Props> = ({
  name,
  value,
  labelI18n,
  errorI18n,
  placeholder,
  onLocationChange,
  disabled,
  variant,
  infoI18n,
  labelFontSize,
  ...rest
}) => (
  <InputPlaceSearch
    name={name}
    value={value}
    disabled={disabled}
    labelI18n={labelI18n}
    infoI18n={infoI18n}
    errorI18n={errorI18n}
    placeholder={placeholder}
    labelFontSize={labelFontSize}
    variant={variant}
    onPlacesChange={(_, places) => {
      // Extract city/country from returned places
      if (places.length) {
        const city = getAddressComponentByTypes(
          [places[0]],
          ["locality", "political"]
        )?.short_name;
        const country = getAddressComponentByTypes(
          [places[0]],
          ["country", "political"]
        )?.short_name;
        const lat = places[0].geometry.location.lat();
        const lng = places[0].geometry.location.lng();

        if (country?.length && city?.length) {
          return onLocationChange(city, country, lat, lng);
        } else if (country?.length && !city?.length) {
          return onLocationChange(undefined, country, lat, lng);
        } else if (city?.length && !country?.length) {
          return onLocationChange(city, undefined, lat, lng);
        }
      }
      // If there is no value or there is an error return undefined
      return onLocationChange("", "");
    }}
    {...(rest as any)}
  />
);
