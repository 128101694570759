import { withSearch } from "@elastic/react-search-ui";
import { Button } from "app/shared";
import React, { useState } from "react";
import { Trans } from "react-i18next";
import { Box, Text } from "rebass";
import { compose } from "recompose";
import { SearchFilter } from "../../models";

interface Props {
  clearFilters: (except: string[]) => void;
  filters: SearchFilter[];
}

/**
 * The button will save the initial filters on mount, and reset to them again
 * when the clear filters button is clicked.
 */
const ResetFiltersButtonBase: React.FC<Props> = ({ clearFilters, filters }) => {
  const [initialFilters] = useState<string[]>(
    filters?.map(filter => filter.field) ?? []
  );

  return (
    <Box sx={{ border: 1, borderTop: 0 }}>
      <Button
        width="100%"
        variant="black"
        onClick={() => clearFilters(initialFilters)}>
        <Text fontSize={[1, 2, 3]}>
          <Trans i18nKey="search.filters.buttons.resetFiltersButton" />
        </Text>
      </Button>
    </Box>
  );
};

export const ResetFiltersButton = compose<Props, any>(
  withSearch(({ clearFilters, filters }: Props) => ({
    clearFilters,
    filters
  }))
)(ResetFiltersButtonBase);
