import React from "react";
import Svg, { SvgProps } from "primitives/Svg";

const SvgTrash = ({ width, height, size, color }: SvgProps) => (
  <Svg
    width={size || width}
    height={size || height}
    viewBox="0 0 16 19"
    color={color}>
    <path d="M13.478 5.115c-.34 0-.64.29-.64.665v10.691c0 .52-.42.957-.92.957h-7.82c-.5 0-.92-.437-.92-.957V5.76c0-.353-.28-.665-.64-.665-.34 0-.64.29-.64.665v10.692c0 1.248.98 2.267 2.18 2.267h7.82c1.2 0 2.18-1.02 2.18-2.267V5.759a.607.607 0 00-.6-.644z" />
    <path d="M8.602 15.37V6.84c0-.353-.26-.665-.6-.665-.34 0-.6.291-.6.665v8.528c0 .354.26.666.6.666.34 0 .6-.291.6-.666zM6 15.37V6.84c0-.353-.26-.665-.6-.665-.34 0-.6.291-.6.665v8.528c0 .354.26.666.6.666.34 0 .6-.291.6-.666zM11.2 15.37V6.84c0-.353-.26-.665-.6-.665-.34 0-.6.291-.6.665v8.528c0 .354.26.666.6.666.34 0 .6-.291.6-.666zM14.84 2.535H1.16c-.34 0-.64.27-.64.624s.28.624.64.624h13.66c.34 0 .64-.27.64-.624a.618.618 0 00-.62-.624zM6.18 1.287h3.64c.34 0 .64-.27.64-.624A.622.622 0 009.82.04H6.18c-.34 0-.64.27-.64.624s.28.624.64.624z" />
  </Svg>
);

SvgTrash.defaultProps = {
  width: 24,
  height: 24
};
export default SvgTrash;
