import ConditionalWrapper from "app/shared/wrapper/ConditionalWrapper";
import React, { ReactNode, useState } from "react";
import { Sticky } from "react-stickup";
import { Box, Flex } from "rebass";
import { useMediaQueryContext } from "styles/context";
import Filters from "../filters";
import { FilteredResultsCounter } from "../filters/buttons/FilteredResultsCounter";

interface Props {
  bodyContent: ReactNode;
  sideContent: ReactNode;
  totalResults: number;
  bodyHeader?: ReactNode;
  bodySubHeader?: ReactNode;
  onCollapseChange?: (isCollapsed: boolean) => void;
}

export const SearchLayout: React.FC<Props> = ({
  bodyContent,
  sideContent,
  totalResults,
  bodyHeader,
  bodySubHeader,
  onCollapseChange
}) => {
  const { isSmallMedium } = useMediaQueryContext();
  const [collapsed, setCollapsed] = useState(true);
  const isCollapsed = collapsed || totalResults === 0;

  React.useEffect(() => {
    onCollapseChange?.(isCollapsed);
  }, [isCollapsed, onCollapseChange]);

  return (
    <Flex
      flexDirection={isSmallMedium ? "column" : isCollapsed ? "column" : "row"}>
      <ConditionalWrapper
        wrapper={children => (
          <Sticky style={{ zIndex: 3 }}>
            <Flex
              flexDirection="row"
              justifyContent="space-between"
              bg="white"
              pt={2}>
              {children}
            </Flex>
          </Sticky>
        )}
        condition={isCollapsed && !isSmallMedium}>
        {isSmallMedium && (
          <Sticky style={{ zIndex: 3 }}>
            <Box width="100%" pt={2} bg="white">
              {bodyHeader}
            </Box>
          </Sticky>
        )}
        <Filters
          paddingRight={isSmallMedium ? 0 : 4}
          toggleSidebar={() => setCollapsed(!isCollapsed)}
          isSidebarCollapsed={isCollapsed}
          marginTop={isCollapsed ? 0 : 2}
          disableSticky={isSmallMedium ? true : false}
          width={isSmallMedium ? "100%" : "25%"}
          isMobile={isSmallMedium}>
          <FilteredResultsCounter totalResults={totalResults} />
          <Box maxHeight="70vh" sx={{ overflow: "auto" }}>
            {sideContent}
          </Box>
        </Filters>
        {isCollapsed && !isSmallMedium && <Box width="75%">{bodyHeader}</Box>}
      </ConditionalWrapper>
      <Box width={isCollapsed || isSmallMedium ? "100%" : "70%"}>
        {!isCollapsed && !isSmallMedium && bodyHeader && (
          <Sticky style={{ zIndex: 3 }}>
            <Box bg="white" pt={2}>
              {bodyHeader}
            </Box>
          </Sticky>
        )}
        {bodySubHeader && (
          <Box mt={isSmallMedium || isCollapsed ? 3 : 7}>{bodySubHeader}</Box>
        )}
        <Box ml={[0, isCollapsed ? 0 : 0]}> {bodyContent}</Box>
      </Box>
    </Flex>
  );
};
