import React from "react";
import Svg, { SvgProps } from "primitives/Svg";

const SvgEye = ({ width, height, size, color }: SvgProps) => (
  <Svg
    width={size || width}
    height={size || height}
    viewBox="0 0 21 9"
    color={color}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.92 7.87l.053.13H20.036l-.056-.161-.467.161.467-.162v-.002l-.003-.006-.006-.019a6.978 6.978 0 00-.125-.321 14.899 14.899 0 00-2.105-3.552C16.174 1.992 13.673 0 10.017 0 6.363 0 3.861 1.993 2.296 3.937A14.9 14.9 0 00.191 7.49a9.015 9.015 0 00-.125.321l-.007.02-.002.005v.002L.522 8l-.467-.161L0 8H1.063l.053-.13a13.9 13.9 0 011.959-3.305C4.529 2.757 6.772 1 10.018 1c.102 0 .203.002.303.005C8.47 1.112 7 2.86 7 5c0 2.21 1.567 4 3.5 4S14 7.21 14 5c0-1.933-1.2-3.545-2.794-3.919 2.615.366 4.486 1.906 5.756 3.483A13.904 13.904 0 0118.92 7.87zM13 5c0 1.786-1.24 3-2.5 3S8 6.786 8 5s1.24-3 2.5-3S13 3.214 13 5z"
    />
  </Svg>
);

SvgEye.defaultProps = {
  width: 24,
  height: 24
};
export default SvgEye;
