import React from "react";
import Svg, { SvgProps } from "primitives/Svg";

const SvgMoney = ({ width, height, size, color }: SvgProps) => (
  <Svg
    width={size || width}
    height={size || height}
    viewBox="0 0 9 16"
    color={color}>
    <g clipPath="url(#Money_svg__clip0)">
      <path d="M4.954 12.283c.468-.059.809-.164 1.022-.316.374-.269.56-.733.56-1.394 0-.503-.174-.9-.524-1.192-.207-.17-.56-.345-1.058-.526v3.428zM4.109 3.12c-.516.012-.898.15-1.147.412-.25.257-.374.61-.374 1.061 0 .49.187.88.56 1.166.208.158.528.304.96.438V3.121zM4.972 16h-.863v-1.745c-1.168-.128-2.04-.38-2.615-.754C.481 12.83-.017 11.684 0 10.065h2.402c.083.736.198 1.23.346 1.481.232.392.685.646 1.36.763V8.565l-.72-.21c-1.132-.327-1.932-.783-2.4-1.368C.525 6.403.294 5.7.294 4.875c0-.544.089-1.038.267-1.482.183-.444.438-.827.764-1.149.421-.415.89-.701 1.406-.859.314-.1.773-.172 1.378-.219V0h.845v1.184c.966.076 1.725.31 2.276.7 1.002.626 1.521 1.64 1.557 3.043h-2.34c-.047-.52-.136-.9-.266-1.14-.225-.415-.634-.643-1.227-.683v3.34c1.41.48 2.36.903 2.845 1.271C8.6 8.33 9 9.194 9 10.31c0 1.473-.548 2.543-1.645 3.209-.67.409-1.465.649-2.383.719V16z" />
    </g>
    <defs>
      <clipPath id="Money_svg__clip0">
        <path d="M0 0h9v16H0z" />
      </clipPath>
    </defs>
  </Svg>
);

SvgMoney.defaultProps = {
  width: 24,
  height: 24
};
export default SvgMoney;
