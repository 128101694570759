import { Button, Input, Toast } from "app/shared";
import { Textarea } from "app/shared/input/Textarea";
import Firebase, { withFirebase } from "authentication/firebase";
import { AuthUserContext } from "authentication/session";
import i18next from "i18next";
import { Document } from "models";
import React, { SyntheticEvent, useState } from "react";
import { Trans } from "react-i18next";
import { Box, BoxProps, Flex, Text } from "rebass";
import { compose } from "recompose";
import { createProposal } from "./services";

interface PropsExternal extends BoxProps {
  document: Document;
  userId: string;
}

interface Props extends PropsExternal {
  firebase: Firebase;
}

const ProposalsFieldBase: React.FC<Props> = ({
  document,
  userId,
  firebase,
  ...rest
}) => {
  const [disabled, setDisabled] = useState(false);
  const [message, setMessage] = useState("");
  const [price, setPrice] = useState<number | undefined>();

  const submitDisabled = !price || price <= 0 || disabled;

  const handleOnPriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPrice(Number(event.target.value));
  };

  const sendMessage = async () => {
    if (userId && message.length) {
      try {
        setDisabled(true);
        await firebase.messageService.composeMessage(
          document.owner.id,
          message
        );
        setMessage("");
      } catch (error) {
        Toast.apiError(error);
        setDisabled(false);
      }
    }
  };

  const createProposalHandler = async () => {
    if (price) {
      setDisabled(true);

      const buyer = userId;
      const seller = document.owner.id;
      const license = "royaltyfree";

      createProposal(
        [document.id],
        { value: price, precision: 2, currency: "EUR" },
        buyer,
        seller,
        license
      )
        .then(() => {
          Toast.success({
            title: {
              key: "proposals.successProposal"
            }
          });
          setDisabled(false);
          sendMessage();
        })
        .catch(error => {
          Toast.apiError(error);
          setDisabled(false);
        });
    }
  };

  const limitPrice = (e: SyntheticEvent<HTMLInputElement>) => {
    e.currentTarget.value = Math.max(0, parseInt(e.currentTarget.value))
      .toString()
      .slice(0, 10);
  };

  return (
    <>
      <Box px={[2, 2, 0]} {...(rest as any)}>
        <AuthUserContext.Consumer>
          {({ authUser }) =>
            authUser && (
              <Flex flexDirection="column">
                <Box backgroundColor="black.0" sx={{ border: 1 }}>
                  <Flex>
                    <Text
                      fontSize={[3, 5, 7]}
                      color="white.0"
                      variant="grotDisplayCaps"
                      pl={[1, 2, 3]}
                      my={1}>
                      {i18next.t("proposals.title")}
                    </Text>
                  </Flex>
                </Box>
                <Flex
                  flexDirection={"column"}
                  mb={2}
                  sx={{
                    border: 1
                  }}>
                  <Box width="100%" py={3} sx={{ borderBottom: 1 }}>
                    <Text pl={[1, 2, 3]}>
                      <Trans i18nKey="proposals.description" />
                    </Text>
                  </Box>

                  <Flex flexDirection="row" width="100%">
                    <Flex width="30%" sx={{ borderRight: 1 }} py={3}>
                      <Input
                        max={9999999999}
                        width="100%"
                        name="basePrice"
                        type="number"
                        onInput={limitPrice}
                        onChange={handleOnPriceChange}
                        px={2}
                      />
                    </Flex>

                    <Flex width="70%">
                      <Textarea
                        name="message"
                        type="text"
                        labelI18n="account.editMyInfo.about.description"
                        onChange={event => {
                          setMessage(event.target.value);
                        }}
                        value={message}
                        width="100%"
                        py={2}
                      />
                    </Flex>
                  </Flex>
                </Flex>
                <Box sx={{ opacity: submitDisabled ? 0.3 : 1 }}>
                  <Button
                    width="100%"
                    variant="blue"
                    onClick={createProposalHandler}
                    disabled={submitDisabled}>
                    <Trans i18nKey="proposals.sendButton" />
                  </Button>
                </Box>
              </Flex>
            )
          }
        </AuthUserContext.Consumer>
      </Box>
    </>
  );
};

export const ProposalsField = compose<Props, PropsExternal>(withFirebase)(
  ProposalsFieldBase
);
