import { Icon } from "app/shared";
import i18next from "i18next";
import * as React from "react";
import { Flex, Text } from "rebass";

interface Props {
  iconName: string;
  iconSize?: number;
  buttonNameI18n?: string;
  disabled?: boolean;
}

//This component needs to be wrapped in a button since function components can't be given refs
//eventually refactor this to use forwardRef or useRef
export const MobileTriggerButton: React.FC<Props> = ({
  iconName,
  buttonNameI18n,
  iconSize,
  disabled
}) => (
  <Flex
    maxWidth={["56px", "80px", "170px"]}
    pt={2}
    height="60px"
    flexDirection="column"
    alignItems="center"
    justifyContent="flex-start">
    {/* When icon color works correctly, remove this "Disabled" icon name and remove the corresponding disabled icons*/}
    <Icon
      name={disabled ? iconName + "Disabled" : iconName}
      size={iconSize ?? 24}
    />
    {buttonNameI18n && (
      <Flex minHeight="18px" alignItems="center" justifyContent="center">
        <Text fontSize={["8px", 1, 3]} opacity={disabled ? 0.4 : 1}>
          {i18next.t(buttonNameI18n)}
        </Text>
      </Flex>
    )}
  </Flex>
);
