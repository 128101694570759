import React from "react";
import Svg, { SvgProps } from "primitives/Svg";

const SvgLock2 = ({ width, height, size, color }: SvgProps) => (
  <Svg
    width={size || width}
    height={size || height}
    viewBox="0 0 12 15.8"
    color={color}>
    <path d="M10.8 4.8C10.8 2.2 8.7 0 6 0S1.2 2.2 1.2 4.8H0v11h12v-11h-1.2zM6 1.6c1.8 0 3.2 1.5 3.2 3.2H2.8C2.8 3 4.2 1.6 6 1.6zm4.5 12.7h-9v-8h9v8z" />
  </Svg>
);

SvgLock2.defaultProps = {
  width: 24,
  height: 24
};
export default SvgLock2;
