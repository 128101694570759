import { CollectionView } from "app/collections";
import { DocumentView } from "app/documents";
import { Collection, DocumentThumbnail } from "models";
import React from "react";
import { BucketItem } from "./models";

interface Props {
  bucketItem: BucketItem;
  onClick?: () => void;
  showDocumentHoverInfo?: boolean;
  userInfo?: boolean;
  bucketsList: BucketItem[];
  bucketId?: number;
  bucketUid?: string;
  height?: string | string[];
  largeThumbnail?: boolean;
  showInfoBottom?: boolean;
  showManageIcons?: boolean;
  customPb?: number | number[];
  titleFontSize?: number | number[];
  textBottomFontSize?: number | number[];
  ownerNameFontSize?: number | number[];
  customMinHeight?: string | string[];
  forceShowOnHover?: boolean;
}

export const BucketItemView: React.FC<Props> = ({
  bucketItem,
  onClick,
  showDocumentHoverInfo,
  userInfo,
  bucketsList,
  bucketId,
  height,
  largeThumbnail,
  showInfoBottom,
  showManageIcons,
  customPb,
  titleFontSize,
  textBottomFontSize,
  ownerNameFontSize,
  customMinHeight,
  bucketUid,
  forceShowOnHover
}) => {
  if (bucketItem.thumbnail) {
    return (
      <DocumentView
        document={bucketItem as DocumentThumbnail}
        documentsList={bucketsList as DocumentThumbnail[]}
        showHoverInfo={showDocumentHoverInfo}
        onClick={onClick}
        width="100%"
        showUserInfo={userInfo}
        showManageIcons={showManageIcons ?? true}
        bucketId={bucketId}
        bucketUid={bucketUid}
        largeThumbnail={largeThumbnail}
        showInfoBottom={showInfoBottom}
        customPb={customPb}
        titleFontSize={titleFontSize}
        textBottomFontSize={textBottomFontSize}
        ownerNameFontSize={ownerNameFontSize}
        customMinHeight={customMinHeight}
      />
    );
  } else {
    return (
      <CollectionView
        collection={bucketItem as Collection}
        onClick={onClick}
        width="100%"
        height={height ?? "425px"}
        showUserInfo={userInfo}
        largeThumbnail={largeThumbnail}
        showInfoBottom={showInfoBottom}
        customPb={customPb}
        titleFontSize={titleFontSize}
        textBottomFontSize={textBottomFontSize}
        ownerNameFontSize={ownerNameFontSize}
        bucketId={bucketId}
        bucketUid={bucketUid}
        forceShowOnHover={forceShowOnHover}
        showManageIcons={showManageIcons ?? true}
      />
    );
  }
};
