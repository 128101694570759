import React from "react";
import Svg, { SvgProps } from "primitives/Svg";

const SvgPencil = ({ width, height, size, color }: SvgProps) => (
  <Svg
    width={size || width}
    height={size || height}
    viewBox="0 0 512 512"
    color={color}>
    <g>
      <path d="M422.953 176.019c.549-.48 1.09-.975 1.612-1.498l21.772-21.772c12.883-12.883 12.883-33.771 0-46.654l-40.434-40.434c-12.883-12.883-33.771-12.883-46.653 0l-21.772 21.772a32.839 32.839 0 00-1.498 1.613l86.973 86.973zM114.317 397.684l43 43-50.659 7.658L56 456l7.658-50.659 7.658-50.658zM349.143 125.535L118.982 355.694l-12.441-12.441 230.16-230.159-12.441-12.441-242.601 242.6 87.088 87.088L411.348 187.74z" />
    </g>
  </Svg>
);

SvgPencil.defaultProps = {
  width: 24,
  height: 24
};
export default SvgPencil;
