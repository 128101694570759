import axios from "network";
import { Bucket, Privacy, ListIntUpdate } from "models";
import { BucketInfo } from "../models";

export const getBucket = async (bucketId: number): Promise<Bucket> => {
  return axios.get(`/buckets/${bucketId}`).then(response => response.data);
};

export const createBucket = async (
  privacy: Privacy,
  title: string
): Promise<Bucket> => {
  return axios
    .post(`/buckets`, { privacy, title })
    .then(response => response.data);
};

export const updateBucket = async (
  bucketId: number,
  privacy?: Privacy,
  title?: string,
  documents?: ListIntUpdate,
  collections?: ListIntUpdate
): Promise<Bucket> => {
  return axios
    .put(`/buckets/${bucketId}`, {
      privacy,
      title,
      documents,
      collections
    })
    .then(response => response.data);
};

export const deleteBuckets = async (buckets: number[]) => {
  return axios.delete(`/buckets`, {
    data: { buckets } // axios does not support body in delete requests
  });
};

/**
 * Relations
 */
export const getBucketInfo = async (bucketId: number): Promise<BucketInfo> => {
  return axios
    .get(`/relations/buckets/${bucketId}/info`)
    .then(response => response.data);
};

export const likeBucket = async (bucketId: number) => {
  return axios.put(`/relations/buckets/${bucketId}/like`);
};

export const shareBucket = async (bucketId: number) => {
  return axios.put(`/relations/buckets/${bucketId}/share`);
};

export const unlikeBucket = async (bucketId: number) => {
  return axios.delete(`/relations/buckets/${bucketId}/like`);
};
