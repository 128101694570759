import React from "react";
import Svg, { SvgProps } from "primitives/Svg";

const SvgFullscreen = ({ width, height, size, color }: SvgProps) => (
  <Svg
    width={size || width}
    height={size || height}
    viewBox="0 0 24 24"
    color={color}>
    <path d="M4 14H3v7h7v-1H4v-6zm-1-4h1V4h6V3H3v7zm17 10h-6v1h7v-7h-1v6zM14 3v1h6v6h1V3h-7z" />
  </Svg>
);

SvgFullscreen.defaultProps = {
  width: 24,
  height: 24
};
export default SvgFullscreen;
