import {
  ErrorBoundary,
  SearchProvider,
  WithSearch
} from "@elastic/react-search-ui";
import AppSearchAPIConnector from "@elastic/search-ui-app-search-connector";
import { UsersView, UserView } from "app/account";
import MultiCheckboxFacet from "app/search/filters/facets/MultiCheckboxFacet";
import { CachedPagination, PageEmptyState } from "app/shared";
import environment from "configurations";
import i18next from "i18next";
import { ColumnItem, UserThumbnail } from "models";
import React, { PureComponent, useEffect, useState } from "react";
import { Box } from "rebass";
import { DEFAULT_RESULTS_PER_PAGE, DocumentTabs } from "../models";
import { SearchHeader, SearchLayout } from "../shared";
import { Trans } from "react-i18next";
import { mapUserResults } from "../utils/mapResults";

interface Props {
  resultsPerPage?: number;
}

interface WithSearchProps {
  wasSearched: boolean;
  trackClickThrough: (documentId: string) => void;
  results: any[];
  totalPages: number;
  totalResults: number;
  setCurrent: (page: number) => void;
  current: number;
  isLoading: boolean;
}

export class UserSearch extends PureComponent<Props, any> {
  static defaultProps = {
    resultsPerPage: DEFAULT_RESULTS_PER_PAGE
  };

  getConfig = () => {
    const { resultsPerPage } = this.props;

    return {
      apiConnector: new AppSearchAPIConnector(environment.search),
      alwaysSearchOnInitialLoad: true,
      initialState: {
        filters: [{ field: "class_name", values: ["user"], type: "any" }], // set this filter as init filter
        resultsPerPage
      },
      searchQuery: {
        result_fields: {
          real_id: { raw: {} },
          class_name: { raw: {} },
          professional_name: { raw: {} },
          country: { raw: {} },
          city: { raw: {} },
          picture: { raw: {} },
          status: { raw: {} }
        },
        disjunctiveFacets: [],
        facets: {
          city: { type: "value", size: 30 },
          country: { type: "value", size: 30 },
          skills: { type: "value", size: 30 },
          collaboration: { type: "value", size: 30 },
          status: { type: "value", size: 30 }
        }
      }
    };
  };

  render() {
    return (
      <SearchProvider config={this.getConfig()}>
        <WithSearch
          mapContextToProps={({
            wasSearched,
            trackClickThrough,
            results,
            totalPages,
            totalResults,
            setCurrent,
            current,
            isLoading
          }: WithSearchProps) => ({
            wasSearched,
            trackClickThrough,
            results,
            totalPages,
            totalResults,
            setCurrent,
            current,
            isLoading
          })}>
          {(props: WithSearchProps) => {
            return (
              <Box>
                <ErrorBoundary>
                  <SearchBodyContent {...props} />
                </ErrorBoundary>
              </Box>
            );
          }}
        </WithSearch>
      </SearchProvider>
    );
  }
}
const SearchBodyContent: React.FC<WithSearchProps> = ({
  results,
  isLoading,
  current,
  totalPages,
  setCurrent,
  totalResults,
  trackClickThrough,
  wasSearched
}) => {
  const [isCollapsed, setCollapsed] = useState(true);
  const [column, setColumn] = useState<number | undefined>(undefined);

  interface ExtendedColumnItem extends ColumnItem {
    textButtonFontSize?: number | number[];
    userPictureSize?: string | string[];
  }
  const [userHeight, setUserHeight] = useState<ExtendedColumnItem>({
    isCollapsed: "225px",
    notCollapsed: "145px",
    titleFontSize: isCollapsed ? undefined : 1,
    textBottomFontSize: isCollapsed ? undefined : 0,
    textButtonFontSize: isCollapsed ? undefined : 1,
    userPictureSize: undefined
  });

  useEffect(() => {
    if (column) {
      if (column === 1) {
        setUserHeight({
          isCollapsed: "825px",
          notCollapsed: "570px",
          titleFontSize: 6,
          textBottomFontSize: 5,
          textButtonFontSize: undefined,
          userPictureSize: "85px"
        });
      } else if (column === 2) {
        setUserHeight({
          isCollapsed: "425px",
          notCollapsed: "270px",
          titleFontSize: 5,
          textBottomFontSize: 4,
          textButtonFontSize: undefined,
          userPictureSize: "85px"
        });
      } else if (column === 4) {
        setUserHeight({
          isCollapsed: "225px",
          notCollapsed: "145px",
          titleFontSize: isCollapsed ? undefined : 1,
          textBottomFontSize: isCollapsed ? undefined : 0,
          textButtonFontSize: isCollapsed ? undefined : 1,
          userPictureSize: undefined
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [column, isCollapsed]);

  return (
    <SearchLayout
      onCollapseChange={collapsed => setCollapsed(collapsed)}
      totalResults={totalResults}
      sideContent={
        <>
          <MultiCheckboxFacet
            field="city"
            label={i18next.t("search.users.city")}
            filterType="any"
          />
          <MultiCheckboxFacet
            field="country"
            label={i18next.t("search.users.country")}
            filterType="any"
          />
          <MultiCheckboxFacet
            field="skills"
            label={i18next.t("search.users.skills")}
            filterType="any"
          />
          <MultiCheckboxFacet
            field="collaboration"
            label={i18next.t("search.users.collaboration")}
            filterType="any"
          />
        </>
      }
      bodyContent={
        <CachedPagination<UserThumbnail>
          disabled={isLoading}
          firstPageNumber={1}
          results={mapUserResults(results)}
          page={current}
          totalPages={totalPages}
          setPage={setCurrent}>
          {users =>
            users.length ? (
              <UsersView column={column}>
                {users.map(user => (
                  <UserView
                    key={user.id}
                    user={user}
                    isCollapsed={isCollapsed}
                    titleFontSize={userHeight.titleFontSize}
                    textBottomFontSize={userHeight.textBottomFontSize}
                    textButtonFontSize={userHeight.textButtonFontSize}
                    height={
                      isCollapsed
                        ? userHeight.isCollapsed
                        : ["225px", userHeight.notCollapsed]
                    }
                    userPictureSize={userHeight.userPictureSize}
                    onClick={() => trackClickThrough(user.id.toString())}
                    largeThumbnail={column === 1 ? true : undefined}
                    customPaddingTopFollowButton={
                      column === 4 && !isCollapsed ? "12px" : undefined
                    } //to follow message and donate buttons padding
                    customPaddingBottomFollowButton={
                      column === 4 && !isCollapsed ? "11px" : undefined
                    } //to follow message and donate buttons padding
                  />
                ))}
              </UsersView>
            ) : (
              <PageEmptyState
                mt={6}
                loading={!wasSearched || isLoading}
                header={<Trans i18nKey="search.shared.noResults" />}
                description={
                  <Trans i18nKey="search.shared.noResultsDescription" />
                }
              />
            )
          }
        </CachedPagination>
      }
      bodyHeader={
        <SearchHeader
          onColumnChange={column => setColumn(column)}
          tabs={DocumentTabs}
          activeTabName="user"
          hideShowHideButton={true}
        />
      }
    />
  );
};
