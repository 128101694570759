import { UploadTask, Document, ValidationError } from "models";
import { updateDocuments, publishTask } from ".";
import { UpdateDocumentsResponse } from "../submitContent/models";
import { UpdateDocumentAndLicensesValidator } from "models/UpdateDocumentAndLicensesValidator";

type UpdateDocumentsMethod = (
  documents: Document[]
) => Promise<UpdateDocumentsResponse>;
type PublishTaskMethod = (taskId: number) => Promise<void>;

export interface PerDocumentErrors {
  documentId: number;
  errors: ValidationError[];
}

export class UpdateTaskService {
  private updateDocuments: UpdateDocumentsMethod;
  private publishTask: PublishTaskMethod;

  constructor(
    updateDocuments: UpdateDocumentsMethod,
    publishTask: PublishTaskMethod
  ) {
    this.updateDocuments = updateDocuments;
    this.publishTask = publishTask;
  }

  static default(): UpdateTaskService {
    return new UpdateTaskService(updateDocuments, publishTask);
  }

  validateDocuments(documents: Document[]): PerDocumentErrors[] {
    return documents
      .map(doc => ({
        documentId: doc.id,
        errors: new UpdateDocumentAndLicensesValidator(doc).validate()
      }))
      .filter(err => err.errors.length > 0);
  }

  validateDraft(documents: Document[]) {
    return documents
      .map(doc => ({
        documentId: doc.id,
        errors: new UpdateDocumentAndLicensesValidator(doc).validateDraft()
      }))
      .filter(err => err.errors.length > 0);
  }

  async saveAllAsDraft(
    documents: Document[]
  ): Promise<UpdateDocumentsResponse> {
    return this.updateDocuments(documents);
  }

  async publishAll(task: UploadTask, documents: Document[]): Promise<void> {
    await this.updateDocuments(documents);
    await this.publishTask(task.id);
  }
}
