import React from "react";
import Svg, { SvgProps } from "primitives/Svg";

const SvgLightning = ({ width, height, size, color }: SvgProps) => (
  <Svg
    width={size || width}
    height={size || height}
    viewBox="0 0 76 152"
    color={color}>
    <path d="M75.398 59.16l-64.61 91.504c-2.077 2.954-6.608.566-5.434-2.862l19.75-57.936c.708-2.036-.797-4.179-2.92-4.179H3.096c-2.227 0-3.733-2.327-2.83-4.408L34.242 1.898C34.739.75 35.852 0 37.087 0h28.24c2.153 0 3.643 2.189 2.905 4.24L51.658 49.915c-.752 2.051.753 4.24 2.906 4.24h18.335c2.514 0 3.989 2.908 2.513 4.99" />
  </Svg>
);

SvgLightning.defaultProps = {
  width: 24,
  height: 24
};

export default SvgLightning;
