import { ShareButton } from "app/shared";
import React from "react";
import { Collection } from "models";
import { BoxProps } from "rebass";
import { shareCollection } from "../services";

interface Props extends BoxProps {
  collection: Collection;
  variant?: string;
  magazineLink?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  open?: boolean;
  hideTrigger?: boolean;
}

/**
 * TODO:
 * - Generate unique ID to send for anonymous users.
 */
export const CollectionShareButton: React.FC<Props> = ({
  collection,
  variant,
  magazineLink,
  onOpen,
  onClose,
  open,
  hideTrigger,
  ...rest
}) => {
  const onOpenPopup = () => {
    shareCollection(collection.id).catch(error =>
      console.error("Share error", error)
    );
  };

  return (
    <ShareButton
      variant={variant}
      open={open}
      hideTrigger={hideTrigger}
      onOpen={() => {
        onOpen?.();
        onOpenPopup();
      }}
      onClose={() => onClose?.()}
      path={`/${magazineLink ? "magazine" : "collections"}/${collection.id}`}
      photo={collection.documents?.[0]?.thumbnail}
      {...(rest as any)}
    />
  );
};
