// ISO 639 Codes
export type ISOLanguageCode = "en" | "pt" | "pt-BR" | "es" | "fr";

export interface Language {
  name: string;
  code: ISOLanguageCode;
}

export const SupportedLanguages: Language[] = [
  { name: "EN", code: "en" },
  { name: "PT", code: "pt" },
  { name: "BR", code: "pt-BR" },
  { name: "ES", code: "es" },
  { name: "FR", code: "fr" }
];
