import React from "react";
import Svg, { SvgProps } from "primitives/Svg";

const SvgUser2 = ({ width, height, size, color }: SvgProps) => (
  <Svg
    width={size || width}
    height={size || height}
    viewBox="0 0 85 81"
    color={color}>
    <path d="M21.587 53.78c-.1-2.675 2.008-4.948 4.67-4.948h32.287c2.661 0 4.77 2.222 4.67 4.898-.251 7.12-.703 14.241-1.406 21.311h16.42V5.959H6.573V75.09h16.47c-.653-6.968-1.105-14.089-1.456-21.31zm20.838-38.732c7.884 0 14.261 6.414 14.261 14.342 0 7.928-6.327 14.392-14.26 14.392-7.884 0-14.261-6.413-14.261-14.341 0-7.929 6.377-14.393 14.26-14.393z" />
    <path d="M.497 0v81h83.907V0H.497zm80.894 77.97H3.51V3.03H81.39v74.94z" />
  </Svg>
);

SvgUser2.defaultProps = {
  width: 24,
  height: 24
};
export default SvgUser2;
