import "react-app-polyfill/ie11";
import "react-app-polyfill/stable"; // IMPORTANT: leave polyfills as first import
import Firebase, { FirebaseContext } from "authentication/firebase";
import "i18n/i18n";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import store from "./store";
import "./styles/fonts/font.css";
import "react-toastify/dist/ReactToastify.min.css";
import { firebaseApp } from "firebase/config";

ReactDOM.render(
  <Provider store={store}>
    <FirebaseContext.Provider value={new Firebase(firebaseApp)}>
      <App />
    </FirebaseContext.Provider>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
