import { BackButton } from "app/shared";
import { ContentArea } from "layout";
import { Bucket, ColumnItem } from "models";
import React, { useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { compose } from "recompose";
import { BucketDetailsHeader } from "./BucketDetailsHeader";
import { getBucket } from "./services";
import { BucketItemView } from "app/buckets";
import { BucketItemSearch } from "app/search/buckets/BucketItemSearch";
import MasonryLayout from "../shared/masonryLayout";

interface Params {
  id: string;
}

type Props = RouteComponentProps<Params>;

const BucketDetailsBase: React.FC<Props> = props => {
  const [bucket, setBucket] = useState<Bucket>();
  const [column, setColumn] = useState<number | undefined>(undefined);
  const [showInfo, setShowInfo] = useState<boolean | undefined>(undefined);
  const [documentHeight, setDocumentHeight] = useState<ColumnItem>({
    isCollapsed: "",
    notCollapsed: "",
    titleFontSize: undefined,
    textBottomFontSize: undefined,
    ownerNameFontSize: undefined
  });

  const loadBucket = () => {
    const bucketId = parseInt(props.match.params.id, 10);

    getBucket(bucketId)
      .then(bucket => setBucket(bucket))
      .catch(err => {
        if (err.response.status === 404) {
          props.history.push("/not-found"); // redirect to not found page
        }
        throw err;
      });
  };

  useEffect(() => {
    loadBucket();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (column) {
      if (column === 1) {
        setDocumentHeight({
          isCollapsed: "825px",
          notCollapsed: "",
          titleFontSize: undefined,
          textBottomFontSize: undefined,
          ownerNameFontSize: undefined
        });
      } else if (column === 2) {
        setDocumentHeight({
          isCollapsed: "425px",
          notCollapsed: "",
          titleFontSize: undefined,
          textBottomFontSize: undefined,
          ownerNameFontSize: undefined
        });
      } else if (column === 4) {
        setDocumentHeight({
          isCollapsed: "230px",
          notCollapsed: "",
          titleFontSize: [2, 3, 3],
          textBottomFontSize: 1,
          ownerNameFontSize: 1
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [column]);

  return (
    <ContentArea mx={[2, 0]}>
      {bucket && (
        <>
          <BackButton i18n="account.profile.profileTabsButtons.buckets" />
          <BucketDetailsHeader
            bucket={bucket}
            showEditButton={true}
            onColumnChange={column => setColumn(column)}
            onShowInfoChange={showInfo => setShowInfo(showInfo)}
          />
          <BucketItemSearch
            filters={[
              {
                field: "buckets",
                values: [bucket.id.toString()],
                type: "any"
              }
            ]}
            view={bucketItems => {
              return (
                <MasonryLayout
                  columns={column ?? 2}
                  gap={4}
                  items={bucketItems.map(bucketItem => {
                    return (
                      <>
                        <BucketItemView
                          key={bucketItem.id}
                          bucketItem={bucketItem}
                          bucketsList={bucketItems.filter(
                            it => it.thumbnail !== undefined
                          )}
                          showDocumentHoverInfo={true}
                          bucketId={bucket.id}
                          bucketUid={bucket.uid}
                          height={documentHeight.isCollapsed}
                          largeThumbnail={column === 1 ? true : undefined}
                          showInfoBottom={showInfo ?? undefined}
                          userInfo={showInfo ?? undefined}
                          showManageIcons={true}
                          customPb={showInfo ? undefined : 0}
                          titleFontSize={documentHeight.titleFontSize}
                          textBottomFontSize={documentHeight.textBottomFontSize}
                          ownerNameFontSize={documentHeight.ownerNameFontSize}
                          customMinHeight={column === 4 ? "100px" : undefined}
                        />
                      </>
                    );
                  })}
                  itemDimensions={bucketItems.map(document => ({
                    height: document.height ? (document.height as number) : 1,
                    width: document.width ? (document.width as number) : 1
                  }))}
                />
              );
            }}
          />
        </>
      )}
    </ContentArea>
  );
};

export const BucketDetails = compose<Props, any>(withRouter)(BucketDetailsBase);
