import React, { useState, useEffect } from "react";
import { Box, Text, Flex } from "rebass";
import { Button, FormattedDate, Image, Toast } from "app/shared";
import { Trans } from "react-i18next";
import { withAuthorization } from "authentication/session";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { compose } from "recompose";
import { AuthUserContext } from "authentication/session";
import { getDocument } from "../services";
import { Document } from "models";
import { checkSameUser } from "authentication/utils";
import environment from "configurations";

interface Params {
  id: string;
}

type Props = RouteComponentProps<Params>;

const CopyrightBase: React.FC<Props> = ({ match }) => {
  const documentId = parseInt(match.params.id, 10);
  const [document, setDocument] = useState<Document | undefined>(undefined);
  useEffect(() => {
    getDocument(documentId)
      .then(document => setDocument(document))
      .catch(error => Toast.apiError(error));
  }, [documentId]);
  if (!document) return null;

  return (
    <AuthUserContext.Consumer>
      {({ authUser }) =>
        authUser &&
        checkSameUser(document.uid, authUser) && (
          <Box fontSize={4} mb={5}>
            <Button
              width="100%"
              variant="black"
              mb={3}
              onClick={() => window.print()}
              sx={{
                position: "fixed",
                top: 0,
                left: 0,
                "@media print": {
                  display: "none"
                }
              }}>
              <Trans i18nKey="documents.receipts.copyright.print" />
            </Button>
            <Box px={3} pt={7}>
              <Text textAlign="center" fontWeight="bold">
                <Trans i18nKey="documents.receipts.copyright.title" />
              </Text>
              <Flex justifyContent="center">
                <Image
                  mt={4}
                  src={document.thumbnail}
                  width="50%"
                  maxWidth="500px"
                />
              </Flex>
              <Box
                mt={4}
                sx={{
                  "& table": {
                    width: "100%",
                    borderCollapse: "collapse"
                  },
                  "& table, td": {
                    border: "1px solid black"
                  },
                  "& td": {
                    p: 1
                  }
                }}>
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <b>
                          <Trans i18nKey="documents.receipts.copyright.id" />
                        </b>
                      </td>
                      <td>
                        {document.shortHash} ({document.id})
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>
                          <Trans i18nKey="documents.receipts.copyright.link" />
                        </b>
                      </td>
                      <td>
                        <Button
                          href={`${environment.url}/documents/${document.id}`}>
                          {environment.url}/documents/{document.id}
                        </Button>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>
                          <Trans i18nKey="documents.receipts.copyright.photoTitle" />
                        </b>
                      </td>
                      <td>{document.translations?.[0]?.title}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>
                          <Trans i18nKey="documents.receipts.copyright.author" />
                        </b>
                      </td>
                      <td>
                        {document.owner.name} ({document.uid})
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>
                          <Trans i18nKey="documents.receipts.copyright.date" />
                        </b>
                      </td>
                      <td>
                        <FormattedDate
                          format="string"
                          date={document.createdAt}
                          showHours
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Box>
            </Box>
          </Box>
        )
      }
    </AuthUserContext.Consumer>
  );
};

export const Copyright = compose<Props, any>(
  withRouter,
  withAuthorization()
)(CopyrightBase);
