import { MultiSelectSearch } from "app/search/shared";
import { getRaw } from "app/search/utils";
import {
  Button,
  Input,
  MobileTriggerButton,
  Popup,
  Tabs,
  Toast,
  Icon
} from "app/shared";
import { AuthUserContext } from "authentication/session";
import i18next from "i18next";
import debounce from "lodash.debounce";
import { Bucket, Privacy } from "models";
import React, { Component } from "react";
import { Trans } from "react-i18next";
import { Box, BoxProps, Text } from "rebass";
import { createBucket, updateBucket } from "./services";

interface BucketItemAdd {
  type: "document" | "collection";
  id: number;
}

interface Props extends BoxProps {
  bucketItemIds: BucketItemAdd[];
  open?: boolean;
  variant?: string;
  forcePageReload?: boolean; // this will force page reload so that ES cached results are updated
  hideTrigger?: boolean;
  onClose?: () => void;
  onAdd?: (added: boolean) => void;
}

interface State {
  mode: string;
  disabled: boolean;
  bucketId?: number;
  existingBucketTitle: string;
  newBucket: Bucket;
  isAdded: boolean;
}

export class BucketAddItemsButton extends Component<Props, State> {
  static defaultProps = {
    variant: "text",
    forcePageReload: false
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      mode: "existing",
      disabled: false,
      bucketId: undefined,
      existingBucketTitle: "",
      isAdded: false,
      newBucket: {
        id: -1,
        createdAt: new Date().toISOString(),
        privacy: "public",
        uid: "",
        owner: { id: "", name: "" },
        title: "",
        totalDocuments: 0,
        totalCollections: 0,
        totalItems: 0,
        documents: []
      }
    };
  }

  addExistingBucket(bucketId: number, bucketTitle: string, close: () => void) {
    const { forcePageReload, bucketItemIds, onAdd } = this.props;
    const { mode } = this.state;

    this.setState({ disabled: true }, async () => {
      updateBucket(
        bucketId,
        undefined,
        undefined,
        {
          mode: "append",
          list: bucketItemIds
            .filter(it => it.type === "document")
            .map(it => it.id)
        },
        {
          mode: "append",
          list: bucketItemIds
            .filter(it => it.type === "collection")
            .map(it => it.id)
        }
      )
        .then(() => {
          if (mode === "existing") {
            Toast.success({
              title: {
                key: "buckets.bucketAddPhotoButton.confirmationToast",
                options: {
                  number: bucketItemIds.length,
                  title: bucketTitle
                }
              }
            });
          } else {
            Toast.success({
              title: {
                key: "buckets.bucketAddPhotoButton.confirmationToastCreated",
                options: {
                  number: bucketItemIds.length,
                  title: bucketTitle
                }
              }
            });
          }
          close();
          this.setState({ disabled: false, isAdded: true });
          onAdd?.(true);
          if (forcePageReload) {
            // Waiting a few for backend re-index changes.
            // TODO: This can be replaced if we manually change affected elements locally.
            debounce(() => window.location.reload(), 1000)();
          }
        })
        .catch(error => {
          Toast.apiError(error);
          this.setState({ disabled: false });
        });
    });
  }

  createBucket(close: () => void) {
    const { title, privacy } = this.state.newBucket;

    if (title && title.length > 0) {
      this.setState({ disabled: true }, async () => {
        createBucket(privacy, title)
          .then(bucket => this.addExistingBucket(bucket.id, title, close))
          .then(() => debounce(() => window.location.reload(), 2000)())
          .catch(error => {
            Toast.apiError(error);
            this.setState({ disabled: false });
          });
      });
    }
  }

  handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { newBucket } = this.state;

    this.setState({
      newBucket: {
        ...newBucket,
        [event.target.name]: event.target.value
      }
    });
  };

  handleOnPrivacyChange = (privacy: Privacy) => {
    const { newBucket } = this.state;

    this.setState({
      newBucket: {
        ...newBucket,
        privacy: privacy
      }
    });
  };

  render() {
    const {
      disabled,
      bucketId,
      existingBucketTitle,
      mode,
      newBucket,
      isAdded
    } = this.state;

    const {
      bucketItemIds,
      open,
      variant,
      hideTrigger,
      onClose,
      ...rest
    } = this.props;
    const buttonEnabled = bucketItemIds.length !== 0;
    const submitEnabled =
      mode === "existing"
        ? bucketId !== undefined
        : newBucket.title && newBucket.title.length > 0;

    return (
      <AuthUserContext.Consumer>
        {({ authUser }) => (
          <Popup
            hideOverflow={true}
            onClose={() => onClose?.()}
            trigger={
              variant === "mobile" ? (
                <Button variant="buttons.mobileButton">
                  <MobileTriggerButton
                    iconName="AddTo"
                    disabled={disabled || !buttonEnabled}
                    buttonNameI18n="buckets.bucketAddPhotoButton.addToBucket"
                  />
                </Button>
              ) : !hideTrigger ? (
                <Button
                  variant={variant === "icon" ? "transparent" : "white"}
                  sx={{
                    "& svg path": {
                      fill: isAdded ? "" : "blue"
                    }
                  }}
                  disabled={disabled || !buttonEnabled}
                  {...(rest as any)}>
                  {variant === "icon" && (
                    <Icon
                      name={
                        isAdded ? "AddToCollectionFilled" : "AddToCollection"
                      }
                      size={22}
                      color="blue"
                    />
                  )}
                  {variant === "text" && (
                    <Text fontSize={[1, 2, 3]}>
                      <Trans i18nKey="buckets.bucketAddPhotoButton.addToBucket" />
                    </Text>
                  )}
                </Button>
              ) : (
                <></>
              )
            }
            heading={i18next.t("buckets.bucketAddPhotoButton.chooseBucket")}
            open={open}
            cancelDisabled={disabled}
            submit={close => {
              if (mode === "existing") {
                this.addExistingBucket(bucketId!, existingBucketTitle, close);
              } else {
                this.createBucket(close);
              }
            }}
            submitText={
              mode === "existing"
                ? undefined
                : i18next.t("buckets.bucketAddPhotoButton.create")
            }
            submitDisabled={disabled || !submitEnabled}
            disabled={!buttonEnabled || !authUser}>
            <>
              <Tabs
                mb={2}
                activeName={mode}
                tabs={[
                  {
                    name: "existing",
                    label: i18next.t("buckets.bucketAddPhotoButton.existing"),
                    onClick: () => this.setState({ mode: "existing" })
                  },
                  {
                    name: "create",
                    label: i18next.t(
                      "buckets.bucketAddPhotoButton.createBucket"
                    ),
                    onClick: () => this.setState({ mode: "create" })
                  }
                ]}
              />
              {mode === "existing" && authUser && (
                <MultiSelectSearch
                  filters={[
                    {
                      field: "class_name",
                      values: ["bucket"],
                      type: "any"
                    },
                    { field: "uid", values: [authUser.uid], type: "any" }
                  ]}
                  isMulti={false}
                  resultFields={{
                    real_id: { raw: {} },
                    privacy: { raw: {} },
                    uid: { raw: {} },
                    title: { raw: {} },
                    created_at: { raw: {} }
                  }}
                  sortField="created_at"
                  sortDirection="desc"
                  defaultValues={
                    bucketId
                      ? [
                          {
                            value: `${bucketId}`,
                            label: `${existingBucketTitle}`
                          }
                        ]
                      : undefined
                  }
                  labelI18n={"buckets.bucketAddPhotoButton.buckets"}
                  mapResults={results =>
                    results.map((result: any) => ({
                      value: getRaw(result, "real_id"),
                      label: getRaw(result, "title")
                    }))
                  }
                  onChange={options => {
                    if (options.length) {
                      const option = options[0];
                      const bucketId = parseInt(option.value, 10);
                      const existingBucketTitle = option.label;
                      this.setState({
                        bucketId,
                        existingBucketTitle
                      });
                    }
                  }}
                />
              )}
              {mode === "create" && (
                <Box>
                  <Input
                    name="title"
                    type="text"
                    labelI18n={"buckets.bucketAddPhotoButton.bucketName"}
                    onChange={this.handleOnChange}
                    value={newBucket.title}
                    mb={3}
                  />
                  <Tabs
                    activeName={newBucket.privacy}
                    flexBasis={["32%"]}
                    fontSize={[1, 3]}
                    sx={{
                      "&:": {
                        textTransform: "lowercase !important",
                        marginRight: -4
                      }
                    }}
                    tabs={[
                      {
                        name: "public",
                        label: i18next.t("buckets.bucketAddPhotoButton.public"),
                        icon: "Globe",
                        onClick: () => this.handleOnPrivacyChange("public")
                      },
                      {
                        name: "private",
                        label: i18next.t(
                          "buckets.bucketAddPhotoButton.private"
                        ),
                        icon: "Lock2",
                        onClick: () => this.handleOnPrivacyChange("private")
                      }
                    ]}
                  />
                </Box>
              )}
            </>
          </Popup>
        )}
      </AuthUserContext.Consumer>
    );
  }
}
