import React from "react";
import Svg, { SvgProps } from "primitives/Svg";

const SvgPlus2 = ({ width, height, size, color }: SvgProps) => (
  <Svg
    width={size || width}
    height={size || height}
    viewBox="0 0 114 115"
    color={color}>
    <path d="M0 71.123v-27.48h43.157V.5h27.686v43.157H114v27.479H70.843V114.5H43.157V71.136H0v-.013z" />
  </Svg>
);

SvgPlus2.defaultProps = {
  width: 24,
  height: 24
};
export default SvgPlus2;
