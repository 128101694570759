import { Button, Popup } from "app/shared";
import i18next from "i18next";
import React, { useEffect, useState } from "react";
import { Box, BoxProps, Flex, Text } from "rebass";
import { BuyProposal } from "./models";
import {
  acceptProposal,
  declineProposal,
  deleteProposal,
  getProposal
} from "./services";
import { DocumentView } from "app/documents";
import { MonetaryText } from "app/shared/monetaryText";
import { TableLayout } from "app/shared/tableLayout";
import { Trans } from "react-i18next";
import { UserPicture } from "app/account";

interface Props extends BoxProps {
  title: string;
  proposalId: number;
}

export const ProposalViewer: React.FC<{
  proposal: BuyProposal;
  mode: "buyer" | "seller";
}> = ({ proposal, mode }) => {
  const user = mode === "buyer" ? proposal.seller : proposal.buyer;

  return (
    <Box>
      <Box p={2} sx={{ borderBottom: "1px solid black" }}>
        <Button
          href={`/users/${user.id}`}
          variant="transparent"
          bg="white"
          pr={2}
          sx={{ "&:hover": { opacity: "1 !important" } }}>
          <Flex alignItems="center">
            {user.picture ? (
              <UserPicture
                picture={user.picture}
                height="35px"
                verifiedIconSize={16}
                userVerified="verified"
              />
            ) : (
              <UserPicture
                picture={undefined}
                height="35px"
                verifiedIconSize={16}
                userVerified="verified"
              />
            )}
            <Text
              pl={3}
              color="black"
              variant="grotTextCaps"
              sx={{ lineHeight: 1 }}
              fontSize={2}>
              {user.name}
            </Text>
          </Flex>
        </Button>
      </Box>
      <Flex
        flexDirection={["column", "row"]}
        sx={{ borderBottom: "1px solid black" }}>
        <Box
          width={["100%", "50%"]}
          sx={{
            borderRight: [0, 1],
            borderBottom: [1, 0],
            borderColor: "black"
          }}>
          <Box p={2}>
            <Text variant="grotTextCaps" fontSize={1}>
              <Trans i18nKey="proposals.popUp.offer" />
            </Text>
            <Text variant="grotTextCaps" color="blue">
              <MonetaryText price={proposal.price} fontSize={8} />
            </Text>
          </Box>
        </Box>
        <Box width={["100%", "50%"]}>
          <Box p={2}>
            <Text variant="grotTextCaps" fontSize={1}>
              <Trans i18nKey="proposals.popUp.license" />
            </Text>
            <Text variant="grotTextCaps" fontSize={8} color="blue">
              {proposal.license}
            </Text>
          </Box>
        </Box>
      </Flex>
      <TableLayout
        width="100%"
        columns={2}
        mb={2}
        tableNodes={[
          ...proposal.documents.map(it => [
            <DocumentView
              key={`${it.id}-view`}
              document={{
                id: it.id,
                thumbnail: it.thumbnail
              }}
            />
          ])
        ]}
      />
    </Box>
  );
};

export const ProposalSellerPopUp: React.FC<Props> = ({
  proposalId,
  title,
  ...rest
}) => {
  const [open, setOpen] = useState(false);
  const [proposal, setProposal] = useState<BuyProposal | undefined>();

  useEffect(() => {
    if (open) {
      getProposal(proposalId).then(res => setProposal(res.data));
    }
  }, [proposalId, open]);

  return (
    <Popup
      trigger={
        <Button disabled={false} {...(rest as any)}>
          <Text fontSize={[0, 4]} textAlign="left">
            {title}
          </Text>
        </Button>
      }
      onOpen={() => setOpen(true)}
      heading={`${i18next.t("proposals.popUp.titleSeller")} (${
        proposal?.status
      })`}
      submit={async close => {
        if (proposal) {
          if (proposal.status === "pending") {
            await acceptProposal(proposal.id);
            setProposal({ ...proposal, status: "accepted" });
          }

          close();
        }
      }}
      submitText={i18next.t("proposals.popUp.accept")}
      submitDisabled={!["pending"].includes(proposal?.status ?? "")}
      cancelText={
        proposal?.status === "pending"
          ? i18next.t("proposals.popUp.reject")
          : i18next.t("proposals.popUp.delete")
      }
      cancel={async close => {
        if (proposal) {
          if (proposal.status === "pending") {
            await declineProposal(proposal.id);
            setProposal({ ...proposal, status: "rejected" });
          } else if (proposal.status === "accepted") {
            await deleteProposal(proposal.id);
            setProposal({ ...proposal, status: "deleted" });
          }

          close();
        }
      }}
      cancelDisabled={!["pending", "accepted"].includes(proposal?.status ?? "")}
      contentPadding={0}
      onClose={() => null}>
      <>{proposal && <ProposalViewer proposal={proposal} mode="seller" />}</>
    </Popup>
  );
};
