import React from "react";
import themeGet from "@styled-system/theme-get";
import { Global, css } from "@emotion/core";
import { DefaultTheme } from "emotion-theming";

const globalStyles = (theme: DefaultTheme) => css`
  /* autoprefixer grid: autoplace */

  * {
    box-sizing: border-box;
  }

  html {
    /* Adjust font size */
    font-size: 100%;
    -webkit-text-size-adjust: 100%;
    /* Font varient */
    font-variant-ligatures: none;
    -webkit-font-variant-ligatures: none;
    /* Smoothing */
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
  }

  html,
  body {
    margin: 0;
    padding: 0;
    font-family: ${themeGet("fonts.body")({ theme })};
  }
`;
export default () => <Global styles={globalStyles} />;
