import { JsonObject, Status, UserTranslation } from "models";
import { SocialMediaTypes } from "../constants";
import { Currency } from "marketplace";
import { ISOLanguageCode } from "i18n/resources/supportedLanguages";

/**
 * Relations
 */
export interface RelationUser extends JsonObject<string | undefined | number> {
  id: string;
  status: Status;
  createdAt: string;
  name: string;
  city?: string;
  country?: string;
  sold: number;
  bought: number;
  earnings: number;
  spent: number;
  profileViews: number;
  documentViews: number;
  likes: number;
  likesGiven: number;
  picture: string;
}

export interface RelationDocument extends JsonObject<number | string | Status> {
  id: number;
  type: string;
  status: Status;
  uid: string;
  createdAt: string;
  sales: number;
  earnings: number;
  views: number;
  likes: number;
  shares: number;
  thumbnail: string;
}

export interface RelationDocumentSale {
  id: number;
  createdAt: string;
  price: number;
  currency: Currency;
}

export interface UserRelationInfo {
  follow: boolean;
  view: boolean;
}

//UserInfo: frontend model used to store the inputs in edit information of the user profile
export interface UserInfo
  extends JsonObject<
    | string
    | string[]
    | undefined
    | SocialMediaInput[]
    | WebsiteInput[]
    | UserTranslation[]
  > {
  professionalName?: string;
  fullName?: string;
  username?: string;
  picture?: string;
  country?: string;
  city?: string;
  websites?: WebsiteInput[];
  translations?: UserTranslation[];
  skills?: string[];
  collaboration?: string[];
  social?: SocialMediaInput[];
}

type StringWithDigitsLettersDots = string;
export const REGEX_LETTERS_DIGITS_DOTS = /^[a-zA-Z0-9_.-]*$/;

export interface SocialMediaInput {
  name: SocialMediaTypes;
  value: StringWithDigitsLettersDots;
}

//export const REGEX_BASIC_WEBSITE = /(http|ftp|https):\/\/[\w-]+(\.[\w-]+)+([\w.,@?^=%&amp;:\/~+#-]*[\w@?^=%&amp;\/~+#-])?/;
type Url = string;

export interface WebsiteInput {
  value: Url;
}

/**
 * Register
 */
export interface UserPreferences {
  subscribeNewsletters: boolean;
  language: ISOLanguageCode;
}

/**
 * Notifications
 */
export interface NotificationCount {
  unread: number;
  total: number;
}

export interface Notification {
  id: number;
  userId: string;
  createdAt: string;
  read: boolean;
  deleted: boolean;
  subject: string;
  shortDescription?: string;
  entity?: "User" | "Document" | "UploadTask";
  entityId?: string;
  type: "Informational" | "Entity";
}

export interface NotificationChannel {
  type: "Email" | "InternalNotification";
  enabled: boolean;
}

export interface NotificationSetting {
  groupKey: string;
  channels: NotificationChannel[];
}

export interface NotificationPreference {
  language: ISOLanguageCode;
}

/**
 * Marketplace
 */
export type PayoutProviderName = "paypal" | "test";

export interface UserMarketplaceSettings {
  uid: string;
  payoutProviderName: PayoutProviderName;
  payoutReceiver: string;
  receiveDonations: boolean;
}

/**
 * Invites
 */
export type InviteType = "PortfolioValidation" | "IdentityValidation";

export interface Invite {
  type: InviteType;
  id: number;
  code: string;
}
