import { Button, Popup } from "app/shared";
import i18next from "i18next";
import React, { useEffect, useState } from "react";
import { BoxProps, Text } from "rebass";
import store from "store";
import * as actions from "app/documents/checkout/reducers/actions";
import { BuyProposal } from "./models";
import { deleteProposal, getProposal } from "./services";
import { ProposalViewer } from "./ProposalSellerPopUp";

interface Props extends BoxProps {
  title: string;
  proposalId: number;
}

export const ProposalBuyerPopUp: React.FC<Props> = ({
  proposalId,
  title,
  ...rest
}) => {
  const [open, setOpen] = useState(false);
  const [proposal, setProposal] = useState<BuyProposal | undefined>();

  useEffect(() => {
    if (open) {
      getProposal(proposalId).then(res => setProposal(res.data));
    }
  }, [proposalId, open]);

  return (
    <Popup
      trigger={
        <Button disabled={false} {...(rest as any)}>
          <Text fontSize={[0, 4]} textAlign="left">
            {title}
          </Text>
        </Button>
      }
      onOpen={() => setOpen(true)}
      heading={`${i18next.t("proposals.popUp.titleBuyer")} (${
        proposal?.status
      })`}
      submit={close => {
        if (proposal) {
          proposal.documents.forEach(document => {
            store.dispatch(
              actions.addCheckoutItem({
                documentId: document.id,
                thumbnail: document.thumbnail,
                licensePrice: {
                  license: proposal.license,
                  basePrice: proposal.price
                }
              })
            );
          });

          close();
        }
      }}
      submitText={i18next.t("proposals.popUp.checkout")}
      submitDisabled={!["accepted"].includes(proposal?.status ?? "")}
      cancelText={i18next.t("proposals.popUp.delete")}
      cancel={async close => {
        if (proposal) {
          if (["pending", "accepted"].includes(proposal.status ?? "")) {
            await deleteProposal(proposal.id);
            setProposal({ ...proposal, status: "deleted" });
          }

          close();
        }
      }}
      cancelDisabled={!["pending", "accepted"].includes(proposal?.status ?? "")}
      contentPadding={0}
      onClose={() => null}>
      <>{proposal && <ProposalViewer proposal={proposal} mode="buyer" />}</>
    </Popup>
  );
};
