import React from "react";
import Svg, { SvgProps } from "primitives/Svg";

const SvgSettings = ({ width, height, size, color }: SvgProps) => (
  <Svg
    width={size || width}
    height={size || height}
    viewBox="0 0 24 24"
    color={color}>
    <path d="M12 8c2.21 0 4 1.79 4 4s-1.79 4-4 4-4-1.79-4-4 1.79-4 4-4zm0-1c-2.76 0-5 2.24-5 5s2.24 5 5 5 5-2.24 5-5-2.24-5-5-5z" />
    <path d="M22 14V9.38l-2.83-.34L21 6.88 17.13 3l-2.15 1.83L14.63 2H9.38l-.35 2.83L6.88 3 3 6.88l1.84 2.16L2 9.38v5.25l2.83.35-1.84 2.17 1.25 1.25.71-.71-.6-.6 1.24-1.46.51-.6c-.15-.3-.29-.62-.4-.94L4.94 14 3 13.74v-3.48l1.96-.24.75-.09c.11-.33.24-.65.4-.95l-.51-.59-1.24-1.46 2.57-2.57 1.45 1.23.6.51c.3-.16.62-.29.95-.4l.09-.76.24-1.94h3.48l.24 1.95.09.76c.33.11.64.24.95.4l.6-.51 1.45-1.23 2.57 2.57-1.24 1.45-.51.6c.16.3.29.62.4.95l.75.09 1.96.23v3.48l-1.95.24-.76.09c-.11.33-.24.64-.4.94l.51.6 1.24 1.46-2.57 2.57-1.46-1.24-.6-.51c-.3.16-.62.29-.95.4l-.09.75-.24 1.96h-3.47l-.24-1.96-.09-.74c-.33-.11-.65-.24-.95-.4l-.6.51-1.46 1.25-.56-.56-.71.7 1.21 1.21 2.17-1.84.35 2.83.62.01h4l.63-.01.34-2.83 2.16 1.84 3.88-3.88-1.84-2.16 2.83-.35V14z" />
  </Svg>
);

SvgSettings.defaultProps = {
  width: 24,
  height: 24
};
export default SvgSettings;
