import React from "react";
import Svg, { SvgProps } from "primitives/Svg";

const SvgStar = ({ width, height, size, color }: SvgProps) => (
  <Svg
    width={size || width}
    height={size || height}
    viewBox="0 0 18 17"
    color={color}>
    <path
      d="M13.803 16.142a.36.36 0 01-.21-.068L9.05 12.817l-4.543 3.257a.36.36 0 01-.551-.177.349.349 0 010-.218l1.735-5.27-4.543-3.256a.354.354 0 01-.13-.395.353.353 0 01.34-.244h5.616l1.735-5.27A.354.354 0 019.049 1a.361.361 0 01.341.244l1.735 5.27h5.616a.362.362 0 01.34.244.349.349 0 01-.13.395l-4.543 3.256 1.736 5.27a.349.349 0 01-.051.317.356.356 0 01-.29.145v0zM9.05 12.027a.36.36 0 01.21.068l3.862 2.768-1.475-4.48a.35.35 0 01.13-.394l3.862-2.769h-4.774a.361.361 0 01-.34-.244L9.05 2.496l-1.475 4.48a.354.354 0 01-.341.244H2.46L6.322 9.99a.353.353 0 01.13.394l-1.475 4.48 3.862-2.768a.36.36 0 01.21-.068z"
      stroke="#000"
      strokeWidth={0.6}
    />
  </Svg>
);

SvgStar.defaultProps = {
  width: 24,
  height: 24
};
export default SvgStar;
