export const notificationSettings = [
  {
    title: "account.settings.management.notifications.general",
    items: [
      {
        title: "account.settings.management.notifications.follow.title",
        description:
          "account.settings.management.notifications.follow.description",
        name: "UserFollow"
      },
      {
        title: "account.settings.management.notifications.newsletter.title",
        description:
          "account.settings.management.notifications.newsletter.description",
        name: "NewsletterSubscription"
      }
    ]
  },
  {
    title: "account.settings.management.notifications.seller",
    items: [
      {
        title: "account.settings.management.notifications.newSell.title",
        description:
          "account.settings.management.notifications.newSell.description",
        name: "DocumentPurchase"
      }
    ]
  }
];
