import { CachedPagination, PageEmptyState, PageHeader } from "app/shared";
import { withAuthorization } from "authentication/session";
import i18next from "i18next";
import { ContentArea } from "layout";
import debounce from "lodash.debounce";
import React, { Component, Dispatch } from "react";
import { Trans } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { Action } from "redux";
import { Notification } from "../models";
import { getNotifications, markAllNotificationsRead } from "../services";
import { NotificationsViewer } from "./NotificationsViewer";
import * as actions from "./reducers/actions";

interface State {
  disabled: boolean;
  first: boolean;
  last: boolean;
  page: number;
  size: number;
  totalElements: number;
  totalPages: number;
  notifications: Notification[];
}

interface Props {
  setUnreadNotifications: (count: number) => void;
}

class NotificationsBase extends Component<Props, State> {
  state = {
    // control
    disabled: false,
    notifications: [],
    // page variables
    first: true,
    last: false,
    page: 0,
    size: 10,
    totalElements: 1,
    totalPages: 1
  };

  componentDidMount() {
    this.getNotifications(0, this.state.size);

    // Mark all notifications read after a few seconds
    debounce(() => {
      markAllNotificationsRead().then(() =>
        this.props.setUnreadNotifications(0)
      );
    }, 1000)();
  }

  getNotifications(page: number, size: number) {
    this.setState({ disabled: true }, () => {
      getNotifications(page, size).then(
        ({ data, page, size, totalElements, totalPages }) => {
          this.setState({
            disabled: false,
            notifications: data,
            page,
            size,
            totalElements,
            totalPages
          });
        }
      );
    });
  }

  render() {
    const { notifications, disabled, page, size, totalPages } = this.state;

    return (
      <ContentArea>
        <CachedPagination<Notification>
          disabled={disabled}
          results={notifications}
          page={page}
          totalPages={totalPages}
          setPage={page => this.getNotifications(page, size)}>
          {notifications =>
            notifications.length ? (
              <>
                <PageHeader
                  variant="2"
                  title={i18next.t("account.notifications.pageTitle")}
                  mb={4}
                />
                <NotificationsViewer notifications={notifications} />
              </>
            ) : (
              <PageEmptyState
                loading={disabled}
                header={<Trans i18nKey="account.notifications.pageTitle" />}
                description={
                  <Trans i18nKey="account.notifications.noNotifications" />
                }
              />
            )
          }
        </CachedPagination>
      </ContentArea>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  setUnreadNotifications: async (count: number) =>
    dispatch(actions.setUnreadNotifications(count))
});

export const Notifications = compose<Props, any>(
  connect(undefined, mapDispatchToProps),
  withRouter,
  withAuthorization()
)(NotificationsBase);
