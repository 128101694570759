import i18next, { TOptions } from "i18next";
import * as React from "react";
import { Text, TextProps } from "rebass";

interface Props extends TextProps {
  i18nKey: string;
  i18nOptions?: TOptions;
}

export const GridLabel: React.FC<Props> = ({
  i18nKey,
  i18nOptions,
  ...rest
}) => (
  <Text fontSize={[0, 1]} variant="grotTextCaps" {...(rest as any)}>
    {i18next.t(i18nKey, i18nOptions)}
  </Text>
);
