import React from "react";
import i18next from "i18next";
import { Popup } from "app/shared";
import { Trans } from "react-i18next";
import { Box, Text } from "rebass";
import { compose } from "recompose";
import { RouteComponentProps, withRouter } from "react-router-dom";

interface PropsExternal {
  trigger: any;
  status: "approved" | "rejected";
}

interface Props extends PropsExternal, RouteComponentProps {}

const ProcessedPopupBase: React.FC<Props> = ({ trigger, status, history }) => (
  <Popup
    trigger={trigger}
    heading={i18next.t("account.popups.processedPopup.heading")}
    cancel={close => close()}
    submit={close => {
      if (status === "approved") {
        history.push("/submit-content");
      }
      close();
    }}
    submitText={i18next.t("account.popups.processedPopup.submit")}>
    <>
      <Box>
        <Text textAlign="center" variant="grotText">
          <Trans i18nKey={`account.popups.processedPopup.content1`} />
        </Text>
        <Text textAlign="center" variant="grotText">
          <Trans i18nKey={`account.popups.processedPopup.content2`} />
        </Text>
        <Text textAlign="center" variant="grotText">
          <Trans i18nKey={`account.popups.processedPopup.content3`} />
        </Text>
      </Box>
    </>
  </Popup>
);

export const ProcessedPopup = compose<Props, PropsExternal>(withRouter)(
  ProcessedPopupBase
);
