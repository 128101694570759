import React from "react";
import Svg, { SvgProps } from "primitives/Svg";

const SvgFVertical = ({ width, height, size, color }: SvgProps) => (
  <Svg
    width={size || width}
    height={size || height}
    viewBox="0 0 19 24"
    color={color}>
    <path d="M17.895 0H1.19h-.048c-.024 0-.04.012-.064.016C1.05.024 1.022.028.994.04.966.052.946.068.922.084.9.1.875.116.855.136.835.156.819.179.803.199.787.223.77.247.755.275.743.303.739.327.73.355.723.375.715.395.71.419v22.375c.024.24.227.427.475.427H4.95a.476.476 0 00.475-.427V13.96h12.462a.476.476 0 00.427-.474v-3.76a.476.476 0 00-.427-.474H5.425V4.704h12.462a.476.476 0 00.427-.474V.478a.476.476 0 00-.427-.474L17.895 0z" />
  </Svg>
);

SvgFVertical.defaultProps = {
  width: 24,
  height: 24
};
export default SvgFVertical;
