import { Chat, Message } from "messaging/MessageService";
import { AuthUser } from "models";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Box, Flex, Text } from "rebass";
import { ChatTriangle } from "./ChatTriangle";
import "./scrollbar.css";
import i18next from "i18next";

interface Props {
  chat: Chat;
  chatSelected: number;
  authUser?: AuthUser;
}

export const ChatMessagesList: React.FC<Props> = ({
  chat,
  chatSelected,
  authUser
}) => {
  const [scrollHeight, setScrollHeight] = useState(0);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [numberOfMessages, setNumberOfMessages] = useState(0);

  useEffect(() => {
    const selectedChat = document.getElementById(`chat_${chatSelected}`);
    if (selectedChat && chatSelected !== -1 && chat) {
      const numMsgs = chat.messages.length;
      setScrollHeight(selectedChat.scrollHeight);
      setNumberOfMessages(numMsgs);
      selectedChat.scrollTo(0, scrollHeight);
    }
  }, [chatSelected, scrollHeight, chat]);

  return (
    <Box
      id={`chat_${chatSelected}`}
      overflowY="auto"
      height="50%"
      sx={{ flexGrow: 1 }}>
      {chatSelected !== -1 &&
        chat.messages.map((message: Message) => {
          const fromOwnUser = authUser && authUser.uid === message.uid;

          return (
            <Flex
              key={message.createdAt.seconds}
              justifyContent={"flex-start"}
              flexDirection={fromOwnUser ? "row-reverse" : "row"}
              mt={3}
              mx={[1, 2, 3]}>
              <ChatTriangle side={fromOwnUser ? "left" : "right"} />
              <Box
                backgroundColor={fromOwnUser ? "black.0" : "white.0"}
                width="70%"
                sx={{ border: 1, borderColor: "black.0" }}
                py={2}
                px={[1, 2, 3]}>
                <Text
                  fontSize={[0, 1]}
                  variant="grotText"
                  color={fromOwnUser ? "white.0" : "black.0"}>
                  {moment(message.createdAt.toDate()).calendar(undefined, {
                    sameDay: "HH:mm",
                    lastDay: `[${i18next.t(
                      "messages.relativeDay.yesterday"
                    )}], HH:mm`,
                    sameElse: "DD/MM/YYYY, HH:mm"
                  })}
                </Text>
                <Text
                  pt={[1, 2]}
                  fontSize={[2, 4, 6]}
                  variant="grotText"
                  color={fromOwnUser ? "white.0" : "black.0"}>
                  {message.content}
                </Text>
              </Box>
            </Flex>
          );
        })}
    </Box>
  );
};
