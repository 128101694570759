import { Button } from "app/shared";
import React from "react";
import { Trans } from "react-i18next";
import { UserInfo } from "../models";
import SocialMediaInputs from "./SocialMediaInputs";

interface Props {
  userInfo: UserInfo;
  handleOnSocialMediaChange: (
    index: number,
    type: "select" | "value",
    value: string
  ) => void;
  validateSocialMedia: (
    event: React.KeyboardEvent<HTMLDivElement>,
    regex: RegExp
  ) => void;
  addSocialMedia: () => void;
  addBtnDisabled: () => boolean;
  deleteSocialMediaRow: (index: number) => void;
}

const SocialProfiles: React.FC<Props> = props => (
  <>
    <SocialMediaInputs {...props} />
    <Button
      onClick={props.addSocialMedia}
      mr={1}
      mt={[1, 2, 3]}
      disabled={props.addBtnDisabled()}>
      <Trans i18nKey="account.editMyInfo.socialProfiles.socialMedia.add" />
    </Button>
  </>
);

export default SocialProfiles;
