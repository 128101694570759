import { Button, Label, Toast } from "app/shared";
import React, { Component } from "react";
import { Trans } from "react-i18next";
import { updateUser } from "../services";
import { Box, Flex, Text } from "rebass";
import { UpdateUserDTO } from "../dto";
import i18next from "i18next";
import { FormFieldSet } from "app/shared/forms";
import { User, UserTranslation, ValidationError } from "models";
import { SocialMediaInput } from "../models";
import WebsiteInputs from "../editMyInfo/WebsiteInputs";
import { Textarea } from "app/shared/input/Textarea";
import { compose } from "recompose";
import { connect } from "react-redux";
import { ISOLanguageCode } from "i18n/resources/supportedLanguages";
import SocialProfiles from "../editMyInfo/SocialProfiles";
import { SocialMediaTypes } from "../constants";
import { DEFAULT_EMPTY_SOCIAL_MEDIA } from "../editMyInfo/EditMyInfo";

interface PropsExternal {
  user: User;
  onSkip?: () => void;
  onComplete: (user: User) => void;
}

interface Props extends PropsExternal {
  language: ISOLanguageCode;
}

interface State {
  translations: UserTranslation[];
  websites: string[];
  social: SocialMediaInput[];
  publishedWorks: string[];
  disabled: boolean;
}

class PromoToolsBase extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      translations: props.user.translations.length
        ? props.user.translations
        : [],
      websites: props.user.websites.length ? props.user.websites : [""],
      social: props.user.social.length
        ? props.user.social
        : [{ ...DEFAULT_EMPTY_SOCIAL_MEDIA }],
      publishedWorks: props.user.publishedWorks.length
        ? props.user.publishedWorks
        : [""],
      disabled: false
    };
  }

  validateForm(): ValidationError[] {
    return [];
  }

  onSubmit() {
    const { onComplete, user } = this.props;
    const errors = this.validateForm();

    if (user && errors.length === 0) {
      this.setState({ disabled: true }, () => {
        const { translations } = this.state;
        const websites = this.state.websites.filter(it => it.length);
        const social = this.state.social.filter(it => it.value.length);
        const publishedWorks = this.state.publishedWorks.filter(
          it => it.length
        );
        const updateUserDTO: UpdateUserDTO = {
          userId: user.id,
          translations,
          websites,
          social,
          publishedWorks
        };
        updateUser(updateUserDTO)
          .then(user => {
            this.setState({ disabled: false });
            onComplete(user);
            Toast.success({
              title: {
                key: "account.onboarding.confirmationToast"
              }
            });
          })
          .catch(error => {
            Toast.apiError(error);
            this.setState({ disabled: false });
          });
      });
    }
  }

  render() {
    const { onSkip, language } = this.props;
    const {
      translations,
      websites,
      publishedWorks,
      social,
      disabled
    } = this.state;

    return (
      <Box px={[2, 0]}>
        <Text fontSize={[2, 3, 7]} variant="grotTextCaps" mb={[1, 3]}>
          <Trans i18nKey="account.onboarding.promoTools.aboutMe" />
        </Text>
        <Text variant="caps" fontSize={[1, 2, 5]} mb={4}>
          <Trans i18nKey="account.onboarding.promoTools.personalDescription" />
        </Text>
        <Textarea
          name="translations"
          type="text"
          placeholder={i18next.t(
            "account.onboarding.promoTools.personalDescriptionPlaceholder"
          )}
          onChange={event => {
            // Here it's assumed we will only change the first created translation.
            // When we add support for multiple language translations this have to be changed.
            const translation: UserTranslation = translations?.[0] ?? {
              language,
              about: ""
            };
            translation.about = event.target.value; // set about value
            this.setState({ translations: [translation] });
          }}
          value={translations?.[0]?.about}
          minHeight="100px"
          mb={3}
        />
        <FormFieldSet
          titleTop={i18next.t("account.onboarding.promoTools.websitesTitle")}
          sx={{ border: 1, mb: 4 }}>
          <WebsiteInputs
            labelI18n="account.onboarding.promoTools.websitesDescription"
            websites={websites}
            handleWebsiteChange={(index, website) => {
              websites[index] = website;
              this.setState({ websites });
            }}
            removeWebsite={index => {
              websites.splice(index, 1);
              this.setState({ websites });
            }}
            addWebsite={() => {
              const newWebsites = [...websites, ""];
              this.setState({ websites: newWebsites });
            }}
            disabled={disabled}
          />
        </FormFieldSet>
        <FormFieldSet
          titleTop={i18next.t("account.onboarding.promoTools.socialTitle")}
          sx={{ border: 1, mb: 4 }}>
          <>
            <Label
              i18n="account.onboarding.promoTools.socialDescription"
              variant="grotTextCaps"
              mb={4}
              fontSize={[0, 1]}
            />
            <SocialProfiles
              userInfo={{ social }}
              handleOnSocialMediaChange={(index, type, value) => {
                if (social[index]) {
                  type === "select"
                    ? (social[index].name = value as SocialMediaTypes)
                    : (social[index].value = value);
                }
                this.setState({ social });
              }}
              validateSocialMedia={(event, regex) => {
                if (!regex.test(event.key)) {
                  event.preventDefault();
                }
              }}
              addSocialMedia={() => {
                const newSocial: SocialMediaInput[] = [
                  ...social,
                  { ...DEFAULT_EMPTY_SOCIAL_MEDIA }
                ];
                this.setState({ social: newSocial });
              }}
              deleteSocialMediaRow={index => {
                if (index > -1 && social[index]) {
                  social.splice(index, 1);
                  this.setState({ social });
                }
              }}
              addBtnDisabled={() => disabled}
            />
          </>
        </FormFieldSet>
        <FormFieldSet
          titleTop={i18next.t(
            "account.onboarding.promoTools.publishedWorkTitle"
          )}
          sx={{ border: 1 }}>
          <WebsiteInputs
            labelI18n="account.onboarding.promoTools.publishedWorkDescription"
            websites={publishedWorks}
            handleWebsiteChange={(index, website) => {
              publishedWorks[index] = website;
              this.setState({ publishedWorks });
            }}
            removeWebsite={index => {
              publishedWorks.splice(index, 1);
              this.setState({ publishedWorks });
            }}
            addWebsite={() => {
              const newWebsites = [...publishedWorks, ""];
              this.setState({ publishedWorks: newWebsites });
            }}
            disabled={disabled}
          />
        </FormFieldSet>
        <Flex justifyContent="space-between" mt={8}>
          {onSkip && (
            <Flex justifyContent="flex-end" alignItems="center" width="100%">
              <Button
                variant="white"
                width={["100%", "70%"]}
                p={3}
                disabled={disabled}
                onClick={() => this.onSubmit()}>
                <Trans i18nKey="account.onboarding.promoTools.skip" />
              </Button>
            </Flex>
          )}
          <Flex
            justifyContent={onSkip ? "left" : "center"}
            alignItems="center"
            width="100%"
            ml={onSkip ? 4 : 0}>
            <Button
              variant="blue"
              width={onSkip ? ["100%", "70%"] : "50%"}
              p={3}
              disabled={disabled}
              onClick={() => this.onSubmit()}>
              <Trans
                i18nKey={
                  onSkip
                    ? "account.onboarding.promoTools.submit"
                    : "account.onboarding.save"
                }
              />
            </Button>
          </Flex>
        </Flex>
      </Box>
    );
  }
}

const mapStateToProps = (state: any) => ({
  language: state.language.code
});

export const PromoTools = compose<Props, PropsExternal>(
  connect(mapStateToProps)
)(PromoToolsBase);
