import React from "react";
import Svg, { SvgProps } from "primitives/Svg";

const SvgExclamation = ({ width, height, size, color }: SvgProps) => (
  <Svg
    width={size || width}
    height={size || height}
    viewBox="0 0 6 43"
    color={color}>
    <path d="M1.7 32.62h2.7l1.32-18.78V.1H.38v13.74L1.7 32.62zM.14 43h5.82v-5.94H.14V43z" />
  </Svg>
);

SvgExclamation.defaultProps = {
  width: 24,
  height: 24
};
export default SvgExclamation;
