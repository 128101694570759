import React from "react";
import { Button, FullWidthContainer } from "app/shared";
import { connect } from "react-redux";
import { ISOLanguageCode } from "i18n/resources/supportedLanguages";

interface Label {
  language: ISOLanguageCode;
  label: string;
}

interface Props {
  language: ISOLanguageCode;
  labels: Label[];
  marginTop?: number | number[];
  fontSize?: number | number[];
  background?: string;
  color?: string;
  variant?: "full" | "normal";
}

const ButtonParser: React.FC<Props> = ({
  language,
  labels,
  marginTop,
  fontSize,
  background,
  color,
  variant,
  ...rest
}) => {
  const label =
    labels?.find(it => it.language === language)?.label ?? labels?.[0]?.label; // Showing first if there is no match with current user language
  const button = (
    <Button
      width="100%"
      variant="link.button"
      backgroundColor={background}
      color={color}
      marginTop={marginTop}
      fontSize={fontSize}
      sx={{
        textAlign: "center"
      }}
      {...(rest as any)}>
      {label}
    </Button>
  );

  return variant === "normal" ? (
    button
  ) : (
    <FullWidthContainer>{button}</FullWidthContainer>
  );
};

ButtonParser.defaultProps = {
  marginTop: 0,
  background: "blue",
  color: "white",
  variant: "normal"
};

const mapStateToProps = (state: any) => ({
  language: state.language.code
});

export default connect(mapStateToProps)(ButtonParser);
