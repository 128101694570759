import React, { ReactNode, useState } from "react";
import { Box, Text, BoxProps } from "rebass";
import { Button } from "app/shared";
import OutsideClickWrapper from "app/shared/wrapper/OutsideClickWrapper";
import { Trans } from "react-i18next";

interface Props extends BoxProps {
  i18nKey: string;
  mode?: "button" | "tooltip";
  children?: ReactNode;
}

export const Tooltip: React.FC<Props> = ({
  i18nKey,
  mode,
  children,
  ...rest
}) => {
  const [open, setOpen] = useState(false);

  return (
    <Box sx={{ position: "relative" }}>
      <Button
        variant={mode === "button" ? undefined : "black"}
        alignItems="center"
        px={mode === "button" ? undefined : 1}
        py={mode === "button" ? undefined : 0}
        onClick={() => setOpen(true)}
        onMouseEnter={() => setOpen(true)}
        onMouseLeave={() => setOpen(false)}
        {...(rest as any)}>
        {mode === "button" ? children : <Text fontSize={2}>?</Text>}
      </Button>
      {open && (
        <Box
          backgroundColor="white"
          p={3}
          width="max-content"
          maxWidth="min(600px, 90vw)"
          sx={{
            position: "absolute",
            top: mode === "button" ? 6 : 5,
            left: 1,
            border: 1,
            borderColor: "black.0",
            zIndex: 1
          }}>
          <OutsideClickWrapper isOpen={open} callback={() => setOpen(false)}>
            <Text fontSize={0} variant="grotTextCaps">
              <Trans i18nKey={i18nKey} />
            </Text>
          </OutsideClickWrapper>
        </Box>
      )}
    </Box>
  );
};
Tooltip.defaultProps = {
  mode: "tooltip"
};
