import { DocumentView } from "app/documents";
import { getDocument } from "app/documents/services";
import { Document } from "models";
import React, { useEffect, useState } from "react";
import environment from "configurations";
import { Box, Flex } from "rebass";
import MasonryLayout from "app/shared/masonryLayout";

interface Props {
  id: number;
}

const DocumentParser: React.FC<Props> = ({ id }) => {
  const [document, setDocument] = useState<Document>();
  useEffect(() => {
    const fetchData = async () => {
      setDocument(
        await getDocument(id).then(document => {
          return {
            ...document,
            title: document.translations[0].title,
            owner: {
              ...document.owner,
              picture:
                environment.url + "/users/" + document.owner.id + "/picture"
            }
          };
        })
      );
    };
    fetchData();
  }, [id]);
  if (!document) return null;
  return (
    <Flex justifyContent="center" alignItems="center" flexDirection="column">
      <Box width={["100%", "50%"]}>
        <MasonryLayout
          columns={1}
          gap={1}
          items={[
            <DocumentView
              key={document.id}
              document={document}
              width={"100%"}
            />
          ]}
          itemDimensions={[
            {
              height: document.height ? (document.height as number) : 0,
              width: document.width ? (document.width as number) : 0
            }
          ]}
        />
      </Box>
    </Flex>
  );
};

export default DocumentParser;
