import { UsersListSearch } from "app/search/users/UsersListSearch";
import React from "react";
import { Trans } from "react-i18next";
import { Box, Text, Flex } from "rebass";
import { Button } from "app/shared";

interface Props {
  order: "recent";
  anchor?: string;
  marginTop?: number | number[];
}

const ExploreUsersParser: React.FC<Props> = ({ order, anchor, marginTop }) => (
  <Box id={anchor} marginTop={marginTop}>
    <Flex
      alignItems="center"
      justifyContent="space-between"
      mb={3}
      pb={[2, 2, 0]}
      sx={{ borderBottom: 1, borderColor: "black.0" }}>
      <Text fontSize={[3, 8, 14]} variant="grotTextCaps" fontWeight="bold">
        <Trans i18nKey="pages.parser.exploreUsersParser.exploreUsers" />
      </Text>
      <Button
        ml={3}
        variant="link.button"
        sx={{ borderColor: "black.0" }}
        href="/search/user">
        <Trans i18nKey="pages.parser.exploreUsersParser.viewAll" />
      </Button>
    </Flex>
    <UsersListSearch
      resultsPerPage={3}
      scrollListenerEnabled={false}
      sortField={order === "recent" ? "created_at" : undefined}
      sortDirection={order === "recent" ? "desc" : undefined}
      noResultsView={<></>}
    />
  </Box>
);

ExploreUsersParser.defaultProps = {
  marginTop: 0
};

export default ExploreUsersParser;
