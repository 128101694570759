import React, { Component, FC } from "react";
import { Trans } from "react-i18next";
import { Box, Text, Flex } from "rebass";
import { LocalComputerUpload } from "app/documents/submitContent/uploadProviders";
import { Popup, Button, Toast, Label, Icon } from "app/shared";
import i18next from "i18next";
import { UploadFileItem } from "app/documents/submitContent/models";
import { checkFileSize, checkFileMimeType } from "utils/upload";
import { FormFieldSet } from "app/shared/forms";
import { PortfolioReviewService } from "../services/PortfolioReviewService";
import {
  PortfolioReviewProcess,
  PortfolioReviewProcessValidator
} from "../models/PortfolioReviewProcess";
import { ValidationError } from "models";
import WebsiteInputs from "../editMyInfo/WebsiteInputs";
import { UploadedFileList } from "app/shared/forms/UploadedFileList";

const ACCEPTED_FILE_TYPES = ["image/png", "image/jpeg"];
const ACCEPTED_FILE_SIZE = 20 * 1024 * 1024; // 20MB
const MAX_ALLOWED_FILES = 20;
const UPLOAD_WAIT_TIMEOUT = 1000;

interface PortfolioWebsiteProps {
  disabled: boolean;
  websites: string[];
  errors: ValidationError[];
  handleWebsiteChange: (index: number, website: string) => void;
  addWebsite: () => void;
  removeWebsite: (idx: number) => void;
  changeMethod: () => void;
}

const PortfolioWebsites: FC<PortfolioWebsiteProps> = props => {
  const websiteErrors = props.errors.find(err => err.field === "websites");
  return (
    <>
      <Label
        sx={{ textTransform: "uppercase" }}
        variant="grotText"
        i18n="account.onboarding.reviewPortfolioForms.portfolio.label"
      />
      <FormFieldSet
        sx={{
          border: 1,
          width: "100%",
          mb: [4, 0]
        }}>
        <Text fontSize={[1, 3]} sx={{ lineHeight: "24px" }} mb={4}>
          <Trans i18nKey="account.onboarding.reviewPortfolioForms.portfolio.description" />
        </Text>
        {websiteErrors && (
          <Text color="text.danger" mt={2}>
            <Trans
              i18nKey={websiteErrors.i18nKey}
              tOptions={websiteErrors.options}
            />
          </Text>
        )}
        {/* Websites list */}
        <WebsiteInputs {...props} disabled={false} />
      </FormFieldSet>
      {props.disabled && (
        <Flex justifyContent="center" alignItems="center" mt={4}>
          <Button
            variant="transparent"
            sx={{ textDecoration: "underline" }}
            onClick={_ => props.changeMethod()}>
            <Text>
              <Trans i18nKey="account.onboarding.reviewPortfolioForms.chooseLinks" />
            </Text>
          </Button>
        </Flex>
      )}
    </>
  );
};

interface PortfolioImageUpdateProps {
  disabled: boolean;
  files: UploadFileItem[];
  errors: ValidationError[];
  addFiles: (files: File[], label: string) => void;
  removeFile: (fileId: string) => void;
  changeMethod: () => void;
}

class PortfolioImageUpload extends Component<PortfolioImageUpdateProps> {
  render() {
    const {
      addFiles,
      files,
      disabled,
      removeFile,
      errors,
      changeMethod
    } = this.props;
    const fileErrors = errors.find(err => err.field === "files");
    return (
      <>
        {disabled && (
          <Flex justifyContent="center" alignItems="center">
            <Button
              variant="transparent"
              sx={{ textDecoration: "underline" }}
              onClick={_ => changeMethod()}>
              <Text>
                <Trans i18nKey="account.onboarding.reviewPortfolioForms.chooseDrive" />
              </Text>
            </Button>
          </Flex>
        )}
        {!disabled && (
          <>
            <Label
              sx={{ textTransform: "uppercase" }}
              variant="grotText"
              i18n="account.onboarding.reviewPortfolioForms.images.label"
            />
            <FormFieldSet
              sx={{
                border: 1,
                width: "100%",
                mb: [4, 0]
              }}>
              <Text fontSize={[1, 3]} mb={4}>
                <Trans i18nKey="account.onboarding.reviewPortfolioForms.images.imageAttachmentInstructions" />
              </Text>
              <LocalComputerUpload
                onFileDrop={newFiles =>
                  addFiles(
                    newFiles,
                    i18next.t(
                      "account.onboarding.reviewPortfolioForms.images.localComputer"
                    )
                  )
                }
              />
              {fileErrors && (
                <Text color="text.danger" mt={2}>
                  <Trans
                    i18nKey={fileErrors.i18nKey}
                    tOptions={fileErrors.options}
                  />
                </Text>
              )}
              {files.length > 0 && (
                <UploadedFileList
                  files={files}
                  showFileRemove={file => file.status !== "uploading"}
                  onRemoveFile={removeFile}
                  variant="short"
                />
              )}
            </FormFieldSet>
          </>
        )}
      </>
    );
  }
}

interface State {
  method: "images" | "links";
  disabled: boolean;
  files: UploadFileItem[];
  websites: string[];
  errors: ValidationError[];
}

interface Props {
  portfolioReviewService: PortfolioReviewService;
  onSkip: () => void;
  onComplete: (portfolioReviewProcess: PortfolioReviewProcess) => void;
}

export class PortfolioReviewForm extends Component<Props, State> {
  interval?: number;

  constructor(props: Props) {
    super(props);
    this.state = {
      method: "links",
      disabled: false,
      files: [],
      websites: [""],
      errors: []
    };
  }

  componentWillUnmount() {
    if (this.interval) {
      clearTimeout(this.interval);
      this.interval = undefined;
    }
  }

  private onMethodChanged(method: "images" | "links") {
    this.setState({ method });
  }

  private async uploadDocuments() {
    clearTimeout(this.interval); // Remove current timeout
    const queuedFiles = this.state.files.filter(
      it => it.status === "queued" && it.errors.length === 0
    );

    if (queuedFiles.length > 0) {
      try {
        // Upload current file
        const currentFile = queuedFiles[0];
        this.replaceFile(currentFile.id, {
          ...currentFile,
          status: "uploading"
        });
        await this.props.portfolioReviewService
          .uploadFiles([currentFile.file])
          .then(() => {
            this.replaceFile(currentFile.id, {
              ...currentFile,
              status: "uploaded"
            });
          })
          .catch(error => {
            this.replaceFile(currentFile.id, {
              ...currentFile,
              status: "canceled",
              errors: [error.message]
            });
            throw error; // throw error again so is not submitted
          });
      } catch (error) {
        Toast.apiError(error);
      }
    }

    // Upload next document in queue
    if (queuedFiles.length > 1) {
      this.interval = setTimeout(
        () => this.uploadDocuments(),
        UPLOAD_WAIT_TIMEOUT
      );
    }
  }

  private async submitPortfolio() {
    const { files, method } = this.state;
    const websites = this.state.websites.filter(it => it.length);
    const errors = new PortfolioReviewProcessValidator({
      method,
      files,
      websites
    }).validate();
    if (errors.length === 0) {
      this.setState({ disabled: true }, async () => {
        try {
          const updatedStatus = await this.props.portfolioReviewService.submitPortfolio(
            { websites }
          );
          this.props.onComplete(updatedStatus);
          Toast.success({
            title: {
              key: "account.onboarding.reviewPortfolioForms.confirmationToast"
            }
          });
        } catch (err) {
          console.error(err);
          Toast.apiError(err);
        } finally {
          this.setState({ disabled: false });
        }
      });
    } else {
      this.setState({ errors });
    }
  }

  private addFiles(blobs: File[], source: string) {
    if (blobs.length <= MAX_ALLOWED_FILES) {
      const files: UploadFileItem[] = blobs.map(file => {
        const errors = [
          ...checkFileSize(file, ACCEPTED_FILE_SIZE),
          ...checkFileMimeType(file, ACCEPTED_FILE_TYPES)
        ];
        const thumbnail = URL.createObjectURL(file);

        return {
          id: thumbnail,
          status: "queued",
          file,
          thumbnail,
          source,
          errors,
          documentId: undefined
        };
      });

      //Filter out repeated files
      const updatedFiles = files.filter(
        newFile =>
          !this.state.files.find(existingFile => {
            const sameName = newFile.file.name === existingFile.file.name;
            const sameType = newFile.file.type === existingFile.file.type;
            const sameSize = newFile.file.size === existingFile.file.size;

            return sameName && sameType && sameSize;
          })
      );

      // Set and upload files
      this.setState(
        {
          files: this.state.files.concat(updatedFiles)
        },
        () => {
          this.onFormChanged();
          this.uploadDocuments();
        }
      );
    }
  }

  private async onFormChanged() {
    const { files, method } = this.state;

    // Presenting erros when files are added
    const errors = new PortfolioReviewProcessValidator({
      method,
      files,
      websites: this.state.websites.filter(it => it.length)
    }).validate();

    this.setState({ errors });
  }

  private async removeFile(id: string) {
    const file = this.state.files.find(it => it.id === id);
    const files = this.state.files.filter(it => it.id !== id);

    try {
      if (file && file.status === "uploaded") {
        await this.props.portfolioReviewService.deleteFile(file.file.name);
      }
      this.setState({ files }, () => this.onFormChanged());
    } catch (err) {
      console.error(err);
      Toast.apiError(err);
    }
  }

  private replaceFile(id: string, file: UploadFileItem) {
    const files = this.state.files.map(it => (it.id === id ? { ...file } : it));
    this.setState({ files });
  }

  render() {
    const { onSkip } = this.props;
    const { disabled, files, errors, websites, method } = this.state;
    const uploadedFilesLength = files.filter(it => it.status === "uploaded")
      .length;
    const pendingFilesLength = files.filter(
      it => it.status === "queued" || it.status === "uploading"
    ).length;
    const submitDisabled =
      disabled ||
      (uploadedFilesLength === 0 && websites.length === 0) ||
      pendingFilesLength > 0 ||
      !files.every(it => it.errors.length === 0);

    return (
      <Flex justifyContent="center" flexDirection="column" p={3}>
        <Box sx={{ textAlign: "center" }} mb={8}>
          <Text
            fontSize={3}
            sx={{
              display: "inline-block",
              textAlign: "left",
              lineHeight: "24px"
            }}>
            <Trans i18nKey="account.onboarding.reviewPortfolioForms.headerText" />
          </Text>
        </Box>
        <Flex flexDirection="column" justifyContent="space-between">
          {/* Portfolio websites */}
          <Box mb={4}>
            <PortfolioWebsites
              websites={websites}
              errors={errors}
              disabled={disabled || method !== "links"}
              addWebsite={() =>
                this.setState({ websites: websites.concat([""]) })
              }
              handleWebsiteChange={(idx, value) => {
                // TODO: extract this
                const updatedWebsites = websites.concat([]);
                updatedWebsites[idx] = value;
                this.setState({ websites: updatedWebsites });
              }}
              removeWebsite={idx => {
                // TODO: extract this
                const updatedWebsites = websites.concat([]);
                updatedWebsites.splice(idx, 1);
                this.setState({ websites: updatedWebsites });
              }}
              changeMethod={() => this.onMethodChanged("links")}
            />
          </Box>
          {/* File uploads */}
          <PortfolioImageUpload
            disabled={
              files.length > MAX_ALLOWED_FILES ||
              disabled ||
              method !== "images"
            }
            files={files}
            errors={errors}
            addFiles={(blobs: File[], label: string) =>
              this.addFiles(blobs, label)
            }
            removeFile={(fileId: string) => this.removeFile(fileId)}
            changeMethod={() => this.onMethodChanged("images")}
          />
        </Flex>
        <Flex justifyContent="space-between" mt={6}>
          {/* Cancel button */}
          <Popup
            closeOnDocumentClick={disabled}
            closeOnEscape={disabled}
            trigger={
              <Flex justifyContent="flex-end" alignItems="center" width="100%">
                <Button
                  variant="white"
                  width={["100%", "70%"]}
                  p={3}
                  disabled={disabled}>
                  <Trans i18nKey="account.onboarding.reviewPortfolioForms.skip" />
                </Button>
              </Flex>
            }
            heading={i18next.t(
              "account.onboarding.reviewPortfolioForms.popup.header"
            )}
            cancelText={i18next.t(
              "account.onboarding.reviewPortfolioForms.popup.cancel"
            )}
            cancelDisabled={disabled}
            cancel={close => {
              close();
              onSkip();
            }}
            submit={close => {
              close();
            }}
            submitText={i18next.t(
              "account.onboarding.reviewPortfolioForms.popup.continue"
            )}
            submitDisabled={disabled}>
            <>
              <Text textAlign="center">
                {i18next.t(
                  "account.onboarding.reviewPortfolioForms.popup.content",
                  {
                    length: files.length
                  }
                )}
              </Text>
            </>
          </Popup>
          {/* Submit button */}
          <Flex justifyContent="left" alignItems="center" width="100%" ml={4}>
            <Button
              variant="blue"
              width={["100%", "70%"]}
              disabled={disabled || submitDisabled}
              p={3}
              onClick={() => this.submitPortfolio()}>
              <Trans i18nKey="account.onboarding.reviewPortfolioForms.submit" />
            </Button>
          </Flex>
        </Flex>
      </Flex>
    );
  }
}

export const PortfolioReviewInProgress: FC<any> = () => {
  return (
    <>
      <Flex justifyContent="center" alignItems="center">
        <Flex color="blue" mr={[4, 3]}>
          <Icon name="Tick" size={[40, 70]} />
        </Flex>
        <Flex>
          <Text fontSize={[2, 6]} sx={{ lineHeight: "40px" }}>
            <Trans i18nKey="account.onboarding.reviewPortfolioForms.reviewInProgressDetail" />
          </Text>
        </Flex>
      </Flex>
    </>
  );
};
