import { Input, Toast, Checkbox, TextLink } from "app/shared";
import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { compose } from "recompose";
import {
  checkFileEventMaxSelected,
  checkFileEventMimeType,
  checkFileEventSize
} from "utils/upload";
import { createUser } from "../services";
import AuthLayout from "./AuthLayout";
import RedirectButton from "./RedirectButton";
import AuthButton from "./AuthButton";
import { PageParser } from "app/pages";
import queryString from "query-string";
import { Trans } from "react-i18next";
import { Text, Box, Flex } from "rebass";
import i18next from "i18next";
import { RegisterPrivacyPolicy, RegisterTerms } from "./constants";
import {
  ISOLanguageCode,
  SupportedLanguages
} from "i18n/resources/supportedLanguages";
import { SelectInput } from "app/shared/input/SelectInput";
import { firebaseAnalytics } from "analytics/firebaseAnalytics";

interface PropsExternal {
  backgroundImage?: string;
  backgroundImageAuthor?: string;
}

interface Props extends PropsExternal, RouteComponentProps {}

interface State {
  professionalName: string;
  email: string;
  password: string;
  subscribeNewsletters: boolean;
  language: ISOLanguageCode;
  disabled: boolean;
  redirect?: string;
}

const INITIAL_STATE: State = {
  professionalName: "",
  email: "",
  password: "",
  subscribeNewsletters: true,
  language: "en",
  disabled: false
};

class RegisterBase extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      ...INITIAL_STATE,
      redirect: queryString
        .parse(props.location.search, { decode: false })
        ?.["redirect"]?.toString()
    };
  }

  handleOnSubmit() {
    const {
      email,
      password,
      professionalName,
      subscribeNewsletters,
      language,
      redirect
    } = this.state;

    this.setState({ disabled: true }, () => {
      createUser(
        email,
        password,
        professionalName,
        { subscribeNewsletters, language },
        undefined,
        undefined
      )
        .then(() => {
          firebaseAnalytics.logEvent("sign_up", {});
          this.setState({ ...INITIAL_STATE });

          this.props.history.push(
            `/login${redirect ? `?redirect=${redirect}` : ""}`
          );
          Toast.success({
            title: {
              key: "account.authentication.register.confirmationToast"
            }
          });
        })
        .catch(error => {
          Toast.apiError(error);
          this.setState({ disabled: false });
        });
    });
  }

  handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState<never>({ [event.target.name]: event.target.value });
  };

  handleOnFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const errors = [
      ...checkFileEventMaxSelected(event),
      ...checkFileEventMimeType(event),
      ...checkFileEventSize(event)
    ];

    if (event.target.files && errors.length === 0) {
      this.setState<never>({
        [event.target.name]: event.target.files[0]
      });
    } else {
      Toast.error({ title: errors.join(", ") });
    }
  };

  redirectLogin = () => {
    const { redirect } = this.state;
    this.props.history.push(`/login${redirect ? `?redirect=${redirect}` : ""}`);
  };

  render() {
    const { backgroundImage, backgroundImageAuthor } = this.props;
    const {
      professionalName,
      email,
      password,
      subscribeNewsletters,
      language,
      disabled,
      redirect
    } = this.state;
    const isInvalid =
      professionalName === "" || password.length < 6 || email === "";
    const selectedLanguage =
      SupportedLanguages.find(it => it.code === language) ??
      SupportedLanguages[0];

    return (
      <AuthLayout
        backgroundImage={backgroundImage}
        backgroundImageAuthor={backgroundImageAuthor}
        backgroundImageAuthorPosition="top"
        headerI18n="account.authentication.register.label"
        mainSection={
          <Flex width="100%" flexDirection="column" sx={{ flexGrow: 1 }}>
            <Input
              name="professionalName"
              type="text"
              labelI18n="account.authentication.register.professionalName"
              labelFontSize={2}
              infoI18n="account.authentication.register.professionalNameInfo"
              placeholder={i18next.t(
                "account.authentication.register.professionalNamePlaceholder"
              )}
              onChange={this.handleOnChange}
              value={professionalName}
              mb={2}
            />
            <Input
              name="email"
              type="email"
              labelI18n="account.authentication.register.email"
              labelFontSize={2}
              placeholder={i18next.t(
                "account.authentication.register.emailPlaceholder"
              )}
              onChange={this.handleOnChange}
              value={email}
              mb={2}
            />
            <Input
              name="password"
              type="password"
              labelI18n="account.authentication.register.password"
              labelFontSize={2}
              onChange={this.handleOnChange}
              value={password}
              infoI18n="account.authentication.register.passwordInfo"
              placeholder={i18next.t(
                "account.authentication.register.passwordPlaceholder"
              )}
              mb={2}
            />
            <SelectInput
              name="language"
              labelI18n="account.authentication.register.language"
              labelFontSize={2}
              onChange={this.handleOnChange}
              value={{
                value: selectedLanguage.code,
                label: selectedLanguage.name
              }}
              options={SupportedLanguages.map(language => ({
                value: language.code,
                label: language.name
              }))}
              infoI18n="account.authentication.register.languageInfo"
              sx={{ mb: [3, 5] }}
            />
            <Checkbox
              size={16}
              name="subscribeNewsletters"
              mb={[3, 5]}
              checked={subscribeNewsletters}
              onChange={() =>
                this.setState({ subscribeNewsletters: !subscribeNewsletters })
              }>
              <Text fontSize={1} variant="grotTextCaps">
                <Trans i18nKey="account.authentication.register.newsletter" />
              </Text>
            </Checkbox>
            <Text fontSize={[0, "11px"]} variant="grotTextCaps" mb={[2, 3]}>
              {i18next.t("account.authentication.register.registerInfoFirst")}{" "}
              <TextLink
                i18n={RegisterTerms.i18n}
                href={RegisterTerms.href}
                target="_blank"
              />{" "}
              {i18next.t("account.authentication.register.registerInfoSecond")}{" "}
              <TextLink
                i18n={RegisterPrivacyPolicy.i18n}
                href={RegisterPrivacyPolicy.href}
                target="_blank"
              />
            </Text>
            <AuthButton
              onClick={() => this.handleOnSubmit()}
              variant="blue"
              disabled={isInvalid || disabled}
              i18nKey="account.authentication.register.register"
              mb={[2, 3]}
            />
            <Box sx={{ flexGrow: 1 }} />
            <RedirectButton
              i18nKey="account.authentication.register.login"
              href={`/login${redirect ? `?redirect=${redirect}` : ""}`}
              variant="link.button.brand"
              headerI18nKey="account.authentication.register.loginHeader"
            />
          </Flex>
        }
      />
    );
  }
}

const RegisterCompose = compose<Props, PropsExternal>(withRouter)(RegisterBase);

/**
 * This components overrides <Random /> component and uses props.values to calculate a random image.
 * Note that only <Random /> component is being implemented, so other components defined in the backoffice will be ignored.
 * We are using a container to wrap <Register /> to prevent rendering <PageParser /> again that would result in another random image.
 */
export const Register = () => (
  <PageParser
    pageId={3}
    components={{
      Random: (props: any) => {
        const randomValue =
          props.values?.[Math.floor(Math.random() * props.values?.length)] ??
          undefined;

        return (
          <RegisterCompose
            backgroundImage={randomValue?.photo}
            backgroundImageAuthor={randomValue?.author}
          />
        );
      }
    }}
    placeholder={<RegisterCompose />}
  />
);
