import { keyframes } from "@emotion/core";
import styled from "@emotion/styled";
import React from "react";
import { Flex, Text, FlexProps } from "rebass";

const blink = keyframes`
    0% {
        opacity: .2;
    }
    20% {
        opacity: 1;
    }
    100% {
        opacity: .2;
    }
`;

const AnimatedText = styled(Text)`
  animation: ${blink};
  animation-fill-mode: both;
  animation-duration: 1.4s;
  animation-iteration-count: infinite;
  line-height: 1;
`;

interface Props extends FlexProps {
  children?: React.ReactNode;
}

export const SpinnerProgress: React.FC<Props> = ({ children, ...rest }) => (
  <Flex flexDirection="row" alignItems="flex-end" {...(rest as any)}>
    {children}
    <AnimatedText fontSize={[2, 4, 8]}>.</AnimatedText>
    <AnimatedText
      fontSize={[2, 4, 8]}
      sx={{ animationDelay: "0.2s !important" }}>
      .
    </AnimatedText>
    <AnimatedText
      fontSize={[2, 4, 8]}
      sx={{ animationDelay: "0.4s !important" }}>
      .
    </AnimatedText>
  </Flex>
);
