import i18next from "i18next";

/**
 * Gets translation if exists or return default
 * @param name label to translate
 */
export const getCommonTranslation = (name: string): string => {
  const i18nKey = `shared.translations.${name}`;
  const value = i18next.t(i18nKey);

  return value?.length && value !== i18nKey ? value : name;
};
