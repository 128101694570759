import React from "react";
import Svg, { SvgProps } from "primitives/Svg";

const SvgArrowDownBig = ({ width, height, size, color }: SvgProps) => (
  <Svg
    width={size || width}
    height={size || height}
    viewBox="0 0 38 132"
    color={color}>
    <path d="M17.232 130.768a2.5 2.5 0 003.536 0l15.91-15.91a2.5 2.5 0 00-3.536-3.536L19 125.464 4.858 111.322a2.5 2.5 0 00-3.536 3.536l15.91 15.91zM16.5 0v129h5V0h-5z" />
  </Svg>
);

SvgArrowDownBig.defaultProps = {
  width: 24,
  height: 24
};
export default SvgArrowDownBig;
