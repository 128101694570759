import React from "react";
import { Trans } from "react-i18next";
import { Box, Flex, Text, BoxProps } from "rebass";
import { Graph, Point } from "app/shared/graph";
import { Button } from "app/shared";

/**
 * Graph tooltip renderer
 */
const tooltipRender = (point: Point) => {
  if (!point?.data) return null; // there is no data at this point

  return (
    <Box
      backgroundColor="#fff"
      sx={{
        border: "1px solid #000"
      }}>
      <Box p={2}>
        <Text fontWeight="bold">{point.data?.date}</Text>
      </Box>
      <Box
        p={2}
        sx={{
          borderTop: "1px solid #000",
          borderBottom: "1px solid #000"
        }}>
        <Text fontWeight="bold">
          {point.data?.price} {point.data?.currency}
        </Text>
      </Box>
      <Box p={2}>
        <Text fontWeight="bold" variant="caps">
          {point.data?.items}{" "}
          <Trans i18nKey="account.insights.graph.itemsBought" />
        </Text>
      </Box>
    </Box>
  );
};

interface Props extends BoxProps {
  graphData: Point[];
  title: string;
  value: string;
  items: number;
  itemsLabel: string;
  link: string;
}

export const HistoryGraph: React.FC<Props> = ({
  graphData,
  title,
  value,
  items,
  itemsLabel,
  link,
  ...rest
}) => (
  <Flex
    flexDirection={["column", "row"]}
    sx={{
      border: "1px solid #000"
    }}
    {...(rest as any)}>
    <Flex
      flexDirection="column"
      width={["100%", "25%"]}
      sx={{
        borderRight: [0, "1px solid #000"],
        borderBottom: ["1px solid #000", 0]
      }}>
      <Box p={3}>
        <Text fontSize={6} variant="caps">
          {title}
        </Text>
        <Text fontSize={8} fontWeight="bold" my={2}>
          {value}
        </Text>
        <Flex alignItems="flex-end">
          <Text fontSize={8} fontWeight="bold" lineHeight="36px">
            {items}
          </Text>
          <Text fontSize={0} variant="caps" ml={2}>
            {itemsLabel}
          </Text>
        </Flex>
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          borderBottom: "1px solid #000"
        }}
      />
      <Button
        href={link}
        p={2}
        sx={{
          textTransform: "uppercase",
          textAlign: "center"
        }}>
        View Transactions
      </Button>
    </Flex>
    <Box p={3} width={["100%", "75%"]}>
      <Graph
        data={graphData}
        tooltipRender={tooltipRender}
        tickFormatterY={value => `${value}€`}
      />
    </Box>
  </Flex>
);
