import i18next from "i18next";

export const checkFrequency = (frequency: string, parentId?: number) => {
  let frequencyLabel = frequency;

  if (!parentId) {
    if (frequency === "OneTime") {
      frequencyLabel = i18next.t("donations.donationsViewer.frequency.oneTime");
    } else if (frequency === "Monthly") {
      frequencyLabel = i18next.t("donations.donationsViewer.frequency.monthly");
    } else if (frequency === "Monthly") {
      frequencyLabel = i18next.t("donations.donationsViewer.frequency.yearly");
    }
  } else {
    frequencyLabel = i18next.t(
      "donations.donationsViewer.frequency.recurrently"
    );
  }
  return frequencyLabel;
};

export const checkStatus = (status: string) => {
  let statusLabel = status;
  if (status === "pending") {
    statusLabel = i18next.t("donations.donationsViewer.status.pending");
  } else if (status === "error") {
    statusLabel = i18next.t("donations.donationsViewer.status.error");
  } else if (status === "completed") {
    statusLabel = i18next.t("donations.donationsViewer.status.completed");
  } else if (status === "deleted") {
    statusLabel = i18next.t("donations.donationsViewer.status.deleted");
  }
  return statusLabel;
};

export const checkPrice = (value: number, currency: string) => {
  let currencySymbol = currency;
  if (currency === "EUR") {
    currencySymbol = "€";
  }
  return value + currencySymbol;
};
