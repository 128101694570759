import { Button, MobileTriggerButton, Popup, Toast } from "app/shared";
import { AuthUserContext } from "authentication/session";
import i18next from "i18next";
import debounce from "lodash.debounce";
import React, { Component } from "react";
import { Trans } from "react-i18next";
import { BoxProps, Text } from "rebass";
import { getCollection, updateCollection, deleteCollections } from "./services";

interface Props extends BoxProps {
  documentIds: number[];
  collectionId: number;
  mobileView?: boolean;
  hideTrigger?: boolean;
  open?: boolean;
  onClose?: () => void;
  onOpen?: () => void;
}

interface State {
  disabled: boolean;
}

export class RemoveCollectionPhotosButton extends Component<Props, State> {
  state = {
    disabled: false
  };

  deleteCollections() {
    const { collectionId } = this.props;
    deleteCollections([collectionId]).catch(error => {
      Toast.apiError(error);
      this.setState({ disabled: false });
    });
  }

  removeCollectionPhotos(close: () => void) {
    const { documentIds, collectionId } = this.props;
    if (documentIds.length && collectionId !== undefined) {
      this.setState({ disabled: true }, async () => {
        updateCollection(
          collectionId,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          {
            mode: "remove",
            list: documentIds
          }
        )
          .then(() => {
            // Waiting a few for backend re-index changes.
            // TODO: This can be replaced if we manually change affected elements locally.
            Toast.success({
              title: {
                key: "collections.removeCollectionPhotosButton.removeSuccess",
                options: { value: documentIds.length }
              }
            });
            let afterDelete: boolean | string = true;
            getCollection(collectionId).then(collection => {
              if (collection.documents.length === 0) {
                deleteCollections([collectionId]);
                afterDelete = `/users/${collection.owner.id}/collections`;
              }
            });
            close();
            debounce(
              () =>
                this.setState({ disabled: false }, () =>
                  afterDelete === true
                    ? window.location.reload()
                    : (document.location.href = afterDelete.toString())
                ),
              1000
            )();
          })
          .catch(error => {
            Toast.apiError(error);
            this.setState({ disabled: false });
          });
      });
    }
  }

  render() {
    const { disabled } = this.state;
    const {
      documentIds,
      mobileView,
      collectionId,
      hideTrigger,
      open,
      onClose,
      onOpen,
      ...rest
    } = this.props;
    const buttonEnabled =
      documentIds.length !== 0 && collectionId !== undefined;

    return (
      <Popup
        trigger={
          hideTrigger ? (
            undefined
          ) : mobileView ? (
            <Button variant="buttons.mobileButton">
              <MobileTriggerButton
                iconName="RemoveFrom"
                disabled={disabled || !buttonEnabled}
                buttonNameI18n="collections.removeCollectionPhotosButton.removeFromCollection"
              />
            </Button>
          ) : (
            <Button
              width="100%"
              disabled={disabled || !buttonEnabled}
              {...(rest as any)}>
              <Text fontSize={[1, 2, 3]}>
                <Trans i18nKey="collections.removeCollectionPhotosButton.removeFromCollection" />
              </Text>
            </Button>
          )
        }
        open={open}
        heading={i18next.t(
          "collections.removeCollectionPhotosButton.removePhotos"
        )}
        submit={close => this.removeCollectionPhotos(close)}
        submitText={i18next.t(
          "collections.removeCollectionPhotosButton.confirm"
        )}
        submitDisabled={disabled}
        cancelText={i18next.t(
          "collections.removeCollectionPhotosButton.cancel"
        )}
        cancelDisabled={disabled}
        disabled={!buttonEnabled}
        onClose={onClose}
        onOpen={onOpen}>
        <>
          <AuthUserContext.Consumer>
            {({ authUser }) =>
              authUser && (
                <Text textAlign="center">
                  {i18next.t(
                    "collections.removeCollectionPhotosButton.popupText",
                    {
                      value: documentIds.length
                    }
                  )}
                </Text>
              )
            }
          </AuthUserContext.Consumer>
        </>
      </Popup>
    );
  }
}
