import React from "react";
import Svg, { SvgProps } from "primitives/Svg";

const SvgClaps = ({ width, height, size, color }: SvgProps) => (
  <Svg
    width={size || width}
    height={size || height}
    viewBox="0 0 21 22"
    color={color}>
    <path d="M19.768 7.936v-1.88c0-.476-.192-.923-.54-1.258a1.87 1.87 0 00-1.305-.521c-1.018 0-1.846.797-1.846 1.778v.28l-2.832-2.729a1.877 1.877 0 00-2.581 0 1.748 1.748 0 00-.38.536 1.901 1.901 0 00-2.216.289c-.324.312-.513.72-.538 1.158a1.864 1.864 0 00-1.202.518c-.325.313-.513.721-.538 1.159a1.864 1.864 0 00-1.202.518 1.735 1.735 0 000 2.515l.51.491c-.185.085-.358.2-.51.347a1.735 1.735 0 000 2.515l5.8 5.59a3.96 3.96 0 002.755 1.097c.998 0 1.996-.366 2.755-1.098l4.44-4.277c.426-.41.66-.957.66-1.537V9.612c0-.772-.514-1.431-1.23-1.676zm-8.234-3.492a.605.605 0 01.42-.167c.152 0 .305.056.42.167l3.883 3.741c.176.17.44.22.67.129a.593.593 0 00.38-.548v-1.71c0-.328.277-.594.616-.594.164 0 .319.062.435.174.116.112.18.26.18.42v1.88c-.716.245-1.23.904-1.23 1.676v1.206l-5.774-5.563a.56.56 0 010-.81zm-2.596.825a.633.633 0 01.87 0l.51.491c-.185.085-.358.2-.51.347a1.769 1.769 0 00-.36.492l-.51-.492a.578.578 0 010-.838zm10.83 8.158a.964.964 0 01-.3.698l-4.44 4.278a2.741 2.741 0 01-3.77 0l-3.19-3.074-2.61-2.515a.578.578 0 010-.838.633.633 0 01.87 0l2.61 2.515c.24.231.63.231.87 0a.578.578 0 000-.839l-4.35-4.191a.578.578 0 010-.839.623.623 0 01.435-.173c.164 0 .318.061.435.173l4.35 4.192a.634.634 0 00.87 0 .578.578 0 000-.838l-4.35-4.192a.578.578 0 010-.838.632.632 0 01.87 0l1.74 1.676 2.61 2.515a.634.634 0 00.87 0 .578.578 0 000-.838l-2.61-2.515a.578.578 0 010-.838.623.623 0 01.435-.174c.165 0 .319.062.435.174l2.61 2.515 3.33 3.208c.176.17.44.22.67.128a.593.593 0 00.38-.548V9.612c0-.327.276-.592.615-.592.34 0 .615.265.615.592v3.815zM5.537 4.651c.34 0 .615-.265.615-.592V2.162a.604.604 0 00-.615-.593.604.604 0 00-.615.593v1.897c0 .327.275.592.615.592zM2.238 2.889a.632.632 0 00-.87 0 .577.577 0 000 .838L2.844 5.15c.12.115.278.173.435.173a.625.625 0 00.435-.173.577.577 0 000-.839L2.238 2.89zM2.584 6.311H.615A.604.604 0 000 6.904c0 .328.275.593.615.593h1.969c.34 0 .615-.265.615-.593a.604.604 0 00-.615-.593z" />
  </Svg>
);

SvgClaps.defaultProps = {
  width: 24,
  height: 24
};
export default SvgClaps;
