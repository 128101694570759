import React from "react";
import Svg, { SvgProps } from "primitives/Svg";

const SvgClipboard = ({ width, height, size, color }: SvgProps) => (
  <Svg
    width={size || width}
    height={size || height}
    viewBox="0 0 56 55"
    color={color}>
    <path d="M23.538 42.005l-6.43 6.43a6.818 6.818 0 01-9.647 0 6.82 6.82 0 010-9.645l12.862-12.862a6.819 6.819 0 019.645 0 2.274 2.274 0 003.215-3.216c-4.44-4.44-11.636-4.44-16.076 0L4.245 35.575c-4.44 4.44-4.44 11.636 0 16.075 4.439 4.442 11.636 4.442 16.078 0l6.43-6.43a2.274 2.274 0 00-3.215-3.215z" />
    <path d="M52.154 3.744c-4.44-4.44-11.638-4.44-16.078 0l-7.714 7.714a2.274 2.274 0 003.215 3.216l7.715-7.715a6.822 6.822 0 019.647 0 6.82 6.82 0 010 9.645L34.792 30.75a6.819 6.819 0 01-9.645 0 2.274 2.274 0 00-3.215 3.216c4.44 4.44 11.636 4.44 16.075 0L52.154 19.82c4.44-4.44 4.44-11.637 0-16.076z" />
  </Svg>
);

SvgClipboard.defaultProps = {
  width: 24,
  height: 24
};
export default SvgClipboard;
