import React from "react";
import { Box } from "rebass";
import JsxParser from "react-jsx-parser";
import {
  Button,
  Bucket,
  Collection,
  ExploreBuckets,
  ExploreCollections,
  ExploreUsers,
  ExplorePhotos,
  Grid,
  Document
} from "./components";

interface Props {
  text: string;
  components?: any; // override components for custom rendering
}

/**
 * Docs: https://github.com/TroyAlford/react-jsx-parser
 *
 * Notes:
 * - Fix disableKeyGeneration={true} is throwing "Each child in a list should have a unique "key" prop"
 */
export const Parser: React.FC<Props> = ({ text, components }) => (
  <Box
    sx={{
      "& > :first-child": { marginTop: 0 }
    }}>
    <JsxParser
      components={components}
      jsx={text}
      allowUnknownElements={false}
      componentsOnly={true}
      renderInWrapper={false}
      showWarnings={true}
      disableKeyGeneration={true}
      onError={(err: any) => console.error("JsxParser", err)}
    />
  </Box>
);

Parser.defaultProps = {
  components: {
    Button,
    Bucket,
    Collection,
    ExploreBuckets,
    ExploreCollections,
    ExploreUsers,
    ExplorePhotos,
    Grid,
    Document
  }
};
