import { Button, Icon } from "app/shared";
import React from "react";
import { Box, BoxProps, Text, Flex } from "rebass";
import { Trans } from "react-i18next";

interface Props extends BoxProps {
  href?: string;
  i18n?: string;
  onClick?: () => void;
  title?: string;
  mb?: string | string[] | number | number[];
}

export const BackButton: React.FC<Props> = ({
  i18n,
  href,
  onClick,
  title,
  mb
}) => {
  return (
    <Box mb={mb ?? 3} ml={1} sx={{ "& path": { stroke: "black" } }}>
      <Text variant="grotTextCaps">
        <Button
          variant="transparent"
          href={href}
          onClick={href ? void 0 : onClick}>
          <Flex alignItems="center">
            <Flex pr={3}>
              <Icon name="ArrowLeft" size={18} />
            </Flex>
            <Flex color="black">
              {!title ? <Trans i18nKey={i18n} /> : <Text>{title}</Text>}
            </Flex>
          </Flex>
        </Button>
      </Text>
    </Box>
  );
};

BackButton.defaultProps = {
  i18n: "backButton.defaultTitle",
  onClick: () => window.history.back()
};
