import React, { ReactNode } from "react";
import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip
} from "recharts";

export interface Point {
  x: number;
  y: number;
  xAxisLabel: string;
  data?: any;
}

interface Props {
  data: Point[];
  tooltipRender?: (point: Point) => ReactNode;
  tickFormatterX?: (value: string) => string;
  tickFormatterY?: (value: string) => string;
  height?: number;
  lineColor?: string;
  dotActive?: boolean;
  dotColor?: string;
}

/**
 * Docs: http://recharts.org/en-US/api/LineChart
 */
export const Graph: React.FC<Props> = ({
  data,
  height,
  tooltipRender,
  tickFormatterX,
  tickFormatterY,
  lineColor,
  dotActive,
  dotColor
}) => (
  <ResponsiveContainer height={height}>
    <LineChart data={data}>
      <CartesianGrid vertical={false} stroke="#000" />
      <XAxis
        dataKey="xAxisLabel"
        height={30}
        stroke="#000"
        type="category"
        tickCount={3}
        tickLine={false}
        tickMargin={10}
        minTickGap={100}
        tickFormatter={tickFormatterX}
      />
      <YAxis
        width={30}
        stroke="#000"
        axisLine={false}
        tickCount={3}
        tickFormatter={tickFormatterY}
      />
      <Tooltip
        content={(props: any) =>
          props?.active && tooltipRender?.(props?.payload?.[0]?.payload)
        } // using [0] because only one series is being supported in this component
      />
      <Line
        type="monotone"
        dataKey="y"
        dot={false}
        activeDot={dotActive && { stroke: dotColor }}
        stroke={lineColor}
      />
    </LineChart>
  </ResponsiveContainer>
);

Graph.defaultProps = {
  height: 300,
  lineColor: "#000",
  dotActive: true,
  dotColor: "#0000FF"
};
