import { Button } from "app/shared";
import React from "react";
import { Box } from "rebass";
import { getRaw } from "../utils";
import { PopupResult } from "./PopupResult";
import i18next from "i18next";
import { getLocationLabel } from "app/shared/locationLabel/utils";
import { formatToNumberDate } from "app/shared/formattedDate/utils";
import { connect } from "react-redux";
import { compose } from "recompose";
import { ISOLanguageCode } from "i18n/resources/supportedLanguages";

interface PropsExternal {
  results: any[];
  open: boolean;
  close?: () => void;
}

interface Props extends PropsExternal {
  language: ISOLanguageCode;
}

const PopupResultsBase: React.FC<Props> = ({
  results,
  open,
  close,
  language
}) => {
  if (!open) return null;

  return (
    <Box
      p={3}
      width="100%"
      height="100%"
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        background: "rgba(0, 0, 0, 0.5)"
      }}
      onClick={close}>
      <Box
        p={5}
        width="100%"
        height="100%"
        backgroundColor="#fff"
        sx={{ position: "relative", overflowY: "auto" }}>
        <Button
          color="text.default"
          onClick={close}
          px={2}
          py={1}
          sx={{
            position: "absolute",
            top: 0,
            right: 0
          }}>
          X
        </Button>
        <Box
          sx={{
            "& > *": { marginBottom: "20px !important" },
            "& > :last-child": { marginBottom: "0 !important" }
          }}>
          {results.map((result, index) => {
            const id = getRaw(result, "real_id") ?? "";
            const className = getRaw(result, "class_name") ?? "";
            const link = `/${className}s/${id}`;
            const location =
              getLocationLabel(
                getRaw(result, "city"),
                getRaw(result, "country"),
                false
              ) ?? "";
            let thumbnail = undefined;
            let title = "";
            let firstColumnLabel = "";
            let firstColumnText = "";
            let secondColumnLabel = "";
            let secondColumnText = "";

            // Parse results depending on the className
            if (className === "document") {
              thumbnail = getRaw(result, "thumbnail");
              title = ((getRaw(
                result,
                "translations"
              ) as unknown) as string[])?.[0]?.split("=")?.[1];
              firstColumnLabel = i18next.t(
                "search.locations.popupResults.location"
              );
              firstColumnText = location;
              secondColumnLabel = i18next.t(
                "search.locations.popupResults.date"
              );
              secondColumnText = formatToNumberDate(
                getRaw(result, "taken_at") ?? "",
                language,
                false
              );
            } else if (className === "event") {
              thumbnail = ((getRaw(
                result,
                "documents"
              ) as unknown) as string[])?.[0]?.split("=")?.[1];
              title = getRaw(result, "title") ?? "";
              firstColumnLabel = i18next.t(
                "search.locations.popupResults.location"
              );
              firstColumnText = location;
              secondColumnLabel = i18next.t(
                "search.locations.popupResults.date"
              );
              secondColumnText = formatToNumberDate(
                getRaw(result, "date") ?? "",
                language,
                false
              );
            } else if (className === "user") {
              thumbnail = getRaw(result, "picture");
              title = getRaw(result, "professional_name") ?? "";
              firstColumnLabel = i18next.t(
                "search.locations.popupResults.skills"
              );
              firstColumnText =
                ((getRaw(result, "skills") as unknown) as string[])
                  ?.map(it =>
                    i18next.t(`account.editMyInfo.about.skills.${it}`)
                  )
                  ?.join(", ") ?? "-";
              secondColumnLabel = i18next.t(
                "search.locations.popupResults.location"
              );
              secondColumnText = location;
            }

            return (
              <PopupResult
                key={index}
                link={link}
                title={title}
                firstColumnLabel={firstColumnLabel}
                firstColumnText={firstColumnText}
                secondColumnLabel={secondColumnLabel}
                secondColumnText={secondColumnText}
                thumbnail={thumbnail}
              />
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

const mapStateToProps = (state: any) => ({
  language: state.language.code
});

export const PopupResults = compose<Props, PropsExternal>(
  connect(mapStateToProps)
)(PopupResultsBase);
