import { Button, Icon } from "app/shared";
import React, { ReactNode, useState } from "react";
import { Trans } from "react-i18next";
import { Box, Flex, Text } from "rebass";

interface Props {
  titleI18n: string;
  children: ReactNode;
  variant?: "blue" | "white";
  px?: number | number[];
  py?: number | number[];
  id?: string;
  collapsed?: boolean;
}

export const Section: React.FC<Props> = ({
  titleI18n,
  children,
  px,
  py,
  variant,
  id,
  collapsed
}) => {
  const [isCollapsed, setCollapsed] = useState(collapsed);

  return (
    <Box id={id} my={1}>
      <Button
        variant="transparent"
        width="100%"
        backgroundColor={variant}
        px={px}
        py={py}
        onClick={() => setCollapsed(!isCollapsed)}
        sx={{
          borderBottom: variant === "white" ? 1 : 0,
          borderColor: "blue"
        }}>
        <Flex
          width="100%"
          alignItems="center"
          justifyContent="space-between"
          sx={{ "& path": { stroke: variant === "blue" ? "white" : "blue" } }}>
          <Text
            fontSize={[6]}
            variant="grotText"
            color={variant === "blue" ? "white" : "black"}
            textAlign="left">
            <Trans i18nKey={titleI18n} />
          </Text>
          {isCollapsed ? (
            <Icon name="ArrowUp" size={32} />
          ) : (
            <Icon name="ArrowDown" size={32} />
          )}
        </Flex>
      </Button>
      {/* Using display=none so that it is still crawable when not collapsed */}
      <Box px={px} py={py} display={!isCollapsed ? "none" : undefined}>
        {children}
      </Box>
    </Box>
  );
};

Section.defaultProps = {
  variant: "blue",
  px: 4,
  py: 2
};
