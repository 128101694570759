import { Box } from "rebass";
import React, { ReactNode } from "react";

interface Props {
  children: ReactNode;
  width?: string;
}

/**
 * This component allows the component to go outside parent width
 */
export const FullWidthContainer: React.FC<Props> = ({ children, width }) => (
  <Box
    width={width}
    ml={`calc(${width}/-2)`}
    mr={`calc(${width}/-2)`}
    sx={{
      position: "relative",
      left: "50%",
      right: "50%"
    }}>
    {children}
  </Box>
);

FullWidthContainer.defaultProps = {
  width: "100vw"
};
