import React from "react";
import Svg, { SvgProps } from "primitives/Svg";

const SvgSubmitArrow = ({ width, height, size, color }: SvgProps) => (
  <Svg
    width={size || width}
    height={size || height}
    viewBox="0 0 24 24"
    color={color}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 3H3v18h18v-7h-1v6H4V4h6V3zm4 0v1h5.293l-7.647 7.646.708.708L20 4.707V10h1V3h-7z"
    />
  </Svg>
);

SvgSubmitArrow.defaultProps = {
  width: 24,
  height: 24
};
export default SvgSubmitArrow;
