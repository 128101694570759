import { FullWidthContainer } from "app/shared";
import React from "react";
import { Box } from "rebass";
import FVertical from "icons/svg/FVertical.svg";
import FVerticalBlack from "icons/svg/FVerticalBlack.svg";
import Fup from "icons/svg/Fup.svg";
import FDown from "icons/svg/FDown.svg";
import Logo from "app/shared/siteImageButton/static/Logo.svg";

export const Separator: React.FC = () => {
  return (
    <>
      <FullWidthContainer>
        <Box
          sx={{
            backgroundImage: `url(${FVertical})`,
            backgroundRepeat: "repeat-x",
            width: "100%",
            height: "24px"
          }}
          mb={1}
        />

        <Box
          sx={{
            backgroundImage: `url(${FVerticalBlack})`,
            backgroundRepeat: "repeat-x",
            width: "100%",
            height: "24px"
          }}
          mb={1}
        />

        <Box
          sx={{
            backgroundImage: `url(${Fup})`,
            backgroundRepeat: "repeat-x",
            width: "100%",
            height: "16px"
          }}
        />

        <Box
          sx={{
            backgroundImage: `url(${Fup})`,
            backgroundRepeat: "repeat-x",
            width: "100%",
            height: "16px"
          }}
        />

        <Box
          sx={{
            backgroundImage: `url(${Fup})`,
            backgroundRepeat: "repeat-x",
            width: "100%",
            height: "16px"
          }}
        />

        <Box
          sx={{
            backgroundImage: `url(${FDown})`,
            backgroundRepeat: "repeat-x",
            width: "100%",
            height: "16px"
          }}
        />

        <Box
          sx={{
            backgroundImage: `url(${FDown})`,
            backgroundRepeat: "repeat-x",
            width: "100%",
            height: "16px"
          }}
        />

        <Box
          sx={{
            backgroundImage: `url(${FDown})`,
            backgroundRepeat: "repeat-x",
            width: "100%",
            height: "16px"
          }}
        />

        <Box
          sx={{
            backgroundImage: `url(${Logo})`,
            backgroundRepeat: "repeat space",
            width: "100%",
            height: "96px"
          }}
        />

        <Box
          sx={{
            backgroundImage: `url(${FVertical})`,
            backgroundRepeat: "repeat-x",
            width: "100%",
            height: "24px"
          }}
          my={1}
        />

        <Box
          sx={{
            backgroundImage: `url(${FVerticalBlack})`,
            backgroundRepeat: "repeat-x",
            width: "100%",
            height: "24px"
          }}
          mb={1}
        />
      </FullWidthContainer>
    </>
  );
};
