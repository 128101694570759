import { ShareButton } from "app/shared";
import React from "react";
import { Bucket } from "models";
import { BoxProps } from "rebass";
import { shareBucket } from "../services";

interface Props extends BoxProps {
  bucket: Bucket;
  variant?: string;
  onOpen?: () => void;
  onClose?: () => void;
  open?: boolean;
  hideTrigger?: boolean;
}

/**
 * TODO:
 * - Generate unique ID to send for anonymous users.
 */
export const BucketShareButton: React.FC<Props> = ({
  bucket,
  variant,
  onOpen,
  onClose,
  open,
  hideTrigger,
  ...rest
}) => {
  const onOpenPopup = () => {
    shareBucket(bucket.id).catch(error => console.error("Share error", error));
  };

  return (
    <ShareButton
      variant={variant}
      open={open}
      hideTrigger={hideTrigger}
      onOpen={() => {
        onOpen?.();
        onOpenPopup();
      }}
      onClose={() => onClose?.()}
      path={`/buckets/${bucket.id}`}
      photo={bucket.documents?.[0]?.thumbnail}
      {...(rest as any)}
    />
  );
};
