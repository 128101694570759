import React from "react";
import i18next from "i18next";
import { Icon, Popup } from "app/shared";
import { Trans } from "react-i18next";
import { Text, Box, Flex } from "rebass";

interface Props {
  onSkip: (close: () => void) => void;
  onComplete: (close: () => void) => void;
  trigger?: any;
  open?: boolean;
}

export const BecomeVerifiedPopup: React.FC<Props> = ({
  trigger,
  open,
  onSkip,
  onComplete
}) => (
  <Popup
    open={open}
    trigger={trigger}
    contentPadding={0}
    heading={i18next.t("account.popups.becomeVerifiedPopup.heading")}
    cancel={close => onSkip(close)}
    cancelText={i18next.t("account.popups.becomeVerifiedPopup.cancel")}
    submit={close => onComplete(close)}
    submitText={i18next.t("account.popups.becomeVerifiedPopup.submit")}>
    <>
      <Box>
        <Text textAlign="center" p={2} variant="grotText">
          <Trans i18nKey="account.popups.becomeVerifiedPopup.contentHeader" />
        </Text>
        <Text textAlign="center" p={2} color="blue" variant="grotText">
          <Trans i18nKey="account.popups.becomeVerifiedPopup.content1" />
        </Text>
        <Text
          textAlign="center"
          px={2}
          pb={8}
          pt={2}
          color="blue"
          variant="grotText">
          <Trans i18nKey="account.popups.becomeVerifiedPopup.content2" />
        </Text>
      </Box>
      <Flex justifyContent="space-around" my={3}>
        <Flex alignItems="center" flexDirection="column">
          <Icon name="Fingerprint" size={30} />
          <Text p={2} fontSize={[0, 3]} variant="caps">
            <Trans i18nKey="account.popups.becomeVerifiedPopup.verifyIdentity" />
          </Text>
        </Flex>
        <Flex alignItems="center" flexDirection="column">
          <Icon name="User3" size={30} />
          <Text p={2} fontSize={[0, 3]} variant="caps">
            <Trans i18nKey="account.popups.becomeVerifiedPopup.userType" />
          </Text>
        </Flex>
        <Flex alignItems="center" flexDirection="column">
          <Icon name="Megaphone" size={30} />
          <Text p={2} fontSize={[0, 3]} variant="caps">
            <Trans i18nKey="account.popups.becomeVerifiedPopup.promoTools" />
          </Text>
        </Flex>
        <Flex alignItems="center" flexDirection="column">
          <Icon name="Family" size={30} />
          <Text p={2} fontSize={[0, 3]} variant="caps">
            <Trans i18nKey="account.popups.becomeVerifiedPopup.sharingArea" />
          </Text>
        </Flex>
      </Flex>
      <Text
        px={2}
        pb={2}
        textAlign="center"
        fontSize={0}
        variant="grotTextCaps"
        color="footerGrey.0">
        <Trans i18nKey="account.popups.becomeVerified.contentFooter" />
      </Text>
    </>
  </Popup>
);
