import { CollectionsListSearch } from "app/search/collections/CollectionsListSearch";
import React from "react";
import { Trans } from "react-i18next";
import { Box, Text, Flex } from "rebass";
import { Button } from "app/shared";

interface Props {
  order: "recent";
  anchor?: string;
  marginTop?: number | number[];
}

const ExploreCollectionsParser: React.FC<Props> = ({
  order,
  anchor,
  marginTop
}) => (
  <Box id={anchor} marginTop={marginTop}>
    <Flex
      alignItems="center"
      justifyContent="space-between"
      mb={3}
      pb={[2, 2, 0]}
      sx={{ borderBottom: 1, borderColor: "black.0" }}>
      <Text fontSize={[3, 8, 14]} variant="grotTextCaps" fontWeight="bold">
        <Trans i18nKey="pages.parser.exploreCollectionsParser.exploreCollections" />
      </Text>
      <Button
        ml={3}
        variant="link.button"
        sx={{ borderColor: "black.0" }}
        href="/search/collection">
        <Trans i18nKey="pages.parser.exploreCollectionsParser.viewAll" />
      </Button>
    </Flex>
    <CollectionsListSearch
      filters={[{ field: "privacy", values: ["public"], type: "any" }]}
      resultsPerPage={4}
      scrollListenerEnabled={false}
      sortField={order === "recent" ? "real_id" : undefined}
      sortDirection={order === "recent" ? "desc" : undefined}
      noResultsView={<></>}
    />
  </Box>
);

ExploreCollectionsParser.defaultProps = {
  marginTop: 0
};

export default ExploreCollectionsParser;
