import { Button, PageEmptyState, Popup, Toast, Checkbox } from "app/shared";
import { MonetaryText } from "app/shared/monetaryText";
import environment from "configurations";
import i18next from "i18next";
import { Currency, MonetaryAmount, Transaction } from "marketplace";
import React, { Component } from "react";
import { Trans } from "react-i18next";
import {
  CardElement,
  Elements,
  injectStripe,
  StripeProvider
} from "react-stripe-elements";
import { Box, Flex, Heading, Text } from "rebass";
import store from "store";
import { createTransaction } from "../services";
import { CheckoutItem } from "./models";
import * as actions from "./reducers/actions";
import { Link } from "i18n/components";

interface PropsExternal {
  checkout: CheckoutItem[];
  onCancel: () => void;
  onComplete: (transaction: Transaction) => void;
}

interface Props extends PropsExternal {
  stripe: any;
}

interface State {
  disabled: boolean;
  totalAmount: MonetaryAmount;
  termsAccepted: boolean;
}

class SelectPaymentForm extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      disabled: false,
      totalAmount: this.sumTotalAmount(props.checkout),
      termsAccepted: false
    };
  }

  sumTotalAmount = (checkout: CheckoutItem[]): MonetaryAmount => {
    const amounts: MonetaryAmount[] = checkout.map(
      checkoutItem =>
        new MonetaryAmount(
          checkoutItem.licensePrice.basePrice.value,
          checkoutItem.licensePrice.basePrice.currency,
          checkoutItem.licensePrice.basePrice.precision
        )
    );
    const currency: Currency = amounts[0].getCurrency();
    const precision: number = amounts[0].getPrecision();

    //nonNull checkout items
    return amounts.reduce(
      (accumulator: MonetaryAmount, currentValue: MonetaryAmount) =>
        accumulator.add(currentValue),
      new MonetaryAmount(0, currency, precision)
    );
  };

  /**
   * When totalAmount > 0 stripe is used to generate card token to pay for the transaction.
   */
  async pay(close: () => void) {
    const { checkout, onComplete, stripe } = this.props;
    const { totalAmount } = this.state;

    this.setState({ disabled: true }, async () => {
      try {
        let paymentSource: string | undefined = undefined;

        // Only generate token if totalAmount > 0
        if (totalAmount.getAmount() > 0) {
          const { token, error } = await stripe.createToken();

          if (error) {
            throw error;
          } else {
            paymentSource = token?.id;
          }
        }

        // Prepare items
        const items = checkout.map(it => ({
          documentId: it.documentId,
          license: it.licensePrice.license,
          licenseConfiguration: it.licenseConfiguration
        }));

        // totalChargeAmount is sent to backend to check if value presented matches with the one calculated in the backend.
        const transaction = await createTransaction(
          items,
          {
            value: totalAmount.getAmount(),
            currency: totalAmount.getCurrency(),
            precision: totalAmount.getPrecision()
          },
          environment.paymentProvider,
          paymentSource
        );

        // Complete page
        store.dispatch(actions.clearCheckoutItems());
        close();
        onComplete(transaction);
      } catch (error) {
        Toast.apiError(error);
        this.setState({ disabled: false });
      }
    });
  }

  render() {
    const { onCancel, checkout } = this.props;
    const { disabled, totalAmount, termsAccepted } = this.state;

    return (
      <Box>
        {totalAmount.getAmount() > 0 ? (
          <>
            <Text textAlign="center" mb={3}>
              <Trans i18nKey="documents.selectPayment.confirmPaymentDetails" />
            </Text>
            <Box
              width={["100%", null, "500px"]}
              margin="auto"
              px={4}
              py={3}
              sx={{
                border: "1px solid #000"
              }}>
              <Heading mb={3}>
                <Trans i18nKey="documents.selectPayment.paymentInfo" />
              </Heading>
              <Text mb={3}>
                <Trans i18nKey="documents.selectPayment.paymentMethodProcess" />
              </Text>
              <CardElement />
            </Box>
          </>
        ) : (
          <PageEmptyState
            header={<Trans i18nKey="documents.selectPayment.free" />}
          />
        )}
        <Flex mt={3}>
          <Button display="block" width="100%" p={3} onClick={() => onCancel()}>
            <Text fontWeight="bold">
              <Trans i18nKey="documents.selectPayment.previous" />
            </Text>
          </Button>
          <Box width="100%">
            <Popup
              trigger={
                <Button
                  display="block"
                  width="100%"
                  variant="black"
                  disabled={disabled || checkout.length === 0}
                  p={3}>
                  <MonetaryText
                    monetaryAmount={totalAmount}
                    i18n={"documents.selectPayment.pay"}
                    sx={{ fontWeight: "bold" }}
                  />
                </Button>
              }
              variant="full-buttons"
              contentPadding={0}
              heading={i18next.t("documents.selectPayment.confirmPurchase")}
              submit={close => this.pay(close)}
              onClose={() => this.setState({ termsAccepted: false })}
              submitText={i18next.t("documents.selectPayment.popupConfirm")}
              submitDisabled={disabled || !termsAccepted}
              cancelText={i18next.t("documents.selectPayment.popupCancel")}
              cancelDisabled={disabled}
              disabled={checkout.length === 0}>
              <>
                <MonetaryText
                  monetaryAmount={totalAmount}
                  sx={{
                    textAlign: "center",
                    borderBottom: 1,
                    borderColor: "black.0",
                    px: 2,
                    py: 4
                  }}
                  i18n={"documents.selectPayment.popupText"}
                  i18nOptions={{ numberOfItems: checkout.length }}
                />
                <Checkbox
                  name={"confirmation"}
                  checked={termsAccepted}
                  onChange={() =>
                    this.setState({ termsAccepted: !termsAccepted })
                  }
                  p={3}
                  align="flex-end"
                  direction="row">
                  <Text fontSize={1} variant="caps">
                    <Trans
                      i18nKey="documents.selectPayment.terms"
                      components={[
                        <Link href="/about#terms-conditions">Translation</Link>,
                        <Link href="/about#licenses">Translation</Link>
                      ]}
                    />
                  </Text>
                </Checkbox>
              </>
            </Popup>
          </Box>
        </Flex>
      </Box>
    );
  }
}

const InjectedSelectPaymentForm = injectStripe(SelectPaymentForm as any);

export const SelectPayment: React.FC<PropsExternal> = props => {
  return (
    <StripeProvider apiKey={environment.stripeKey}>
      <Elements>
        <InjectedSelectPaymentForm {...props} />
      </Elements>
    </StripeProvider>
  );
};
