import { Image } from "app/shared";
import React from "react";
import { Flex, ImageProps, SxStyleProp } from "rebass";
import NoImage from "./static/NoImage.svg";
import User from "../../../../src/icons/svg/User.svg";
import SimpleLogo from "../../../../src/icons/svg/FFSimpleLogo.svg";

interface Props extends ImageProps {
  src?: string;
  width?: string | string[];
  height?: string | string[];
  sx?: SxStyleProp;
  mode?: "user" | "image" | "ff";
}

export const BackgroundImage: React.FC<Props> = ({
  src,
  width,
  height,
  mode,
  sx,
  ...rest
}) => (
  <Flex
    width={width}
    height={height}
    justifyContent="center"
    alignItems="center"
    sx={{
      backgroundImage: `url(${src}), url(${
        mode === "user" ? User : mode === "ff" ? SimpleLogo : ""
      })`,
      backgroundSize: mode === "ff" ? "" : "cover",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      border: src?.length ? 0 : 1,
      borderColor: "black.0",
      ...sx
    }}
    {...(rest as any)}>
    {!src?.length && <Image src={NoImage} maxWidth="90%" />}
  </Flex>
);

BackgroundImage.defaultProps = {
  width: "100%",
  height: "100%",
  mode: "image"
};
