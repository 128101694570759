import i18next, { TOptions } from "i18next";
import { MonetaryAmount, Price } from "marketplace";
import React from "react";
import { SxStyleProp, Text } from "rebass";

const formatMonetaryText = (
  monetaryAmount: MonetaryAmount,
  i18n?: string,
  options?: TOptions
): string => {
  const priceLocaleString = monetaryAmount.toLocaleString();

  if (i18n) {
    //i18n translations for monetaryText need {{price}} on translations file
    const priceOption = { price: priceLocaleString };
    const i18nOptions = { ...options, ...priceOption };

    return i18next.t(i18n, i18nOptions);
  } else {
    return priceLocaleString;
  }
};

const createOrUseProvidedMonetaryAmount = (
  monetaryAmount?: MonetaryAmount,
  price?: Price,
  i18n?: string,
  options?: TOptions
): string => {
  let monetaryText = "";

  if (monetaryAmount) {
    monetaryText = formatMonetaryText(monetaryAmount, i18n, options);
  } else if (price) {
    monetaryText = formatMonetaryText(
      new MonetaryAmount(price.value, price.currency, price.precision),
      i18n,
      options
    );
  }

  return monetaryText;
};

interface Props {
  price?: Price;
  monetaryAmount?: MonetaryAmount;
  sx?: SxStyleProp;
  i18n?: string;
  i18nOptions?: TOptions;
  fontSize?: number[] | number;
}

export const MonetaryText: React.FC<Props> = ({
  monetaryAmount,
  price,
  i18n,
  i18nOptions,
  fontSize,
  ...rest
}) => (
  <Text fontSize={fontSize} {...(rest as any)}>
    {createOrUseProvidedMonetaryAmount(
      monetaryAmount,
      price,
      i18n,
      i18nOptions
    )}
  </Text>
);

MonetaryText.defaultProps = {
  fontSize: [0, 1, 2, 3]
};
