import i18next from "i18next";
import { LicensePrice } from "marketplace";
import { Document } from "models";
import React from "react";
import { Box, Text } from "rebass";
import store from "store";
import * as actions from "../checkout/reducers/actions";
import { Toast } from "app/shared";

interface Props {
  document: Document;
  licensePrice?: LicensePrice;
}

const GetPhotoButton: React.FC<Props> = ({ document, licensePrice }) => {
  const handleOnClick = () => {
    if (licensePrice) {
      store.dispatch(
        actions.addCheckoutItem({
          documentId: document.id,
          licensePrice,
          thumbnail: document.thumbnail
        })
      );
      Toast.success({
        title: { key: "documents.documentDetails.getPhotoButton.toastTitle" },
        button: {
          title: {
            key: "documents.documentDetails.getPhotoButton.toastButtonLabel"
          },
          href: "/checkout"
        }
      });
    }
  };
  return (
    <Box
      backgroundColor="brand.0"
      sx={{
        textAlign: "center",
        cursor: licensePrice ? "pointer" : undefined,
        opacity: licensePrice ? 1 : 0.3
      }}
      onClick={licensePrice ? handleOnClick : undefined}>
      <Text
        fontSize={[1, 2, 3]}
        color="white.0"
        variant="grotDisplayCaps"
        pl={[1, 2, 3]}
        my={2}>
        {i18next.t("documents.documentDetails.getPhotoButton.getPhoto")}
      </Text>
    </Box>
  );
};

export default GetPhotoButton;
