import * as type from "./constants";
import {
  SupportedLanguages,
  ISOLanguageCode
} from "i18n/resources/supportedLanguages";

interface State {
  code: ISOLanguageCode;
}

const loadState = (): State => {
  const localStorageValue = localStorage.getItem("i18nextLng");

  // Check if valid ISOLanguageCode and return en if none found
  if (SupportedLanguages.find(it => it.code === localStorageValue)) {
    return {
      code: localStorageValue as ISOLanguageCode
    };
  } else {
    return {
      code: SupportedLanguages[0].code
    };
  }
};

const saveState = (state: State): State => {
  localStorage.setItem("i18nextLng", state.code);
  return state;
};

const initialState: State = loadState();

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case type.SET_LANGUAGE: {
      return saveState({ code: action.code });
    }
    default: {
      return state;
    }
  }
};

export default reducer;
