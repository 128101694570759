import MessageService, { Chat } from "messaging/MessageService";
import { AuthUser } from "models";
import React from "react";
import { Trans } from "react-i18next";
import { Box, Flex, Text } from "rebass";
import { ChatHeader } from "./ChatHeader";
import { ChatInput } from "./ChatInput";
import { ChatMessagesList } from "./ChatMessagesList";

interface Props {
  messageService: MessageService;
  chats: Chat[];
  chatSelected: number;
  authUser?: AuthUser;
  deselectChat: () => void;
}

export const ChatBody: React.FC<Props> = ({
  messageService,
  chats,
  chatSelected,
  authUser,
  deselectChat
}) => (
  <Box
    width="60%"
    sx={{
      border: 1,
      borderColor: "black.0"
    }}>
    {chats.length > 0 ? (
      <Flex flexDirection="column" height="60vh" justifyContent="space-between">
        <ChatHeader
          chat={chats[chatSelected]}
          messageService={messageService}
          deselectChat={deselectChat}
          authUser={authUser}
        />
        <ChatMessagesList
          authUser={authUser}
          chatSelected={chatSelected}
          chat={chats[chatSelected]}
        />
        <ChatInput messageService={messageService} chat={chats[chatSelected]} />
      </Flex>
    ) : (
      <Text py={2}>
        <Trans i18nKey="messages.noChats" />
      </Text>
    )}
  </Box>
);
