import React, { Component } from "react";
import { Flex, Box, Text } from "rebass";
import { Checkbox, Toast } from "app/shared";
import {
  getNotificationsSettings,
  updateNotificationsSettings,
  getNotificationPreferences,
  updateNotificationPreferences
} from "../services";
import { NotificationPreference, NotificationSetting } from "../models";
import { Trans } from "react-i18next";
import { FormFieldSet } from "app/shared/forms";
import i18next from "i18next";
import { notificationSettings } from "./models";
import { SelectInput } from "app/shared/input/SelectInput";
import {
  ISOLanguageCode,
  SupportedLanguages
} from "i18n/resources/supportedLanguages";

interface State {
  settings: NotificationSetting[];
  preference?: NotificationPreference;
}

export class Management extends Component<any, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      settings: [],
      preference: undefined
    };
  }

  componentDidMount() {
    getNotificationsSettings().then(settings => this.setState({ settings }));
    getNotificationPreferences().then(preference =>
      this.setState({ preference })
    );
  }

  getEnabledGroups() {
    const { settings } = this.state;

    return settings
      .filter(
        (setting: NotificationSetting) =>
          !setting.channels.map(it => it.enabled).includes(false)
      )
      .map((setting: NotificationSetting) => setting.groupKey);
  }

  async setGroupState(groupKey: string, enabled: boolean) {
    const settings = this.state.settings.filter(
      setting => setting.groupKey !== groupKey
    );
    const setting = this.state.settings.find(
      setting => setting.groupKey === groupKey
    );

    if (setting) {
      settings.push({
        groupKey,
        channels: setting.channels.map(it => ({ ...it, enabled }))
      });

      this.setState({ settings });
      await updateNotificationsSettings(settings);
      Toast.success({
        title: {
          key: "account.settings.management.notifications.confirmationToast"
        }
      });
    }
  }

  async onLanguageChange(language: ISOLanguageCode) {
    await updateNotificationPreferences(language);
    this.setState({ preference: { language } });
  }

  render() {
    const { preference } = this.state;
    const enabled = this.getEnabledGroups();
    const selectedLanguage =
      SupportedLanguages.find(it => it.code === preference?.language) ??
      SupportedLanguages[0];

    return (
      <>
        <FormFieldSet
          title={i18next.t("account.settings.management.notifications.title")}
          sx={{ border: 1 }}>
          <>
            <FormFieldSet
              title={i18next.t(
                "account.settings.management.notifications.language"
              )}
              headerPadding={[]}
              contentPadding={[]}
              sx={{ borderBottom: 0 }}>
              <SelectInput
                name="language"
                onChange={event =>
                  this.onLanguageChange(event.target.value as ISOLanguageCode)
                }
                value={{
                  value: selectedLanguage.code,
                  label: selectedLanguage.name
                }}
                options={SupportedLanguages.map(language => ({
                  value: language.code,
                  label: language.name
                }))}
              />
            </FormFieldSet>
            {notificationSettings.map(setting => (
              <FormFieldSet
                key={setting.title}
                title={i18next.t(setting.title)}
                headerPadding={[]}
                contentPadding={[]}
                sx={{ borderBottom: 0 }}>
                {setting.items.map(item => {
                  const isChecked = enabled.includes(item.name);

                  return (
                    <Flex
                      key={item.name}
                      alignItems="center"
                      justifyContent="space-between"
                      opacity={isChecked ? undefined : 0.65}
                      mb={3}
                      pb={2}
                      sx={{ borderBottom: 1, borderColor: "black.0" }}>
                      <Box>
                        <Text fontSize={[4, 5, 6]} mb={2}>
                          <Trans i18nKey={item.title} />
                        </Text>
                        <Text fontSize={[2, 3, 4]}>
                          <Trans i18nKey={item.description} />
                        </Text>
                      </Box>
                      <Checkbox
                        name={item.name}
                        variant="slider"
                        checked={isChecked}
                        mr={[0, 5]}
                        onChange={() =>
                          this.setGroupState(item.name, !isChecked)
                        }
                      />
                    </Flex>
                  );
                })}
              </FormFieldSet>
            ))}
          </>
        </FormFieldSet>
      </>
    );
  }
}
