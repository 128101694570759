import { Icon } from "app/shared";
import OutsideClickWrapper from "app/shared/wrapper/OutsideClickWrapper";
import { User } from "models";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { compose } from "recompose";
import { useMediaQueryContext } from "styles/context";
import NavbarButton from "../NavbarButton";
import { UserMenu } from "../UserMenu";

interface Props extends RouteComponentProps {
  user: User;
}

const MobileMenuBase: React.FC<Props> = ({ history, user }) => {
  const { isMedium } = useMediaQueryContext();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    history.listen(() => {
      open && setOpen(false);
    });
  });

  return (
    <OutsideClickWrapper isOpen={open} callback={() => setOpen(false)}>
      <NavbarButton
        height={["50px", "50px", "50px", "80px"]}
        px={isMedium ? 2 : 3}
        sx={{ borderLeft: 1, flexGrow: 1 }}
        onClick={() => setOpen(!open)}>
        <Icon name="BurgerMenu" size={isMedium ? 24 : 36} />
      </NavbarButton>
      {open && <UserMenu user={user} />}
    </OutsideClickWrapper>
  );
};

const mapStateToProps = ({ loggedInUser }: User) => ({
  user: loggedInUser
});

export const MobileMenu = compose<Props, any>(
  connect(mapStateToProps),
  withRouter
)(MobileMenuBase);
