import React from "react";
import Svg, { SvgProps } from "primitives/Svg";

const SvgMoreOptions = ({ width, height, size, color }: SvgProps) => (
  <Svg
    width={size || width}
    height={size || height}
    viewBox="0 0 24 24"
    color={color}>
    <path d="M0 24V0h24v24z" />
    <circle
      cx={20}
      cy={12}
      r={2.5}
      transform="rotate(-90 20 12)"
      stroke="#fff"
    />
    <circle
      cx={12}
      cy={12}
      r={2.5}
      transform="rotate(-90 12 12)"
      stroke="#fff"
    />
    <circle cx={4} cy={12} r={2.5} transform="rotate(-90 4 12)" stroke="#fff" />
  </Svg>
);

SvgMoreOptions.defaultProps = {
  width: 24,
  height: 24
};
export default SvgMoreOptions;
