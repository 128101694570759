import {
  Button,
  Checkbox,
  Popup,
  Toast,
  LoadingFF,
  BackgroundImage,
  Tooltip
} from "app/shared";
import i18next from "i18next";
import {
  Collection,
  Document,
  DocumentId,
  UploadTask,
  ValidationError,
  VersionedDocument,
  VersionNumber
} from "models";
import { MonetaryAmount } from "marketplace";
import React, { Component } from "react";
import { Trans } from "react-i18next";
import { Box, Flex, Text } from "rebass";
import { deleteDocument, deleteTask } from "../services";
import {
  PerDocumentErrors,
  UpdateTaskService
} from "../services/UpdateTaskService";
import { DocumentDetail } from "./FillInformationDocumentDetail";
import { Link } from "i18n/components";
import { CollectionSelect } from "app/collections";
import { createCollection, updateCollection } from "app/collections/services";

interface Props {
  task: UploadTask;
  onComplete: (task: UploadTask) => void;
  onDocumentDeleted: (documentId: DocumentId) => void;
}

interface State {
  versionedDocuments: VersionedDocument[];
  draftVersionsSaved: Map<DocumentId, VersionNumber>;
  documentErrors: PerDocumentErrors[];
  basePriceAmount?: MonetaryAmount; // set in the context of applyToAll
  disabled: boolean;
  termsAccepted: boolean;
  collection: Collection;
  currentDocument: number;
  showErrors: boolean;
}

export class FillInformation extends Component<Props, State> {
  private updateTaskService: UpdateTaskService;

  constructor(props: Props) {
    super(props);

    this.updateTaskService = UpdateTaskService.default();

    const savedVersions = new Map<DocumentId, VersionNumber>();
    props.task.documents.forEach(it => savedVersions.set(it.id, 0));

    this.state = {
      versionedDocuments: props.task.documents
        .sort((a, b) => a.id - b.id)
        .map(it => ({ document: { ...it }, version: 0 })),
      draftVersionsSaved: savedVersions,
      basePriceAmount: undefined,
      disabled: false,
      termsAccepted: false,
      documentErrors: [],
      collection: props.task.documents?.[0]?.collections?.[0],
      currentDocument: 0,
      showErrors: false
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    const { documents } = nextProps.task;
    if (documents.length < this.state.versionedDocuments.length) {
      // Filter out any documents that are not part of incoming document set
      const resultingDocuments = this.state.versionedDocuments.filter(
        currentDoc => documents.find(it => it.id === currentDoc.document.id)
      );

      const updatedDraftVersions = new Map<DocumentId, VersionNumber>();
      documents.forEach(it => {
        const currentVersion = this.state.draftVersionsSaved.get(it.id);
        if (currentVersion) {
          updatedDraftVersions.set(it.id, currentVersion);
        }
      });

      this.setState({
        versionedDocuments: resultingDocuments,
        draftVersionsSaved: updatedDraftVersions
      });
    }
  }

  componentDidUpdate() {
    if (this.props.task.documents.length === 0) {
      this.deleteTask(); // Automatically delete task
    }
  }

  componentDidMount() {
    // This can be a bit overkill, but may be useful for some legacy acccounts where
    // the user hasn't manually restarted the task.
    if (this.props.task.documents.length === 0) {
      this.deleteTask();
    }
  }

  checkError = (hideError?: boolean) => {
    const { versionedDocuments, currentDocument, showErrors } = this.state;
    const documents = versionedDocuments.map(it => it.document);
    const documentErrors = this.updateTaskService.validateDocuments(documents);
    if (
      documentErrors.length > 0 &&
      documentErrors.filter(
        it => it.documentId === versionedDocuments[currentDocument].document.id
      ).length > 0 &&
      !hideError
    ) {
      Toast.error({
        title: { key: "documents.fillInformation.formError" }
      });
    }
    if (documentErrors.length > 0) {
      this.setState({ documentErrors });
    } else {
      this.setState({ documentErrors: [] });
    }
    if (!showErrors) {
      this.setState({ showErrors: true });
    }
  };

  render() {
    const firstCollectionError:
      | ValidationError
      | undefined = this.state.documentErrors
      .flatMap(it => it.errors)
      .find(error => error.field === "collections");

    const collectionExists = this.state.versionedDocuments.map(
      it => it.document
    )?.[0]?.collections?.[0];
    const {
      versionedDocuments,
      currentDocument,
      documentErrors,
      showErrors
    } = this.state;

    const changeDocument = (nextDocument: number) => {
      if (nextDocument !== currentDocument) {
        this.checkError();
        this.setState({ currentDocument: nextDocument });
      }
    };

    return (
      <Box>
        <Text textAlign="center" my={5} fontSize={[2, 4]} variant="caps">
          <Trans i18nKey="documents.fillInformation.infoHeader" />
        </Text>
        {versionedDocuments.length > 1 && (
          <Box
            mb={5}
            mx={[1, 0]}
            sx={{
              border: "1px solid #000"
            }}>
            <Box py={2} sx={{ borderBottom: 1, borderColor: "black.0" }}>
              <Flex alignItems="center">
                <Text fontSize={7} pl={3} variant="grotTextCaps">
                  {versionedDocuments.length}{" "}
                  <Trans i18nKey="documents.fillInformation.imageTabs" />
                </Text>
              </Flex>
            </Box>
            <Flex flexWrap="wrap" width="100%">
              {versionedDocuments.map((it, index) => (
                <>
                  <Flex p={3} width={["50%", "initial"]}>
                    <Button
                      width="100%"
                      variant="transparent"
                      onClick={() => changeDocument(index)}
                      sx={{
                        border: index === currentDocument ? 2 : 0,
                        borderColor: index === currentDocument ? "blue" : "none"
                      }}>
                      <Box sx={{ position: "relative" }}>
                        <BackgroundImage
                          src={it.document.thumbnail}
                          width={["100%", "155px"]}
                          height="100px"
                        />
                        {showErrors && (
                          <Flex
                            sx={{ position: "absolute", bottom: 0, right: 0 }}>
                            {documentErrors.filter(
                              it =>
                                it.documentId ===
                                versionedDocuments[index].document.id
                            ).length > 0 ? (
                              <Tooltip
                                mode="button"
                                bg="transparent"
                                sx={{ border: 0 }}
                                p={0}
                                i18nKey="documents.fillInformation.imageMissingTooltip">
                                <Box bg="red" width="21px" height="21px">
                                  <Flex
                                    width="100%"
                                    height="100%"
                                    justifyContent="center"
                                    alignItems="center">
                                    <Text
                                      color="white"
                                      fontSize={2}
                                      variant="grotText">
                                      !
                                    </Text>
                                  </Flex>
                                </Box>
                              </Tooltip>
                            ) : (
                              <Tooltip
                                mode="button"
                                bg="transparent"
                                sx={{ border: 0 }}
                                p={0}
                                i18nKey="documents.fillInformation.imageCompleteTooltip">
                                <Box bg="blue" width="21px" height="21px">
                                  <Flex
                                    width="100%"
                                    height="100%"
                                    justifyContent="center"
                                    alignItems="center">
                                    <Text
                                      color="white"
                                      fontSize={2}
                                      variant="grotText">
                                      ✓
                                    </Text>
                                  </Flex>
                                </Box>
                              </Tooltip>
                            )}
                          </Flex>
                        )}
                      </Box>
                    </Button>
                  </Flex>
                </>
              ))}
            </Flex>
          </Box>
        )}
        <Text textAlign="center" my={5} fontSize={[2, 4]} variant="caps">
          <Trans i18nKey="documents.fillInformation.storyHeader" />
        </Text>
        {firstCollectionError && (
          <Text color="red">
            <Trans i18nKey={firstCollectionError?.i18nKey} />
          </Text>
        )}
        <CollectionSelect
          onCompleted={collection => this.onCollectionChange(collection)}
          onCreating={collection => this.onCollectionChange(collection)}
          collectionCreated={
            collectionExists && collectionExists.id > 0
              ? collectionExists
              : undefined
          }
        />
        <Text textAlign="center" my={5} fontSize={[2, 4]} variant="caps">
          <Trans i18nKey="documents.fillInformation.header" />
        </Text>

        {this.renderDocuments()}
        <Flex
          mt={3}
          justifyContent="space-between"
          sx={{
            // :not() important for popups have 100% width
            "& > div:not([class*='popup'])": {
              width: "calc(100% / 3 - 1px)"
            }
          }}>
          {this.renderDeleteAllButton()}
          {this.renderSaveDraftButton()}
          {this.renderPublishButton()}
        </Flex>
      </Box>
    );
  }

  private onCollectionChange = (collection: Collection) => {
    const newDocuments = this.state.versionedDocuments.map(it => {
      return {
        document: {
          ...it.document,
          collections: [collection]
        },
        version: it.version + 1
      };
    });
    this.setState({ versionedDocuments: newDocuments });
  };

  private onChange = (document: Document) => {
    const newDocuments = this.state.versionedDocuments.map(it =>
      it.document.id === document.id
        ? { document: { ...document }, version: it.version + 1 }
        : it
    );
    this.setState({ versionedDocuments: newDocuments });
  };
  /**
   * Apply changes to all other documents without doing any change to the actual document passed as prop.
   * To do this we have to clone the originalDocument so that no references to the original object is kept.
   */
  private applyToAll = (
    originalDocument: Document,
    basePriceAmount: MonetaryAmount
  ) => {
    const newDocuments = this.state.versionedDocuments.map(it => {
      if (it.document.id === originalDocument.id) {
        return it;
      } else {
        // Removes references to original object
        // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/assign#Deep_Clone
        const document = JSON.parse(JSON.stringify(originalDocument));

        return {
          document: {
            ...it.document,
            translations: document.translations,
            userCategories: document.userCategories,
            credits: document.credits,
            prices: document.prices,
            collections: document.collections,
            attributes: document.attributes,
            userLabels: document.userLabels,
            city: document.city ?? undefined,
            country: document.country ?? undefined,
            latitude:
              it.document.metadata.latitude ?? document.latitude ?? undefined,
            longitude:
              it.document.metadata.longitude ?? document.longitude ?? undefined,
            takenAt: it.document.metadata.date ?? document.takenAt ?? undefined
          },
          version: it.version + 1
        };
      }
    });
    this.setState({ versionedDocuments: newDocuments, basePriceAmount }, () =>
      this.checkError(true)
    );
  };

  private removeAllLabels = () => {
    const newDocuments = this.state.versionedDocuments.map(it => {
      return {
        document: {
          ...it.document,
          machineLabels: [],
          userLabels: []
        },
        version: it.version + 1
      };
    });
    this.setState({ versionedDocuments: newDocuments });
  };

  private onDocumentDelete = (document: Document) => {
    const documentId = document.id;
    this.setState({ disabled: true }, async () => {
      deleteDocument([documentId])
        .then(() => {
          Toast.success({
            title: { key: "documents.fillInformation.documentDeleteToast" }
          });
          // TODO: Change this so we dont have to use hard refresh
          this.setState({ disabled: false }, () =>
            this.props.onDocumentDeleted(documentId)
          );
        })
        .catch(error => {
          console.log("error", error);
          Toast.error({
            title: { key: "documents.fillInformation.networkError" }
          });
          this.setState({ disabled: false });
        });
    });
  };

  /**
   * If undefined is returned it means it was not found therefore the document have changed
   */
  private getChangedDocuments(): VersionedDocument[] {
    const { versionedDocuments, draftVersionsSaved } = this.state;
    return versionedDocuments.filter(it => {
      const lastSavedVersion = draftVersionsSaved.get(it.document.id);
      return lastSavedVersion !== it.version;
    });
  }

  private async saveAsDraft(close: () => void) {
    this.setState({ disabled: true }, async () => {
      try {
        let documents = this.getChangedDocuments();
        const collection = this.state.versionedDocuments.map(
          it => it.document
        )[0].collections[0];
        const setDraft = async () => {
          documents = this.getChangedDocuments();

          await this.updateTaskService.saveAllAsDraft(
            documents.map(it => it.document)
          );
          const updatedDraftVersionsSaved = new Map<DocumentId, VersionNumber>(
            this.state.draftVersionsSaved
          );
          documents.forEach(vdoc =>
            updatedDraftVersionsSaved.set(vdoc.document.id, vdoc.version)
          );
          this.setState({
            disabled: false,
            draftVersionsSaved: updatedDraftVersionsSaved
          });

          Toast.success({
            title: { key: "documents.fillInformation.confirmationToast" }
          });
          close();
        };
        if (
          this.updateTaskService.validateDraft(documents.map(it => it.document))
            .length > 0
        ) {
          const newDocuments = this.state.versionedDocuments.map(it => {
            return {
              document: {
                ...it.document,
                collections: []
              },
              version: it.version + 1
            };
          });
          this.setState({ versionedDocuments: newDocuments }, async () => {
            setDraft();
          });
        } else {
          this.updateCreateCollection(collection, async () => {
            setDraft();
          });
        }
      } catch (error) {
        console.log("error", error);
        Toast.error({
          title: { key: "documents.fillInformation.networkError" }
        });
        this.setState({ disabled: false });
      }
    });
  }

  private updateCreateCollection(
    collection: Collection,
    executeAfter: () => void
  ) {
    const promise =
      collection.id > 0
        ? updateCollection(
            collection.id,
            collection.privacy,
            collection.title,
            collection.description,
            collection.shareWithLink,
            collection.city,
            collection.country,
            undefined
          )
        : createCollection(
            collection.privacy,
            collection.title,
            collection.description,
            collection.shareWithLink,
            collection.city,
            collection.country
          );

    promise
      .then(async collection => {
        this.onCollectionChange(collection);
        executeAfter();
      })
      .catch(error => {
        Toast.apiError(error);
        this.setState({ disabled: false });
      });
  }

  private async publish(close: () => void) {
    this.setState({ disabled: true }, async () => {
      try {
        const { task, onComplete } = this.props;
        const { versionedDocuments } = this.state;
        const documents = versionedDocuments.map(it => it.document);
        const documentErrors = this.updateTaskService.validateDocuments(
          documents
        );
        if (documentErrors.length > 0) {
          Toast.error({
            title: { key: "documents.fillInformation.formError" }
          });
          this.setState({ documentErrors, disabled: false });
        } else {
          this.setState({ disabled: true }, () => {
            this.updateCreateCollection(
              documents[0].collections[0],
              async () => {
                const documents = this.state.versionedDocuments.map(
                  it => it.document
                );
                await this.updateTaskService.publishAll(task, documents);
                Toast.success({
                  title: { key: "documents.fillInformation.confirmationToast" }
                });
                this.setState({ disabled: false }, () =>
                  onComplete({ ...task, documents })
                ); // update task documents so task is updated in the result page
                close();
              }
            );
          });
        }
      } catch (error) {
        console.log("error", error);
        Toast.error({
          title: { key: "documents.fillInformation.networkError" }
        });
        this.setState({ disabled: false });
      }
    });
  }

  private deleteTask() {
    const { task } = this.props;
    deleteTask(task.id)
      .then(() => {
        Toast.success({
          title: { key: "documents.fillInformation.taskDeletedToast" }
        });
        window.location.reload();
      })
      .catch(error => {
        console.log("error", error);
        Toast.error({
          title: { key: "documents.fillInformation.networkError" }
        });
        this.setState({ disabled: false });
      });
  }

  private getDocumentErrors(documentId: number): ValidationError[] {
    const candidateError = this.state.documentErrors.find(
      docError => docError.documentId === documentId
    );
    return candidateError ? candidateError.errors : [];
  }

  private renderDocuments() {
    const { versionedDocuments, basePriceAmount, currentDocument } = this.state;
    return (
      <>
        {versionedDocuments.map(
          (vdoc, index) =>
            currentDocument === index && (
              <DocumentDetail
                key={vdoc.document.id}
                document={vdoc.document}
                version={vdoc.version}
                errors={this.getDocumentErrors(vdoc.document.id)}
                totalNumDocuments={versionedDocuments.length}
                currentDocument={index}
                onChange={this.onChange}
                onApplyToAll={this.applyToAll}
                removeAllLabels={this.removeAllLabels}
                basePriceAmount={basePriceAmount}
                onDelete={this.onDocumentDelete}
                onCheckError={() => this.checkError()}
              />
            )
        )}
      </>
    );
  }

  private renderDeleteAllButton() {
    const { disabled } = this.state;
    return (
      <Popup
        trigger={
          <Button
            center={true}
            variant="black"
            p={3}
            disabled={disabled}
            mr={4}
            ml={[1, 0]}>
            <Text>
              <Trans i18nKey="documents.fillInformation.deleteAll" />
            </Text>
          </Button>
        }
        heading={i18next.t("documents.fillInformation.popups.deleteAll.header")}
        cancelText={i18next.t(
          "documents.fillInformation.popups.deleteAll.cancel"
        )}
        cancelDisabled={disabled}
        submit={() => this.deleteTask()}
        submitText={i18next.t(
          "documents.fillInformation.popups.deleteAll.submit"
        )}
        submitDisabled={disabled}>
        <>
          <Text textAlign="center">
            <Trans i18nKey="documents.fillInformation.popups.deleteAll.content" />
          </Text>
        </>
      </Popup>
    );
  }

  private renderSaveDraftButton() {
    const { disabled } = this.state;
    return (
      <Popup
        trigger={
          <Button center={true} p={3} disabled={disabled} mr={4}>
            <Text>
              <Trans i18nKey="documents.fillInformation.saveAsDraft" />
            </Text>
          </Button>
        }
        heading={i18next.t("documents.fillInformation.popups.draft.header")}
        cancelText={i18next.t("documents.fillInformation.popups.draft.cancel")}
        cancelDisabled={disabled}
        submit={close => this.saveAsDraft(close)}
        submitText={i18next.t("documents.fillInformation.popups.draft.submit")}
        submitDisabled={disabled}>
        <>
          <Text textAlign="center">
            <Trans i18nKey="documents.fillInformation.popups.draft.content" />
          </Text>
          <Text textAlign="center" mt={3} p={3}>
            <Trans i18nKey="documents.fillInformation.popups.draft.info" />
          </Text>
        </>
      </Popup>
    );
  }

  private renderPublishButton() {
    const { disabled, termsAccepted } = this.state;
    return (
      <Popup
        trigger={
          <Button
            center={true}
            disabled={disabled}
            p={3}
            variant="blue"
            mr={[1, 0]}>
            <Text fontWeight="bold">
              <Trans i18nKey="documents.fillInformation.publishAll" />
            </Text>
          </Button>
        }
        contentPadding={0}
        heading={
          disabled
            ? undefined
            : i18next.t("documents.fillInformation.popups.publish.header")
        }
        cancelText={i18next.t(
          "documents.fillInformation.popups.publish.cancel"
        )}
        hideActionButtons={disabled}
        hideCancelButtons={disabled}
        hideCloseButton={disabled}
        transparent={disabled}
        cancelDisabled={disabled}
        submit={close => this.publish(close)}
        onClose={() => this.setState({ termsAccepted: false })}
        submitText={i18next.t(
          "documents.fillInformation.popups.publish.submit"
        )}
        submitDisabled={disabled || !termsAccepted}>
        <>
          {disabled ? (
            <LoadingFF m={2} />
          ) : (
            <>
              <Text
                textAlign="center"
                px={2}
                py={4}
                sx={{ borderBottom: 1, borderColor: "black.0" }}>
                <Trans i18nKey="documents.fillInformation.popups.publish.content" />
              </Text>
              <Box m="auto">
                <Checkbox
                  name={"confirmation"}
                  checked={termsAccepted}
                  size={20}
                  onChange={() =>
                    this.setState({ termsAccepted: !termsAccepted })
                  }
                  p={3}
                  align="center"
                  direction="row">
                  <Text fontSize={1} variant="caps">
                    <Trans
                      i18nKey="documents.fillInformation.popups.publish.terms"
                      components={[
                        <Link href="/about#terms-conditions">Translation</Link>,
                        <Link href="/about#licenses">Translation</Link>
                      ]}
                    />
                  </Text>
                </Checkbox>
              </Box>
            </>
          )}
        </>
      </Popup>
    );
  }
}
