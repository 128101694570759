import { ISOLanguageCode } from "i18n/resources/supportedLanguages";
import { NamedBlob, User } from "models";
import axios from "network";
import queryString from "query-string";
import { UpdateUserDTO } from "../dto";
import {
  NotificationCount,
  NotificationSetting,
  RelationDocument,
  RelationUser,
  UserRelationInfo,
  RelationDocumentSale,
  UserPreferences,
  NotificationPreference
} from "../models";
import { PayoutProviderName, UserMarketplaceSettings } from "../models";

/**
 * Users
 */
export const getUser = async (userId: string): Promise<User> => {
  return axios.get(`/users/${userId}`).then(response => response.data);
};

export const getUserProfile = async (userId: string): Promise<User> => {
  return axios.get(`/users/${userId}/profile`).then(response => response.data);
};

export const createUser = async (
  email: string,
  password: string,
  professionalName: string,
  preferences: UserPreferences,
  picture?: File,
  uid?: string
) => {
  const data = new FormData();
  data.append("email", email);
  data.append("password", password);
  data.append("professionalName", professionalName);
  data.append("preferences", JSON.stringify(preferences));
  uid && data.append("uid", uid);
  picture && data.append("picture", picture);
  return axios.post(`/users`, data);
};

export const updateUser = async (
  updateUserDto: UpdateUserDTO
): Promise<User> => {
  const data = new FormData();

  Object.keys(updateUserDto).forEach(prop => {
    if (prop && prop !== "userId" && updateUserDto[prop]) {
      const type = typeof updateUserDto[prop];

      switch (type) {
        case "string": {
          data.append(prop, updateUserDto[prop] as string);
          break;
        }
        case "object": {
          updateUserDto[prop] instanceof File
            ? data.append(prop, updateUserDto[prop] as File)
            : data.append(prop, JSON.stringify(updateUserDto[prop]));
          break;
        }
        default:
          throw new Error(`Type ${type} not supported in user info`);
      }
    }
  });
  return axios
    .put(`/users/${updateUserDto.userId}`, data)
    .then(response => response.data);
};

export const createVerificationFiles = async (
  userId: string,
  files: NamedBlob[]
) => {
  const data = new FormData();

  for (let i = 0; i < files.length; i++) {
    data.append(files[i].name, files[i].file);
  }

  return axios.put(`/users/${userId}/verification/files`, data);
};

export const requestUserVerification = async (
  userId: string
): Promise<User> => {
  return axios
    .post(`/users/${userId}/verification`)
    .then(response => response.data);
};

/**
 * Relations
 */
export const getRelationUser = async (
  userId: string
): Promise<RelationUser> => {
  return axios
    .get(`/relations/users/${userId}`)
    .then(response => response.data);
};

export const getRelationDocuments = async (
  from: string,
  to: string
): Promise<RelationDocument[]> => {
  return axios
    .get(`/relations/documents?${queryString.stringify({ from, to })}`)
    .then(response => response.data);
};

export const getRelationSales = async (
  from: string,
  to: string
): Promise<RelationDocumentSale[]> => {
  return axios
    .get(`/relations/documents/sales?${queryString.stringify({ from, to })}`)
    .then(response => response.data);
};

export const getRelationBuys = async (
  from: string,
  to: string
): Promise<RelationDocumentSale[]> => {
  return axios
    .get(`/relations/documents/buys?${queryString.stringify({ from, to })}`)
    .then(response => response.data);
};

export const getUserInfo = async (
  userId: string
): Promise<UserRelationInfo> => {
  return axios
    .get(`/relations/users/${userId}/info`)
    .then(response => response.data);
};

export const getFollowers = async (
  userId: string,
  page: number,
  size: number
) => {
  return axios
    .get(
      `/relations/users/${userId}/followers?${queryString.stringify({
        page,
        size
      })}`
    )
    .then((response: any) => ({
      data: response.data,
      ...response.pagination
    }));
};

export const getFollowing = async (
  userId: string,
  page: number,
  size: number
) => {
  return axios
    .get(
      `/relations/users/${userId}/following?${queryString.stringify({
        page,
        size
      })}`
    )
    .then((response: any) => ({
      data: response.data,
      ...response.pagination
    }));
};

export const follow = async (userId: string) => {
  return axios.put(`/relations/users/${userId}/follow`);
};

export const unfollow = async (userId: string) => {
  return axios.delete(`/relations/users/${userId}/follow`);
};

/**
 * Notifications
 */
export const getNotificationsCount = async (): Promise<NotificationCount> => {
  return axios.get(`/notificationCounter`).then(response => response.data);
};

export const getNotifications = async (page: number, size: number) => {
  return axios
    .get(`/notification?${queryString.stringify({ page, size })}`)
    .then((response: any) => ({
      data: response.data,
      ...response.pagination
    }));
};

export const markAllNotificationsRead = async () => {
  return axios.post(`/notification/markAllRead`);
};

export const getNotificationsSettings = async (): Promise<NotificationSetting[]> => {
  return axios.get(`/notificationSettings`).then(response => response.data);
};

export const updateNotificationsSettings = async (
  updatedSettings: NotificationSetting[]
): Promise<NotificationSetting[]> => {
  return axios
    .put(`/notificationSettings`, { updatedSettings })
    .then(response => response.data);
};

export const getNotificationPreferences = async (): Promise<NotificationPreference> => {
  return axios.get(`/notificationPreferences`).then(response => response.data);
};

export const updateNotificationPreferences = async (
  language: ISOLanguageCode
) => {
  return axios.put(`/notificationPreferences`, { language });
};

/**
 * Marketplace
 */
export const getMarketplaceSettings = async (): Promise<UserMarketplaceSettings> => {
  return axios
    .get(`/user/marketplace/settings`)
    .then(response => response.data);
};

export const updateMarketplaceSettings = async (
  payoutProviderName: PayoutProviderName,
  payoutReceiver: string,
  receiveDonations: boolean
) => {
  return axios
    .put(`/user/marketplace/settings`, {
      payoutProviderName,
      payoutReceiver,
      receiveDonations
    })
    .then(response => response.data);
};

/**
 * Invites
 */
export const confirmInvite = async (
  id: number,
  code: string
): Promise<void> => {
  return axios.put(`/invites/confirm`, { id, code });
};

export const reportUser = async (userId: string, description: string) => {
  return axios.post(
    `/reports`,
    queryString.stringify({
      objectType: "User",
      objectId: userId,
      description: description
    }),
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      }
    }
  );
};

/**
 * Recommendations
 */
export const getRecommendations = async (page: number, size: number) => {
  return axios
    .post(
      `/relations/recommendations/unseen?${queryString.stringify({
        page,
        size
      })}`,
      {
        labels: ["document", "collection", "bucket"]
      }
    )
    .then((response: any) => ({
      data: response.data,
      ...response.pagination
    }));
};
