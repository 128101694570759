import React, { ReactNode } from "react";
import { connect } from "react-redux";
import { ISOLanguageCode } from "i18n/resources/supportedLanguages";
import { CardGridLayout } from "layout";
import { Box, Text } from "rebass";

interface Title {
  language: ISOLanguageCode;
  label: string;
}

interface Props {
  language: ISOLanguageCode;
  title: Title[];
  children: ReactNode[];
  marginTop?: number | number[];
  height?: string | string[];
  gridTemplateColumns?: (string | null)[] | (string | null)[];
  gridGap: number | number[];
}

const GridParser: React.FC<Props> = ({
  language,
  title,
  children,
  marginTop,
  height,
  gridTemplateColumns,
  gridGap,
  ...rest
}) => {
  const label =
    title?.find(it => it.language === language)?.label ?? title?.[0]?.label; // Showing first if there is no match with current user language

  return (
    <Box marginTop={marginTop}>
      {label && (
        <Text fontSize={[8, 14]} variant="grotText" pb={[2, 2, 0]}>
          {label}
        </Text>
      )}
      <CardGridLayout
        gridTemplateColumns={gridTemplateColumns}
        gridGap={gridGap}
        marginTop={marginTop}
        {...rest}>
        {children}
      </CardGridLayout>
    </Box>
  );
};

GridParser.defaultProps = {
  marginTop: 0,
  height: "250px",
  gridTemplateColumns: ["1fr", null, "repeat(1, 1fr)"],
  gridGap: 4
};

const mapStateToProps = (state: any) => ({
  language: state.language.code
});

export default connect(mapStateToProps)(GridParser);
