import environment from "configurations";
import "firebase/analytics";
import app from "firebase/app";
import "firebase/auth";
import { IdToken, SignInResponse, SignInResult } from "./models";
import { MessageService } from "messaging";
import { Toast } from "app/shared";

interface FirebaseError {
  code: string;
  email?: string;
}

export default class Firebase {
  // Firebase
  auth: app.auth.Auth;
  googleProvider: app.auth.GoogleAuthProvider;
  facebookProvider: app.auth.FacebookAuthProvider;
  analytics: app.analytics.Analytics;

  // Chat
  messageService: MessageService;

  private loginProviders: { [fieldName: string]: app.auth.AuthProvider } = {
    "google.com": new app.auth.GoogleAuthProvider(),
    "facebook.com": new app.auth.FacebookAuthProvider()
  };

  constructor(firebaseApp: app.app.App) {
    // Connect to chat framework
    this.messageService = new MessageService(firebaseApp);

    // Helpers
    this.auth = firebaseApp.auth();
    this.analytics = firebaseApp.analytics();

    // Social sign in providers
    this.googleProvider = new app.auth.GoogleAuthProvider();
    this.facebookProvider = new app.auth.FacebookAuthProvider();
  }

  // Auth API
  doCreateUserWithEmailAndPassword = (email: string, password: string) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email: string, password: string) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignInWithGoogle = () => this.signInWithProvider("google.com");

  signInWithProvider = async (providerId: string): Promise<SignInResponse> => {
    const firebaseAuth = this.auth;
    const provider = this.loginProviders[providerId];

    try {
      const result = await this.auth.signInWithPopup(provider);
      const email = result.user?.email;
      const displayName = result.user?.displayName || "";
      const photoURL = result.user?.photoURL || "";

      if (email) {
        const methods = await firebaseAuth.fetchSignInMethodsForEmail(email);

        if (!methods.includes("password")) {
          const uid = result.user?.uid;
          return {
            uid,
            email,
            displayName,
            photoURL,
            result: SignInResult.RegistrationRequired
          };
        }

        return { result: SignInResult.Success };
      } else {
        return { result: SignInResult.Unknown };
      }
    } catch (err) {
      const error = err as FirebaseError;

      if (error.code === "auth/account-exists-with-different-credential") {
        const email = error.email;

        // At this point, you should let the user know that they already has an account
        // but with a different provider, and let them validate the fact they want to
        // sign in with this provider.
        // Sign in to provider. Note: browsers usually block popup triggered asynchronously,
        // so in real scenario you should ask the user to click on a "continue" button
        // that will trigger the signInWithPopup.
        Toast.error({
          title: {
            key: "authentication.firebase.existingAccount",
            options: { email }
          }
        });

        return { result: SignInResult.Unknown };

        // User's email already exists.
        // The pending Google credential.
        //const pendingCred = error.credential;
        // const methods = await firebaseAuth.fetchSignInMethodsForEmail(email);

        // If the user has several sign-in methods,
        // the first method in the list will be the "recommended" method to use.
        // if (methods[0] === "password") {
        //   // Asks the user their password.
        //   return {
        //     email,
        //     credentials: error.credential,
        //     result: SignInResult.PasswordRequired
        //   };
        // }

        //const provider = this.loginProviders[methods[0]];
        //const result = await firebaseAuth.signInWithPopup(provider);
        //await result.user?.linkAndRetrieveDataWithCredential(pendingCred);

        //return { result: SignInResult.Success };
      } else if (error.code === "auth/popup-closed-by-user") {
        return { result: SignInResult.Unknown };
      } else {
        return { result: SignInResult.Unknown };
      }
    }
  };

  linkAccountWithCredential = async (
    credentials: firebase.auth.AuthCredential
  ) => {
    await this.auth.currentUser?.linkWithCredential(credentials);
  };

  getLinkedAccounts = () => {
    const email = this.auth.currentUser?.email ?? null;

    if (email !== null) {
      return this.auth.fetchSignInMethodsForEmail(email);
    }

    return [];
  };

  linkAccount = async (providerId: string) => {
    const provider = this.loginProviders[providerId];

    await this.auth.currentUser?.linkWithPopup(provider);
  };

  unlinkAccount = (providerId: string) => {
    this.auth.currentUser?.unlink(providerId);
  };

  doSignInWithFacebook = () => this.signInWithProvider("facebook.com");

  doSignOut = async () => this.auth.signOut();

  doPasswordReset = (email: string) => this.auth.sendPasswordResetEmail(email);

  doSendEmailVerification = () =>
    this.getCurrentUser().sendEmailVerification({
      url: `${environment.protocol}://${environment.host}${
        environment.port ? `:${environment.port}` : ""
      }`
    });

  doPasswordUpdate = (password: string) =>
    this.getCurrentUser().updatePassword(password);

  confirmPasswordReset = (code: string, password: string) =>
    this.auth.confirmPasswordReset(code, password);

  isLogged = () => this.auth.currentUser !== null;

  getCurrentUser = () => this.auth.currentUser!;

  getIdToken = async (forceRefresh = false): Promise<IdToken> => {
    const token = await this.getCurrentUser().getIdTokenResult(forceRefresh);

    return {
      token: token.token,
      roles: token.claims.roles?.[0] ?? [],
      expirationTime: token.expirationTime,
      issuedAtTime: token.issuedAtTime
    };
  };
}
