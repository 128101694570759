import { MultiSelectSearch } from "app/search/shared";
import { getRaw } from "app/search/utils";
import { Button, Input, Popup, Toast } from "app/shared";
import { Firebase, withFirebase } from "authentication/firebase";
import { AuthUserContext } from "authentication/session";
import { checkSameUser } from "authentication/utils";
import i18next from "i18next";
import { User } from "models";
import React, { Component } from "react";
import { Box, BoxProps, Flex, Text } from "rebass";
import { compose } from "recompose";
import { Icon } from "app/shared";
import { Trans } from "react-i18next";

interface PropsExternal extends BoxProps {
  user?: User;
  buttonText?: string;
  fontSize?: number | string;
  fontName?: string;
}

interface Props extends PropsExternal {
  firebase: Firebase;
}

interface State {
  disabled: boolean;
  message: string;
  user?: User;
}

class NewMessageButtonBase extends Component<Props, State> {
  static defaultProps = {
    buttonText: ""
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      disabled: false,
      message: "",
      user: undefined
    };
  }

  async sendMessage(close: () => void) {
    const { firebase } = this.props;
    const { message } = this.state;
    const user = this.props.user ?? this.state.user;

    if (user && message.length) {
      try {
        this.setState({ disabled: true }, async () => {
          await firebase.messageService.composeMessage(user.id, message);

          this.setState({ message: "", disabled: false }, () => {
            close();
          });
        });
      } catch (error) {
        Toast.apiError(error);
        this.setState({ disabled: false });
      }
    }
  }

  handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState<never>({ [event.target.name]: event.target.value });
  };

  handleOnClose() {
    // Clear selected user
    this.setState({ user: undefined });
  }

  render() {
    const { buttonText, fontName, fontSize, ...rest } = this.props;
    const { disabled, message } = this.state;
    const user = this.props.user ?? this.state.user;

    return (
      <AuthUserContext.Consumer>
        {({ authUser }) => {
          const buttonDisabled =
            !authUser || (authUser && user && checkSameUser(user.id, authUser)); // disable if not logged in or if own user

          return (
            <Popup
              trigger={
                <Button
                  width="fit-content"
                  disabled={disabled || buttonDisabled}
                  {...(rest as any)}>
                  {buttonText ? (
                    <Flex justifyContent="center" alignItems="center">
                      <Icon name="Chat" size={18} />
                      <Text
                        py={[2, 0]}
                        pl="12px"
                        fontSize={fontSize ? fontSize : [0, 3]}
                        variant={fontName ? fontName : "grotText"}>
                        <Trans i18nKey={buttonText} />
                      </Text>
                    </Flex>
                  ) : (
                    <Flex
                      width="100%"
                      height="100%"
                      justifyContent="center"
                      alignItems="center">
                      <Icon name="Chat" size={[12, 20]} />
                    </Flex>
                  )}
                </Button>
              }
              heading={i18next.t("messages.newMessageButton.newMsg")}
              submit={close => this.sendMessage(close)}
              cancelDisabled={disabled}
              onClose={() => this.handleOnClose()}
              submitText={i18next.t("messages.newMessageButton.sendMsg")}
              submitDisabled={disabled}
              disabled={buttonDisabled}>
              <>
                {authUser && (
                  <Box sx={{ textAlign: "left" }}>
                    <MultiSelectSearch
                      disabled={user !== undefined}
                      filters={[
                        { field: "class_name", values: ["user"], type: "any" },
                        {
                          field: "real_id",
                          values: [authUser.uid],
                          type: "none"
                        }
                      ]}
                      isMulti={false}
                      resultFields={{
                        real_id: { raw: {} },
                        professional_name: { raw: {} }
                      }}
                      defaultValues={
                        user
                          ? [
                              {
                                value: `${user.id}`,
                                label: `${user.professionalName}`
                              }
                            ]
                          : undefined
                      }
                      labelI18n="messages.newMessageButton.to"
                      mapResults={results =>
                        results.map((result: any) => ({
                          value: getRaw(result, "real_id"),
                          label: getRaw(result, "professional_name")
                        }))
                      }
                      onChange={options => {
                        if (options.length) {
                          const users = options.map(option => ({
                            id: option.value,
                            professionalName: option.label
                          })) as User[];
                          this.setState({ user: users[0] });
                        }
                      }}
                    />
                    <Box mt={3}>
                      <Input
                        name="message"
                        type="text"
                        labelI18n="messages.newMessageButton.label"
                        onChange={this.handleOnChange}
                        value={message}
                      />
                    </Box>
                  </Box>
                )}
              </>
            </Popup>
          );
        }}
      </AuthUserContext.Consumer>
    );
  }
}

export const NewMessageButton = compose<Props, PropsExternal>(withFirebase)(
  NewMessageButtonBase
);
