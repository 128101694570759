import React from "react";
import { Box } from "rebass";

type TriangleSide = "left" | "right";

interface Props {
  side: TriangleSide;
}

export const ChatTriangle: React.FC<Props> = ({ side }) => {
  const isLeft = side === "left";
  return (
    <Box
      sx={{
        width: 0,
        height: 0,
        borderTop: "16px solid black",
        borderLeft: !isLeft ? "16px solid transparent" : undefined,
        borderRight: isLeft ? "16px solid transparent" : undefined
      }}>
      {!isLeft && (
        <Box
          sx={{
            position: "relative",
            width: 0,
            height: 0,
            top: "-15px",
            left: "-13.5px",
            borderTop: "15px solid white",
            borderLeft: "15px solid transparent",
            borderRight: "3px solid white"
          }}
        />
      )}
    </Box>
  );
};
