import * as type from "./constants";
import { CheckoutItem } from "../models";
import { firebaseAnalytics } from "analytics/firebaseAnalytics";

const loadState = (): CheckoutItem[] => {
  return JSON.parse(localStorage.getItem("checkout") ?? "[]");
};

const saveState = (checkout: CheckoutItem[]): CheckoutItem[] => {
  localStorage.setItem("checkout", JSON.stringify(checkout));
  return checkout;
};

/**
 * Allow only one CheckoutItem with the same documentId.
 */
const filterItem = (
  item: CheckoutItem,
  checkout: CheckoutItem[]
): CheckoutItem[] =>
  checkout.filter((it: CheckoutItem) => !(it.documentId === item.documentId));

const initialState: CheckoutItem[] = loadState();

const reducer = (
  state = initialState,
  action: { type: string; item: CheckoutItem }
) => {
  switch (action.type) {
    case type.ADD_CHECKOUT_ITEM: {
      const item: CheckoutItem = action.item;
      firebaseAnalytics.logEvent("add_to_cart", {
        items: [
          {
            item_id: item.documentId.toString()
          }
        ],
        currency: item.licensePrice.basePrice.currency,
        value: item.licensePrice.basePrice.value
      });
      return saveState(filterItem(item, state).concat(item));
    }
    case type.REMOVE_CHECKOUT_ITEM: {
      const item: CheckoutItem = action.item;
      return saveState(filterItem(item, state));
    }
    case type.CLEAR_CHECKOUT_ITEMS: {
      return saveState([]);
    }
    default: {
      return state;
    }
  }
};

export default reducer;
