module.exports = {
  name: "production",
  url: "<REPLACE>",
  api: "<REPLACE>",
  firebase: {
    apiKey: "AIzaSyDt8XBbdf8cqDOKpvacVZh03fCifdh6Kng",
    authDomain: "frontfiles-production.firebaseapp.com",
    databaseURL: "https://frontfiles-production.firebaseio.com",
    projectId: "frontfiles-production",
    storageBucket: "",
    messagingSenderId: "621457599608",
    appId: "1:621457599608:web:bfdc418940e1a59887118d",
    measurementId: "G-8J7LGD4LMD"
  },
  search: {
    searchKey: "<REPLACE>",
    engineName: "<REPLACE>",
    hostIdentifier: "<REPLACE>",
    endpointBase: "<REPLACE>"
  },
  toast: {
    autoCloseTime: 5000
  },
  festivalEventBucket: "<REPLACE>",
  stripeKey: "<REPLACE>",
  paymentProvider: "stripe",
  payoutProvider: "paypal",
  googleMapsKey: "<REPLACE>",
  mailchimpNewsletterEndpoint: "<REPLACE>",
  featureFlags: {
    donationButtonDisabled: true,
    specialOfferDisabled: true
  }
};
