import { Checkbox, CollapsableButton } from "app/shared";
import React, { useState } from "react";
import { Box, Text } from "rebass";
import { SortOption } from "../constants";

interface Props {
  label?: string;
  onChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    option: string
  ) => void;
  options: string[];
  sortOptions: SortOption[];
  selectedValue: string;
}

const SortingView: React.FC<Props> = ({
  label,
  onChange,
  options,
  sortOptions,
  selectedValue,
  ...rest
}) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const getOptionNameFromSortString = (sortString: string) => {
    const [value] = sortString.split("|||");
    const sortOption: SortOption = sortOptions.filter(
      option => option.value === value
    )?.[0];
    return sortOption.name ?? "";
  };
  return (
    <Box
      sx={{
        borderRight: 1,
        borderLeft: 1,
        borderBottom: 1,
        borderColor: "black.0"
      }}
      px={[1, 1, 1, 1, 3]}
      py={2}>
      <CollapsableButton
        fontSize={[2, 4, 5, 6]}
        mb={!isCollapsed ? 2 : 0}
        sx={{ fontFamily: "heading", textTransform: "uppercase" }}
        onClick={() => setIsCollapsed(!isCollapsed)}
        collapsed={isCollapsed}>
        {label}
      </CollapsableButton>

      {!isCollapsed &&
        options.map(option => (
          <Checkbox
            my={2}
            size={20}
            key={`sorting_checkbox_${option}`}
            name={option}
            checked={option === selectedValue}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              onChange(event, option)
            }>
            <Text fontSize={1}> {getOptionNameFromSortString(option)}</Text>
          </Checkbox>
        ))}
    </Box>
  );
};

export default SortingView;
