import React from "react";
import Svg, { SvgProps } from "primitives/Svg";

const SvgAddToCollectionFilled = ({ width, height, size, color }: SvgProps) => (
  <Svg
    id="AddToCollectionFilled_svg__Camada_1"
    viewBox="0 0 18.9 21.2"
    width={size || width}
    height={size || height}
    color={color}>
    <style>
      {".AddToCollectionFilled_svg__st1{fill:#fff;stroke:#fff;stroke-width:.3}"}
    </style>
    <path d="M0 0v21.2l9.5-6.1 8.9 5.8.5.3V0z" />
    <path className="AddToCollectionFilled_svg__st1" d="M8.7 4.2h1.5v7H8.7z" />
    <path
      transform="rotate(90 9.45 7.639)"
      className="AddToCollectionFilled_svg__st1"
      d="M8.7 4.2h1.5v7H8.7z"
    />
  </Svg>
);

SvgAddToCollectionFilled.defaultProps = {
  width: 24,
  height: 24
};
export default SvgAddToCollectionFilled;
