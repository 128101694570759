import React from "react";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import { Virtual } from "swiper";
// Styles must use direct files imports
import "swiper/swiper.scss"; // core Swiper
import "swiper/modules/navigation/navigation.scss"; // Navigation module
import "swiper/modules/pagination/pagination.scss"; // Pagination module
import "./styles.css";
import SwiperCore, { Autoplay, Navigation, Pagination } from "swiper";
import { Box } from "rebass";

interface Props {
  slides: JSX.Element[];
  height?: string | string[] | number | number[];
  initialSlide?: number;
  onClick?: () => void;
  navigation?: boolean;
  onSlideChange?: (swiper: SwiperCore) => void;
  autoplay?: boolean;
  autoplayDelay?: number;
  hideBullets?: boolean;
}

export const SwiperView: React.FC<Props> = ({
  slides,
  height,
  initialSlide,
  onClick,
  navigation,
  onSlideChange,
  autoplay,
  autoplayDelay,
  hideBullets
}) => {
  SwiperCore.use([Autoplay, Navigation, hideBullets ? "" : Pagination]);
  return (
    <Box width="100%" height={height}>
      <Swiper
        initialSlide={initialSlide ?? 1}
        onSlideChange={swiper => (onSlideChange ? onSlideChange(swiper) : null)}
        autoplay={
          autoplay
            ? {
                delay: autoplayDelay ?? 5000,
                disableOnInteraction: false
              }
            : false
        }
        centeredSlides={true}
        spaceBetween={0}
        modules={[Virtual]}
        virtual={true}
        allowTouchMove={hideBullets ? false : true}
        pagination={{
          dynamicBullets: slides.length > 1 ? true : false
        }}
        navigation={navigation}>
        {slides.map((slideContent, index) => (
          <SwiperSlide py={4} width="100%" height={height} onClick={onClick}>
            {slideContent}
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
};
