import { Button, MobileTriggerButton, Popup, Toast } from "app/shared";
import { AuthUserContext } from "authentication/session";
import i18next from "i18next";
import debounce from "lodash.debounce";
import React, { Component } from "react";
import { Trans } from "react-i18next";
import { BoxProps, Text } from "rebass";
import { deleteCollections } from "./services";

interface Props extends BoxProps {
  collectionIds: number[];
  mobileView?: boolean;
}

interface State {
  disabled: boolean;
}

export class DeleteCollectionsButton extends Component<Props, State> {
  state = {
    disabled: false
  };

  deleteCollections(close: () => void) {
    const { collectionIds } = this.props;

    if (collectionIds.length) {
      deleteCollections(collectionIds)
        .then(() => {
          Toast.success({
            title: {
              key: "collections.deleteCollectionsButton.deleteSuccess",
              options: { value: collectionIds.length }
            }
          });
          close();
          debounce(() => window.location.reload(), 1000)();
        })
        .catch(error => {
          Toast.apiError(error);
          this.setState({ disabled: false });
        });
    }
  }

  render() {
    const { disabled } = this.state;
    const { collectionIds, mobileView, ...rest } = this.props;
    const buttonEnabled = collectionIds.length !== 0;

    return (
      <Popup
        trigger={
          mobileView ? (
            <Button variant="buttons.mobileButton">
              <MobileTriggerButton
                iconName="RemoveFrom"
                disabled={disabled || !buttonEnabled}
                buttonNameI18n="collections.deleteCollectionsButton.deleteSelected"
              />
            </Button>
          ) : (
            <Button
              width="100%"
              disabled={disabled || !buttonEnabled}
              {...(rest as any)}>
              <Text fontSize={[1, 2, 3]}>
                <Trans i18nKey="collections.deleteCollectionsButton.deleteSelected" />
              </Text>
            </Button>
          )
        }
        heading={i18next.t(
          "collections.deleteCollectionsButton.deleteCollections"
        )}
        submit={close => this.deleteCollections(close)}
        submitText={i18next.t("collections.deleteCollectionsButton.confirm")}
        submitDisabled={disabled}
        cancelText={i18next.t("collections.deleteCollectionsButton.cancel")}
        cancelDisabled={disabled}
        disabled={!buttonEnabled}>
        <>
          <AuthUserContext.Consumer>
            {({ authUser }) =>
              authUser && (
                <Text textAlign="center">
                  {i18next.t("collections.deleteCollectionsButton.popupText", {
                    value: collectionIds.length
                  })}
                </Text>
              )
            }
          </AuthUserContext.Consumer>
        </>
      </Popup>
    );
  }
}
