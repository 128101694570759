import { Icon, Label } from "app/shared";
import i18next from "i18next";
import React, { ReactNode } from "react";
import { components, default as ReactSelect } from "react-select";
import { Box, SxStyleProp, Text } from "rebass";
import { useMediaQueryContext } from "styles/context";
import { colors } from "styles/theme";
import { Trans } from "react-i18next";

export interface SelectOption {
  value: string;
  label: string;
}

interface Props {
  defaultValue?: SelectOption;
  value?: SelectOption[];
  disabled?: boolean;
  labeli18n?: string;
  labelFontSize?: number | number[] | string | string[];
  labelPaddingBottom?: number | number[] | string | string[];
  errorI18n?: string;
  placeholder?: string;
  noOptions?: string | ReactNode;
  onChange: (options: any) => void;
  options: SelectOption[];
  width?: number;
  menuWidth?: number;
  menuMarginTop?: number;
  valuePadding?: number;
  fontSize?: number;
  onInputChange?: (value: string) => void;
  isMulti?: boolean;
  hideDropdownIndicator?: boolean;
  borderBottom?: number;
  sx?: SxStyleProp;
  fullBorder?: boolean;
  marginArrow?: number;
  hideLabels?: boolean;
  variantTitle?: string;
  optionsBold?: boolean;
}

const selectStyles = {
  control: (base: any, state: any) => ({
    ...base,
    backgroundColor: colors.white[0],
    border: state.isFocused ? 0 : 0,
    width: state.selectProps.width ?? base.width,
    boxShadow: state.isFocused ? 0 : 0,
    "&:hover": {
      border: state.isFocused ? 0 : 0
    },
    padding: 0,
    cursor: "pointer",
    minHeight: "initial"
  }),
  option: (base: any, state: any) => ({
    ...base,
    textTransform: "uppercase",
    fontSize: state.selectProps.fontSize ?? "16px",
    fontWeight: state.selectProps.optionsBold ? "bold" : undefined,
    backgroundColor: state.isSelected
      ? colors.brand[0]
      : state.data.bg ?? colors.white[0],
    color: state.isSelected
      ? colors.white[0]
      : state.data.color ?? colors.black[0],
    "&:hover": {
      backgroundColor: colors.brand[0],
      color: colors.white[0]
    }
  }),
  indicatorSeparator: (base: any) => ({
    ...base,
    display: "none"
  }),
  clearIndicator: (base: any) => ({
    ...base,
    padding: 0
  }),
  dropdownIndicator: (base: any, state: any) => ({
    ...base,
    color: colors.black[0],
    "&:hover": {
      color: colors.black[0]
    },
    display: state.selectProps.hideDropdownIndicator ? "none" : undefined,
    padding: 0,
    marginLeft: state.selectProps.marginArrow
  }),
  menu: (base: any, state: any) => ({
    ...base,
    width: state.selectProps.menuWidth ?? base.width,
    borderRadius: 0,
    border: "1px solid",
    borderColor: colors.black[0],
    marginTop: state.selectProps.menuMarginTop ?? base.marginTop,
    marginLeft: -1,
    boxShadow: 0,
    zIndex: 3
  }),
  valueContainer: (base: any, state: any) => ({
    ...base,
    fontSize: state.selectProps.fontSize ?? "16px",
    fontWeight: state.selectProps.optionsBold ? "bold" : undefined,
    textTransform: "uppercase",
    padding: 0,
    "&:before": {
      content: state.selectProps.hideLabels
        ? `"${state.selectProps.placeholder}"`
        : undefined,
      fontFamily: "Haas Grot Text Pro",
      opacity: 0.6,
      fontSize: "12px"
    }
  }),
  multiValue: (base: any, state: any) => ({
    ...base,
    margin: "0 5px 5px 0",
    display: state.selectProps.hideLabels ? "none" : "flex"
  }),
  multiValueLabel: (base: any, state: any) => ({
    ...base,
    backgroundColor: colors.white[0],
    color: colors.black[0],
    borderRadius: 0,
    borderColor: colors.black[0],
    border: "1px solid",
    borderRight: 0,
    display: state.selectProps.hideLabels ? "none" : "flex"
  }),
  multiValueRemove: (base: any, state: any) => ({
    ...base,
    backgroundColor: colors.white[0],
    borderRadius: 0,
    borderColor: colors.black[0],
    border: "1px solid",
    borderLeft: 0,
    display: state.selectProps.hideLabels ? "none" : "flex",
    "&:hover": {
      color: colors.black[0],
      backgroundColor: colors.white[0]
    }
  }),
  placeholder: (base: any, state: any) => ({
    ...base,
    fontSize: "10px",
    color: "#757575",
    display: state.selectProps.hideLabels ? "none" : "",
    fontFamily: "Haas Grot Text Pro",
    letterSpacing: 1.8
  })
};

const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <Icon name="SelectDropdownArrow" size={24} />
    </components.DropdownIndicator>
  );
};

const MultiValueRemove = (props: any) => {
  return (
    <components.MultiValueRemove {...props}>
      <Icon name="Close" size={18} />
    </components.MultiValueRemove>
  );
};

export const Select: React.FC<Props> = ({
  disabled,
  labeli18n,
  labelFontSize,
  labelPaddingBottom,
  errorI18n,
  placeholder,
  onChange,
  options,
  defaultValue,
  width,
  menuWidth,
  menuMarginTop,
  isMulti,
  onInputChange,
  value,
  hideDropdownIndicator,
  marginArrow,
  borderBottom,
  noOptions,
  fullBorder,
  hideLabels,
  variantTitle,
  optionsBold,
  sx,
  ...rest
}) => {
  const { isSmall, isMedium } = useMediaQueryContext();
  const fontSize = isSmall ? "12px" : isMedium ? "14px" : "16px";

  return (
    <Box
      py={fullBorder ? "3px" : ""}
      sx={{
        borderBottom,
        borderColor: "black.0",
        border: fullBorder ? "1px solid black" : "",
        ...sx
      }}
      {...(rest as any)}>
      {labeli18n && (
        <Label
          i18n={labeli18n}
          variant={variantTitle}
          pb={labelPaddingBottom}
          fontSize={labelFontSize ?? [1, 4]}
        />
      )}
      {errorI18n && (
        <Text color="text.danger" pt={1}>
          <Trans i18nKey={errorI18n} />
        </Text>
      )}
      <ReactSelect
        isDisabled={disabled}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        options={options}
        styles={selectStyles}
        components={{ DropdownIndicator, MultiValueRemove }}
        defaultValue={defaultValue}
        width={width}
        menuWidth={menuWidth}
        menuMarginTop={menuMarginTop}
        fontSize={fontSize}
        isMulti={isMulti}
        onInputChange={onInputChange}
        hideDropdownIndicator={hideDropdownIndicator}
        hideLabels={hideLabels}
        optionsBold={optionsBold}
        marginArrow={marginArrow}
        noOptionsMessage={() => noOptions as any} // Using any because package types do not return correct type
      />
    </Box>
  );
};

Select.defaultProps = {
  disabled: false,
  valuePadding: 8,
  borderBottom: 0,
  noOptions: i18next.t("shared.select.noResults"),
  variantTitle: "grotTextCaps"
};
