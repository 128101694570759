import React, { ReactNode } from "react";
import { Image, Button } from "app/shared";
import { Text } from "rebass";
import { Span } from "primitives";

export const BoldUnderline: React.FC<{ children: ReactNode }> = ({
  children
}) => (
  <b>
    <u>{children}</u>
  </b>
);

export const Img: React.FC<{
  src: string;
  width: string | string[];
  height?: string | string[];
}> = ({ src, width, height }) => (
  <Image src={src} width={width} height={height} />
);

export const Link: React.FC<{ children: string; href?: string }> = ({
  children,
  href
}) => {
  const link = href ?? (children as string[])?.[0];
  return (
    <Button href={link} target="_blank">
      <u>{children}</u>
    </Button>
  );
};

export const Paragraph: React.FC<{ children: ReactNode }> = ({ children }) => (
  <Text pl={3}>{children}</Text>
);

export const Btn: React.FC<{ children: string; onClick: () => void }> = ({
  children,
  onClick
}) => (
  <Button onClick={onClick} variant="transparent">
    <u>{children}</u>
  </Button>
);

export const SpanBlue: React.FC<{ children: ReactNode }> = ({ children }) => (
  <Span color="blue">{children}</Span>
);
