import { Button, Popup } from "app/shared";
import i18next from "i18next";
import { Document, ValidationError } from "models";
import { MonetaryAmount } from "marketplace";
import React, { FC } from "react";
import { Trans } from "react-i18next";
import { Box, Flex, Text } from "rebass";
import { DocumentEditor } from "..";

interface Props {
  document: Document;
  version: number;
  errors: ValidationError[];
  basePriceAmount?: MonetaryAmount;
  totalNumDocuments: number;
  currentDocument: number;
  onChange: (document: Document) => void;
  onApplyToAll: (document: Document, basePriceAmount: MonetaryAmount) => void;
  onCheckError?: () => void;
  removeAllLabels: () => void;
  onDelete: (document: Document) => void;
}

export const DocumentDetail: FC<Props> = props => {
  const {
    document,
    errors,
    version,
    totalNumDocuments,
    currentDocument,
    onChange,
    onApplyToAll,
    onCheckError,
    removeAllLabels,
    basePriceAmount,
    onDelete
  } = props;

  return (
    <>
      <Box
        key={document.id}
        mb={5}
        mx={[1, 0]}
        sx={{
          border: "1px solid #000"
        }}>
        <Flex
          flexDirection="row"
          justifyContent="space-between"
          sx={{ borderBottom: 1, borderColor: "black.0" }}>
          <Flex alignItems="center">
            <Text fontSize={7} pl={3} variant="grotTextCaps">
              <Trans i18nKey="documents.fillInformation.image" />{" "}
              {currentDocument + 1}/{totalNumDocuments}
            </Text>
          </Flex>
          <Popup
            trigger={
              <Button variant="black" width="30%" py={3}>
                <Trans i18nKey="documents.fillInformation.deleteImage.button" />
              </Button>
            }
            heading={i18next.t("documents.fillInformation.deleteImage.header")}
            cancelText={i18next.t(
              "documents.fillInformation.deleteImage.cancel"
            )}
            submit={close => {
              onDelete(document);
              close();
            }}
            submitText={i18next.t(
              "documents.fillInformation.deleteImage.submit"
            )}>
            <>
              <Text textAlign="center">
                <Trans i18nKey="documents.fillInformation.deleteImage.content" />
              </Text>
            </>
          </Popup>
        </Flex>
        <Box>
          <DocumentEditor
            version={version}
            errors={errors}
            document={document}
            onChange={onChange}
            applyToAll={totalNumDocuments > 1 ? onApplyToAll : undefined}
            checkError={() => onCheckError?.()}
            removeAllLabels={removeAllLabels}
            basePriceAmount={basePriceAmount}
          />
        </Box>
      </Box>
    </>
  );
};
