import loggedInUserReducer from "app/account/reducers/loggedInUser";
import checkoutReducer from "app/documents/checkout/reducers";
import languageReducer from "i18n/reducers";
import { combineReducers } from "redux";
import messagingReducer from "app/messages/reducers";
import searchReducer from "app/search/reducers";
import notificationsReducer from "app/account/notifications/reducers";

export default combineReducers({
  language: languageReducer,
  checkout: checkoutReducer,
  messages: messagingReducer,
  loggedInUser: loggedInUserReducer,
  search: searchReducer,
  notifications: notificationsReducer
});
