import { AuthUser } from "models";

const checkSameUser = (uid: string, authUser: AuthUser): boolean =>
  uid === authUser.uid;

const isAdmin = (authUser: AuthUser): boolean =>
  authUser.roles.includes("users:admin");

const containsRole = (role: string, authUser: AuthUser): boolean =>
  authUser.roles.includes(role);

export { checkSameUser, isAdmin, containsRole };
