import {
  Button,
  Checkbox,
  Input,
  MobileTriggerButton,
  Popup,
  Toast
} from "app/shared";
import i18next from "i18next";
import debounce from "lodash.debounce";
import { Bucket, Privacy } from "models";
import React, { Component } from "react";
import { Trans } from "react-i18next";
import { BoxProps, Flex, Text } from "rebass";
import { createBucket, updateBucket } from "./services";

interface Props extends BoxProps {
  bucket?: Bucket;
  mobileView?: boolean;
  open?: boolean;
  onCompleted?: (bucket: Bucket) => void;
  onOpen?: () => void;
  onClose?: () => void;
  hideTrigger?: boolean;
}

interface State {
  bucket: Bucket;
  disabled: boolean;
}

export class BucketEditPopup extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      bucket: props.bucket
        ? props.bucket
        : {
            id: -1,
            createdAt: new Date().toISOString(),
            privacy: "private",
            uid: "",
            owner: { id: "", name: "" },
            title: "",
            totalDocuments: 0,
            totalCollections: 0,
            totalItems: 0,
            documents: []
          },
      disabled: false
    };
  }

  editBucket(close: () => void) {
    const { onCompleted } = this.props;
    const { id, title, privacy } = this.state.bucket;

    this.setState({ disabled: true }, () => {
      const promise =
        id > 0
          ? updateBucket(id, privacy, title, undefined, undefined)
          : createBucket(privacy, title);

      promise
        .then(bucket => {
          Toast.success({
            title: { key: "buckets.bucketEditPopup.confirmationToast" }
          });
          close();
          if (onCompleted) {
            onCompleted(bucket);
          } else {
            // Waiting a few for backend re-index changes.
            // TODO: This can be replaced if we manually change affected elements locally.
            debounce(
              () =>
                this.setState({ disabled: false }, () =>
                  window.location.reload()
                ),
              1000
            )();
          }
        })
        .catch(error => {
          Toast.apiError(error);
          this.setState({ disabled: false });
        });
    });
  }

  handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { bucket } = this.state;

    this.setState({
      bucket: {
        ...bucket,
        [event.target.name]: event.target.value
      }
    });
  };

  handleOnCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { bucket } = this.state;

    this.setState({
      bucket: {
        ...bucket,
        privacy: event.target.name as Privacy
      }
    });
  };

  render() {
    const {
      mobileView,
      open,
      onClose,
      onCompleted,
      onOpen,
      hideTrigger,
      ...rest
    } = this.props;
    const { disabled, bucket } = this.state;
    const trigger = hideTrigger ? (
      undefined
    ) : open !== undefined ? (
      undefined
    ) : mobileView ? (
      <Button variant="buttons.mobileButton">
        <MobileTriggerButton
          iconName="CreateCollection"
          disabled={disabled}
          buttonNameI18n={`buckets.bucketEditPopup.${
            bucket.id > 0 ? "editBucket" : "createBucket"
          }`}
        />
      </Button>
    ) : (
      <Button width="100%" disabled={disabled} {...(rest as any)}>
        <Trans
          i18nKey={`buckets.bucketEditPopup.${
            bucket.id > 0 ? "editBucket" : "createBucket"
          }`}
        />
      </Button>
    );

    return (
      <Popup
        open={open}
        trigger={trigger}
        heading={i18next.t(
          `buckets.bucketEditPopup.${
            bucket.id > 0 ? "editBucket" : "createBucket"
          }`
        )}
        submit={close => this.editBucket(close)}
        submitText={i18next.t(
          `buckets.bucketEditPopup.${bucket.id > 0 ? "edit" : "create"}`
        )}
        submitDisabled={bucket.title.length === 0 || disabled}
        cancelDisabled={disabled}
        onOpen={onOpen}
        onClose={onClose}>
        <>
          <Input
            name="title"
            type="text"
            labelI18n="buckets.bucketEditPopup.bucketName"
            onChange={this.handleOnChange}
            value={bucket.title}
            mb={3}
            labelAlignment="left"
          />
          <Flex>
            {["public", "private"].map(privacy => (
              <Checkbox
                key={privacy}
                name={privacy}
                width="50%"
                checked={bucket.privacy === privacy}
                onChange={this.handleOnCheckboxChange}>
                <Text fontSize={[1, 3, 6]} sx={{ textTransform: "capitalize" }}>
                  {i18next.t(`buckets.bucketEditPopup.${privacy}`)}
                </Text>
              </Checkbox>
            ))}
          </Flex>
        </>
      </Popup>
    );
  }
}
