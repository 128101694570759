import {
  PortfolioReviewForm,
  PortfolioReviewInProgress
} from "./ReviewPortfolioForms";
import { Toast } from "app/shared";
import React, { Component } from "react";
import { PortfolioReviewService } from "../services/PortfolioReviewService";
import {
  PortfolioReviewStatus,
  PortfolioReviewProcess
} from "../models/PortfolioReviewProcess";
import { Invite } from "../models";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { compose } from "recompose";

const PORTFOLIO_REVIEW_INTERVAL_MS = 10000;

interface PropsExternal {
  uid: string;
  onSkip: () => void;
  onComplete: () => void;
  invite?: Invite;
}

interface Props extends PropsExternal, RouteComponentProps {}

interface State {
  portfolioReviewStatus?: PortfolioReviewStatus;
  portfolioReviewService?: PortfolioReviewService;
  portfolioStatusInterval?: number;
}

class ReviewPortfolioBase extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      portfolioReviewStatus: undefined,
      portfolioReviewService: undefined,
      portfolioStatusInterval: undefined
    };
  }

  componentDidMount() {
    const { uid, invite } = this.props;

    // Check if there is a portfolio validation invite and confirm it
    if (invite && invite.type === "PortfolioValidation") {
      if (invite.id > 0 && invite.code.length) {
        this.confirmPortfolioInvite(uid, invite.id, invite.code);
      }
    } else {
      this.updateAccordingToPortfolioReviewStatus(uid);
    }
  }

  componentWillUnmount() {
    if (this.state.portfolioStatusInterval) {
      clearInterval(this.state.portfolioStatusInterval);
      this.setState({ portfolioStatusInterval: undefined });
    }
  }

  render() {
    const { onSkip } = this.props;
    const { portfolioReviewStatus, portfolioReviewService } = this.state;

    if (portfolioReviewStatus === "Pending") {
      return <PortfolioReviewInProgress />;
    } else if (portfolioReviewService) {
      return (
        <PortfolioReviewForm
          portfolioReviewService={portfolioReviewService}
          onSkip={onSkip}
          onComplete={this.updatePortfolioReviewStatus}
        />
      );
    } else {
      return <></>;
    }
  }

  private confirmPortfolioInvite(
    uid: string,
    inviteId: number,
    inviteCode: string
  ) {
    const { history } = this.props;

    const portfolioReviewService =
      this.state.portfolioReviewService ?? new PortfolioReviewService(uid);
    portfolioReviewService
      .confirmPortfolioInvite({ id: inviteId, code: inviteCode })
      .then(() => {
        history.push(history.location.pathname); // remove invite from path
        window.location.reload(); // force the page to reload to refresh the token
      })
      .catch(err => {
        Toast.apiError(err);
      });
  }

  private updateAccordingToPortfolioReviewStatus(uid: string) {
    if (!this.state.portfolioReviewService) {
      this.setState(
        { portfolioReviewService: new PortfolioReviewService(uid) },
        () => {
          this.runUpdate();
        }
      );
    } else {
      this.runUpdate();
    }
  }

  // Since this is the first run of the get portfolio validation status, this does not
  // require the refresh on 404, since at this point a 404 can only mean that the user
  // has not been verified
  private runUpdate() {
    this.state.portfolioReviewService
      ?.getPortfolioValidationStatus()
      .then(portfolioReview => {
        this.setState({ portfolioReviewStatus: portfolioReview.status });
        this.startPortfolioReviewStatusListener(portfolioReview.status);
      })
      .catch(err => {
        if (err?.response?.status !== 404) {
          console.error(err);
        }
      });
  }

  private startPortfolioReviewStatusListener(status: PortfolioReviewStatus) {
    const { uid } = this.props;
    if (
      status === "Pending" &&
      !this.state.portfolioStatusInterval &&
      uid !== ""
    ) {
      if (!this.state.portfolioReviewService) {
        this.setState({
          portfolioReviewService: new PortfolioReviewService(uid)
        });
      }

      this.setState({
        portfolioStatusInterval: setInterval(() => {
          this.fetchReviewProcessUpdates();
        }, PORTFOLIO_REVIEW_INTERVAL_MS)
      });
    } else if (status !== "Pending" && this.state.portfolioStatusInterval) {
      clearInterval(this.state.portfolioStatusInterval);
      this.setState({ portfolioStatusInterval: undefined });
    }
  }

  // Code that fetches the portfolio review process after it has been submitted. Once this method
  // returns 404 again, the page is refreshed.
  private fetchReviewProcessUpdates() {
    this.state.portfolioReviewService
      ?.getPortfolioValidationStatus()
      .then(portfolioReview => {
        this.setState({ portfolioReviewStatus: portfolioReview.status });
      })
      .catch(err => {
        if (err?.response?.status !== 404) {
          console.error(err);
        } else {
          window.location.reload(); // force the page to reload to refresh the token
        }
      });
  }

  private updatePortfolioReviewStatus = (
    portfolioReview: PortfolioReviewProcess
  ) => {
    this.setState({ portfolioReviewStatus: portfolioReview.status });
    this.startPortfolioReviewStatusListener(portfolioReview.status);
  };
}

export const ReviewPortfolio = compose<Props, PropsExternal>(withRouter)(
  ReviewPortfolioBase
);
