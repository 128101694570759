import styled from "@emotion/styled";
import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { BoxProps, Flex, Text } from "rebass";
import { compose } from "recompose";

interface PropsExternal extends BoxProps {
  disabled?: boolean;
  onClick?: () => void;
  href?: string;
  isText?: boolean;
  borderLeft?: number;
  borderRight?: number;
  backgroundColor?: string;
  flexShrink?: number;
}

interface Props extends PropsExternal, RouteComponentProps {}

const FlexWrapper = styled(Flex)<Props & any>`
  outline: none;
  text-transform: uppercase;
  cursor: ${props => !props.disabled && "pointer"};
  ${props =>
    props.disabled &&
    `
    pointer-events: none;
    opacity: 0.5;
  `}
`;

const NavbarButtonBase: React.FC<Props> = ({
  children,
  history,
  disabled,
  href,
  onClick,
  isText,
  borderLeft,
  borderRight,
  backgroundColor,
  flexShrink,
  ...rest
}) => {
  return (
    <FlexWrapper
      alignItems="center"
      justifyContent="center"
      backgroundColor={backgroundColor}
      onClick={href ? () => history.push(href) : onClick}
      sx={{
        borderLeft,
        borderRight,
        flexShrink,
        borderColor: "black.0",
        "&:active": {
          background: "black.0",
          color: "white.0"
        },
        "&:hover": { background: "blue.0", color: "white.0" }
      }}
      {...(rest as any)}>
      {isText ? <Text fontSize={[1, 3, 5]}>{children}</Text> : <>{children}</>}
    </FlexWrapper>
  );
};

NavbarButtonBase.defaultProps = {
  borderLeft: 1,
  backgroundColor: "white"
};

const NavbarButton = compose<Props, PropsExternal>(withRouter)(
  NavbarButtonBase
);

export default NavbarButton;
