import { createSearchUrl } from "app/search/utils";
import { Button, GridLabel } from "app/shared";
import { MultilineText } from "app/shared/multilineText";
import { getCommonTranslation } from "app/shared/translations/utils";
import { Document } from "models";
import React from "react";
import { Box, Flex, Text } from "rebass";
import { DocumentDataGrid } from "./DocumentDataGrid";
import { Metadata } from "app/shared";
import i18next from "i18next";

interface Props {
  document: Document;
}

const DocumentData: React.FC<Props> = ({ document }) => {
  // Here it's assumed we will only present the first created translation.
  // When we add support for multiple language translations this have to be changed.
  const translation = document.translations?.[0];

  return (
    <Box width={["100%"]} px={[2, 2, 0]}>
      <Box>
        {document.userLabels.length > 0 &&
          document.userLabels.map(label => (
            <Button
              key={label.id}
              variant="link.button.categoriesAndLabels"
              bg="black.0"
              href={createSearchUrl(
                "document",
                ["document"],
                [
                  {
                    field: "labels",
                    values: [label.title],
                    type: "any"
                  }
                ]
              )}
              mr={2}
              mb={2}>
              {label.title}
            </Button>
          ))}
      </Box>
      <Box>
        {document.userCategories.length > 0 &&
          document.userCategories.map(it => {
            const label = `${it.taxonomy}=${it.translations?.[0]?.title}`;
            return (
              <Button
                key={label}
                variant="link.button.categoriesAndLabels"
                bg="brand.0"
                href={createSearchUrl(
                  "document",
                  ["document"],
                  [
                    {
                      field: "categories",
                      values: [label],
                      type: "any"
                    }
                  ]
                )}
                mr={2}
                mb={2}>
                {getCommonTranslation(label)}
              </Button>
            );
          })}
      </Box>
      <Flex flexDirection="column" mb={7} mt={6}>
        <Box sx={{ border: 1, borderBottom: 0, wordBreak: "break-all" }}>
          <Box>
            <GridLabel
              px={[1, 2, 3]}
              pt={2}
              i18nKey="documents.documentDetails.documentData.photoTitle"
            />
            <Text
              px={[1, 2, 3]}
              fontSize={[3, 8]}
              variant="grotText"
              py={2}
              sx={{ textTransform: "uppercase" }}>
              {translation && translation.title && translation.title.length > 0
                ? translation.title
                : i18next.t("documents.documentView.noTitle")}
            </Text>
            <Metadata
              title={
                translation && translation.title && translation.title.length > 0
                  ? translation.title
                  : i18next.t("documents.documentView.noTitle")
              }
            />
          </Box>
          {translation?.description && translation.description.length > 0 && (
            <>
              <MultilineText
                fontSize={[2, 4, 6]}
                py={2}
                px={[1, 2, 3]}
                text={translation.description}
              />
              <Metadata description={translation.description} />
            </>
          )}
        </Box>
        <DocumentDataGrid document={document} />
      </Flex>
    </Box>
  );
};

export default DocumentData;
