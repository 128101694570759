import React from "react";
import { Box, Flex, Text } from "rebass";
import { useMediaQueryContext } from "styles/context";
import { BackButton, Button, Image } from "app/shared";
import logo from "./image/FrontTalksLogo_brazil.svg";
import { ContentArea } from "layout";
import { FrontTalksCardFestival } from "./FrontTalksCardFestival";
import { Trans } from "react-i18next";
import { WhatsappIcon } from "react-share";

export const FrontTalksPage = () => {
  const { isSmallMedium } = useMediaQueryContext();
  const date = window.location.pathname.split("/")[3];

  return (
    <ContentArea px={[2, 0]}>
      {date === "festival" && (
        <>
          <Box mb={2}>
            <BackButton />
          </Box>
          <Box>
            <FrontTalksCardFestival hideButton={true} />
          </Box>
          <Text
            sx={{ textTransform: "uppercase" }}
            variant="grotText"
            fontSize={[4, 6]}
            mt={9}>
            <Trans i18nKey="events.festival.page.logo" />
          </Text>
          <Text fontSize={[8, 11]} mt={6}>
            <Trans i18nKey="events.festival.page.header1" />
          </Text>
          <Text fontSize={[8, 11]}>
            <Trans i18nKey="events.festival.page.header2" />
          </Text>
          <Box>
            <Text
              sx={{ textTransform: "uppercase" }}
              variant="grotText"
              fontSize={[4, 6]}
              mt={6}>
              <Trans i18nKey="events.festival.page.location" />
            </Text>
          </Box>
          <Box>
            <Text
              sx={{ textTransform: "uppercase" }}
              variant="grotText"
              fontSize={[4, 6]}
              mt={8}>
              <Trans i18nKey="events.festival.page.titleContent" />
            </Text>
          </Box>
          <Box>
            <Text
              width={["100%", "50%"]}
              sx={{ display: "inline-block", textAlign: "left" }}
              fontSize={[3, 5]}
              mt={6}>
              <Trans i18nKey="events.festival.page.content" />
            </Text>
          </Box>
          <Box mt={6}>
            <Button
              width={isSmallMedium ? "100%" : undefined}
              href="https://www.fotografiaemtempoeafeto.com/convocatória-edição-v"
              target="_blank"
              variant="link.button.blue">
              <Trans i18nKey="events.festival.page.siteButton" />
            </Button>
          </Box>
          <Text mt={6} variant="caps">
            <Trans i18nKey="events.festival.page.whatsappGroup" />
          </Text>
          <Button
            mt={2}
            variant="transparent"
            href="https://chat.whatsapp.com/E7ZG0DX4iEjLBXjFAzC1Wm"
            target="_blank">
            <WhatsappIcon size={64} iconBgStyle={{ fill: "#48CB59" }} />
          </Button>
        </>
      )}
      {date === "13-01-2022" && (
        <>
          <Box mb={6}>
            <BackButton />
          </Box>
          <Box>
            <Image src={logo} width="301px" height="44px" />
          </Box>
          <Box mt={6}>
            <iframe
              width={isSmallMedium ? "100%" : "1268"}
              height={isSmallMedium ? "400" : "713"}
              allowFullScreen
              src="https://www.youtube.com/embed/jbnJL1lbP2c"
              title="FrontTalks Video"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
          </Box>
          <Text variant="caps" fontSize={[8, 11]} mt={6}>
            MULHERES NA FOTOGAFIA
          </Text>
          <Box>
            <Text
              sx={{ textTransform: "uppercase" }}
              variant="grotText"
              fontSize={[4, 6]}
              mt={6}>
              13 de janeiro, 19 horas
            </Text>
          </Box>
          <Box>
            <Text
              sx={{ textTransform: "uppercase" }}
              variant="grotText"
              fontSize={[4, 6]}
              mt={6}>
              CONTEXTO:
            </Text>
          </Box>
          <Box>
            <Text
              width={["100%", "50%"]}
              sx={{ display: "inline-block", textAlign: "left" }}
              fontSize={[3, 5]}
              mt={6}>
              “A profissão se caracteriza pela falta de relação empregatícia,
              mas enquanto 60% dos homens estão em relações informais de
              trabalho, 86% das mulheres prestam serviços sem contrato. Quando
              existe contrato, apenas 7% são do gênero feminino, enquanto os
              homens chegam a 22%. Dentre as fotojornalistas, 82% possuem
              graduação completa, enquanto apenas 69% dos homens possuem nível
              universitário. No entanto, em todas as faixas salariais que foram
              investigadas, as mulheres ganham menos que os homens.” (REUTERS /
              2016; pesquisa realizada em 100 países).
              <br />
              <br />
              “Além da falta de espaço para mulheres no ramo, as fotógrafas
              ainda enfrentam estereótipos relacionados às áreas de atuação
              consideradas “femininas” — tais como acreditar que mulheres são
              mais indicadas para produzir imagens familiares ou relacionadas à
              maternidade como ensaios de recém-nascido, de família ou de
              gestante.” (Elas por Elas/ Mulheres do PT)
              <br />
              <br />
              “Profissão essencialmente colonialista, machista, misógina e
              hegemonicamente protagonizada por homens brancos e de classe
              média/alta. Homens esses que ocupam e protagonizam histórias a
              partir de suas percepções, do olhar desbravador – a salvação sobre
              um lugar, um corpo, uma raça, um gênero desde sua origem”.
              (Manifesto contra referência/Mamana).
              <br />
              <br />
              Provocações:
              <br />
              - A posição da mulher no mercado fotográfico;
              <br />
              - Maiores dificuldades;
              <br />
              - Estratégias para a colocação no mercado;
              <br />
              - Particularidades do olhar feminino;
              <br />- Coletivos que buscam dar visibilidade para o olhar
              feminino na fotografia e no fotojornalismo.
            </Text>
          </Box>
          <Text
            variant="grotText"
            fontSize={[4, 6]}
            mt={6}
            sx={{ textTransform: "uppercase" }}>
            CONVIDADOS
          </Text>
          <Flex flexDirection={["column", "row"]} mt={6}>
            <Text variant="grotText" fontSize={[3, 5]}>
              Mônica Maia
            </Text>
            <Text pt={"3px"} fontSize={[3, 5]}>
              {" "}
              {!isSmallMedium && <> &nbsp;</>} (Mulheres Luz/SP)
            </Text>
          </Flex>
          <Flex flexDirection={["column", "row"]} mt={2}>
            <Text variant="grotText" fontSize={[3, 5]}>
              Ana Carolina Fernandes
            </Text>
            <Text pt={"3px"} fontSize={[3, 5]}>
              {" "}
              {!isSmallMedium && <> &nbsp;</>} (Trófeu Mulher Imprensa/RJ)
            </Text>
          </Flex>
          <Flex flexDirection={["column", "row"]} mt={2}>
            <Text variant="grotText" fontSize={[3, 5]}>
              Marcela Bondim
            </Text>
            <Text pt={"3px"} fontSize={[3, 5]}>
              {" "}
              {!isSmallMedium && <> &nbsp;</>} (Mulheres Luz FF/BA)
            </Text>
          </Flex>
          <Text
            variant="grotText"
            fontSize={[4, 6]}
            mt={6}
            sx={{ textTransform: "uppercase", textAlign: "center" }}>
            13 de janeiro, 19 horas
          </Text>
        </>
      )}
      {date === "4-11-2021" && (
        <>
          <Box mb={6}>
            <BackButton />
          </Box>
          <Box>
            <Image src={logo} width="301px" height="44px" />
          </Box>
          <Box mt={6}>
            <iframe
              width={isSmallMedium ? "100%" : "1268"}
              height={isSmallMedium ? "400" : "713"}
              allowFullScreen
              src="https://www.youtube.com/embed/m6hF5eGsiII"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
          </Box>
          <Box width={["100%", "70%"]}>
            <Box>
              <Text variant="grotText" fontSize={[3, 6]} mt={6}>
                A FAVELA NO FRONT
              </Text>
            </Box>
            <Text variant="caps" fontSize={[8, 11]} mt={6}>
              Caminhos da fotografia
              <br />
              da periferia ao centro
            </Text>
            <Box>
              <Text
                sx={{ textTransform: "uppercase" }}
                variant="grotText"
                fontSize={[4, 6]}
                mt={6}>
                4 de novembro, 19 horas
              </Text>
            </Box>
            <Box>
              {" "}
              <Text variant="grotText" fontSize={[3, 5]} mt={6}>
                Inspirado no Dia da Favela, o Frontfiles promove uma conversa
                com
                <br />
                fotógrafos de favelas, mídias periféricas e mercado editorial
                sobre o<br />
                trabalho fotográfico do profissional oriundo da favela - suas
                <br />
                particularidades, desafios e propostas.
              </Text>
            </Box>
            <Box>
              <Text variant="grotText" fontSize={[3, 5]} mt={6}>
                {" "}
                • Fotógrafo da favela só pode fotografar favela?
                <br />• Como é o acesso à profissionalização na área da
                fotografia?
                <br />• O centro está interessado no olhar periférico?
                <br />• Um editor de fotografia tem interesse no conteúdo
                fotográfico da favela somente quando se trata de violência?
                <br />• Qual a visão do fotógrafo periférico em relação ao
                centro?
              </Text>
            </Box>
            <Text
              variant="grotText"
              fontSize={[4, 6]}
              mt={6}
              sx={{ textTransform: "uppercase" }}>
              MODERAÇÃO
            </Text>
            <Flex flexDirection={["column", "row"]} mt={6}>
              <Text variant="grotText" fontSize={[3, 5]}>
                Bárbara Nascimento
              </Text>
              <Text pt={"3px"} fontSize={[3, 5]}>
                {" "}
                {!isSmallMedium && <>,</>} Vidigal, Frontfiles
              </Text>
            </Flex>
            <Text
              variant="grotText"
              fontSize={[4, 6]}
              mt={6}
              sx={{ textTransform: "uppercase" }}>
              CONVIDADOS
            </Text>
            <Flex flexDirection={["column", "row"]} mt={6}>
              <Text variant="grotText" fontSize={[3, 5]}>
                Anderson França
              </Text>
              <Text pt={"3px"} fontSize={[3, 5]}>
                {" "}
                {!isSmallMedium && <>,</>} Vila Aliança, Coluna de terça
              </Text>
            </Flex>
            <Flex flexDirection={["column", "row"]}>
              <Text variant="grotText" fontSize={[3, 5]}>
                Bruno Itan
              </Text>
              <Text pt={"3px"} fontSize={[3, 5]}>
                {" "}
                {!isSmallMedium && <>,</>} Alemã, Fotógrafo
              </Text>
            </Flex>
            <Flex flexDirection={["column", "row"]}>
              <Text variant="grotText" fontSize={[3, 5]}>
                Diego Cardoso
              </Text>
              <Text pt={"3px"} fontSize={[3, 5]}>
                {" "}
                {!isSmallMedium && <>,</>} Rocinha, Fotógrafo, Rocinha Sob
                Lentes
              </Text>
            </Flex>
            <Flex flexDirection={["column", "row"]}>
              <Text variant="grotText" fontSize={[3, 5]}>
                Matheus Andrade
              </Text>
              <Text pt={"3px"} fontSize={[3, 5]}>
                {" "}
                {!isSmallMedium && <>,</>} Jornalista, Voz das Comunidades
              </Text>
            </Flex>
            <Flex flexDirection={["column", "row"]}>
              <Text variant="grotText" fontSize={[3, 5]}>
                Maurício Hora
              </Text>
              <Text pt={"3px"} fontSize={[3, 5]}>
                {" "}
                {!isSmallMedium && <>,</>} Providência, Fotógrafo, Casa Amarela
              </Text>
            </Flex>
            <Flex flexDirection={["column", "row"]}>
              <Text variant="grotText" fontSize={[3, 5]}>
                Otávio Vale
              </Text>
              <Text pt={"3px"} fontSize={[3, 5]}>
                {" "}
                {!isSmallMedium && <>,</>} Editor de fotografia, Folha de São
                Paulo{" "}
              </Text>
            </Flex>
            <Flex flexDirection={["column", "row"]}>
              <Text variant="grotText" fontSize={[3, 5]}>
                Rosilene Miliotti
              </Text>
              <Text pt={"3px"} fontSize={[3, 5]}>
                {" "}
                {!isSmallMedium && <>,</>} Maré, Jornalista, Fotógrafa popular
              </Text>
            </Flex>
            <Flex flexDirection={["column", "row"]}>
              <Text variant="grotText" fontSize={[3, 5]}>
                Rumba Gabriel
              </Text>
              <Text pt={"3px"} fontSize={[3, 5]}>
                {" "}
                {!isSmallMedium && <>,</>} Jacarezinho, Jornalista, Portal
                Favelas
              </Text>
            </Flex>
            <Flex flexDirection={["column", "row"]}>
              <Text variant="grotText" fontSize={[3, 5]}>
                Thaís Alvarenga
              </Text>
              <Text pt={"3px"} fontSize={[3, 5]}>
                {" "}
                {!isSmallMedium && <>,</>} Vila Kennedy, Fotógrafa,
                Negras[fotos]grafias, Favela em Foco, CRUA, Zona Onírica
              </Text>
            </Flex>
            <Text
              variant="grotText"
              fontSize={[4, 6]}
              mt={6}
              sx={{ textTransform: "uppercase" }}>
              Sobre o 4 DE NOVEMBRO: DIA DA FAVELA
            </Text>
            <Text
              sx={{ display: "inline-block", textAlign: "left" }}
              variant="grotText"
              fontSize={[3, 5]}
              mt={6}>
              A data refere-se à primeira vez que o termo apareceu em um
              documento oficial. Tratava-se de um ofício de cunho higienista,
              enviado pelo delegado de polícia da 10º. Circunscrição ao então
              chefe de polícia Enéas Galvão, em 1900. O documento publicado no
              Jornal do Brasil sugeria uma intervenção na Providência (primeira
              favela do Brasil), devido a problemas sanitários, sociais, morais
              e criminais. A proposta era “limpar” a favela, lugar “infestado de
              vagabundos e criminosos que são o sobressalto das famílias no
              local designado, sem bem que não há famílias no local designado”.
              <br />
              <br />
              Com o objetivo de romper com estigmas relacionados à favela, em
              2005, a CUFA reuniu mais de 700 mil assinaturas em apoio ao dia 4
              de novembro como o “Dia da Favela”.
              <br />
              <br />
              Embora a data já fosse considerada por diversos favelados, somente
              em 2019, o governo do Rio de Janeiro alterou a lei Nº 5.645 e
              instituiu o dia 4 de novembro como o dia estadual da favela,
              através da Lei Ordinária N° 8489/2019, como forma de relembrar o
              histórico de luta, resistência, contribuição cultural e
              sociabilidade das favelas.
              <br />
              <br />
              Inspirado na data, o Frontfiles deseja propor uma conversa online
              com fotógrafos oriundos de favela, mídias periféricas e mercado
              editorial. Desejamos discutir as particularidades e desafios do
              trabalho do fotógrafo de favela e as estratégias capazes de
              minimizar ou diluir as dificuldades e coletivizar as soluções.
              <br />
              <br />O encontro será virtual, através da plataforma Zoom, no dia
              4 de novembro, às 19 horas. A transmissão será ao vivo para todos
              os convidados, através do link de exibição, e ficará gravada para
              posterior visualização de quem desejar.
            </Text>
          </Box>
          <Text
            variant="grotText"
            fontSize={[4, 6]}
            mt={6}
            sx={{ textTransform: "uppercase", textAlign: "center" }}>
            4 de novembro, 19 horas
          </Text>
        </>
      )}
    </ContentArea>
  );
};
