import React from "react";
import Svg, { SvgProps } from "primitives/Svg";

const SvgShare2 = ({ width, height, size, color }: SvgProps) => (
  <Svg
    width={size || width}
    height={size || height}
    viewBox="0 0 23 19"
    color={color}>
    <path
      d="M12.225 12.75v-.6h-2.85s0 0 0 0a10.724 10.724 0 00-8.342 3.98c.56-5.355 5.088-9.53 10.592-9.53h.6V1.623l9.69 7.752-9.69 7.752V12.75z"
      stroke="#000"
      strokeWidth={1.2}
    />
  </Svg>
);

SvgShare2.defaultProps = {
  width: 24,
  height: 24
};
export default SvgShare2;
