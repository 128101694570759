import { ContentArea } from "layout";
import React from "react";
import { PageEmptyState } from "app/shared";
import { Trans } from "react-i18next";

export const NotFound: React.FC = () => (
  <ContentArea>
    <PageEmptyState header={<Trans i18nKey="home.notFound.title" />} />
  </ContentArea>
);
