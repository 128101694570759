import React, { ReactNode } from "react";
import { Box, BoxProps, Flex, Text } from "rebass";
import { Label } from "../label";

type I18nDotSeparatedString = string;

interface Props extends BoxProps {
  tableName?: I18nDotSeparatedString;
  columns: number;
  tableNodes: ReactNode[];
  columnSizeArray?: string[];
}

export const EmptyHeader: React.FC<BoxProps> = ({ ...rest }) => (
  <Text fontSize={1} mb={0} {...(rest as any)}>
    &nbsp;
  </Text>
);

export const TableLayout: React.FC<Props> = ({
  tableName,
  columns,
  tableNodes,
  columnSizeArray,
  ...rest
}) => {
  const sx: any = {
    "& > *": {
      width: ["", `calc(100% / ${columns})`],
      p: 2
    }
  };

  // :nth-child(3n + 1) will set every first column (of a table with 3 columns) to the specified style
  if (columnSizeArray) {
    columnSizeArray.forEach((size, index) => {
      sx[`& > :nth-child(${columns}n + ${index + 1})`] = {
        width: size
      };
    });
  }

  return (
    <Box
      width={[1, 3 / 4, 2 / 3]}
      sx={{ overflowX: "auto" }}
      {...(rest as any)}>
      {tableName && (
        <Label i18n={tableName} fontSize={1} variant="grotTextCaps" />
      )}
      <Box width={["800px", "800px", "100%"]}>
        <Flex width="100%" flexWrap="wrap" alignItems="center" sx={sx}>
          {tableNodes}
        </Flex>
      </Box>
    </Box>
  );
};
