import React from "react";
import Svg, { SvgProps } from "primitives/Svg";

const SvgSquare = ({ width, height, size, color }: SvgProps) => (
  <Svg
    width={size || width}
    height={size || height}
    viewBox="0 0 16 16"
    color={color}>
    <path d="M0 0h16v16H0V0z" />
    <path stroke="#000" d="M.5.5h15v15H.5z" />
  </Svg>
);

SvgSquare.defaultProps = {
  width: 24,
  height: 24
};
export default SvgSquare;
