import { Button, Icon, Toast } from "app/shared";
import React from "react";
import { Trans } from "react-i18next";
import { BoxProps } from "rebass";
import { downloadDocument } from "../services";

interface Props extends BoxProps {
  documentId: number;
  showIcon?: boolean;
}

export const DownloadButton: React.FC<Props> = ({
  documentId,
  showIcon,
  ...rest
}) => {
  const handleOnClick = () => {
    downloadDocument(documentId)
      .then(signedUrl => window.open(signedUrl.url))
      .catch(error => Toast.apiError(error));
  };
  return (
    <Button onClick={handleOnClick} width="100%" {...(rest as any)}>
      {showIcon ? (
        <Icon name="Download" color="blue" size={16} />
      ) : (
        <Trans i18nKey="documents.documentDetails.downloadButton.download" />
      )}
    </Button>
  );
};
