import { BusinessInfo, VerifyAccount } from "app/account/onboarding";
import { Step } from "app/shared/stepper/Step";
import Steps from "app/shared/stepper/Steps";
import { Firebase } from "authentication/firebase";
import { withAuthorization } from "authentication/session";
import i18next from "i18next";
import { ContentArea } from "layout";
import { Transaction } from "marketplace";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Flex } from "rebass";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { compose } from "recompose";
import { CheckoutItem } from "./models";
import { CheckoutSteps } from "./models/constants";
import { ResultOrder } from "./ResultOrder";
import { ReviewItems } from "./ReviewItems";
import { SelectPayment } from "./SelectPayment";
import { PageEmptyState, PageHeader, Toast } from "app/shared";
import { User, USER_VERIFIED_ROLE } from "models";
import { getUser } from "app/account/services";
import { Trans } from "react-i18next";
import { firebaseAnalytics } from "analytics/firebaseAnalytics";

interface Props extends RouteComponentProps {
  firebase: Firebase;
  checkout: CheckoutItem[];
}

interface State {
  step: number;
  user?: User;
  roles: string[];
  transaction?: Transaction;
}

class CheckoutBase extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      step: -1,
      user: undefined,
      roles: [],
      transaction: undefined
    };
  }

  componentDidMount() {
    const { firebase } = this.props;

    const { step } = this.state;

    if (step === 1) {
      firebaseAnalytics.logEvent("view_cart", {});
    } else if (step === 2) {
      firebaseAnalytics.logEvent("begin_checkout", {});
    }

    firebase.getIdToken(true).then(token => {
      const uid = firebase.getCurrentUser().uid;
      const roles = token.roles;

      this.setState({ roles }, () => {
        if (roles.includes(USER_VERIFIED_ROLE)) {
          this.setState({ step: 3 });
        } else {
          getUser(uid).then(user => {
            const step = user.status === "pendingReview" ? 2 : 1;
            this.setState({ user, step });
          });
        }
      });
    });
  }

  private renderVerifyAccount(user: User) {
    if (user.status === "pendingReview") {
      return (
        <PageEmptyState
          header={
            <Trans i18nKey="documents.checkout.verifyAccount.inVerification" />
          }
          description={
            <Trans i18nKey="documents.checkout.verifyAccount.inVerificationDescription" />
          }
        />
      );
    } else {
      return (
        <VerifyAccount
          user={user}
          onComplete={user => this.setState({ user })}
          submitWidth="100%"
          submitPadding={3}
        />
      );
    }
  }

  getSteps(roles: string[]) {
    if (!roles.includes(USER_VERIFIED_ROLE)) {
      return CheckoutSteps.slice(0, 2);
    } else {
      return CheckoutSteps.slice(2);
    }
  }

  render() {
    const { checkout } = this.props;
    const { step, user, roles, transaction } = this.state;
    const checkoutSteps = this.getSteps(roles);
    const selectedStep = CheckoutSteps.find(it => it.stepNumber === step);

    return (
      <ContentArea>
        {selectedStep && (
          <PageHeader variant="2" title={i18next.t(selectedStep.i18n)} mb={4} />
        )}
        <Flex flexDirection="column" alignItems="center" mb={4}>
          <Steps>
            {checkoutSteps.map((checkoutStep, index) => (
              <Step
                key={index}
                index={index + 1}
                stepNumber={checkoutStep.stepNumber}
                title={i18next.t(checkoutStep.i18n)}
                currentStep={step}
                totalSteps={CheckoutSteps.length}
                changeStep={step => this.setState({ step })}
              />
            ))}
          </Steps>
        </Flex>

        {step === 1 && user && (
          <BusinessInfo
            user={user}
            onComplete={user => this.setState({ step: 2, user })}
          />
        )}
        {step === 2 && user && this.renderVerifyAccount(user)}
        {step === 3 && (
          <ReviewItems
            checkout={checkout}
            onComplete={() => this.setState({ step: 4 })}
          />
        )}
        {step === 4 && (
          <SelectPayment
            checkout={checkout}
            onCancel={() => this.setState({ step: 3 })}
            onComplete={transaction => {
              this.setState({ transaction, step: 5 }, () => {
                // Show success toast
                Toast.success({
                  title: { key: "documents.checkout.purchaseSuccess" },
                  button: {
                    title: { key: "documents.checkout.viewPurchases" },
                    href: "/transactions/buy"
                  }
                });
              });
            }}
          />
        )}
        {step === 5 && transaction && <ResultOrder transaction={transaction} />}
      </ContentArea>
    );
  }
}

const mapStateToProps = (state: any) => ({
  checkout: state.checkout
});

export const Checkout = compose<Props, any>(
  withRouter,
  connect(mapStateToProps),
  withAuthorization()
)(CheckoutBase);
