import { Button, Icon } from "app/shared";
import React from "react";
import { BoxProps } from "rebass";

interface Props extends BoxProps {
  iconName: string;
  iconSize: number;
  color?: string;
}

export const MobileSearchBoxBtn: React.FC<Props> = ({
  iconName,
  iconSize,
  color,
  onClick,
  ...rest
}) => (
  <Button
    color={color}
    onClick={onClick}
    display="grid"
    sx={{ border: 0 }}
    {...(rest as any)}>
    <Icon name={iconName} size={iconSize} />
  </Button>
);
