import React from "react";
import Svg, { SvgProps } from "primitives/Svg";

const SvgHeartActive = ({ width, height, size, color }: SvgProps) => (
  <Svg
    id="HeartActive_svg__Camada_1"
    viewBox="0 0 24 24"
    width={size || width}
    height={size || height}
    color={color}>
    <style>{".HeartActive_svg__st0{fill:#00f}"}</style>
    <path
      className="HeartActive_svg__st0"
      d="M12 4c2.1-3 5.8-3.4 8.1-1.7-.4.4-.7.8-1.1 1.2-.4-.2-.8-.4-1.3-.5-1.4-.3-2.6.1-3.7 1-.5.4-.9.9-1.1 1.5-.2.5-.5.7-.9.7s-.7-.2-.9-.6c-.5-1-1.3-1.8-2.3-2.3-1.9-.8-4-.2-5.2 1.4-.7 1-1 2-1 3.2.1 2.2.8 4.2 2 6 .6.9 1.3 1.8 2.1 2.6l.1.1-1.1 1.1L5 17c-1-1.1-1.8-2.3-2.5-3.6-.9-1.8-1.5-3.8-1.4-5.8.1-2.2.9-4 2.8-5.3 2.1-1.4 5-1.2 6.9.4.4.4.8.8 1.1 1.2l.1.1zM21.8 4c.3.4.5.9.7 1.3.4 1.1.5 2.2.4 3.3-.2 2.6-1.2 4.8-2.7 6.9-1.3 1.8-2.9 3.3-4.6 4.6-.8.6-1.7 1.2-2.6 1.7-.6.3-1.2.3-1.8 0-1.2-.7-2.4-1.5-3.5-2.3 0 0 .1 0 0 0 .4-.4.7-.8 1.1-1.2.2.2.3.3.5.4.8.6 1.7 1.2 2.6 1.7.2.1.3.1.5 0 1.5-.8 2.9-1.8 4.1-2.9 1.5-1.3 2.8-2.9 3.7-4.7.8-1.5 1.2-3.2 1.3-4.9 0-.9-.3-1.8-.7-2.6.4-.5.6-.8 1-1.3-.1 0 0 0 0 0z"
    />
    <path
      className="HeartActive_svg__st0"
      d="M22.5 5.3c-.2-.4-.4-.9-.7-1.3-.4-.7-1.3-1.4-1.7-1.7-.1-.1-.2-.1-.2-.1C17.6.6 14 1.1 12 4c0 0-.1 0-.1-.1-.3-.4-.7-.8-1.1-1.2C8.9 1.1 6 .9 3.9 2.3 2 3.6 1.2 5.4 1.1 7.6c-.1 2 .5 4 1.4 5.8C3.2 14.7 4 15.9 5 17c.2.1.3.3.4.4l.3.3s1.6 1.6 2.3 2c1 .7 2.1 1.4 3.2 2.1.6.3 1.2.3 1.8 0 .9-.5 1.8-1.1 2.6-1.7 1.7-1.3 3.3-2.8 4.6-4.6 1.5-2.1 2.5-4.3 2.7-6.9.1-1.1 0-2.2-.4-3.3z"
    />
  </Svg>
);

SvgHeartActive.defaultProps = {
  width: 24,
  height: 24
};
export default SvgHeartActive;
