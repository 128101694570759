import React from "react";
import Svg, { SvgProps } from "primitives/Svg";

const SvgArrowRight = ({ width, height, size, color }: SvgProps) => (
  <Svg
    width={size || width}
    height={size || height}
    viewBox="0 0 66 50"
    color={color}>
    <path d="M64 25H0m64 0L44 49m20-24L44 1" stroke="#fff" strokeWidth={2} />
  </Svg>
);

SvgArrowRight.defaultProps = {
  width: 24,
  height: 24
};
export default SvgArrowRight;
