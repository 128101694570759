import React from "react";
import Svg, { SvgProps } from "primitives/Svg";

const SvgGalleryIcon = ({ width, height, size, color }: SvgProps) => (
  <Svg
    width={size || width}
    height={size || height}
    viewBox="0 0 25 16"
    color={color}>
    <path
      stroke="#000"
      d="M.5.5h6.143v6.111H.5zM.5 9.391h6.143v6.111H.5zM9.43.5h6.143v6.111H9.43zM9.43 9.391h6.143v6.111H9.43zM18.355.5h6.143v6.111h-6.143zM18.355 9.391h6.143v6.111h-6.143z"
    />
  </Svg>
);

SvgGalleryIcon.defaultProps = {
  width: 24,
  height: 24
};
export default SvgGalleryIcon;
