import styled from "@emotion/styled";
import themeGet from "@styled-system/theme-get";
import Area from "./Area";

const ContentArea = styled(Area)`
  min-height: calc(
    100vh - ${themeGet("sizes.navbarHeight.sm")} -
      ${themeGet("sizes.footerHeight.sm")}
  );
  ${themeGet("mediaQueries.lg")} {
    min-height: calc(
      100vh - ${themeGet("sizes.navbarHeight.lg")} -
        ${themeGet("sizes.footerHeight.lg")}
    );
  }
`;

ContentArea.defaultProps = {
  gridArea: "content",
  gridAutoRows: "min-content", // to disable rows height dynamic sizing
  py: ["44px", "46px", "46px", 8]
};

export default ContentArea;
