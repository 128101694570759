import {
  Button,
  Input,
  Label,
  MobileTriggerButton,
  Popup,
  Tabs,
  Toast,
  Tooltip
} from "app/shared";
import i18next from "i18next";
import debounce from "lodash.debounce";
import { Collection, Privacy } from "models";
import React, { Component } from "react";
import { Trans } from "react-i18next";
import { BoxProps, Flex } from "rebass";
import { createCollection, updateCollection } from "./services";
import { LocationInputSearch } from "../account/editMyInfo/LocationInputSearch";
import { getCommonTranslation } from "app/shared/translations/utils";

interface Props extends BoxProps {
  collection?: Collection;
  mobileView?: boolean;
  open?: boolean;
  hideTrigger?: boolean;
  onCompleted?: (collection: Collection) => void;
  onClose?: () => void;
  onOpen?: () => void;
}

interface State {
  collection: Collection;
  disabled: boolean;
}

export class CollectionEditPopup extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      collection: props.collection
        ? props.collection
        : {
            id: -1,
            createdAt: new Date().toISOString(),
            privacy: "private",
            uid: "",
            owner: { id: "", name: "" },
            title: "",
            description: "",
            shareWithLink: false,
            city: "",
            country: "",
            totalDocuments: 0,
            documents: []
          },
      disabled: false
    };
  }

  editCollection(close?: () => void) {
    const { onCompleted } = this.props;
    const {
      id,
      title,
      description,
      shareWithLink,
      city,
      country,
      privacy
    } = this.state.collection;

    this.setState({ disabled: true }, () => {
      const promise =
        id > 0
          ? updateCollection(
              id,
              privacy,
              title,
              description,
              shareWithLink,
              city,
              country,
              undefined
            )
          : createCollection(
              privacy,
              title,
              description,
              shareWithLink,
              city,
              country
            );
      promise
        .then(collection => {
          Toast.success({
            title: { key: "collections.collectionEditPopup.confirmationToast" }
          });
          if (onCompleted) {
            onCompleted(collection);
            this.setState({ disabled: false });
            close?.();
          } else {
            // Waiting a few for backend re-index changes.
            // TODO: This can be replaced if we manually change affected elements locally.
            debounce(
              () =>
                this.setState({ disabled: false }, () =>
                  window.location.reload()
                ),
              1000
            )();
          }
        })
        .catch(error => {
          Toast.apiError(error);
          this.setState({ disabled: false });
        });
    });
  }

  handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { collection } = this.state;

    this.setState({
      collection: {
        ...collection,
        [event.target.name]: event.target.value
      }
    });
  };

  handleOnPrivacyChange = (privacy: Privacy, shareWithLink?: boolean) => {
    const { collection } = this.state;

    this.setState({
      collection: {
        ...collection,
        privacy: privacy as Privacy,
        shareWithLink:
          shareWithLink !== undefined ? shareWithLink : collection.shareWithLink
      }
    });
  };

  render() {
    const {
      mobileView,
      open,
      hideTrigger,
      onClose,
      onCompleted,
      onOpen,
      ...rest
    } = this.props;
    const { disabled, collection } = this.state;
    const trigger = hideTrigger ? (
      undefined
    ) : mobileView ? (
      <Button variant="buttons.mobileButton">
        <MobileTriggerButton
          iconName="CreateCollection"
          disabled={disabled}
          buttonNameI18n={`collections.collectionEditPopup.${
            collection.id > 0 ? "editCollection" : "createCollection"
          }`}
        />
      </Button>
    ) : (
      <Button width="100%" disabled={disabled} {...(rest as any)}>
        <Trans
          i18nKey={`collections.collectionEditPopup.${
            collection.id > 0 ? "editCollection" : "createCollection"
          }`}
        />
      </Button>
    );

    const collectionFields = (
      <>
        <Flex alignItems="center">
          <Label
            variant="grotTextCaps"
            fontSize={0}
            i18n="collections.collectionEditPopup.collectionName"
          />
          <Tooltip
            i18nKey="collections.collectionEditPopup.collectionNameTooltip"
            ml={1}
          />
        </Flex>
        <Input
          name="title"
          type="text"
          placeholder={i18next.t(
            "collections.collectionEditPopup.collectionNamePlaceholder"
          )}
          onChange={this.handleOnChange}
          value={collection.title}
          mb={3}
        />
        <Flex alignItems="center">
          <Label
            variant="grotTextCaps"
            fontSize={0}
            i18n="collections.collectionEditPopup.collectionDescription"
          />
          <Tooltip
            i18nKey="collections.collectionEditPopup.collectionDescriptionTooltip"
            ml={1}
          />
        </Flex>
        <Input
          name="description"
          type="text"
          placeholder={i18next.t(
            "collections.collectionEditPopup.collectionDescriptionPlaceholder"
          )}
          onChange={this.handleOnChange}
          value={collection.description}
          mb={3}
        />
        <Flex alignItems="center">
          <Label
            variant="grotTextCaps"
            fontSize={0}
            i18n="collections.collectionEditPopup.collectionLocation"
          />
          <Tooltip
            i18nKey="collections.collectionEditPopup.collectionLocationTooltip"
            ml={1}
          />
        </Flex>
        <LocationInputSearch
          name="city"
          value={[
            collection.city,
            collection.country && getCommonTranslation(collection.country)
          ]
            .filter(it => it)
            .join(", ")}
          placeholder={i18next.t(
            "collections.collectionEditPopup.collectionLocationPlaceholder"
          )}
          onLocationChange={(city, country) => {
            if (country?.length) {
              this.handleOnChange({
                target: { name: "city", value: city }
              } as React.ChangeEvent<HTMLInputElement>);
              return this.handleOnChange({
                target: { name: "country", value: country }
              } as React.ChangeEvent<HTMLInputElement>);
            }
          }}
          disabled={false}
          variant={false ? "input.borderless" : undefined}
          mb={3}
        />
        <Flex mx={["-13px", 0]}>
          <Tabs
            activeName={
              collection.privacy === "public"
                ? "public"
                : collection.privacy === "private" &&
                  collection.shareWithLink === false
                ? "private"
                : collection.privacy === "private" &&
                  collection.shareWithLink === true
                ? "exclusive"
                : collection.privacy
            }
            flexBasis={["30%"]}
            fontSize={[1, 3]}
            sx={{
              "&:": { textTransform: "lowercase !important", marginRight: -4 }
            }}
            tabs={[
              {
                name: "public",
                label: i18next.t("collections.collectionEditPopup.public"),
                icon: "Globe",
                onClick: () => this.handleOnPrivacyChange("public")
              },
              {
                name: "private",
                label: i18next.t("collections.collectionEditPopup.private"),
                icon: "Lock2",
                onClick: () => this.handleOnPrivacyChange("private", false)
              },
              {
                name: "exclusive",
                label: i18next.t("collections.collectionEditPopup.exclusive"),
                icon: "Star",
                onClick: () => this.handleOnPrivacyChange("private", true)
              }
            ]}
          />
        </Flex>
      </>
    );

    const submitDisabled =
      collection.title.length === 0 ||
      collection.description.length === 0 ||
      (collection?.city && collection.city.length === 0) ||
      disabled;

    return (
      <Popup
        open={open}
        hideOverflow={true}
        trigger={trigger}
        heading={i18next.t(
          `collections.collectionEditPopup.${
            collection.id > 0 ? "editCollection" : "createCollection"
          }`
        )}
        submit={close => this.editCollection(close)}
        submitText={i18next.t(
          `collections.collectionEditPopup.${
            collection.id > 0 ? "edit" : "create"
          }`
        )}
        submitDisabled={submitDisabled}
        cancelDisabled={disabled}
        onClose={onClose}
        onOpen={onOpen}>
        {collectionFields}
      </Popup>
    );
  }
}
