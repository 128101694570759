import React, { Component } from "react";
import { compose } from "recompose";
import { Firebase, withFirebase } from "authentication/firebase";
import { Button, Input, Toast } from "app/shared";
import { Trans } from "react-i18next";
import { FormFieldSet } from "app/shared/forms";
import i18next from "i18next";

interface Props {
  firebase: Firebase;
}

interface State {
  passwordOne: string;
  passwordTwo: string;
}

const INITIAL_STATE = {
  passwordOne: "",
  passwordTwo: ""
};

class ChangePasswordBase extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  handleOnSubmit() {
    const { passwordOne } = this.state;

    this.props.firebase
      .doPasswordUpdate(passwordOne)
      .then(() => {
        Toast.success({
          title: {
            key: "account.settings.account.changePassword.confirmationToast"
          }
        });
        this.setState({ ...INITIAL_STATE });
      })
      .catch(error => {
        Toast.apiError(error);
      });
  }

  handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState<never>({ [event.target.name]: event.target.value });
  };

  render() {
    const { passwordOne, passwordTwo } = this.state;
    const isInvalid = passwordOne !== passwordTwo || passwordOne.length === 0;

    return (
      <FormFieldSet
        title={i18next.t("account.settings.account.changePassword.title")}
        headerPadding={[]}
        contentPadding={[]}
        sx={{ borderBottom: 0 }}>
        <Input
          name="passwordOne"
          type="password"
          labelI18n="account.settings.account.changePassword.newPassword"
          onChange={this.handleOnChange}
          value={passwordOne}
          mb={3}
        />
        <Input
          name="passwordTwo"
          type="password"
          labelI18n="account.settings.account.changePassword.confirmPassword"
          onChange={this.handleOnChange}
          value={passwordTwo}
        />
        <Button
          onClick={() => this.handleOnSubmit()}
          disabled={isInvalid}
          mt={3}>
          <Trans i18nKey="account.settings.account.changePassword.setNewPassword" />
        </Button>
      </FormFieldSet>
    );
  }
}

export const ChangePassword = compose<Props, any>(withFirebase)(
  ChangePasswordBase
);
