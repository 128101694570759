import { FileInput, Label } from "app/shared";
import { Firebase } from "authentication/firebase";
import { withAuthorization } from "authentication/session";
import React, { Dispatch, Component } from "react";
import { Trans } from "react-i18next";
import { compose } from "recompose";
import { User, ValidationError } from "models";
import { updateUser } from "../services";
import { Button, Toast, Input } from "app/shared";
import i18next from "i18next";
import { UserPicture } from "../UserPicture";
import {
  checkFileEventMaxSelected,
  checkFileEventMimeType,
  checkFileEventSize
} from "utils/upload";
import { Box, Flex, Text } from "rebass";
import { UpdateUserDTO } from "../dto";
import { connect } from "react-redux";
import { Action } from "redux";
import * as actions from "../reducers/actions";
import { LocationInputSearch } from "../editMyInfo/LocationInputSearch";

interface PropsExternal {
  user: User;
  onComplete: (user: User) => void;
  submiti18n?: string;
  viewMode?: boolean;
  saveMode?: boolean;
  hideSaveButton?: boolean;
}

interface Props extends PropsExternal {
  firebase: Firebase;
  setLoggedInUser: (user: User) => void;
}

interface State {
  disabled: boolean;
  errors: ValidationError[];
  professionalName: string;
  city: string;
  country: string;
  picture?: File;
}

class PersonalInfoBase extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      disabled: false,
      errors: [],
      professionalName: props.user.professionalName,
      city: props.user.city ?? "",
      country: props.user.country ?? "",
      picture: undefined
    };
  }

  validateForm(): ValidationError[] {
    const { professionalName, city, country } = this.state;
    const errors: ValidationError[] = [];

    if (professionalName.length === 0) {
      errors.push({
        i18nKey: "shared.forms.invalidField",
        field: "professionalName"
      });
    }
    if (city.length === 0) {
      errors.push({ i18nKey: "shared.forms.invalidField", field: "city" });
    }
    if (country.length === 0) {
      errors.push({ i18nKey: "shared.forms.invalidField", field: "country" });
    }

    return errors;
  }

  onSubmit() {
    const { onComplete, setLoggedInUser, user } = this.props;
    const { professionalName, city, country, picture } = this.state;
    const errors = this.validateForm();

    if (user && errors.length === 0) {
      this.setState({ disabled: true, errors: [] }, () => {
        const updateUserDTO: UpdateUserDTO = {
          userId: user.id,
          professionalName,
          city,
          country,
          picture
        };
        updateUser(updateUserDTO)
          .then(user => {
            this.setState({ disabled: false });
            setLoggedInUser(user); // to sync user image
            onComplete(user);
          })
          .catch(error => {
            Toast.apiError(error);
            this.setState({ disabled: false });
          });
      });
    } else {
      this.setState({ errors });
    }
  }

  handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState<any>({ [event.target.name]: event.target.value });
  };

  handleOnFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const errors = [
      ...checkFileEventMaxSelected(event),
      ...checkFileEventMimeType(event),
      ...checkFileEventSize(event)
    ];

    if (event.target.files && errors.length === 0) {
      this.setState<never>({
        [event.target.name]: event.target.files[0]
      });
    } else {
      Toast.error({ title: errors.join(", ") });
    }
  };

  componentDidUpdate(prevProps: Props) {
    if (this.props.saveMode !== undefined) {
      if (prevProps.saveMode !== this.props.saveMode) {
        this.onSubmit();
      }
    }
  }

  render() {
    const { user, submiti18n, viewMode, hideSaveButton } = this.props;
    const {
      disabled,
      professionalName,
      city,
      country,
      picture,
      errors
    } = this.state;
    if (!user) return null;

    return (
      <Box px={[2, 0]}>
        {!viewMode && (
          <Flex width="100%" alignItems="flex-start" mb={6}>
            <UserPicture
              picture={
                picture
                  ? URL.createObjectURL(picture)
                  : user.picture
                  ? user.picture
                  : undefined
              }
              height={["50px", "110px"]}
            />
            <Flex pl={[2, 3, 4]} height="100%" flexDirection="column">
              <Text fontSize={[1, 3, 7]} variant="caps" sx={{ flexGrow: 1 }}>
                <Trans i18nKey="account.onboarding.personalInfo.profilePhoto" />
              </Text>
              <FileInput
                accept="image/png, image/jpg, image/jpeg"
                name="picture"
                buttonNameI18n="account.onboarding.personalInfo.browse"
                onChange={this.handleOnFileChange}
                width={["", "332px"]}
                disabled={viewMode}
              />
              <Label
                i18n="account.onboarding.personalInfo.pictureInfo"
                fontSize="10px"
                pt={1}
                pb={0}
              />
            </Flex>
          </Flex>
        )}
        <Input
          name="professionalName"
          type="text"
          labelI18n="account.onboarding.personalInfo.professionalName"
          placeholder={i18next.t(
            "account.onboarding.personalInfo.professionalNamePlaceholder"
          )}
          errorI18n={
            errors.find(it => it.field === "professionalName")?.i18nKey
          }
          onChange={this.handleOnChange}
          value={professionalName}
          disabled={viewMode}
          variant={viewMode ? "input.borderless" : undefined}
          mb={4}
        />
        <LocationInputSearch
          labelI18n="account.onboarding.personalInfo.location"
          name="city"
          value={[city, country].filter(it => it).join(", ")}
          errorI18n={errors.find(it => it.field === "city")?.i18nKey}
          placeholder={i18next.t(
            "account.onboarding.personalInfo.locationPlaceholder"
          )}
          onLocationChange={(city, country) => {
            if (city?.length && country?.length) {
              this.handleOnChange({
                target: { name: "city", value: city }
              } as React.ChangeEvent<HTMLInputElement>);
              return this.handleOnChange({
                target: { name: "country", value: country }
              } as React.ChangeEvent<HTMLInputElement>);
            } else {
              // If there is no value or there is an error use the old value
              return this.handleOnChange({
                target: {
                  name: "city",
                  value: city
                }
              } as React.ChangeEvent<HTMLInputElement>);
            }
          }}
          disabled={viewMode}
          variant={viewMode ? "input.borderless" : undefined}
          mb={hideSaveButton || viewMode ? 0 : 8}
        />
        {!viewMode && !hideSaveButton && (
          <Flex justifyContent="center" alignItems="center">
            <Button
              width={["100%", "40%"]}
              variant="blue"
              p={3}
              disabled={disabled}
              onClick={() => this.onSubmit()}>
              <Trans
                i18nKey={submiti18n ?? "account.onboarding.personalInfo.submit"}
              />
            </Button>
          </Flex>
        )}
      </Box>
    );
  }
}

const mapStateToProps = (state: any) => ({});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  setLoggedInUser: async (user: User) => dispatch(actions.setLoggedInUser(user))
});

export const PersonalInfo = compose<Props, PropsExternal>(
  connect(mapStateToProps, mapDispatchToProps),
  withAuthorization()
)(PersonalInfoBase);
