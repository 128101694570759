import axios from "network";
import { confirmInvite } from "./";
import {
  PortfolioReviewProcess,
  CreatePortfolioReviewCommand,
  ConfirmPortfolioInviteCommand
} from "../models/PortfolioReviewProcess";

export class PortfolioReviewService {
  private uid: string;

  constructor(uid: string) {
    this.uid = uid;
  }

  async uploadFiles(files: File[]): Promise<void> {
    const data = new FormData();

    for (let i = 0; i < files.length; i++) {
      data.append(files[i].name, files[i]);
    }

    return axios.put(`/users/${this.uid}/portfolio/files`, data);
  }

  async deleteFile(name: string): Promise<void> {
    return axios.delete(`/users/${this.uid}/portfolio/files/${name}`);
  }

  async submitPortfolio(
    cmd: CreatePortfolioReviewCommand
  ): Promise<PortfolioReviewProcess> {
    const response = await axios.post(`/users/${this.uid}/portfolio`, cmd);
    return response.data;
  }

  async getPortfolioValidationStatus(): Promise<PortfolioReviewProcess> {
    const response = await axios.get(`/users/${this.uid}/portfolio`);
    return response.data;
  }

  async confirmPortfolioInvite(
    cmd: ConfirmPortfolioInviteCommand
  ): Promise<void> {
    return confirmInvite(cmd.id, cmd.code);
  }
}
