import React, { useState, useEffect, ReactNode } from "react";
import { Parser } from "./parser";
import { Page } from "./models";
import { getPage } from "./services";

interface Props {
  pageId: number;
  components?: any;
  onFetch?: () => void;
  placeholder?: ReactNode;
}

export const PageParser: React.FC<Props> = ({
  pageId,
  components,
  onFetch,
  placeholder
}) => {
  const [page, setPage] = useState<Page>();
  useEffect(() => {
    const fetchData = async () => {
      setPage(await getPage(pageId));
      onFetch?.();
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageId]); // using only pageId as dependency to useEffect to prevent onFetch to fetch data again
  if (!page) return placeholder ? <>{placeholder}</> : null;

  return <Parser text={page.text} components={components} />;
};
