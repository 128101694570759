import React from "react";
import { UploadTask } from "models";
import { Trans } from "react-i18next";
import { Box, Flex, Text } from "rebass";
import { DocumentView } from "app/documents";
import { Icon, Button } from "app/shared";
import { BecomeVerifiedPopup } from "app/account/popups";
import { compose } from "recompose";
import { RouteComponentProps, withRouter } from "react-router-dom";

interface PropsExternal {
  task: UploadTask;
  userIsVerified: boolean;
}

interface Props extends PropsExternal, RouteComponentProps {}

const ResultSubmitBase: React.FC<Props> = ({
  task,
  userIsVerified,
  history
}) => {
  const collectionUrl = `/collections/${task.documents[0].collections[0].id}`;

  return (
    <Box>
      <Text textAlign="center" fontSize={[4, 5, 6]} mb={5}>
        <Trans i18nKey="documents.resultSubmit.contentPublished" />
      </Text>
      {task.documents.map(document => (
        <Flex
          key={document.id}
          alignItems="center"
          justifyContent="space-between"
          p={2}
          pr={5}
          mb={3}
          sx={{
            border: 1,
            borderColor: "black.0"
          }}>
          <Box>
            <DocumentView
              document={{
                id: document.id,
                thumbnail: document.thumbnail
              }}
              width="150px"
              height="100px"
              showInfoBottom={false}
              forceShowOnHover={false}
              showManageIcons={false}
              showUserInfo={false}
              showHoverInfo={false}
              customPb={0}
              customMinHeight="0px"
              hideLoading={true}
            />
          </Box>
          <Text
            fontSize={[4, 5, 6]}
            ml={7}
            sx={{ flexGrow: 1, wordBreak: "break-all" }}>
            {document.translations?.[0]?.title ?? `#${document.id}`}
          </Text>
          <Button href={`/documents/${document.id}`}>
            <Icon name="SubmitArrow" size={24} />
          </Button>
        </Flex>
      ))}
      {userIsVerified ? (
        <Button
          href={collectionUrl}
          variant="link.button.brand"
          width="100%"
          p={3}>
          <Text variant="caps">
            <Trans i18nKey="documents.resultSubmit.done" />
          </Text>
        </Button>
      ) : (
        <BecomeVerifiedPopup
          trigger={
            <Button variant="blue" width="100%" p={3}>
              <Text variant="caps">
                <Trans i18nKey="documents.resultSubmit.done" />
              </Text>
            </Button>
          }
          onSkip={close => {
            history.push("/");
            close();
          }}
          onComplete={() => {
            window.location.reload(); // force the page to reload verify flow
          }}
        />
      )}
    </Box>
  );
};

export const ResultSubmit = compose<Props, PropsExternal>(withRouter)(
  ResultSubmitBase
);
