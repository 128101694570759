import { Button, Icon } from "app/shared";
import { AuthUserContext } from "authentication/session";
import React from "react";
import { Trans } from "react-i18next";
import { Box, Flex, Text } from "rebass";
import { SignOutButton } from "./SignOutButton";
import { LanguageSelect } from "./LanguageSelect";
import { MenuWrapper } from "./MenuWrapper";
import { useMediaQueryContext } from "styles/context";
import { UserPicture } from "app/account";
import { User } from "models";

interface Props {
  user?: User;
}

export const UserMenu: React.FC<Props> = ({ user }) => {
  const { isMedium } = useMediaQueryContext();

  return (
    <MenuWrapper maxWidth="350px" backgroundColor="white">
      <AuthUserContext.Consumer>
        {({ authUser }) => (
          <>
            {/* User name */}
            {authUser && isMedium && (
              <Button
                width="100%"
                href={`/users/${authUser.uid}/collections`}
                px={3}
                py={2}
                sx={{ borderBottom: 1 }}>
                <Flex>
                  <UserPicture
                    picture={user?.picture ?? undefined}
                    height="48px"
                  />
                  <Flex
                    flexDirection="column"
                    justifyContent="space-around"
                    ml={3}>
                    <Text
                      fontSize={[3, 4, 5]}
                      variant="caps"
                      sx={{ wordBreak: "break-word" }}>
                      {user && user.professionalName.length > 15
                        ? `${user.professionalName.substr(0, 15)}..`
                        : user?.professionalName}
                    </Text>
                    <Text fontSize={1} variant="caps" color="blue">
                      <Trans i18nKey="navbar.userMenu.viewProfile" />
                    </Text>
                  </Flex>
                </Flex>
              </Button>
            )}
            {/* Buttons */}
            {isMedium && (
              <Box
                sx={{
                  borderBottom: 1,
                  "& > *": { width: "100%", borderBottom: 1, pl: 3, py: 2 },
                  "& > :last-child": { borderBottom: 0 }
                }}>
                <Button href="/checkout">
                  <Flex>
                    <Icon name="Cart" size={24} />
                    <Text fontSize={5} variant="caps" ml={3}>
                      <Trans i18nKey="navbar.userMenu.cart" />
                    </Text>
                  </Flex>
                </Button>
                {authUser && (
                  <>
                    <Button href="/messages">
                      <Flex>
                        <Icon name="Mail" size={24} />
                        <Text fontSize={5} variant="caps" ml={3}>
                          <Trans i18nKey="navbar.userMenu.messagens" />
                        </Text>
                      </Flex>
                    </Button>
                    <Button href="/notifications">
                      <Flex>
                        <Icon name="Bell" size={24} />
                        <Text fontSize={5} variant="caps" ml={3}>
                          <Trans i18nKey="navbar.userMenu.notifications" />
                        </Text>
                      </Flex>
                    </Button>
                  </>
                )}
              </Box>
            )}
            {/* Activity */}
            <Flex
              flexDirection="column"
              sx={{
                "& > *": { borderBottom: 1, py: 2 },
                "& > :last-child": { borderBottom: 0 }
              }}>
              {isMedium && (
                <Button href="/explore">
                  <Text fontSize={5} variant="caps" pl={3}>
                    <Trans i18nKey="navbar.userMenu.explore" />
                  </Text>
                </Button>
              )}
              {authUser ? (
                <>
                  <Box sx={{ borderBottom: 1 }}>
                    <Text
                      fontSize={5}
                      variant="caps"
                      pl={3}
                      pb={2}
                      sx={{ borderBottom: 1 }}>
                      <Trans i18nKey="navbar.userMenu.account" />
                    </Text>
                    <Flex
                      flexDirection="column"
                      pl={3}
                      sx={{
                        "& > *": {
                          width: "100%",
                          borderBottom: 1,
                          pl: 3,
                          pt: 2,
                          pb: 1
                        },
                        "& > :last-child": { marginBottom: 0 }
                      }}>
                      <Button href={`/edit-my-info`}>
                        <Text fontSize={5} variant="caps">
                          <Trans i18nKey="navbar.userMenu.editMyinfo" />
                        </Text>
                      </Button>
                      <Button href="/settings">
                        <Text fontSize={5} variant="caps">
                          <Trans i18nKey="navbar.userMenu.settings" />
                        </Text>
                      </Button>
                    </Flex>
                    <Flex
                      flexDirection="column"
                      pl={6}
                      sx={{
                        "& > *": {
                          width: "100%",
                          borderBottom: 1,
                          pt: 2,
                          pb: 1
                        },
                        borderBottom: 1,
                        "& > :last-child": { marginBottom: 2 }
                      }}>
                      <Button href="/settings/account">
                        <Text fontSize={5} variant="caps" pl={3}>
                          <Trans i18nKey="navbar.userMenu.password" />
                        </Text>
                      </Button>
                      <Button href="/settings/billing">
                        <Text fontSize={5} variant="caps" pl={3}>
                          <Trans i18nKey="navbar.userMenu.billing" />
                        </Text>
                      </Button>
                      <Button href="/settings/management">
                        <Text fontSize={5} variant="caps" pl={3}>
                          <Trans i18nKey="navbar.userMenu.notifications" />
                        </Text>
                      </Button>
                    </Flex>
                    <Button
                      href="/transactions"
                      width="100%"
                      sx={{ borderBottom: 1 }}>
                      <Text fontSize={5} variant="caps" py={2} pl={3}>
                        <Trans i18nKey="navbar.userMenu.transactionsHistory" />
                      </Text>
                    </Button>
                    <Button href="/metrics">
                      <Text fontSize={5} variant="caps" pt={2} pl={3}>
                        <Trans i18nKey="navbar.userMenu.metrics" />
                      </Text>
                    </Button>
                  </Box>
                  <SignOutButton />
                </>
              ) : (
                <>
                  <Button href="/login">
                    <Text fontSize={5} variant="caps" pl={3}>
                      <Trans i18nKey="navbar.userMenu.logIn" />
                    </Text>
                  </Button>
                  <Box pl={3}>
                    <LanguageSelect />
                  </Box>
                </>
              )}
            </Flex>
          </>
        )}
      </AuthUserContext.Consumer>
    </MenuWrapper>
  );
};
