const ACCEPTED_FILE_TYPES = [
  "image/png",
  "image/jpeg",
  "image/tiff",
  "image/gif",
  "application/pdf"
];
const ACCEPTED_FILE_SIZE = 10000000; // 10MB
const ACCEPTED_MAX_FILES = 5;

//TODO - return error codes instead of strings so that translation can be used

/**
 * This method will validate if files number are < ACCEPTED_MAX_FILES
 */
export const checkFileEventMaxSelected = (
  event: React.ChangeEvent<HTMLInputElement>,
  acceptedMaxFiles: number = ACCEPTED_MAX_FILES
): string[] => {
  if (
    event &&
    event.target &&
    event.target.files &&
    event.target.files.length > acceptedMaxFiles
  ) {
    event.target.value = "";
    return [`Only ${ACCEPTED_MAX_FILES} files can be uploaded at a time`];
  }

  return [];
};

export const checkFileMimeType = (
  file: File,
  acceptedFileTypes: string[] = ACCEPTED_FILE_TYPES
): string[] => {
  const errors = [];

  if (acceptedFileTypes.every(type => file.type !== type)) {
    errors.push(`${file.type} is not a supported format`);
  }

  return errors;
};

export const checkFileSize = (
  file: File,
  acceptedFileSize: number = ACCEPTED_FILE_SIZE
): string[] => {
  const errors = [];

  if (file.size > acceptedFileSize) {
    errors.push(
      `${file.size}/${acceptedFileSize} is over the allowed file size`
    );
  }

  return errors;
};

/**
 * This method will validate files mime type
 */
export const checkFileEventMimeType = (
  event: React.ChangeEvent<HTMLInputElement>
): string[] => {
  const errors = [];

  if (event.target.files) {
    for (let i = 0; i < event.target.files.length; i++) {
      errors.push(...checkFileMimeType(event.target.files[i]));
    }

    if (errors.length) {
      event.target.value = "";
      return errors;
    }

    return [];
  }

  return ["No file selected"];
};

/**
 * This method will validate files size
 */
export const checkFileEventSize = (
  event: React.ChangeEvent<HTMLInputElement>
): string[] => {
  const errors = [];

  if (event.target.files) {
    for (let i = 0; i < event.target.files.length; i++) {
      errors.push(...checkFileSize(event.target.files[i]));
    }

    if (errors.length) {
      event.target.value = "";
      return errors;
    }

    return [];
  }

  return ["No file selected"];
};
