import { Button, Icon } from "app/shared";
import React, { ReactNode, useEffect, useState } from "react";
import { Box, BoxProps, Flex, SxStyleProp, Text } from "rebass";
import { useMediaQueryContext } from "styles/context";

interface Tab {
  name: string;
  label: string | ReactNode;
  href?: string;
  onClick?: () => void;
  icon?: string;
}

interface Props extends BoxProps {
  tabs: Tab[];
  activeName?: string;
  variant?: "center" | "left" | "right" | "mobile";
  wrapTabs?: boolean | boolean[];
  sx?: SxStyleProp;
  flexBasis?: string | string[];
  fontSize?: number | number[];
  customPy?: string | string[] | number | number[];
  onlyOneFlexBasis?: boolean;
  customMx?: number | number[];
}

export const Tabs: React.FC<Props> = ({
  tabs,
  activeName,
  variant,
  wrapTabs,
  flexBasis,
  fontSize,
  customPy,
  onlyOneFlexBasis,
  customMx,
  sx,
  ...rest
}) => {
  const [active, setIsActive] = useState(tabs?.[0]?.name);
  const { isMedium, isSmallMedium } = useMediaQueryContext();
  const defaultTabBorders = {
    "& > :last-of-type": {
      paddingRight: 0
    },
    "& > *": {
      paddingRight: customMx ? 0 : isMedium ? 0 : 3
    },
    ...sx
  };
  useEffect(() => {
    if (activeName) {
      setIsActive(activeName ?? "");
    }
  }, [activeName]);

  return (
    <Flex
      sx={defaultTabBorders}
      width="100%"
      px={[2, 0]}
      flexWrap={wrapTabs ? "wrap" : isSmallMedium ? "nowrap" : "wrap"}
      {...(rest as any)}>
      {tabs.map(tab => (
        <Box
          mb={flexBasis ? 2 : 0}
          key={tab.name}
          sx={{
            flexGrow: 1,
            flexBasis: onlyOneFlexBasis
              ? flexBasis
              : [
                  "50%",
                  // "50%",
                  // "50%",
                  // flexBasis ? flexBasis : 0,
                  // flexBasis ? flexBasis : 0,
                  flexBasis ? flexBasis : 0
                ],
            display: "flex"
          }}>
          <Button
            href={tab.href}
            py={customPy ?? undefined}
            mb={isMedium ? 1 : undefined}
            mx={customMx ?? (isMedium ? 1 : undefined)}
            width="100%"
            onClick={() => {
              setIsActive(tab.name);
              tab.onClick?.();
            }}
            variant={
              active === tab.name
                ? tab.href
                  ? "link.button.active"
                  : "active"
                : tab.href
                ? "link.button"
                : ""
            }
            sx={{
              textTransform: "uppercase",
              border:
                active === tab.name ? "1px solid blue" : "1px solid black",
              py: 2
            }}>
            {tab.icon ? (
              <Flex justifyContent="center" alignItems="center">
                <Flex
                  pr={2}
                  sx={{
                    "& svg path": {
                      fill: active === tab.name ? "white" : "",
                      stroke: active === tab.name ? "white" : ""
                    }
                  }}>
                  <Icon name={tab.icon} size={20} />
                </Flex>
                <Flex>
                  <Text fontSize={fontSize ? fontSize : 3}>{tab.label}</Text>
                </Flex>
              </Flex>
            ) : (
              <Text fontSize={fontSize ? fontSize : 3}>{tab.label}</Text>
            )}
          </Button>
        </Box>
      ))}
    </Flex>
  );
};

Tabs.defaultProps = {
  variant: "center"
};
