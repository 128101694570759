import { Icon } from "app/shared";
import React from "react";
import { useMediaQueryContext } from "styles/context";
import NavbarButton from "../NavbarButton";

export const MobileUpload: React.FC = () => {
  const { isMedium } = useMediaQueryContext();
  return (
    <NavbarButton
      href="/submit-content"
      px={isMedium ? 2 : 3}
      bg="blue"
      color="white"
      sx={{ "&:hover": { background: "black" } }}>
      <Icon name="Upload" size={isMedium ? 24 : 36} />
    </NavbarButton>
  );
};
