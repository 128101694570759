import { Button, Icon, LoadingFF, ThumbnailCardOne } from "app/shared";
import { Bucket, DocumentThumbnail } from "models";
import React, { useState, BaseSyntheticEvent, ReactNode } from "react";
import { Box, BoxProps, Flex, Text } from "rebass";
import { Trans } from "react-i18next";
import { BucketShareButton } from "./bucketDetails/BucketShareButton";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { compose } from "recompose";
import { useMediaQueryContext } from "styles/context";
import { AuthUserContext } from "authentication/session";
import { checkSameUser } from "authentication/utils";
import { SearchProvider, WithSearch } from "@elastic/react-search-ui";
import AppSearchAPIConnector from "@elastic/search-ui-app-search-connector";
import environment from "configurations";
import { mapDocumentResults } from "app/search/utils/mapResults";
import { LikeButton } from "app/documents/documentDetails/LikeButton";
import { BucketInfo } from "./models";
import { SwiperView } from "app/shared/swiperView";

const IMAGE_LINK_ID = "image-link";

interface PropsExternal extends BoxProps {
  bucket: Bucket;
  onClick?: () => void;
  variant?: string;
  whiteMode?: boolean;
  defaultHover?: boolean;
  showInfoBottom?: boolean;
  height?: string | string[];
  titleFontSize?: number | number[];
  textBottomFontSize?: number | number[];
  customPb?: string | string[] | number | number[];
  largeThumbnail?: boolean;
  autoplay?: boolean;
  autoplayDelay?: number;
  hideNavigation?: boolean;
  hideBullets?: boolean;
  href?: string;
  forceShowOnHover?: boolean;
}

interface Props extends PropsExternal, RouteComponentProps {
  onThumbnailChange: (increment: number) => void;
  initialSlide: number;
}

interface DocumentClickWrapperProps extends BoxProps {
  children?: ReactNode;
  href?: string;
  onClick?: (e: BaseSyntheticEvent) => void;
}

const DocumentClickWrapper: React.FC<DocumentClickWrapperProps> = ({
  children,
  href,
  onClick,
  ...rest
}) => (
  <Button
    display="block"
    variant="transparent"
    href={href}
    as={href ? undefined : "div"}
    onClick={event => {
      event.preventDefault();
      onClick?.(event);
    }}
    {...(rest as any)}>
    {children}
  </Button>
);

interface WithSearchProps {
  results: any[];
  setCurrent: (page: number) => void;
  current: number;
}

const BucketViewBase: React.FC<Props> = props => {
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [index, setIndex] = useState(0);

  if (!isSearchActive) {
    return (
      <BucketViewComponent
        initialSlide={index}
        {...(props as any)}
        onThumbnailChange={() => {
          setIsSearchActive(true);
          setIndex(1);
        }}
      />
    );
  } else {
    return (
      <SearchProvider
        config={{
          apiConnector: new AppSearchAPIConnector(environment.search),
          alwaysSearchOnInitialLoad: true,
          trackUrlState: false,
          initialState: {
            resultsPerPage: 1
          },
          searchQuery: {
            filters: [
              { field: "class_name", values: ["document"], type: "any" }, // this component should only show documents
              {
                field: "buckets",
                values: [props.bucket.id.toString()],
                type: "any"
              },
              {
                field: "real_id",
                values: [`${props.bucket.documents?.[0]?.id}`],
                type: "none"
              }
            ],
            sort: { id: "desc" },
            result_fields: {
              real_id: { raw: {} },
              thumbnail: { raw: {} }
            }
          }
        }}>
        <WithSearch
          mapContextToProps={({
            results,
            setCurrent,
            current
          }: WithSearchProps) => ({
            results,
            setCurrent,
            current
          })}>
          {({ results, setCurrent, current }: WithSearchProps) => {
            const documents: DocumentThumbnail[] = mapDocumentResults(results);
            return (
              <>
                <BucketViewComponent
                  {...(props as any)}
                  initialSlide={index}
                  bucket={
                    index === 0
                      ? props.bucket
                      : {
                          ...props.bucket,
                          documents: documents.map(it => ({
                            ...it,
                            thumbnail: props.largeThumbnail
                              ? it.thumbnail.replace(
                                  "small" || "normal",
                                  "large"
                                )
                              : it.thumbnail
                          }))
                        }
                  }
                  onThumbnailChange={index => {
                    setIndex(index);
                    if (index > 0) {
                      setCurrent(index);
                    }
                  }}
                  defaultHover={true}
                />
              </>
            );
          }}
        </WithSearch>
      </SearchProvider>
    );
  }
};

export const BucketViewComponent: React.FC<Props> = ({
  bucket,
  height,
  onClick,
  variant,
  history,
  whiteMode,
  onThumbnailChange,
  defaultHover,
  showInfoBottom,
  initialSlide,
  titleFontSize,
  textBottomFontSize,
  customPb,
  largeThumbnail,
  autoplay,
  autoplayDelay,
  hideNavigation,
  hideBullets,
  href,
  forceShowOnHover,
  ...rest
}) => {
  const { isSmallMedium } = useMediaQueryContext();
  const [isHover, setHover] = useState(defaultHover ?? false);
  let documentLink = href ?? `/buckets/${bucket.id}`;
  const [isShareOpen, setIsShareOpen] = useState(false);
  const [bucketInfo, setBucketInfo] = useState<BucketInfo | undefined>(
    undefined
  ); // used to cache info for the next hover

  const BucketInfoBox: React.FC = () => (
    <>
      {(showInfoBottom || (forceShowOnHover ? isHover : showInfoBottom)) && (
        <Flex
          width="100%"
          draggable={false}
          flexDirection="column"
          sx={{
            position: "absolute",
            bottom: 0,
            zIndex: 0,
            userSelect: "none"
          }}
          flexWrap="wrap">
          <Flex
            alignSelf="flex-start"
            maxWidth="80%"
            pr={2}
            py={2}
            pl={2}
            ml={2}
            bg="black">
            <Flex alignItems="center" width="100%">
              <Text
                fontSize={titleFontSize ?? [2, 3, 6]}
                variant="grotText"
                sx={{
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap"
                }}
                color="white">
                {bucket.title}
              </Text>
            </Flex>
            <AuthUserContext.Consumer>
              {({ authUser }) => {
                return authUser && checkSameUser(bucket.owner.id, authUser) ? (
                  <>
                    <Flex justifyContent="flex-end" alignItems="center" px={2}>
                      <Button
                        variant="transparent"
                        href={`/buckets/${bucket.id}/edit`}
                        sx={{
                          "& svg path": {
                            fill: "white"
                          }
                        }}>
                        <Icon name="Pencil2" size={20} />
                      </Button>
                    </Flex>
                  </>
                ) : (
                  <>
                    <Flex
                      justifyContent="flex-end"
                      alignItems="center"
                      pl={2}
                      sx={{
                        "& svg path": {
                          fill: "white",
                          strokeWidth: "5px"
                        }
                      }}>
                      <Icon name="ArrowRight" size={20} />
                    </Flex>
                  </>
                );
              }}
            </AuthUserContext.Consumer>
          </Flex>
          <Flex mt={1} ml={2} mb={5} px={2} alignSelf="flex-start" bg="blue">
            <Flex alignItems="center" height="100%">
              {bucket.owner?.name && (
                <Button href={`/users/${bucket.owner.id}`}>
                  <Flex pr={3} alignItems="center" mr={2}>
                    <Text
                      variant="grotText"
                      fontSize={textBottomFontSize ?? [1, 2]}
                      color="white">
                      <Trans i18nKey="buckets.bucketView.selectedBy" />{" "}
                      {bucket.owner.name}
                    </Text>
                  </Flex>
                </Button>
              )}
              <Flex alignItems="center">
                <Icon name="Photos" size={22} color="white" />
                <Text
                  variant="grotText"
                  fontSize={textBottomFontSize ?? [1, 2]}
                  color="white"
                  ml={2}>
                  {bucket.totalDocuments}
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      )}
    </>
  );

  const slides = Array.from({ length: bucket.totalDocuments }).map(
    (el, index) => (
      <Box width="100%" height={height}>
        {bucket.documents?.[0]?.thumbnail && (
          <ThumbnailCardOne
            thumbnail={
              largeThumbnail
                ? bucket.documents?.[0]?.thumbnail.replace("normal", "large")
                : bucket.documents?.[0]?.thumbnail
            }
          />
        )}
      </Box>
    )
  );

  return (
    <Box
      pb={customPb ?? 0}
      sx={{ position: "relative" }}
      onMouseEnter={() => (isHover === false ? setHover(true) : null)}
      onMouseLeave={() => (isHover === true ? setHover(false) : null)}>
      <AuthUserContext.Consumer>
        {({ authUser }) => {
          documentLink =
            authUser && checkSameUser(bucket.owner.id, authUser)
              ? href ?? documentLink + "/edit"
              : documentLink;
          return (
            <DocumentClickWrapper
              href={documentLink}
              onClick={e => {
                const eventTarget = e.target.id || e.target.parentNode.id;
                if (eventTarget.startsWith(IMAGE_LINK_ID)) {
                  history.push(documentLink);
                }
              }}
              width="100%"
              height={height}
              sx={{
                transition: "0.5s",
                zIndex: 0,
                "&:hover": { opacity: "initial !important" }
              }}
              {...(rest as any)}>
              <Flex flexDirection="column" height="100%">
                <Box
                  sx={{
                    position: "relative",
                    flexGrow: 1
                  }}
                  height="80%">
                  {(bucket.documents?.[0]?.thumbnail ||
                    bucket.totalDocuments > 0) &&
                  bucket.totalItems > 0 ? (
                    <SwiperView
                      height={height}
                      slides={slides}
                      autoplay={autoplay}
                      autoplayDelay={autoplayDelay}
                      onClick={() => history.push(documentLink)}
                      onSlideChange={swiper =>
                        onThumbnailChange(swiper.realIndex)
                      }
                      hideBullets={hideBullets}
                      initialSlide={initialSlide}
                      navigation={
                        hideNavigation
                          ? false
                          : isHover &&
                            bucket.totalDocuments > 1 &&
                            !isSmallMedium
                          ? true
                          : false
                      }
                    />
                  ) : (
                    <ThumbnailCardOne thumbnail={undefined} />
                  )}
                  {(bucket.documents?.[0]?.thumbnail ||
                    bucket.totalDocuments > 0) && (
                    <Box
                      width="100%"
                      sx={{ position: "absolute", zIndex: -1, bottom: "40%" }}>
                      <LoadingFF showIcon={true} hideBar={true} />
                    </Box>
                  )}
                  <Flex
                    id={`${IMAGE_LINK_ID}-2`} //to work href only in the thumbnail area
                    width="100%"
                    height="100%"
                    sx={{
                      position: "absolute",
                      top: 0
                    }}></Flex>
                  <BucketInfoBox />
                  {/* <IconButtons/> */}
                </Box>
              </Flex>
            </DocumentClickWrapper>
          );
        }}
      </AuthUserContext.Consumer>
      {(showInfoBottom || (forceShowOnHover ? isHover : showInfoBottom)) && (
        <Box mr={2} sx={{ position: "absolute", top: 0, right: 0, zIndex: 1 }}>
          <Flex flexDirection="column">
            <AuthUserContext.Consumer>
              {({ authUser }) =>
                authUser &&
                bucket.owner &&
                !checkSameUser(bucket.owner?.id, authUser) && (
                  <Flex
                    mt={2}
                    bg="white"
                    p={1}
                    sx={{
                      border: 1,
                      borderColor: "blue"
                    }}>
                    <LikeButton
                      name="Like"
                      variant="icon"
                      display="block"
                      mode="bucket"
                      documentId={bucket.id}
                      bucketInfo={bucketInfo}
                      onInfoChange={info => setBucketInfo(info as BucketInfo)}
                    />
                  </Flex>
                )
              }
            </AuthUserContext.Consumer>
            <Flex mt={2}>
              <Button
                bg="white"
                onClick={() => setIsShareOpen(true)}
                p={1}
                variant="transparent"
                sx={{
                  border: 1,
                  borderColor: "blue",
                  "&:hover": { opacity: "1 !important" },
                  "& svg path": { fill: "transparent", stroke: "blue" }
                }}>
                <Icon name="Share2" size={22} />
              </Button>
            </Flex>
          </Flex>
        </Box>
      )}
      <BucketShareButton
        hideTrigger={true}
        open={isShareOpen}
        onClose={() => setIsShareOpen(false)}
        name="Share"
        variant="transparent"
        display="block"
        bucket={bucket}
      />
    </Box>
  );
};

BucketViewBase.defaultProps = {
  height: "300px",
  whiteMode: true,
  showInfoBottom: true,
  forceShowOnHover: true
};

export const BucketView = compose<Props, PropsExternal>(withRouter)(
  BucketViewBase
);
