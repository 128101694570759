import { Firebase, withFirebase } from "authentication/firebase";
import firebase from "firebase";
import React, { Component } from "react";
import { compose } from "recompose";
import {
  SignInResponse,
  SignInResult
} from "../../../authentication/firebase/models";
import AuthButton from "./AuthButton";
import { BoxProps } from "rebass";
import { FederatedUser } from "../models/social";

interface PropsExternal extends BoxProps {
  provider: "google" | "facebook";
  i18nKey?: string;
  icon?: string;
  onPasswordRequired: (
    email: string,
    credentials?: firebase.auth.OAuthCredential
  ) => void;
  onRegistrationRequired: (federatedUser: FederatedUser) => void;
  onSuccess: () => void;
}

interface Props extends PropsExternal {
  firebase: Firebase;
}

class SocialProviderButtonBase extends Component<Props> {
  handleFederatedSignInResponse(response: SignInResponse) {
    const {
      onPasswordRequired,
      onRegistrationRequired,
      onSuccess
    } = this.props;

    switch (response.result) {
      case SignInResult.PasswordRequired:
        onPasswordRequired(response.email ?? "", response.credentials);
        break;
      case SignInResult.RegistrationRequired:
        if (response.uid?.length && response.email?.length) {
          onRegistrationRequired({
            uid: response.uid,
            email: response.email,
            displayName: response.displayName || "",
            photoURL: response.photoURL || ""
          });
        } else {
          console.error("Federated user without uid/email", response);
        }
        break;
      case SignInResult.Success:
        onSuccess();
        break;
      case SignInResult.Unknown:
        console.error("Unpredicted case", response);
        break;
    }
  }

  async signInWithFacebook() {
    const { firebase } = this.props;

    const response = await firebase.doSignInWithFacebook();

    this.handleFederatedSignInResponse(response);
  }

  async signInWithGoogle() {
    const { firebase } = this.props;

    const response = await firebase.doSignInWithGoogle();

    this.handleFederatedSignInResponse(response);
  }

  signInWithProvider() {
    const { provider } = this.props;

    if (provider === "google") {
      this.signInWithGoogle();
    } else if (provider === "facebook") {
      this.signInWithFacebook();
    }
  }

  render() {
    const {
      provider,
      i18nKey,
      onPasswordRequired,
      onRegistrationRequired,
      onSuccess,
      variant,
      icon,
      size,
      ...rest
    } = this.props;

    return (
      <AuthButton
        {...(rest as any)}
        onClick={() => this.signInWithProvider()}
        variant={variant ? variant : "blue"}
        i18nKey={i18nKey}
        icon={icon}
        size={size}
      />
    );
  }
}

export const SocialProviderButton = compose<Props, PropsExternal>(withFirebase)(
  SocialProviderButtonBase
);
