import styled from "@emotion/styled";
import themeGet from "@styled-system/theme-get";
import Area from "./Area";

const FooterArea = styled(Area)`
  height: ${themeGet("sizes.footerHeight.sm")};
  ${themeGet("mediaQueries.lg")} {
    height: ${themeGet("sizes.footerHeight.lg")};
  }
`;

FooterArea.defaultProps = {
  gridArea: "footer",
  bg: "fill.0",
  px: [0, 2],
  sx: { borderTop: 1, borderColor: "black.0" }
};

export default FooterArea;
