import React from "react";
import Svg, { SvgProps } from "primitives/Svg";

const SvgLocation = ({ width, height, size, color }: SvgProps) => (
  <Svg
    width={size || width}
    height={size || height}
    viewBox="0 0 16 16"
    color={color}>
    <path d="M8 0C3.587 0 0 3.587 0 8s3.587 8 8 8 8-3.587 8-8-3.597-8-8-8zM.615 8.302h1.571c.03 1.088.222 2.126.534 3.073h-1.3a7.4 7.4 0 01-.805-3.073zm10.347-.604a19.72 19.72 0 00-.262-3.073h1.935c.332.927.544 1.964.574 3.073h-2.247zM9.975 4.02H8.302V.675c.635.302 1.28 1.521 1.673 3.345zM7.698.675V4.02H6.025C6.418 2.196 7.053.977 7.698.675zm0 3.95v3.073H5.632c.01-1.129.111-2.167.272-3.073h1.794zm0 3.677v3.073H5.904a19.8 19.8 0 01-.272-3.073h2.066zm0 3.668v3.345c-.635-.302-1.28-1.511-1.673-3.345h1.673zm.604 3.345V11.97h1.673c-.393 1.833-1.038 3.043-1.673 3.345zm0-3.95V8.302h2.066a18.742 18.742 0 01-.272 3.073H8.302v-.01zm0-3.667V4.625h1.794c.16.906.252 1.944.272 3.073H8.302zM10.59 4.02a10.645 10.645 0 00-.423-1.562C9.934 1.804 9.673 1.28 9.38.877c1.25.493 2.318 1.632 3.003 3.143h-1.794zM6.61.877c-.292.403-.554.927-.785 1.581-.162.474-.303.998-.423 1.562H3.607C4.292 2.51 5.35 1.36 6.61.877zM5.29 4.625a19.62 19.62 0 00-.261 3.073H2.79a9.912 9.912 0 01.564-3.073H5.29zM2.187 7.698H.615a7.36 7.36 0 01.806-3.073h1.3a10.331 10.331 0 00-.535 3.073zm.605.604h2.237c.01 1.078.1 2.116.262 3.073H3.355a10.024 10.024 0 01-.564-3.073zM5.4 11.97c.11.564.251 1.088.423 1.561.231.655.493 1.18.786 1.582-1.25-.493-2.318-1.632-3.003-3.143h1.794zm3.99 3.153c.292-.403.554-.926.785-1.581.162-.474.303-.998.424-1.562h1.793c-.685 1.511-1.753 2.65-3.003 3.143zm1.31-3.758c.16-.947.251-1.995.261-3.073H13.2a9.68 9.68 0 01-.574 3.073H10.7zm3.113-3.063h1.571a7.358 7.358 0 01-.806 3.073h-1.3c.313-.947.504-1.985.535-3.073zm0-.604a10.787 10.787 0 00-.534-3.073h1.3c.473.927.765 1.964.805 3.073h-1.571zm.413-3.678h-1.18c-.453-1.068-1.067-1.985-1.813-2.67a7.501 7.501 0 012.993 2.67zM4.766 1.35c-.746.685-1.37 1.602-1.814 2.67H1.773a7.37 7.37 0 012.993-2.67zM1.773 11.97h1.18c.453 1.068 1.067 1.985 1.813 2.67a7.3 7.3 0 01-2.993-2.67zm9.451 2.67c.746-.685 1.37-1.602 1.814-2.67h1.179a7.3 7.3 0 01-2.993 2.67z" />
  </Svg>
);

SvgLocation.defaultProps = {
  width: 24,
  height: 24
};
export default SvgLocation;
