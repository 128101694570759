import React from "react";
import Svg, { SvgProps } from "primitives/Svg";

const SvgInstagram = ({ width, height, size, color }: SvgProps) => (
  <Svg
    width={size || width}
    height={size || height}
    viewBox="0 0 49 49"
    color={color}>
    <g clipPath="url(#Instagram_svg__clip0_9841_156699)">
      <path d="M35.766 8.93h-22.5a4.224 4.224 0 00-4.22 4.218v22.5a4.224 4.224 0 004.22 4.22h22.5a4.224 4.224 0 004.218-4.22v-22.5a4.224 4.224 0 00-4.218-4.218zm-11.25 25.312c-5.428 0-9.844-4.416-9.844-9.844 0-5.427 4.416-9.843 9.844-9.843 5.427 0 9.843 4.416 9.843 9.843 0 5.428-4.416 9.844-9.843 9.844zm9.843-16.875a2.816 2.816 0 01-2.812-2.812 2.816 2.816 0 012.812-2.813 2.816 2.816 0 012.813 2.813 2.816 2.816 0 01-2.813 2.812z" />
      <path d="M24.516 17.367a7.04 7.04 0 00-7.032 7.031c0 3.877 3.155 7.032 7.032 7.032a7.04 7.04 0 007.03-7.032c0-3.876-3.154-7.03-7.03-7.03z" />
      <path d="M41.39.398H7.64C3.765.398.517 3.647.517 7.523v33.75c0 3.877 3.248 7.125 7.125 7.125h33.75c3.876 0 7.125-3.248 7.125-7.125V7.523c0-3.876-3.249-7.125-7.125-7.125zm1.407 35.25a7.04 7.04 0 01-7.031 7.032h-22.5c-3.877 0-7.032-3.155-7.032-7.032v-22.5a7.04 7.04 0 017.032-7.03h22.5c3.876 0 7.03 3.154 7.03 7.03v22.5z" />
      <path d="M.516.398h48v4.364h-48z" />
      <path d="M.515 48.398v-48H4.88v48zM44.148 48.399v-48h4.364v48z" />
      <path d="M.516 44.031h48v4.364h-48z" />
    </g>
    <defs>
      <clipPath id="Instagram_svg__clip0_9841_156699">
        <path transform="translate(.516 .398)" d="M0 0h48v48H0z" />
      </clipPath>
    </defs>
  </Svg>
);

SvgInstagram.defaultProps = {
  width: 24,
  height: 24
};
export default SvgInstagram;
