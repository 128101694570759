interface InfoHref {
  i18n: string;
  href: string;
}

export const RegisterTerms: InfoHref = {
  i18n: "account.authentication.register.terms",
  href: "/about#terms-conditions"
};

export const RegisterPrivacyPolicy: InfoHref = {
  i18n: "account.authentication.register.privacyPolicy",
  href: "/about#privacy-policy"
};
