import {
  ErrorBoundary,
  SearchProvider,
  WithSearch
} from "@elastic/react-search-ui";
import AppSearchAPIConnector from "@elastic/search-ui-app-search-connector";
import { DocumentGalleryInfo, DocumentsView } from "app/documents";
import MultiCheckboxFacet from "app/search/filters/facets/MultiCheckboxFacet";
import SliderFacet from "app/search/filters/facets/SliderFacet";
import {
  CachedPagination,
  GalleryLayout,
  Modal,
  PageEmptyState
} from "app/shared";
import environment from "configurations";
import i18next from "i18next";
import { ColumnItem, ExtendedDocumentThumbnail, User } from "models";
import React, { ReactNode, useEffect, useState } from "react";
import { Trans } from "react-i18next";
import { Box } from "rebass";
import Sorting from "../filters/facets/Sorting";
import { DEFAULT_NUM_THUMBNAILS, DEFAULT_RESULTS_PER_PAGE } from "../models";
import { SearchLayout } from "../shared";
import { SliderMarks } from "./constants";
import moment from "moment";
import { mapDocumentResults } from "../utils/mapResults";

interface Props extends DocumentColumn {
  bodyHeader?: ReactNode;
  bodySubHeader?: ReactNode;
  resultsPerPage?: number;
  collections?: number[]; // if defined we should filter by photos with same collection id
  trackUrlState?: boolean;
  hideFilters?: boolean;
}

interface DocumentColumn {
  column?: number;
  showInfo?: boolean;
}

interface WithSearchProps extends DocumentColumn {
  wasSearched: boolean;
  trackClickThrough: (documentId: string) => void;
  results: any[];
  totalPages: number;
  totalResults: number;
  setCurrent: (page: number) => void;
  current: number;
  isLoading: boolean;
}

export const DocumentSearch: React.FC<Props> = ({
  bodyHeader,
  bodySubHeader,
  collections,
  trackUrlState,
  resultsPerPage,
  column,
  showInfo,
  hideFilters
}) => {
  const getConfig = () => {
    const filters = [
      { field: "class_name", values: ["document"], type: "any" }
    ];

    if (collections && collections.length) {
      filters.push({
        field: "collections",
        values: collections.map(it => it.toString()),
        type: "any"
      });
    }

    return {
      apiConnector: new AppSearchAPIConnector(environment.search),
      alwaysSearchOnInitialLoad: true,
      trackUrlState,
      initialState: {
        filters, // set this filter as init filter
        resultsPerPage
      },
      searchQuery: {
        result_fields: {
          real_id: { raw: {} },
          thumbnail: { raw: {} },
          thumbnail_large: { raw: {} },
          width: { raw: {} },
          height: { raw: {} },
          uid: { raw: {} },
          owner: { raw: {} },
          owner_picture: { raw: {} },
          city: { raw: {} },
          country: { raw: {} },
          taken_at: { raw: {} },
          translations: { raw: {} }
        },
        facets: {
          country: { type: "value", size: 30 },
          taken_at: {
            type: "range",
            ranges: [
              {
                from: moment()
                  .subtract(100, "years")
                  .toISOString(),
                name: i18next.t("search.documents.anyDate")
              },
              {
                from: moment()
                  .subtract(24, "hours")
                  .toISOString(),
                name: i18next.t("search.documents.last24Hours")
              },
              {
                from: moment()
                  .subtract(1, "weeks")
                  .toISOString(),
                name: i18next.t("search.documents.lastWeek")
              },
              {
                from: moment()
                  .subtract(1, "months")
                  .toISOString(),
                name: i18next.t("search.documents.lastMonth")
              },
              {
                from: moment()
                  .subtract(1, "years")
                  .toISOString(),
                name: i18next.t("search.documents.lastYear")
              }
            ]
          },
          categories: { type: "value", size: 30 },
          labels: { type: "value", size: 30 },
          orientation: { type: "value", size: 30 },
          attributes: { type: "value", size: 30 },
          licenses: { type: "value", size: 30 },
          owner_picture: { type: "value", size: 30 },
          prices: { type: "value", size: 1 }
        }
      }
    };
  };

  return (
    <SearchProvider config={getConfig()}>
      <WithSearch
        mapContextToProps={({
          wasSearched,
          trackClickThrough,
          results,
          totalPages,
          totalResults,
          setCurrent,
          current,
          isLoading
        }: WithSearchProps) => ({
          wasSearched,
          trackClickThrough,
          results,
          totalPages,
          totalResults,
          setCurrent,
          current,
          isLoading
        })}>
        {(props: WithSearchProps) => {
          return (
            <Box>
              <ErrorBoundary>
                {!hideFilters ? (
                  <SearchLayout
                    totalResults={props.totalResults}
                    sideContent={
                      <>
                        {props.wasSearched && (
                          <Sorting
                            label={i18next.t("search.documents.sortBy")}
                            sortOptions={[
                              {
                                name: i18next.t("search.documents.bestMatch"),
                                value: "",
                                direction: ""
                              },
                              {
                                name: i18next.t("search.documents.latest"),
                                value: "created_at",
                                direction: "desc"
                              }
                            ]}
                          />
                        )}
                        <MultiCheckboxFacet
                          field="country"
                          label={i18next.t("search.documents.country")}
                          filterType="any"
                        />
                        <MultiCheckboxFacet
                          field="taken_at"
                          label={i18next.t("search.documents.dateRange")}
                          filterType="any"
                        />
                        <MultiCheckboxFacet
                          field="categories"
                          label={i18next.t("search.documents.categories")}
                          filterType="any"
                        />
                        <MultiCheckboxFacet
                          field="labels"
                          label={i18next.t("search.documents.labels")}
                          filterType="any"
                        />
                        <MultiCheckboxFacet
                          field="orientation"
                          label={i18next.t("search.documents.orientation")}
                          filterType="any"
                        />
                        <MultiCheckboxFacet
                          field="attributes"
                          label={i18next.t("search.documents.attributes")}
                          filterType="any"
                        />
                        <MultiCheckboxFacet
                          field="licenses"
                          label={i18next.t("search.documents.licenses")}
                          filterType="any"
                        />
                        <SliderFacet
                          field="prices"
                          label={i18next.t("search.documents.prices")}
                          filterType="any"
                          marks={SliderMarks}
                        />
                      </>
                    }
                    bodyContent={
                      <SearchBodyContent
                        {...props}
                        showInfo={showInfo}
                        column={column}
                      />
                    }
                    bodyHeader={bodyHeader}
                    bodySubHeader={bodySubHeader}
                  />
                ) : (
                  <SearchBodyContent
                    {...props}
                    showInfo={showInfo}
                    column={column}
                  />
                )}
              </ErrorBoundary>
            </Box>
          );
        }}
      </WithSearch>
    </SearchProvider>
  );
};

DocumentSearch.defaultProps = {
  resultsPerPage: DEFAULT_RESULTS_PER_PAGE,
  trackUrlState: true
};

/**
 * Using component with its own state to present results to prevent re-render of SearchProvider
 * when state changes resulting in CachedPagination being constructed again.
 */
const SearchBodyContent: React.FC<WithSearchProps> = ({
  results,
  isLoading,
  current,
  totalPages,
  setCurrent,
  totalResults,
  trackClickThrough,
  wasSearched,
  column,
  showInfo
}) => {
  const [isSlideShow, setSlideShow] = useState(false);
  const [selectedDocumentId, setSelectedDocumentId] = useState<
    number | undefined
  >(undefined);

  const [documentHeight, setDocumentHeight] = useState<ColumnItem>({
    isCollapsed: "",
    notCollapsed: "",
    titleFontSize: undefined,
    textBottomFontSize: undefined,
    ownerNameFontSize: undefined
  });

  useEffect(() => {
    if (column) {
      if (column === 1) {
        setDocumentHeight({
          isCollapsed: "",
          notCollapsed: "",
          titleFontSize: undefined,
          textBottomFontSize: undefined,
          ownerNameFontSize: undefined
        });
      } else if (column === 2) {
        setDocumentHeight({
          isCollapsed: "",
          notCollapsed: "",
          titleFontSize: undefined,
          textBottomFontSize: undefined,
          ownerNameFontSize: undefined
        });
      } else if (column === 4) {
        setDocumentHeight({
          isCollapsed: "",
          notCollapsed: "",
          titleFontSize: [2, 3, 3],
          textBottomFontSize: 1,
          ownerNameFontSize: 1
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [column]);

  return (
    <CachedPagination<ExtendedDocumentThumbnail>
      disabled={isLoading}
      firstPageNumber={1}
      results={mapDocumentResults(results)}
      page={current}
      totalPages={totalPages}
      setPage={setCurrent}
      scrollListenerEnabled={isSlideShow ? false : true}>
      {(documents, setPage) => {
        if (documents.length) {
          return (
            <>
              <DocumentsView
                documents={documents}
                columnCount={column}
                titleFontSize={documentHeight.titleFontSize}
                textBottomFontSize={documentHeight.textBottomFontSize}
                ownerNameFontSize={documentHeight.ownerNameFontSize}
                customMinHeight={column === 4 ? "100px" : undefined}
                largeThumbnail={column === 1 ? true : undefined}
                onDocumentClick={documentId => {
                  setSelectedDocumentId(documentId);
                  setSlideShow(true);
                }}
                trackClickThrough={trackClickThrough}
                showInfoBottom={showInfo}
                showUserInfo={showInfo}
                showManageIcons={showInfo}
                showHoverInfo={showInfo}
              />
              <Modal open={isSlideShow} onClose={() => setSlideShow(false)}>
                <GalleryLayout
                  items={documents}
                  documentInfoView={(
                    documentId: number,
                    user: User,
                    description?: string,
                    trackClickThrough?: () => void
                  ) => (
                    <DocumentGalleryInfo
                      documentId={documentId}
                      user={user}
                      description={description}
                      trackClickThrough={trackClickThrough}
                    />
                  )}
                  setPage={setPage}
                  totalDocuments={totalResults}
                  numberOfThumbnails={DEFAULT_NUM_THUMBNAILS}
                  selectedDocumentId={selectedDocumentId}
                />
              </Modal>
            </>
          );
        } else {
          return (
            <PageEmptyState
              mt={6}
              loading={!wasSearched || isLoading}
              header={<Trans i18nKey="search.shared.noResults" />}
              description={
                <Trans i18nKey="search.shared.noResultsDescription" />
              }
            />
          );
        }
      }}
    </CachedPagination>
  );
};
