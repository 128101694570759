import { ResultsCounter } from "app/search/shared/ResultsCounter";
import {
  Tabs,
  Button,
  GridLabel,
  SocialMediaButton,
  ColumnButton,
  ShowHideInfoButton
} from "app/shared";
import { AuthUserContext } from "authentication/session";
import { checkSameUser } from "authentication/utils";
import { User } from "models";
import React, { ReactNode, useState } from "react";
import { Trans } from "react-i18next";
import { Box, Flex, Text } from "rebass";
import { ProfileTab } from "./models";
import { UserFollowsCounter } from "./UserFollowsCounter";
import { UserProfileCollections } from "./UserProfileCollections";
import { UserProfileBuckets } from "./UserProfileBuckets";
import { UserProfilePhotos } from "./UserProfilePhotos";
import i18next from "i18next";
import { useMediaQueryContext } from "styles/context";
import { Icon, ElementOptions } from "app/shared";
import ConditionalWrapper from "app/shared/wrapper/ConditionalWrapper";
import { ReportUserButton } from "app/shared/elementOptions/ReportUserButton";

import {
  UserEquipments,
  UserHousings,
  UserTransportations
} from "app/account/editMyInfo/constants";
import { Connections } from "..";

interface Props {
  user: User;
  filter: string;
}

/**
 * Renders provided tab for the provided user uid.
 */
const profileTabLabelRender = (tab: ProfileTab, uid: string): ReactNode => {
  switch (tab.type) {
    case "ResultsCounter":
      return (
        <>
          <Trans i18nKey={tab.i18n} />{" "}
          <ResultsCounter filters={tab.filters ?? []} />
        </>
      );
    case "UserFollowsCounter":
      return (
        <>
          <Trans i18nKey={tab.i18n} />{" "}
          <UserFollowsCounter uid={uid} mode={tab.mode ?? "followers"} />
        </>
      );
    default:
      return <Trans i18nKey={tab.i18n} />;
  }
};

export const ProfileTabs: React.FC<Props> = ({ user, filter }) => {
  const [collapsed, setCollapsed] = useState(true);
  const [column, setColumn] = useState<number | undefined>(undefined);
  const [showInfo, setShowInfo] = useState<boolean | undefined>(undefined);

  const { isSmallMedium } = useMediaQueryContext();
  return (
    <AuthUserContext.Consumer>
      {({ authUser }) => {
        const tabs: ProfileTab[] = [
          {
            name: "collections",
            type: "none",
            filters: [
              { field: "class_name", values: ["collection"], type: "any" },
              { field: "uid", values: [user.id], type: "any" },
              {
                field: "privacy",
                values:
                  authUser && checkSameUser(user.id, authUser)
                    ? ["public", "private"]
                    : ["public"],
                type: "any"
              }
            ],
            i18n: "account.profile.profileTabsButtons.collections",
            href: `/users/${user.id}/collections`
          },
          {
            name: "photos",
            type: "none",
            filters: [
              { field: "class_name", values: ["document"], type: "any" },
              { field: "uid", values: [user.id], type: "any" }
            ],
            i18n: "account.profile.profileTabsButtons.photos",
            href: `/users/${user.id}/photos`
          },
          {
            name: "buckets",
            type: "none",
            filters: [
              { field: "class_name", values: ["bucket"], type: "any" },
              { field: "uid", values: [user.id], type: "any" },
              {
                field: "privacy",
                values:
                  authUser && checkSameUser(user.id, authUser)
                    ? ["public", "private"]
                    : ["public"],
                type: "any"
              }
            ],
            i18n: "account.profile.profileTabsButtons.buckets",
            href: `/users/${user.id}/buckets`
          }
        ];
        const userHousing = UserHousings.filter(housing =>
          user.collaboration?.includes(housing.name)
        );
        const userEquipments = UserEquipments.filter(equipment =>
          user.collaboration?.includes(equipment.name)
        );
        const userTransportation = UserTransportations.filter(transportation =>
          user.collaboration?.includes(transportation.name)
        );
        const tabsComponent = (
          <Box>
            <Flex
              alignItems={isSmallMedium ? "flex-start" : "center"}
              height="100%"
              mt={4}>
              <Tabs
                px={[1, 0]}
                key={user.id} // Using key={user.uid} to force refresh when user changes
                fontSize={[1, 3]}
                activeName={filter}
                tabs={tabs.map(tab => ({
                  name: tab.name,
                  label: profileTabLabelRender(tab, user.id),
                  href: tab.href
                }))}
                wrapTabs={false}
              />
              {!isSmallMedium && (
                <ColumnButton
                  ml={3}
                  onColumnChange={column => setColumn(column)}
                />
              )}
              {filter !== "connections" && (
                <Flex
                  ml={[0, 3]}
                  mr={[2, 0]}
                  height="100%"
                  alignItems="flex-start"
                  justifyContent="flex-end">
                  <ShowHideInfoButton
                    customPy={isSmallMedium ? "5px" : undefined}
                    onShowInfoChange={showInfo => setShowInfo(showInfo)}
                  />
                </Flex>
              )}
            </Flex>
          </Box>
        );
        return (
          <Flex
            flexDirection={
              isSmallMedium ? "column" : collapsed ? "column" : "row"
            }>
            <ConditionalWrapper
              wrapper={children => (
                <Flex flexDirection="row" justifyContent="space-between">
                  {children}
                </Flex>
              )}
              condition={collapsed && !isSmallMedium}>
              {isSmallMedium && <Box width={"100%"}>{tabsComponent}</Box>}
              <Flex
                flexWrap="wrap"
                pt={4}
                pb={[6, 2]}
                pr={isSmallMedium ? 2 : 6}
                pl={[2, 0]}
                justifyContent="center">
                <Box
                  width={isSmallMedium ? "100%" : "304px"}
                  sx={{ flexShrink: 0 }}>
                  <Box px={2} width={2 / 2} color="white" bg="black">
                    <Flex>
                      <Flex alignItems="center" width="100%">
                        <Box>
                          <Text
                            fontSize={[2, 3]}
                            p={2}
                            variant={"grotTextCaps"}>
                            <Trans i18nKey="account.profile.userProfileAbout.about" />{" "}
                            {user.professionalName.split(" ")[0]}
                            {collapsed}
                          </Text>
                        </Box>
                      </Flex>
                      <Flex justifyContent="flex-end" width={"50%"} mr={-2}>
                        {authUser && !checkSameUser(user.id, authUser) && (
                          <ElementOptions
                            closeMenuOnClick={false}
                            options={[
                              () => (
                                <ReportUserButton
                                  userId={user.id}
                                  sx={{
                                    border: 0,
                                    textAlign: "center",
                                    color: "red"
                                  }}
                                />
                              )
                            ]}
                            elementId={12}
                            iconName="MoreOptions"
                            iconSize={18}
                            optionsWidth={["120px", "179px"]}
                            backgroundAlwaysActive={false}
                            sx={{
                              borderRadius: 0,
                              bg: "white.0",
                              border: 1,
                              borderColor: "black.0",
                              borderRight: "none",
                              px: 2,
                              py: 2,
                              "& svg path": { fill: "white" }
                            }}
                            popupSx={{
                              position: "relative",
                              top: "-30px",
                              color: "black",
                              zIndex: 99,
                              left: ["-130px", "-10px"]
                            }}
                          />
                        )}
                        <Button
                          onClick={() => setCollapsed(!collapsed)}
                          sx={{
                            px: 2,
                            py: 2
                          }}>
                          <Icon
                            name={collapsed ? "ArrowDown" : "ArrowUp"}
                            size={18}
                          />
                        </Button>
                      </Flex>
                    </Flex>
                  </Box>
                  {!collapsed && (
                    <Box>
                      <Box
                        px={2}
                        width={2 / 2}
                        color="black"
                        bg="white"
                        sx={{
                          border: 1,
                          borderColor: "black.0",
                          borderTop: "none"
                        }}>
                        <Text fontSize={[3]} p={2} variant={"grotText"}>
                          {user.translations?.[0]?.about || (
                            <Trans i18nKey="account.profile.userProfileAbout.noDescription" />
                          )}
                        </Text>
                      </Box>
                      {user.skills && user.skills.length !== 0 && (
                        <Box width={2 / 2}>
                          <Box px={2} color="white" bg="black">
                            <Text fontSize={[3]} p={2} variant={"grotTextCaps"}>
                              <Trans i18nKey="account.profile.profileInfo.skills" />
                            </Text>
                          </Box>
                          <Box
                            px={2}
                            py={2}
                            color="black"
                            bg="white"
                            sx={{
                              border: 1,
                              borderColor: "black.0",
                              borderTop: "none"
                            }}>
                            {user.skills.slice(0, 3).map((skill, index) => (
                              <Text
                                variant={"grotText"}
                                sx={{
                                  display: "inline",
                                  wordBreak: "break-all"
                                }}
                                fontSize={[3]}
                                key={`skill_${index}`}>
                                {i18next.t(
                                  `account.editMyInfo.about.skills.${skill}`
                                ) +
                                  `${
                                    index !== 2 &&
                                    index !== user.skills.length - 1
                                      ? ", "
                                      : ""
                                  }`}
                              </Text>
                            ))}
                          </Box>
                        </Box>
                      )}
                      {user.social.length !== 0 && (
                        <Box>
                          <Box
                            px={2}
                            py={2}
                            width={2 / 2}
                            color="black"
                            bg="white"
                            sx={{
                              border: 1,
                              borderTop: "none",
                              borderColor: "black.0"
                            }}>
                            {user.social.map(socialMedia =>
                              socialMedia.name ? (
                                <Box
                                  key={socialMedia.name}
                                  pr={2}
                                  sx={{
                                    display: "inline"
                                  }}>
                                  <SocialMediaButton
                                    key={`${socialMedia.name}_icon`}
                                    socialMediaType={socialMedia.name}
                                    socialMediaValue={socialMedia.value}
                                  />
                                </Box>
                              ) : null
                            )}
                          </Box>
                        </Box>
                      )}
                      {user.websites && user.websites.length !== 0 && (
                        <Box width={2 / 2}>
                          <Box px={2} color="white" bg="black">
                            <Text fontSize={[3]} p={2} variant={"grotTextCaps"}>
                              <Trans i18nKey="account.profile.userProfileAbout.website" />
                            </Text>
                          </Box>
                          <Box
                            px={2}
                            py={2}
                            color="black"
                            bg="white"
                            sx={{
                              border: 1,
                              borderColor: "black.0",
                              borderTop: "none",
                              wordBreak: "break-all"
                            }}>
                            {user.websites.map((website, index) => (
                              <Button
                                key={`website_${index}`}
                                href={
                                  website.includes("://")
                                    ? website
                                    : `http://${website}`
                                }
                                target="_blank">
                                <Text fontSize={[3]} variant={"grotText"}>
                                  {website}
                                </Text>
                              </Button>
                            ))}
                          </Box>
                        </Box>
                      )}
                      {user.publishedWorks && user.publishedWorks.length !== 0 && (
                        <Box width={2 / 2}>
                          <Box px={2} color="white" bg="black">
                            <Text fontSize={[3]} p={2} variant={"grotTextCaps"}>
                              <Trans i18nKey="account.profile.userProfileAbout.publishedWorks" />
                            </Text>
                          </Box>
                          <Box
                            px={2}
                            py={2}
                            color="black"
                            bg="white"
                            sx={{
                              border: 1,
                              borderColor: "black.0",
                              borderTop: "none",
                              wordBreak: "break-all"
                            }}>
                            {user.publishedWorks.map((publishedWork, index) => (
                              <Button
                                key={`website_${index}`}
                                href={
                                  publishedWork.includes("://")
                                    ? publishedWork
                                    : `http://${publishedWork}`
                                }
                                target="_blank">
                                <Text fontSize={[3]} variant={"grotText"}>
                                  {publishedWork}
                                </Text>
                              </Button>
                            ))}
                          </Box>
                        </Box>
                      )}
                      <Box width={2 / 2}>
                        {user.collaboration.length !== 0 && (
                          <Box px={2} color="white" bg="black">
                            <Text fontSize={[3]} p={2} variant={"grotTextCaps"}>
                              <Trans i18nKey="account.editMyInfo.colabWork.collaborative" />
                            </Text>
                          </Box>
                        )}
                        {userEquipments && userEquipments.length !== 0 && (
                          <Box
                            px={2}
                            py={2}
                            color="black"
                            bg="white"
                            sx={{
                              border: 1,
                              borderColor: "black.0",
                              borderTop: "none"
                            }}>
                            <GridLabel
                              pb={2}
                              i18nKey="account.profile.userProfileAbout.equipment"
                            />
                            {userEquipments.map((equipment, index) => (
                              <Text
                                variant={"grotText"}
                                sx={{
                                  display: "inline"
                                }}
                                fontSize={[3]}
                                key={`equipment_${index}`}>
                                {i18next.t(equipment.i18n) +
                                  `${
                                    index !== userEquipments.length - 1
                                      ? ", "
                                      : ""
                                  }`}
                              </Text>
                            ))}
                          </Box>
                        )}
                        {userHousing && userHousing.length !== 0 && (
                          <Box
                            px={2}
                            py={2}
                            color="black"
                            bg="white"
                            sx={{
                              border: 1,
                              borderTop: "none",
                              borderColor: "black.0"
                            }}>
                            <GridLabel
                              pb={2}
                              i18nKey="account.profile.userProfileAbout.housing"
                            />
                            {userHousing.map((housing, index) => (
                              <Text
                                variant={"grotText"}
                                sx={{
                                  display: "inline"
                                }}
                                fontSize={[3]}
                                key={`housing_${index}`}>
                                {i18next.t(housing.i18n) +
                                  `${
                                    index !== userHousing.length - 1 ? ", " : ""
                                  }`}
                              </Text>
                            ))}
                          </Box>
                        )}
                        {userTransportation && userTransportation.length !== 0 && (
                          <Box
                            px={2}
                            py={2}
                            color="black"
                            bg="white"
                            sx={{
                              border: 1,
                              borderTop: "none",
                              borderColor: "black.0"
                            }}>
                            <GridLabel
                              pb={2}
                              i18nKey="account.profile.userProfileAbout.transportation"
                            />
                            {userTransportation.map((transportation, index) => (
                              <Text
                                variant={"grotText"}
                                sx={{
                                  display: "inline"
                                }}
                                fontSize={[3]}
                                key={`transportation_${index}`}>
                                {i18next.t(transportation.i18n) +
                                  `${
                                    index !== userTransportation.length - 1
                                      ? ", "
                                      : ""
                                  }`}
                              </Text>
                            ))}
                          </Box>
                        )}
                      </Box>
                    </Box>
                  )}
                </Box>
              </Flex>
              {collapsed && !isSmallMedium && (
                <Box width={"75%"}>{tabsComponent}</Box>
              )}
            </ConditionalWrapper>
            <Box
              mt={[1, 3]}
              px={[2, 0]}
              width={collapsed || isSmallMedium ? "100%" : "75%"}>
              {!collapsed && !isSmallMedium && <Box>{tabsComponent}</Box>}
              {filter === "collections" && (
                <UserProfileCollections
                  uid={user.id}
                  sidebarOpen={!collapsed}
                  column={column}
                  showInfo={showInfo}
                />
              )}
              {filter === "photos" && (
                <UserProfilePhotos
                  uid={user.id}
                  column={column}
                  showInfo={showInfo}
                />
              )}
              {filter === "buckets" && (
                <UserProfileBuckets
                  uid={user.id}
                  sidebarOpen={!collapsed}
                  column={column}
                  showInfo={showInfo}
                />
              )}
              {filter === "connections" && (
                <Connections
                  column={column}
                  isCollapsed={collapsed}
                  uid={user.id}
                />
              )}
            </Box>
          </Flex>
        );
      }}
    </AuthUserContext.Consumer>
  );
};
