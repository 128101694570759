type I18nDotSeparatedString = string;

interface SubmitContentSteps {
  stepNumber: number;
  i18n: I18nDotSeparatedString;
}

export const SubmitContentSteps: SubmitContentSteps[] = [
  { stepNumber: 1, i18n: "documents.submitContent.personalInfoTitle" },
  { stepNumber: 2, i18n: "documents.submitContent.reviewPortfolioTitle" },
  { stepNumber: 3, i18n: "documents.submitContent.skillRolesTitle" },
  { stepNumber: 4, i18n: "documents.submitContent.promoToolsTitle" },
  { stepNumber: 5, i18n: "documents.submitContent.collabTitle" },
  { stepNumber: 6, i18n: "documents.submitContent.businessInfoTitle" },
  { stepNumber: 7, i18n: "documents.submitContent.verifyAccountTitle" },
  { stepNumber: 8, i18n: "documents.submitContent.uploadContentTitle" },
  { stepNumber: 9, i18n: "documents.submitContent.fillInformationTitle" },
  { stepNumber: 10, i18n: "documents.submitContent.resultTitle" }
];
