import React, { ComponentType, ReactNode, useState } from "react";
import { Trans } from "react-i18next";
import { Box, Flex, SxStyleProp, Text } from "rebass";
import { Checkbox } from "../checkbox";
import { DropdownButton } from "../dropdownButton";
import { ElementOptions } from "../elementOptions";
import { ElementOption } from "../elementOptions/models";
import { Actions } from "./Actions";
import { Action, ActionsProps } from "./models";

interface Props {
  actions?: Action[];
  elementOptions?: ElementOption[];
  defaultSelectActions?: boolean;
  nodes: ReactNode[];
  view: (children: ReactNode[]) => ReactNode;
  sx?: SxStyleProp;
  popUpOpen?: boolean;
  selectedIds?: any[];
  onSelectedIdsChange?: (ids: any[]) => void;
  hideCheckbox?: boolean;
}

interface State {
  ids: any[];
}

//auxiliary function to get the actions view depending if it is mobile or not
const actionsView = (
  Component: ComponentType<ActionsProps>,
  props: ActionsProps
): ReactNode => <Component {...(props as any)} />;

export const BulkActionLayout: React.FC<Props> = ({
  selectedIds,
  actions,
  nodes,
  defaultSelectActions,
  view,
  sx,
  elementOptions,
  popUpOpen,
  onSelectedIdsChange,
  hideCheckbox
}) => {
  const [ids, setInternalIds] = useState<any>(selectedIds ?? []);

  const setIds = (ids: any[]) => {
    setInternalIds([...ids]);
    onSelectedIdsChange?.(ids);
  };
  const toggleId = (id: any) => {
    let newIds = ids;

    if (ids.includes(id)) {
      newIds = ids.filter((it: any) => it !== id);
    } else {
      newIds.push(id);
    }
    setIds(newIds);
  };

  const actionsProps: ActionsProps = {
    actions,
    selectedIds: ids,
    defaultSelectActions,
    selectAll: () => setIds(nodes.map((node: any) => node.key)),
    selectNone: () => setIds([])
  };

  return (
    <Box>
      <DropdownButton
        popUpOpen={popUpOpen}
        titlei18n="shared.bulk.bulkActionLayout.edit"
        width="50%"
        mb={4}>
        {defaultSelectActions &&
          actions?.length !== 0 &&
          actionsView(Actions, actionsProps)}
      </DropdownButton>
      {nodes.length > 0 ? (
        view(
          nodes.map((node: any, index: number) => (
            <Box
              key={index}
              sx={{
                position: "relative"
              }}>
              <Flex sx={{ position: "absolute" }}>
                {!hideCheckbox && (
                  <Checkbox
                    p={0}
                    name="documentId"
                    checked={ids.includes(node.key)}
                    onChange={() => {
                      toggleId(node.key);
                    }}
                    sx={{ ...sx, zIndex: 1 }}
                  />
                )}
                {elementOptions && elementOptions.length > 0 && (
                  <ElementOptions
                    options={elementOptions}
                    elementId={node.key}
                  />
                )}
              </Flex>
              {node}
            </Box>
          ))
        )
      ) : (
        <Text>
          <Trans i18nKey="shared.bulk.bulkActionLayout.noResults" />
        </Text>
      )}
    </Box>
  );
};
