import React from "react";
import Svg, { SvgProps } from "primitives/Svg";

const SvgCloseWhite = ({ width, height, size, color }: SvgProps) => (
  <Svg
    width={size || width}
    height={size || height}
    viewBox="0 0 24 24"
    color={color}>
    <path d="M18.478 5.478L5.522 18.433m12.956 0L5.522 5.478" stroke="#fff" />
  </Svg>
);

SvgCloseWhite.defaultProps = {
  width: 24,
  height: 24
};
export default SvgCloseWhite;
