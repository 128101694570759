import React, { ReactNode, useEffect, useState } from "react";
import { Box, BoxProps } from "rebass";
import { useMediaQueryContext } from "styles/context";
import { sizes } from "styles/theme";

interface Props extends BoxProps {
  children: ReactNode;
}

/**
 * This component will guarantee that menu's are aligned at the right below the end of the main layout.
 * This component adds window resize listener to dynamically update its position when window is resized.
 */
export const MenuWrapper: React.FC<Props> = ({
  children,
  maxWidth,
  ...rest
}) => {
  const { isMediumLarge } = useMediaQueryContext();
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);
  const isMobile = isMediumLarge;
  const onResize = () => {
    if (window.innerWidth !== innerWidth) {
      setInnerWidth(window.innerWidth);
    }
  };
  useEffect(() => {
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  });

  return (
    <Box
      width={["100%", null]}
      maxWidth={["100%", maxWidth ? maxWidth : null]}
      sx={{
        position: "absolute",
        border: 1,
        borderColor: "black.0",
        right: isMobile
          ? 0
          : `calc((${innerWidth}px - ${sizes.maxWidth} - 5px) / 2)`,
        top: [
          `calc(${sizes.navbarHeight.sm} - 1px)`,
          null,
          null,
          `calc(${sizes.navbarHeight.lg} - 1px)`
        ],
        zIndex: 10
      }}
      {...(rest as any)}>
      {children}
    </Box>
  );
};
