import { UserPicture } from "app/account";
import { Donation } from "app/donations/models";
import {
  PageEmptyState,
  FormattedDate,
  Toast,
  Button,
  BackgroundImage
} from "app/shared";
import { Label } from "app/shared/label";
import { EmptyHeader, TableLayout } from "app/shared/tableLayout";
import React, { Component } from "react";
import { Trans } from "react-i18next";
import { Box, Text } from "rebass";
import { CancelDonationPopUp } from "./CancelDonationPopUp";
import { getDonationInvoice, getDonationReceipt } from "./services";
import { checkFrequency, checkPrice, checkStatus } from "./utils";
import SimpleLogo from "../../../src/icons/svg/FFSimpleLogo.svg";

interface Props {
  items: any;
  refresh?: () => void;
  mode: "made" | "received";
}

interface State {
  disabled: boolean;
}

export class DonationsViewer extends Component<Props, State> {
  state = {
    disabled: false
  };

  receiptDonation(donationId: number) {
    this.setState({ disabled: true }, () => {
      getDonationReceipt(donationId)
        .then(signedUrl => {
          this.setState({ disabled: false }, () => {
            window.open(signedUrl.url);
          });
        })
        .catch(error => {
          Toast.apiError(error);
          this.setState({ disabled: false });
        });
    });
  }

  invoiceDonation(donationId: number) {
    this.setState({ disabled: true }, () => {
      getDonationInvoice(donationId)
        .then(signedUrl => {
          this.setState({ disabled: false }, () => {
            window.open(signedUrl.url);
          });
        })
        .catch(error => {
          Toast.error({
            title: { key: "documents.transactionItemsViewer.noInvoice" }
          });
          this.setState({ disabled: false });
        });
    });
  }

  render() {
    const { items, mode } = this.props;
    const { disabled } = this.state;
    if (items.length === 0) {
      return (
        <PageEmptyState
          loading={disabled}
          header={<Trans i18nKey="donations.donationsViewer.noItems" />}
        />
      );
    } else {
      return (
        <>
          <Text fontSize={[3, 7]} py={3} sx={{ textTransform: "uppercase" }}>
            <Trans
              i18nKey={
                mode === "made"
                  ? "donations.donationsViewer.madeTitle"
                  : "donations.donationsViewer.receivedTitle"
              }
            />
          </Text>
          <TableLayout
            width="100%"
            columns={7}
            // columnSizeArray={["100%"]}
            py={[1, 2, 3]}
            mb={[1, 2, 3]}
            tableNodes={[
              <EmptyHeader
                minWidth={["300px", "100px"]}
                key="emptyHeader"
                sx={{ borderBottom: 1, borderColor: "black.0" }}
              />,
              <EmptyHeader
                key="emptyHeader"
                sx={{ borderBottom: 1, borderColor: "black.0" }}
              />,
              <Label
                key="dateHeader"
                i18n="donations.donationsViewer.dateHeader"
                fontSize={1}
                sx={{ borderBottom: 1, borderColor: "black.0" }}
              />,
              <Label
                key="priceHeader"
                i18n="donations.donationsViewer.contributionHeader"
                fontSize={[1]}
                sx={{ borderBottom: 1, borderColor: "black.0" }}
              />,
              <Label
                key="priceHeader"
                i18n="donations.donationsViewer.frequencyHeader"
                fontSize={1}
                sx={{ borderBottom: 1, borderColor: "black.0" }}
              />,
              <Label
                key="priceHeader"
                i18n="donations.donationsViewer.statusHeader"
                fontSize={1}
                sx={{ borderBottom: 1, borderColor: "black.0" }}
              />,
              <EmptyHeader
                minWidth={["100px", "0"]}
                key="emptyHeader"
                sx={{ borderBottom: 1, borderColor: "black.0" }}
              />,
              ...items.flatMap((item: Donation) => [
                <Box>
                  {mode === "made" && item.seller?.id !== undefined ? (
                    <UserPicture picture={item.seller?.picture} width="100px" />
                  ) : mode === "made" && item.seller?.id === undefined ? (
                    <BackgroundImage
                      mode="ff"
                      src={SimpleLogo}
                      width="100px"
                      height="100px"
                    />
                  ) : (
                    <UserPicture picture={item.buyer?.picture} width="100px" />
                  )}
                </Box>,
                <Box minWidth={["200px", "0"]} maxWidth={"200px"}>
                  <Text variant="grotText" sx={{ textTransform: "uppercase" }}>
                    {mode === "made"
                      ? item.seller?.name
                        ? item.seller?.name
                        : "Frontfiles"
                      : item.buyer?.name
                      ? item.buyer?.name
                      : "Frontfiles"}
                  </Text>
                </Box>,
                <Text variant="grotText">
                  <FormattedDate format="number" date={item?.createdAt} />
                </Text>,
                <Text variant="grotText">
                  {checkPrice(item.price?.value, item.price?.currency)}
                </Text>,
                <Text variant="grotText">
                  {checkFrequency(item.frequency, item.parentId as number)}
                </Text>,
                <Text variant="grotText">{checkStatus(item.status)}</Text>,
                (item.status === "pending" || item.status === "error") &&
                mode === "made" ? (
                  <Box>
                    <CancelDonationPopUp donation={item} />
                  </Box>
                ) : mode === "made" && item.status === "completed" ? (
                  <Box>
                    <Button
                      width="100%"
                      onClick={() => this.receiptDonation(item.id)}>
                      <Text>
                        <Trans i18nKey="donations.donationsViewer.receipt" />
                      </Text>
                    </Button>
                  </Box>
                ) : mode === "received" && item.status === "completed" ? (
                  <Box>
                    <Button
                      width="100%"
                      onClick={() => this.invoiceDonation(item.id)}>
                      <Text>
                        <Trans i18nKey="donations.donationsViewer.invoice" />
                      </Text>
                    </Button>
                  </Box>
                ) : (
                  <Box minWidth={["100px", "0"]}></Box>
                )
              ])
            ]}
          />
        </>
      );
    }
  }
}
