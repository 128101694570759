import { ISOLanguageCode } from "i18n/resources/supportedLanguages";

export type ZuluTimeString = string;
export type FormatDateType = "number" | "string" | "hours";

export const formatToStringDate = (
  createdAt: ZuluTimeString,
  language: ISOLanguageCode,
  showHours: boolean
) =>
  new Date(createdAt).toLocaleDateString(language, {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: showHours ? "numeric" : undefined,
    minute: showHours ? "numeric" : undefined
  });

export const formatToNumberDate = (
  createdAt: ZuluTimeString,
  language: ISOLanguageCode,
  showHours: boolean
) =>
  new Date(createdAt)
    .toLocaleDateString(language, {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: showHours ? "numeric" : undefined,
      minute: showHours ? "numeric" : undefined
    })
    .split("/")
    .join(".");
