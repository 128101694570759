import React from "react";
import Svg, { SvgProps } from "primitives/Svg";

const SvgAddToCollection = ({ width, height, size, color }: SvgProps) => (
  <Svg
    width={size || width}
    height={size || height}
    viewBox="0 0 24 27"
    color={color}>
    <path d="M16 8.667h-3.333V5.333h-1.334v3.334H8V10h3.333v3.333h1.334V10H16V8.667z" />
    <path d="M0 0v18.667h1.333V1.333h21.334V24.24l-9.947-6.36-.72-.467-.72.467-9.947 6.36v-2.907H0v5.334L12 19l12 7.667V0H0z" />
  </Svg>
);

SvgAddToCollection.defaultProps = {
  width: 24,
  height: 24
};
export default SvgAddToCollection;
