import Icon from "icons/Icon";
import React, { ReactNode, useState } from "react";
import { Trans } from "react-i18next";
import { Box, BoxProps, Flex } from "rebass";
import { Button } from "..";
import OutsideClickWrapper from "app/shared/wrapper/OutsideClickWrapper";

interface Props extends BoxProps {
  titlei18n: string;
  children: ReactNode;
  popUpOpen?: boolean;
  noBorder?: boolean;
  icon?: string;
  titleFont?: number | number[] | string | string[];
  hideDropdownIndicator?: boolean;
}

export const DropdownButton: React.FC<Props> = ({
  titlei18n,
  children,
  popUpOpen,
  noBorder,
  icon,
  titleFont,
  hideDropdownIndicator,
  ...rest
}) => {
  const [collapsed, setCollapsed] = useState(popUpOpen ? false : true);

  return (
    <Box {...(rest as any)}>
      <Box
        sx={{
          position: "relative"
        }}>
        <OutsideClickWrapper
          isOpen={!collapsed}
          callback={() => {
            if (!popUpOpen) {
              setCollapsed(true);
            }
          }}>
          <Button
            sx={{ border: noBorder ? 0 : "1px solid black" }}
            width="100%"
            bg={collapsed ? "white" : "black"}
            color={collapsed ? "black" : "white"}
            onClick={() => {
              setCollapsed(!collapsed);
            }}>
            <Flex justifyContent="center" alignItems="center">
              {icon ? (
                <Flex justifyContent="center" alignItems="center" width="100%">
                  <Flex
                    pr={2}
                    sx={{
                      "& svg path": {
                        fill: !collapsed ? "white" : "",
                        stroke: !collapsed ? "white" : ""
                      }
                    }}>
                    <Icon name={icon} size={20} />
                  </Flex>
                  <Flex>
                    <Flex width="100%" fontSize={titleFont ?? [3, 4]}>
                      <Trans i18nKey={titlei18n} />
                    </Flex>
                  </Flex>
                </Flex>
              ) : (
                <Flex
                  width="100%"
                  justifyContent="center"
                  fontSize={titleFont ?? [3, 4]}>
                  <Trans i18nKey={titlei18n} />
                </Flex>
              )}
              {!hideDropdownIndicator && (
                <Flex
                  justifyContent="flex-end"
                  width="0%"
                  sx={{ "& path": { stroke: collapsed ? "black" : "white" } }}>
                  <Icon name={collapsed ? "ArrowDown" : "ArrowUp"} size={18} />
                </Flex>
              )}
            </Flex>
          </Button>
          {!collapsed && (
            <Box
              width="100%"
              backgroundColor="white"
              sx={{
                position: "absolute",
                zIndex: 2
              }}>
              {children}
            </Box>
          )}
        </OutsideClickWrapper>
      </Box>
    </Box>
  );
};
