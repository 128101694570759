import { SearchProvider, WithSearch } from "@elastic/react-search-ui";
import AppSearchAPIConnector from "@elastic/search-ui-app-search-connector";
import { MultiSelect } from "app/shared";
import { SelectOption } from "app/shared/multiSelect/models";
import environment from "configurations";
import React, { ReactNode } from "react";
import { TOptions } from "i18next";
import { SearchFilter } from "../models";
import i18next from "i18next";
import uniqBy from "lodash.uniqby";

interface Props {
  filters: SearchFilter[];
  resultFields: any;
  isMulti?: boolean;
  labelI18n?: string;
  infoI18n?: string;
  errorI18nOptions?: TOptions;
  errorI18n?: string;
  defaultValues?: SelectOption[]; // default selected values
  disabled?: boolean;
  placeholder?: string;
  searchOnInitialLoad?: boolean;
  mapResults: (results: any[]) => any[];
  onChange: (options: SelectOption[]) => void;
  noOptions?: string | ReactNode;
  fullBorder?: boolean;
  hideLabels?: boolean;
  sortField?: string;
  sortDirection?: "asc" | "desc";
}

interface WithSearchProps {
  setSearchTerm: (searchTerms: string, options: any) => void;
  results: any[];
}

export const MultiSelectSearch: React.FC<Props> = ({
  filters,
  resultFields,
  isMulti,
  defaultValues,
  errorI18n,
  errorI18nOptions,
  labelI18n,
  infoI18n,
  disabled,
  placeholder,
  searchOnInitialLoad,
  mapResults,
  onChange,
  fullBorder,
  noOptions,
  hideLabels,
  sortField,
  sortDirection
}) => (
  <SearchProvider
    config={{
      apiConnector: new AppSearchAPIConnector(environment.search),
      alwaysSearchOnInitialLoad: searchOnInitialLoad,
      trackUrlState: false,
      searchQuery: {
        filters: [...filters],
        result_fields: { ...resultFields },
        sort:
          sortField && sortDirection
            ? { [sortField]: sortDirection }
            : undefined
      }
    }}>
    <WithSearch
      mapContextToProps={({ setSearchTerm, results }: WithSearchProps) => ({
        setSearchTerm,
        results
      })}>
      {({ setSearchTerm, results }: WithSearchProps) => (
        <MultiSelect
          fullBorder={fullBorder}
          isMulti={isMulti}
          hideLabels={hideLabels}
          options={uniqBy(
            mapResults(results).concat(defaultValues ?? []), // filter duplicated values that defaultValues can add
            "value"
          )}
          onChange={onChange}
          defaultValues={defaultValues?.map(it => it.value)}
          onInputChange={value => {
            setSearchTerm(value, { debounce: 200 });
          }}
          errorI18n={errorI18n}
          errorI18nOptions={errorI18nOptions}
          labelI18n={labelI18n}
          infoI18n={infoI18n}
          placeholder={placeholder}
          disabled={disabled}
          noOptions={noOptions}
        />
      )}
    </WithSearch>
  </SearchProvider>
);

MultiSelectSearch.defaultProps = {
  searchOnInitialLoad: true,
  placeholder: i18next.t("search.shared.multiSelectSearch.placeholder")
};
