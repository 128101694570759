import axios from "network";
import { Collection, Privacy, ListIntUpdate } from "models";
import { CollectionInfo } from "../models";

export const getCollection = async (
  collectionId: number
): Promise<Collection> => {
  return axios
    .get(`/collections/${collectionId}`)
    .then(response => response.data);
};

export const createCollection = async (
  privacy: Privacy,
  title: string,
  description: string,
  shareWithLink: boolean,
  city?: string,
  country?: string
): Promise<Collection> => {
  return axios
    .post(`/collections`, {
      privacy,
      title,
      description,
      shareWithLink,
      city,
      country
    })
    .then(response => response.data);
};

export const updateCollection = async (
  collectionId: number,
  privacy?: Privacy,
  title?: string,
  description?: string,
  shareWithLink?: boolean,
  city?: string,
  country?: string,
  documents?: ListIntUpdate
): Promise<Collection> => {
  return axios
    .put(`/collections/${collectionId}`, {
      privacy,
      title,
      description,
      shareWithLink,
      city,
      country,
      documents
    })
    .then(response => response.data);
};

export const deleteCollections = async (collections: number[]) => {
  return axios.delete(`/collections`, {
    data: { collections } // axios does not support body in delete requests
  });
};

/**
 * Relations
 */
export const getCollectionInfo = async (
  collectionId: number
): Promise<CollectionInfo> => {
  return axios
    .get(`/relations/collections/${collectionId}/info`)
    .then(response => response.data);
};

export const likeCollection = async (collectionId: number) => {
  return axios.put(`/relations/collections/${collectionId}/like`);
};

export const shareCollection = async (collectionId: number) => {
  return axios.put(`/relations/collections/${collectionId}/share`);
};

export const unlikeCollection = async (collectionId: number) => {
  return axios.delete(`/relations/collections/${collectionId}/like`);
};
