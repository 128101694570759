import React, { useEffect, useState } from "react";
import { Box, BoxProps, Flex } from "rebass";
import { Icon, Tooltip } from "..";
import { useMediaQueryContext } from "../../../styles/context";

interface Props extends BoxProps {
  onShowInfoChange?: (showInfo: boolean) => void;
  defaultInfo?: boolean;
  customPy?: number | number[] | string | string[];
}

export const ShowHideInfoButton: React.FC<Props> = ({
  onShowInfoChange,
  defaultInfo,
  customPy,
  ...rest
}) => {
  const { isSmallMedium } = useMediaQueryContext();
  const [showInfo, setShowInfo] = useState<boolean | undefined>(
    defaultInfo ?? (isSmallMedium ? true : false)
  );

  useEffect(() => {
    if (showInfo !== undefined) {
      onShowInfoChange?.(showInfo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showInfo]);

  return (
    <Box {...(rest as any)}>
      <Tooltip
        width="100%"
        variant={showInfo === true ? "blue" : undefined}
        py={customPy ?? undefined}
        onClick={() => setShowInfo(!showInfo)}
        mode="button"
        i18nKey={
          showInfo
            ? "showHideInfoButton.hideInfoButton"
            : "showHideInfoButton.showInfoButton"
        }>
        <Flex width="100%" height="100%" justifyContent="center">
          <Icon name="EyeOpen" size={22} />
        </Flex>
      </Tooltip>
    </Box>
  );
};
