import React from "react";
import { Box } from "rebass";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { compose } from "recompose";
import { TransactionItemLicense } from "marketplace";
import { LicenseStandard } from "./LicenseStandard";
import { LicenseCreativeCommons } from "./LicenseCreativeCommons";
import { LicenseType } from "marketplace";

interface Params {
  type: LicenseType;
}

type Props = RouteComponentProps<Params>;

const LicenseExampleBase: React.FC<Props> = ({ match }) => {
  const licenseType = match.params.type;
  const license: TransactionItemLicense = {
    transactionItem: {
      id: 1,
      createdAt: new Date().toISOString(),
      status: "completed",
      transactionId: 1,
      documentId: 0,
      documentType: "image",
      license: "royaltyfree",
      seller: "-",
      buyer: "-",
      price: {
        value: 100.0,
        precision: 2,
        currency: "EUR"
      },
      thumbnail: ""
    },
    buyer: {
      id: "-",
      email: "-",
      name: "Buyer",
      nationality: "-",
      nationalCardNumber: "-",
      vat: "-",
      address: "Street",
      city: "City",
      postalCode: "Code",
      country: "Country"
    },
    seller: {
      id: "-",
      email: "-",
      name: "Seller",
      nationality: "-",
      nationalCardNumber: "-",
      vat: "-",
      address: "Street",
      city: "City",
      postalCode: "Code",
      country: "Country"
    }
  };

  return (
    <Box px={3} pt={7}>
      {licenseType === "royaltyfree" && <LicenseStandard license={license} />}
      {licenseType === "creativecommons" && (
        <LicenseCreativeCommons license={license} />
      )}
    </Box>
  );
};

export const LicenseExample = compose<Props, any>(withRouter)(
  LicenseExampleBase
);
