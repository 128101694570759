import React from "react";
import Svg, { SvgProps } from "primitives/Svg";

const SvgFDown = ({ width, height, size, color }: SvgProps) => (
  <Svg
    width={size || width}
    height={size || height}
    viewBox="0 0 37 14"
    color={color}>
    <path d="M17.25 12.923V.753.717c0-.016-.009-.032-.013-.044a.176.176 0 00-.02-.06.226.226 0 00-.032-.051c-.012-.016-.024-.036-.04-.048-.015-.016-.031-.024-.047-.036a.255.255 0 00-.116-.048c-.016-.004-.032-.012-.048-.016H.628.594A.35.35 0 00.28.761v2.743c0 .179.136.326.312.346H7.043v9.081c.016.176.168.311.347.311h2.742a.35.35 0 00.348-.31V3.85h3.32v9.081c.016.176.164.311.348.311h2.746a.35.35 0 00.347-.31l.008-.009z" />
    <path d="M36.222 12.923V.753.717c0-.016-.008-.032-.012-.044a.323.323 0 00-.016-.06.226.226 0 00-.032-.051c-.012-.016-.024-.036-.036-.048-.016-.016-.032-.024-.048-.036a.254.254 0 00-.056-.032c-.02-.008-.04-.012-.06-.016-.016-.004-.032-.012-.048-.016H19.605h-.036a.35.35 0 00-.311.347v2.743c0 .179.136.326.311.346h6.451v9.081c.015.176.167.311.347.311h2.746a.35.35 0 00.347-.31V3.85h3.321v9.081c.016.176.164.311.347.311h2.747a.35.35 0 00.347-.31v-.009z" />
  </Svg>
);

SvgFDown.defaultProps = {
  width: 24,
  height: 24
};
export default SvgFDown;
