/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { PureComponent } from "react";
import { ChangeEventValue, Bounds } from "google-map-react";
import { Box } from "rebass";
import { getRaw } from "../utils";
import { withSearch } from "@elastic/react-search-ui";
import { ClusterPoint } from "./ClusterPoint";
import supercluster from "points-cluster";
import { compose } from "recompose";
import { getDistanceInKm } from "../utils";
import debounce from "lodash.debounce";
import { PopupResults } from "./PopupResults";
import { Marker, Cluster } from "./models";
import { Coords } from "app/shared/maps/models";
import { Map } from "app/shared";
import { DEFAULT_CENTER, DEFAULT_BOUNDS } from "app/shared/maps/models";

interface PropsExternal {
  defaultCenter?: Coords;
}

interface WithSearchProps {
  results: any[];
  setFilter: (
    name: string,
    value: any,
    filterType: "all" | "any" | "none"
  ) => void;
}

interface Props extends PropsExternal, WithSearchProps {
  defaultZoom: number;
  defaultBounds: Bounds;
  minZoom: number;
  maxZoom: number;
  clusterRadius: number;
}

interface State {
  // Internal state
  clickedCluster?: Cluster;
  popupOpen: boolean;
  // Changed with map drag
  center: Coords;
  zoom: number;
  bounds: Bounds;
  markers: Marker[];
  clusters: Cluster[];
}

class MapSearchResultsBase extends PureComponent<Props, State> {
  static defaultProps = {
    defaultCenter: DEFAULT_CENTER,
    defaultZoom: 3,
    defaultBounds: DEFAULT_BOUNDS,
    minZoom: 3,
    maxZoom: 15,
    clusterRadius: 60
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      clickedCluster: undefined,
      popupOpen: false,
      center: props.defaultCenter!!,
      zoom: props.defaultZoom,
      bounds: props.defaultBounds,
      markers: [],
      clusters: []
    };

    // Construct markers
    this.parseResultsAndSetState(
      props.results,
      props.defaultBounds,
      props.defaultZoom
    );

    // Delays execution of map onChange to prevent search in all map drags
    this.onChange = debounce(this.onChange.bind(this), 500);
  }

  /**
   * Calculates if map results changed and redraws them if it did.
   */
  componentDidUpdate(prevProps: Props) {
    const prevResultsIds = prevProps.results
      .map(it => getRaw(it, "real_id"))
      .join(",");
    const pResultsIds = this.props.results
      .map(it => getRaw(it, "real_id"))
      .join(",");

    if (prevResultsIds !== pResultsIds) {
      this.parseResultsAndSetState(
        this.props.results,
        this.state.bounds,
        this.state.zoom
      );
    }
  }

  parseResultsAndSetState(results: any[], bounds: Bounds, zoom: number) {
    if (results.length) {
      const newMarkers = this.state.markers;
      const existingMarkerIds = newMarkers.map(it => it.id);

      // Add new results
      results.forEach(result => {
        const markerId = getRaw(result, "id") ?? "";

        if (!existingMarkerIds.includes(markerId)) {
          const className = getRaw(result, "class_name") ?? "document";
          const location = getRaw(result, "location")?.split(",");

          if (location) {
            newMarkers.push({
              id: markerId,
              className,
              result,
              lat: parseFloat(location[0]),
              lng: parseFloat(location[1])
            });
          }
        }
      });
      const clusters: Cluster[] = this.getClusters(newMarkers, zoom);

      this.setState({ markers: newMarkers, clusters });
    }
  }

  /**
   * TODO: Some performance optimizations can be made:
   * - Check if new bounds are outside old bounds, if it is no new search is needed.
   */
  onChange(value: ChangeEventValue) {
    const { markers } = this.state;

    this.setState(
      {
        center: value.center,
        zoom: value.zoom,
        bounds: value.bounds,
        clusters: this.getClusters(markers, value.zoom)
      },
      () => {
        const existingMarkerIds = markers.map(it => it.id);

        // Calculate distance from center to the closest bound point and do new search ignoring already received
        const topBoundPoint = {
          lat: value.bounds.nw.lat,
          lng: value.center.lng
        };
        const leftBoundPoint = {
          lat: value.center.lat,
          lng: value.bounds.nw.lng
        };
        const viewportDistance = Math.min(
          getDistanceInKm(value.center, topBoundPoint),
          getDistanceInKm(value.center, leftBoundPoint)
        );

        if (existingMarkerIds.length) {
          this.props.setFilter("id", existingMarkerIds, "none");
        }

        this.props.setFilter(
          "location",
          {
            center: `${value.center.lat},${value.center.lng}`,
            distance: viewportDistance,
            unit: "km"
          },
          "any"
        );
      }
    );
  }

  getClusters(markers: Marker[], zoom: number): Cluster[] {
    const { minZoom, maxZoom, clusterRadius } = this.props;

    return supercluster(markers, { minZoom, maxZoom, radius: clusterRadius })({
      bounds: DEFAULT_BOUNDS, // keep all points on map
      zoom
    }).map((cluster: any) => ({
      id: cluster.points[0].id,
      lat: cluster.wy,
      lng: cluster.wx,
      numMarkers: cluster.numPoints,
      markers: cluster.points
    }));
  }

  render() {
    const { defaultCenter, defaultZoom, minZoom, maxZoom } = this.props;
    const { clusters, clickedCluster, popupOpen } = this.state;

    return (
      <Box
        height="500px"
        sx={{
          position: "relative",
          border: "1px solid #000"
        }}>
        <Map
          center={defaultCenter}
          zoom={defaultZoom}
          minZoom={minZoom}
          maxZoom={maxZoom}
          onChildClick={(_, props) =>
            this.setState({ clickedCluster: props.cluster, popupOpen: true })
          }
          onChange={this.onChange}>
          {clusters.map(cluster => (
            <ClusterPoint
              key={cluster.id}
              lat={cluster.lat}
              lng={cluster.lng}
              cluster={cluster}>
              {cluster.numMarkers}
            </ClusterPoint>
          ))}
        </Map>
        {clickedCluster && (
          <PopupResults
            results={clickedCluster.markers.map(it => it.result)}
            open={popupOpen}
            close={() =>
              this.setState({ clickedCluster: undefined, popupOpen: false })
            }
          />
        )}
      </Box>
    );
  }
}

export const MapSearchResults = compose<Props, PropsExternal>(
  withSearch(({ results, setFilter }: WithSearchProps) => ({
    results,
    setFilter
  }))
)(MapSearchResultsBase);
