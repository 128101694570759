import { createSearchUrl } from "app/search/utils";
import { Input } from "app/shared";
import i18next from "i18next";
import React, { useState } from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Box, Flex, SxStyleProp, Text } from "rebass";
import { compose } from "recompose";
import { Action, Dispatch } from "redux";
import { FlexboxProps } from "styled-system";
import { useMediaQueryContext } from "styles/context";
import * as actions from "../../reducers/actions";
import { MobileSearchBoxBtn } from "./MobileSearchBoxBtn";

interface PropsExternal extends FlexboxProps {
  onClose?: () => void;
  sx?: SxStyleProp;
  navbar?: boolean;
}

interface Props extends PropsExternal, RouteComponentProps {
  searchTerms: string;
  searchClassName: string;
  setSearchTerms: (terms: string) => void;
  setSearchClassName: (className: string) => void;
}

const MobileSearchBoxBase: React.FC<Props> = ({
  searchTerms,
  setSearchTerms,
  searchClassName,
  history,
  onClose,
  navbar,
  sx
}) => {
  const { isSmall } = useMediaQueryContext();
  const [searched, setSearched] = useState(false);

  const inSearchPage = history.location.pathname.includes("/search");

  const doSearch = (terms: string) => {
    const searchUrl = createSearchUrl(
      searchClassName,
      [searchClassName],
      [],
      terms
    );
    history.push(searchUrl);
    if (terms && terms !== "") {
      setSearched(true);
    } else {
      onClose?.();
    }
  };

  const resetSearchTerms = () => {
    setSearchTerms("");
    setSearched(false);

    if (inSearchPage) {
      doSearch("");
    } else {
      onClose?.();
    }
  };

  return (
    <Box
      bg="fill.0"
      width="100%"
      sx={
        navbar
          ? {
              position: "absolute",
              top: ["50px", "50px", "50px", "80px", "80px"],
              zIndex: 9,
              right: 0,
              borderBottom: 1,
              borderTop: 1,
              borderColor: "black.0"
            }
          : { border: 1 }
      }>
      {!searched ? (
        <Flex
          alignItems="center"
          sx={{
            "& > :nth-of-type(1)": {
              paddingLeft: 1
            },
            "& > :nth-of-type(n+2)": {
              borderLeft: 1
            }
          }}>
          <Flex alignItems="center" sx={{ flexGrow: 1 }}>
            <Input
              name="searchTerms"
              type="text"
              placeholder={i18next.t("search.searchBox.placeholder")}
              variant="input.secondary"
              onChange={event => setSearchTerms(event.target.value)}
              value={searchTerms}
              onKeyUp={event => {
                if (event.key === "Enter") {
                  doSearch(searchTerms);
                }
              }}
              sx={{ flexGrow: 1 }}
            />
            <Flex flexDirection="row" pr={1}>
              <MobileSearchBoxBtn
                iconName="Search"
                iconSize={isSmall ? 16 : 24}
                px={[0, 1]}
                onClick={() => doSearch(searchTerms)}
              />
              {navbar && (
                <MobileSearchBoxBtn
                  iconName="Close"
                  iconSize={isSmall ? 16 : 24}
                  px={[0, 1]}
                  onClick={() => {
                    onClose?.();
                  }}
                />
              )}
            </Flex>
          </Flex>
        </Flex>
      ) : (
        <Flex bg="brand.0" alignItems="center" minHeight="46px">
          <MobileSearchBoxBtn
            iconName="ArrowLeft"
            iconSize={24}
            px={[1, 2]}
            onClick={() => setSearched(false)}
            bg="brand.0"
          />
          <Text
            fontSize={6}
            mx="auto"
            sx={{
              textTransform: "uppercase",
              color: "white.0",
              maxWidth: "177px",
              overflow: "hidden"
            }}>
            {searchTerms}
          </Text>
          <MobileSearchBoxBtn
            iconName="CloseWhite"
            iconSize={24}
            px={[1, 2]}
            onClick={() => resetSearchTerms()}
            bg="brand.0"
          />
        </Flex>
      )}
    </Box>
  );
};
MobileSearchBoxBase.defaultProps = {
  navbar: true
};

const mapStateToProps = (state: any) => ({
  searchTerms: state.search.terms,
  searchClassName: state.search.className
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  setSearchTerms: async (terms: string) =>
    dispatch(actions.setSearchTerms(terms)),
  setSearchClassName: async (className: string) =>
    dispatch(actions.setSearchClassName(className))
});

export const MobileSearchBox = compose<Props, PropsExternal>(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(MobileSearchBoxBase);
