import {
  Button,
  ThumbnailCardOne,
  Icon,
  FormattedDate,
  ElementOptions,
  LoadingFF
} from "app/shared";
import { Collection, DocumentThumbnail } from "models";
import React, { useState, BaseSyntheticEvent, ReactNode } from "react";
import { Box, BoxProps, Flex, Text } from "rebass";
import { CollectionShareButton } from "./collectionDetails/CollectionShareButton";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { compose } from "recompose";
import { BucketAddItemsButton } from "../buckets/BucketAddItemsButton";
import { getCommonTranslation } from "app/shared/translations/utils";
import { FollowButton } from "app/account/FollowButton";
import { AuthUserContext } from "authentication/session";
import { checkSameUser } from "authentication/utils";
import { UserRelationInfo } from "app/account/models";
import { useMediaQueryContext } from "styles/context";
import { UserPicture } from "app/account";
import { ReportUserButton } from "app/shared/elementOptions/ReportUserButton";
import { SearchProvider, WithSearch } from "@elastic/react-search-ui";
import AppSearchAPIConnector from "@elastic/search-ui-app-search-connector";
import environment from "configurations";
import { mapDocumentResults } from "app/search/utils/mapResults";
import { DonationPopUp } from "app/donations/DonationPopup";
import { LikeButton } from "app/documents/documentDetails/LikeButton";
import { CollectionInfo } from "./models";
import { SwiperView } from "app/shared/swiperView";
import { CollectionEditPopup } from ".";
import { RemoveBucketItemsButton } from "../buckets/RemoveBucketItemsButton";

const IMAGE_LINK_ID = "image-link";

interface PropsExternal extends BoxProps {
  collection: Collection;
  height?: string | string[];
  width?: string | string[];
  onClick?: () => void;
  variant?: string;
  showUserInfo?: boolean;
  whiteMode?: boolean;
  defaultHover?: boolean;
  showInfoBottom?: boolean;
  largeThumbnail?: boolean;
  customPb?: string | string[] | number | number[];
  titleFontSize?: number | number[];
  textBottomFontSize?: number | number[];
  ownerNameFontSize?: number | number[];
  showManageIcons?: boolean;
  bucketId?: number;
  bucketUid?: string;
  forceShowOnHover?: boolean;
}

interface Props extends PropsExternal, RouteComponentProps {
  onThumbnailChange: (increment: number) => void;
  initialSlide: number;
}

interface DocumentClickWrapperProps extends BoxProps {
  children?: ReactNode;
  href?: string;
  onClick?: (e: BaseSyntheticEvent) => void;
}

const DocumentClickWrapper: React.FC<DocumentClickWrapperProps> = ({
  children,
  href,
  onClick,
  ...rest
}) => (
  <Button
    display="block"
    variant="transparent"
    href={href}
    as={href ? undefined : "div"}
    onClick={event => {
      event.preventDefault();
      onClick?.(event);
    }}
    {...(rest as any)}>
    {children}
  </Button>
);

interface WithSearchProps {
  results: any[];
  setCurrent: (page: number) => void;
  current: number;
}

const CollectionViewBase: React.FC<Props> = props => {
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [index, setIndex] = useState(0);

  if (!isSearchActive) {
    return (
      <CollectionViewComponent
        initialSlide={index}
        {...(props as any)}
        onThumbnailChange={() => {
          setIsSearchActive(true);
          setIndex(1);
        }}
      />
    );
  } else {
    return (
      <SearchProvider
        config={{
          apiConnector: new AppSearchAPIConnector(environment.search),
          alwaysSearchOnInitialLoad: true,
          trackUrlState: false,
          initialState: {
            resultsPerPage: 1
          },
          searchQuery: {
            filters: [
              { field: "class_name", values: ["document"], type: "any" }, // this component should only show documents
              {
                field: "collections",
                values: [props.collection.id.toString()],
                type: "any"
              },
              {
                field: "real_id",
                values: [`${props.collection.documents?.[0]?.id}`],
                type: "none"
              }
            ],
            sort: { id: "desc" },
            result_fields: {
              real_id: { raw: {} },
              thumbnail: { raw: {} }
            }
          }
        }}>
        <WithSearch
          mapContextToProps={({
            results,
            setCurrent,
            current
          }: WithSearchProps) => ({
            results,
            setCurrent,
            current
          })}>
          {({ results, setCurrent, current }: WithSearchProps) => {
            const documents: DocumentThumbnail[] = mapDocumentResults(results);
            return (
              <CollectionViewComponent
                {...(props as any)}
                initialSlide={index}
                collection={
                  index === 0
                    ? props.collection
                    : {
                        ...props.collection,
                        documents: documents.map(it => ({
                          ...it,
                          thumbnail: props.largeThumbnail
                            ? it.thumbnail.replace("normal", "large")
                            : it.thumbnail
                        }))
                      }
                }
                onThumbnailChange={index => {
                  setIndex(index);
                  if (index > 0) {
                    setCurrent(index);
                  }
                }}
                defaultHover={true}
              />
            );
          }}
        </WithSearch>
      </SearchProvider>
    );
  }
};
const CollectionViewComponent: React.FC<Props> = ({
  collection,
  height,
  width,
  onClick,
  variant,
  history,
  showUserInfo,
  whiteMode,
  onThumbnailChange,
  defaultHover,
  showInfoBottom,
  initialSlide,
  customPb,
  titleFontSize,
  textBottomFontSize,
  ownerNameFontSize,
  largeThumbnail,
  showManageIcons,
  bucketId,
  bucketUid,
  forceShowOnHover,
  ...rest
}) => {
  const { isSmallMedium } = useMediaQueryContext();
  const [isHover, setHover] = useState(defaultHover ?? false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isOpenRemove, setIsOpenRemove] = useState(false);
  let documentLink = `/magazine/${collection.id}`;
  const documentLinkEdit = `/collections/${collection.id}/edit`;
  const [isBucketAddItemOpen, setIsBucketAddItemOpen] = useState(false);
  const [isShareOpen, setIsShareOpen] = useState(false);
  const [isBucketAdded, setIsBucketAdded] = useState(false);
  const [openDonationPopUp, setOpenDonationPopUp] = useState(false);
  const [openReportPopUp, setOpenReportPopUp] = useState(false);

  const [collectionInfo, setCollectionInfo] = useState<
    CollectionInfo | undefined
  >(undefined); // used to cache info for the next hover
  //const [currentIndex, setCurrentIndex] = useState(0);

  const ManageIcons: React.FC = () => {
    return (
      <>
        <AuthUserContext.Consumer>
          {({ authUser }) =>
            authUser &&
            bucketUid &&
            checkSameUser(bucketUid, authUser) &&
            isHover &&
            showManageIcons && (
              <>
                <Flex
                  width="100%"
                  pb={2}
                  pr={2}
                  alignItems="center"
                  justifyContent="flex-end"
                  flexWrap="wrap"
                  sx={{
                    position: "absolute",
                    bottom: 0,
                    zIndex: 1
                  }}>
                  {bucketId && (
                    <Button onClick={() => setIsOpenRemove(true)} p={1}>
                      <Icon name="Trash" color="red" size={16} />
                    </Button>
                  )}
                </Flex>
              </>
            )
          }
        </AuthUserContext.Consumer>
      </>
    );
  };

  const UserInfoBox: React.FC = () => {
    const [info, setInfo] = useState<UserRelationInfo | undefined>(undefined);

    return (
      <>
        {(showUserInfo || (forceShowOnHover ? isHover : showUserInfo)) && (
          <Flex>
            <Flex>
              <Button
                href={`/users/${collection.owner.id}`}
                width="100%"
                variant="transparent"
                bg="white"
                pr={2}
                sx={{ "&:hover": { opacity: "1 !important" } }}>
                {collection.owner.picture ? (
                  <UserPicture
                    picture={collection.owner?.picture}
                    height="35px"
                    verifiedIconSize={16}
                    userVerified="verified"
                  />
                ) : (
                  <UserPicture
                    picture={undefined}
                    height="35px"
                    verifiedIconSize={16}
                    userVerified="verified"
                  />
                )}
              </Button>
            </Flex>
            {collection.owner.name && (
              <Flex bg="white" width="100%" alignItems="center">
                <Button href={`/users/${collection.owner.id}`}>
                  <Flex width="100%">
                    <Text
                      pl={3}
                      color="black"
                      variant="grotTextCaps"
                      letterSpacing={1.2}
                      fontSize={ownerNameFontSize ?? 1}>
                      {collection.owner.name}
                    </Text>
                  </Flex>
                </Button>
              </Flex>
            )}
            <AuthUserContext.Consumer>
              {({ authUser }) => {
                const disabled =
                  !authUser ||
                  (authUser && checkSameUser(collection.owner.id, authUser)); //disable follow button if is the story of the user logged
                return (
                  !disabled && (
                    <Flex
                      justifyContent="flex-end"
                      alignItems="center"
                      bg="white"
                      pr={2}>
                      <ElementOptions
                        closeMenuOnClick={false}
                        options={[
                          () => (
                            <>
                              <FollowButton
                                uid={collection.owner.id}
                                width="100%"
                                info={info}
                                onInfoChange={info => setInfo(info)}
                                variant="transparent"
                                color="black"
                                py={2}
                              />
                              <DonationPopUp
                                width="100%"
                                onlyTrigger={true}
                                onClickTrigger={() =>
                                  setOpenDonationPopUp(true)
                                }
                                uid={collection.owner.id}
                                sx={{ borderLeft: 0, borderRight: 0 }}
                              />
                              <ReportUserButton
                                userId={collection.owner.id}
                                onlyTrigger={true}
                                onClickTrigger={() => setOpenReportPopUp(true)}
                                sx={{
                                  border: 0,
                                  textAlign: "center",
                                  color: "red"
                                }}
                              />
                            </>
                          )
                        ]}
                        elementId={20}
                        iconName="MoreOptions"
                        iconSize={18}
                        optionsWidth={["139px", "179px"]}
                        backgroundAlwaysActive={true}
                        sx={{
                          borderRadius: 0,
                          bg: "transparent",
                          borderColor: "black.0",
                          borderRight: "none",
                          "& svg path": { fill: "transparent" }
                        }}
                        popupSx={{
                          position: "relative",
                          top: "26px",
                          color: "black",
                          zIndex: 1,
                          left: ["-130px", "-170px"]
                        }}
                      />
                    </Flex>
                  )
                );
              }}
            </AuthUserContext.Consumer>
          </Flex>
        )}
      </>
    );
  };

  const CollectionInfoBox: React.FC = () => (
    <>
      {((forceShowOnHover ? isHover : showInfoBottom) || showInfoBottom) && (
        <Box
          width="100%"
          sx={{
            position: "absolute",
            bottom: 0,
            zIndex: 0,
            userSelect: "none"
          }}
          draggable={false}>
          <Flex
            flexDirection="column"
            maxWidth={
              collection.documents && collection.documents.length > 1
                ? "95%"
                : "100%"
            }
            flexWrap="wrap">
            <Flex pl={2} maxWidth="90%" pb={1} sx={{ alignSelf: "flex-start" }}>
              <UserInfoBox />
            </Flex>
            <Flex
              alignSelf="flex-start"
              maxWidth="80%"
              pr={2}
              py={2}
              pl={2}
              ml={2}
              bg="black">
              <Flex alignItems="center" width="100%">
                <Text
                  fontSize={titleFontSize ?? [2, 3, 6]}
                  variant="grotText"
                  sx={{
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap"
                  }}
                  color="white">
                  {collection.title}
                </Text>
              </Flex>
              <AuthUserContext.Consumer>
                {({ authUser }) => {
                  return authUser &&
                    checkSameUser(collection.owner.id, authUser) ? (
                    <>
                      <Flex
                        justifyContent="flex-end"
                        alignItems="center"
                        px={2}>
                        <Button
                          variant="transparent"
                          onClick={() => setIsEditMode(true)}
                          sx={{
                            "& svg path": {
                              fill: "white"
                            }
                          }}>
                          <Icon name="Pencil2" size={20} />
                        </Button>
                      </Flex>
                    </>
                  ) : (
                    <>
                      <Flex
                        justifyContent="flex-end"
                        alignItems="center"
                        pl={2}
                        sx={{
                          "& svg path": {
                            fill: "white",
                            strokeWidth: "5px"
                          }
                        }}>
                        <Icon name="ArrowRight" size={20} />
                      </Flex>
                    </>
                  );
                }}
              </AuthUserContext.Consumer>
            </Flex>
            <Flex mt={1} ml={2} mb={5} px={2} alignSelf="flex-start" bg="blue">
              <Flex
                pr={3}
                py={2}
                alignItems="center"
                height="24px"
                mr={collection.city || collection.country ? 0 : 2}>
                <Text
                  variant="grotText"
                  fontSize={textBottomFontSize ?? [1, 2]}
                  color="white">
                  <FormattedDate format="number" date={collection.createdAt} />
                </Text>
              </Flex>
              {(collection.city || collection.country) && (
                <Flex
                  pr={3}
                  py={2}
                  alignItems="center"
                  height="24px"
                  sx={{
                    textTransform: "none"
                  }}>
                  <Text
                    variant="grotText"
                    fontSize={textBottomFontSize ?? [1, 2]}
                    color="white">
                    {[
                      collection.city,
                      collection.country &&
                        getCommonTranslation(collection.country)
                    ]
                      .filter(it => it)
                      .join(", ")}
                  </Text>
                </Flex>
              )}
              <Flex py={2} alignItems="center" height="24px">
                <Icon name="Photos" size={22} color={"white"} />
                <Text
                  variant="grotText"
                  fontSize={textBottomFontSize ?? [1, 2]}
                  color="white"
                  ml={2}>
                  {collection.totalDocuments}
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </Box>
      )}
    </>
  );

  return (
    <>
      <Box
        pb={customPb ?? 0}
        width={width}
        sx={{ position: "relative" }}
        onMouseEnter={() => (isHover === false ? setHover(true) : null)}
        onMouseLeave={() => (isHover === true ? setHover(false) : null)}>
        <AuthUserContext.Consumer>
          {({ authUser }) => {
            documentLink =
              authUser && checkSameUser(collection.owner.id, authUser)
                ? documentLinkEdit
                : documentLink;
            return (
              <DocumentClickWrapper
                href={documentLink}
                onClick={e => {
                  const eventTarget = e.target.id || e.target.parentNode.id;
                  if (eventTarget.startsWith(IMAGE_LINK_ID)) {
                    history.push(documentLink);
                  }
                }}
                width="100%"
                height={height}
                sx={{
                  position: "relative",
                  zIndex: 0,
                  transition: "0.5s",
                  "&:hover": {
                    opacity: "initial !important"
                  }
                }}
                {...(rest as any)}>
                <Flex flexDirection="column" height="100%">
                  <Box
                    sx={{
                      flexGrow: 1,
                      position: "relative"
                    }}
                    height="80%">
                    <SwiperView
                      height={height}
                      slides={Array.from({
                        length: collection.totalDocuments
                      }).map(index => (
                        <Box width="100%" height={height}>
                          {collection.documents?.[0]?.thumbnail && (
                            <ThumbnailCardOne
                              thumbnail={
                                largeThumbnail
                                  ? collection.documents?.[0]?.thumbnail.replace(
                                      "normal",
                                      "large"
                                    )
                                  : collection.documents?.[0]?.thumbnail
                              }
                            />
                          )}
                        </Box>
                      ))}
                      onClick={() => history.push(documentLink)}
                      onSlideChange={swiper =>
                        onThumbnailChange(swiper.realIndex)
                      }
                      initialSlide={initialSlide}
                      navigation={
                        isHover &&
                        collection.totalDocuments > 1 &&
                        !isSmallMedium
                          ? true
                          : false
                      }
                    />
                    <Box
                      width="100%"
                      sx={{ position: "absolute", zIndex: -1, bottom: "40%" }}>
                      <LoadingFF showIcon={true} hideBar={true} />
                    </Box>
                    <Flex
                      id={`${IMAGE_LINK_ID}-1`} //to work href only in the thumbnail area
                      width="100%"
                      height="100%"
                      sx={{
                        position: "absolute",
                        top: 0
                      }}></Flex>
                    <ManageIcons />
                  </Box>
                </Flex>
                <CollectionInfoBox />
              </DocumentClickWrapper>
            );
          }}
        </AuthUserContext.Consumer>
        {(showInfoBottom || (forceShowOnHover ? isHover : showInfoBottom)) && (
          <Box
            mr={2}
            sx={{ position: "absolute", top: 0, right: 0, zIndex: 1 }}
            onMouseEnter={() =>
              isHover === false ? setHover(true) : setHover(true)
            }>
            <Box>
              <Flex flexDirection="column" justifyContent="center">
                <AuthUserContext.Consumer>
                  {({ authUser }) =>
                    authUser &&
                    collection.owner &&
                    !checkSameUser(collection.owner?.id, authUser) && (
                      <Flex
                        bg="white"
                        sx={{ border: 1, borderColor: "blue" }}
                        mt={2}
                        p={1}>
                        <LikeButton
                          name="Like"
                          variant="icon"
                          display="block"
                          mode="collection"
                          documentId={collection.id}
                          collectionInfo={collectionInfo}
                          onInfoChange={info =>
                            setCollectionInfo(info as CollectionInfo)
                          }
                        />
                      </Flex>
                    )
                  }
                </AuthUserContext.Consumer>
                <Flex mt={2}>
                  <Button
                    bg="white"
                    onClick={() => setIsShareOpen(true)}
                    p={1}
                    variant="transparent"
                    sx={{
                      border: 1,
                      borderColor: "blue",
                      "&:hover": { opacity: "1 !important" },
                      "& svg path": { fill: "transparent", stroke: "blue" }
                    }}>
                    <Icon name="Share2" size={22} />
                  </Button>
                </Flex>
                <AuthUserContext.Consumer>
                  {({ authUser }) =>
                    authUser && (
                      <Flex mt={2}>
                        <Button
                          bg="white"
                          onClick={() => setIsBucketAddItemOpen(true)}
                          p={1}
                          variant="transparent"
                          sx={{
                            border: 1,
                            borderColor: "blue",
                            "&:hover": { opacity: "1 !important" }
                          }}>
                          <Icon
                            name={
                              isBucketAdded
                                ? "AddToCollectionFilled"
                                : "AddToCollection"
                            }
                            size={22}
                            color="blue"
                          />
                        </Button>
                      </Flex>
                    )
                  }
                </AuthUserContext.Consumer>
              </Flex>
            </Box>
          </Box>
        )}
      </Box>
      <AuthUserContext.Consumer>
        {({ authUser }) =>
          authUser &&
          bucketUid &&
          checkSameUser(bucketUid, authUser) &&
          bucketId &&
          showManageIcons && (
            <>
              {bucketId ? (
                <RemoveBucketItemsButton
                  bucketItemIds={[{ type: "collection", id: collection.id }]}
                  bucketId={bucketId}
                  hideTrigger={true}
                  open={isOpenRemove}
                  onOpen={() => setIsOpenRemove(true)}
                  onClose={() => setIsOpenRemove(false)}
                />
              ) : (
                <></>
              )}
            </>
          )
        }
      </AuthUserContext.Consumer>
      <CollectionShareButton
        hideTrigger={true}
        open={isShareOpen}
        onClose={() => setIsShareOpen(false)}
        name="Share"
        variant="transparent"
        display="block"
        collection={collection}
      />
      <AuthUserContext.Consumer>
        {({ authUser }) =>
          authUser && (
            <>
              <BucketAddItemsButton
                onAdd={added => setIsBucketAdded(added)}
                open={isBucketAddItemOpen}
                onClose={() => setIsBucketAddItemOpen(false)}
                hideTrigger={true}
                bucketItemIds={[{ type: "collection", id: collection.id }]}
                variant="icon"
              />
              <DonationPopUp
                width="100%"
                hideTrigger={true}
                onClose={() => setOpenDonationPopUp(false)}
                open={openDonationPopUp}
                uid={collection.owner.id}
              />
              <ReportUserButton
                userId={collection.owner.id}
                hideTrigger={true}
                onClose={() => setOpenReportPopUp(false)}
                open={openReportPopUp}
              />
            </>
          )
        }
      </AuthUserContext.Consumer>
      <CollectionEditPopup
        hideTrigger={true}
        collection={collection}
        open={isEditMode}
        onClose={() => setIsEditMode(false)}
      />
    </>
  );
};
CollectionViewBase.defaultProps = {
  height: "300px",
  showUserInfo: true,
  whiteMode: true,
  showInfoBottom: true,
  forceShowOnHover: true
};
export const CollectionView = compose<Props, PropsExternal>(withRouter)(
  CollectionViewBase
);
