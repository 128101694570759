import React, { Component } from "react";
import { FormFieldSet } from "app/shared/forms";
import i18next from "i18next";
import { BusinessInfoForm } from "app/account/onboarding";
import { RouteComponentProps, withRouter } from "react-router-dom";
import Firebase, { withFirebase } from "authentication/firebase";
import { compose } from "recompose";
import { withAuthorization } from "authentication/session";
import { getUser } from "app/account/services";
import { User } from "models";

interface Props extends RouteComponentProps {
  firebase: Firebase;
}

interface State {
  user?: User;
}

export class PayoutInfoBase extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      user: undefined
    };
  }

  componentDidMount() {
    const { firebase } = this.props;

    if (firebase.isLogged()) {
      firebase.getIdToken(true).then(() => {
        const uid = firebase.getCurrentUser().uid;
        getUser(uid).then(user => {
          this.setState({ user });
        });
      });
    }
  }

  render() {
    const { user } = this.state;
    return (
      <FormFieldSet
        title={i18next.t("account.settings.billing.payoutInfo.title")}
        description={i18next.t(
          "account.settings.billing.payoutInfo.description"
        )}
        sx={{ border: 1, mt: 3 }}>
        <BusinessInfoForm
          showBilling={false}
          showPayout={true}
          user={user}
          submiti18n="account.settings.billing.payoutInfo.submit"
        />
      </FormFieldSet>
    );
  }
}

export const PayoutInfo = compose<Props, any>(
  withRouter,
  withFirebase,
  withAuthorization()
)(PayoutInfoBase);
