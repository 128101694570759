import { SearchProvider, WithSearch } from "@elastic/react-search-ui";
import AppSearchAPIConnector from "@elastic/search-ui-app-search-connector";
import { DocumentsView } from "app/documents";
import { DocumentAddPhotoButton } from "app/documents/DocumentAddPhotoButton";
import { ResultField, SearchFilter } from "app/search/models";
import { CachedPagination, PageEmptyState } from "app/shared";
import { AuthUserContext } from "authentication/session";
import environment from "configurations";
import mergeWith from "lodash.mergewith";
import { DocumentThumbnail } from "models";
import React, { Component, ReactNode } from "react";
import { Trans } from "react-i18next";

interface Props<T> {
  filters: SearchFilter[];
  resultFields?: ResultField[];
  sortField: string;
  sortDirection: "asc" | "desc";
  resultsPerPage: number;
  maxResults?: number;
  view?: (
    viewable: T[],
    setPage: (mode: "next" | "previous") => void
  ) => React.ReactElement;
  scrollListenerEnabled?: boolean;
  showLoadButtons?: boolean;
  mapResults: (results: any[]) => T[];
  noResultsView?: ReactNode;
  showUserInfo?: boolean;
  showManageIcons?: boolean;
  collectionId?: number;
}

interface WithSearchProps {
  wasSearched: boolean;
  results: any[];
  totalPages: number;
  setCurrent: (page: number) => void;
  current: number;
  isLoading: boolean;
}

/**
 * When maxResults > resultsPerPage results are wrap with Pagination that will search for more results in page bottom scroll.
 */
export class PhotosListSearch<T extends DocumentThumbnail> extends Component<
  Props<T>
> {
  static defaultProps = {
    filters: [],
    resultFields: [],
    resultsPerPage: 20,
    sortField: "id",
    sortDirection: "desc"
  };
  /**
   * Use view from props or use the default.
   */
  view(viewable: T[], setPage: (mode: "next" | "previous") => void) {
    const { view } = this.props;

    if (view) {
      return view(viewable, setPage);
    } else {
      return (
        <DocumentsView
          showUserInfo={this.props.showUserInfo}
          showManageIcons={this.props.showManageIcons}
          collectionId={this.props.collectionId}
          documents={viewable}
        />
      );
    }
  }

  render() {
    const {
      filters,
      maxResults,
      resultsPerPage,
      scrollListenerEnabled,
      showLoadButtons,
      sortField,
      sortDirection,
      mapResults,
      resultFields,
      noResultsView
    } = this.props;
    return (
      <SearchProvider
        config={{
          apiConnector: new AppSearchAPIConnector(environment.search),
          alwaysSearchOnInitialLoad: true,
          trackUrlState: false,
          initialState: {
            resultsPerPage
          },
          searchQuery: {
            filters: [
              { field: "class_name", values: ["document"], type: "any" }, // this component should only show documents
              ...filters
            ],
            sort: { [sortField]: sortDirection },
            result_fields: mergeWith(
              {
                real_id: { raw: {} },
                thumbnail: { raw: {} },
                width: { raw: {} },
                height: { raw: {} },
                owner: { raw: {} },
                owner_picture: { raw: {} },
                uid: { raw: {} },
                city: { raw: {} },
                country: { raw: {} },
                taken_at: { raw: {} },
                created_at: { raw: {} },
                labels: { raw: {} },
                translations: { raw: {} }
              },
              ...resultFields
            )
          }
        }}>
        <WithSearch
          mapContextToProps={({
            wasSearched,
            results,
            totalPages,
            setCurrent,
            current,
            isLoading
          }: WithSearchProps) => ({
            wasSearched,
            results,
            totalPages,
            setCurrent,
            current,
            isLoading
          })}>
          {({
            wasSearched,
            results,
            totalPages,
            setCurrent,
            current,
            isLoading
          }: WithSearchProps) => {
            if (results.length) {
              const mappedResults: T[] = mapResults(results);

              // Wrap results around Pagination if maxResults > resultsPerPage
              if (maxResults === undefined || maxResults > resultsPerPage) {
                return (
                  <CachedPagination<T>
                    disabled={isLoading}
                    scrollListenerEnabled={scrollListenerEnabled}
                    showLoadButtons={showLoadButtons}
                    firstPageNumber={1}
                    results={mappedResults}
                    page={current}
                    totalPages={totalPages}
                    setPage={setCurrent}>
                    {(viewable, setPage) => this.view(viewable, setPage)}
                  </CachedPagination>
                );
              } else {
                return this.view(mappedResults, (mode: "next" | "previous") => {
                  if (mode === "next") {
                    setCurrent(current + 1);
                  } else {
                    setCurrent(current - 1);
                  }
                });
              }
            } else {
              return noResultsView ? (
                noResultsView
              ) : (
                <AuthUserContext.Consumer>
                  {({ authUser }) => {
                    const isLoggedUserProfile =
                      authUser &&
                      filters.filter(
                        it =>
                          it.field === "uid" && it.values?.[0] === authUser.uid
                      ).length > 0;

                    return (
                      <PageEmptyState
                        loading={!wasSearched || isLoading}
                        header={
                          <Trans i18nKey="search.documents.photosListSearch.noPhotos" />
                        }
                        description={
                          isLoggedUserProfile && (
                            <DocumentAddPhotoButton maxWidth="300px" />
                          )
                        }
                      />
                    );
                  }}
                </AuthUserContext.Consumer>
              );
            }
          }}
        </WithSearch>
      </SearchProvider>
    );
  }
}
