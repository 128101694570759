import React from "react";
import Svg, { SvgProps } from "primitives/Svg";

const SvgShare = ({ width, height, size, color }: SvgProps) => (
  <Svg
    width={size || width}
    height={size || height}
    viewBox="0 0 24 24"
    color={color}>
    <path d="M18.5 15.01c-1.19 0-2.24.6-2.87 1.5L8.81 13.1c.11-.34.19-.7.19-1.08 0-.39-.08-.75-.19-1.1l6.82-3.42c.63.91 1.68 1.5 2.87 1.5C20.43 9 22 7.43 22 5.5S20.43 2 18.5 2 15 3.57 15 5.5c0 .39.08.75.19 1.1l-6.82 3.42c-.63-.91-1.68-1.5-2.87-1.5-1.93 0-3.49 1.56-3.5 3.49h1a2.505 2.505 0 012.5-2.49 2.5 2.5 0 010 5c-1.03 0-1.91-.62-2.29-1.51H2.16a3.483 3.483 0 006.22.99l6.81 3.41c-.12.35-.19.71-.19 1.1 0 1.93 1.57 3.5 3.5 3.5s3.5-1.57 3.5-3.5-1.57-3.5-3.5-3.5zm0-12a2.5 2.5 0 010 5 2.5 2.5 0 010-5zm0 18a2.5 2.5 0 010-5 2.5 2.5 0 010 5z" />
  </Svg>
);

SvgShare.defaultProps = {
  width: 24,
  height: 24
};
export default SvgShare;
