import React from "react";
import Svg, { SvgProps } from "primitives/Svg";

const SvgCopyright = ({ width, height, size, color }: SvgProps) => (
  <Svg
    width={size || width}
    height={size || height}
    viewBox="0 0 114 114"
    color={color}>
    <path d="M96.93 97.904C85.855 108.635 72.54 114 57.016 114c-15.524 0-29.533-5.492-40.545-16.475C5.49 86.54 0 73.033 0 57c0-16.033 5.932-30.52 17.796-41.535C28.934 5.145 41.996 0 57.016 0 72.035 0 86.17 5.555 97.309 16.696 108.447 27.837 114 41.282 114 57c0 15.718-5.711 29.826-17.102 40.935l.032-.031zM23.538 23.955C14.451 33.235 9.94 44.25 9.94 57c0 12.75 4.607 24.429 13.852 33.644 9.182 9.248 20.257 13.856 33.256 13.856 13 0 24.012-4.64 33.194-13.919C99.423 81.24 103.998 70.066 103.998 57c0-13.066-4.544-23.703-13.694-33.045-9.213-9.5-20.32-14.234-33.257-14.234-12.936 0-24.264 4.734-33.477 14.234h-.032zm51.778 61.45c-5.427 3.598-11.075 5.397-16.912 5.397-8.677 0-16.155-3.093-22.465-9.279-6.31-6.186-9.466-14.297-9.466-24.365 0-9.374 2.713-17.39 8.172-23.987 5.427-6.596 12.905-9.91 22.402-9.91 6.027 0 11.265 1.23 15.714 3.724 7.225 4.04 11.706 11.11 13.473 21.178l-14.546-.221c-.884-4.671-2.745-7.827-5.585-9.437-2.366-1.515-4.985-2.272-7.825-2.272-4.291 0-7.794 1.767-10.507 5.302-2.745 3.535-4.102 8.837-4.102 15.938 0 7.102 1.515 12.183 4.512 15.37 3.03 3.189 6.626 4.798 10.854 4.798 3.85 0 6.784-1.041 8.772-3.156 1.988-2.115 3.25-4.766 3.818-7.953h14.167c-1.546 8.995-5.049 15.307-10.476 18.905v-.032z" />
  </Svg>
);

SvgCopyright.defaultProps = {
  width: 24,
  height: 24
};
export default SvgCopyright;
