import { MultiSelectSearch } from "app/search/shared";
import { getRaw } from "app/search/utils";
import {
  Button,
  Input,
  MobileTriggerButton,
  Popup,
  Tabs,
  Toast,
  Icon,
  Label,
  Tooltip
} from "app/shared";
import { AuthUserContext } from "authentication/session";
import i18next from "i18next";
import debounce from "lodash.debounce";
import { Collection, Privacy } from "models";
import React, { Component } from "react";
import { Trans } from "react-i18next";
import { Box, BoxProps, Flex, Text } from "rebass";
import { createCollection, updateCollection } from "./services";
import { LocationInputSearch } from "../account/editMyInfo/LocationInputSearch";
import { getCommonTranslation } from "app/shared/translations/utils";

interface Props extends BoxProps {
  documentIds: number[];
  open?: boolean;
  variant?: string;
  forcePageReload?: boolean; // this will force page reload so that ES cached results are updated
  onClose?: () => void;
  onOpen?: () => void;
}

interface State {
  mode: string;
  disabled: boolean;
  collectionId?: number;
  existingCollectionTitle: string;
  newCollection: Collection;
}

export class CollectionAddPhotoButton extends Component<Props, State> {
  static defaultProps = {
    variant: "text",
    forcePageReload: false
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      mode: "existing",
      disabled: false,
      collectionId: undefined,
      existingCollectionTitle: "",
      newCollection: {
        id: -1,
        createdAt: new Date().toISOString(),
        privacy: "public",
        uid: "",
        owner: { id: "", name: "" },
        title: "",
        description: "",
        shareWithLink: false,
        city: undefined,
        country: undefined,
        totalDocuments: 0,
        documents: []
      }
    };
  }

  addExistingCollection(
    collectionId: number,
    collectionTitle: string,
    close: () => void
  ) {
    const { forcePageReload } = this.props;
    const { mode } = this.state;

    this.setState({ disabled: true }, async () => {
      const documentIds = [...new Set(this.props.documentIds)];
      updateCollection(
        collectionId,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        {
          mode: "append",
          list: documentIds
        }
      )
        .then(() => {
          if (mode === "existing") {
            Toast.success({
              title: {
                key: "collections.collectionAddPhotoButton.confirmationToast",
                options: {
                  number: documentIds.length,
                  title: collectionTitle
                }
              }
            });
          } else {
            Toast.success({
              title: {
                key:
                  "collections.collectionAddPhotoButton.confirmationToastCreated",
                options: {
                  number: documentIds.length,
                  title: collectionTitle
                }
              }
            });
          }

          close();
          this.setState({ disabled: false });

          if (forcePageReload) {
            // Waiting a few for backend re-index changes.
            // TODO: This can be replaced if we manually change affected elements locally.
            debounce(() => window.location.reload(), 1000)();
          }
        })
        .catch(error => {
          Toast.apiError(error);
          this.setState({ disabled: false });
        });
    });
  }

  createCollection(close: () => void) {
    const {
      title,
      description,
      shareWithLink,
      city,
      country,
      privacy
    } = this.state.newCollection;

    if (title && title.length > 0) {
      this.setState({ disabled: true }, async () => {
        createCollection(
          privacy,
          title,
          description,
          shareWithLink,
          city,
          country
        )
          .then(collection =>
            this.addExistingCollection(collection.id, title, close)
          )
          .catch(error => {
            Toast.apiError(error);
            this.setState({ disabled: false });
          });
      });
    }
  }

  handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { newCollection } = this.state;

    this.setState({
      newCollection: {
        ...newCollection,
        [event.target.name]: event.target.value
      }
    });
  };

  handleOnPrivacyChange = (privacy: Privacy, shareWithLink?: boolean) => {
    const { newCollection } = this.state;

    this.setState({
      newCollection: {
        ...newCollection,
        privacy: privacy,
        shareWithLink:
          shareWithLink !== undefined
            ? shareWithLink
            : (newCollection?.shareWithLink as boolean)
      }
    });
  };

  handleOnCityChange = (country: string, city?: string) => {
    const { newCollection } = this.state;

    this.setState({
      newCollection: {
        ...newCollection,
        city: city,
        country: country
      }
    });
  };

  render() {
    const {
      disabled,
      collectionId,
      existingCollectionTitle,
      mode,
      newCollection
    } = this.state;

    const { documentIds, open, variant, onOpen, onClose, ...rest } = this.props;
    const buttonEnabled = documentIds.length !== 0;
    const submitEnabled =
      mode === "existing"
        ? collectionId !== undefined
        : newCollection.title &&
          newCollection.title.length > 0 &&
          newCollection.description &&
          newCollection.description.length > 0 &&
          newCollection.country &&
          newCollection.country.length > 0;
    return (
      <AuthUserContext.Consumer>
        {({ authUser }) => (
          <Popup
            hideOverflow={true}
            trigger={
              variant === "mobile" ? (
                <Button variant="buttons.mobileButton">
                  <MobileTriggerButton
                    iconName="AddTo"
                    disabled={disabled || !buttonEnabled}
                    buttonNameI18n="collections.collectionAddPhotoButton.addToCollection"
                  />
                </Button>
              ) : (
                <Button
                  variant={variant === "icon" ? "icon" : "white"}
                  disabled={disabled || !buttonEnabled}
                  {...(rest as any)}>
                  {variant === "icon" && (
                    <Icon name="AddToCollection" size={22} />
                  )}
                  {variant === "text" && (
                    <Text fontSize={[1, 2, 3]}>
                      <Trans i18nKey="collections.collectionAddPhotoButton.addToCollection" />
                    </Text>
                  )}
                </Button>
              )
            }
            heading={i18next.t(
              "collections.collectionAddPhotoButton.chooseCollection"
            )}
            onClose={onClose}
            onOpen={onOpen}
            open={open}
            cancelDisabled={disabled}
            submit={close => {
              if (mode === "existing") {
                this.addExistingCollection(
                  collectionId!,
                  existingCollectionTitle,
                  close
                );
              } else {
                this.createCollection(close);
              }
            }}
            submitText={
              mode === "existing"
                ? undefined
                : i18next.t("collections.collectionAddPhotoButton.create")
            }
            submitDisabled={disabled || !submitEnabled}
            disabled={!authUser}>
            <>
              <Tabs
                mb={2}
                activeName={mode}
                tabs={[
                  {
                    name: "existing",
                    label: i18next.t(
                      "collections.collectionAddPhotoButton.existing"
                    ),
                    onClick: () => this.setState({ mode: "existing" })
                  },
                  {
                    name: "create",
                    label: i18next.t(
                      "collections.collectionAddPhotoButton.createCollection"
                    ),
                    onClick: () => this.setState({ mode: "create" })
                  }
                ]}
              />
              {mode === "existing" && authUser && (
                <MultiSelectSearch
                  filters={[
                    {
                      field: "class_name",
                      values: ["collection"],
                      type: "any"
                    },
                    { field: "uid", values: [authUser.uid], type: "any" }
                  ]}
                  isMulti={false}
                  resultFields={{
                    real_id: { raw: {} },
                    privacy: { raw: {} },
                    uid: { raw: {} },
                    title: { raw: {} }
                  }}
                  defaultValues={
                    collectionId
                      ? [
                          {
                            value: `${collectionId}`,
                            label: `${existingCollectionTitle}`
                          }
                        ]
                      : undefined
                  }
                  labelI18n={"collections.collectionAddPhotoButton.collections"}
                  mapResults={results =>
                    results.map((result: any) => ({
                      value: getRaw(result, "real_id"),
                      label: getRaw(result, "title")
                    }))
                  }
                  onChange={options => {
                    if (options.length) {
                      const option = options[0];
                      const collectionId = parseInt(option.value, 10);
                      const existingCollectionTitle = option.label;
                      this.setState({
                        collectionId,
                        existingCollectionTitle
                      });
                    }
                  }}
                />
              )}
              {mode === "create" && (
                <Box>
                  <Flex alignItems="center" mt={4}>
                    <Label
                      variant="grotTextCaps"
                      fontSize={0}
                      i18n="collections.collectionEditPopup.collectionName"
                    />
                    <Tooltip
                      i18nKey="collections.collectionEditPopup.collectionNameTooltip"
                      ml={1}
                    />
                  </Flex>
                  <Input
                    name="title"
                    type="text"
                    placeholder={i18next.t(
                      "collections.collectionEditPopup.collectionNamePlaceholder"
                    )}
                    onChange={this.handleOnChange}
                    value={newCollection?.title}
                    mb={3}
                  />
                  <Flex alignItems="center">
                    <Label
                      variant="grotTextCaps"
                      fontSize={0}
                      i18n="collections.collectionEditPopup.collectionDescription"
                    />
                    <Tooltip
                      i18nKey="collections.collectionEditPopup.collectionDescriptionTooltip"
                      ml={1}
                    />
                  </Flex>
                  <Input
                    name="description"
                    type="text"
                    placeholder={i18next.t(
                      "collections.collectionEditPopup.collectionDescriptionPlaceholder"
                    )}
                    onChange={this.handleOnChange}
                    value={newCollection?.description}
                    mb={3}
                  />
                  <Flex alignItems="center">
                    <Label
                      variant="grotTextCaps"
                      fontSize={0}
                      i18n="collections.collectionEditPopup.collectionLocation"
                    />
                    <Tooltip
                      i18nKey="collections.collectionEditPopup.collectionLocationTooltip"
                      ml={1}
                    />
                  </Flex>
                  <LocationInputSearch
                    name="city"
                    value={[
                      newCollection?.city,
                      newCollection?.country &&
                        getCommonTranslation(newCollection.country)
                    ]
                      .filter(it => it)
                      .join(", ")}
                    placeholder={i18next.t(
                      "collections.collectionEditPopup.collectionLocationPlaceholder"
                    )}
                    onLocationChange={(city, country) => {
                      if (country?.length) {
                        this.handleOnCityChange(country, city);
                      }
                    }}
                    disabled={false}
                    variant={false ? "input.borderless" : undefined}
                    mb={3}
                  />
                  <Flex>
                    <Tabs
                      activeName={
                        newCollection?.privacy === "public"
                          ? "public"
                          : newCollection?.privacy === "private" &&
                            newCollection.shareWithLink === false
                          ? "private"
                          : newCollection?.privacy === "private" &&
                            newCollection.shareWithLink === true
                          ? "exclusive"
                          : newCollection?.privacy
                      }
                      flexBasis={["22%"]}
                      fontSize={[0, 3]}
                      sx={{
                        "&:": {
                          textTransform: "lowercase !important",
                          marginRight: -4
                        }
                      }}
                      tabs={[
                        {
                          name: "public",
                          label: i18next.t(
                            "collections.collectionEditPopup.public"
                          ),
                          icon: "Globe",
                          onClick: () => this.handleOnPrivacyChange("public")
                        },
                        {
                          name: "private",
                          label: i18next.t(
                            "collections.collectionEditPopup.private"
                          ),
                          icon: "Lock2",
                          onClick: () =>
                            this.handleOnPrivacyChange("private", false)
                        },
                        {
                          name: "exclusive",
                          label: i18next.t(
                            "collections.collectionEditPopup.exclusive"
                          ),
                          icon: "Star",
                          onClick: () =>
                            this.handleOnPrivacyChange("private", true)
                        }
                      ]}
                    />
                  </Flex>
                </Box>
              )}
            </>
          </Popup>
        )}
      </AuthUserContext.Consumer>
    );
  }
}
