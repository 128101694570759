import { User } from "models";
import * as type from "./constants";
import { firebaseAnalytics } from "analytics/firebaseAnalytics";

const loadState = (): User => {
  return JSON.parse(localStorage.getItem("user") ?? "{}");
};

const saveState = (user: User): User => {
  localStorage.setItem("user", JSON.stringify(user));
  return user;
};

const initialState: User = loadState();

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case type.SET_LOGGED_IN_USER: {
      firebaseAnalytics.logEvent("login", {});
      return saveState(action.item as User);
    }
    default: {
      return state;
    }
  }
};

export default reducer;
