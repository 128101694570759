import { AuthUserContext, withAuthorization } from "authentication/session";
import { ContentArea } from "layout";
import { DocumentTransaction } from "marketplace";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { DocumentTransactionsViewer } from "./DocumentTransactionsViewer";
import { DonationsViewer } from "../donations/DonationsViewer";
import { RouteComponentProps } from "react-router-dom";
import i18next from "i18next";
import { Tabs, CachedPagination, PageHeader, BackButton } from "app/shared";
import { getDocumentPayments, getDocumentPayouts } from "./services";
import { getDonations } from "app/donations/services";
import { Donation } from "app/donations/models";

interface DocumentTransactionResult extends DocumentTransaction {
  id: number;
}

interface Params {
  filter?: string;
}

type Props = RouteComponentProps<Params>;

interface State {
  disabled: boolean;
  items: DocumentTransaction[] | Donation[];
  first: boolean;
  last: boolean;
  page: number;
  size: number;
  totalElements: number;
  totalPages: number;
  mode: string;
}

const INITIAL_STATE: State = {
  mode: "sell",
  // control
  disabled: false,
  items: [],
  // page variables
  first: true,
  last: false,
  page: 0,
  size: 10,
  totalElements: 1,
  totalPages: 1
};

class TransactionsBase extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      ...INITIAL_STATE,
      // filters
      mode: props.match.params.filter ?? "sell"
    };
  }

  componentDidMount() {
    this.getTransactions(0, this.state.size);
  }

  getTransactions(pageRequest: number, sizeRequest: number) {
    const { mode } = this.state;

    this.setState({ disabled: true }, async () => {
      let promise;

      if (mode === "buy") {
        promise = getDocumentPayments(pageRequest, sizeRequest);
      } else if (mode === "sell") {
        promise = getDocumentPayouts(pageRequest, sizeRequest);
      } else if (mode === "donationsMade") {
        promise = getDonations("made", pageRequest, sizeRequest);
      } else if (mode === "donationsReceived") {
        promise = getDonations("received", pageRequest, sizeRequest);
      }

      const { data, page, size, totalElements, totalPages } = await promise;
      this.setState({
        disabled: false,
        items: data,
        page,
        size,
        totalElements,
        totalPages
      });
    });
  }

  setMode(mode: string) {
    this.setState({ ...INITIAL_STATE, mode }, () => {
      this.getTransactions(0, this.state.size);
    });
  }

  render() {
    const { disabled, items, page, size, totalPages, mode } = this.state;

    return (
      <ContentArea>
        <AuthUserContext.Consumer>
          {({ authUser }) =>
            authUser && (
              <BackButton
                i18n="documents.transactions.backButton"
                href={`/users/${authUser.uid}`}
              />
            )
          }
        </AuthUserContext.Consumer>
        <PageHeader
          variant="1"
          title={i18next.t("documents.transactions.pageTitle")}
          subtitle={i18next.t("documents.transactions.pageSubtitle")}
        />
        <Tabs
          my={4}
          variant="right"
          activeName={mode}
          fontSize={[1, 3]}
          flexBasis={["10%"]}
          wrapTabs={true}
          tabs={[
            {
              name: "sell",
              label: i18next.t("documents.transactions.sellHistory"),
              href: "/transactions/sell",
              onClick: () => {
                this.setMode("sell");
              }
            },
            {
              name: "buy",
              label: i18next.t("documents.transactions.buyHistory"),
              href: "/transactions/buy",
              onClick: () => {
                this.setMode("buy");
              }
            },
            {
              name: "donationsMade",
              label: i18next.t("documents.transactions.donationsMade"),
              href: "/transactions/donationsMade",
              onClick: () => {
                this.setMode("donationsMade");
              }
            },
            {
              name: "donationsReceived",
              label: i18next.t("documents.transactions.donationsReceived"),
              href: "/transactions/donationsReceived",
              onClick: () => {
                this.setMode("donationsReceived");
              }
            }
          ]}
        />
        {(mode === "sell" || mode === "buy") && (
          <CachedPagination<DocumentTransactionResult>
            disabled={disabled}
            results={(items as DocumentTransaction[]).map(
              (item: DocumentTransaction) => ({
                ...item,
                id: item.transactionItemId
              })
            )}
            page={page}
            totalPages={totalPages}
            setPage={page => this.getTransactions(page, size)}>
            {results => (
              <DocumentTransactionsViewer
                items={results}
                refresh={() => this.getTransactions(0, size)}
              />
            )}
          </CachedPagination>
        )}
        {(mode === "donationsMade" || mode === "donationsReceived") && (
          <CachedPagination
            showLoadButtons={true}
            disabled={disabled}
            results={(items as Donation[]).map((item: Donation) => ({
              ...item
            }))}
            page={page}
            totalPages={totalPages}
            setPage={page => this.getTransactions(page, size)}>
            {results => (
              <DonationsViewer
                items={results}
                mode={
                  mode === "donationsMade"
                    ? "made"
                    : mode === "donationsReceived"
                    ? "received"
                    : "made"
                }
                refresh={() => this.getTransactions(0, size)}
              />
            )}
          </CachedPagination>
        )}
      </ContentArea>
    );
  }
}

export const Transactions = compose<Props, any>(
  withRouter,
  withAuthorization()
)(TransactionsBase);
