import styled from "@emotion/styled";
import { LanguageSelect } from "app/navbar/LanguageSelect";
import { Button, SocialMediaButton, Image } from "app/shared";
import React from "react";
import { Trans } from "react-i18next";
import { Flex, Text, Box } from "rebass";
import { FlexboxProps } from "styled-system";
import Centro2020 from "./static/Centro2020.svg";
import Portugal2020 from "./static/Portugal2020.svg";
import Feder from "./static/Feder.svg";
import { DonationPopUp } from "app/donations/DonationPopup";
import { AuthUserContext } from "authentication/session";

const FooterLink = styled(Button)``;
FooterLink.defaultProps = {
  width: [1 / 2, 1 / 3],
  variant: "link.footer"
};

export const Footer: React.FC<FlexboxProps> = (props: FlexboxProps) => {
  return (
    <Box>
      <Flex
        py={[0, 3, 3]}
        width={[1]}
        maxWidth={["maxWidth"]}
        mx="auto"
        flexDirection="column"
        {...props}>
        <Flex justifyContent="space-between" flexDirection={["column", "row"]}>
          <Flex
            flexWrap="wrap"
            sx={{
              "& > *": {
                paddingRight: [2, 4, 6, 8],
                py: 2,
                px: [2, 0],
                borderBottom: [1, 0]
              },
              "& > :nth-of-type(even)": {
                borderLeft: [1, 0]
              }
            }}>
            <FooterLink href="/about">
              <Trans i18nKey="footer.aboutUs" />
            </FooterLink>
            <FooterLink href="/about#licenses">
              <Trans i18nKey="footer.rights" />
            </FooterLink>
            <FooterLink href="/register">
              <Trans i18nKey="footer.becomeASeller" />
            </FooterLink>
            <FooterLink href="/privacy-policy">
              <Trans i18nKey="footer.privacyPolicy" />
            </FooterLink>
            <FooterLink href="/about#faqs">
              <Trans i18nKey="footer.faqs" />
            </FooterLink>
            <FooterLink href="/terms-conditions">
              <Trans i18nKey="footer.termsOfUse" />
            </FooterLink>
          </Flex>
          <Flex
            flexDirection={["row-reverse", "column"]}
            width={["100%", "20%"]}>
            <Flex width="100%">
              <AuthUserContext.Consumer>
                {({ authUser }) => {
                  const logged = authUser?.uid ? true : false;
                  return (
                    <DonationPopUp
                      mb={2}
                      pt={["15px", 2]}
                      width="100%"
                      backgroundColor="blue"
                      color={"white"}
                      isLogged={logged}
                      sx={{
                        borderLeft: 0,
                        borderColor: "blue"
                      }}
                    />
                  );
                }}
              </AuthUserContext.Consumer>
            </Flex>
            <Flex
              flexDirection="row"
              mt={2}
              width={["50%", "100%"]}
              flex={["1 0 auto", "0 1 auto"]}
              sx={{
                "& > *": {
                  paddingLeft: 2,
                  py: [2, 1]
                }
              }}>
              <SocialMediaButton
                key={`facebook_footer_icon`}
                socialMediaType={"facebook"}
                socialMediaValue={"FrontFiles"}
              />
              <SocialMediaButton
                key={`twitter_footer_icon`}
                socialMediaType={"twitter"}
                socialMediaValue={"FrontFiles"}
              />
              <LanguageSelect width={60} menuMarginTop={0} />
            </Flex>
          </Flex>
        </Flex>
        <Flex
          justifyContent="space-between"
          alignItems={["flex-start", "flex-end"]}
          flexDirection={["column", "row"]}
          pt={[0, 3]}
          pb={[2, 0]}
          pl={[2, 0]}>
          <Box>
            <Text fontSize={1} variant="caps" mb={2}>
              <Trans i18nKey="footer.coFinancedBy" />
            </Text>
            <Flex flexDirection={["column", "row"]} alignItems="flex-start">
              <Button
                href="https://storage.googleapis.com/frontfiles-staging-static/FileProject.pdf"
                target="_blank">
                <Image src={Centro2020} />
              </Button>
              <Button
                href="https://storage.googleapis.com/frontfiles-staging-static/FileProject.pdf"
                target="_blank"
                mx={[0, 4]}
                my={[3, 0]}>
                <Image src={Portugal2020} />
              </Button>
              <Button
                href="https://storage.googleapis.com/frontfiles-staging-static/FileProject.pdf"
                target="_blank">
                <Image src={Feder} />
              </Button>
            </Flex>
          </Box>
          <Text fontSize={[1, 2, 3]} variant="caps" color="footerGrey.0">
            <Trans i18nKey="footer.copyright" />
          </Text>
        </Flex>
      </Flex>
    </Box>
  );
};

export default Footer;
