import { ContentArea } from "layout";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { compose } from "recompose";
import { DocumentSearch } from "./documents";
import { DocumentLocationSearch } from "./documents/DocumentLocationSearch";
import { BucketSearch } from "./buckets";
import { DocumentTabs } from "./models";
import { SearchHeader } from "./shared";
import { UserSearch } from "./users";
import { UserLocationSearch } from "./users/UserLocationSearch";
import { CollectionSearch } from "./collections";

interface Params {
  view?: string;
}

type Props = RouteComponentProps<Params>;

/**
 * Key is used in search views to force component render when query changes.
 * Using document as default search view.
 */
const SearchBase: React.FC<Props> = ({ history, match }) => {
  const query =
    queryString.parse(history.location.search)?.["q"]?.toString() ?? "";
  const searchView = match.params.view ?? "document";
  const [documentColumn, setDocumentColumn] = useState<number | undefined>(
    undefined
  );
  const [showDocumentInfo, setShowDocumentInfo] = useState<boolean | undefined>(
    undefined
  );

  useEffect(() => {
    if (searchView !== "document" && showDocumentInfo !== undefined) {
      setShowDocumentInfo(undefined);
    }
  }, [searchView, showDocumentInfo]);

  return (
    <ContentArea px={[2, 0]}>
      {searchView === "document" && (
        <DocumentSearch
          key={query}
          column={documentColumn}
          showInfo={showDocumentInfo}
          bodyHeader={
            <SearchHeader
              onColumnChange={column => setDocumentColumn(column)}
              onShowInfoChange={showInfo => setShowDocumentInfo(showInfo)}
              tabs={DocumentTabs}
              activeTabName="document"
              defaultShowHideInfo={showDocumentInfo}
            />
          }
        />
      )}
      {searchView === "collection" && <CollectionSearch key={query} />}
      {searchView === "bucket" && <BucketSearch key={query} />}
      {searchView === "document-location" && (
        <DocumentLocationSearch key={query} />
      )}
      {searchView === "user" && <UserSearch key={query} />}
      {searchView === "user-location" && <UserLocationSearch key={query} />}
    </ContentArea>
  );
};

export const Search = compose<Props, any>(withRouter)(SearchBase);
