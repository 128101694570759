import { LanguageSelect } from "app/navbar/LanguageSelect";
import { SiteImageButton } from "app/shared";
import React, { ReactNode } from "react";
import { Trans } from "react-i18next";
import { Flex, Text } from "rebass";
import BackgroundWrapper from "./BackgroundWrapper";

interface Props {
  backgroundImage?: string;
  backgroundImageAuthor?: string;
  backgroundImageAuthorPosition?: "top" | "bottom";
  headerI18n?: string;
  headerFontSize?: number[];
  mainSection?: ReactNode;
}

const AuthLayout: React.FC<Props> = ({
  backgroundImage,
  backgroundImageAuthor,
  backgroundImageAuthorPosition,
  headerI18n,
  headerFontSize,
  mainSection
}) => (
  <BackgroundWrapper
    backgroundImage={backgroundImage}
    backgroundImageAuthor={backgroundImageAuthor}
    backgroundImageAuthorPosition={backgroundImageAuthorPosition}>
    <Flex
      margin="auto"
      minHeight="100vh"
      flexDirection="column"
      bg="white.0"
      maxWidth="550px"
      px={[3, 7]}
      py={[5, 8]}>
      <Flex
        justifyContent="space-between"
        alignItems="flex-start"
        flexDirection="row">
        <SiteImageButton width={["190px", "225px"]} />
        <LanguageSelect width={60} menuMarginTop={0} sx={{ marginLeft: 3 }} />
      </Flex>
      <Flex flexDirection="column" alignItems="center" sx={{ flexGrow: 1 }}>
        {headerI18n && (
          <Text
            fontSize={headerFontSize ? headerFontSize : [7, 10, 14]}
            pt={[5, 6, 7]}
            pb={[3, 4, 5]}
            sx={{ textAlign: "center", textTransform: "uppercase" }}>
            <Trans i18nKey={headerI18n} />
          </Text>
        )}
        {mainSection}
      </Flex>
    </Flex>
  </BackgroundWrapper>
);

export default AuthLayout;
