import styled from "@emotion/styled";
import Grid, { GridProps } from "primitives/Grid";

const CardGridLayout = styled(Grid)<GridProps>``;

CardGridLayout.defaultProps = {
  gridTemplateColumns: ["1fr", null, "repeat(3, 1fr)"]
};

export default CardGridLayout;
