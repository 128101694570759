import React from "react";

interface Props {
  condition: boolean;
  wrapper: (props: any) => any;
}

const ConditionalWrapper: React.FC<Props> = ({
  condition,
  wrapper,
  children
}) => {
  return condition ? wrapper(children) : children;
};

export default ConditionalWrapper;
