import { Button, Input } from "app/shared";
import i18next from "i18next";
import MessageService, { Chat } from "messaging/MessageService";
import React, { PureComponent } from "react";
import { Box, Flex, Text } from "rebass";

interface Props {
  messageService: MessageService;
  chat: Chat;
}

interface State {
  message: string;
  disabled: boolean;
}

export class ChatInput extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      message: "",
      disabled: false
    };
  }

  async sendMessage(message: string) {
    const { messageService, chat } = this.props;

    if (message.length) {
      this.setState({ disabled: true }, async () => {
        await messageService.sendMessage(
          chat.id,
          chat.userMetadata.id,
          message
        );
        this.setState({ message: "", disabled: false });
      });
    }
  }

  handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState<never>({ [event.target.name]: event.target.value });
  };

  render() {
    const { message, disabled } = this.state;

    return (
      <Flex flexDirection="column">
        <Box mx={[1, 2, 3]} my={1}>
          <Input
            name="message"
            type="text"
            variant="chat"
            placeholder={i18next.t("messages.messageInputHint")}
            onChange={this.handleOnChange}
            disabled={disabled}
            onKeyUp={event => {
              if (event.key === "Enter") {
                this.sendMessage(message);
                // For some reason this event is being triggered twice,
                // so we stop its propagation here for now
                event.stopPropagation();
              }
            }}
            value={message}
          />
        </Box>
        <Button
          variant="black"
          width={"100%"}
          onClick={() => this.sendMessage(message)}
          disabled={disabled}>
          <Text fontWeight="bold">{i18next.t("messages.send")}</Text>
        </Button>
      </Flex>
    );
  }
}
