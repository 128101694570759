import React from "react";
import Svg, { SvgProps } from "primitives/Svg";

const SvgChat = ({ width, height, size, color }: SvgProps) => (
  <Svg
    width={size || width}
    height={size || height}
    viewBox="0 0 18 17"
    color={color}>
    <path
      d="M.2.2V2h.6V.8h16.4V13.2H3.1l-1.9 2-.4.4V6H.2v10.9l1.1-1.1 2-2h14.5V.2H.2z"
      stroke="#000"
      strokeOpacity={0.4}
    />
  </Svg>
);

SvgChat.defaultProps = {
  width: 24,
  height: 24
};
export default SvgChat;
