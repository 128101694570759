import React, { FC } from "react";
import { Box, Text, TextProps } from "rebass";

interface Props extends TextProps {
  text: string;
}

export const MultilineText: FC<Props> = ({ text, ...rest }) => {
  const lines = text.split("\n").filter(it => it !== "");
  return (
    <Text {...(rest as any)}>
      {lines.map((line, index) => (
        <Box
          key={`multiline_${
            text.length > 6 ? text.substring(0, 6) : text
          }_${index}`}>
          {line}
          {index < lines.length - 1 && <br />}
        </Box>
      ))}
    </Text>
  );
};
