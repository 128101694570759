import React from "react";
import Svg, { SvgProps } from "primitives/Svg";

const SvgSelectNone = ({ width, height, size, color }: SvgProps) => (
  <Svg
    width={size || width}
    height={size || height}
    viewBox="0 0 24 24"
    color={color}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 6h13v12h1V5H5v15h13v-1H6V6z"
    />
    <path d="M15.818 15.182L9.454 8.818m0 6.364l6.364-6.364" stroke="#000" />
  </Svg>
);

SvgSelectNone.defaultProps = {
  width: 24,
  height: 24
};
export default SvgSelectNone;
