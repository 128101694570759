import { withSearch } from "@elastic/react-search-ui";
import React, { useState } from "react";
import { compose } from "recompose";
import { markSelectedFacetValuesFromFilters } from "../helpers";
import MultiCheckboxFacetView from "../views/MultiCheckboxFacetView";

type FilterType = "all" | "any" | "none";

interface PropsExternal {
  field: string;
  label: string;
  filterType: FilterType;
  show?: number;
  isFilterable?: boolean;
}

interface Props extends PropsExternal {
  filters: any;
  facets: any;
  addFilter: any;
  removeFilter: any;
  a11yNotify: any;
}

const MultiCheckboxFacetContainer: React.FC<Props> = ({
  field,
  label,
  filterType,
  show,
  a11yNotify,
  removeFilter,
  addFilter,
  facets,
  filters
}) => {
  const [more, setMore] = useState<number>(show ?? 5);

  const handleClickMore = (totalOptions: number) => {
    let visibleOptionsCount = more + 10;
    const showingAll = visibleOptionsCount >= totalOptions;
    if (showingAll) visibleOptionsCount = totalOptions;

    a11yNotify("moreFilters", { visibleOptionsCount, showingAll });

    setMore(visibleOptionsCount);
  };

  const facetsForField = facets[field];

  if (!facetsForField) return null;

  // By using `[0]`, we are currently assuming only 1 facet per field. This will likely be enforced
  // in future version, so instead of an array, there will only be one facet allowed per field.
  const facet = facetsForField[0];

  const facetValues = markSelectedFacetValuesFromFilters(
    facet,
    filters,
    field,
    filterType
  ).data;

  const selectedValues = facetValues
    .filter((fv: any) => fv.selected)
    .map((fv: any) => fv.value);

  if (!facetValues.length && !selectedValues.length) return null;

  return (
    <MultiCheckboxFacetView
      label={label}
      onMoreClick={() => handleClickMore(facetValues.length)}
      onSelect={value => addFilter(field, value, filterType)}
      onRemove={value => removeFilter(field, value, filterType)}
      options={facetValues.slice(0, more)}
      showMore={facetValues.length > more}
    />
  );
};

MultiCheckboxFacetContainer.defaultProps = {
  filterType: "all",
  isFilterable: false
};

export default compose<Props, PropsExternal>(
  withSearch(
    ({ filters, facets, addFilter, removeFilter, a11yNotify }: Props) => ({
      filters,
      facets,
      addFilter,
      removeFilter,
      a11yNotify
    })
  )
)(MultiCheckboxFacetContainer);
