import { ContentArea } from "layout";
import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { PageParser } from "app/pages";
import { HomepageHeader } from "./HomepageHeader";
import { Box, Flex } from "rebass";
import {
  Button,
  ColumnButton,
  FullWidthContainer,
  Icon,
  NewsletterPopUp,
  ShowHideInfoButton
} from "app/shared";
import { withFirebase } from "authentication/firebase";
import { sizes } from "styles/theme";
import { Text } from "rebass";
import { Trans } from "react-i18next";
import { WelcomePopup } from "app/account/popups";
import { Sticky } from "react-stickup";
import { FacebookIcon, PinterestIcon, TwitterIcon } from "react-share";
import { DocumentSearch } from "../search/documents";
import { useMediaQueryContext } from "../../styles/context";

const HomepageTabs: React.FC = () => {
  const [tab1, setTab1] = useState(true); //latest
  const [tab2, setTab2] = useState(false); //highlights
  const [column, setColumn] = useState<number | undefined>(undefined);
  const [showInfo, setShowInfo] = useState<boolean | undefined>(undefined);
  const { isSmallMedium } = useMediaQueryContext();

  return (
    <>
      <Box mt={8}>
        <Sticky style={{ zIndex: 2, backgroundColor: "white" }}>
          <Box bg="white" pt={2} pb={4}>
            <Box sx={{ borderBottom: 1, borderColor: "blue" }}>
              <Box
                bg="white"
                px={[4, 0]}
                width="100%"
                maxWidth={["maxWidth"]}
                mx="auto"
                sx={{
                  "& > *": { flexShrink: 0 }
                }}>
                <Flex
                  height="100%"
                  width={[1]}
                  maxWidth={["maxWidth"]}
                  mx="auto"
                  sx={{
                    "& > *": { flexShrink: 0 }
                  }}>
                  <Flex
                    width="100%"
                    justifyContent="center"
                    alignItems="center">
                    <Button
                      width="100%"
                      sx={{
                        borderBottom: tab1 ? 0 : 1,
                        borderBottomColor: "blue",
                        position: "relative",
                        top: "1px",
                        ":hover": {
                          backgroundColor: "blue",
                          borderColor: "blue",
                          color: "white"
                        }
                      }}
                      mr={[2, 4]}
                      onClick={() => {
                        setTab1(true);
                        setTab2(false);
                      }}
                      variant={tab1 ? "activeBorder" : undefined}>
                      <Trans i18nKey="home.tabs.latest" />
                    </Button>
                    <Button
                      width="100%"
                      sx={{
                        borderBottom: tab2 ? 0 : 1,
                        borderBottomColor: "blue",
                        position: "relative",
                        top: "1px",
                        ":hover": {
                          backgroundColor: "blue",
                          borderColor: "blue",
                          color: "white"
                        }
                      }}
                      onClick={() => {
                        setTab2(true);
                        setTab1(false);
                      }}
                      variant={tab2 ? "activeBorder" : undefined}>
                      <Trans i18nKey="home.tabs.highlights" />
                    </Button>
                  </Flex>
                </Flex>
              </Box>
            </Box>
          </Box>
        </Sticky>
      </Box>
      {/* TAB CONTENT */}
      <Box sx={{ overflow: "auto" }}>
        {tab1 && (
          <Box bg="white" px={[2, 0]} pb={4} pt={2}>
            <Box m="auto" maxWidth={sizes.maxWidth}>
              <>
                <Flex alignItems="center" justifyContent="flex-end" mb={2}>
                  {!isSmallMedium && (
                    <ColumnButton
                      mr={3}
                      onColumnChange={column => setColumn(column)}
                    />
                  )}
                  <ShowHideInfoButton
                    onShowInfoChange={showInfo => setShowInfo(showInfo)}
                  />
                </Flex>
                <DocumentSearch
                  hideFilters={true}
                  trackUrlState={false}
                  resultsPerPage={10}
                  column={column}
                  showInfo={showInfo}
                />
              </>
            </Box>
          </Box>
        )}
        {tab2 && (
          <Box bg="white" px={[2, 0]} color="black" pt={2} pb={8}>
            <Box m="auto" maxWidth={sizes.maxWidth}>
              <PageParser pageId={1} />
            </Box>
          </Box>
        )}
      </Box>
      {/* Social Area */}
      <Box sx={{ borderTop: 1, borderColor: "blue" }} py={8} mb={8}>
        <Flex
          width="100%"
          flexDirection="column"
          justifyContent="center"
          alignItems="center">
          <Text fontSize={[8, 12]} pb={6}>
            <Trans i18nKey="home.socialArea.title" />
          </Text>
          <Flex>
            <Button
              variant="transparent"
              href="https://www.facebook.com/Frontfiles/"
              target="_blank"
              mr={4}>
              <FacebookIcon iconBgStyle={{ fill: "black" }} size={40} />
            </Button>
            <Button
              variant="transparent"
              href="https://www.instagram.com/frontfiles/"
              target="_blank"
              mr={4}>
              <Icon name="Instagram" size={40} />
            </Button>
            <Button
              variant="transparent"
              href="https://twitter.com/frontfiles"
              target="_blank"
              mr={4}>
              <TwitterIcon iconBgStyle={{ fill: "black" }} size={40} />
            </Button>
            <Button
              variant="transparent"
              href="https://www.pinterest.pt/Frontfiles/"
              target="_blank">
              <PinterestIcon iconBgStyle={{ fill: "black" }} size={40} />
            </Button>
          </Flex>
          <Flex justifyContent="center" alignItems="center" pt={6} width="100%">
            <NewsletterPopUp
              i18n="home.socialArea.newsletterButton"
              width={["100%", "30%"]}
            />
          </Flex>
        </Flex>
      </Box>
    </>
  );
};

const HomepageBase: React.FC = () => {
  return (
    <>
      <ContentArea py={0}>
        <HomepageHeader />
        <FullWidthContainer>
          {/* TABS */}
          <HomepageTabs />
        </FullWidthContainer>
      </ContentArea>
      <WelcomePopup />
    </>
  );
};

export const Homepage = compose(withRouter, withFirebase)(HomepageBase);
