import { Button, Icon } from "app/shared";
import React, { Component } from "react";
import { compose } from "recompose";
import { BoxProps, Flex } from "rebass";

interface PropsExternal extends BoxProps {
  documentId: number;
  type?: string;
  variant?: string;
}

type Props = PropsExternal;

class BuyButtonBase extends Component<Props> {
  static defaultProps = {
    type: "document",
    variant: "transparent"
  };

  render() {
    const { documentId, type, variant } = this.props;

    const handleOnClick = () => {
      let path = "documents";
      if (type === "collection") {
        path = "collections";
      } else if (type === "bucket") {
        path = "buckets";
      }
      document.location.href = `/${path}/${documentId}`;
    };
    return (
      <Button
        bg="white"
        p={1}
        sx={{
          border: 1,
          borderColor: "blue",
          "&:hover": { opacity: "1 !important" }
        }}
        variant={variant}
        onClick={handleOnClick}>
        <Flex justifyContent="center" alignItems="center">
          <Icon name="Cart2" size={22} color="blue" />
        </Flex>
      </Button>
    );
  }
}

export const BuyButton = compose<Props, PropsExternal>()(BuyButtonBase);
