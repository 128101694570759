import * as type from "./constants";
import { CheckoutItem } from "../models";

export function addCheckoutItem(
  item: CheckoutItem
): { type: string; item: CheckoutItem } {
  return {
    item,
    type: type.ADD_CHECKOUT_ITEM
  };
}

export function removeCheckoutItem(item: CheckoutItem) {
  return {
    item,
    type: type.REMOVE_CHECKOUT_ITEM
  };
}

export function clearCheckoutItems() {
  return {
    type: type.CLEAR_CHECKOUT_ITEMS
  };
}
