import { Button, Input, Popup } from "app/shared";
import i18next from "i18next";
import { axiosNoAuthentication } from "network";
import React, { Component } from "react";
import { Trans } from "react-i18next";
import { Box, Flex, Text } from "rebass";
import { AccessToken, Photo, RequestToken } from "./models";
import {
  executeRequest,
  getAccessToken,
  getRequestToken
} from "./services/flickr";

interface Props {
  disabled?: boolean;
  onFileDrop: (files: File[]) => void;
}

interface State {
  disabled: boolean;
  completed: boolean;
  files: File[];
  step: number;
  requestToken?: RequestToken;
  accessToken?: AccessToken;
  page: number;
  photosetTotalPages: number;
  photosets: any[];
  photosetUserUrl: string;
  photosetUserId: string;
}

const INITIAL_STATE = {
  // control
  disabled: false,
  completed: false,
  files: [],
  step: 1,
  requestToken: undefined,
  accessToken: undefined,
  // Photosets
  page: 1, // flickr api begins with page=1
  photosetTotalPages: 0,
  photosets: [],
  photosetUserUrl: "",
  photosetUserId: ""
};

export class FlickrUpload extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit(close: () => void) {
    this.props.onFileDrop(this.state.files);
    close();
  }

  /**
   * This will open a popup asking for authorization to flickr account
   */
  openCodePopup() {
    this.setState({ disabled: true }, async () => {
      const requestToken = await getRequestToken();

      this.setState({ step: 1, disabled: false, requestToken }, () =>
        window.open(requestToken.authorizationUrl, "login", "scrollbars=yes")
      );
    });
  }

  setRequestTokenCode(code: string) {
    const { requestToken } = this.state;

    this.setState({
      requestToken: {
        ...requestToken,
        code
      } as RequestToken
    });
  }

  getAccessToken() {
    const { requestToken } = this.state;

    if (requestToken) {
      this.setState({ disabled: true }, async () => {
        const accessToken = await getAccessToken(
          {
            token: requestToken.token,
            tokenSecret: requestToken.tokenSecret
          },
          requestToken.code
        );

        this.setState({ step: 2, disabled: false, accessToken });
      });
    }
  }

  /**
   * Flickr API available in: https://www.flickr.com/services/api
   */
  executeRequest(method: string, parameters: any = {}): Promise<any> {
    const { accessToken } = this.state;

    if (accessToken) {
      return executeRequest(accessToken, method, parameters);
    }

    throw new Error("No access token provided");
  }

  getPhotosetList(page = 1, per_page = 10) {
    const user_id = this.state.photosetUserId || undefined; // get selected photoset user id
    return this.executeRequest("flickr.photosets.getList", {
      user_id,
      page,
      per_page
    }).then(response =>
      this.setState({
        page,
        photosets:
          response.data.photosets.page === "1"
            ? response.data.photosets.photoset
            : this.state.photosets.concat(response.data.photosets.photoset),
        photosetTotalPages: response.data.photosets.pages
      })
    );
  }

  getUserIdFromUrl(url: string) {
    return this.executeRequest("flickr.urls.lookupUser", { url }).then(
      response => response.data.user && response.data.user.id
    );
  }

  getPhotosetPhotos(photoset_id: number, page = 1, per_page = 100) {
    return this.executeRequest("flickr.photosets.getPhotos", {
      photoset_id,
      page,
      per_page
    });
  }

  async downloadPhotoset(photoset_id: number) {
    const files = [];
    const photos: Photo[] = [];
    let totalPages = 1;

    // Get all photoset photos
    for (let currentPage = 1; currentPage <= totalPages; currentPage++) {
      const response = await this.getPhotosetPhotos(photoset_id, currentPage);
      response.data.photoset.photo.forEach((photo: Photo) => {
        photos.push(photo);
      });

      totalPages = response.data.photoset.pages;
    }

    // Download photos from flickr
    for (let i = 0; i < photos.length; i++) {
      const photo = photos[i];
      const photoUrl = `https://farm${photo.farm}.staticflickr.com/${photo.server}/${photo.id}_${photo.secret}_b.jpg`;

      try {
        const response = await axiosNoAuthentication.get(photoUrl, {
          responseType: "arraybuffer"
        });
        const blob = new File([response.data], photo.id, {
          type: response.headers["content-type"]
        });
        files.push(blob);
      } catch (err) {
        console.log(`Error downloading photo ${i}=${photoUrl}`);
      }
    }

    // Set popup as completed
    this.setState({ step: 4, files, completed: true });
  }

  render() {
    const {
      completed,
      step,
      requestToken,
      accessToken,
      photosets,
      photosetTotalPages,
      page,
      photosetUserUrl
    } = this.state;
    const disabled = this.props.disabled || completed;

    return (
      <Popup
        trigger={
          // TODO: Remove this when FF-332 is fixed: https://waterdog.atlassian.net/browse/FF-332
          <Box>
            <Button
              mb={3}
              width="100%"
              disabled={disabled}
              variant="blue"
              sx={{ border: "none" }}
              onClick={() => this.openCodePopup()}>
              <Text fontSize={3} variant="caps">
                <Trans i18nKey="documents.submitContent.uploadProviders.flickr.button" />
              </Text>
            </Button>
          </Box>
        }
        heading={i18next.t(
          "documents.submitContent.uploadProviders.flickr.button"
        )}
        cancelDisabled={this.state.disabled}
        submit={close => this.onSubmit(close)}
        submitText={i18next.t(
          "documents.submitContent.uploadProviders.flickr.submitText"
        )}
        onClose={() => this.setState({ ...INITIAL_STATE })} // reset state on close
        submitDisabled={this.state.disabled || !completed}
        disabled={this.props.disabled}>
        <>
          {step === 1 && (
            <Box>
              <Input
                name="verifier"
                type="text"
                onChange={e => this.setRequestTokenCode(e.target.value)}
              />
              <Button
                onClick={() => this.getAccessToken()}
                disabled={disabled || requestToken?.code?.length === 0}
                mt={2}>
                <Trans i18nKey="documents.submitContent.uploadProviders.flickr.verifyCode" />
              </Button>
            </Box>
          )}
          {step === 2 && (
            <>
              <Input
                name="photosetUserUrl"
                labelI18n={
                  "documents.submitContent.uploadProviders.flickr.fromPublicUser"
                }
                type="text"
                onChange={e =>
                  this.setState({ photosetUserUrl: e.target.value })
                }
                mb={3}
              />
              <Flex>
                <Button
                  display="block"
                  width="100%"
                  onClick={async () => {
                    const photosetUserId = await this.getUserIdFromUrl(
                      photosetUserUrl
                    );
                    this.setState({ step: 3, photosetUserId }, () => {
                      this.getPhotosetList(page);
                    });
                  }}
                  disabled={disabled || photosetUserUrl?.length === 0}>
                  <Trans i18nKey="documents.submitContent.uploadProviders.flickr.downloadFromPublic" />
                </Button>
                <Button
                  display="block"
                  width="100%"
                  onClick={() => {
                    this.getPhotosetList(page);
                    this.setState({ step: 3 });
                  }}
                  disabled={disabled}>
                  <Trans i18nKey="documents.submitContent.uploadProviders.flickr.downloadOwn" />
                </Button>
              </Flex>
            </>
          )}
          {step === 3 && accessToken && Object.keys(accessToken).length && (
            <Box>
              {photosets.map((photoset, key) => (
                <Button
                  key={key}
                  onClick={() => this.downloadPhotoset(photoset.id)}
                  disabled={disabled}
                  mb={2}>
                  #{photoset.id} {photoset.title._content} ({photoset.photos}{" "}
                  photos)
                </Button>
              ))}
              {page < photosetTotalPages && (
                <Button
                  onClick={() => this.getPhotosetList(page + 1)}
                  disabled={disabled}>
                  <Trans i18nKey="documents.submitContent.uploadProviders.flickr.getMoreAlbums" />
                </Button>
              )}
            </Box>
          )}
          {step === 4 && (
            <Trans i18nKey="documents.submitContent.uploadProviders.flickr.completed" />
          )}
        </>
      </Popup>
    );
  }
}
