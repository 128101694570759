import React from "react";
import Svg, { SvgProps } from "primitives/Svg";

const SvgZoomIn = ({ width, height, size, color }: SvgProps) => (
  <Svg
    width={size || width}
    height={size || height}
    viewBox="0 0 66 66"
    color={color}>
    <path d="M20.276 27.62h4.18v4.4h2.376v-4.4h4.18v-2.222h-4.18v-4.4h-2.376v4.4h-4.18v2.222zM34.51 38.828l2.157-2.158L56.08 56.084l-2.157 2.157L34.51 38.828z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M36.706 15.064c5.976 5.976 5.976 15.666 0 21.642-.74.74-1.536 1.387-2.375 1.944l2.158 2.157a18.422 18.422 0 002.338-1.98c7.148-7.148 7.148-18.737 0-25.884-7.148-7.148-18.737-7.148-25.885 0-7.147 7.147-7.147 18.736 0 25.884 4.79 4.79 11.574 6.37 17.68 4.741l-2.538-2.538a15.28 15.28 0 01-13.02-4.324c-5.977-5.976-5.977-15.666 0-21.642 5.976-5.977 15.666-5.977 21.642 0z"
    />
  </Svg>
);

SvgZoomIn.defaultProps = {
  width: 24,
  height: 24
};
export default SvgZoomIn;
