type Url = string;
type I18nDotSeparatedString = string;

/**
 * EditMyInfo - Basic Information section
 */
type UserInputTypes = "file" | "text";

export type UserBasicInfoTypes =
  | "picture"
  | "professionalName"
  | "fullName"
  | "username"
  | "country"
  | "city";

export interface UserBasicInfo {
  name: UserBasicInfoTypes;
  type: UserInputTypes;
  i18n: I18nDotSeparatedString;
}

export const UserBasicInfos: UserBasicInfo[] = [
  {
    name: "picture",
    type: "file",
    i18n: "account.editMyInfo.basicInformation.profilePicture"
  },
  {
    name: "professionalName",
    type: "text",
    i18n: "account.editMyInfo.basicInformation.professionalName"
  },
  {
    name: "fullName",
    type: "text",
    i18n: "account.editMyInfo.basicInformation.fullName"
  },
  // {
  //   name: "username",
  //   type: "text",
  //   i18n: "account.editMyInfo.basicInformation.username"
  // },
  {
    name: "city",
    type: "text",
    i18n: "account.editMyInfo.basicInformation.location"
  }
];

/**
 * EditMyInfo - About section
 */
interface UserSkills {
  name: string;
  i18n: I18nDotSeparatedString;
}

export const UserSkills: UserSkills[] = [
  {
    name: "photographer",
    i18n: "account.editMyInfo.about.skills.photographer"
  },
  {
    name: "executiveProducer",
    i18n: "account.editMyInfo.about.skills.executiveProducer"
  },
  {
    name: "light",
    i18n: "account.editMyInfo.about.skills.light"
  },
  {
    name: "copy",
    i18n: "account.editMyInfo.about.skills.copy"
  },
  {
    name: "videographer",
    i18n: "account.editMyInfo.about.skills.videographer"
  },
  {
    name: "producer",
    i18n: "account.editMyInfo.about.skills.producer"
  },
  {
    name: "directorAssistant",
    i18n: "account.editMyInfo.about.skills.directorAssistant"
  },
  {
    name: "translation",
    i18n: "account.editMyInfo.about.skills.translation"
  },
  {
    name: "illustrator",
    i18n: "account.editMyInfo.about.skills.illustrator"
  },
  {
    name: "managingDirector",
    i18n: "account.editMyInfo.about.skills.managingDirector"
  },
  {
    name: "technician",
    i18n: "account.editMyInfo.about.skills.technician"
  },
  {
    name: "subtitles",
    i18n: "account.editMyInfo.about.skills.subtitles"
  },
  {
    name: "designer",
    i18n: "account.editMyInfo.about.skills.designer"
  },
  {
    name: "marketingManager",
    i18n: "account.editMyInfo.about.skills.marketingManager"
  },
  {
    name: "rigger",
    i18n: "account.editMyInfo.about.skills.rigger"
  },
  {
    name: "offVoice",
    i18n: "account.editMyInfo.about.skills.offVoice"
  },
  {
    name: "edition",
    i18n: "account.editMyInfo.about.skills.edition"
  },
  {
    name: "projectManager",
    i18n: "account.editMyInfo.about.skills.projectManager"
  },
  {
    name: "fixer",
    i18n: "account.editMyInfo.about.skills.fixer"
  },
  {
    name: "personalAssistant",
    i18n: "account.editMyInfo.about.skills.personalAssistant"
  },
  {
    name: "journalist",
    i18n: "account.editMyInfo.about.skills.journalist"
  },
  {
    name: "creativeDirector",
    i18n: "account.editMyInfo.about.skills.creativeDirector"
  },
  {
    name: "security",
    i18n: "account.editMyInfo.about.skills.security"
  },
  {
    name: "intern",
    i18n: "account.editMyInfo.about.skills.intern"
  },
  {
    name: "contentManager",
    i18n: "account.editMyInfo.about.skills.contentManager"
  },
  {
    name: "curator",
    i18n: "account.editMyInfo.about.skills.curator"
  },
  {
    name: "driver",
    i18n: "account.editMyInfo.about.skills.driver"
  },
  {
    name: "professor",
    i18n: "account.editMyInfo.about.skills.professor"
  },
  {
    name: "socialMediaManager",
    i18n: "account.editMyInfo.about.skills.socialMediaManager"
  },
  {
    name: "artDirector",
    i18n: "account.editMyInfo.about.skills.artDirector"
  },
  // {
  //   name: "videoEditor",
  //   i18n: "account.editMyInfo.about.skills.videoEditor" //wait back end
  // },
  {
    name: "student",
    i18n: "account.editMyInfo.about.skills.student"
  },
  {
    name: "agent",
    i18n: "account.editMyInfo.about.skills.agent"
  },
  // {
  //   name: "photoManager",
  //   i18n: "account.editMyInfo.about.skills.photoManager" // wait backend
  // },
  {
    name: "photoPostProduction",
    i18n: "account.editMyInfo.about.skills.photoPostProduction"
  },
  {
    name: "director",
    i18n: "account.editMyInfo.about.skills.director"
  },
  {
    name: "sound",
    i18n: "account.editMyInfo.about.skills.sound"
  },
  {
    name: "videoPostProduction",
    i18n: "account.editMyInfo.about.skills.videoPostProduction"
  }
];

/**
 * EditMyInfo - User type section
 */

interface UserType {
  name: string;
  i18n: I18nDotSeparatedString;
}

export const UserTypes: UserType[] = [
  {
    name: "contentProducer",
    i18n: "account.editMyInfo.about.userTypes.contentProducer"
  },
  {
    name: "contentBuyer",
    i18n: "account.editMyInfo.about.userTypes.contentBuyer"
  },
  {
    name: "justWatching",
    i18n: "account.editMyInfo.about.userTypes.justWatching"
  }
];

/**
 * EditMyInfo - Colab Work section
 */
// User Equipment
interface UserEquipments {
  name: string;
  i18n: I18nDotSeparatedString;
}

export const UserEquipments: UserEquipments[] = [
  {
    name: "photoCamera",
    i18n: "account.editMyInfo.colabWork.equipment.photoCamera"
  },
  {
    name: "editingStation",
    i18n: "account.editMyInfo.colabWork.equipment.editingStation"
  },
  {
    name: "videoMonitor",
    i18n: "account.editMyInfo.colabWork.equipment.videoMonitor"
  },
  { name: "mic", i18n: "account.editMyInfo.colabWork.equipment.mic" },
  { name: "tripod", i18n: "account.editMyInfo.colabWork.equipment.tripod" },
  {
    name: "videoCamera",
    i18n: "account.editMyInfo.colabWork.equipment.videoCamera"
  },
  {
    name: "satellitePhone",
    i18n: "account.editMyInfo.colabWork.equipment.satellitePhone"
  },
  {
    name: "computer",
    i18n: "account.editMyInfo.colabWork.equipment.computer"
  },
  {
    name: "soundRecorder",
    i18n: "account.editMyInfo.colabWork.equipment.soundRecorder"
  },
  { name: "drone", i18n: "account.editMyInfo.colabWork.equipment.drone" },
  { name: "lens", i18n: "account.editMyInfo.colabWork.equipment.lens" }
];

//User Transportation
interface UserTransportation {
  name: string;
  i18n: I18nDotSeparatedString;
}

export const UserTransportations: UserTransportation[] = [
  { name: "car", i18n: "account.editMyInfo.colabWork.transportation.car" },
  { name: "truck", i18n: "account.editMyInfo.colabWork.transportation.truck" },
  {
    name: "bicycle",
    i18n: "account.editMyInfo.colabWork.transportation.bicycle"
  },
  {
    name: "motorcycle",
    i18n: "account.editMyInfo.colabWork.transportation.motorcycle"
  }
];

// User Housing
interface UserHousing {
  name: string;
  i18n: I18nDotSeparatedString;
}

export const UserHousings: UserHousing[] = [
  { name: "room", i18n: "account.editMyInfo.colabWork.housing.room" },
  { name: "sofa", i18n: "account.editMyInfo.colabWork.housing.sofa" },
  { name: "apartment", i18n: "account.editMyInfo.colabWork.housing.apartment" }
];
