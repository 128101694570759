import { DocumentsView } from "app/documents";
import { PhotosListSearch } from "app/search/documents/PhotosListSearch";
import { getRaw } from "app/search/utils";
import { AuthUserContext } from "authentication/session";
import { checkSameUser } from "authentication/utils";
import { ContentArea } from "layout";
import { Collection, ColumnItem, DocumentThumbnail } from "models";
import React, { useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { compose } from "recompose";
import { CollectionDragPhotos } from ".";
import { CollectionDetailsHeader } from "./CollectionDetailsHeader";
import { getCollection } from "./services";

interface Params {
  id: string;
}

type Props = RouteComponentProps<Params>;

const CollectionEditBase: React.FC<Props> = props => {
  const [collection, setCollection] = useState<Collection>();
  const [mode] = useState<"drag" | "edit">("edit"); // implement later with backend supporting
  const [column, setColumn] = useState<number | undefined>(undefined);
  const [showInfo, setShowInfo] = useState<boolean | undefined>(undefined);
  const [documentHeight, setDocumentHeight] = useState<ColumnItem>({
    isCollapsed: "",
    notCollapsed: "",
    titleFontSize: undefined,
    textBottomFontSize: undefined,
    ownerNameFontSize: undefined
  });

  useEffect(() => {
    const collectionId = parseInt(props.match.params.id, 10);
    getCollection(collectionId).then(collection => setCollection(collection));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (column) {
      if (column === 1) {
        setDocumentHeight({
          isCollapsed: "",
          notCollapsed: "",
          titleFontSize: undefined,
          textBottomFontSize: undefined,
          ownerNameFontSize: undefined
        });
      } else if (column === 2) {
        setDocumentHeight({
          isCollapsed: "",
          notCollapsed: "",
          titleFontSize: undefined,
          textBottomFontSize: undefined,
          ownerNameFontSize: undefined
        });
      } else if (column === 4) {
        setDocumentHeight({
          isCollapsed: "",
          notCollapsed: "",
          titleFontSize: [2, 3, 3],
          textBottomFontSize: 1,
          ownerNameFontSize: 1
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [column]);

  return (
    <ContentArea px={[2, 0]}>
      {collection && (
        <>
          <CollectionDetailsHeader
            collection={collection}
            showEditButton={true}
            onColumnChange={column => setColumn(column)}
            onShowInfoChange={showInfo => setShowInfo(showInfo)}
          />
          <AuthUserContext.Consumer>
            {({ authUser }) =>
              authUser &&
              checkSameUser(collection.uid, authUser) && (
                <PhotosListSearch<DocumentThumbnail>
                  filters={[
                    {
                      field: "collections",
                      values: [collection.id.toString()],
                      type: "any"
                    }
                  ]}
                  mapResults={(results: any[]) =>
                    results.map(result => ({
                      id: parseInt(getRaw(result, "real_id") ?? "0", 10),
                      thumbnail: getRaw(result, "thumbnail") ?? "",
                      height: parseInt(getRaw(result, "height") ?? "1", 10),
                      width: parseInt(getRaw(result, "width") ?? "1", 10),
                      owner: {
                        id: getRaw(result, "uid") ?? "",
                        name: getRaw(result, "owner") ?? ""
                      },
                      title: ((getRaw(
                        result,
                        "translations"
                      ) as unknown) as string[])?.[0]?.split("=")?.[1]
                    }))
                  }
                  view={documents => {
                    return (
                      <>
                        {mode === "drag" ? (
                          // implement later with backend supporting
                          <CollectionDragPhotos documents={documents} />
                        ) : (
                          <DocumentsView
                            documents={documents}
                            showInfoBottom={showInfo ?? true}
                            collectionId={collection.id}
                            showManageIcons={showInfo ?? true}
                            showUserInfo={false}
                            showHoverInfo={false}
                            titleFontSize={documentHeight.titleFontSize}
                            textBottomFontSize={
                              documentHeight.textBottomFontSize
                            }
                            ownerNameFontSize={documentHeight.ownerNameFontSize}
                            customMinHeight={column === 4 ? "100px" : undefined}
                            columnCount={column}
                            largeThumbnail={column === 1 ? true : false}
                          />
                        )}
                      </>
                    );
                  }}
                />
              )
            }
          </AuthUserContext.Consumer>
        </>
      )}
    </ContentArea>
  );
};

export const CollectionEdit = compose<Props, any>(withRouter)(
  CollectionEditBase
);
