import React from "react";
import Svg, { SvgProps } from "primitives/Svg";

const SvgArrowRight3 = ({ width, height, size, color }: SvgProps) => (
  <Svg
    width={size || width}
    height={size || height}
    viewBox="0 0 59 46"
    color={color}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M39.03 1.785L58.12 20.877a3 3 0 010 4.242L39.03 44.212a3 3 0 01-4.242-4.242l13.97-13.97H0v-6h48.757L34.787 6.027a3 3 0 014.242-4.243z"
    />
  </Svg>
);

SvgArrowRight3.defaultProps = {
  width: 24,
  height: 24
};
export default SvgArrowRight3;
