import { Transaction, DocumentTransaction } from "marketplace";
import React from "react";
import { Trans } from "react-i18next";
import { Box, Heading, Text } from "rebass";
import { DocumentTransactionsViewer } from "../DocumentTransactionsViewer";

interface Props {
  transaction: Transaction;
}

export const ResultOrder: React.FC<Props> = ({ transaction }) => {
  const items: DocumentTransaction[] = transaction.items.map(item => ({
    createdAt: item.createdAt,
    type: "payment",
    status: item.status,
    documentId: item.documentId,
    documentType: item.documentType,
    seller: item.seller,
    buyer: item.buyer,
    price: item.price,
    thumbnail: item.thumbnail,
    transactionId: item.transactionId,
    transactionItemId: item.id
  }));

  return (
    <Box>
      <Text textAlign="center" mb={3}>
        <Trans i18nKey="documents.resultOrder.purchasedItems" />
      </Text>
      <Box
        px={4}
        py={3}
        sx={{
          border: "1px solid #000"
        }}>
        <Heading mb={3}>
          <Trans i18nKey="documents.resultOrder.yourPurchase" />
        </Heading>
        <DocumentTransactionsViewer items={items} />
        <Text>
          <Trans i18nKey="documents.resultOrder.historyAccess" />
        </Text>
      </Box>
    </Box>
  );
};
