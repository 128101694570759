import { updateDocument } from ".";
import { UpdateDocumentResponse } from "../submitContent/models";
import { ValidationError, Document } from "models";
import { UpdateDocumentAndLicensesValidator } from "models/UpdateDocumentAndLicensesValidator";

type UpdateDocumentMethod = (
  document: Document
) => Promise<UpdateDocumentResponse>;

export class UpdateDocumentService {
  private doUpdateDocument: UpdateDocumentMethod;

  constructor(updateDocument: UpdateDocumentMethod) {
    this.doUpdateDocument = updateDocument;
  }

  static default(): UpdateDocumentService {
    return new UpdateDocumentService(updateDocument);
  }

  validateDocument(document: Document): ValidationError[] {
    return new UpdateDocumentAndLicensesValidator(document).validate();
  }

  async updateDocument(document: Document): Promise<void> {
    const errors = this.validateDocument(document);
    if (errors.length > 0) {
      console.error("Cannot update a document with errors", errors);
      throw Error("Cannot update document with errors");
    }
    await this.doUpdateDocument(document);
  }
}
