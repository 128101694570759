import { JsonObject, DocumentType } from "models";
import { isBrowserIE } from "../utils/checkBrowserType";

export type Int = number & { __int__: void };

export const roundToInt = (num: number): Int => Math.round(num) as Int;

export const toInt = (value: string): Int => {
  return Number.parseInt(value) as Int;
};

export const checkIsInt = (num: number): num is Int => num % 1 === 0;

export const assertAsInt = (num: number): Int => {
  try {
    if (checkIsInt(num)) {
      return num;
    }
  } catch (err) {
    throw new Error(`Invalid Int value (error): ${num}`);
  }

  throw new Error(`Invalid Int value: ${num}`);
};

export class MonetaryAmount {
  private locale: string;
  private amount: number;
  private currency: Currency;
  private precision: Int;
  private multiplyDecimals: Int;

  readonly IE_LOCALE = "en-US";

  constructor(amount: number, currency: Currency, precision = 2) {
    assertAsInt(precision);

    this.locale = !isBrowserIE() ? navigator.language : "";
    this.amount = +amount.toFixed(precision); //transforming to number
    this.precision = precision as Int;
    this.currency = currency;
    this.multiplyDecimals = Math.pow(10, precision) as Int;
  }

  getLocale = (): string => {
    return this.locale !== "" ? this.locale : this.IE_LOCALE;
  };

  setLocale = (locale: string): void => {
    this.locale = locale;
  };

  getAmount = (): number => {
    return this.amount;
  };

  getCurrency = (): Currency => {
    return this.currency;
  };

  getPrecision = (): Int => {
    return this.precision;
  };

  assertSameCurrency = (monetaryAmount: MonetaryAmount): boolean => {
    try {
      if (this.currency === monetaryAmount.getCurrency()) {
        return true;
      }
    } catch (err) {
      throw new Error(
        `Different currencies: ${
          this.currency
        } and ${monetaryAmount.getCurrency()}`
      );
    }

    throw new Error(
      `Different currencies: ${
        this.currency
      } and ${monetaryAmount.getCurrency()}`
    );
  };

  assertSamePrecision = (monetaryAmount: MonetaryAmount): boolean => {
    try {
      if (this.precision === monetaryAmount.getPrecision()) {
        return true;
      }
    } catch (err) {
      throw new Error(
        `Different precisions: ${
          this.precision
        } and ${monetaryAmount.getPrecision()}`
      );
    }

    throw new Error(
      `Different precisions: ${
        this.precision
      } and ${monetaryAmount.getPrecision()}`
    );
  };

  add(monetaryAmount: MonetaryAmount): MonetaryAmount {
    this.assertSamePrecision(monetaryAmount);
    this.assertSameCurrency(monetaryAmount);

    return new MonetaryAmount(
      (this.getAmount() * this.multiplyDecimals +
        monetaryAmount.getAmount() * this.multiplyDecimals) /
        this.multiplyDecimals,
      this.currency,
      this.precision
    );
  }

  subtract(monetaryAmount: MonetaryAmount): MonetaryAmount {
    this.assertSamePrecision(monetaryAmount);
    this.assertSameCurrency(monetaryAmount);

    return new MonetaryAmount(
      this.getAmount() * this.multiplyDecimals -
        monetaryAmount.getAmount() * this.multiplyDecimals,
      this.currency,
      this.precision
    );
  }

  multiply(value: number): MonetaryAmount {
    return new MonetaryAmount(
      Math.round(
        (this.getAmount() * value + Number.EPSILON) * this.multiplyDecimals
      ) / this.multiplyDecimals,
      this.getCurrency(),
      this.getPrecision()
    );
  }

  toLocaleString(): string {
    return new Intl.NumberFormat(this.getLocale(), {
      style: "currency",
      currency: this.getCurrency()
    }).format(this.getAmount());
  }
}

export type LicenseType = "creativecommons" | "royaltyfree";

export type Currency = "EUR" | "USD";

export interface Price {
  value: number;
  currency: Currency;
  precision: number;
}

export interface LicensePrice {
  license: LicenseType;
  basePrice: Price;
}

export interface DocumentLicensingOptions {
  documentId: number;
  documentType: DocumentType;
  seller: string;
  prices: LicensePrice[];
}

export interface AvailableLicenseType {
  license: LicenseType;
  sellerFee: number;
  buyerFee: number;
}

export type TransactionStatus = "pending" | "completed" | "error" | "deleted";

export type PaymentProviderName = "stripe" | "test";

export interface Transaction {
  id: number;
  createdAt: string;
  status: TransactionStatus;
  totalPrice: Price;
  buyer: string;
  items: TransactionItem[];
}

export interface TransactionItem {
  id: number;
  createdAt: string;
  status: TransactionStatus;
  transactionId: number;
  documentId: number;
  documentType: DocumentType;
  license: LicenseType;
  seller: string;
  buyer: string;
  price: Price;
  thumbnail: string;
}

export interface TransactionDocumentLicense {
  documentId: number;
  license: LicenseType;
  licenseConfiguration?: JsonObject<string>[];
}

export type PayoutStatus =
  | "registered"
  | "readyToExecute"
  | "executed"
  | "failed";

export interface DocumentTransaction {
  createdAt: string;
  type: "payment" | "payout";
  status: TransactionStatus | PayoutStatus;
  documentId: number;
  documentType: DocumentType;
  seller: string;
  buyer: string;
  price: Price;
  thumbnail: string;
  transactionId?: number;
  transactionItemId: number;
  payoutId?: number;
  fromRevenueShare?: boolean;
}

export interface MarketplaceUser {
  id: string;
  email: string;
  name: string;
  address: string;
  city: string;
  postalCode: string;
  country: string;
  vat: string;
  nationality: string;
  nationalCardNumber: string;
}

export interface TransactionItemLicense {
  transactionItem: TransactionItem;
  buyer: MarketplaceUser;
  seller: MarketplaceUser;
}
