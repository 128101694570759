import React, { useState } from "react";
import { Box } from "rebass";
import { Button } from "app/shared";
import { Label, Input } from "@rebass/forms";
import environment from "configurations";
import { Trans } from "react-i18next";
import i18next from "i18next";

export const NewsletterForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  const onChange = (callback: (value: string) => void) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    callback(event.target.value);
  };

  const onSubmit = () => {
    // Reset on submit waiting a little so values are not reset before sending it to the form action
    setTimeout(() => {
      setName("");
      setEmail("");
    }, 2000);
  };

  return (
    <Box
      as="form"
      id="mc-embedded-subscribe-form"
      name="mc-embedded-subscribe-form"
      action={environment.mailchimpNewsletterEndpoint}
      method="post"
      target="_blank"
      onSubmit={onSubmit}>
      <Box px={[3, 6]}>
        <Box backgroundColor="white" px={3} py={2}>
          <Label htmlFor="MERGE1" mb={1}>
            <Trans i18nKey="shared.newsletter.inputName" />
          </Label>
          <Input
            name="MERGE1"
            placeholder={i18next.t("shared.newsletter.inputNamePlaceholder")}
            value={name}
            onChange={onChange(setName)}
            required
          />
        </Box>
        <Box backgroundColor="white" px={3} py={2} mt={3}>
          <Label htmlFor="EMAIL" mb={1}>
            <Trans i18nKey="shared.newsletter.inputEmail" />
          </Label>
          <Input
            name="EMAIL"
            placeholder={i18next.t("shared.newsletter.inputEmailPlaceholder")}
            value={email}
            onChange={onChange(setEmail)}
            required
          />
        </Box>
      </Box>
      <Input type="hidden" name="b_dfeda9ebea80059ddcd76838f_2deb7e2552" />
      <Button
        as="button"
        fontSize={0}
        width="100%"
        variant="blue"
        mt={4}
        py={3}
        type="submit"
        disabled={name.length === 0 || email.length === 0}>
        <Trans i18nKey="shared.newsletter.submitButton" />
      </Button>
    </Box>
  );
};
