import React from "react";
import Svg, { SvgProps } from "primitives/Svg";

const SvgBell = ({ width, height, size, color }: SvgProps) => (
  <Svg
    width={size || width}
    height={size || height}
    viewBox="0 0 24 24"
    color={color}>
    <path d="M12 21.83a2 2 0 002-2h-4a2 2 0 002 2z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21 18.83H3l.413-2H4.64l-.183 1h15.076l-1.667-8.866C16 1.83 7 3.33 6.077 8.988L5.006 14.83h-1.18l1.292-6.248c1.535-7.425 12.132-7.451 13.704-.034L21 18.831z"
    />
  </Svg>
);

SvgBell.defaultProps = {
  width: 24,
  height: 24
};
export default SvgBell;
