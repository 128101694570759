import React, { ReactNode } from "react";
import { Trans } from "react-i18next";
import { Box, Text } from "rebass";
import { useMediaQueryContext } from "styles/context";

interface Props {
  children: ReactNode;
  backgroundImage?: string;
  backgroundImageAuthor?: string;
  backgroundImageAuthorPosition?: "top" | "bottom";
}

const BackgroundWrapper: React.FC<Props> = ({
  children,
  backgroundImage,
  backgroundImageAuthor,
  backgroundImageAuthorPosition
}) => {
  const { isMedium } = useMediaQueryContext();
  return (
    <Box
      sx={{
        backgroundImage: backgroundImage
          ? `url(${backgroundImage})`
          : undefined,
        backgroundColor: backgroundImage ? undefined : "#ccc", // default background if there are no images
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat"
      }}>
      <>
        {children}
        {backgroundImageAuthor && !isMedium && (
          <Text
            px={2}
            py={1}
            fontSize={0}
            backgroundColor="blue"
            color="white"
            variant="grotTextCaps"
            sx={{
              position: "absolute",
              top: backgroundImageAuthorPosition === "top" ? 3 : undefined,
              bottom:
                backgroundImageAuthorPosition === "bottom" ? 3 : undefined,
              right: 3
            }}>
            <Trans i18nKey="account.authentication.backgroundWrapper.photoBy" />{" "}
            {backgroundImageAuthor}
          </Text>
        )}
      </>
    </Box>
  );
};

BackgroundWrapper.defaultProps = {
  backgroundImageAuthorPosition: "bottom"
};

export default BackgroundWrapper;
