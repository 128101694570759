import React from "react";
import { Trans } from "react-i18next";
import { Box, Flex, Text } from "rebass";
import { Button } from "../button";
import { ActionsProps } from "./models";

export const Actions: React.FC<ActionsProps> = ({
  actions,
  selectedIds,
  defaultSelectActions,
  selectAll,
  selectNone
}) => (
  <Flex
    flexDirection="column"
    sx={{
      "& > *": { borderTop: "none !important" }
    }}>
    {defaultSelectActions && (
      <Box>
        <Button key={"select_all_action"} width="100%" onClick={selectAll}>
          <Text fontSize={[1, 2, 3]}>
            <Trans i18nKey="shared.bulk.actions.selectAll" />
          </Text>
        </Button>
        <Button
          key={"select_none_action"}
          width="100%"
          sx={{ borderTop: 0 }}
          onClick={selectNone}
          disabled={selectedIds.length === 0}>
          <Text fontSize={[1, 2, 3]}>
            <Trans i18nKey="shared.bulk.actions.selectNone" />
          </Text>
        </Button>
      </Box>
    )}
    {actions?.map(action => action(selectedIds))}
  </Flex>
);
