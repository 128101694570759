module.exports = {
  name: "development",
  url: "https://frontfiles-webapp-dev.web.app",
  api: "https://frontfiles-backend-dev-fkop366xaq-ew.a.run.app",
  firebase: {
    apiKey: "AIzaSyAoWvkmb75YUvxK6OcJAxq495dTJb0oGzI",
    authDomain: "frontfiles-dev.firebaseapp.com",
    databaseURL: "https://frontfiles-dev.firebaseio.com",
    projectId: "frontfiles-dev",
    storageBucket: "",
    messagingSenderId: "726137884218",
    appId: "1:726137884218:web:49db8d426821819028ef03",
    measurementId: "G-BY0WKLQ54F"
  },
  search: {
    searchKey: "search-qwoe4bpncjstuixufmntbrxs",
    engineName: "frontfiles-dev",
    hostIdentifier: "",
    endpointBase:
      "https://49e9a8869142430da17d0ac2cd6a73a2.app-search.europe-west1.gcp.cloud.es.io"
  },
  mailchimpNewsletterEndpoint:
    "https://frontfiles.us19.list-manage.com/subscribe/post?u=423b40aafb376b5b170904497&amp;id=b51350b72e",
  toast: {
    autoCloseTime: 5000
  },
  festivalEventBucket: 18,
  stripeKey: "pk_test_3dbaFjVIl0ym86MkFnc4Owpz00D8QboC41",
  paymentProvider: "stripe",
  payoutProvider: "paypal",
  googleMapsKey: "AIzaSyCbXQlheSpcpVeODo7EYcljRHHgCfDERyM",
  // Intentionally left blank
  featureFlags: {
    donationButtonDisabled: false,
    specialOfferDisabled: false
  }
};
