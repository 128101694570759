import { UserPicture } from "app/account";
import { FollowButton } from "app/account/FollowButton";
import { UserRelationInfo } from "app/account/models";
import { getUser } from "app/account/services";
import { BucketAddItemsButton } from "app/buckets";
import { LikeButton } from "app/documents/documentDetails/LikeButton";
import { DonationPopUp } from "app/donations/DonationPopup";
import {
  BackButton,
  Button,
  ColumnButton,
  DropdownButton,
  ElementOptions,
  FormattedDate,
  GridLabel,
  Icon,
  LocationLabel,
  Metadata,
  ShowHideInfoButton,
  Toast,
  Tooltip
} from "app/shared";
import { ReportUserButton } from "app/shared/elementOptions/ReportUserButton";
import { AuthUserContext } from "authentication/session";
import { checkSameUser } from "authentication/utils";
import environment from "configurations";
import { Collection, Privacy, User } from "models";
import React, { useEffect, useState } from "react";
import { Trans } from "react-i18next";
import { Box, BoxProps, Flex, Text } from "rebass";
import { useMediaQueryContext } from "styles/context";
import { CollectionEditPopup } from ".";
import { CollectionShareButton } from "./collectionDetails/CollectionShareButton";
import { CollectionInfo } from "./models";
import { updateCollection } from "./services";

interface Props extends BoxProps {
  collection: Collection;
  showEditButton?: boolean;
  onColumnChange?: (column: number) => void;
  onShowInfoChange?: (showInfo: boolean) => void;
}

export const CollectionDetailsHeader: React.FC<Props> = ({
  collection,
  showEditButton,
  onColumnChange,
  onShowInfoChange,
  ...rest
}) => {
  const [disabled, setDisabled] = useState<boolean>(false);
  const [currentPrivacy, setCurrentPrivacy] = useState<string>(
    collection.privacy === "public"
      ? "public"
      : collection.privacy === "private" && collection.shareWithLink === false
      ? "private"
      : collection.privacy === "private" && collection.shareWithLink === true
      ? "exclusive"
      : "private"
  );
  const [user, setUser] = useState<User | undefined>(undefined);
  const { isSmall, isSmallMedium } = useMediaQueryContext();
  const [userInfo, setUserInfo] = useState<UserRelationInfo | undefined>(
    undefined
  );
  const [collectionInfo, setCollectionInfo] = useState<
    CollectionInfo | undefined
  >(undefined);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const updatePrivacy = (privacy: Privacy, shareWithLink: boolean) => {
    setDisabled(true);
    updateCollection(
      collection.id,
      privacy,
      undefined,
      undefined,
      shareWithLink,
      undefined,
      undefined,
      undefined
    )
      .then(collection => {
        Toast.success({
          title: { key: "collections.collectionDetailsHeader.privacyToast" }
        });
        setCurrentPrivacy(
          collection.privacy === "public"
            ? "public"
            : collection.privacy === "private" &&
              collection.shareWithLink === false
            ? "private"
            : collection.privacy === "private" &&
              collection.shareWithLink === true
            ? "exclusive"
            : "private"
        );
        setDisabled(false);
      })
      .catch(error => {
        Toast.apiError(error);
        setDisabled(false);
      });
  };

  const copyClipboard = () => {
    navigator.clipboard.writeText(
      `${environment.url}/collections/${collection.id}`
    );
    Toast.success({
      title: {
        key: "shared.elementOptions.copyLinkButton.confirmationToast"
      }
    });
  };

  const loadUser = () => {
    getUser(collection.uid).then(user => setUser(user));
  };

  useEffect(() => {
    loadUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box mb={6} {...(rest as any)}>
      <BackButton i18n="magazine.backButton" mb={5} />
      {user && (
        <>
          <Flex width="100%" pt={[5, 2]} pb={3}>
            <Flex>
              <Button
                href={`/users/${user.id}`}
                width="100%"
                variant="transparent"
                bg="white"
                pr={2}
                sx={{ "&:hover": { opacity: "1 !important" } }}>
                {user.picture ? (
                  <UserPicture
                    verifiedIconSize={16}
                    userVerified="verified"
                    picture={user.picture}
                    height="35px"
                  />
                ) : (
                  <UserPicture
                    verifiedIconSize={16}
                    userVerified="verified"
                    picture={undefined}
                    height="35px"
                  />
                )}
              </Button>
            </Flex>
            {user.professionalName && (
              <Flex bg="white" alignItems="center">
                <Button href={`/users/${user.id}`}>
                  <Flex>
                    <Text
                      pl={3}
                      color="black"
                      variant="grotTextCaps"
                      sx={{ lineHeight: 1 }}
                      fontSize={2}>
                      {user.professionalName}
                    </Text>
                  </Flex>
                </Button>
              </Flex>
            )}
            <AuthUserContext.Consumer>
              {({ authUser }) => {
                const disabled =
                  !authUser ||
                  (authUser && user && checkSameUser(user.id, authUser)); //disable follow button if is the story of the user logged
                return (
                  !disabled &&
                  user && (
                    <Flex pl={10}>
                      <ElementOptions
                        closeMenuOnClick={false}
                        options={[
                          () =>
                            user.id && (
                              <>
                                <FollowButton
                                  uid={user.id}
                                  width="100%"
                                  info={userInfo}
                                  onInfoChange={userInfo =>
                                    setUserInfo(userInfo)
                                  }
                                  variant="transparent"
                                  color="black"
                                  py={2}
                                />
                                <DonationPopUp
                                  width="100%"
                                  uid={user.id}
                                  sx={{ borderLeft: 0, borderRight: 0 }}
                                />
                                <ReportUserButton
                                  userId={user.id}
                                  sx={{
                                    border: 0,
                                    textAlign: "center",
                                    color: "red"
                                  }}
                                />
                              </>
                            )
                        ]}
                        elementId={28}
                        iconName="MoreOptions"
                        iconSize={18}
                        optionsWidth={["139px", "179px"]}
                        backgroundAlwaysActive={true}
                        sx={{
                          borderRadius: 0,
                          borderRight: "none"
                        }}
                        popupSx={{
                          position: "relative",
                          top: "26px",
                          zIndex: 1,
                          left: ["-130px", "-170px"]
                        }}
                      />
                    </Flex>
                  )
                );
              }}
            </AuthUserContext.Consumer>
          </Flex>
        </>
      )}
      <Metadata
        title={collection.title}
        image={collection.documents?.[0]?.thumbnail}
      />
      <Box
        sx={{
          border: "1px solid #000"
        }}>
        <Box
          p={2}
          sx={{
            borderBottom: "1px solid #000"
          }}>
          <Flex>
            <Flex width="100%">
              <Box>
                <GridLabel i18nKey="collections.collectionDetailsHeader.collection" />
                <Text
                  fontSize={[4, 7]}
                  variant="grotText"
                  sx={{ textTransform: "uppercase", wordBreak: "break-word" }}>
                  {collection.title}
                </Text>
              </Box>
            </Flex>
            <AuthUserContext.Consumer>
              {({ authUser }) =>
                authUser &&
                checkSameUser(collection.uid, authUser) && (
                  <Flex
                    width={["10%", "20%"]}
                    justifyContent="flex-end"
                    alignItems="center">
                    <Button
                      p={[1, 2]}
                      onClick={() => setIsOpen(true)}
                      variant="black">
                      <Flex alignItems="center">
                        <Flex pr={isSmall ? 0 : 2}>
                          <Icon name="Pencil" size={isSmall ? 15 : 20} />
                        </Flex>
                        {!isSmall && (
                          <Flex>
                            <Trans i18nKey="collections.collectionEditPopup.editCollection" />
                          </Flex>
                        )}
                      </Flex>
                    </Button>
                    <CollectionEditPopup
                      collection={collection}
                      open={isOpen}
                      onOpen={() => setIsOpen(true)}
                      onClose={() => setIsOpen(false)}
                      hideTrigger={true}
                    />
                  </Flex>
                )
              }
            </AuthUserContext.Consumer>
          </Flex>
        </Box>
        {collection.description && (
          <Box
            p={2}
            sx={{
              borderBottom: "1px solid #000"
            }}>
            <Text fontSize={[3, 6]}>{collection?.description}</Text>
          </Box>
        )}
        {currentPrivacy === "exclusive" && (
          <Flex
            py={currentPrivacy === "exclusive" ? 0 : 2}
            sx={{
              borderBottom: "1px solid #000"
            }}>
            <Flex
              alignItems="center"
              width="75%"
              sx={{
                borderRight:
                  currentPrivacy === "exclusive" ? "1px solid black" : 0
              }}
              pl={2}></Flex>
            <Flex width="25%">
              <Button
                width="100%"
                height="100%"
                variant="blue"
                onClick={copyClipboard}>
                <Flex justifyContent="center" alignItems="center">
                  <Flex pr={2}>
                    <Icon name="Clipboard" size={18} />
                  </Flex>
                  <Flex>
                    <Text>
                      <Trans i18nKey="collections.collectionDetailsHeader.copyLink" />
                    </Text>
                  </Flex>
                </Flex>
              </Button>
            </Flex>
          </Flex>
        )}
        <Flex>
          <Flex
            alignItems="center"
            minWidth="25%"
            justifyContent="center"
            sx={{
              borderRight: "1px solid #000"
            }}>
            <Flex flexDirection="row" alignItems="center">
              <Text
                variant="caps"
                color="blue"
                sx={{ textDecoration: "underline" }}>
                <LocationLabel
                  fontSize={[0, 3]}
                  city={collection.city}
                  country={collection.country}
                  latitude={collection.latitude}
                  longitude={collection.longitude}
                  onClickOpenMap={true}
                />
              </Text>
            </Flex>
          </Flex>
          <Flex
            alignItems="center"
            minWidth="25%"
            justifyContent="center"
            sx={{
              borderRight: "1px solid #000"
            }}>
            <Flex flexDirection="row" alignItems="center">
              <Icon name="Photos" size={[16, 20]} />
              <Text ml={2} fontSize={[1, 3]} pl={1} pr={[1, 2]}>
                <FormattedDate format="number" date={collection.createdAt} />
              </Text>
            </Flex>
          </Flex>
          <Flex
            alignItems="center"
            minWidth="25%"
            justifyContent="center"
            sx={{
              borderRight: "1px solid #000"
            }}>
            <Flex flexDirection="row" alignItems="center">
              <Icon name="Photos" size={[16, 20]} />
              <Text
                ml={2}
                fontSize={[1, 3]}
                variant="grotTextCaps"
                pl={1}
                pr={[1, 2]}>
                {collection.totalDocuments}
              </Text>
            </Flex>
          </Flex>
          <Flex minWidth="25%">
            <AuthUserContext.Consumer>
              {({ authUser }) =>
                authUser && checkSameUser(collection.uid, authUser) ? (
                  <DropdownButton
                    titleFont={[0, 4]}
                    hideDropdownIndicator={isSmall ? true : false}
                    icon={
                      currentPrivacy === "public"
                        ? "Globe"
                        : currentPrivacy === "private"
                        ? "Lock2"
                        : "Star"
                    }
                    noBorder={true}
                    width="100%"
                    titlei18n={
                      currentPrivacy === "public"
                        ? "collections.collectionEditPopup.public"
                        : currentPrivacy === "private"
                        ? "collections.collectionEditPopup.private"
                        : currentPrivacy === "exclusive"
                        ? "collections.collectionEditPopup.exclusive"
                        : "collections.collectionEditPopup.private"
                    }
                    children={
                      <Box width="100%">
                        {currentPrivacy !== "public" && (
                          <Button
                            onClick={() => updatePrivacy("public", false)}
                            disabled={disabled}
                            width="100%">
                            <Flex justifyContent="center" alignItems="center">
                              <Flex pr={2}>
                                <Icon name="Globe" size={16} />
                              </Flex>
                              <Flex>
                                <Text>
                                  <Trans i18nKey="collections.collectionDetailsHeader.public" />
                                </Text>
                              </Flex>
                            </Flex>
                          </Button>
                        )}
                        {currentPrivacy !== "private" && (
                          <Button
                            onClick={() => updatePrivacy("private", false)}
                            disabled={disabled}
                            width="100%"
                            sx={{ borderTop: 0 }}>
                            <Flex justifyContent="center" alignItems="center">
                              <Flex pr={2}>
                                <Icon name="Lock2" size={16} />
                              </Flex>
                              <Flex>
                                <Text>
                                  <Trans i18nKey="collections.collectionDetailsHeader.private" />
                                </Text>
                              </Flex>
                            </Flex>
                          </Button>
                        )}
                        {currentPrivacy !== "exclusive" && (
                          <Button
                            onClick={() => updatePrivacy("private", true)}
                            disabled={disabled}
                            width="100%"
                            sx={{ borderTop: 0 }}>
                            <Flex justifyContent="center" alignItems="center">
                              <Flex pr={2}>
                                <Icon name="Star" size={16} />
                              </Flex>
                              <Flex pr={2}>
                                <Text>
                                  <Trans i18nKey="collections.collectionDetailsHeader.exclusive" />
                                </Text>
                              </Flex>
                            </Flex>
                          </Button>
                        )}
                      </Box>
                    }
                  />
                ) : (
                  <Flex
                    justifyContent="center"
                    py={2}
                    alignItems="center"
                    width="100%">
                    <Flex pr={2}>
                      <Icon
                        name={
                          currentPrivacy === "public"
                            ? "Globe"
                            : currentPrivacy === "private"
                            ? "Lock2"
                            : currentPrivacy === "exclusive"
                            ? "Star"
                            : "Lock2"
                        }
                        size={20}
                      />
                    </Flex>
                    <Flex>
                      <Text
                        fontSize={[1, 4]}
                        sx={{ textTransform: "uppercase" }}>
                        <Trans
                          i18nKey={
                            currentPrivacy === "public"
                              ? "collections.collectionDetailsHeader.public"
                              : currentPrivacy === "private"
                              ? "collections.collectionDetailsHeader.private"
                              : currentPrivacy === "exclusive"
                              ? "collections.collectionDetailsHeader.exclusive"
                              : "collections.collectionDetailsHeader.private"
                          }
                        />
                      </Text>
                    </Flex>
                  </Flex>
                )
              }
            </AuthUserContext.Consumer>
          </Flex>
        </Flex>
      </Box>
      <Flex mt={6} justifyContent="space-between" alignItems="center">
        <Flex>
          <Flex pr={4}>
            <AuthUserContext.Consumer>
              {({ authUser }) =>
                authUser && (
                  <BucketAddItemsButton
                    bucketItemIds={[{ type: "collection", id: collection.id }]}
                    variant="icon"
                  />
                )
              }
            </AuthUserContext.Consumer>
          </Flex>
          <Flex>
            <CollectionShareButton
              name="Share"
              variant="transparent"
              display="block"
              collection={collection}
            />
          </Flex>
          <Flex pl={4}>
            <AuthUserContext.Consumer>
              {({ authUser }) =>
                authUser &&
                collection.owner &&
                !checkSameUser(collection.owner?.id, authUser) && (
                  <>
                    <LikeButton
                      name="Like"
                      variant="icon"
                      display="block"
                      mode="collection"
                      documentId={collection.id}
                      collectionInfo={collectionInfo}
                      onInfoChange={info =>
                        setCollectionInfo(info as CollectionInfo)
                      }
                    />
                  </>
                )
              }
            </AuthUserContext.Consumer>
          </Flex>
        </Flex>
        <Flex justifyContent="flex-end" alignItems="center">
          {!isSmallMedium && (
            <ColumnButton
              mr={3}
              onColumnChange={column => onColumnChange?.(column)}
            />
          )}
          <Box mr={3}>
            <Tooltip
              href={`/magazine/${collection.id}`}
              width="100%"
              variant="link.button"
              mode="button"
              sx={{ borderColor: "black" }}
              i18nKey="magazine.articleButton">
              <Flex
                width="100%"
                height="100%"
                justifyContent="center"
                color="black"
                sx={{
                  "& svg path": {
                    stroke: "black"
                  }
                }}>
                <Icon name="ArticleIcon" size={22} />
              </Flex>
            </Tooltip>
          </Box>
          <ShowHideInfoButton
            onShowInfoChange={showInfo => onShowInfoChange?.(showInfo)}
          />
        </Flex>
      </Flex>
    </Box>
  );
};
