/* eslint-disable no-restricted-globals */
import { Button, Label, Popup, Toast } from "app/shared";
import React, { useRef } from "react";
import { Trans } from "react-i18next";
import { Box, BoxProps, Text } from "rebass";
import { Textarea } from "@rebass/forms";
import i18next from "i18next";
import { reportUser } from "../../account/services";

interface Props extends BoxProps {
  userId: string;
  open?: boolean;
  hideTrigger?: boolean;
  onClose?: () => void;
  onOpen?: () => void;
  onlyTrigger?: boolean;
  onClickTrigger?: () => void;
}

export const ReportUserButton: React.FC<Props> = ({
  userId,
  open,
  hideTrigger,
  onClose,
  onOpen,
  onlyTrigger,
  onClickTrigger,
  ...rest
}) => {
  const descriptionRef = useRef(null);

  const handleOnClick = async (close: () => void) => {
    const description = (descriptionRef.current as any).value;

    if (description.length > 15) {
      await reportUser(userId, description);
      close();
      Toast.success({
        title: {
          key: "shared.elementOptions.reportUserButton.confirmationToast"
        }
      });
    } else {
      Toast.error({
        title: {
          key: "shared.elementOptions.reportUserButton.descriptionLengthSize"
        }
      });
    }
  };

  const trigger = hideTrigger ? (
    undefined
  ) : (
    <Button width="100%" onClick={() => onClickTrigger?.()} {...(rest as any)}>
      <Text variant="grotText">
        <Trans i18nKey="shared.elementOptions.reportUserButton.report" />
      </Text>
    </Button>
  );

  return (
    <>
      {onlyTrigger ? (
        trigger
      ) : (
        <Popup
          open={open}
          onClose={() => onClose?.()}
          onOpen={() => onOpen?.()}
          heading={i18next.t("shared.elementOptions.reportUserButton.title")}
          trigger={trigger}
          submitText={i18next.t(
            "shared.elementOptions.reportUserButton.submit"
          )}
          submit={close => handleOnClick(close)}>
          <>
            <Box>
              <Label
                i18n={"shared.elementOptions.reportUserButton.descriptionLabel"}
                fontSize={1}
              />
              <Textarea onHandleChange ref={descriptionRef} name="comment" />
              <p>
                {i18next.t("shared.elementOptions.reportUserButton.disclaimer")}
              </p>
            </Box>
          </>
        </Popup>
      )}
    </>
  );
};
