import NavbarButton from "app/navbar/NavbarButton";
import { createSearchUrl } from "app/search/utils";
import { Icon, Input } from "app/shared";
import i18next from "i18next";
import debounce from "lodash.debounce";
import React, { useState } from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Box, Flex, SxStyleProp } from "rebass";
import { compose } from "recompose";
import { Action, Dispatch } from "redux";
import { FlexboxProps } from "styled-system";
import { sizes } from "styles/theme";
import * as actions from "../reducers/actions";
import { firebaseAnalytics } from "analytics/firebaseAnalytics";

interface PropsExternal extends FlexboxProps {
  onClose?: () => void;
  sx?: SxStyleProp;
}

interface Props extends PropsExternal, RouteComponentProps {
  searchTerms: string;
  searchClassName: string;
  setSearchTerms: (terms: string) => void;
  setSearchClassName: (className: string) => void;
}

const SearchBoxBase: React.FC<Props> = ({
  searchTerms,
  setSearchTerms,
  searchClassName,
  history,
  onClose,
  sx
}) => {
  const doSearch = (terms: string) => {
    const searchUrl = createSearchUrl(
      searchClassName,
      [searchClassName],
      [],
      terms
    );
    firebaseAnalytics.logEvent("search", {
      search_term: terms
    });
    history.push(searchUrl);
    onClose?.();
    setSearchTerms("");
  };

  const [focus, setFocus] = useState(false);
  const inSearchPage = history.location.pathname.includes("/search");

  const resetSearchTerms = () => {
    setSearchTerms("");
    if (inSearchPage) {
      doSearch("");
    } else {
      onClose?.();
    }
  };

  const inputError = () => {
    setFocus(true);
    debounce(() => {
      setFocus(false);
    }, 7000)();
  };

  return (
    <Box
      bg="fill.0"
      sx={{
        ...sx
      }}>
      <Flex alignItems="center">
        <Flex
          height={[
            `calc(${sizes.navbarHeight.sm} - 1px)`,
            `calc(${sizes.navbarHeight.lg} - 1px)`
          ]} // Removing border
        >
          <NavbarButton
            onClick={() => (searchTerms ? doSearch(searchTerms) : inputError())}
            px={"14px"}
            isText={true}
            flexShrink={0}
            borderRight={1}>
            <Icon name="Search" size={26} />
          </NavbarButton>
        </Flex>
        <Flex sx={{ flexGrow: 1 }}>
          <Input
            name="searchTerms"
            type="text"
            placeholder={i18next.t("search.searchBox.placeholder")}
            variant={focus ? "input.secondary.error" : "input.secondary"}
            onChange={event => {
              setSearchTerms(event.target.value);
              if (focus) {
                setFocus(false);
              }
            }}
            onBlur={() => setFocus(false)}
            value={searchTerms}
            onKeyUp={event => {
              if (event.key === "Enter") {
                searchTerms ? doSearch(searchTerms) : inputError();
              }
            }}
            sx={{ flexGrow: 1 }}
          />
          {searchTerms.length > 0 && (
            <NavbarButton
              onClick={() => resetSearchTerms()}
              px={[1, 2, 3]}
              sx={{
                borderLeft: 0,
                "&:hover": { opacity: 0.7 }
              }}>
              <Icon name="Close" size={20} />
            </NavbarButton>
          )}
        </Flex>
      </Flex>
    </Box>
  );
};

const mapStateToProps = (state: any) => ({
  searchTerms: state.search.terms,
  searchClassName: state.search.className
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  setSearchTerms: async (terms: string) =>
    dispatch(actions.setSearchTerms(terms)),
  setSearchClassName: async (className: string) =>
    dispatch(actions.setSearchClassName(className))
});

export const SearchBox = compose<Props, PropsExternal>(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(SearchBoxBase);
