import { FileDragInput } from "app/shared";
import React, { Component } from "react";

interface Props {
  disabled?: boolean;
  accept?: string;
  onFileDrop: (files: File[]) => void;
}

interface State {
  value: string;
}

export class LocalComputerUpload extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { value: "" };
  }

  private handleOnFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const files = [];

      for (let i = 0; i < event.target.files.length; i++) {
        files.push(event.target.files[i]);
      }

      this.props.onFileDrop(files);
    }
  };

  render() {
    const { disabled, accept } = this.props;
    const { value } = this.state;
    return (
      <>
        <FileDragInput
          name="files"
          accept={accept}
          onChange={e => {
            this.handleOnFileChange(e);
            this.setState({ value: "" });
          }}
          disabled={disabled}
          multiple={true}
          value={value}
        />
      </>
    );
  }
}
