import queryString from "query-string";
import { SearchFilter } from "../models";
import { Coords } from "app/shared/maps/models";

/**
 * Get field type from the raw response from appsearch.
 */
export const getFieldType = (
  result: any,
  field: string,
  type: string
): string | undefined => {
  return result[field] ? result[field][type] : undefined;
};

/**
 * Get field from the raw response from appsearch.
 */
export const getRaw = (result: any, field: string): string | undefined => {
  return getFieldType(result, field, "raw");
};

/**
 * This method returns a URL constructed from the provided filters
 * that can be used to navigate to the search page with the selected filters.
 * e.g. /search/document?size=n_40_n&filters[0][field]=documents&filters[0][type]=any&filters[0][values][0]=1
 */
export const createSearchUrl = (
  view: string,
  classNames: string[],
  filters: SearchFilter[],
  terms?: string
): string => {
  const params: any = {};
  let filterNum = 0;

  if (terms) {
    params["q"] = terms;
  }

  // Add classNames filters to params
  if (classNames.length) {
    params[`filters[${filterNum}][field]`] = "class_name";
    classNames.forEach((className, i) => {
      params[`filters[${filterNum}][values][${i}]`] = className;
    });
    params[`filters[${filterNum}][type]`] = "any";
    filterNum += 1;
  }

  // Add filters to params
  filters.forEach(filter => {
    params[`filters[${filterNum}][field]`] = filter.field;
    filter.values.forEach((value: string | number, i: number) => {
      params[`filters[${filterNum}][values][${i}]`] = value;
    });
    params[`filters[${filterNum}][type]`] = filter.type;
    filterNum += 1;
  });
  // console.log(params)

  return `/search/${view}?${queryString.stringify(params)}`;
};

export const rad = (x: number): number => {
  return (x * Math.PI) / 180;
};

export const getDistanceInKm = (p1: Coords, p2: Coords): number => {
  const R = 6378137; // Earth’s mean radius in meter
  const dLat = rad(p2.lat - p1.lat);
  const dLong = rad(p2.lng - p1.lng);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(rad(p1.lat)) *
      Math.cos(rad(p2.lat)) *
      Math.sin(dLong / 2) *
      Math.sin(dLong / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c;

  return d / 1000; // returns the distance in km
};
