import {
  ErrorBoundary,
  SearchProvider,
  WithSearch
} from "@elastic/react-search-ui";
import AppSearchAPIConnector from "@elastic/search-ui-app-search-connector";
import MultiCheckboxFacet from "app/search/filters/facets/MultiCheckboxFacet";
import environment from "configurations";
import i18next from "i18next";
import React, { PureComponent } from "react";
import { Box } from "rebass";
import { MapSearchResults } from "../locations/MapSearchResults";
import { DocumentTabs } from "../models";
import { SearchHeader, SearchLayout } from "../shared";

interface WithSearchProps {
  totalResults: number;
}

export class DocumentLocationSearch extends PureComponent {
  getConfig = () => {
    return {
      apiConnector: new AppSearchAPIConnector(environment.search),
      alwaysSearchOnInitialLoad: false,
      trackUrlState: false,
      initialState: {
        filters: [
          { field: "class_name", values: ["document", "event"], type: "any" }
        ],
        resultsPerPage: 100
      },
      searchQuery: {
        result_fields: {
          // Shared
          real_id: { raw: {} },
          class_name: { raw: {} },
          location: { raw: {} },
          city: { raw: {} },
          country: { raw: {} },
          // Documents
          thumbnail: { raw: {} },
          translations: { raw: {} },
          taken_at: { raw: {} },
          // Events
          title: { raw: {} },
          date: { raw: {} },
          documents: { raw: {} }
        },
        disjunctiveFacets: [],
        facets: {
          city: { type: "value", size: 30 },
          country: { type: "value", size: 30 }
        }
      }
    };
  };

  render() {
    return (
      <SearchProvider config={this.getConfig()}>
        <WithSearch
          mapContextToProps={({ totalResults }: WithSearchProps) => ({
            totalResults
          })}>
          {({ totalResults }: WithSearchProps) => {
            return (
              <Box>
                <ErrorBoundary>
                  <SearchLayout
                    totalResults={totalResults}
                    sideContent={
                      <>
                        <MultiCheckboxFacet
                          field="city"
                          label={i18next.t(
                            "search.documents.documentLocationSearch.city"
                          )}
                          filterType="any"
                        />
                        <MultiCheckboxFacet
                          field="country"
                          label={i18next.t(
                            "search.documents.documentLocationSearch.country"
                          )}
                          filterType="any"
                        />
                      </>
                    }
                    bodyContent={<MapSearchResults />}
                    bodyHeader={
                      <SearchHeader
                        tabs={DocumentTabs}
                        activeTabName="document-location"
                        // hideViewButton={true}
                        hideShowHideButton={true}
                      />
                    }
                  />
                </ErrorBoundary>
              </Box>
            );
          }}
        </WithSearch>
      </SearchProvider>
    );
  }
}
