import { Button } from "app/shared";
import React from "react";
import { Trans } from "react-i18next";
import { BoxProps, Text } from "rebass";
import { Icon } from "app/shared";

interface Props extends BoxProps {
  i18nKey?: string;
  onClick?: () => void;
  icon?: string;
}

const AuthButton: React.FC<Props> = ({
  i18nKey,
  onClick,
  variant,
  icon,
  size,
  ...rest
}) => (
  <Button
    width="100%"
    py={[3, "13px"]}
    variant={variant ? variant : "white"}
    onClick={onClick}
    {...(rest as any)}>
    {i18nKey && (
      <Text variant="grotTextCaps" fontSize={[1, 2, 3]}>
        <Trans i18nKey={i18nKey} />
      </Text>
    )}
    {icon && <Icon name={icon} size={size ? size : 40} />}
  </Button>
);

export default AuthButton;
