import { NamedBlob } from "models";
import React from "react";
import { Trans } from "react-i18next";
import { Box, BoxProps, Flex, Text } from "rebass";
import { Button, Icon, Label, Tooltip } from "app/shared";
import "./fileInput.css";

interface Props extends BoxProps {
  name: string;
  buttonNameI18n: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onFileRemove?: (file: NamedBlob) => void;
  errorI18n?: string;
  value?: string;
  placeholder?: string;
  disabled?: boolean;
  infoI18n?: string;
  labelI18n?: string;
  labelAlignment?: "left" | "center" | "right";
  width?: string | string[];
  multiple?: boolean;
  accept?: string;
  files?: NamedBlob[];
}

export const FileInput: React.FC<Props> = ({
  name,
  buttonNameI18n,
  onChange,
  onFileRemove,
  errorI18n,
  value,
  placeholder,
  disabled,
  infoI18n,
  labelI18n,
  labelAlignment,
  multiple,
  accept,
  files,
  ...rest
}) => {
  const inputId = `${Math.random()
    .toString(36)
    .substring(7)}`;

  return (
    <Box {...(rest as any)}>
      {(infoI18n || labelI18n) && (
        <Flex alignItems="center" pb={2}>
          {labelI18n && (
            <Label i18n={labelI18n} pb={0} sx={{ textAlign: labelAlignment }} />
          )}
          {infoI18n && <Tooltip i18nKey={infoI18n} ml={1} />}
        </Flex>
      )}
      {errorI18n && (
        <Text color="text.danger" pb={2}>
          <Trans i18nKey={errorI18n} />
        </Text>
      )}
      <input
        id={inputId}
        accept={accept}
        name={name}
        type="file"
        onChange={onChange}
        placeholder={placeholder}
        value={value}
        disabled={disabled}
        className="inputfile"
        multiple={multiple}
      />
      <label
        htmlFor={inputId}
        className={multiple && files && files.length ? "notEmpty" : undefined}>
        <Text fontSize={[1, 2, 3]} width="100%">
          <Trans i18nKey={buttonNameI18n} />
        </Text>
      </label>
      {multiple &&
        files &&
        files.map((file, index) => (
          <Flex
            key={file.name}
            p={2}
            justifyContent="space-between"
            sx={{
              borderLeft: 1,
              borderRight: 1,
              borderBottom: 1,
              borderColor: "black.0"
            }}>
            <Text sx={{ wordWrap: "break-word" }}>{file.name}</Text>
            <Button
              variant="transparent"
              onClick={() => onFileRemove?.(files[index])}>
              <Icon name="Close" size={18} />
            </Button>
          </Flex>
        ))}
    </Box>
  );
};
