import { Icon } from "app/shared";
import { Firebase, withFirebase } from "authentication/firebase";
import { withAuthorization } from "authentication/session";
import { ContentArea } from "layout";
import {
  PORTFOLIO_VERIFIED_ROLE,
  UploadTask,
  User,
  USER_VERIFIED_ROLE
} from "models";
import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Flex, Text } from "rebass";
import { compose } from "recompose";
import { getPendingTasks } from "../services";
import { FillInformation } from "./FillInformation";
import { ProcessingContent } from "./ProcessingContent";
import { ResultSubmit } from "./ResultSubmit";
import { UploadContent } from "./UploadContent";
import { Trans } from "react-i18next";
import {
  PersonalInfo,
  ReviewPortfolio,
  SkillRoles,
  PromoTools,
  Collab,
  BusinessInfo,
  VerifyAccount
} from "app/account/onboarding";
import { PortfolioReviewService } from "app/account/services/PortfolioReviewService";
import { getUser } from "app/account/services";
import { Invite, InviteType } from "app/account/models";
import queryString from "query-string";
import { SubmitContentSteps } from "./models/constants";
import Steps from "app/shared/stepper/Steps";
import i18next from "i18next";
import { Step } from "app/shared/stepper/Step";

interface Props extends RouteComponentProps {
  firebase: Firebase;
}

interface State {
  step: number;
  task?: UploadTask;
  user?: User;
  invite?: Invite;
  roles: string[];
}

class SubmitContentBase extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      step: -1,
      task: undefined,
      user: undefined,
      roles: []
    };
  }

  componentDidMount() {
    const { firebase } = this.props;

    if (firebase.isLogged()) {
      firebase.getIdToken(true).then(token => {
        const uid = firebase.getCurrentUser().uid;
        const roles = token.roles;
        this.setState({ roles }, () => {
          if (
            roles.includes(PORTFOLIO_VERIFIED_ROLE) &&
            roles.includes(USER_VERIFIED_ROLE)
          ) {
            this.refreshTask();
          } else {
            getUser(uid).then(user => {
              this.setState({ user }, () => {
                const invite = this.parseInvite();
                if (invite && invite.type === "PortfolioValidation") {
                  this.setState({ step: 3, invite }); //step 2
                } else if (invite && invite.type === "IdentityValidation") {
                  this.setState({ step: 3, invite });
                } else if (!roles.includes(PORTFOLIO_VERIFIED_ROLE)) {
                  const portfolioReviewService = new PortfolioReviewService(
                    user.id
                  );
                  portfolioReviewService
                    .getPortfolioValidationStatus()
                    .then(() => this.setState({ step: 3 })) // step 2 - portfolio exists and is submitted
                    .catch(() => {
                      if (user.status === "pendingReview") {
                        this.setState({ step: 7 });
                      } else if (user.professionalName && user.city) {
                        this.setState({ step: 3 }); //step 2
                      } else {
                        this.setState({ step: 3 }); //step 1
                      }
                    });
                }
              });
            });
          }
        });
      });
    }
  }

  parseInvite(): Invite | undefined {
    const { location } = this.props;
    const search = queryString.parse(location.search);
    const inviteType = search["inviteType"] ?? "";
    const inviteId = parseInt(search["inviteId"]?.toString() ?? "-1", 10);
    const inviteCode = search["inviteCode"]?.toString() ?? "";

    if (inviteType.length && inviteId > 0 && inviteCode.length) {
      return {
        type: inviteType as InviteType,
        id: inviteId,
        code: inviteCode
      };
    }
  }

  getSteps(step: number) {
    if (step <= 2) {
      return SubmitContentSteps.slice(0, 2);
    } else if (step <= 7) {
      return SubmitContentSteps.slice(2, 7);
    } else {
      return SubmitContentSteps.slice(7);
    }
  }

  render() {
    const { step, task, user, roles, invite } = this.state;
    const { history } = this.props;
    const submitContentSteps = this.getSteps(step);
    const selectedStep = SubmitContentSteps.find(it => it.stepNumber === step);

    return (
      <ContentArea>
        {selectedStep && selectedStep.stepNumber <= 7 && (
          <Flex flexDirection="column" alignItems="center" mb={4}>
            <Steps>
              {submitContentSteps.map((submitContentStep, index) => (
                <Step
                  key={index}
                  index={index + 1}
                  stepNumber={submitContentStep.stepNumber}
                  title={i18next.t(submitContentStep.i18n)}
                  currentStep={step}
                  totalSteps={SubmitContentSteps.length}
                  changeStep={
                    step < 8 ? step => this.setState({ step }) : undefined
                  }
                />
              ))}
            </Steps>
          </Flex>
        )}
        {step === 1 && user && (
          <PersonalInfo
            user={user}
            onComplete={user => this.setState({ step: 2, user })}
          />
        )}
        {step === 2 && user && (
          <ReviewPortfolio
            uid={user.id}
            invite={invite}
            onSkip={() => history.push("/")}
            onComplete={() => {
              this.setState({ step: 3, user });
              window.scrollTo(0, 0);
            }}
          />
        )}
        {step === 3 && user && (
          <SkillRoles
            user={user}
            onSkip={() => this.refreshTask()}
            onComplete={user => {
              this.setState({ step: 4, user });
              window.scrollTo(0, 0);
            }}
          />
        )}
        {step === 4 && user && (
          <PromoTools
            user={user}
            onSkip={() => this.refreshTask()}
            onComplete={user => {
              this.setState({ step: 5, user });
              window.scrollTo(0, 0);
            }}
          />
        )}
        {step === 5 && user && (
          <Collab
            user={user}
            onSkip={() => this.refreshTask()}
            onComplete={user => {
              this.setState({ step: 6, user });
              window.scrollTo(0, 0);
            }}
          />
        )}
        {step === 6 && user && (
          <BusinessInfo
            user={user}
            onSkip={() => this.refreshTask()}
            onComplete={user => {
              this.setState({ step: 7, user });
              window.scrollTo(0, 0);
            }}
          />
        )}
        {step === 7 && user && this.renderVerifyAccount(user, invite)}
        {step === 8 && (
          <UploadContent
            task={task}
            onComplete={task => {
              this.setState({ step: 9, task });
              window.scrollTo(0, 0);
            }}
            onUploadError={() => {
              // Refreshing the task when there is an error should allow to recover from errors
              // like timeouts on submitTask and should send the user to the correct step.
              this.refreshTask();
            }}
          />
        )}
        {step === 9 && this.renderFillInformation(task)}
        {step === 10 && task && roles && (
          <ResultSubmit
            task={task}
            userIsVerified={roles.includes(USER_VERIFIED_ROLE)}
          />
        )}
      </ContentArea>
    );
  }

  private renderFillInformation(task?: UploadTask) {
    if (task) {
      const taskIsProcessed = task.status === "processed";
      if (taskIsProcessed) {
        return (
          <FillInformation
            task={task}
            onDocumentDeleted={() => {
              this.refreshTask();
            }}
            onComplete={task => this.setState({ task, step: 10 })}
          />
        );
      } else {
        return (
          <ProcessingContent
            task={task}
            onComplete={task => this.setState({ step: 9, task })}
          />
        );
      }
    } else {
      console.error(
        "Inconsistent data received. Cannot render step 4 without a task. Rendering fallback"
      );
      return (
        <Text mb={3}>
          <Trans i18nKey="documents.submitContent.processingContent.pleaseRefresh" />
        </Text>
      );
    }
  }

  private renderVerifyAccount(user: User, invite?: Invite) {
    if (user.status === "pendingReview") {
      return (
        <>
          <Flex justifyContent="center" alignItems="center">
            <Flex color="blue" mr={[4, 3]}>
              <Icon name="Tick" size={[40, 70]} />
            </Flex>
            <Flex flexDirection="column">
              <Text fontSize={[2, 6]} sx={{ lineHeight: "40px" }}>
                <Trans i18nKey="documents.submitContent.verifyAccount.inVerificationText1" />
              </Text>
              <Text fontSize={[2, 6]} sx={{ lineHeight: "40px" }}>
                <Trans i18nKey="documents.submitContent.verifyAccount.inVerificationText2" />
              </Text>
              <Text fontSize={[2, 6]} sx={{ lineHeight: "40px" }}>
                <Trans i18nKey="documents.submitContent.verifyAccount.inVerificationText3" />
              </Text>
            </Flex>
          </Flex>
        </>
      );
    } else {
      return (
        <VerifyAccount
          user={user}
          invite={invite}
          onSkip={() => this.refreshTask()}
          onComplete={user => this.setState({ user })}
        />
      );
    }
  }

  private refreshTask() {
    getPendingTasks().then(tasks => {
      if (tasks.length > 0) {
        const task = tasks[0];
        // If there are no documents upload to current task
        if (task.status === "created") {
          this.setState({ step: 8, task });
        } else if (task.status === "processed" || task.status === "submitted") {
          this.setState({ step: 9, task });
        } else {
          this.setState({ step: 10, task });
        }
      } else {
        this.setState({ step: 8, task: undefined });
      }
    });
  }
}

export const SubmitContent = compose<Props, any>(
  withRouter,
  withFirebase,
  withAuthorization()
)(SubmitContentBase);
