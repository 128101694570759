import { Icon } from "app/shared";
import React from "react";
import { Box, Flex, Text } from "rebass";

interface Props {
  index: number;
  stepNumber: number;
  title: string;
  currentStep: number;
  totalSteps: number;
  className?: string;
  changeStep?: (step: number) => void;
}

export const Step: React.FC<Props> = ({
  index,
  stepNumber,
  title,
  currentStep,
  className,
  changeStep,
  totalSteps,
  ...rest
}) => {
  const stepOutterColor = stepNumber <= currentStep ? "black.0" : "darkGrey.0";
  const stepNumberColor =
    stepNumber < currentStep
      ? "black.0"
      : stepNumber === currentStep
      ? "white.0"
      : "darkGrey.0";
  const stepInnerColor = stepNumber === currentStep ? "black.0" : "white.0";
  const previousStep = stepNumber < currentStep;
  const itsCurrentStep = currentStep === stepNumber;
  const clickable = previousStep;

  return (
    <Flex
      justifyContent="center"
      flexWrap="wrap"
      sx={{
        borderRight: 1,
        borderTop: 1,
        borderBottom: 1,
        borderColor: stepOutterColor,
        cursor: clickable ? "pointer" : undefined,
        flexGrow: 1
      }}
      backgroundColor={stepInnerColor}
      py={[2, "10px"]}
      px={[1, 1, 1, 2]}
      onClick={
        clickable && changeStep ? () => changeStep(stepNumber) : undefined
      }
      width={1 / totalSteps}>
      <Flex alignItems="center" justifyContent="center">
        {previousStep ? (
          <Box pr={1}>
            <Icon name="Tick" size={16} color="brand.0" />
          </Box>
        ) : (
          <Text
            fontWeight={itsCurrentStep ? "bold" : undefined}
            fontSize={[0, 1, 2, 3]}
            color={stepNumberColor}
            pr={[1, 1, 2, 3]}>
            {index}
          </Text>
        )}
        <Text
          fontWeight={itsCurrentStep ? "bold" : undefined}
          fontSize={["7px", 1]}
          textAlign={["center", null]}
          color={stepNumberColor}
          variant="caps">
          {title}
        </Text>
      </Flex>
    </Flex>
  );
};
