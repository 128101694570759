import { Icon } from "app/shared";
import React, { ReactNode, useEffect, useState } from "react";
import { Box, BoxProps, Flex } from "rebass";
import { Image } from "app/shared";
import SliderSelected from "./static/SliderSelected.svg";
import SliderUnselected from "./static/SliderUnselected.svg";

interface Props extends BoxProps {
  name: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  children?: ReactNode;
  checked?: boolean;
  disabled?: boolean;
  align?: "flex-start" | "center" | "flex-end";
  direction?: "row" | "row-reverse";
  variant?: "checkbox" | "slider";
  size?: number;
}

export const Checkbox: React.FC<Props> = ({
  name,
  children,
  onChange,
  checked,
  disabled,
  align,
  direction,
  variant,
  size,
  ...rest
}) => {
  const [selected, setSelected] = useState(checked);

  const onClick = () => {
    if (!disabled) {
      const toggledSelected = !selected;
      setSelected(toggledSelected);
      onChange({
        target: {
          name: name,
          checked: toggledSelected
        }
      } as React.ChangeEvent<HTMLInputElement>);
    }
  };
  useEffect(() => {
    setSelected(checked);
  }, [selected, checked]);

  return (
    <Box
      onClick={onClick}
      sx={{
        cursor: disabled ? undefined : "pointer",
        opacity: !selected || disabled ? 0.65 : undefined
      }}
      {...(rest as any)}>
      <Flex
        flexDirection={direction}
        fontSize={[1, 2, 3, 4, 5]}
        alignItems="center"
        justifyContent={align}
        flexWrap={
          direction === "row" ? ["wrap", "nowrap"] : ["wrap-reverse", "nowrap"]
        }>
        {variant === "checkbox" ? (
          <Icon name="Square" size={size} color={selected ? "blue" : "white"} />
        ) : (
          <Image src={selected ? SliderSelected : SliderUnselected} /> // Using Image instead of Icon because it assumes the same width/height
        )}
        {children && <Box ml={[1, 2]}>{children}</Box>}
      </Flex>
    </Box>
  );
};

Checkbox.defaultProps = {
  align: "flex-start",
  direction: "row",
  variant: "checkbox",
  size: 28
};
