import { TOptions } from "i18next";
import React from "react";
import { Trans } from "react-i18next";
import { Box, Text, Flex, SxStyleProp } from "rebass";
import { Label } from "../label";
import { Tooltip } from "app/shared";
import { Select, SelectOption } from "../select";

interface Props {
  name: string;
  options: SelectOption[];
  defaultValue?: SelectOption;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  errorI18n?: string;
  errorI18nOptions?: TOptions;
  labelI18n?: string;
  labelAlignment?: "left" | "center" | "right";
  value?: SelectOption;
  disabled?: boolean;
  infoI18n?: string;
  sx?: SxStyleProp;
  labelFontSize?: string | number | string[] | number[];
}

export const SelectInput: React.FC<Props> = ({
  name,
  options,
  defaultValue,
  onChange,
  errorI18n,
  errorI18nOptions,
  labelI18n,
  labelAlignment,
  value,
  disabled,
  infoI18n,
  labelFontSize,
  ...rest
}) => {
  return (
    <Box {...rest}>
      {(infoI18n || labelI18n) && (
        <Flex alignItems="center" pb={1}>
          {labelI18n && (
            <Label
              i18n={labelI18n}
              fontSize={labelFontSize ? labelFontSize : [1, 5, 7]}
              pb={0}
              sx={{ textAlign: labelAlignment }}
            />
          )}
          {infoI18n && <Tooltip i18nKey={infoI18n} ml={1} />}
        </Flex>
      )}
      {errorI18n && (
        <Text color="text.danger">
          <Trans i18nKey={errorI18n} tOptions={errorI18nOptions} />
        </Text>
      )}
      <Select
        defaultValue={defaultValue}
        onChange={options =>
          onChange({
            target: { name, value: options.value }
          } as React.ChangeEvent<HTMLInputElement>)
        }
        options={options}
        value={value ? [value] : []}
        disabled={disabled}
        sx={{ borderBottom: "1px solid black", py: 2 }}
      />
    </Box>
  );
};
