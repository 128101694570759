import styled from "@emotion/styled";
import React, { Component, ReactNode, BaseSyntheticEvent } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  BoxProps,
  Button as RebassButton,
  Flex,
  Link as RebassLink
} from "rebass";

interface Props extends BoxProps {
  children: ReactNode;
  disabled?: boolean;
  href?: string;
  target?: string;
  onClick?: (event: BaseSyntheticEvent) => void;
  center?: boolean;
}

/**
 * Using <a /> elements for anchors because <ReactLink /> does not seem to work.
 */
const isLinkInternal = (href: string) => {
  if (href.startsWith("/") && !href.startsWith("/#")) {
    return true;
  }
  return false;
};

/**
 * Using any interface so it can receive the ...rest of the props received.
 * Vertical align fixes padding with images inside links:
 *  - https://stackoverflow.com/questions/5804256/image-inside-div-has-extra-space-below-the-image
 */
const LinkWrapper = styled(RebassLink)<Props & any>`
  &:hover {
    opacity: 0.8;
  }
  &,
  &:link,
  &:visited,
  &:active,
  &:hover {
    text-decoration: none;
  }
  cursor: ${props => !props.disabled && "pointer"};
  ${props =>
    props.disabled &&
    `
    pointer-events: none;
    opacity: 0.8;
  `}
  & > img, svg {
    display: block;
  }
`;

const ButtonWrapper = styled(RebassButton)<Props & any>`
  &:hover {
    opacity: 0.8;
  }
  outline: none;
  cursor: ${props => !props.disabled && "pointer"};
  ${props =>
    props.disabled &&
    `
    pointer-events: none;
    opacity: 0.8;
  `}
  & > img, svg {
    display: block;
  }
`;

/**
 * Using Component instead of FC to fix using Button as a trigger to reactjs-popup.
 * React.FC throws "Warning: Function components cannot be given refs": // https://github.com/yjose/reactjs-popup/issues/128
 */
export class Button extends Component<Props> {
  static defaultProps = {
    display: "inline-block"
  };

  render() {
    const {
      href,
      as,
      onClick,
      children,
      disabled,
      target,
      variant,
      center,
      ...rest
    } = this.props;

    // Render Link element
    if (href) {
      const isInternal = isLinkInternal(href);

      return (
        <LinkWrapper
          as={isInternal ? RouterLink : RebassLink}
          to={isInternal ? href : undefined}
          href={isInternal ? undefined : href}
          target={target}
          rel={target === "_blank" ? "noopener noreferrer" : ""}
          onClick={onClick}
          variant={variant ? variant : "link"}
          {...rest}>
          {children}
        </LinkWrapper>
      );
    }
    // Render Button element
    else {
      return (
        <ButtonWrapper
          variant={variant ? variant : "white"}
          onClick={!disabled && onClick ? onClick : undefined}
          disabled={disabled}
          as={as}
          {...rest}>
          {center ? (
            <Flex
              width="100%"
              justifyContent="center"
              alignItems="center"
              height="100%">
              {children}
            </Flex>
          ) : (
            children
          )}
        </ButtonWrapper>
      );
    }
  }
}
