import { DocumentView } from "app/documents";
import MasonryLayout from "app/shared/masonryLayout";
import { DocumentThumbnail } from "models";
import React from "react";

interface Props {
  documents: DocumentThumbnail[];
  columnCount?: number;
  columnGutter?: number;
  view?: (document: DocumentThumbnail) => React.ReactElement;
  onDocumentClick?: (documentId: number) => void;
  trackClickThrough?: (documentId: string) => void;
  width?: string;
  showUserInfo?: boolean;
  showManageIcons?: boolean;
  collectionId?: number;
  showInfoBottom?: boolean;
  showHoverInfo?: boolean;
  titleFontSize?: number | number[];
  textBottomFontSize?: number | number[];
  ownerNameFontSize?: number | number[];
  largeThumbnail?: boolean;
  hideLoading?: boolean;
  customMinHeight?: string | string[];
}

export const DocumentsView: React.FC<Props> = ({
  documents,
  columnCount,
  columnGutter,
  view,
  onDocumentClick,
  trackClickThrough,
  width,
  showUserInfo,
  showManageIcons,
  collectionId,
  showInfoBottom,
  showHoverInfo,
  titleFontSize,
  textBottomFontSize,
  ownerNameFontSize,
  largeThumbnail,
  hideLoading,
  customMinHeight
}) => (
  <MasonryLayout
    columns={columnCount ?? 2}
    gap={columnGutter ?? 2}
    items={documents.map(document =>
      view ? (
        view(document)
      ) : (
        <DocumentView
          key={document.id}
          document={document}
          documentsList={documents}
          onDocumentClick={onDocumentClick}
          trackClickThrough={() => trackClickThrough?.(document.id.toString())}
          width={width}
          showHoverInfo={showHoverInfo}
          showUserInfo={showUserInfo}
          showManageIcons={showManageIcons}
          collectionId={collectionId}
          showInfoBottom={showInfoBottom}
          titleFontSize={titleFontSize}
          textBottomFontSize={textBottomFontSize}
          ownerNameFontSize={ownerNameFontSize}
          largeThumbnail={largeThumbnail}
          hideLoading={hideLoading}
          customMinHeight={customMinHeight}
          customPb={!showManageIcons ? 0 : undefined}
        />
      )
    )}
    itemDimensions={documents.map(document => ({
      height: document.height ?? 0,
      width: document.width ?? 0
    }))}
  />
);

DocumentsView.defaultProps = {
  columnCount: 2,
  columnGutter: 4,
  width: "100%"
};
