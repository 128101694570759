import { BackgroundImage, Icon } from "app/shared";
import React from "react";
import { Box, BoxProps, Flex } from "rebass";
import userIcon from "../../icons/svg/User.svg";

interface Props extends BoxProps {
  picture?: string;
  userVerified?: string;
  verifiedIconSize?: number | number[];
}

// TODO: Make sure we are using square pictures or else crop it here using background image in a div
export const UserPicture: React.FC<Props> = ({
  picture,
  width,
  height,
  userVerified,
  verifiedIconSize,
  ...rest
}) => (
  <Box width={width ?? height} height={height ?? width} {...(rest as any)}>
    <Flex
      width={width ?? height}
      height={height ?? width}
      alignItems="center"
      sx={{
        position: "relative",
        display: "grid"
      }}>
      <BackgroundImage
        mode="user"
        src={picture?.length ? picture : userIcon}
        backgroundColor="#ccc"
      />
      {userVerified && userVerified === "verified" && (
        <Flex
          width="100%"
          height="100%"
          flexDirection="column"
          alignItems="flex-end"
          sx={{
            "& svg path": { fill: "blue" },
            position: "absolute",
            top: 0,
            left: 0
          }}>
          <Flex>
            <Box>
              <Icon name="UserVerifiedIcon" size={verifiedIconSize ?? 45} />
            </Box>
          </Flex>
        </Flex>
      )}
    </Flex>
  </Box>
);
