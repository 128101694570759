export interface SliderMarks<T> {
  [key: number]: T;
}

export const SliderMarks: SliderMarks<string> = {
  0: "0",
  10: "10",
  20: "20",
  30: "30",
  40: ">30"
};

export const SLIDER_MINIMUM_VALUE = 0;
export const SLIDER_MAXIMUM_VALUE = 40;
export const SLIDER_STEP = 10;
