import { NavbarMessageButton } from "app/messages";
import { SiteImageButton } from "app/shared";
import { AuthUserContext } from "authentication/session";
import React from "react";
import { Trans } from "react-i18next";
import { Flex } from "rebass";
import { CheckoutButton } from "./CheckoutButton";
import { LanguageSelect } from "./LanguageSelect";
import NavbarButton from "./NavbarButton";
import { NotificationButton } from "app/account/notifications/NotificationButton";
import { SearchDropdown } from "./SearchDropdown";
import { UserPictureMenu } from "./UserPictureMenu";
import { UserSettingsMenu } from "./UserSettingsMenu";

/**
 * flexShrink set to 0 to childs so that searchTerms is the only one that can shrink
 */
export const FullWidthNavbar: React.FC = () => (
  <Flex
    width={[1]}
    maxWidth={["maxWidth"]}
    mx="auto"
    sx={{
      "& > *": { flexShrink: 0 }
    }}>
    <Flex alignItems="center" pr={[3, 4, 5]}>
      <SiteImageButton />
    </Flex>
    <NavbarButton px={24} href="/search/collection" isText={true}>
      <Trans i18nKey="navbar.explore" />
    </NavbarButton>
    <AuthUserContext.Consumer>
      {({ authUser }) =>
        !authUser ? (
          <>
            <SearchDropdown />
            <NavbarButton px={24} href="/login" isText={true} borderRight={1}>
              <Trans i18nKey="navbar.logIn" />
            </NavbarButton>
            <CheckoutButton />
            <LanguageSelect menuMarginTop={0} sx={{ marginLeft: 3 }} />
          </>
        ) : (
          <>
            <SearchDropdown />
            <NavbarButton
              href="/submit-content"
              bg="blue"
              color="white"
              sx={{
                borderLeft: "1px solid black",
                borderRight: "1px solid black",
                "&:hover": { background: "black" }
              }}
              isText={true}
              px={[1, 2, 3]}
              flexShrink={0}
              borderRight={1}>
              <Trans i18nKey="navbar.submitContent" />
            </NavbarButton>
            <CheckoutButton />
            <NotificationButton />
            <NavbarMessageButton />
            <UserSettingsMenu />
            <UserPictureMenu />
            <LanguageSelect width={60} menuMarginTop={0} />
          </>
        )
      }
    </AuthUserContext.Consumer>
  </Flex>
);
