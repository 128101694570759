import { Textarea as RebassTextara } from "@rebass/forms";
import React from "react";
import { Box, BoxProps, Text, Flex } from "rebass";
import { Label } from "../label";
import { Trans } from "react-i18next";
import { TOptions } from "i18next";
import { Tooltip } from "app/shared";

interface Props extends BoxProps {
  name: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  errorI18n?: string;
  errorI18nOptions?: TOptions;
  labelI18n?: string;
  value?: string;
  placeholder?: string;
  disabled?: boolean;
  multiple?: boolean;
  infoI18n?: string;
  labelFontSize?: string | number | string[] | number[];
}

export const Textarea: React.FC<Props> = ({
  name,
  onChange,
  errorI18n,
  errorI18nOptions,
  labelI18n,
  value,
  placeholder,
  disabled,
  multiple,
  variant,
  infoI18n,
  labelFontSize,
  ...rest
}) => {
  return (
    <Box {...(rest as any)}>
      {(infoI18n || labelI18n) && (
        <Flex alignItems="center" pb={1}>
          {labelI18n && (
            <Label fontSize={labelFontSize ?? [1, 4]} i18n={labelI18n} pb={0} />
          )}
          {infoI18n && <Tooltip i18nKey={infoI18n} ml={1} />}
        </Flex>
      )}
      {errorI18n && (
        <Text color="text.danger">
          <Trans i18nKey={errorI18n} tOptions={errorI18nOptions} />
        </Text>
      )}
      <RebassTextara
        {...(rest as any)}
        id={`${name}-${Math.random()
          .toString(36)
          .substring(7)}`} // Appending random string at the end so ids are unique
        name={name}
        onChange={onChange}
        placeholder={placeholder}
        value={value}
        disabled={disabled}
        multiple={multiple}
        variant={variant}
      />
    </Box>
  );
};

Textarea.defaultProps = { variant: "textarea" };
