import React from "react";
import Svg, { SvgProps } from "primitives/Svg";

const SvgLetterF = ({ width, height, size, color }: SvgProps) => (
  <Svg
    width={size || width}
    height={size || height}
    viewBox="0 0 33 43"
    color={color}>
    <path d="M32.202 0H.796a.724.724 0 00-.114.035.657.657 0 00-.294.127C.344.192.302.223.263.258A.746.746 0 00.17.38a1.097 1.097 0 00-.09.134.737.737 0 00-.043.152.667.667 0 00-.036.12.728.728 0 000 .089V42.12a.787.787 0 000 .092.875.875 0 00.29.562A.899.899 0 00.89 43h7.058c.22 0 .433-.08.597-.225a.875.875 0 00.29-.562.794.794 0 000-.092V25.862h23.364a.895.895 0 00.573-.287.872.872 0 00.229-.592v-6.955a.876.876 0 00-.23-.59.9.9 0 00-.572-.286H8.835V8.724h23.36a.896.896 0 00.572-.285.872.872 0 00.23-.59V.893a.875.875 0 00-.223-.598.9.9 0 00-.572-.295z" />
  </Svg>
);

SvgLetterF.defaultProps = {
  width: 24,
  height: 24
};
export default SvgLetterF;
