import * as type from "./constants";

export function setSearchTerms(terms: string) {
  return {
    terms,
    type: type.SET_SEARCH_TERMS
  };
}

export function setSearchClassName(className: string) {
  return {
    className,
    type: type.SET_SEARCH_CLASSNAME
  };
}
