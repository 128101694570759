import { ResultsCounter } from "app/search/shared/ResultsCounter";
import { Tabs } from "app/shared";
import { AuthUserContext } from "authentication/session";
import React, { ReactNode, useEffect, useState } from "react";
import { Trans } from "react-i18next";
import { Box } from "rebass";
import { ColumnItem } from "../../../models";
import { ProfileTab } from "./models";
import { UserFollows } from "./UserFollows";
import { UserFollowsCounter } from "./UserFollowsCounter";

interface Props {
  uid: string;
  isCollapsed?: boolean;
  column?: number;
}

/**
 * Renders provided tab for the provided user uid.
 */
const connectionsTabLabelRender = (tab: ProfileTab, uid: string): ReactNode => {
  switch (tab.type) {
    case "ResultsCounter":
      return (
        <>
          <Trans i18nKey={tab.i18n} />{" "}
          <ResultsCounter filters={tab.filters ?? []} />
        </>
      );
    case "UserFollowsCounter":
      return (
        <>
          <Trans i18nKey={tab.i18n} />{" "}
          <UserFollowsCounter uid={uid} mode={tab.mode ?? "followers"} />
        </>
      );
    default:
      return <Trans i18nKey={tab.i18n} />;
  }
};

export const Connections: React.FC<Props> = ({ uid, isCollapsed, column }) => {
  const [mode, setMode] = useState("followers");

  interface ExtendedColumnItem extends ColumnItem {
    textButtonFontSize?: number | number[];
    userPictureSize?: string | string[];
  }
  const [userHeight, setUserHeight] = useState<ExtendedColumnItem>({
    isCollapsed: "225px",
    notCollapsed: "145px",
    titleFontSize: isCollapsed ? undefined : 1,
    textBottomFontSize: isCollapsed ? undefined : 0,
    textButtonFontSize: isCollapsed ? undefined : 1,
    userPictureSize: undefined
  });

  useEffect(() => {
    if (column) {
      if (column === 1) {
        setUserHeight({
          isCollapsed: "825px",
          notCollapsed: "570px",
          titleFontSize: 6,
          textBottomFontSize: 5,
          textButtonFontSize: undefined,
          userPictureSize: "85px"
        });
      } else if (column === 2) {
        setUserHeight({
          isCollapsed: "425px",
          notCollapsed: "270px",
          titleFontSize: 5,
          textBottomFontSize: 4,
          textButtonFontSize: undefined,
          userPictureSize: "85px"
        });
      } else if (column === 4) {
        setUserHeight({
          isCollapsed: "225px",
          notCollapsed: "145px",
          titleFontSize: isCollapsed ? undefined : 1,
          textBottomFontSize: isCollapsed ? undefined : 0,
          textButtonFontSize: isCollapsed ? undefined : 1,
          userPictureSize: undefined
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [column, isCollapsed]);

  return (
    <AuthUserContext.Consumer>
      {() => {
        const tabs: ProfileTab[] = [
          {
            name: "followers",
            type: "UserFollowsCounter",
            mode: "followers",
            i18n: "account.profile.profileTabsButtons.followers",
            href: `/users/${uid}/connections/followers`
          },
          {
            name: "following",
            type: "UserFollowsCounter",
            mode: "following",
            i18n: "account.profile.profileTabsButtons.following",
            href: `/users/${uid}/connections/following`
          }
        ];
        return (
          <Box>
            <Tabs
              key={uid} // Using key={user.uid} to force refresh when user changes
              my={4}
              activeName={mode}
              tabs={tabs.map(tab => ({
                name: tab.name,
                label: connectionsTabLabelRender(tab, uid),
                onClick: () => setMode(tab.name)
              }))}
              wrapTabs={false}
            />
            {mode === "followers" && (
              <UserFollows
                isCollapsed={isCollapsed}
                uid={uid}
                mode="followers"
                column={column}
                userHeight={userHeight}
              />
            )}
            {mode === "following" && (
              <UserFollows
                isCollapsed={isCollapsed}
                uid={uid}
                mode="following"
                column={column}
                userHeight={userHeight}
              />
            )}
          </Box>
        );
      }}
    </AuthUserContext.Consumer>
  );
};
