import React from "react";
import { Box, Flex, Text } from "rebass";
import { Icon } from "app/shared";

interface Props {
  iconName: string;
  counter: number;
  size?: number;
}

export const NavbarIconCounter: React.FC<Props> = ({
  iconName,
  counter,
  size
}) => (
  <Flex
    height="100%"
    alignItems="center"
    sx={{
      position: "relative"
    }}>
    <Box px={[1, 2, 3]}>
      <Icon name={iconName} size={size ? size : 24} />
    </Box>
    {counter > 0 && (
      <Text
        color="blue"
        textAlign="right"
        fontSize={[4, 5, 6]}
        sx={{
          position: "absolute",
          bottom: 1,
          right: 2
        }}>
        {counter > 99 ? "99+" : counter}
      </Text>
    )}
  </Flex>
);
