import { CachedPagination, PageEmptyState } from "app/shared";
import { AuthUserContext } from "authentication/session";
import { checkSameUser } from "authentication/utils";
import React, { Component } from "react";
import { Trans } from "react-i18next";
import { ColumnItem } from "../../../models";
import { RelationUser } from "../models";
import { getFollowers, getFollowing } from "../services";
import { UsersView } from "./UsersView";
import { UserView } from "./UserView";

interface Props {
  uid: string;
  mode: "followers" | "following";
  isCollapsed?: boolean;
  column?: number;
  userHeight: ExtendedColumnItem;
}

interface ExtendedColumnItem extends ColumnItem {
  textButtonFontSize?: number | number[];
  userPictureSize?: string | string[];
}

interface State {
  disabled: boolean;
  first: boolean;
  last: boolean;
  page: number;
  size: number;
  totalElements: number;
  totalPages: number;
  list: RelationUser[];
}

export class UserFollows extends Component<Props, State> {
  state = {
    // control
    disabled: false,
    list: [],
    // page variables
    first: true,
    last: false,
    page: 0,
    size: 10,
    totalElements: 1,
    totalPages: 1
  };

  getFollows(page: number, size: number) {
    const { uid, mode } = this.props;

    this.setState({ disabled: true }, () => {
      const call =
        mode === "followers"
          ? getFollowers(uid, page, size)
          : getFollowing(uid, page, size);
      call.then(({ data, page, size, totalElements, totalPages }) => {
        this.setState({
          disabled: false,
          list: data,
          page,
          size,
          totalElements,
          totalPages
        });
      });
    });
  }

  componentDidMount() {
    this.getFollows(0, this.state.size);
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.uid !== prevProps.uid) {
      this.getFollows(0, this.state.size);
    }
  }

  render() {
    const { uid, mode, isCollapsed, column, userHeight } = this.props;
    const { list, disabled, page, size, totalPages } = this.state;

    if (list.length) {
      return (
        <AuthUserContext.Consumer>
          {({ authUser }) => (
            <CachedPagination<RelationUser>
              disabled={disabled}
              results={list}
              page={page}
              totalPages={totalPages}
              setPage={page => this.getFollows(page, size)}>
              {users => (
                <UsersView column={column}>
                  {/* If viewing own user and tab is following assume that follow=true */}
                  {users.map(user => (
                    <UserView
                      key={user.id}
                      user={user}
                      height={
                        userHeight
                          ? isCollapsed
                            ? userHeight.isCollapsed
                            : ["225px", userHeight.notCollapsed]
                          : "225px"
                      }
                      isCollapsed={isCollapsed}
                      titleFontSize={userHeight.titleFontSize}
                      textBottomFontSize={userHeight.textBottomFontSize}
                      textButtonFontSize={userHeight.textButtonFontSize}
                      userPictureSize={userHeight.userPictureSize}
                      largeThumbnail={column === 1 ? true : undefined}
                      customPaddingTopFollowButton={
                        column === 4 && !isCollapsed ? "12px" : undefined
                      } //to follow message and donate buttons padding
                      customPaddingBottomFollowButton={
                        column === 4 && !isCollapsed ? "11px" : undefined
                      } //to follow message and donate buttons padding
                      info={
                        mode === "following" &&
                        authUser &&
                        checkSameUser(uid, authUser)
                          ? { follow: true, view: true }
                          : undefined
                      }
                    />
                  ))}
                </UsersView>
              )}
            </CachedPagination>
          )}
        </AuthUserContext.Consumer>
      );
    } else {
      return (
        <PageEmptyState
          loading={disabled}
          header={<Trans i18nKey="account.userFollows.noUsers" />}
        />
      );
    }
  }
}
