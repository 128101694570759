import { UserPicture } from "app/account";
import { Button, ElementOptions, Toast } from "app/shared";
import { PopupButton } from "app/shared/elementOptions/PopupButton";
import i18next from "i18next";
import MessageService, { Chat } from "messaging/MessageService";
import { AuthUser } from "models";
import React, { PureComponent } from "react";
import { Flex, Text } from "rebass";

interface Props {
  chat: Chat;
  deselectChat: () => void;
  messageService: MessageService;
  authUser?: AuthUser;
}

export class ChatHeader extends PureComponent<Props> {
  deleteSelectedChat = async (close: () => void) => {
    const { messageService, chat, deselectChat } = this.props;

    deselectChat();
    await messageService.deleteChat(chat.id);
    Toast.success({ title: { key: "messages.deleteConfirmationToast" } });

    close();
  };

  block = async (close: () => void) => {
    const { messageService, chat, deselectChat } = this.props;

    deselectChat();
    await messageService.blockUser(chat.userMetadata.id);
    Toast.success({
      title: {
        key: "messages.blockConfirmationToast",
        options: {
          name: chat.userMetadata.displayName
        }
      },
      button: {
        title: { key: "messages.manage" },
        href: "/settings/account"
      }
    });

    close();
  };

  render() {
    const { chat } = this.props;
    return (
      <Flex
        alignItems={"center"}
        sx={{
          borderBottom: 1,
          borderColor: "black.0"
        }}
        p={2}>
        <Button href={`/users/${chat?.userMetadata.id}`}>
          <UserPicture
            picture={chat?.userMetadata.photoUrl ?? undefined}
            height={["56px", "72px"]}
          />
        </Button>
        <Flex
          sx={{ flexGrow: 1 }}
          justifyContent="space-between"
          alignItems="center">
          <Button href={`/users/${chat?.userMetadata.id}`} pl={[1, 3, 5]}>
            <Text fontSize={[2, 4, 7]} variant="caps">
              {chat?.userMetadata.displayName}
            </Text>
          </Button>
          <ElementOptions
            options={[
              () => (
                <PopupButton
                  sx={{ border: 0 }}
                  onClickButton={this.deleteSelectedChat}
                  headingText={i18next.t("messages.deletePopupHeader")}
                  buttonText={i18next.t("messages.deletePopupSubmit")}
                  submitText={i18next.t("messages.deleteSubmitText")}
                  popupText={i18next.t("messages.deletePopupText")}
                />
              ),
              () => (
                <PopupButton
                  sx={{ border: 0 }}
                  onClickButton={this.block}
                  headingText={i18next.t("messages.blockPopupHeader")}
                  buttonText={i18next.t("messages.blockPopupSubmit")}
                  submitText={i18next.t("messages.blockSubmitText")}
                  popupText={i18next.t("messages.blockPopupText")}
                />
              )
            ]}
            elementId={chat?.id}
            iconName="VerticalDots"
            iconSize={24}
            iconColor="white.0"
            closeMenuOnClick={false}
            optionsWidth={["120px", "179px"]}
            sx={{ padding: 0 }}
            popupSx={{
              position: "relative",
              top: "30px",
              left: ["-119px", "-178px"]
            }}
          />
        </Flex>
      </Flex>
    );
  }
}
