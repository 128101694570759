import React from "react";
import Svg, { SvgProps } from "primitives/Svg";

const SvgArrowDown = ({ width, height, size, color }: SvgProps) => (
  <Svg
    width={size || width}
    height={size || height}
    viewBox="0 0 24 24"
    color={color}>
    <path d="M12 20V4m0 16l-6-5m6 5l6-5" stroke="#000" />
  </Svg>
);

SvgArrowDown.defaultProps = {
  width: 24,
  height: 24
};
export default SvgArrowDown;
