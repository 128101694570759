import React, { useEffect, useState } from "react";
import { Trans } from "react-i18next";
import { Box, Flex, Text } from "rebass";
import { useMediaQueryContext } from "styles/context";
import { Button } from "app/shared";
import { BucketView } from "../../buckets";
import { Bucket } from "../../../models";
import { getBucket } from "../../buckets/services";
import environment from "../../../configurations";

interface Props {
  hideButton?: boolean;
}

export const FrontTalksCardFestival: React.FC<Props> = ({ hideButton }) => {
  const { isSmallMedium } = useMediaQueryContext();
  const [bucket, setBucket] = useState<Bucket>();

  const loadBucket = () => {
    const bucketId = environment.festivalEventBucket;
    getBucket(bucketId).then(bucket => setBucket(bucket));
  };

  useEffect(() => {
    loadBucket();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {bucket && (
        <Box sx={{ position: "relative" }} mt={6}>
          <Box width="100%" height="100%">
            <BucketView
              bucket={bucket}
              height="681px"
              largeThumbnail={true}
              showInfoBottom={false}
              forceShowOnHover={false}
              autoplay={true}
              hideNavigation={true}
              hideBullets={true}
              href="/fronttalks/brazil/festival"
            />
          </Box>
          <Flex>
            <Flex
              width="100%"
              alignItems="start"
              justifyContent="flex-start"
              pl={[3, 6]}>
              <Box
                sx={{
                  zIndex: 0,
                  position: "absolute",
                  top: ["35%", "60%"],
                  "& svg path": { fill: "blue" }
                }}>
                <Box mt={3}>
                  <Box p={2} sx={{ background: "rgba(0, 0, 0, .5)" }}>
                    <Box px={[0, 3]}>
                      <Text pb={2} color="white" variant="grotText">
                        <Trans i18nKey="events.festival.card.textTop" />
                      </Text>
                      <Text
                        pb={2}
                        fontSize={8}
                        color="white"
                        variant="grotText">
                        <Trans i18nKey="events.festival.card.title" />
                      </Text>
                      <Text pb={2} color="white">
                        <Trans i18nKey="events.festival.card.location" />
                      </Text>
                    </Box>
                  </Box>
                  <Box
                    mr={[0, 6]}
                    py={1}
                    px={2}
                    mt={3}
                    sx={{ background: "rgba(0, 0, 0, .5)" }}>
                    <Box px={[0, 6]}>
                      <Button
                        variant="transparent"
                        href="https://www.fotografiaemtempoeafeto.com/convocatória-edição-v"
                        target="_blank">
                        <Text pb={2} color="white" variant="grotText">
                          www.fotografiaemtempoeafeto.com/convocatória-edição-v
                        </Text>
                      </Button>
                      <Text pb={2} color="white" variant="grotText">
                        <Trans i18nKey="events.festival.card.site2" />
                      </Text>
                    </Box>
                  </Box>
                </Box>
                {isSmallMedium && !hideButton && (
                  <Box width="100%" mt={3} pr={[3, 6]}>
                    <Button
                      width="100%"
                      variant="link.button.blue"
                      href="/fronttalks/brazil/festival"
                      target="_blank">
                      <Trans i18nKey="events.festival.card.infoButton" />
                    </Button>
                  </Box>
                )}
              </Box>
            </Flex>
            {!isSmallMedium && !hideButton && (
              <Flex
                width="100%"
                alignItems="start"
                justifyContent="flex-end"
                pr={6}>
                <Box
                  sx={{
                    zIndex: 0,
                    position: "absolute",
                    top: "90%",
                    "& svg path": { fill: "blue" }
                  }}>
                  <Box width="100%">
                    <Button
                      px={7}
                      variant="link.button.blue"
                      href="/fronttalks/brazil/festival"
                      target="_blank">
                      <Trans i18nKey="events.festival.card.infoButton" />
                    </Button>
                  </Box>
                </Box>
              </Flex>
            )}
          </Flex>
        </Box>
      )}
    </>
  );
};
