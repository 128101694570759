import { BucketItem } from "app/buckets/models";
import { LatestItem } from "app/home/models";
import {
  Bucket,
  Collection,
  ExtendedDocumentThumbnail,
  Privacy,
  UserThumbnail
} from "models";
import { getRaw } from ".";

export const mapDocumentResults = (
  results: any[]
): ExtendedDocumentThumbnail[] => {
  return results.map(result => ({
    id: parseInt(getRaw(result, "real_id") ?? "0", 10),
    uid: getRaw(result, "uid") ?? "",
    thumbnail: getRaw(result, "thumbnail") ?? "",
    original: getRaw(result, "thumbnail_large") ?? "",
    width: parseInt(getRaw(result, "width") ?? "1", 10),
    height: parseInt(getRaw(result, "height") ?? "1", 10),
    city: getRaw(result, "city") ?? "",
    country: getRaw(result, "country") ?? "",
    takenAt: getRaw(result, "taken_at") ?? "",
    owner: {
      id: getRaw(result, "uid") ?? "",
      name: getRaw(result, "owner") ?? "",
      picture: getRaw(result, "owner_picture") ?? ""
    },
    title: ((getRaw(
      result,
      "translations"
    ) as unknown) as string[])?.[0]?.split("=")?.[1]
  }));
};

export const mapCollectionResults = (results: any[]): Collection[] => {
  return results.map(result => ({
    id: parseInt(getRaw(result, "real_id") ?? "0", 10),
    createdAt: getRaw(result, "created_at") ?? "",
    privacy: (getRaw(result, "privacy") ?? "private") as Privacy,
    uid: getRaw(result, "uid") ?? "",
    owner: {
      id: getRaw(result, "uid") ?? "",
      name: getRaw(result, "owner") ?? "",
      picture: getRaw(result, "owner_picture") ?? ""
    },
    title: getRaw(result, "title") ?? "",
    description: getRaw(result, "description") ?? "",
    shareWithLink: getRaw(result, "shareWithLink") === "true" ?? false,
    city: getRaw(result, "city") ?? "",
    country: getRaw(result, "country") ?? "",
    totalDocuments: parseInt(getRaw(result, "total_documents") ?? "0", 10),
    documents: ((getRaw(result, "documents") || []) as string[])?.map(it => {
      const split = it.split("=");
      return {
        id: parseInt(split[0], 10),
        thumbnail: split[1]
      };
    })
  }));
};

export const mapBucketResults = (results: any[]): Bucket[] => {
  return results.map(result => ({
    id: parseInt(getRaw(result, "real_id") ?? "0", 10),
    createdAt: getRaw(result, "created_at") ?? "",
    privacy: (getRaw(result, "privacy") ?? "private") as Privacy,
    uid: getRaw(result, "uid") ?? "",
    owner: {
      id: getRaw(result, "uid") ?? "",
      name: getRaw(result, "owner") ?? "",
      picture: getRaw(result, "owner_picture") ?? ""
    },
    title: getRaw(result, "title") ?? "",
    totalDocuments: parseInt(getRaw(result, "total_documents") ?? "0", 10),
    totalCollections: parseInt(getRaw(result, "total_collections") ?? "0", 10),
    totalItems: parseInt(getRaw(result, "total_items") ?? "0", 10),
    documents: ((getRaw(result, "documents") || []) as string[])?.map(it => {
      const split = it.split("=");
      return {
        id: parseInt(split[0], 10),
        thumbnail: split[1]
      };
    })
  }));
};

export const mapDocumentItemResults = (results: any[]) => {
  return results.map(result => {
    const className = getRaw(result, "class_name") ?? "";
    if (className === "document") {
      return mapDocumentResults([result])[0];
    } else {
      return undefined;
    }
  });
};

export const mapBucketItemResults = (results: any[]): BucketItem[] => {
  return results.map(result => {
    const className = getRaw(result, "class_name") ?? "";
    if (className === "document") {
      return mapDocumentResults([result])[0];
    } else {
      return mapCollectionResults([result])[0];
    }
  });
};

export const mapLatestItemResults = (results: any[]): LatestItem[] => {
  return results.map(result => {
    const className = getRaw(result, "class_name") ?? "";
    if (className === "document") {
      return { ...mapDocumentResults([result])[0], className: "document" };
    } else if (className === "collection") {
      return { ...mapCollectionResults([result])[0], className: "collection" };
    } else {
      return { ...mapBucketResults([result])[0], className: "bucket" };
    }
  });
};

export const mapUserResults = (results: any[]): UserThumbnail[] => {
  return results.map(result => ({
    id: getRaw(result, "real_id") ?? "",
    picture: getRaw(result, "picture"),
    name: getRaw(result, "professional_name") ?? "",
    city: getRaw(result, "city"),
    country: getRaw(result, "country"),
    status: getRaw(result, "status")
  }));
};
