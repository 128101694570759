/* eslint-disable react-hooks/exhaustive-deps */
import OutsideClickWrapper from "app/shared/wrapper/OutsideClickWrapper";
import { User } from "models";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { compose } from "recompose";
import NavbarButton from "./NavbarButton";
import { UserMenu } from "./UserMenu";
import { NavbarIconCounter } from "app/shared";

interface Props extends RouteComponentProps {
  user: User;
}

const UserSettingsMenuBase: React.FC<Props> = ({ history, user }) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    history.listen(() => {
      open && setOpen(false);
    });
  });

  const uid = user.id ?? "";
  const displayName = user.professionalName ?? "Professional Name";
  const picture = user.picture;

  return (
    <OutsideClickWrapper isOpen={open} callback={() => setOpen(false)}>
      <NavbarButton
        height="100%"
        onClick={() => setOpen(!open)}
        sx={{
          "& svg path": { fill: "white", stroke: "black" },
          borderLeft: 1,
          borderColor: "black.0",
          flexGrow: 1,
          "&:hover": {
            background: "blue.0",
            color: "white.0",
            "& svg path": { fill: "transparent", stroke: "white" }
          }
        }}>
        <NavbarIconCounter counter={0} iconName="Settings2" />
      </NavbarButton>
      {open && (
        <UserMenu
          user={{ id: uid, professionalName: displayName, picture } as User}
        />
      )}
    </OutsideClickWrapper>
  );
};

const mapStateToProps = ({ loggedInUser }: User) => ({
  user: loggedInUser
});

export const UserSettingsMenu = compose<Props, any>(
  connect(mapStateToProps),
  withRouter
)(UserSettingsMenuBase);
