import React from "react";
import Svg, { SvgProps } from "primitives/Svg";

const SvgFingerprint = ({ width, height, size, color }: SvgProps) => (
  <Svg
    width={size || width}
    height={size || height}
    viewBox="0 0 16 16"
    color={color}>
    <path d="M13.213 1.89C11.628 1.12 9.836.722 8.003.722c-1.834 0-3.64.398-5.238 1.168l-.414-.614A12.97 12.97 0 018.003 0c1.984 0 3.914.445 5.623 1.276l-.413.614z" />
    <path d="M.118 5.61C1.91 3.275 4.832 1.878 8.003 1.878c3.225 0 6.147 1.397 7.87 3.732l-.703.373C13.6 3.854 10.94 2.589 8.003 2.589c-2.881 0-5.555 1.265-7.196 3.408L.118 5.61z" />
    <path d="M5.783 15.627c-1.723-1.614-2.77-3.66-2.77-5.382 0-2.636 2.15-4.623 5.003-4.623 2.675 0 4.7 1.686 5.1 4.226l-.813.072c-.344-2.155-2.04-3.587-4.273-3.587-2.384 0-4.176 1.673-4.176 3.9 0 1.541.965 3.395 2.55 4.876l-.62.518z" />
    <path d="M12.496 14.326a5.226 5.226 0 01-1.737-.289 5.096 5.096 0 01-2.178-1.48c-.523-.626-.868-1.397-1.088-2.396l.813-.12c.193.878.496 1.553.937 2.082.455.542 1.117 1 1.82 1.24a4.4 4.4 0 002.219.169l.22.698c-.33.06-.661.096-1.006.096z" />
    <path d="M10.856 16c-1.406-.265-2.081-.518-2.812-1.035C6.39 13.797 5.218 11.75 5.246 10.1c.027-1.505.95-2.432 2.536-2.528.069 0 .152-.013.22-.013 1.462 0 2.44.928 2.757 2.613.166.843.91 1.433 1.834 1.433 1.171 0 1.833-.494 1.86-1.373.056-1.457-.592-2.925-1.805-4.02-1.24-1.12-2.895-1.747-4.66-1.747-3.597 0-6.298 2.48-6.298 5.78 0 .998.206 1.913.62 2.66l-.73.337c-.47-.843-.704-1.878-.704-2.998 0-3.648 3.13-6.489 7.113-6.489 1.999 0 3.86.698 5.252 1.962 1.364 1.24 2.109 2.89 2.04 4.54-.056 1.275-1.048 2.058-2.647 2.082-1.378 0-2.467-.843-2.688-2.035-.262-1.348-.896-2.01-1.957-2.01h-.152c-.8.036-1.764.397-1.792 1.818-.027 1.444 1.034 3.25 2.495 4.298.62.433 1.213.65 2.468.89l-.152.699z" />
  </Svg>
);

SvgFingerprint.defaultProps = {
  width: 24,
  height: 24
};
export default SvgFingerprint;
