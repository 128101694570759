import React, { Component } from "react";
import { GooglePlace } from "./models";
import { Input } from "@rebass/forms";
import debounce from "lodash.debounce";

interface Props {
  onChange: (places: GooglePlace[]) => void;
  maps: any; // google maps API dependency
  value?: string;
  placeholder?: string;
  disabled?: boolean;
  variant?: string;
}

/**
 * https://github.com/google-map-react/google-map-react/issues/460
 */
export class GoogleSearchBox extends Component<Props, any> {
  searchInputRef: React.RefObject<any>;
  searchBox: any;

  static defaultProps = {
    variant: "input"
  };

  constructor(props: Props) {
    super(props);

    this.searchInputRef = React.createRef();

    this.createSearchBox();
  }

  /**
   * Create SearchBox when maps api is provided.
   */
  componentDidUpdate() {
    this.createSearchBox();
    this.onPropValueChange();
  }

  /**
   * Clear input on unmount.
   */
  componentWillUnmount() {
    const { maps } = this.props;

    if (this.searchBox) {
      maps.event?.clearInstanceListeners?.(this.searchBox);
    }
  }

  /**
   * To re-use the component after componentWillUnmount is called a timeout is need before creating a new SearchBox.
   */
  createSearchBox() {
    const { maps } = this.props;

    if (maps?.places?.SearchBox && !this.searchBox) {
      debounce(() => {
        this.searchBox = new maps.places.SearchBox(this.searchInputRef.current);
        this.searchBox.addListener(
          "places_changed",
          this.onPlacesChanged.bind(this)
        );
      }, 500)();
    }
  }

  /**
   * Update input value if prop changed
   */
  onPropValueChange() {
    const currentValue = this.searchInputRef.current.value;

    if (currentValue !== this.props.value) {
      const newValue = this.props.value ?? "";
      this.searchInputRef.current.value = newValue;
    }
  }

  /**
   * Call onChange if places changed
   */
  onPlacesChanged() {
    if (this.searchBox?.getPlaces) {
      this.props.onChange(this.searchBox.getPlaces());
    }
  }

  render() {
    const { disabled, maps, value, placeholder, variant } = this.props;

    return (
      <Input
        autoComplete="off"
        width="100%"
        type="text"
        placeholder={placeholder}
        ref={this.searchInputRef}
        defaultValue={value}
        disabled={disabled || !maps}
        variant={variant}
      />
    );
  }
}
