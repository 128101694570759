import { MobileSearchBox } from "app/search/shared/mobile/MobileSearchBox";
import { Icon } from "app/shared";
import React, { useState } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { useMediaQueryContext } from "styles/context";
import NavbarButton from "../NavbarButton";

interface Props {
  searchTerms: string;
}

const MobileSearchDropdownBase: React.FC<Props> = ({ searchTerms }) => {
  const [open, setOpen] = useState(false);
  const { isMedium } = useMediaQueryContext();
  return (
    <>
      <NavbarButton
        px={isMedium ? 2 : 3}
        onClick={() => setOpen(!open)}
        sx={{ borderLeft: 1, flexGrow: 1 }}>
        <Icon name="Search" size={isMedium ? 24 : 36} />
      </NavbarButton>
      {open && <MobileSearchBox onClose={() => setOpen(false)} />}
    </>
  );
};

const mapStateToProps = (state: any) => ({
  searchTerms: state.search.terms
});

export const MobileSearchDropdown = compose<Props, any>(
  connect(mapStateToProps)
)(MobileSearchDropdownBase);
