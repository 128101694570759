import React, { Component } from "react";
import { Image as RebassImage, ImageProps, Box } from "rebass";

interface Props extends ImageProps {
  src: string;
}

interface State {
  error: boolean;
}

const INITIAL_STATE = {
  error: false
};

export class Image extends Component<Props, State> {
  state = { ...INITIAL_STATE };

  componentDidUpdate(prevProps: Props) {
    if (this.props.src !== prevProps.src) {
      this.setState({ ...INITIAL_STATE });
    }
  }

  render() {
    const { src, ...rest } = this.props;
    const { error } = this.state;

    if (error) {
      return (
        <Box
          width="100%"
          height="100%"
          backgroundColor="#ccc"
          {...(rest as any)}
        />
      );
    } else {
      return (
        <RebassImage
          {...(rest as any)}
          src={src}
          alt="alt"
          onError={() => this.setState({ error: true })}
        />
      );
    }
  }
}
