import React from "react";
import { ChangePassword } from "./ChangePassword";
import { SocialProviders } from "./SocialProviders";
import { FormFieldSet } from "app/shared/forms";
import i18next from "i18next";

export const Account: React.FC = () => (
  <FormFieldSet
    title={i18next.t("account.settings.account.loginDetails")}
    sx={{ border: 1 }}>
    <ChangePassword />
    <SocialProviders />
  </FormFieldSet>
);
