import { Button } from "app/shared";
import Firebase from "authentication/firebase";
import { withAuthorization } from "authentication/session";
import React from "react";
import { Trans } from "react-i18next";
import { Text } from "rebass";
import { compose } from "recompose";

interface Props {
  firebase: Firebase;
}

const SignOutButtonBase: React.FC<Props> = ({ firebase }) => (
  <Button
    href="/"
    onClick={async () => {
      await firebase.doSignOut();
      localStorage.clear();
    }}>
    <Text fontSize={5} variant="caps" pl={3}>
      <Trans i18nKey="navbar.userMenu.signOut" />
    </Text>
  </Button>
);

export const SignOutButton = compose<Props, any>(withAuthorization())(
  SignOutButtonBase
);
