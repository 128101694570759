import React, { useEffect, useState } from "react";
import { Trans } from "react-i18next";
import { Box, Flex, Text } from "rebass";
import {
  Button,
  FullWidthContainer,
  Icon,
  Image,
  NewsletterPopUp,
  Separator
} from "app/shared";
import { FacebookIcon, PinterestIcon, TwitterIcon } from "react-share";
import { NavbarAreaStyle } from "layout/NavbarArea";
import { StickyScrollUp } from "react-stickup";
import { getCollection } from "app/collections/services";
import { getDocument } from "app/documents/services";
import { DocumentView } from "app/documents";
import { sizes } from "styles/theme";
import { CollectionView } from "app/collections";
import { Collection, Document } from "models";
import { PageParser } from "app/pages";
import { UserPicture } from "../account";
import { getUserProfile } from "../account/services";
import i18next from "i18next";
import LogoImage from "app/shared/siteImageButton/static/Logo.svg";
import FrontTalksImage from "./static/FrontTalks.svg";
import MagazineImage from "./static/Magazine.svg";

interface Props {
  type: "document" | "collection";
  id: number;
  thumbnail: string;
}

const RandomItem: React.FC<Props> = ({ type, id, thumbnail }) => {
  const [item, setItem] = useState<Document | Collection | undefined>(
    undefined
  );
  const [userPicture, setUserPicture] = useState<string | undefined>(undefined);

  interface RandomType {
    mode: "document" | "collection";
  }

  const ItemInfo: React.FC<RandomType> = ({ mode }) => {
    return (
      <>
        {item && (
          <Flex
            justifyContent="center"
            flexWrap="wrap"
            flexDirection="column"
            pb={[3, 7]}
            pl={[2, 6]}
            sx={{
              position: "absolute",
              bottom: 0,
              zIndex: 1
            }}>
            <Flex bg="black">
              <Button
                href={
                  (mode === "document" ? "/documents/" : "/collections/") +
                  item.id
                }>
                <Flex
                  alignItems="center"
                  py={2}
                  px={4}
                  sx={{ "& svg path": { fill: "white" } }}>
                  <Text
                    color="white"
                    fontSize={[7, 10]}
                    pr={3}
                    sx={{ wordBreak: "break-word" }}>
                    {mode === "document"
                      ? (item as Document).translations?.[0]?.title ??
                        i18next.t("documents.documentView.noTitle")
                      : (item as Collection).title ??
                        i18next.t("documents.documentView.noTitle")}
                  </Text>
                  <Icon name="ArrowRight3" size={[20, 35]} />
                </Flex>
              </Button>
            </Flex>
            <Flex bg="white" sx={{ alignSelf: "start" }}>
              <Button href={"/users/" + item.owner.id}>
                <Flex alignItems="center">
                  <UserPicture
                    height="35px"
                    picture={userPicture}
                    userVerified="verified"
                    verifiedIconSize={15}
                  />
                  <Text
                    pl={2}
                    color="black"
                    variant="grotTextCaps"
                    fontSize={2}
                    pr={3}>
                    {item.owner.name}
                  </Text>
                </Flex>
              </Button>
            </Flex>
          </Flex>
        )}
      </>
    );
  };

  useEffect(() => {
    if (type === "document") {
      getDocument(id).then(item => {
        setItem(item);
        getUserProfile(item.owner.id).then(user =>
          setUserPicture(user.picture)
        ); //temporary to get user picture because getDocument dont return
      });
    } else if (type === "collection") {
      getCollection(id).then(item => {
        const collection = item as Collection;
        if (collection.documents?.length) {
          collection.documents[0].thumbnail = thumbnail;
        }
        setItem(collection);
        getUserProfile(item.owner.id).then(user =>
          setUserPicture(user.picture)
        );
      });
    }
  }, [type, id, thumbnail]);
  return (
    <Box>
      {type === "document" && item && (
        <>
          <Box
            sx={{
              flexGrow: 1,
              position: "relative"
            }}>
            <DocumentView
              whiteMode={true}
              variant="flex"
              showHoverInfo={false}
              showInfoBottom={false}
              forceShowOnHover={false}
              showUserInfo={false}
              customPb={0}
              document={{
                ...(item as Document),
                title: (item as Document).translations?.[0]?.title,
                thumbnail,
                owner_picture: (item as Document).owner.picture
              }}
              height={[
                `calc(100vh - ${sizes.navbarHeight.sm})`,
                "",
                "",
                `calc(100vh - ${sizes.navbarHeight.lg})`
              ]}
              width="100%"
            />
            <ItemInfo mode="document" />
          </Box>
        </>
      )}
      {type === "collection" && item && (
        <>
          <Box
            sx={{
              flexGrow: 1,
              position: "relative"
            }}>
            <CollectionView
              whiteMode={true}
              variant="flex"
              largeThumbnail={true}
              showInfoBottom={false}
              showUserInfo={false}
              forceShowOnHover={false}
              customPb={0}
              collection={item as Collection}
              height={[
                `calc(100vh - ${sizes.navbarHeight.sm})`,
                "",
                "",
                `calc(100vh - ${sizes.navbarHeight.lg})`
              ]}
              width="100%"
            />
            <ItemInfo mode="collection" />
          </Box>
        </>
      )}
    </Box>
  );
};

export const HomepageHeader = () => {
  return (
    <Box>
      <PageParser
        pageId={4}
        components={{
          Random: (props: any) => {
            const randomNumber = (except?: number): any => {
              //to return 2 random values not equal
              const num =
                props.values?.[
                  Math.floor(Math.random() * props.values?.length)
                ];
              return num
                ? num === except
                  ? randomNumber(except)
                  : num
                : undefined;
            };
            const randomValue = randomNumber();
            return (
              <FullWidthContainer>
                <RandomItem
                  type={randomValue.type}
                  id={randomValue.id}
                  thumbnail={randomValue.thumbnail}
                />
              </FullWidthContainer>
            );
          }
        }}
      />
      <Box px={[2, 0]}>
        <Flex my={8}>
          <Flex width="100%" flexDirection={["column", "row"]}>
            <Box mb={[4, 0]} flex={1}>
              <Image src={LogoImage} height={[27, 37]} />
              <Text variant="grotDisplay" fontSize={[6, 6, 9]} mt={2}>
                <Trans i18nKey="about.intro.subtitle" />
              </Text>
            </Box>
            <Flex height="100%" width={["100%", "45%"]}>
              <Button
                sx={{ border: 1, borderColor: "blue" }}
                href="/search/collection"
                width="100%"
                height={["50px", "89px"]}
                variant="link.button.blue">
                <Flex
                  height="100%"
                  width="100%"
                  justifyContent="center"
                  alignItems="center">
                  <Text variant="grotText" fontSize={[1, 4]}>
                    <Trans i18nKey="home.exploreArea.exploreButton" />
                  </Text>
                </Flex>
              </Button>
            </Flex>
          </Flex>
        </Flex>

        <Flex width="100%" flexDirection={["column", "row"]} my={8}>
          <Box flex={1} mr={7} mb={[8, 0]}>
            <Flex alignItems="center">
              <Icon name="Circle" color="blue" size={[65, 78]} />
              <Text variant="grotText" color="blue" fontSize={[6, 10]} pl={3}>
                <Trans i18nKey="home.headerTitle1" />
              </Text>
            </Flex>
            <Flex alignItems="center" my={6}>
              <Icon name="Lightning" color="blue" size={[65, 78]} />
              <Text variant="grotText" color="blue" fontSize={[6, 10]} pl={3}>
                <Trans i18nKey="home.headerTitle2" />
              </Text>
            </Flex>
            <Flex alignItems="center">
              <Icon name="Plus2" color="blue" size={[65, 78]} />
              <Text
                variant="grotText"
                color="blue"
                fontSize={[6, 10]}
                pl={3}
                sx={{ textDecoration: "underline" }}>
                <Trans i18nKey="home.headerTitle3" />
              </Text>
            </Flex>
          </Box>
          <Box width={["100%", "45%"]}>
            <Button
              width="100%"
              height={["50px", "89px"]}
              variant="link.button"
              href="/about"
              sx={{ border: "1px solid black" }}
              mb={4}>
              <Flex
                height="100%"
                width="100%"
                justifyContent="center"
                alignItems="center">
                <Text variant="grotText" fontSize={[1, 4]}>
                  <Trans i18nKey="home.headerButton2" />
                </Text>
              </Flex>
            </Button>
            <Button
              width="100%"
              height={["50px", "89px"]}
              variant="link.button"
              href="/about#how-it-works"
              sx={{ border: "1px solid black" }}
              mb={4}>
              <Flex
                height="100%"
                width="100%"
                justifyContent="center"
                alignItems="center">
                <Text variant="grotText" fontSize={[1, 4]}>
                  <Trans i18nKey="home.headerButton5" />
                </Text>
              </Flex>
            </Button>
            {/*             <Button
              width="100%"
              height={["50px", "89px"]}
              variant="link.button"
              href="/#"
              sx={{ border: "1px solid black" }}
              mb={4}>
              <Flex
                height="100%"
                width="100%"
                justifyContent="center"
                alignItems="center">
                <Text variant="grotText" fontSize={[1, 4]} color="blue">
                  <Trans i18nKey="home.headerButton6" />
                </Text>
              </Flex>
            </Button> */}
            <Button
              width="100%"
              height={["50px", "89px"]}
              mb={4}
              variant="link.button"
              href="/fronttalks"
              sx={{ border: "1px solid black" }}>
              <Flex
                height="100%"
                width="100%"
                justifyContent="center"
                alignItems="center">
                <Image src={FrontTalksImage} height={[10, 17]} />
              </Flex>
            </Button>
            <Button
              width="100%"
              height={["50px", "89px"]}
              variant="link.button"
              target="_blank"
              href="https://frontfiles-magazine.ghost.io/"
              sx={{ border: "1px solid black" }}>
              <Flex
                height="100%"
                width="100%"
                justifyContent="center"
                alignItems="center">
                <Image src={MagazineImage} height={[17, 28]} />
              </Flex>
            </Button>
          </Box>
        </Flex>

        <Box width="100%" mb={8}>
          <Box sx={{ border: 1, borderColor: "black.0" }} p={4}>
            <Text
              variant="grotDisplay"
              fontSize={[6, 13]}
              textAlign={["left", "center"]}>
              <Trans
                i18nKey="home.info.top"
                components={[
                  <Box
                    as="span"
                    color="blue"
                    sx={{ textDecoration: "underline" }}></Box>
                ]}
              />
            </Text>
          </Box>
          <Flex
            flexDirection={["column", "row"]}
            sx={{ borderLeft: 1, borderRight: 1, borderColor: "black.0" }}>
            <Box width={["100%", "50%"]} p={4}>
              <Text variant="grotDisplay" fontSize={[6, 13]}>
                <Trans
                  i18nKey="home.info.left"
                  components={[
                    <Box
                      as="span"
                      color="blue"
                      sx={{ textDecoration: "underline" }}></Box>
                  ]}
                />
              </Text>
            </Box>
            <Box
              width={["100%", "50%"]}
              p={4}
              sx={{
                borderLeft: [0, 1],
                borderTop: [1, 0],
                borderColor: "black.0"
              }}>
              <Text variant="grotDisplay" fontSize={[6, 13]}>
                <Trans
                  i18nKey="home.info.right"
                  components={[
                    <Box
                      as="span"
                      color="blue"
                      sx={{ textDecoration: "underline" }}></Box>
                  ]}
                />
              </Text>
            </Box>
          </Flex>
          <Box bg="blue" p={4}>
            <Text
              variant="grotDisplay"
              color="white"
              fontSize={[6, 13]}
              textAlign={["left", "center"]}>
              <Trans
                i18nKey="home.info.bottom"
                components={[
                  <Box as="span" sx={{ textDecoration: "underline" }}></Box>
                ]}
              />
            </Text>
          </Box>
        </Box>
      </Box>

      <Separator />
    </Box>
  );
};
