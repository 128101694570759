import { Button, Icon, Popup, Toast } from "app/shared";
import React from "react";
import { Trans } from "react-i18next";
import { BoxProps, Flex } from "rebass";
import i18next from "i18next";
import {
  EmailShareButton,
  FacebookShareButton,
  LineShareButton,
  LinkedinShareButton,
  PinterestShareButton,
  RedditShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterShareButton,
  VKShareButton,
  WhatsappShareButton,
  EmailIcon,
  FacebookIcon,
  LineIcon,
  LinkedinIcon,
  PinterestIcon,
  RedditIcon,
  TelegramIcon,
  TumblrIcon,
  TwitterIcon,
  VKIcon,
  WhatsappIcon
} from "react-share";
import environment from "configurations";
import { CardGridLayout } from "layout";
import { firebaseAnalytics } from "analytics/firebaseAnalytics";

interface Props extends BoxProps {
  path: string;
  title?: string;
  photo?: string;
  variant?: "icon" | "white" | "black" | "transparent";
  onOpen?: () => void;
  onClose?: () => void;
  open?: boolean;
  noBorder?: boolean;
  textWithIcon?: boolean;
  hideTrigger?: boolean;
}

/**
 * TODO:
 * - Add instagram support
 */
export const ShareButton: React.FC<Props> = ({
  path,
  title,
  photo,
  variant,
  onOpen,
  onClose,
  noBorder,
  textWithIcon,
  hideTrigger,
  open,
  ...rest
}) => {
  const url = `${environment.url}${path}`;

  const copyClipboard = () => {
    navigator.clipboard.writeText(url);
    Toast.success({
      title: {
        key: "shared.elementOptions.copyLinkButton.confirmationToast"
      }
    });
  };

  const onShareClicked = () => {
    firebaseAnalytics.logEvent("share", {
      content_id: path
    });
  };

  return (
    <Popup
      trigger={
        !hideTrigger ? (
          <Button
            variant={variant}
            {...(rest as any)}
            sx={{
              border:
                noBorder || variant === "transparent"
                  ? "none"
                  : "1px solid black",
              "& svg path": { fill: "transparent", stroke: "blue" }
            }}>
            {(variant === "icon" || variant === "transparent") &&
              !textWithIcon && <Icon name="Share2" size={22} />}
            {(variant === "white" || variant === "black") && !textWithIcon && (
              <Trans i18nKey="shared.shareButton.share" />
            )}
            {textWithIcon && (
              <Flex justifyContent="center" alignItems="center">
                <Flex sx={{ "& svg path": { fill: "white", stroke: "white" } }}>
                  <Icon name="Share2" size={20} />
                </Flex>
                <Flex pl={2} fontSize={[1, 1, 1, 1, 3]}>
                  <Trans i18nKey="shared.shareButton.share" />
                </Flex>
              </Flex>
            )}
          </Button>
        ) : (
          <></>
        )
      }
      closeOnEscape={false}
      hideActionButtons={true}
      onClose={onClose}
      onOpen={onOpen}
      open={open}
      heading={i18next.t("shared.shareButton.share")}>
      <CardGridLayout
        gridTemplateColumns={[
          "repeat(3, 1fr)",
          "repeat(3, 1fr)",
          "repeat(5, 1fr)",
          "repeat(6, 1fr)"
        ]}
        gridGap={3}
        py={3}
        justifyItems="center"
        sx={{ "& > *": { cursor: "pointer" } }}>
        <Button
          variant="transparent"
          onClick={copyClipboard}
          bg="black"
          width="64px"
          sx={{ "& svg path": { fill: "white" } }}>
          <Flex justifyContent="center" py={3}>
            <Icon name="Clipboard" size={34} />
          </Flex>
        </Button>
        <EmailShareButton
          url={url}
          subject={title}
          body={url}
          onClick={onShareClicked}>
          <EmailIcon size={64} iconBgStyle={{ fill: "black" }} />
        </EmailShareButton>
        <TwitterShareButton url={url} title={title} onClick={onShareClicked}>
          <TwitterIcon size={64} iconBgStyle={{ fill: "black" }} />
        </TwitterShareButton>
        <FacebookShareButton url={url} quote={title} onClick={onShareClicked}>
          <FacebookIcon size={64} iconBgStyle={{ fill: "black" }} />
        </FacebookShareButton>
        <LinkedinShareButton url={url} onClick={onShareClicked}>
          <LinkedinIcon size={64} iconBgStyle={{ fill: "black" }} />
        </LinkedinShareButton>
        <PinterestShareButton
          url={url}
          media={photo ?? ""}
          description={title}
          onClick={onShareClicked}>
          <PinterestIcon size={64} iconBgStyle={{ fill: "black" }} />
        </PinterestShareButton>
        <WhatsappShareButton url={url} title={title} onClick={onShareClicked}>
          <WhatsappIcon size={64} iconBgStyle={{ fill: "black" }} />
        </WhatsappShareButton>
        <TelegramShareButton url={url} title={title} onClick={onShareClicked}>
          <TelegramIcon size={64} iconBgStyle={{ fill: "black" }} />
        </TelegramShareButton>
        <LineShareButton url={url} title={title} onClick={onShareClicked}>
          <LineIcon size={64} iconBgStyle={{ fill: "black" }} />
        </LineShareButton>
        <RedditShareButton url={url} title={title} onClick={onShareClicked}>
          <RedditIcon size={64} iconBgStyle={{ fill: "black" }} />
        </RedditShareButton>
        <TumblrShareButton url={url} title={title} onClick={onShareClicked}>
          <TumblrIcon size={64} iconBgStyle={{ fill: "black" }} />
        </TumblrShareButton>
        <VKShareButton url={url} title={title} onClick={onShareClicked}>
          <VKIcon size={64} iconBgStyle={{ fill: "black" }} />
        </VKShareButton>
      </CardGridLayout>
    </Popup>
  );
};

ShareButton.defaultProps = {
  variant: "white"
};
