import React from "react";
import Svg, { SvgProps } from "primitives/Svg";

const SvgLock = ({ width, height, size, color }: SvgProps) => (
  <Svg
    width={size || width}
    height={size || height}
    viewBox="0 0 18 25"
    color={color}>
    <path stroke="#000" d="M.5 8.5h17v16H.5zM3 7a6 6 0 1112 0v1.5" />
  </Svg>
);

SvgLock.defaultProps = {
  width: 24,
  height: 24
};
export default SvgLock;
