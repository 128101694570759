import { Icon } from "app/shared";
import React, { useState } from "react";
import { Box, Flex, SxStyleProp } from "rebass";
import { useMediaQueryContext } from "styles/context";
import OutsideClickWrapper from "../wrapper/OutsideClickWrapper";
import { ElementOption } from "./models";

interface Props {
  options: ElementOption[];
  elementId: any;
  iconName?: string;
  iconSize?: number;
  iconColor?: string;
  closeMenuOnClick?: boolean;
  backgroundAlwaysActive?: boolean;
  optionsWidth?: string[];
  sx?: SxStyleProp;
  popupSx?: SxStyleProp;
}

export const ElementOptions: React.FC<Props> = ({
  options,
  elementId,
  iconName,
  iconSize,
  iconColor,
  closeMenuOnClick,
  backgroundAlwaysActive,
  optionsWidth,
  sx,
  popupSx
}) => {
  const [open, setOpen] = useState(false);
  const { isSmall } = useMediaQueryContext();

  return (
    <Flex>
      <Flex
        alignItems="center"
        justifyContent="center"
        onClick={() => setOpen(!open)}
        sx={
          sx
            ? {
                ...sx,
                cursor: "pointer"
              }
            : {
                paddingX: [1, 2],
                backgroundColor: "white.0",
                border: 1,
                borderColor: "black.0",
                borderLeft: 0,
                zIndex: 1,
                flexGrow: 1
              }
        }>
        <Icon
          name={"HorizontalDots"}
          size={iconSize ? (isSmall ? 16 : iconSize) : 20}
          color={open ? "white.0" : "black.0"}
        />
      </Flex>
      <Box sx={popupSx ?? { position: "absolute", top: "27px", left: "33px" }}>
        {open && (
          <OutsideClickWrapper isOpen={open} callback={() => setOpen(false)}>
            <Box
              bg="fill.0"
              width={optionsWidth ?? "60px"}
              sx={{
                position: "absolute",
                border: 1,
                borderColor: "black.0",
                zIndex: 1,
                "& > *": { borderTop: 1 },
                "& > :first-of-type": { borderTop: 0 }
              }}>
              {options.map((option, index) => (
                <Box
                  key={`option_${index}_${elementId}`}
                  onClick={closeMenuOnClick ? () => setOpen(false) : undefined}>
                  {option(elementId)}
                </Box>
              ))}
            </Box>
          </OutsideClickWrapper>
        )}
      </Box>
    </Flex>
  );
};

ElementOptions.defaultProps = {
  backgroundAlwaysActive: true,
  closeMenuOnClick: true
};
