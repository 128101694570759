import styled from "@emotion/styled";
import themeGet from "@styled-system/theme-get";
import Grid, { GridProps } from "primitives/Grid";

const MainLayout = styled(Grid)<GridProps>`
  grid-template-columns: 1fr;
  grid-template-rows: ${themeGet("sizes.navbarHeight.sm")} auto 1fr auto;
  grid-template-areas:
    "navbar"
    "header"
    "content"
    "footer";

  ${themeGet("mediaQueries.md")} {
    grid-template-columns: auto minmax(auto, ${themeGet("sizes.maxWidth")}) auto;
    grid-template-areas:
      "navbar navbar navbar"
      "header header header"
      "...... content ......"
      "footer footer footer";
  }

  ${themeGet("mediaQueries.lg")} {
    grid-template-rows: ${themeGet("sizes.navbarHeight.lg")} auto 1fr auto;
  }
`;

MainLayout.defaultProps = {
  height: "100%",
  overflow: "hidden" // to hide FullWidthContainer overflowX
};

export default MainLayout;
