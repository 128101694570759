import React from "react";
import { Box, Flex } from "rebass";
import { GalleryObject } from "./models";

interface Props {
  items: GalleryObject[];
  numberOfThumbnails: number;
  currentIndex: number;
  setCurrentIndex: (index: number) => void;
  disabled?: boolean;
}

export const Carousel: React.FC<Props> = ({
  items,
  numberOfThumbnails,
  currentIndex,
  setCurrentIndex,
  disabled
}) => {
  /**
   * Constructs shown thumbnails depending on the currentIndex and numberOfThumbnails provided
   */
  const getSlicedThumbnails = (currentIndex: number): GalleryObject[] => {
    let to = Math.max(
      numberOfThumbnails,
      currentIndex + Math.round(numberOfThumbnails / 2)
    );
    to = to > items.length ? items.length : to;
    const from = Math.max(0, to - numberOfThumbnails);
    return items.slice(from, to);
  };
  const findItemIndex = (documentId: number): number => {
    return items.findIndex(it => it.id === documentId);
  };

  return (
    <Flex
      flexDirection="row"
      justifyContent="center"
      py={1}
      sx={{
        flexWrap: ["wrap", "nowrap"],
        "& > :last-child": {
          mr: 0
        }
      }}
      height="50%">
      {getSlicedThumbnails(currentIndex).map((item, index) => {
        const itemIndex = findItemIndex(item.id);

        return (
          <Box
            key={item.id}
            width={["102px", "150px", "220px"]}
            onClick={() => !disabled && setCurrentIndex(itemIndex)}
            sx={{
              outline: "none",
              border: itemIndex === currentIndex ? "4px solid" : undefined,
              borderColor: "brand.0",
              marginRight: [1, 2],
              backgroundImage: `url(${item.thumbnail})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              cursor: disabled ? undefined : "pointer"
            }}
          />
        );
      })}
    </Flex>
  );
};

export default Carousel;
