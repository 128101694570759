import React, { useState, useEffect } from "react";
import categories from "./static/iptc-categories.json";
import { MultiSelect } from "app/shared";
import { IptcElement } from "./models";
import { connect } from "react-redux";
import { compose } from "recompose";
import { SelectOption } from "app/shared/multiSelect/models";
import { TOptions } from "i18next";
import i18next from "i18next";
import uniqBy from "lodash.uniqby";
import { ISOLanguageCode } from "i18n/resources/supportedLanguages";

interface PropsExternal {
  onChange: (options: SelectOption[]) => void;
  labelI18n?: string;
  infoI18n?: string;
  errorI18n?: string;
  errorI18nOptions?: TOptions;
  defaultValues?: SelectOption[]; // default selected values
  isMulti?: boolean;
  placeholder?: string;
  disabled?: boolean;
}

interface Props extends PropsExternal {
  language: ISOLanguageCode;
}

/**
 * Method to construct category recursive label
 */
const getCategoryRecursiveLabel = (
  category: IptcElement,
  language: ISOLanguageCode
): string => {
  const label = category[`recursiveName_${language}`] ?? category.recursiveName; // fallback to EN
  return label.toString().replace(new RegExp("\\.", "g"), " » "); // using » to divide parent categories
};

/**
 * Method to get categories parents.
 */
const getSelectedCategoriesParents = (
  options: SelectOption[],
  language: ISOLanguageCode
): SelectOption[] => {
  const parents: IptcElement[] = options.flatMap(it => {
    const category = (categories as IptcElement[]).find(
      category => category.external_id.toString() === it.value
    )!!;

    return getCategoryParents(category, [category]);
  });

  return uniqBy(parents, "external_id").map(category => ({
    value: category.external_id.toString(),
    label: getCategoryRecursiveLabel(category, language)
  }));
};

/**
 * Recursive method that gets all parents for a given category.
 */
const getCategoryParents = (
  category: IptcElement,
  parents: IptcElement[]
): IptcElement[] => {
  if (category.parent === undefined || category.parent.length === 0) {
    return parents;
  }
  const parent = (categories as IptcElement[]).find(
    it => it.id === category.parent
  )!!;
  parents.push(parent);

  return getCategoryParents(parent, parents);
};

/**
 * This component implements the IPTC category selector.
 * Categories have the external_id that represents the database ID.
 */
const CategorySelectBase: React.FC<Props> = ({
  defaultValues,
  labelI18n,
  infoI18n,
  errorI18n,
  errorI18nOptions,
  onChange,
  language,
  isMulti,
  placeholder,
  disabled
}) => {
  const [options, setOptions] = useState<SelectOption[]>([]);
  useEffect(() => {
    const options = (categories as IptcElement[]).map(category => ({
      value: category.external_id.toString(),
      label: getCategoryRecursiveLabel(category, language)
    }));
    setOptions(options); // calculate this only one time inside useEffect
  }, [language]);

  return (
    <MultiSelect
      isMulti={isMulti}
      options={options}
      onChange={options =>
        onChange(getSelectedCategoriesParents(options, language))
      }
      defaultValues={defaultValues?.map(it => it.value)}
      errorI18n={errorI18n}
      errorI18nOptions={errorI18nOptions}
      labelI18n={labelI18n}
      infoI18n={infoI18n}
      placeholder={placeholder}
      disabled={disabled}
    />
  );
};

CategorySelectBase.defaultProps = {
  placeholder: i18next.t("search.shared.multiSelectSearch.placeholder")
};

const mapStateToProps = (state: any) => ({
  language: state.language.code
});

export const CategorySelect = compose<Props, PropsExternal>(
  connect(mapStateToProps)
)(CategorySelectBase);
