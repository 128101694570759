import React from "react";
import Svg, { SvgProps } from "primitives/Svg";

const SvgVerticalDots = ({ width, height, size, color }: SvgProps) => (
  <Svg
    width={size || width}
    height={size || height}
    viewBox="0 0 24 24"
    color={color}>
    <circle cx={12} cy={4} r={2.5} stroke="#000" />
    <circle cx={12} cy={12} r={2.5} stroke="#000" />
    <circle cx={12} cy={20} r={2.5} stroke="#000" />
  </Svg>
);

SvgVerticalDots.defaultProps = {
  width: 24,
  height: 24
};
export default SvgVerticalDots;
