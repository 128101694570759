import { SearchBox } from "app/search/shared/SearchBox";
import React from "react";
import { Box, Flex } from "rebass";
import { sizes } from "styles/theme";

export const SearchDropdown: React.FC = () => {
  return (
    <Box
      sx={{
        position: "relative",
        flexGrow: 1,
        flexShrink: 1
      }}>
      <Box
        width="100%"
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          zIndex: 9
        }}>
        <Flex
          height={[
            `calc(${sizes.navbarHeight.sm} - 1px)`,
            `calc(${sizes.navbarHeight.lg} - 1px)`
          ]} // Removing border
        >
          <Flex width="100%" alignItems="center">
            <Box width="100%">
              <SearchBox />
            </Box>
          </Flex>
        </Flex>
      </Box>
    </Box>
  );
};
