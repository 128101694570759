import React from "react";
import Svg, { SvgProps } from "primitives/Svg";

const SvgSelectDropdownArrow = ({ width, height, size, color }: SvgProps) => (
  <Svg
    width={size || width}
    height={size || height}
    viewBox="0 0 24 24"
    color={color}>
    <path d="M7 10l5 5 5-5H7z" />
  </Svg>
);

SvgSelectDropdownArrow.defaultProps = {
  width: 24,
  height: 24
};
export default SvgSelectDropdownArrow;
