import React, { useEffect, useRef } from "react";
import { Box } from "rebass";

interface Props {
  callback: () => void;
  isOpen?: boolean;
}

const OutsideClickWrapper: React.FC<Props> = ({
  isOpen,
  callback,
  children
}) => {
  const wrapperRef = useRef(null);
  const handleClickOutside = (event: any) => {
    if (isOpen) {
      const currentNode: any = wrapperRef.current;
      if (currentNode && !currentNode.contains(event.target)) {
        callback();
      }
    }
  };
  useEffect(() => {
    isOpen
      ? document.addEventListener("mousedown", handleClickOutside)
      : document.removeEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  return <Box ref={wrapperRef}>{children}</Box>;
};

export default OutsideClickWrapper;
