import React from "react";
import Svg, { SvgProps } from "primitives/Svg";

const SvgPinterest = ({ width, height, size, color }: SvgProps) => (
  <Svg
    width={size || width}
    height={size || height}
    viewBox="0 0 49 49"
    color={color}>
    <g clipPath="url(#Pinterest_svg__clip0_9841_156712)">
      <path d="M.516.398h48v7.273h-48z" />
      <path d="M.515 48.398v-48h7.273v48zM41.242 48.399v-48h7.273v48z" />
      <path d="M.516 39.672h48v8.727h-48z" />
      <path d="M36.477.398H12.517c-6.59 0-12 5.41-12 12.039v23.961c0 6.59 5.41 12 12 12h23.962c6.628 0 12.038-5.41 12.038-12.038V12.437c0-6.629-5.41-12.039-12.038-12.039zm-9.561 30.667c-1.905 0-3.734-.99-4.343-2.133 0 0-1.029 3.962-1.257 4.724-.762 2.704-3.048 5.41-3.2 5.638a.235.235 0 01-.42-.115c-.038-.343-.609-3.771.039-6.552l2.285-9.371s-.571-1.105-.571-2.705c0-2.553 1.524-4.457 3.428-4.457 1.6 0 2.4 1.18 2.4 2.59 0 1.562-1.028 3.924-1.561 6.095-.457 1.829.952 3.315 2.819 3.315 3.352 0 5.638-4.19 5.638-9.143 0-3.772-2.629-6.59-7.39-6.59-5.372 0-8.763 3.885-8.763 8.228 0 1.486.457 2.552 1.181 3.39.343.381.381.534.267.953-.076.305-.267 1.066-.38 1.371-.115.42-.496.61-.877.42-2.476-.991-3.62-3.62-3.62-6.591 0-4.876 4.268-10.743 12.687-10.743 6.78 0 11.238 4.762 11.238 9.867-.038 6.78-3.886 11.81-9.6 11.81z" />
    </g>
    <defs>
      <clipPath id="Pinterest_svg__clip0_9841_156712">
        <path transform="translate(.516 .398)" d="M0 0h48v48H0z" />
      </clipPath>
    </defs>
  </Svg>
);

SvgPinterest.defaultProps = {
  width: 24,
  height: 24
};
export default SvgPinterest;
