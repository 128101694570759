import React, { Component } from "react";
import { Firebase } from "authentication/firebase";
import { withAuthorization } from "authentication/session";
import { compose } from "recompose";
import { connect } from "react-redux";
import { NavbarIconCounter } from "app/shared";
import store from "store";
import * as actions from "./reducers/actions";
import { Subscription } from "messaging";
import NavbarButton from "../navbar/NavbarButton";

interface Props {
  firebase: Firebase;
  unreadMessages: number;
}

class NavbarMessageButtonBase extends Component<Props, any> {
  subscription?: Subscription;

  async componentDidMount() {
    const { firebase } = this.props;

    await firebase.messageService.connect();

    this.resetUnreadMessages();

    this.subscription = firebase.messageService
      .getUnreadMessages()
      .subscribe(count => {
        store.dispatch(actions.setUnreadMessages(count));
      });
  }

  /**
   * Unsubscribe events
   */
  componentWillUnmount() {
    this.subscription?.unsubscribe();
  }

  resetUnreadMessages() {
    const { unreadMessages } = this.props;

    if (unreadMessages !== 0) {
      store.dispatch(actions.setUnreadMessages(0));
    }
  }

  render() {
    const { unreadMessages } = this.props;

    return (
      <NavbarButton
        href="/messages"
        sx={{
          borderLeft: 1,
          "&:hover": {
            borderLeft: "1px solid black",
            background: "blue.0",
            color: "white.0",
            "& svg path": { fill: "white", stroke: "white" }
          }
        }}>
        <NavbarIconCounter counter={unreadMessages} iconName="Chat" size={18} />
      </NavbarButton>
    );
  }
}

const mapStateToProps = (state: any) => ({
  unreadMessages: state.messages.unreadMessages
});

export const NavbarMessageButton = compose<Props, any>(
  connect(mapStateToProps),
  withAuthorization()
)(NavbarMessageButtonBase);
