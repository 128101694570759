import { Checkbox } from "app/shared";
import { TableLayout } from "app/shared/tableLayout";
import React from "react";
import { UserInfo } from "../models";
import { UserEquipments, UserHousings, UserTransportations } from "./constants";
import { Trans } from "react-i18next";
import { Text } from "rebass";

interface Props {
  userInfo: UserInfo;
  handleOnCheckboxChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    propToChange: string
  ) => void;
}

const ColabWork: React.FC<Props> = ({ userInfo, handleOnCheckboxChange }) => {
  return (
    <>
      <TableLayout
        tableName={"account.editMyInfo.colabWork.equipment.tableName"}
        columns={4}
        width="100%"
        pb={30}
        tableNodes={UserEquipments.map(equipment => (
          <Checkbox
            size={18}
            key={equipment.name}
            name={equipment.name}
            checked={userInfo.collaboration?.includes(equipment.name)}
            onChange={e => handleOnCheckboxChange(e, "collaboration")}>
            <Text fontSize={[1, 2]} variant="caps">
              <Trans i18nKey={equipment.i18n} />
            </Text>
          </Checkbox>
        ))}
      />

      <TableLayout
        tableName={"account.editMyInfo.colabWork.housing.tableName"}
        columns={4}
        width="100%"
        pb={30}
        tableNodes={UserHousings.map(housing => (
          <Checkbox
            size={18}
            key={housing.name}
            name={housing.name}
            checked={userInfo.collaboration?.includes(housing.name)}
            onChange={e => handleOnCheckboxChange(e, "collaboration")}>
            <Text fontSize={[1, 2]} variant="caps">
              <Trans i18nKey={housing.i18n} />
            </Text>
          </Checkbox>
        ))}
      />

      <TableLayout
        tableName={"account.editMyInfo.colabWork.transportation.tableName"}
        columns={4}
        width="100%"
        tableNodes={UserTransportations.map(transportation => (
          <Checkbox
            size={18}
            key={transportation.name}
            name={transportation.name}
            checked={userInfo.collaboration?.includes(transportation.name)}
            onChange={e => handleOnCheckboxChange(e, "collaboration")}>
            <Text fontSize={[1, 2]} variant="caps">
              <Trans i18nKey={transportation.i18n} />
            </Text>
          </Checkbox>
        ))}
      />
    </>
  );
};

export default ColabWork;
