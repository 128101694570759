import { Button, Toast, Popup } from "app/shared";
import { withAuthorization } from "authentication/session";
import { ContentArea } from "layout";
import { LicenseType, MonetaryAmount } from "marketplace";
import { ValidationError, VersionedDocument } from "models";
import React, { Component } from "react";
import { Trans } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Flex, Text } from "rebass";
import { compose } from "recompose";
import { DocumentEditor } from "./DocumentEditor";
import { getDocument, deleteDocument } from "./services";
import { UpdateDocumentService } from "./services/UpdateDocumentService";
import i18next from "i18next";

interface Params {
  id: string;
}

type Props = RouteComponentProps<Params>;

interface State {
  vdoc?: VersionedDocument;
  documentErrors: ValidationError[];
  disabled: boolean;
  selectedLicenses?: LicenseType[];
  monetaryAmount?: MonetaryAmount;
}

class DocumentEditBase extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.updateDocumentService = UpdateDocumentService.default();
    this.state = {
      documentErrors: [],
      vdoc: undefined,
      disabled: false,
      monetaryAmount: undefined
    };
  }

  private updateDocumentService: UpdateDocumentService;

  componentDidMount() {
    const documentId = parseInt(this.props.match.params.id, 10);
    getDocument(documentId).then(document => {
      const basePrice = document.prices[0]?.basePrice;
      return this.setState({
        vdoc: { document, version: 0 },
        monetaryAmount: basePrice
          ? new MonetaryAmount(
              basePrice.value,
              basePrice.currency,
              basePrice.precision
            )
          : undefined
      });
    });
  }

  editDocument() {
    const { vdoc } = this.state;
    if (vdoc) {
      this.setState({ disabled: true }, () => {
        const errors = this.updateDocumentService.validateDocument(
          vdoc.document
        );
        if (errors.length > 0) {
          Toast.error({
            title: { key: "documents.documentEdit.formError" }
          });
          this.setState({ documentErrors: errors, disabled: false });
        } else {
          this.updateDocumentService
            .updateDocument(vdoc.document)
            .then(() => {
              this.setState({ disabled: false, documentErrors: [] });
              Toast.success({
                title: { key: "documents.documentEdit.confirmationToast" }
              });
              if (vdoc.document.collections[0].id) {
                document.location.href = `/collections/${vdoc.document?.collections[0]?.id}`;
              }
            })
            .catch(error => {
              Toast.apiError(error);
              this.setState({ disabled: false });
            });
        }
      });
    }
  }
  async deleteDocument() {
    const { vdoc } = this.state;

    if (vdoc) {
      deleteDocument([vdoc.document.id])
        .then(() => {
          Toast.success({
            title: { key: "documents.documentEdit.deleteToast" }
          });
          this.props.history.push("/");
        })
        .catch(error => {
          Toast.apiError(error);
          this.setState({ disabled: false });
        });
    }
  }

  render() {
    const { disabled, vdoc, documentErrors } = this.state;
    const document = vdoc?.document;
    const currentVersion = vdoc?.version ? vdoc?.version : 0;

    return (
      <ContentArea>
        {document && (
          <>
            <DocumentEditor
              errors={documentErrors}
              version={currentVersion}
              document={document}
              onChange={document =>
                this.setState({
                  vdoc: { document, version: currentVersion + 1 }
                })
              }
              showCollectionsDropdown={true}
            />
            <Flex
              mt={3}
              justifyContent="space-between"
              sx={{
                // :not() important for popups have 100% width
                "& > div:not([class*='popup'])": {
                  width: "calc(100% / 2 - 1px)"
                }
              }}>
              {this.renderDeleteButton()}
              <Button
                variant="blue"
                p={3}
                onClick={() => this.editDocument()}
                disabled={disabled}>
                <Text fontWeight="bold">
                  <Trans i18nKey="documents.documentEdit.save" />
                </Text>
              </Button>
            </Flex>
          </>
        )}
      </ContentArea>
    );
  }

  private renderDeleteButton = () => (
    <Popup
      trigger={
        <Button variant="black" p={3}>
          <Text fontWeight="bold">
            <Trans i18nKey="documents.documentEdit.popups.delete.label" />
          </Text>
        </Button>
      }
      heading={i18next.t("documents.documentEdit.popups.delete.header")}
      cancelText={i18next.t("documents.documentEdit.popups.delete.cancel")}
      submit={() => this.deleteDocument()}
      submitText={i18next.t("documents.documentEdit.popups.delete.submit")}>
      <>
        <Text textAlign="center">
          <Trans i18nKey="documents.documentEdit.popups.delete.content" />
        </Text>
      </>
    </Popup>
  );
}

export const DocumentEdit = compose<Props, any>(
  withRouter,
  withAuthorization()
)(DocumentEditBase);
