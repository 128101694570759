import React from "react";
import Svg, { SvgProps } from "primitives/Svg";

const SvgPhotos = ({ width, height, size, color }: SvgProps) => (
  <Svg
    width={size || width}
    height={size || height}
    viewBox="0 0 24 24"
    color={color}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17 4H4v13h1v1H3V3h15v2h-1V4zm3 3H7v13h12v1H6V6h15v13h-1V7z"
    />
  </Svg>
);

SvgPhotos.defaultProps = {
  width: 24,
  height: 24
};
export default SvgPhotos;
