import { SocialMediaTypes } from "app/account/constants";
import { Button, Icon } from "app/shared";
import React from "react";
import { capitalize, getSocialMediaObject } from "./utils";

interface Props {
  socialMediaType: SocialMediaTypes;
  socialMediaValue: string;
}

export const SocialMediaButton: React.FC<Props> = ({
  socialMediaType,
  socialMediaValue
}) => {
  if (socialMediaType) {
    const socialMediaObject = getSocialMediaObject(socialMediaType);
    const socialMediaLink = socialMediaObject
      ? socialMediaObject.link + socialMediaValue
      : undefined;

    return socialMediaLink ? (
      <Button href={`https://${socialMediaLink}`} target="_blank">
        <Icon name={capitalize(socialMediaType)} size={24} />
      </Button>
    ) : null;
  }

  return null;
};
