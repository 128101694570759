import { UploadFileItem } from "app/documents/submitContent/models";
import { ValidationError } from "models";

export type PortfolioReviewStatus = "Approved" | "Rejected" | "Pending";

export interface PortfolioReviewProcess {
  status: PortfolioReviewStatus;
}

export interface CreatePortfolioReviewCommand {
  websites: string[];
}

export interface PortfolioReviewValidation {
  method: "images" | "links";
  files: UploadFileItem[];
  websites: string[];
}

export interface ConfirmPortfolioInviteCommand {
  id: number;
  code: string;
}

export class PortfolioReviewProcessValidator {
  private cmd: PortfolioReviewValidation;

  constructor(cmd: PortfolioReviewValidation) {
    this.cmd = cmd;
  }

  validate(): ValidationError[] {
    const errors: ValidationError[] = [];

    if (this.cmd.method === "images") {
      if (this.cmd.files.length < 1) {
        errors.push({
          i18nKey:
            "account.onboarding.reviewPortfolioForms.errors.notEnoughFiles",
          field: "files",
          options: { currentCount: this.cmd.files.length }
        });
      } else if (this.cmd.files.length > 20) {
        errors.push({
          i18nKey:
            "account.onboarding.reviewPortfolioForms.errors.maxFilesExceeded",
          field: "files",
          options: { currentCount: this.cmd.files.length }
        });
      }
    } else if (this.cmd.method === "links") {
      if (this.cmd.websites.length === 0) {
        errors.push({
          i18nKey: "shared.forms.invalidField",
          field: "websites"
        });
      } else {
        this.cmd.websites.forEach(website => {
          if (website.length === 0) {
            errors.push({
              i18nKey: "shared.forms.invalidField",
              field: "websites"
            });
          }
        });
      }
    }

    return errors;
  }
}
