import { Button, Checkbox, Toast } from "app/shared";
import React, { Component } from "react";
import { Trans } from "react-i18next";
import { updateUser } from "../services";
import { Flex, Box, Text } from "rebass";
import { UpdateUserDTO } from "../dto";
import { TableLayout } from "app/shared/tableLayout";
import { UserSkills, UserTypes } from "../editMyInfo/constants";
import { FormFieldSet } from "app/shared/forms";
import { User, ValidationError } from "models";
import { BecomeVerifiedPopup } from "../popups";

interface Props {
  user: User;
  onSkip?: () => void;
  onComplete: (user: User) => void;
  hideHeader?: boolean;
}

interface State {
  skills: string[];
  userTypes: string[];
  disabled: boolean;
  errors: ValidationError[];
}

export class SkillRoles extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      skills: props.user.skills,
      userTypes: props.user.userTypes,
      disabled: false,
      errors: []
    };
  }

  validateForm(): ValidationError[] {
    const { userTypes } = this.state;
    const errors: ValidationError[] = [];

    if (userTypes.length === 0) {
      errors.push({ i18nKey: "shared.forms.invalidField", field: "userTypes" });
    }

    return errors;
  }

  onSubmit() {
    const { onComplete, user } = this.props;
    const { skills, userTypes } = this.state;
    const errors = this.validateForm();

    if (user && errors.length === 0) {
      this.setState({ disabled: true, errors: [] }, () => {
        const updateUserDTO: UpdateUserDTO = {
          userId: user.id,
          skills,
          userTypes
        };
        updateUser(updateUserDTO)
          .then(user => {
            this.setState({ disabled: false });
            onComplete(user);
            Toast.success({
              title: {
                key: "account.onboarding.confirmationToast"
              }
            });
          })
          .catch(error => {
            Toast.apiError(error);
            this.setState({ disabled: false });
          });
      });
    } else {
      this.setState({ errors });
    }
  }

  toggleSkill(skill: string) {
    const { skills } = this.state;
    this.setState({
      skills: skills.includes(skill)
        ? skills.filter(it => it !== skill)
        : [...skills, skill]
    });
  }

  /**
   * User types can contain both producer/buyer or only just watching
   */
  toggleUserType(type: string) {
    const { userTypes } = this.state;
    const justWatching = "justWatching";
    const newUserTypes = userTypes.includes(type)
      ? userTypes.filter(it => it !== type)
      : [...userTypes, type];

    if (newUserTypes.includes(justWatching) && newUserTypes.length > 1) {
      if (type === justWatching) {
        this.setState({ userTypes: [justWatching] });
      } else {
        this.setState({
          userTypes: newUserTypes.filter(it => it !== justWatching)
        });
      }
    } else {
      this.setState({ userTypes: newUserTypes });
    }
  }

  render() {
    const { onSkip, hideHeader } = this.props;
    const { skills, userTypes, disabled, errors } = this.state;
    const userTypesError = errors.find(it => it.field === "userTypes")?.i18nKey;

    return (
      <Box px={[2, 0]}>
        {!hideHeader && (
          <Box sx={{ textAlign: "center" }} mb={8}>
            <Text
              fontSize={[3]}
              sx={{
                display: "inline-block",
                textAlign: "left",
                lineHeight: "24px"
              }}>
              <Trans i18nKey="account.onboarding.skillRoles.headerText" />
            </Text>
          </Box>
        )}
        <Text fontSize={[1, 3, 7]} variant="grotTextCaps">
          <Trans i18nKey="account.onboarding.skillRoles.userTypeTitle" />
        </Text>
        <FormFieldSet contentPadding={[]} sx={{ border: 1, marginBottom: 8 }}>
          <Box p={[1, 2]}>
            {userTypesError && (
              <Text color="text.danger" pb={1}>
                <Trans i18nKey={userTypesError} />
              </Text>
            )}
            <TableLayout
              mx="auto"
              columns={3}
              tableNodes={UserTypes.map(type => (
                <Checkbox
                  size={18}
                  key={type.name}
                  name={type.name}
                  checked={userTypes.includes(type.name)}
                  onChange={e => this.toggleUserType(type.name)}>
                  <Text fontSize={[1, 2]} variant="caps">
                    <Trans i18nKey={type.i18n} />
                  </Text>
                </Checkbox>
              ))}
            />
          </Box>
        </FormFieldSet>
        <Text fontSize={[1, 3, 7]} variant="grotTextCaps">
          <Trans i18nKey="account.onboarding.skillRoles.skillsTitle" />
        </Text>
        <FormFieldSet contentPadding={[]} sx={{ border: 1 }}>
          <Box p={[1, 2]} mt={3}>
            <TableLayout
              columns={4}
              width="100%"
              tableNodes={UserSkills.map(skill => (
                <Checkbox
                  size={18}
                  key={skill.name}
                  name={skill.name}
                  checked={skills.includes(skill.name)}
                  onChange={e => this.toggleSkill(skill.name)}>
                  <Text fontSize={[1, 2]} variant="caps">
                    <Trans i18nKey={skill.i18n} />
                  </Text>
                </Checkbox>
              ))}
            />
          </Box>
        </FormFieldSet>
        <Flex justifyContent="space-between" mt={8}>
          {onSkip && (
            <BecomeVerifiedPopup
              trigger={
                <Flex
                  justifyContent="flex-end"
                  alignItems="center"
                  width="100%">
                  <Button
                    variant="white"
                    width={["100%", "70%"]}
                    p={3}
                    disabled={disabled}>
                    <Trans i18nKey="account.onboarding.skillRoles.skip" />
                  </Button>
                </Flex>
              }
              onSkip={close => {
                onSkip?.();
                close();
              }}
              onComplete={close => close()}
            />
          )}
          <Flex
            justifyContent={onSkip ? "left" : "center"}
            alignItems="center"
            width="100%"
            ml={onSkip ? 4 : 0}>
            <Button
              variant="blue"
              width={onSkip ? ["100%", "70%"] : "50%"}
              p={3}
              disabled={disabled}
              onClick={() => this.onSubmit()}>
              <Trans
                i18nKey={
                  onSkip
                    ? "account.onboarding.skillRoles.submit"
                    : "account.onboarding.save"
                }
              />
            </Button>
          </Flex>
        </Flex>
      </Box>
    );
  }
}
