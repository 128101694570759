/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, GridLabel, Metadata, LocationLabel } from "app/shared";
import { AuthUserContext } from "authentication/session";
import { checkSameUser } from "authentication/utils";
import { User } from "models";
import React, { useState } from "react";
import { Trans } from "react-i18next";
import { Box, Flex, Text } from "rebass";
import { FollowMessageButton } from "../FollowMessageButton";
import { UserRelationInfo } from "../models";
import { UserPicture } from "../UserPicture";
import { getFollowers, getFollowing } from "../services";
import { useMediaQueryContext } from "../../../styles/context";

interface Props {
  user: User;
  info?: UserRelationInfo;
}
export const FollowsCounter = (mode: string, uid: string) => {
  const [count, setCount] = useState<any>("");
  const call =
    mode === "followers" ? getFollowers(uid, 0, 0) : getFollowing(uid, 0, 0);
  call.then(({ totalElements }) => setCount(totalElements));
  return count;
};

export const ProfileInfo: React.FC<Props> = ({ user, info }) => {
  const { isSmallMedium } = useMediaQueryContext();

  return (
    <Box px={[2, 0]}>
      <Flex sx={{ borderBottom: 1 }}>
        <Box sx={{ border: 1, borderBottom: 0 }}>
          <Metadata title={user.professionalName} image={user.picture} />
          <UserPicture
            picture={user.picture}
            height={["100px", "116px", "116px", "164px"]}
            userVerified={user.status}
            verifiedIconSize={[35, 45]}
          />
        </Box>
        <Box sx={{ flexGrow: 1 }}>
          <Flex flexDirection="column" height="100%">
            <Flex
              flexDirection="row"
              sx={{
                flexGrow: 1,
                "& > *": {
                  px: [1, 2, 3],
                  py: [0, 1, 2],
                  border: 1,
                  borderLeft: 0,
                  borderColor: "black.0"
                }
              }}>
              <Flex sx={{ flexGrow: 1 }} alignItems="center">
                <Text
                  fontSize={[1, 5, 6, 8, 8]}
                  variant={"grotTextCaps"}
                  sx={{ wordBreak: "break-word" }}>
                  {user.professionalName}
                </Text>
              </Flex>
            </Flex>
            <Flex
              flexDirection="row"
              sx={{
                flexGrow: 1,
                "& > *": {
                  px: [1, 2, 3],
                  py: [0, 1, 2],
                  borderRight: 1,
                  borderColor: "black.0"
                }
              }}>
              <Flex
                width={["50%", "70%"]}
                sx={{ flexGrow: 1 }}
                py={2}
                flexDirection="column"
                justifyContent={["center", "space-around"]}>
                <LocationLabel
                  fontSize={[1, 4, 6]}
                  variant={"grotText"}
                  city={user.city}
                  country={user.country}
                  latitude={user.latitude}
                  longitude={user.longitude}
                  onClickOpenMap={true}
                />
              </Flex>
              <Flex
                width={["50%", "30%"]}
                py={2}
                pl={-5}
                flexDirection="row"
                justifyContent="flex-start">
                <Button
                  href={`/users/${user.id}/connections`}
                  variant="transparent">
                  <GridLabel i18nKey="account.profile.profileInfo.connections" />
                </Button>
                <Flex
                  flexWrap="wrap"
                  flexDirection="row"
                  justifyContent="center"
                  pl={[1, 6]}
                  mt={[2, -2]}>
                  <Text fontSize={[2, 4, 12]} pr={[1, 2]}>
                    {FollowsCounter("followers", user.id) +
                      FollowsCounter("following", user.id)}
                  </Text>
                </Flex>
              </Flex>
            </Flex>
            <Flex>
              <AuthUserContext.Consumer>
                {({ authUser }) =>
                  authUser && checkSameUser(user.id, authUser) ? (
                    <Button
                      width="100%"
                      variant="link.button.black"
                      href="/edit-my-info">
                      <Trans i18nKey="account.profile.editMyInfo" />
                    </Button>
                  ) : (
                    <Box width="100%" sx={{ borderRight: 1 }}>
                      <FollowMessageButton
                        chatText={isSmallMedium ? "" : "account.chat.label"}
                        followBorderLeft={0}
                        user={user}
                        info={info}
                        variant="white"
                        donationIcon={isSmallMedium ? true : undefined}
                        borderBottom={0}
                        followWidth={isSmallMedium ? "100%" : undefined}
                        followBorderRight={0}
                        followBorderBottom={0}
                      />
                    </Box>
                  )
                }
              </AuthUserContext.Consumer>
            </Flex>
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
};
