/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-function */
import { SliderMarks } from "app/search/documents/constants";
import { CollapsableButton, Slider } from "app/shared";
import React, { useState } from "react";
import { Box } from "rebass";

interface Props {
  label: string;
  onChange: (value: number[]) => void;
  marks: SliderMarks<string>;
  storedSlider: number[];
  min: number;
  max: number;
  step: number;
}

const SliderFacetView: React.FC<Props> = ({
  label,
  onChange,
  storedSlider,
  marks,
  min,
  max,
  step
}) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  return (
    <Box
      sx={{
        borderRight: 1,
        borderLeft: 1,
        borderBottom: 1,
        borderColor: "black.0"
      }}
      px={[1, 1, 1, 1, 3]}
      py={2}>
      <CollapsableButton
        fontSize={[2, 4, 5, 6, 8]}
        mb={!isCollapsed ? [1, 2, 3] : 0}
        sx={{ fontFamily: "heading", textTransform: "uppercase" }}
        onClick={() => setIsCollapsed(!isCollapsed)}
        collapsed={isCollapsed}>
        {label}
      </CollapsableButton>

      {!isCollapsed && (
        <Box paddingBottom={[3]} px={[2]}>
          <Slider
            marks={marks}
            min={min}
            max={max}
            step={step}
            defaultValue={storedSlider}
            onChange={onChange}
          />
        </Box>
      )}
    </Box>
  );
};

export default SliderFacetView;
