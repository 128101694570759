import firebase from "firebase";

export interface IdToken {
  token: string;
  roles: string[];
  expirationTime: string;
  issuedAtTime: string;
}

export enum SignInResult {
  Success,
  RegistrationRequired,
  PasswordRequired,
  Unknown
}

export interface SignInResponse {
  uid?: string;
  email?: string;
  credentials?: firebase.auth.OAuthCredential;
  result: SignInResult;
  photoURL?: string;
  displayName?: string;
}
