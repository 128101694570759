import React, { useState, useEffect } from "react";
import { Box } from "rebass";
import { Button, Toast } from "app/shared";
import { Trans } from "react-i18next";
import { withAuthorization } from "authentication/session";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { compose } from "recompose";
import { checkSameUser } from "authentication/utils";
import { AuthUserContext } from "authentication/session";
import { TransactionItemLicense } from "marketplace";
import { getTransactionItemLicenseLink, getDocument } from "../services";
import { Document } from "models";
import { LicenseStandard } from "./LicenseStandard";
import { LicenseCreativeCommons } from "./LicenseCreativeCommons";

interface Params {
  id: string;
}

type Props = RouteComponentProps<Params>;

const LicenseBase: React.FC<Props> = ({ match }) => {
  const transactionItemId = parseInt(match.params.id, 10);
  const [license, setLicense] = useState<TransactionItemLicense | undefined>(
    undefined
  );
  const [document, setDocument] = useState<Document | undefined>(undefined);
  useEffect(() => {
    const getInfo = async () => {
      try {
        const license = await getTransactionItemLicenseLink(transactionItemId);
        setLicense(license);
        const document = await getDocument(license.transactionItem.documentId);
        setDocument(document);
      } catch (error) {
        Toast.apiError(error);
      }
    };
    getInfo();
  }, [transactionItemId]);
  if (!license) return null;

  return (
    <AuthUserContext.Consumer>
      {({ authUser }) =>
        authUser &&
        (checkSameUser(license.buyer.id, authUser) ||
          checkSameUser(license.seller.id, authUser)) && (
          <Box fontSize={4} mb={5}>
            <Button
              width="100%"
              variant="black"
              mb={3}
              onClick={() => window.print()}
              sx={{
                position: "fixed",
                top: 0,
                left: 0,
                "@media print": {
                  display: "none"
                }
              }}>
              <Trans i18nKey="documents.receipts.license.print" />
            </Button>
            <Box px={3} pt={7}>
              {license.transactionItem.license === "royaltyfree" && (
                <LicenseStandard license={license} document={document} />
              )}
              {license.transactionItem.license === "creativecommons" && (
                <LicenseCreativeCommons license={license} document={document} />
              )}
            </Box>
          </Box>
        )
      }
    </AuthUserContext.Consumer>
  );
};

export const License = compose<Props, any>(
  withRouter,
  withAuthorization()
)(LicenseBase);
