import React from "react";

import { ContentArea } from "layout";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { compose } from "recompose";
import { Trans } from "react-i18next";

const PrivacyPolicyBase: React.FC<RouteComponentProps> = ({ history }) => {
  return (
    <ContentArea>
      <Trans i18nKey="about.section13.content" />
    </ContentArea>
  );
};

export const PrivacyPolicy = compose<RouteComponentProps, any>(withRouter)(
  PrivacyPolicyBase
);
