import React, { Component } from "react";
import { User } from "models";
import { PersonalInfo } from "./PersonalInfo";
import { Box } from "rebass";
import { BusinessInfoForm } from "./BusinessInfoForm";

interface Props {
  user: User;
  onComplete: (user: User) => void;
  onSkip?: () => void;
  hideHeader?: boolean;
  submiti18n?: string;
}

interface State {
  user: User;
  savePersoalInfo: boolean;
}

export class BusinessInfo extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      user: props.user,
      savePersoalInfo: false
    };
  }

  render() {
    const { onSkip, onComplete, hideHeader, submiti18n } = this.props;
    const { user, savePersoalInfo } = this.state;

    return (
      <Box px={[2, 0]}>
        <BusinessInfoForm
          hideHeader={hideHeader}
          user={user}
          onSkip={onSkip}
          onComplete={updatedUser => {
            this.setState({ savePersoalInfo: true });
            onComplete(updatedUser ?? user);
          }}
          showBilling={true}
          showPayout={true}
          submiti18n={submiti18n}
          submitVariant="blue"
          submitWidth={onSkip ? undefined : "50%"}
          submitPadding={onSkip ? undefined : 3}
          children={
            <PersonalInfo
              user={user}
              onComplete={user => this.setState({ user })}
              submiti18n="account.onboarding.businessInfo.submitPersonalInfo"
              saveMode={savePersoalInfo}
              hideSaveButton={true}
            />
          }
        />
      </Box>
    );
  }
}
