import {
  Button,
  ElementOptions,
  FormattedDate,
  LocationLabel
} from "app/shared";
import { Collection, DocumentThumbnail, User } from "models";
import React, { useState, useEffect } from "react";
import { Box, BoxProps, Flex, Text } from "rebass";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { compose } from "recompose";
import { ContentArea } from "layout";
import { getCollection } from "./services";
import { PhotosListSearch } from "app/search/documents/PhotosListSearch";
import { createSearchUrl, getRaw } from "app/search/utils";
import { AuthUserContext } from "authentication/session";
import { checkSameUser } from "authentication/utils";
import { BucketAddItemsButton } from "app/buckets";
import { CollectionShareButton } from "./collectionDetails/CollectionShareButton";
import { LikeButton } from "app/documents/documentDetails/LikeButton";
import { CollectionInfo } from "./models";
import { DocumentView } from "app/documents";
import MasonryLayout from "app/shared/masonryLayout";
import DocumentUserInfo from "app/documents/documentDetails/DocumentUserInfo";
import { getUserProfile } from "app/account/services";
import { CollectionModeButtons } from ".";
import { ReportUserButton } from "app/shared/elementOptions/ReportUserButton";
import { DonationPopUp } from "app/donations/DonationPopup";
import { FollowButton } from "app/account/FollowButton";
import { UserPicture } from "app/account";
import { UserRelationInfo } from "app/account/models";
import i18next from "i18next";

interface Params {
  id: string;
}

interface PropsExternal extends BoxProps {
  width?: number;
}

interface Props extends PropsExternal, RouteComponentProps<Params> {}

interface MagazineDocument extends DocumentThumbnail {
  userLabels: string[];
}

const CollectionMagazineBase: React.FC<Props> = ({
  width,
  match,
  history,
  ...rest
}) => {
  const [collection, setCollection] = useState<Collection | undefined>(
    undefined
  );
  const [collectionInfo, setCollectionInfo] = useState<
    CollectionInfo | undefined
  >(undefined);
  const [userInfo, setUserInfo] = useState<UserRelationInfo | undefined>(
    undefined
  );
  const [user, setUser] = useState<User | undefined>(undefined);

  const loadCollection = () => {
    const collectionId = parseInt(match.params.id, 10);
    getCollection(collectionId)
      .then(collection => {
        setCollection(collection);
        getUserProfile(collection.uid).then(user => setUser(user));
      })
      .catch(err => {
        if (err.response.status === 404) {
          history.push("/not-found"); // redirect to not found page
        }
        throw err;
      });
  };

  useEffect(() => {
    loadCollection();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ContentArea py={0} px={[2, 0]}>
      <Box {...(rest as any)}>
        {collection && (
          <>
            <CollectionModeButtons
              collectionId={collection.id}
              activeButton="article"
              mb={5}
            />
            {user && (
              <>
                <Flex width="100%" pt={[5, 2]}>
                  <Flex>
                    <Button
                      href={`/users/${user.id}`}
                      width="100%"
                      variant="transparent"
                      bg="white"
                      pr={2}
                      sx={{ "&:hover": { opacity: "1 !important" } }}>
                      {user.picture ? (
                        <UserPicture
                          verifiedIconSize={16}
                          userVerified="verified"
                          picture={user.picture}
                          height="35px"
                        />
                      ) : (
                        <UserPicture
                          verifiedIconSize={16}
                          userVerified="verified"
                          picture={undefined}
                          height="35px"
                        />
                      )}
                    </Button>
                  </Flex>
                  {user.professionalName && (
                    <Flex bg="white" alignItems="center">
                      <Button href={`/users/${user.id}`}>
                        <Flex>
                          <Text
                            pl={3}
                            color="black"
                            variant="grotTextCaps"
                            sx={{ lineHeight: 1 }}
                            fontSize={2}>
                            {user.professionalName}
                          </Text>
                        </Flex>
                      </Button>
                    </Flex>
                  )}
                  <AuthUserContext.Consumer>
                    {({ authUser }) => {
                      const disabled =
                        !authUser ||
                        (authUser && user && checkSameUser(user.id, authUser)); //disable follow button if is the story of the user logged
                      return (
                        !disabled &&
                        user && (
                          <Flex pl={10}>
                            <ElementOptions
                              closeMenuOnClick={false}
                              options={[
                                () =>
                                  user.id && (
                                    <>
                                      <FollowButton
                                        uid={user.id}
                                        width="100%"
                                        info={userInfo}
                                        onInfoChange={userInfo =>
                                          setUserInfo(userInfo)
                                        }
                                        variant="transparent"
                                        color="black"
                                        py={2}
                                      />
                                      <DonationPopUp
                                        width="100%"
                                        uid={user.id}
                                        sx={{ borderLeft: 0, borderRight: 0 }}
                                      />
                                      <ReportUserButton
                                        userId={user.id}
                                        sx={{
                                          border: 0,
                                          textAlign: "center",
                                          color: "red"
                                        }}
                                      />
                                    </>
                                  )
                              ]}
                              elementId={28}
                              iconName="MoreOptions"
                              iconSize={18}
                              optionsWidth={["139px", "179px"]}
                              backgroundAlwaysActive={true}
                              sx={{
                                borderRadius: 0,
                                borderRight: "none"
                              }}
                              popupSx={{
                                position: "relative",
                                top: "26px",
                                zIndex: 1,
                                left: ["-130px", "-170px"]
                              }}
                            />
                          </Flex>
                        )
                      );
                    }}
                  </AuthUserContext.Consumer>
                </Flex>
              </>
            )}
            <Text variant="dmSerifDisp" fontSize={[10, 15]}>
              {collection.title}
            </Text>
            <Flex pt={2} alignItems="center">
              <Flex width="100%" alignItems="center">
                <Text pr={4} fontSize={[1, 2]} color={"black"} variant="caps">
                  <FormattedDate format="string" date={collection.createdAt} />
                </Text>
                {(collection.city || collection.country) && (
                  <Text
                    variant="caps"
                    color="blue"
                    sx={{ textDecoration: "underline" }}>
                    <LocationLabel
                      fontSize={[1, 2]}
                      city={collection.city}
                      country={collection.country}
                      latitude={collection.latitude}
                      longitude={collection.longitude}
                      onClickOpenMap={true}
                    />
                  </Text>
                )}
              </Flex>
            </Flex>
            <Box>
              <Text pt={6} variant="grotText">
                {collection.description}
              </Text>
            </Box>
            <Flex mt={6} justifyContent="flex-end" alignItems="center">
              <Flex>
                <Flex pr={4}>
                  <AuthUserContext.Consumer>
                    {({ authUser }) =>
                      authUser && (
                        <BucketAddItemsButton
                          bucketItemIds={[
                            { type: "collection", id: collection.id }
                          ]}
                          variant="icon"
                        />
                      )
                    }
                  </AuthUserContext.Consumer>
                </Flex>
                <Flex>
                  <CollectionShareButton
                    name="Share"
                    variant="transparent"
                    display="block"
                    collection={collection}
                    magazineLink={true}
                  />
                </Flex>
                <Flex pl={4}>
                  <AuthUserContext.Consumer>
                    {({ authUser }) =>
                      authUser &&
                      collection.owner &&
                      !checkSameUser(collection.owner?.id, authUser) && (
                        <>
                          <LikeButton
                            name="Like"
                            variant="icon"
                            display="block"
                            mode="collection"
                            documentId={collection.id}
                            collectionInfo={collectionInfo}
                            onInfoChange={info =>
                              setCollectionInfo(info as CollectionInfo)
                            }
                          />
                        </>
                      )
                    }
                  </AuthUserContext.Consumer>
                </Flex>
              </Flex>
            </Flex>
            <Box width="100%" mt={6}>
              <PhotosListSearch<MagazineDocument>
                filters={[
                  {
                    field: "collections",
                    values: [collection.id.toString()],
                    type: "any"
                  }
                ]}
                sortField="created_at"
                sortDirection="asc"
                view={results => {
                  const labels: string[] = [];
                  results.map(document =>
                    document.userLabels.map(label => labels.push(label))
                  );
                  return (
                    <>
                      {results.map(document => (
                        <Flex
                          justifyContent="center"
                          alignItems="center"
                          flexDirection="column">
                          <Box width={["100%", "50%"]}>
                            <MasonryLayout
                              columns={1}
                              gap={1}
                              items={[
                                <DocumentView
                                  key={document.id}
                                  document={document}
                                  documentsList={
                                    results.length > 1 ? results : undefined
                                  }
                                  width="100%"
                                  showUserInfo={false}
                                  hideText={true}
                                  customPb={0}
                                  largeThumbnail={true}
                                  sideIcons={true}
                                />
                              ]}
                              itemDimensions={[
                                {
                                  height: document.height ?? 0,
                                  width: document.width ?? 0
                                }
                              ]}
                            />
                            <Box mb={6} sx={{ wordBreak: "break-word" }}>
                              <Text fontSize={[3, "25px"]} pt={2}>
                                {document.title ??
                                  i18next.t("documents.documentView.noTitle")}
                              </Text>
                              {document.description !== "null" &&
                                document.description && (
                                  <>
                                    <Text pt={2} variant="grotText">
                                      {document.description}
                                    </Text>
                                  </>
                                )}
                            </Box>
                          </Box>
                        </Flex>
                      ))}
                      <Flex flexWrap="wrap">
                        {Array.from(new Set(labels)).map(label => (
                          <Button
                            variant="link.button.blue"
                            bg="brand.0"
                            mr={2}
                            mb={2}
                            href={createSearchUrl(
                              "document",
                              ["document"],
                              [
                                {
                                  field: "labels",
                                  values: [label],
                                  type: "any"
                                }
                              ]
                            )}>
                            {label}
                          </Button>
                        ))}
                      </Flex>
                    </>
                  );
                }}
                resultsPerPage={5}
                mapResults={(results: any[]) =>
                  results.map(result => ({
                    id: parseInt(getRaw(result, "real_id") ?? "0", 10),
                    thumbnail: getRaw(result, "thumbnail") ?? "",
                    height: parseInt(getRaw(result, "height") ?? "1", 10),
                    width: parseInt(getRaw(result, "width") ?? "1", 10),
                    city: getRaw(result, "city") ?? "",
                    country: getRaw(result, "country") ?? "",
                    takenAt: getRaw(result, "taken_at") ?? "",
                    createdAt: getRaw(result, "created_at") ?? "",
                    userLabels: (getRaw(
                      result,
                      "labels"
                    ) as unknown) as string[],
                    owner: {
                      id: getRaw(result, "uid") ?? "",
                      name: getRaw(result, "owner") ?? "",
                      picture: getRaw(result, "owner_picture") ?? ""
                    },
                    title: ((getRaw(
                      result,
                      "translations"
                    ) as unknown) as string[])?.[0]?.split("=")?.[1],
                    description: ((getRaw(
                      result,
                      "translations"
                    ) as unknown) as string[])?.[0]?.split("=")?.[2]
                  }))
                }
              />
            </Box>
            {user && <DocumentUserInfo user={user} />}
          </>
        )}
      </Box>
    </ContentArea>
  );
};

CollectionMagazineBase.defaultProps = {};

export const CollectionMagazine = compose<Props, any>(withRouter)(
  CollectionMagazineBase
);
