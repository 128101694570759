import { Button, Toast } from "app/shared";
import { Firebase, withFirebase } from "authentication/firebase";
import { AuthUserContext } from "authentication/session";
import { checkSameUser } from "authentication/utils";
import React, { Component } from "react";
import { Trans } from "react-i18next";
import { BoxProps, Flex, Text } from "rebass";
import { compose } from "recompose";
import { UserRelationInfo } from "./models";
import { follow, getUserInfo, unfollow } from "./services";

interface PropsExternal extends BoxProps {
  uid: string;
  info?: UserRelationInfo;
  fontSize?: number | number[];
  fontName?: string;
  onInfoChange?: (info?: UserRelationInfo) => void;
  borderBottom?: number;
  borderRight?: number;
}

interface Props extends PropsExternal {
  firebase: Firebase;
}

interface State {
  follows: boolean;
}

class FollowButtonBase extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      follows: props.info?.follow ?? false
    };
  }

  componentDidMount() {
    this.updateUserInfo();
  }

  /**
   * Support uid change on prop update (e.g. clicking on another document inside a document details page)
   */
  componentDidUpdate(prevProps: Props) {
    if (prevProps.uid !== this.props.uid) {
      this.updateUserInfo();
    }
  }

  updateUserInfo() {
    const { firebase, info, uid, onInfoChange } = this.props;
    if (info === undefined && firebase.isLogged()) {
      // Optimization to prevent checking if user is following itself

      if (uid === firebase.getCurrentUser().uid) {
        this.setState({ follows: false });
      } else {
        getUserInfo(uid).then(info => {
          this.setState({ follows: info.follow });
          onInfoChange?.(info);
        });
      }
    }
  }

  handleOnClick = () => {
    const { firebase, uid, onInfoChange } = this.props;
    const { follows } = this.state;

    if (firebase.isLogged()) {
      const call = follows ? unfollow(uid) : follow(uid);
      call
        .then(() => {
          if (follows) {
            Toast.success({
              title: { key: "account.followButton.unfollowToast" }
            });
          } else {
            Toast.success({
              title: { key: "account.followButton.followToast" }
            });
          }

          this.setState({ follows: !follows });
          onInfoChange?.({
            follow: !follows,
            view: false
          });
        })
        .catch(error => Toast.apiError(error));
    } else {
      Toast.error({
        title: { key: "account.followButton.notLoggedInToast" }
      });
    }
  };

  render() {
    const {
      uid,
      fontSize,
      fontName,
      onInfoChange,
      info,
      borderBottom,
      borderRight,
      ...rest
    } = this.props;
    const { follows } = this.state;

    return (
      <AuthUserContext.Consumer>
        {({ authUser }) => {
          const disabled =
            !authUser || (authUser && checkSameUser(uid, authUser)); // disable if not logged in or if own user
          return (
            <Button
              variant={follows ? "blue" : "white"}
              onClick={() => this.handleOnClick()}
              disabled={disabled}
              bg={follows ? "blue" : "white"}
              sx={{ borderBottom: borderBottom, borderRight: borderRight }}
              {...(rest as any)}>
              <Flex
                width="100%"
                height="100%"
                justifyContent="center"
                alignItems="center">
                <Text
                  sx={{ textTransform: "uppercase" }}
                  fontSize={fontSize ?? [0, 3]}
                  variant={fontName ? fontName : "grotText"}
                  color={follows ? "white" : ""}>
                  <Trans
                    i18nKey={`account.followButton.${
                      follows ? "connected" : "connect"
                    }`}
                  />
                </Text>
              </Flex>
            </Button>
          );
        }}
      </AuthUserContext.Consumer>
    );
  }
}

export const FollowButton = compose<Props, PropsExternal>(withFirebase)(
  FollowButtonBase
);
