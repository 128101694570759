import React from "react";
import Svg, { SvgProps } from "primitives/Svg";

const SvgTwitter = ({ width, height, size, color }: SvgProps) => (
  <Svg
    width={size || width}
    height={size || height}
    viewBox="0 0 49 49"
    color={color}>
    <g clipPath="url(#Twitter_svg__clip0_9841_156707)">
      <path d="M.516.398h48V10.58h-48zM.516 39.672h48v8.727h-48z" />
      <path d="M45.875 3.039c-1.76-1.76-3.88-2.64-6.36-2.64h-30c-2.478 0-4.599.88-6.359 2.64-1.76 1.76-2.64 3.88-2.64 6.36v30c0 2.478.88 4.598 2.64 6.359 1.76 1.76 3.88 2.64 6.36 2.64h30c2.478 0 4.599-.88 6.358-2.64 1.76-1.76 2.641-3.88 2.641-6.36v-30c0-2.479-.88-4.599-2.64-6.36zm-8.64 15.828c.02.188.03.469.03.844 0 1.75-.255 3.506-.765 5.266a18.843 18.843 0 01-2.344 5.062 19.694 19.694 0 01-3.765 4.282c-1.458 1.239-3.208 2.229-5.25 2.968-2.041.74-4.23 1.11-6.562 1.11-3.625 0-6.98-.98-10.063-2.938.5.063 1.022.094 1.563.094 3.02 0 5.74-.937 8.156-2.813a6.227 6.227 0 01-3.796-1.312 6.67 6.67 0 01-2.329-3.25c.542.083.948.124 1.219.124.458 0 .99-.083 1.593-.25-1.52-.291-2.801-1.046-3.843-2.265-1.042-1.219-1.563-2.609-1.563-4.171v-.063c1.104.52 2.146.791 3.125.813-1.896-1.272-2.844-3.094-2.844-5.47a6.5 6.5 0 01.906-3.312 18.6 18.6 0 006 4.844 18.579 18.579 0 007.563 2.031c-.104-.396-.156-.896-.156-1.5 0-1.813.64-3.36 1.922-4.64 1.28-1.282 2.828-1.923 4.64-1.923 1.917 0 3.511.688 4.782 2.063 1.437-.27 2.834-.802 4.188-1.594-.521 1.604-1.49 2.823-2.907 3.656a13.914 13.914 0 003.78-1.062 12.763 12.763 0 01-3.28 3.406z" />
    </g>
    <defs>
      <clipPath id="Twitter_svg__clip0_9841_156707">
        <path transform="translate(.516 .398)" d="M0 0h48v48H0z" />
      </clipPath>
    </defs>
  </Svg>
);

SvgTwitter.defaultProps = {
  width: 24,
  height: 24
};
export default SvgTwitter;
