import { useEffect, useState } from "react";

export const useKeyPress = (targetKeyCode: number, handler: () => void) => {
  const [keyPressed, setKeyPressed] = useState(false);

  const downHandler = ({ keyCode }: any) => {
    if (keyCode === targetKeyCode) {
      handler();
      setKeyPressed(true);
    }
  };

  const upHandler = ({ keyCode }: any) => {
    if (keyCode === targetKeyCode) {
      setKeyPressed(false);
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", downHandler);
    window.addEventListener("keyup", upHandler);

    return () => {
      window.removeEventListener("keydown", downHandler);
      window.removeEventListener("keyup", upHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handler]);

  return keyPressed;
};
