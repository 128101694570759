import { Button, Icon } from "app/shared";
import React from "react";
import { default as ReactPopup } from "reactjs-popup";
import { Box, Heading } from "rebass";

interface Props {
  children: JSX.Element;
  heading?: string;
  trigger?: JSX.Element;
  open?: boolean;
  disabled?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
}

/**
 * Notes:
 * - Disable=true closes the popup if open and disables its trigger.
 */
export const Modal: React.FC<Props> = ({
  trigger,
  children,
  heading,
  open,
  disabled,
  onOpen,
  onClose
}) => (
  <ReactPopup
    trigger={trigger}
    modal
    closeOnDocumentClick={false}
    closeOnEscape={true}
    contentStyle={{
      width: "100%",
      padding: 0,
      border: 0
    }}
    overlayStyle={{
      backgroundColor: "black",
      padding: 0,
      margin: 0
    }}
    open={open}
    disabled={disabled}
    onOpen={() => onOpen?.()}
    onClose={() => onClose?.()}>
    {(close: () => void) => (
      <Box minHeight="100vh">
        <Button
          bg="fill.0"
          color="text.default"
          onClick={close}
          display="grid"
          mx={2}
          sx={{
            position: "absolute",
            top: 3,
            left: ["90%", "90%", "93%", "93%", "85.5%"],
            p: 0,
            zIndex: 1
          }}>
          <Icon name="Close" size={16} />
        </Button>
        {heading && (
          <Heading textAlign="center" mb={3}>
            {heading}
          </Heading>
        )}
        {children}
      </Box>
    )}
  </ReactPopup>
);
