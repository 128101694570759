import { Button, Popup } from "app/shared";
import i18next from "i18next";
import React, { Component } from "react";
import { Trans } from "react-i18next";
import { Box, BoxProps, Flex } from "rebass";
import { NewsletterForm } from "app/shared";

interface Props extends BoxProps {
  width?: string | string[];
  height?: string | string[];
  i18n?: string;
  open?: boolean;
  onCompleted?: () => void;
  onClose?: () => void;
}

export class NewsletterPopUp extends Component<Props> {
  onSubmit(close: () => void) {
    close();
  }

  render() {
    const { open, onClose, width, height, i18n } = this.props;
    const trigger =
      open !== undefined ? (
        undefined
      ) : (
        <Button
          width={width ? width : ["117px", "227px", "227px", "227px", "365px"]}
          height={height ? height : ""}>
          <Flex
            width="100%"
            height="100%"
            alignItems="center"
            justifyContent="center">
            <Trans i18nKey={i18n ? i18n : "shared.newsletter.buttonTitle"} />
          </Flex>
        </Button>
      );

    return (
      <Popup
        open={open}
        trigger={trigger}
        variant="full-buttons"
        heading={i18next.t("shared.newsletter.popUpTitle")}
        submit={close => this.onSubmit(close)}
        submitText={i18next.t("shared.newsletter.submitButton")}
        hideActionButtons={true}
        onClose={onClose}>
        <Box mx={-2} mb={-2}>
          <NewsletterForm />
        </Box>
      </Popup>
    );
  }
}
