import React from "react";
import { Box, Text } from "rebass";
import { CollapsableSection } from "./CollapsableSection";
import { Trans } from "react-i18next";
import { Icon } from "app/shared";
import i18n from "i18n/i18n";
import styled from "@emotion/styled";

const HighlightText = styled.span`
  color: blue;
`;

interface NewlineTextProps {
  text: string;
}

const NewlineText: React.FC<NewlineTextProps> = ({ text }) => {
  const newText = text.split("\n").map((str, index, array) => (
    <React.Fragment key={index}>
      {str}
      {index === array.length - 1 ? null : <br />}
    </React.Fragment>
  ));

  return <>{newText}</>;
};

export const HOW_IT_WORKS_ANCHOR_ID = "how-it-works";

interface Step {
  icon: string;
  title: string;
  body: string;
  iconRotate?: string;
}

export const HowItWorks: React.FC = () => {
  const steps: Step[] = [
    {
      icon: "ArrowDown2",
      title: "about.howItWorks.createProfile.title",
      body: "about.howItWorks.createProfile.body",
      iconRotate: "180"
    },
    {
      icon: "Circle",
      title: "about.howItWorks.buildPortfolio.title",
      body: "about.howItWorks.buildPortfolio.body"
    },
    {
      icon: "Copyright",
      title: "about.howItWorks.setPrices.title",
      body: "about.howItWorks.setPrices.body"
    },
    {
      icon: "Lightning",
      title: "about.howItWorks.connectSelling.title",
      body: "about.howItWorks.connectSelling.body"
    },
    {
      icon: "ArrowDown2",
      title: "about.howItWorks.collaborate.title",
      body: "about.howItWorks.collaborate.body",
      iconRotate: "-90"
    },
    {
      icon: "Dollar",
      title: "about.howItWorks.funds.title",
      body: "about.howItWorks.funds.body"
    },
    {
      icon: "Plus2",
      title: "about.howItWorks.postFeed.title",
      body: "about.howItWorks.postFeed.body"
    },
    {
      icon: "Square",
      title: "about.howItWorks.sell.title",
      body: "about.howItWorks.sell.body"
    },
    {
      icon: "ArrowDown2",
      title: "about.howItWorks.resourcesHub.title",
      body: "about.howItWorks.resourcesHub.body"
    }
  ];

  return (
    <Box mt={[5, 10]} mb={24}>
      {steps.map((entry, index) => (
        <CollapsableSection
          key={index}
          isLast={index === steps.length - 1}
          title={
            <Box width={"100%"}>
              <Text fontSize={[6, 14]} variant="grotText" textAlign="left">
                <Trans i18nKey={entry.title} components={[<HighlightText />]} />
              </Text>
            </Box>
          }
          icon={
            <Icon
              name={entry.icon}
              color="blue"
              size={[65, 114]}
              rotate={entry.iconRotate}
            />
          }>
          <Text mt={6} variant="grotText" fontSize={[5, 14]}>
            <NewlineText text={i18n.t(entry.body)} />
          </Text>
        </CollapsableSection>
      ))}
    </Box>
  );
};
