import React from "react";
import countries from "./static/countries.json";
import { Select } from "app/shared";
import { BoxProps } from "rebass";
import { getCommonTranslation } from "app/shared/translations/utils";

interface Props extends BoxProps {
  name: string;
  value?: string;
  labelI18n?: string;
  errorI18n?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  borderBottom?: number;
  hideDropdownIndicator?: boolean;
}

/**
 * This component implements a country selector with their 2 digit codes (ISO 3166-1)
 */
export const CountrySelect: React.FC<Props> = ({
  name,
  value,
  labelI18n,
  errorI18n,
  onChange,
  disabled,
  borderBottom,
  hideDropdownIndicator,
  ...rest
}) => {
  const defaultCountryCode =
    countries.find(countryCode => countryCode === value) ?? "";
  const countriesOrder = countries.sort((a, b) =>
    getCommonTranslation(a).localeCompare(getCommonTranslation(b))
  );

  return (
    <Select
      border={0}
      disabled={disabled}
      optionsBold={true}
      value={
        defaultCountryCode !== ""
          ? {
              value: defaultCountryCode,
              label: getCommonTranslation(defaultCountryCode)
            }
          : undefined
      }
      variantTitle="grotDisplayCaps"
      labeli18n={labelI18n ?? "shared.countrySelect.country"}
      errorI18n={errorI18n}
      borderBottom={borderBottom}
      hideDropdownIndicator={hideDropdownIndicator}
      onChange={options =>
        onChange({
          target: { name, value: options.value }
        } as React.ChangeEvent<HTMLInputElement>)
      }
      options={countriesOrder.map(countryCode => ({
        value: countryCode,
        label: getCommonTranslation(countryCode)
      }))}
      {...(rest as any)}
    />
  );
};

CountrySelect.defaultProps = {
  borderBottom: 1
};
