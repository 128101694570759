import React from "react";
import Svg, { SvgProps } from "primitives/Svg";

const SvgHorizontalDots = ({ width, height, size, color }: SvgProps) => (
  <Svg
    width={size || width}
    height={size || height}
    viewBox="0 0 22 6"
    color={color}>
    <circle cx={3} cy={3} r={2.5} transform="rotate(-90 3 3)" stroke="#000" />
    <circle cx={11} cy={3} r={2.5} transform="rotate(-90 11 3)" stroke="#000" />
    <circle cx={19} cy={3} r={2.5} transform="rotate(-90 19 3)" stroke="#000" />
  </Svg>
);

SvgHorizontalDots.defaultProps = {
  width: 24,
  height: 24
};
export default SvgHorizontalDots;
