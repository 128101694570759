import { Button } from "app/shared";
import debounce from "lodash.debounce";
import React, { Component, ReactNode } from "react";
import { Trans } from "react-i18next";
import { Box } from "rebass";

interface CachedPaginationResult {
  id: number | string;
}

interface Props<T> {
  disabled: boolean;
  firstPageNumber: 0 | 1;
  results: T[];
  children: (
    results: T[],
    setPage: (mode: "next" | "previous") => void
  ) => ReactNode;
  setPage: (page: number) => void;
  page: number;
  totalPages: number;
  scrollListenerEnabled: boolean;
  showLoadButtons: boolean;
}

interface State<T> {
  results: T[];
  page: number;
  pages: number[];
  scrollListener: any;
}

/**
 * Pagination with infinite scroll. When bottom window is reached a new page is requested (and caches the old).
 * Set prop.firstPageNumber to 1 when pagination starts in 1.
 *
 * TODO:
 * - Only draw elements in the viewport.
 * - Clean old results when there are huge amounts of data.
 */
export class CachedPagination<
  T extends CachedPaginationResult
> extends Component<Props<T>, State<T>> {
  static defaultProps = {
    disabled: false,
    firstPageNumber: 0,
    scrollListenerEnabled: true,
    showLoadButtons: true
  };

  constructor(props: Props<T>) {
    super(props);

    this.state = {
      results: props.results,
      page: props.results.length > 0 ? props.page : -1,
      pages: [props.page],
      scrollListener: debounce(this.handleScroll.bind(this), 500)
    };
  }

  componentDidMount() {
    if (this.props.scrollListenerEnabled) {
      window.addEventListener("scroll", this.state.scrollListener);
    }
  }

  componentWillUnmount() {
    if (this.state.scrollListener) {
      window.removeEventListener("scroll", this.state.scrollListener);
    }
  }

  handleScroll() {
    const onBottom =
      window.innerHeight + window.scrollY >= document.body.offsetHeight;

    if (onBottom) {
      this.setPage("next");
    }
  }

  /**
   * This method checks if props results changed and adds it to internal state.
   * Using propsChanged we filter cases when elements first update the page and then update
   * the results. This way page/results are set only when there are real prop changes.
   */
  componentDidUpdate(prevProps: Props<T>) {
    const prevPropsIds = prevProps.results.map(it => it.id);
    const nextPropsIds = this.props.results.map(it => it.id);
    const propsChanged = !(
      prevPropsIds.length === nextPropsIds.length &&
      prevPropsIds.every(it => nextPropsIds.includes(it))
    );

    if (propsChanged) {
      const currentPage = this.state.page;
      const nextPage = this.props.page;

      // NOTE: This has a bug that will always empty results when users load an URL
      // with page > 1 and then return to page 1.
      if (nextPage === this.props.firstPageNumber) {
        this.setState({
          page: nextPage,
          pages: [nextPage],
          results: this.props.results
        });
      } else if (nextPage !== currentPage) {
        this.setState({
          page: nextPage,
          results: this.addResults(
            this.state.results,
            this.props.results,
            nextPage > currentPage ? "end" : "begin"
          )
        });
      }
    }
  }

  /**
   * Ask for new page if not visited yet (if not disabled).
   * It is important to to use max/min so that firstPageNumber can begin with any
   * and using this logic is easy to ask for the previous/next page.
   */
  setPage(mode: "next" | "previous") {
    if (!this.props.disabled) {
      const { firstPageNumber } = this.props;
      const { pages } = this.state;
      const nextPage =
        mode === "next" ? Math.max(...pages) + 1 : Math.min(...pages) - 1;

      if (nextPage >= firstPageNumber && nextPage <= this.getLastPageNumber()) {
        pages.push(nextPage);
        this.setState({ pages }, () => this.props.setPage(nextPage));
      }
    }
  }

  /**
   * This method adds results to the array begin/end.
   */
  addResults(currentArray: T[], toAdd: T[], mode: "begin" | "end"): T[] {
    let results = [];

    if (mode === "begin") {
      results = [...toAdd, ...currentArray];
    } else {
      results = [...currentArray, ...toAdd];
    }

    return results;
  }

  getLastPageNumber(): number {
    const { firstPageNumber, totalPages } = this.props;

    return firstPageNumber === 0 ? Math.max(totalPages - 1, 0) : totalPages;
  }

  render() {
    const {
      children,
      firstPageNumber,
      disabled,
      totalPages,
      showLoadButtons
    } = this.props;
    const { results, pages } = this.state;

    return (
      <Box>
        {showLoadButtons && !pages.includes(firstPageNumber) && (
          <Box
            mb={3}
            sx={{
              textAlign: "center"
            }}>
            <Button
              width="100%"
              variant="blue"
              onClick={() => this.setPage("previous")}
              disabled={disabled}>
              <Trans i18nKey="shared.pagination.loadPrevious" />
            </Button>
          </Box>
        )}
        {children(results, this.setPage.bind(this))}
        {showLoadButtons &&
          totalPages > 0 &&
          !pages.includes(this.getLastPageNumber()) && (
            <Box
              mt={3}
              sx={{
                textAlign: "center"
              }}>
              <Button
                width="100%"
                variant="blue"
                onClick={() => this.setPage("next")}
                disabled={disabled}>
                <Trans i18nKey="shared.pagination.loadMore" />
              </Button>
            </Box>
          )}
      </Box>
    );
  }
}
