import { CardGridLayout } from "layout";
import React, { ReactNode } from "react";
import { BoxProps } from "rebass";

interface Props extends BoxProps {
  children: ReactNode;
  column?: number;
}

export const UsersView: React.FC<Props> = ({ children, column, ...rest }) => {
  return (
    <CardGridLayout
      gridTemplateColumns={["1fr", null, `repeat(${column?.toString()}, 1fr)`]}
      gridGap={4}
      sx={{
        "& > *": {
          mb: [2, 3]
        },
        "& > :last-child": {
          mb: 0
        }
      }}
      {...(rest as any)}>
      {children}
    </CardGridLayout>
  );
};

UsersView.defaultProps = {
  column: 4
};
