import styled from "@emotion/styled";
import i18next from "i18next";
import React from "react";
import { Link as RebassLink, LinkProps } from "rebass";

const LinkWrapper = styled(RebassLink)<Props & any>`
  color: brand.0;
  &:hover {
    opacity: 0.8;
  }
  &,
  &:link,
  &:visited,
  &:active,
  &:hover {
    text-decoration: none;
  }
  cursor: ${props => !props.disabled && "pointer"};
  ${props =>
    props.disabled &&
    `
    pointer-events: none;
    opacity: 0.8;
  `}
`;

interface Props extends LinkProps {
  i18n?: string;
  text?: string;
  href: string;
  onClick?: () => void;
}

export const TextLink: React.FC<Props> = ({
  i18n,
  text,
  href,
  variant,
  onClick,
  target,
  ...rest
}) => (
  <LinkWrapper
    href={href}
    variant={variant}
    onClick={onClick}
    target={target}
    rel={target === "_blank" ? "noopener noreferrer" : ""}
    {...(rest as any)}>
    {i18n ? i18next.t(i18n) : text ? text : ""}
  </LinkWrapper>
);
