import React, { FC } from "react";
import { TableLayout, EmptyHeader } from "../tableLayout";
import { Button, BackgroundImage, Label, Icon, LoadingFF } from "app/shared";
import { Flex, Box, Text } from "rebass";
import { UploadFileItem } from "app/documents/submitContent/models";
import { Trans } from "react-i18next";

interface Props {
  files: UploadFileItem[];
  showFileRemove: (file: UploadFileItem) => boolean;
  onRemoveFile: (id: string, documentId?: number) => void;
  variant?: "full" | "short";
}

export const UploadedFileList: FC<Props> = ({
  files,
  showFileRemove,
  onRemoveFile,
  variant
}) => {
  return (
    <TableLayout
      width="100%"
      columns={4}
      columnSizeArray={
        variant === "short"
          ? ["30%", "40%", "20%", "10%"]
          : ["50%", "20%", "20%", "10%"]
      }
      mt={3}
      tableNodes={[
        <Label
          key="mediaHeader"
          i18n="documents.submitContent.uploadContent.mediaHeader"
          fontSize={1}
          sx={{ borderBottom: 1, borderColor: "black.0" }}
        />,
        <Label
          key="sourceHeader"
          i18n="documents.submitContent.uploadContent.sourceHeader"
          fontSize={1}
          sx={{ borderBottom: 1, borderColor: "black.0" }}
        />,
        <EmptyHeader
          key="statusHeader"
          sx={{ borderBottom: 1, borderColor: "black.0" }}
        />,
        <EmptyHeader
          key="removeHeader"
          sx={{ borderBottom: 1, borderColor: "black.0" }}
        />,
        ...files.flatMap(file => [
          <Flex
            key={`${file.id}-thumbnail`}
            alignItems="center"
            color={file.status === "canceled" ? "text.danger" : undefined}>
            <BackgroundImage
              key={file.thumbnail}
              src={file.thumbnail}
              width="150px"
              height="100px"
            />
            {variant === "full" && (
              <Box ml={5} sx={{ overflowWrap: "break-word" }}>
                <Text fontSize={6} variant="grotText">
                  {file.file.name}
                </Text>
              </Box>
            )}
          </Flex>,
          <Box
            key={`${file.id}-source`}
            color={file.status === "canceled" ? "text.danger" : undefined}
            sx={{ overflowWrap: "break-word" }}>
            <Box>
              {variant === "short" && (
                <Text fontSize={6}>{file.file.name}</Text>
              )}
              <Text variant="grotText" fontSize={variant === "short" ? 2 : 6}>
                {file.source}
              </Text>
            </Box>
            <Box key={`${file.id}-error-1`}>
              {file.errors.length > 0 && (
                <Text fontSize={2} color="text.danger">
                  Error: {file.errors.join(", ")}
                </Text>
              )}
            </Box>
          </Box>,
          <Box
            key={`${file.id}-status`}
            color={file.status === "canceled" ? "text.danger" : undefined}>
            {file.status === "uploading" ? (
              <LoadingFF width="80%" />
            ) : (
              <Text
                fontSize={2}
                color={file.status !== "canceled" ? "blue" : undefined}
                variant="caps">
                {file.status === "uploaded" ? (
                  <Trans i18nKey="documents.submitContent.uploadContent.ready" />
                ) : (
                  file.status
                )}
              </Text>
            )}
          </Box>,
          <Box
            key={`${file.id}-remove`}
            color={file.status === "canceled" ? "text.danger" : undefined}>
            {showFileRemove(file) && (
              <Button
                pr={5}
                variant="transparent"
                onClick={() => {
                  onRemoveFile(file.id, file?.documentId);
                }}
                sx={{ textAlign: "right" }}>
                <Icon name="Close" size={18} />
              </Button>
            )}
          </Box>
        ])
      ]}
    />
  );
};

UploadedFileList.defaultProps = {
  variant: "full"
};
