import { UserPicture } from "app/account";
import { getUserProfile } from "app/account/services";
import { Button, MobileTriggerButton, Popup, Toast } from "app/shared";
import i18next from "i18next";
import debounce from "lodash.debounce";
import { User } from "models";
import React, { Component } from "react";
import { Trans } from "react-i18next";
import { Box, BoxProps, Flex, Text } from "rebass";
import { Donation } from "./models";
import { deleteDonation } from "./services";
import { checkFrequency, checkPrice } from "./utils";

interface Props extends BoxProps {
  donation: Donation;
  mobileView?: boolean;
}

interface State {
  disabled: boolean;
  user?: User;
}

export class CancelDonationPopUp extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      disabled: false
    };
  }

  cancelDonation(close: () => void) {
    const { donation } = this.props;
    deleteDonation(donation.id)
      .then(() => {
        Toast.success({
          title: {
            key: "donations.cancelDonationPopup.deleteSuccess"
          }
        });
        close();
        debounce(() => window.location.reload(), 1000)();
      })
      .catch(error => {
        Toast.apiError(error);
        this.setState({ disabled: false });
      });
  }

  render() {
    const { disabled, user } = this.state;
    const { donation, mobileView, ...rest } = this.props;

    return (
      <Popup
        trigger={
          mobileView ? (
            <Button variant="buttons.mobileButton">
              <MobileTriggerButton
                iconName="RemoveFrom"
                disabled={disabled}
                buttonNameI18n="donations.cancelDonationPopup.buttonTitle"
              />
            </Button>
          ) : (
            <Button width="100%" disabled={disabled} {...(rest as any)}>
              <Text fontSize={[1, 2, 3]}>
                <Trans i18nKey="donations.cancelDonationPopup.buttonTitle" />
              </Text>
            </Button>
          )
        }
        onOpen={() => {
          getUserProfile(donation.seller?.id as string).then(user =>
            this.setState({ user })
          );
        }}
        heading={i18next.t("donations.cancelDonationPopup.popUpTitle")}
        submit={close => this.cancelDonation(close)}
        submitText={i18next.t("donations.cancelDonationPopup.submitButton")}
        submitDisabled={disabled}
        cancelText={i18next.t("donations.cancelDonationPopup.cancelButton")}
        cancelDisabled={disabled}
        contentPadding={0}>
        <>
          <Text textAlign="center" py={3} variant="grotText" fontSize={[3, 7]}>
            <Trans i18nKey="donations.cancelDonationPopup.message" />{" "}
            {(donation.seller?.id ? user?.professionalName : "Frontfiles") +
              "?"}
          </Text>
          {user && (
            <Flex
              sx={{ borderBottom: 1, borderTop: 1, borderColor: "black" }}
              mb={2}>
              <UserPicture
                minWidth={["80px", "80px", "180px"]}
                picture={user.picture}
                height={["80px", "80px", "180px"]}
              />
              <Flex flexDirection="column" width="100%">
                <Flex>
                  <Box
                    py={3}
                    width="100%"
                    sx={{ borderBottom: "1px solid black" }}>
                    <Text
                      variant="grotText"
                      pl={2}
                      sx={{ textTransform: "uppercase" }}>
                      {user.professionalName}
                    </Text>
                  </Box>
                </Flex>
                <Flex flexDirection="column">
                  <Text
                    fontSize={1}
                    pl={2}
                    py={2}
                    pb={4}
                    variant="grotTextCaps">
                    <Trans i18nKey="donations.supportLabel" />{" "}
                    {user?.professionalName.split(" ")[0]}
                  </Text>
                  <Text pl={2} fontSize={4}>
                    {user.translations?.[0]?.donations ?? (
                      <Trans i18nKey="donations.noDonationDescription" />
                    )}
                  </Text>
                </Flex>
              </Flex>
            </Flex>
          )}
          <Flex justifyContent="center" px={4} py={3}>
            <Flex
              justifyContent="center"
              bg="blue"
              color="white"
              width="100%"
              py={2}
              sx={{ textTransform: "uppercase", textAlign: "center" }}
              mr={4}>
              <Text>{checkFrequency(donation.frequency)}</Text>
            </Flex>
            <Flex
              justifyContent="center"
              bg="blue"
              color="white"
              width="100%"
              py={2}
              sx={{ textTransform: "uppercase", textAlign: "center" }}>
              <Text>
                {checkPrice(donation.price.value, donation.price.currency)}
              </Text>
            </Flex>
          </Flex>
        </>
      </Popup>
    );
  }
}
