import { Notification } from "app/account/models";
import {
  getNotifications,
  getNotificationsCount,
  markAllNotificationsRead
} from "app/account/services";
import NavbarButton from "app/navbar/NavbarButton";
import { NavbarIconCounter } from "app/shared";
import OutsideClickWrapper from "app/shared/wrapper/OutsideClickWrapper";
import { withAuthorization } from "authentication/session";
import debounce from "lodash.debounce";
import React, { Component, Dispatch } from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { compose } from "recompose";
import { Action } from "redux";
import { NotificationsMenu } from "./NotificationsMenu";
import * as actions from "./reducers/actions";

interface PropsExternal {
  timerInterval?: number;
  numberOfNotifications?: number;
}

interface Props extends PropsExternal, RouteComponentProps {
  unreadNotifications: number;
  setUnreadNotifications: (count: number) => void;
}

interface State {
  notifications?: Notification[];
  open: boolean;
  timer?: number;
}

class NotificationButtonBase extends Component<Props, State> {
  state = {
    notifications: undefined,
    open: false,
    timer: undefined
  };

  static defaultProps = {
    timerInterval: 30000,
    numberOfNotifications: 20
  };

  componentDidMount() {
    // Add interval that checks for new notifications
    this.getNotificationsCount().then(() => {
      const { timerInterval } = this.props;
      const timer = setInterval(
        () => this.getNotificationsCount(),
        timerInterval
      );
      this.setState({ timer });
    });

    // Add listener when location changes
    this.props.history.listen(() => {
      if (this.state.open) {
        this.setState({ open: false });
      }
    });
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { unreadNotifications } = prevProps;
    const { numberOfNotifications } = this.props;

    if (unreadNotifications > 0 && this.props.unreadNotifications === 0) {
      getNotifications(0, numberOfNotifications ?? 1).then(({ data }) =>
        this.setState({ notifications: data })
      );
    }
  }

  componentWillUnmount() {
    this.removeTimer();
  }

  /**
   * Interval to check for new notifications will be removed after retry, so that when service is offline
   * this component does not keep requesting it in a loop.
   */
  removeTimer() {
    const { timer } = this.state;

    if (timer) {
      clearInterval(timer);
    }
  }

  getNotificationsCount() {
    const { numberOfNotifications } = this.props;
    return getNotificationsCount()
      .then(count => {
        const { unreadNotifications } = this.props;
        const { notifications } = this.state;

        if (unreadNotifications !== count.unread) {
          this.props.setUnreadNotifications(count.unread);
        }

        // Update list of notifications menu if any change
        if (
          unreadNotifications !== count.unread ||
          notifications === undefined
        ) {
          getNotifications(0, numberOfNotifications ?? 1).then(({ data }) =>
            this.setState({ notifications: data })
          );
        }
      })
      .catch(error => {
        console.log("error", error);
        this.removeTimer();
      });
  }

  handleClick = () => {
    const { open } = this.state;

    this.setState({ open: !open });
    debounce(() => {
      markAllNotificationsRead().then(() =>
        this.props.setUnreadNotifications(0)
      );
    }, 1000)();
  };

  render() {
    const { unreadNotifications } = this.props;
    const { notifications, open } = this.state;

    return (
      <OutsideClickWrapper
        isOpen={open}
        callback={() => this.setState({ open: false })}>
        <NavbarButton
          height="100%"
          onClick={this.handleClick}
          sx={{
            flexGrow: 1,
            "&:hover": { background: "blue.0", color: "white.0" }
          }}>
          <NavbarIconCounter counter={unreadNotifications} iconName="Bell" />
        </NavbarButton>
        {open && <NotificationsMenu notifications={notifications ?? []} />}
      </OutsideClickWrapper>
    );
  }
}

const mapStateToProps = (state: any) => ({
  unreadNotifications: state.notifications.unreadNotifications
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  setUnreadNotifications: async (count: number) =>
    dispatch(actions.setUnreadNotifications(count))
});

export const NotificationButton = compose<Props, PropsExternal>(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withAuthorization()
)(NotificationButtonBase);
