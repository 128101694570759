import styled from "@emotion/styled";
import { Icon } from "app/shared";
import React, { ReactNode } from "react";
import { Box, BoxProps, Flex } from "rebass";

interface Props extends BoxProps {
  children?: ReactNode;
  disabled?: boolean;
  onClick?: () => void;
  collapsed?: boolean;
  variant?: string;
}

const FlexWrapper = styled(Flex)<Props & any>`
  outline: none;
  text-transform: uppercase;
  cursor: ${props => !props.disabled && "pointer"};
`;

export const CollapsableButton: React.FC<Props> = ({
  children,
  disabled,
  onClick,
  collapsed,
  variant,
  ...rest
}) => {
  return (
    <FlexWrapper
      alignItems="center"
      justifyContent="space-between"
      onClick={onClick}
      bg={variant === "black" ? "black" : "white"}
      color={variant === "black" ? "white" : "black"}
      {...rest}>
      {children}
      {collapsed ? (
        <Box
          sx={{
            "& svg path": { stroke: variant === "black" ? "white" : "black" }
          }}>
          <Icon name="ArrowDown" size={22} />
        </Box>
      ) : (
        <Box
          sx={{
            "& svg path": { stroke: variant === "black" ? "white" : "black" }
          }}>
          <Icon name="ArrowUp" size={22} />
        </Box>
      )}
    </FlexWrapper>
  );
};
