import React from "react";
import Svg, { SvgProps } from "primitives/Svg";

const SvgLike = ({ width, height, size, color }: SvgProps) => (
  <Svg
    width={size || width}
    height={size || height}
    viewBox="0 0 24 24"
    color={color}>
    <path d="M18.99 8h-3V6c0-2.21-1.79-4-4-4h-2v5.45c0 .44-.05.87-.15 1.3-.15.67-.54 1.24-1.04 1.69A.982.982 0 008 10H3c-.55 0-1 .45-1 1v8h1v-8h5v10H4v1h4c.55 0 1-.45 1-1h9c1.66 0 2.75-1.25 3-3l.92-5.53c.05-.32.08-.63.08-.95V11a3.01 3.01 0 00-3.01-3zm2 3.52c0 .26-.02.53-.07.79L20 17.86c-.07.5-.43 2.14-2.01 2.14h-9v-8.43c.93-.62 1.58-1.54 1.82-2.6.12-.51.18-1.03.18-1.53V3h1c1.65 0 3 1.35 3 3v3h4c1.1 0 2 .9 2 2v.52z" />
  </Svg>
);

SvgLike.defaultProps = {
  width: 24,
  height: 24
};
export default SvgLike;
