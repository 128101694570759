import {
  Coords,
  Location,
  GooglePlace,
  GoogleAddressComponent
} from "../models";

/**
 * Google API helper to get address component from results
 */
export const getAddressComponentByTypes = (
  results: GooglePlace[],
  types: string[]
): GoogleAddressComponent | undefined => {
  for (let i = 0; i < results.length; i++) {
    const addressComponents = results[i]?.address_components ?? [];

    for (let j = 0; j < addressComponents.length; j++) {
      const addressComponent = addressComponents[j];

      if (types.every(type => addressComponent.types.includes(type))) {
        return addressComponent;
      }
    }
  }

  return undefined;
};

/**
 * Get location with provided coordinates
 */
export const getLocation = (
  googleMaps: any,
  coords: Coords
): Promise<Location | undefined> => {
  return new Promise((resolve, reject) => {
    const geocoder = new googleMaps.Geocoder();

    geocoder.geocode(
      { location: coords },
      (results: GooglePlace[], status: string) => {
        if (status === "OK") {
          const location: Location = {
            ...coords,
            city: getAddressComponentByTypes(results, ["locality", "political"])
              ?.short_name,
            country: getAddressComponentByTypes(results, [
              "country",
              "political"
            ])?.short_name
          };

          return resolve(location);
        } else {
          return reject(undefined);
        }
      }
    );
  });
};

/**
 * Get coordinates with provided location
 */
export const getCoordinates = (
  googleMaps: any,
  city?: string,
  country?: string
): Promise<Coords | undefined> => {
  return new Promise((resolve, reject) => {
    const geocoder = new googleMaps.Geocoder();

    if (city || country) {
      const address = [city, country].filter(it => it).join(", ");
      geocoder.geocode(
        { address },
        (results: GooglePlace[], status: string) => {
          if (status === "OK") {
            const firstPoliticalResult = results.find(it =>
              it.types.includes("political")
            );
            const location = firstPoliticalResult?.geometry?.location;
            const coords = location
              ? { lat: location.lat(), lng: location.lng() }
              : undefined;
            return resolve(coords);
          } else {
            return reject(undefined);
          }
        }
      );
    } else {
      reject(undefined);
    }
  });
};
