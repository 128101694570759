import { Button, Icon } from "app/shared";
import React from "react";
import { Flex } from "rebass";

export const SocialLinks: React.FC = () => (
  <Flex flexDirection={"row"} justifyContent={"center"} sx={{ gap: "48px" }}>
    <Button href={`https://facebook.com/FrontFiles`} target="_blank">
      <Icon name={"Facebook"} size={48} />
    </Button>
    <Button href={`https://instagram.com/FrontFiles`} target="_blank">
      <Icon name={"Instagram"} size={48} />
    </Button>
    <Button href={`https://twitter.com/FrontFiles`} target="_blank">
      <Icon name={"Twitter"} size={48} />
    </Button>
    <Button href={`https://pinterest.com/FrontFiles`} target="_blank">
      <Icon name={"Pinterest"} size={48} />
    </Button>
  </Flex>
);
