import { Button, BackgroundImage } from "app/shared";
import React from "react";
import { Box, Flex, Text } from "rebass";

interface Props {
  link: string;
  title: string;
  firstColumnLabel: string;
  firstColumnText: string;
  secondColumnLabel: string;
  secondColumnText: string;
  thumbnail?: string;
}

export const PopupResult: React.FC<Props> = ({
  link,
  title,
  firstColumnLabel,
  firstColumnText,
  secondColumnLabel,
  secondColumnText,
  thumbnail
}) => {
  return (
    <Button href={link} width="100%">
      <Flex
        width="100%"
        sx={{
          border: "1px solid #000"
        }}>
        <Flex
          width="150px"
          alignItems="center"
          justifyContent="center"
          p={2}
          sx={{
            borderRight: "1px solid #000"
          }}>
          <BackgroundImage
            src={thumbnail}
            width="150px"
            height="75px"
            sx={{ border: 0 }}
          />
        </Flex>
        <Flex
          flexDirection="column"
          sx={{
            flexGrow: 1
          }}>
          <Flex
            alignItems="center"
            p={2}
            sx={{
              flexGrow: 1,
              borderBottom: "1px solid #000"
            }}>
            <Text fontWeight="bold" variant="caps" fontSize={[5, 6, 7]}>
              {title}
            </Text>
          </Flex>
          <Flex>
            <Box
              width="50%"
              p={2}
              sx={{
                borderRight: "1px solid #000"
              }}>
              <Text fontSize={0} mb={2} variant="caps">
                {firstColumnLabel}
              </Text>
              <Text fontSize={[3, 4, 5]}>{firstColumnText}</Text>
            </Box>
            <Box width="50%" p={2}>
              <Text fontSize={0} mb={2} variant="caps">
                {secondColumnLabel}
              </Text>
              <Text fontSize={[3, 4, 5]}>{secondColumnText}</Text>
            </Box>
          </Flex>
        </Flex>
      </Flex>
    </Button>
  );
};
