import { Button, Toast } from "app/shared";
import React, { Component } from "react";
import { Trans } from "react-i18next";
import { updateUser } from "../services";
import { Box, Flex, Text } from "rebass";
import { UpdateUserDTO } from "../dto";
import i18next from "i18next";
import { FormFieldSet } from "app/shared/forms";
import { User, ValidationError } from "models";
import ColabWork from "../editMyInfo/ColabWork";

interface Props {
  user: User;
  onSkip?: () => void;
  onComplete: (user: User) => void;
  hideHeader?: boolean;
}

interface State {
  collaboration: string[];
  disabled: boolean;
}

export class Collab extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      collaboration: props.user.collaboration,
      disabled: false
    };
  }

  validateForm(): ValidationError[] {
    return [];
  }

  onSubmit() {
    const { onComplete, user } = this.props;
    const { collaboration } = this.state;
    const errors = this.validateForm();

    if (user && errors.length === 0) {
      this.setState({ disabled: true }, () => {
        const updateUserDTO: UpdateUserDTO = {
          userId: user.id,
          collaboration
        };
        updateUser(updateUserDTO)
          .then(user => {
            this.setState({ disabled: false });
            onComplete(user);
            Toast.success({
              title: {
                key: "account.onboarding.confirmationToast"
              }
            });
          })
          .catch(error => {
            Toast.apiError(error);
            this.setState({ disabled: false });
          });
      });
    }
  }

  toggleCollaboration(item: string) {
    const { collaboration } = this.state;
    this.setState({
      collaboration: collaboration.includes(item)
        ? collaboration.filter(it => it !== item)
        : [...collaboration, item]
    });
  }

  render() {
    const { onSkip, hideHeader } = this.props;
    const { collaboration, disabled } = this.state;

    return (
      <Box px={[2, 0]}>
        {!hideHeader && (
          <Box sx={{ textAlign: "center" }} mb={8}>
            <Text
              fontSize={[3]}
              sx={{
                display: "inline-block",
                textAlign: "left",
                lineHeight: "24px"
              }}>
              <Trans i18nKey="account.onboarding.collab.headerText" />
            </Text>
          </Box>
        )}
        <FormFieldSet
          titleTop={i18next.t("account.onboarding.collab.title")}
          sx={{ border: 1 }}>
          <ColabWork
            userInfo={{ collaboration }}
            handleOnCheckboxChange={event =>
              this.toggleCollaboration(event.target.name)
            }
          />
        </FormFieldSet>
        <Flex justifyContent="space-between" mt={8}>
          {onSkip && (
            <Flex justifyContent="flex-end" alignItems="center" width="100%">
              <Button
                variant="white"
                width={["100%", "70%"]}
                p={3}
                disabled={disabled}
                onClick={() => this.onSubmit()}>
                <Trans i18nKey="account.onboarding.collab.skip" />
              </Button>
            </Flex>
          )}
          <Flex
            justifyContent={onSkip ? "left" : "center"}
            alignItems="center"
            width="100%"
            ml={onSkip ? 4 : 0}>
            <Button
              variant="blue"
              width={onSkip ? ["100%", "70%"] : "50%"}
              p={3}
              disabled={disabled}
              onClick={() => this.onSubmit()}>
              <Trans
                i18nKey={
                  onSkip
                    ? "account.onboarding.collab.submit"
                    : "account.onboarding.save"
                }
              />
            </Button>
          </Flex>
        </Flex>
      </Box>
    );
  }
}
