import styled from "@emotion/styled";
import { Button, Icon } from "app/shared";
import i18next from "i18next";
import React from "react";
import { Prompt } from "react-router-dom";
import { default as ReactPopup } from "reactjs-popup";
import { Box, Flex, Text } from "rebass";
import { useMediaQueryContext } from "styles/context";

interface Props {
  children: JSX.Element;
  heading?: string;
  trigger?: JSX.Element;
  open?: boolean;
  disabled?: boolean;
  submit?: (close: () => void) => void;
  hideCloseButton?: boolean;
  closeOnDocumentClick?: boolean;
  closeOnEscape?: boolean;
  submitText?: string;
  submitDisabled?: boolean;
  cancel?: (close: () => void) => void;
  cancelText?: string;
  cancelDisabled?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  maxWidth?: string;
  disableNavigation?: boolean;
  contentPadding?: number;
  variant?: "half-buttons" | "full-buttons";
  hideActionButtons?: boolean;
  hideCancelButtons?: boolean;
  transparent?: boolean;
  hideOverflow?: boolean;
  marginY?: string | number;
  maxHeight?: string | number;
}

const StyledPopup = styled(ReactPopup)`
  &-overlay {
    background-color: rgba(255, 255, 255, 0.95) !important;
    margin-bottom: 0 !important;
  }
`;

/**
 * Notes:
 * - Disable=true closes the popup if open and disables its trigger.
 */
export const Popup: React.FC<Props> = ({
  trigger,
  children,
  heading,
  open,
  disabled,
  submit,
  hideCloseButton,
  closeOnDocumentClick,
  closeOnEscape,
  submitText,
  submitDisabled,
  cancel,
  cancelText,
  cancelDisabled,
  maxWidth,
  disableNavigation,
  onOpen,
  onClose,
  contentPadding,
  variant,
  hideActionButtons,
  hideCancelButtons,
  transparent,
  hideOverflow,
  marginY,
  maxHeight
}) => {
  const { isSmallMedium } = useMediaQueryContext();

  return (
    <StyledPopup
      trigger={trigger}
      modal
      closeOnDocumentClick={closeOnDocumentClick}
      closeOnEscape={closeOnEscape}
      contentStyle={{
        width: isSmallMedium ? "100%" : "80%",
        maxWidth: maxWidth ? maxWidth : isSmallMedium ? "100%" : "60%",
        padding: 0,
        border: 0,
        backgroundColor: transparent ? "transparent" : "white",
        marginTop: marginY ?? "auto",
        marginBottom: marginY ?? "auto"
      }}
      open={open}
      disabled={disabled}
      onOpen={() => onOpen?.()}
      onClose={() => onClose?.()}>
      {(close: () => void) => (
        <>
          <Prompt
            when={disableNavigation}
            message={i18next.t("shared.popup.prompt")}
          />
          <Flex flexDirection="column">
            <Box
              sx={{
                border: transparent ? 0 : 1,
                borderColor: "black.0",
                overflowY: hideOverflow ? "" : "auto"
              }}
              maxHeight={maxHeight ?? "90vh"}>
              {!hideCloseButton && (
                <Button
                  bg="fill.0"
                  color="text.default"
                  onClick={close}
                  display="grid"
                  m={1}
                  p={0}
                  sx={{
                    position: "absolute",
                    top: 0,
                    right: 0
                  }}>
                  <Icon name="Close" size={[12, 16]} />
                </Button>
              )}
              {heading && (
                <Text
                  textAlign="center"
                  fontSize={[2, 4, 7]}
                  variant="grotTextCaps"
                  p={2}
                  sx={{ borderBottom: transparent ? 0 : 1 }}>
                  {heading}
                </Text>
              )}
              <Box sx={{ "& > *": { fontSize: [2, 4, 6] } }} p={contentPadding}>
                {children}
              </Box>
            </Box>
            {!hideActionButtons && (
              <Flex
                flexDirection={
                  variant === "full-buttons" ? "column-reverse" : "row"
                }
                pt={variant === "full-buttons" ? 0 : 1}>
                {!hideCancelButtons && (
                  <Button
                    display="block"
                    width="100%"
                    p={variant === "full-buttons" ? 3 : 2}
                    mr={variant === "full-buttons" ? 0 : "1px"}
                    variant="black"
                    onClick={() => (cancel ? cancel(close) : close())}
                    disabled={disabled || cancelDisabled}>
                    {cancelText ?? i18next.t("shared.popup.cancel")}
                  </Button>
                )}
                <Button
                  display="block"
                  width="100%"
                  p={variant === "full-buttons" ? 3 : 2}
                  variant="blue"
                  onClick={() => (submit ? submit(close) : close())}
                  disabled={disabled || submitDisabled}>
                  {submitText ?? i18next.t("shared.popup.save")}
                </Button>
              </Flex>
            )}
          </Flex>
        </>
      )}
    </StyledPopup>
  );
};

Popup.defaultProps = {
  contentPadding: 2,
  variant: "half-buttons",
  disableNavigation: false
};
