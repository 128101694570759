import React from "react";
import Svg, { SvgProps } from "primitives/Svg";

const SvgCalendar = ({ width, height, size, color }: SvgProps) => (
  <Svg
    width={size || width}
    height={size || height}
    viewBox="0 0 24 24"
    color={color}>
    <path d="M7 2h1v2H7zM16 2h1v2h-1z" />
    <path stroke="#000" d="M5.5 13.5h2v2h-2z" />
    <path d="M4 8h16v1H4z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 5h16v16H5v1h16V4H3v15h1V5z"
    />
  </Svg>
);

SvgCalendar.defaultProps = {
  width: 24,
  height: 24
};
export default SvgCalendar;
