import { keyframes } from "@emotion/core";
import styled from "@emotion/styled";
import Icon from "icons/Icon";
import React, { useEffect, useState } from "react";
import { Trans } from "react-i18next";
import { Box, BoxProps, Flex, Text } from "rebass";

interface Props extends BoxProps {
  loadingText?: boolean;
  i18nKey?: string;
  showIcon?: boolean;
  hideBar?: boolean;
}

export const LoadingFF: React.FC<Props> = ({
  loadingText,
  i18nKey,
  showIcon,
  hideBar,
  ...rest
}) => {
  const [width, setWidth] = useState(0);

  const updateProgress = () => {
    if (width < 100) {
      setWidth(width + 10);
    }
  };

  useEffect(() => {
    updateProgress(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width]);

  const blink = (start: any) => {
    return keyframes`
  0% {
    color: ${start}
  }
  25% {
    color: ${start === "blue" ? "black" : "blue"}
  }
  50% {
    color: ${start}
  }
  75% {
    color: ${start === "blue" ? "black" : "blue"}
  }
  100% {
    color: ${start}
  }
`;
  };

  const AnimationBlue = styled(Text)`
    animation: ${blink("blue")};
    animation-fill-mode: both;
    animation-duration: 2s;
    animation-iteration-count: infinite;
  `;

  const AnimationBlack = styled(Text)`
    animation: ${blink("black")};
    animation-fill-mode: both;
    animation-duration: 2s;
    animation-iteration-count: infinite;
  `;

  return (
    <>
      {showIcon && (
        <Flex justifyContent="center" alignItems="center" mb={3}>
          <Flex>
            <AnimationBlue>
              <Icon name="LetterF" size={34} />
            </AnimationBlue>
          </Flex>
          <Flex>
            <AnimationBlack>
              <Icon name="LetterF" size={34} />
            </AnimationBlack>
          </Flex>
        </Flex>
      )}
      {!hideBar && (
        <Box p="2px" bg="black" {...(rest as any)}>
          <Box bg="blue" width={`${width}%`} sx={{ transition: "4s ease" }}>
            <Text fontSize="5px">&nbsp;</Text>
          </Box>
        </Box>
      )}
      {loadingText && (
        <Text fontSize={2} textAlign="center" variant="grotTextCaps" mt={3}>
          <Trans i18nKey={i18nKey} />
        </Text>
      )}
    </>
  );
};

LoadingFF.defaultProps = {
  loadingText: false,
  i18nKey: "loading.title",
  showIcon: false
};
