import { DefaultTheme } from "emotion-theming";

export const breakpoints = ["544px", "768px", "1024px", "1200px", "1300px"];

export const colors = {
  white: ["#fff"],
  black: ["#000"],
  brand: ["#0000FF"],
  blue: ["#0000FF"],
  grey: ["#ccc", "grey"],
  darkGrey: ["rgba(0, 0, 0, 0.4)"],
  footerGrey: ["rgba(0, 0, 0, 0.65)"],
  green: ["green"],
  red: ["red"],
  orange: ["orange"],
  purple: ["purple"]
};

export const sizes = {
  maxWidth: "1312px",
  // This should be updated when navbar/footer height changes so that other calculations that rely on this continue to work
  navbarHeight: { sm: "51px", lg: "81px" },
  footerHeight: { sm: "201px", lg: "201px" }
};

export const theme: DefaultTheme = {
  borders: ["0", "1px solid", "2px solid"],
  borderWidths: [0, 1, 2],
  borderStyles: ["none", "solid", "dashed"],
  breakpoints,
  mediaQueries: {
    sm: `@media screen and (min-width: ${breakpoints[0]})`,
    md: `@media screen and (min-width: ${breakpoints[1]})`,
    lg: `@media screen and (min-width: ${breakpoints[2]})`
  },
  colors: {
    ...colors,
    text: {
      default: colors.black[0],
      muted: colors.grey[0],
      link: colors.blue[0],
      danger: colors.red[0],
      warning: colors.orange[0],
      success: colors.green[0]
    },
    status: {
      danger: colors.red[0],
      warning: colors.orange[0],
      success: colors.green[0],
      info: colors.blue[0]
    },
    fill: [colors.white[0], colors.grey[0], colors.black[0]],
    border: [colors.grey[0], colors.black[0]]
  },
  fonts: {
    body: "Haas Grot Disp Pro",
    heading: "Haas Grot Text Pro"
  },
  fontSizes: [
    10,
    12,
    14,
    16,
    18,
    20,
    22,
    28,
    33,
    34,
    40,
    47,
    50,
    52,
    60,
    70,
    80,
    92,
    100,
    110
  ],
  fontWeights: {
    body: 500,
    heading: 400,
    bold: 700
  },
  lineHeights: {
    body: 1.15,
    heading: 1.25
  },
  radii: [0, 5, 10],
  shadows: {
    small: "0 0 4px rgba(0, 0, 0, .125)",
    large: "0 0 24px rgba(0, 0, 0, .125)"
  },
  space: [0, 4, 8, 16, 24, 32, 40, 56, 64, 70, 128, 256, 512],
  sizes: {
    ...sizes
  },
  zIndices: [1, 2, 3],
  letterSpacings: [1.6, 1.92],

  // Variants to links, text, buttons, forms and etc
  variants: {
    link: {
      textDecoration: "none",
      color: "text.default",
      nav: {
        textDecoration: "none",
        color: "text.default",
        fontWeight: "bold"
      },
      footer: {
        textDecoration: "none",
        color: "text.default",
        textTransform: "uppercase",
        fontSize: [1, 2, 3]
      },
      mobileButton: {
        color: "text.default",
        fontSize: ["8px", 1, 3],
        fontFamily: "body",
        textTransform: "uppercase",
        textDecoration: "none",
        textAlign: "center"
      },
      button: {
        borderRadius: 0,
        bg: "white.0",
        color: "text.default",
        border: 1,
        borderColor: "grey.0",
        fontSize: [1, 2, 3],
        fontFamily: "body",
        textTransform: "uppercase",
        textDecoration: "none",
        textAlign: "center",
        px: 3,
        py: 2,
        black: {
          textDecoration: "none",
          textAlign: "center",
          color: "white.0",
          fontSize: [1, 2, 3],
          borderRadius: 0,
          bg: "black.0",
          px: 3,
          py: 2,
          textTransform: "uppercase"
        },
        blue: {
          textDecoration: "none",
          textAlign: "center",
          color: "white.0",
          fontSize: [1, 2, 3],
          borderRadius: 0,
          bg: "blue.0",
          px: 3,
          py: 2,
          textTransform: "uppercase"
        },
        active: {
          textDecoration: "none",
          textAlign: "center",
          color: "white.0",
          fontSize: [1, 2, 3],
          fontFamily: "heading",
          fontWeight: "bold",
          borderRadius: 0,
          bg: "blue",
          px: 3,
          py: 2,
          textTransform: "uppercase"
        },
        activeBorder: {
          textDecoration: "none",
          textAlign: "center",
          color: "blue",
          fontSize: [1, 2, 3],
          fontFamily: "heading",
          fontWeight: "bold",
          borderRadius: 0,
          bg: "white",
          borderColor: "blue",
          px: 3,
          py: 2,
          textTransform: "uppercase"
        },
        brand: {
          textDecoration: "none",
          textAlign: "center",
          fontSize: [1, 2, 3],
          color: "white.0",
          borderRadius: 0,
          bg: "brand.0",
          px: 3,
          py: 2,
          textTransform: "uppercase"
        },
        categoriesAndLabels: {
          textDecoration: "none",
          textAlign: "center",
          fontFamily: "heading",
          fontSize: [2, 3, 4],
          borderRadius: 0,
          color: "white.0",
          px: 3,
          py: 2
        }
      }
    },
    card: {}
  },
  text: {
    caps: {
      textTransform: "uppercase",
      letterSpacing: "1px !important"
    },
    grotText: {
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading"
    },
    grotTextCaps: {
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      textTransform: "uppercase",
      letterSpacing: 0
    },
    grotDisplay: {
      fontFamily: "body",
      lineHeight: "body",
      fontWeight: "body"
    },
    grotDisplayCaps: {
      fontFamily: "body",
      lineHeight: "body",
      fontWeight: "body",
      textTransform: "uppercase",
      letterSpacing: 0
    },
    dmSerifDisp: {
      fontFamily: "Dm Serif Disp"
    }
  },
  buttons: {
    inlineList: {
      borderRadius: 0,
      border: "none",
      bg: "white",
      color: "black.0",
      fontSize: [1, 2, 3],
      textTransform: "uppercase",
      textDecoration: "none",
      textAlign: "center",
      p: 0
    },
    white: {
      borderRadius: 0,
      bg: "white.0",
      color: "text.default",
      border: "1px solid #000",
      fontFamily: "body",
      fontSize: [1, 2, 3],
      textTransform: "uppercase"
    },
    whiteBlue: {
      borderRadius: 0,
      bg: "white.0",
      color: "blue",
      border: "1px solid #0000FF",
      fontFamily: "body",
      fontSize: [1, 2, 3],
      textTransform: "uppercase"
    },
    black: {
      borderRadius: 0,
      bg: "black.0",
      color: "white",
      border: "1px solid #000",
      fontFamily: "body",
      fontSize: [1, 2, 3],
      textTransform: "uppercase"
    },
    active: {
      bg: "blue",
      color: "white",
      border: "1px solid blue",
      fontFamily: "heading",
      fontWeight: "bold",
      fontSize: [1, 2, 3],
      textTransform: "uppercase",
      borderRadius: 0
    },
    activeBorder: {
      bg: "white",
      color: "blue",
      border: "1px solid blue",
      fontSize: [1, 2, 3],
      textTransform: "uppercase",
      borderRadius: 0
    },
    blue: {
      borderRadius: 0,
      bg: "blue",
      color: "white",
      border: "1px solid #0000FF",
      fontFamily: "body",
      fontSize: [1, 2, 3],
      textTransform: "uppercase"
    },
    transparent: {
      borderRadius: 0,
      p: 0,
      bg: "transparent",
      color: "text.default"
    },
    outline: {},
    navButton: {
      bg: "white.0",
      color: "text.default"
    },
    mobileButton: {
      color: "text.default",
      px: 0,
      py: 0,
      bg: "fill.0",
      fontSize: ["8px", 1, 3],
      fontFamily: "body",
      textTransform: "uppercase",
      textDecoration: "none",
      textAlign: "center"
    },
    icon: {
      borderRadius: 0,
      p: 1,
      bg: "white.0",
      color: "text.default",
      border: "1px solid #000"
    }
  },
  forms: {
    input: {
      fontFamily: "heading",
      fontSize: [2, 3, 4],
      border: 0,
      borderBottom: `1px solid ${colors.black[0]}`,
      p: 0,
      paddingY: 2,
      m: 0,
      outline: "none",
      letterSpacing: 1,
      "::placeholder": {
        fontSize: 0,
        textTransform: "uppercase"
      },
      secondary: {
        fontFamily: "heading",
        fontSize: [0, 2],
        textTransform: "uppercase",
        outline: "none",
        border: 0,
        bg: "fill.0",
        py: 3,
        px: [1, 2, 3],
        error: {
          fontFamily: "heading",
          fontSize: [0, 2],
          textTransform: "uppercase",
          outline: "none",
          border: 0,
          bg: "fill.0",
          py: 3,
          px: [1, 2, 3],
          "::placeholder": {
            color: "red"
          }
        }
      },
      picture: {
        border: 0,
        p: 0,
        pb: 1
      },
      chat: {
        border: 1
      },
      noPadding: {
        fontFamily: "heading",
        fontSize: [0, 1],
        textTransform: "uppercase",
        outline: "none",
        border: 0,
        bg: "fill.0"
      },
      borderless: {
        fontFamily: "heading",
        fontSize: [2, 3, 4],
        border: 0,
        p: 0,
        paddingY: 2,
        m: 0,
        outline: "none",
        letterSpacing: 1
      }
    },
    select: {
      borderRadius: 0,
      fontWeight: "heading",
      border: 0,
      minWidth: "150px",
      bg: "fill.0",
      p: 3,
      nav: {
        borderRadius: 0,
        border: 0,
        minWidth: "60px"
      }
    },
    switch: {
      borderRadius: 0,
      thumb: { borderRadius: 0, bg: "black.0" }
    },
    textarea: {
      fontFamily: "heading",
      fontSize: [2, 3, 4],
      border: 0,
      borderBottom: `1px solid ${colors.black[0]}`,
      p: 0,
      paddingY: 1,
      m: 0,
      outline: "none",
      resize: "none"
    },
    label: {},
    radio: {},
    checkbox: {}
  }
};
