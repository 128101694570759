import { BackButton, Button, Icon } from "app/shared";
import React from "react";
import { BoxProps, Flex, Text } from "rebass";
import { Trans } from "react-i18next";
import { useMediaQueryContext } from "styles/context";

interface Props extends BoxProps {
  collectionId: number;
  activeButton: "gallery" | "article";
}

export const CollectionModeButtons: React.FC<Props> = ({
  collectionId,
  activeButton,
  ...rest
}) => {
  const { isSmallMedium } = useMediaQueryContext();

  return (
    <>
      {isSmallMedium && <BackButton i18n="magazine.backButton" />}
      <Flex width="100%" alignItems="center" mt={8} {...(rest as any)}>
        {!isSmallMedium && (
          <Flex alignItems="center" height="100%">
            <BackButton i18n="magazine.backButton" mb={0} />
          </Flex>
        )}
        <Flex width="100%" justifyContent={["center", "flex-end"]}>
          <Button
            variant={
              activeButton === "gallery" ? "link.button.active" : "link.button"
            }
            sx={{ borderColor: activeButton === "gallery" ? "" : "black" }}
            mr={4}
            href={`/collections/${collectionId}`}>
            <Flex
              justifyContent="center"
              alignItems="center"
              width="100%"
              sx={{
                "& svg path": {
                  stroke: activeButton === "gallery" ? "white" : "black"
                }
              }}>
              <Icon name="GalleryIcon" color="white" size={24} />
              <Text pl={2}>
                <Trans i18nKey="magazine.galleryButton" />
              </Text>
            </Flex>
          </Button>
          <Button
            variant={
              activeButton === "article" ? "link.button.active" : "link.button"
            }
            sx={{ borderColor: activeButton === "article" ? "" : "black" }}
            href={`/magazine/${collectionId}`}>
            <Flex
              justifyContent="center"
              alignItems="center"
              width="100%"
              sx={{
                "& svg path": {
                  stroke: activeButton === "gallery" ? "black" : "white"
                }
              }}>
              <Icon name="ArticleIcon" color="white" size={24} />
              <Text pl={2}>
                <Trans i18nKey="magazine.articleButton" />
              </Text>
            </Flex>
          </Button>
        </Flex>
      </Flex>
    </>
  );
};
