import { Icon, Tooltip } from "app/shared";
import i18next, { TOptions } from "i18next";
import moment from "moment";
import "moment/locale/en-gb";
import "moment/locale/es";
import "moment/locale/fr";
import "moment/locale/pt";
import React from "react";
import DayPickerInput from "react-day-picker/DayPickerInput";
import { Trans } from "react-i18next";
import { connect } from "react-redux";
import { Box, BoxProps, Flex, Text } from "rebass";
import { compose } from "recompose";
import { Button } from "../button";
import { Label } from "../label";
import "./datePicker.css";
import { ISOLanguageCode } from "i18n/resources/supportedLanguages";

interface PropsExternal extends BoxProps {
  name: string;
  onDayChange: (day: Date) => void;
  errorI18n?: string;
  errorI18nOptions?: TOptions;
  labelI18n?: string;
  infoI18n?: string;
  value?: string;
  clearDate?: () => void;
  disabled?: boolean;
}

interface Props extends PropsExternal {
  language: ISOLanguageCode;
}

export const DatePickerBase: React.FC<Props> = ({
  name,
  onDayChange,
  errorI18n,
  errorI18nOptions,
  labelI18n,
  infoI18n,
  value,
  language,
  clearDate,
  disabled,
  ...rest
}) => {
  const localeFormat = moment
    .localeData(language)
    .longDateFormat("L")
    .split("/")
    .join(".");

  const formatDate = (date: any, format: string, locale: string) => {
    if (date && date !== "") {
      return moment(date).format(localeFormat);
    }
    return "";
  };

  const parseDate = (str: string, format: string, locale: string) => {
    return str && str !== "" ? moment(str, localeFormat).toDate() : new Date();
  };

  const placeholder = formatDate(new Date(), localeFormat, language);

  return (
    <Box {...(rest as any)}>
      {(infoI18n || labelI18n) && (
        <Flex alignItems="center" pb={3}>
          {labelI18n && <Label i18n={labelI18n} pb={0} />}
          {infoI18n && <Tooltip i18nKey={infoI18n} ml={1} />}
        </Flex>
      )}
      {errorI18n && (
        <Text color="text.danger">
          <Trans i18nKey={errorI18n} tOptions={errorI18nOptions} />
        </Text>
      )}
      <Flex flexDirection="row">
        <DayPickerInput
          inputProps={{ readOnly: "readonly", disabled }}
          formatDate={formatDate}
          parseDate={parseDate}
          onDayChange={onDayChange}
          dayPickerProps={{
            locale: language,
            toMonth: new Date(),
            disabledDays: {
              after: new Date()
            },
            weekdaysShort: i18next.t("daysAcronym", { returnObjects: true }),
            months: i18next.t("months", { returnObjects: true })
          }}
          placeholder={placeholder}
          value={formatDate(value, localeFormat, language)}
        />
        <Box sx={{ position: "relative", right: "24px" }}>
          <Icon name="Calendar" size={24} />
        </Box>
        {clearDate && (
          <Button
            bg="fill.0"
            color="text.default"
            onClick={clearDate}
            display="grid"
            sx={{
              position: "relative",
              right: "72px",
              bottom: "1px",
              p: 0,
              border: 0
            }}>
            <Icon name="Close" size={24} />
          </Button>
        )}
      </Flex>
    </Box>
  );
};

const mapStateToProps = (state: any) => ({
  language: state.language.code
});

export const DatePicker = compose<Props, PropsExternal>(
  connect(mapStateToProps)
)(DatePickerBase);
