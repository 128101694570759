import React, { useState, useEffect } from "react";
import { getFollowers, getFollowing } from "../services";

interface Props {
  uid: string;
  mode: "followers" | "following";
}

/**
 * Counter component that returns the total follows/followings for the provided user.
 */
export const UserFollowsCounter: React.FC<Props> = ({ uid, mode }) => {
  const [count, setCount] = useState<number | undefined>(undefined);
  useEffect(() => {
    const call =
      mode === "followers" ? getFollowers(uid, 0, 0) : getFollowing(uid, 0, 0);
    call.then(({ totalElements }) => setCount(totalElements));
  }, [uid, mode]);

  return count === undefined ? null : <>({count})</>;
};
