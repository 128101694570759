import {
  Image,
  BackgroundImage,
  Button,
  Icon,
  FormattedDate,
  ElementOptions,
  LoadingFF,
  PreviewPopUp
} from "app/shared";
import { DocumentThumbnail } from "models";
import React, { useState, BaseSyntheticEvent, ReactNode } from "react";
import { Flex, BoxProps, Box, Text } from "rebass";
import { LikeButton } from "./documentDetails/LikeButton";
import { DocumentShareButton } from "./documentDetails/DocumentShareButton";
import { DocumentInfo } from "./models";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { compose } from "recompose";
import { Trans } from "react-i18next";
import { checkSameUser } from "authentication/utils";
import { AuthUserContext } from "authentication/session";
import { BuyButton } from "./documentDetails/BuyButton";
import { BucketAddItemsButton } from "app/buckets";
import { getCommonTranslation } from "app/shared/translations/utils";
import { ReportUserButton } from "app/shared/elementOptions/ReportUserButton";
import { FollowButton } from "app/account/FollowButton";
import { UserPicture } from "app/account";
import { UserRelationInfo } from "app/account/models";
import { DonationPopUp } from "app/donations/DonationPopup";
import { RemoveCollectionPhotosButton } from "app/collections/RemoveCollectionPhotosButton";
import { DownloadButton } from "./documentDetails/DownloadButton";
import { RemoveBucketItemsButton } from "app/buckets/RemoveBucketItemsButton";

const IMAGE_LINK_ID = "image-link";

interface DocumentClickWrapperProps extends BoxProps {
  children?: ReactNode;
  href?: string;
  onClick?: (e: BaseSyntheticEvent) => void;
}

const DocumentClickWrapper: React.FC<DocumentClickWrapperProps> = ({
  children,
  href,
  onClick,
  ...rest
}) => (
  <Button
    display="block"
    variant="transparent"
    href={href}
    as={href ? undefined : "div"} // Fix: "<button> cannot appear as a descendant of <button>"
    onClick={event => {
      event.preventDefault(); // Disable navigation to href
      onClick?.(event);
    }}
    {...(rest as any)}>
    {children}
  </Button>
);

interface PropsExternal extends BoxProps {
  document: DocumentThumbnail;
  onDocumentClick?: (documentId: number) => void; // overrides onClick of document
  trackClickThrough?: () => void;
  width?: string | string[] | number | number[];
  height?: string | string[] | number | number[];
  hideLoading?: boolean;
  variant?: "image" | "flex";
  showHoverInfo?: boolean;
  showUserInfo?: boolean;
  whiteMode?: boolean;
  documentsList?: DocumentThumbnail[];
  showInfoBottom?: boolean;
  hideText?: boolean;
  customPb?: number | number[] | string | string[];
  collectionId?: number;
  bucketId?: number;
  bucketUid?: string;
  showManageIcons?: boolean;
  titleFontSize?: number | number[];
  textBottomFontSize?: number | number[];
  ownerNameFontSize?: number | number[];
  largeThumbnail?: boolean;
  customMinHeight?: string | string[];
  sideIcons?: boolean;
  forceShowOnHover?: boolean;
}

interface Props extends PropsExternal, RouteComponentProps {}

/**
 * Using DocumentClickWrapper to wrap document with <a> link to document.
 * This will be used when there is no onDocumentClick passed as prop and
 * to allow crawlers to follow links.
 */
const DocumentViewBase: React.FC<Props> = ({
  document,
  onDocumentClick,
  trackClickThrough,
  width,
  height,
  hideLoading,
  variant,
  showHoverInfo,
  history,
  showUserInfo,
  whiteMode,
  documentsList,
  showInfoBottom,
  hideText,
  customPb,
  collectionId,
  showManageIcons,
  bucketId,
  bucketUid,
  titleFontSize,
  textBottomFontSize,
  ownerNameFontSize,
  largeThumbnail,
  customMinHeight,
  sideIcons,
  forceShowOnHover,
  ...rest
}) => {
  const [isHover, setHover] = useState(false);
  const [minHeight, setMinHeight] = useState(customMinHeight ?? "200px");
  const [isOpenRemove, setIsOpenRemove] = useState(false);
  const [documentInfo, setDocumentInfo] = useState<DocumentInfo | undefined>(
    undefined
  ); // used to cache info for the next hover
  let documentLink = `/documents/${document.id}`;
  const [openPreview, setOpenPreview] = useState<boolean>(false);
  const [isBucketAddItemOpen, setIsBucketAddItemOpen] = useState(false);
  const [isShareOpen, setIsShareOpen] = useState(false);
  const [isBucketAdded, setIsBucketAdded] = useState(false);
  const [openDonationPopUp, setOpenDonationPopUp] = useState(false);
  const [openReportPopUp, setOpenReportPopUp] = useState(false);

  const DocumentInfoBox: React.FC = () => (
    <>
      {((forceShowOnHover ? isHover : showInfoBottom) || showInfoBottom) &&
        !hideText && (
          <Flex
            flexDirection="column"
            draggable={false}
            sx={{
              position: "absolute",
              bottom: 0,
              zIndex: 0,
              userSelect: "none"
            }}
            width="100%"
            flexWrap="wrap">
            <Flex pl={2}>
              <UserInfoBox />
            </Flex>
            <Flex
              alignSelf="flex-start"
              maxWidth="80%"
              pr={2}
              py={2}
              pl={2}
              ml={2}
              bg="black">
              <Flex
                alignItems="center"
                pr={1}
                width="100%"
                id={`${IMAGE_LINK_ID}-2`}>
                <Text
                  fontSize={titleFontSize ?? [2, 3, 6]}
                  sx={{
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap"
                  }}
                  variant="grotText"
                  color="white">
                  {document.title ? (
                    document.title
                  ) : (
                    <Trans i18nKey="documents.documentView.noTitle" />
                  )}
                </Text>
              </Flex>
              <AuthUserContext.Consumer>
                {({ authUser }) => {
                  return authUser &&
                    document.owner &&
                    checkSameUser(document.owner.id, authUser) ? (
                    <>
                      <Flex
                        justifyContent="flex-end"
                        alignItems="center"
                        px={2}>
                        <Button
                          variant="transparent"
                          href={`/documents/${document.id}/edit`}
                          sx={{
                            "& svg path": {
                              fill: "white"
                            }
                          }}>
                          <Icon name="Pencil2" size={20} />
                        </Button>
                      </Flex>
                    </>
                  ) : (
                    <>
                      <Flex
                        justifyContent="flex-end"
                        alignItems="center"
                        pl={2}
                        sx={{
                          "& svg path": {
                            fill: "white",
                            strokeWidth: "5px"
                          }
                        }}>
                        <Icon name="ArrowRight" size={20} />
                      </Flex>
                    </>
                  );
                }}
              </AuthUserContext.Consumer>
            </Flex>
            <Flex mt={1} ml={2} mb={5} px={2} alignSelf="flex-start" bg="blue">
              {document.takenAt && (
                <Flex
                  alignItems="center"
                  height="24px"
                  mr={document.city || document.country ? 0 : 2}>
                  <Text
                    variant="grotText"
                    fontSize={textBottomFontSize ?? [1, 2]}
                    color="white">
                    <FormattedDate format="number" date={document.takenAt} />
                  </Text>
                </Flex>
              )}
              {(document.city || document.country) && (
                <Flex
                  alignItems="center"
                  height="24px"
                  pl={3}
                  sx={{
                    textTransform: "none"
                  }}>
                  <Text
                    variant="grotText"
                    fontSize={textBottomFontSize ?? [1, 2]}
                    color="white">
                    {[
                      document.city,
                      document.country && getCommonTranslation(document.country)
                    ]
                      .filter(it => it)
                      .join(", ")}
                  </Text>
                </Flex>
              )}
            </Flex>
          </Flex>
        )}
    </>
  );

  const ManageIcons: React.FC = () => {
    return (
      <>
        <AuthUserContext.Consumer>
          {({ authUser }) =>
            authUser &&
            ((document.owner && checkSameUser(document.owner?.id, authUser)) ||
              (bucketUid && checkSameUser(bucketUid, authUser))) &&
            isHover &&
            showManageIcons && (
              <>
                <Flex
                  width="100%"
                  pb={2}
                  pr={2}
                  alignItems="center"
                  justifyContent="flex-end"
                  flexWrap="wrap"
                  sx={{
                    position: "absolute",
                    bottom: 0,
                    zIndex: 1
                  }}>
                  {document.owner &&
                    checkSameUser(document.owner?.id, authUser) && (
                      <DownloadButton
                        documentId={document.id}
                        width=""
                        showIcon={true}
                        p={1}
                        mr={2}
                      />
                    )}
                  {(collectionId || bucketId) && (
                    <Button onClick={() => setIsOpenRemove(true)} p={1}>
                      <Icon name="Trash" color="red" size={16} />
                    </Button>
                  )}
                </Flex>
              </>
            )
          }
        </AuthUserContext.Consumer>
      </>
    );
  };

  const UserInfoBox: React.FC = () => {
    const [userInfo, setUserInfo] = useState<UserRelationInfo | undefined>(
      undefined
    );

    return (
      <>
        {(showUserInfo || (forceShowOnHover ? isHover : showUserInfo)) &&
          document.owner && (
            <Flex pb={1} sx={{ alignSelf: "flex-start" }}>
              <Flex>
                <Button
                  href={`/users/${document.owner.id}`}
                  width="100%"
                  variant="transparent"
                  bg="white"
                  pr={2}
                  sx={{ "&:hover": { opacity: "1 !important" } }}>
                  {document.owner.picture ? (
                    <UserPicture
                      picture={document.owner?.picture}
                      height="35px"
                      verifiedIconSize={16}
                      userVerified="verified"
                    />
                  ) : (
                    <UserPicture
                      picture={undefined}
                      height="35px"
                      verifiedIconSize={16}
                      userVerified="verified"
                    />
                  )}
                </Button>
              </Flex>
              {document.owner.name && (
                <Flex bg="white" width="100%" alignItems="center">
                  <Button href={`/users/${document.owner.id}`}>
                    <Flex width="100%">
                      <Text
                        pl={3}
                        color="black"
                        variant="grotTextCaps"
                        letterSpacing={1.2}
                        fontSize={ownerNameFontSize ?? 1}>
                        {document.owner.name}
                      </Text>
                    </Flex>
                  </Button>
                </Flex>
              )}
              <AuthUserContext.Consumer>
                {({ authUser }) => {
                  const disabled =
                    !authUser ||
                    (authUser &&
                      document.owner &&
                      checkSameUser(document.owner.id, authUser)); //disable follow button if is the story of the user logged
                  return (
                    !disabled && (
                      <Flex
                        justifyContent="flex-end"
                        alignItems="center"
                        bg="white"
                        pr={2}>
                        <ElementOptions
                          closeMenuOnClick={false}
                          options={[
                            () =>
                              document.owner && (
                                <>
                                  <FollowButton
                                    uid={document.owner.id}
                                    width="100%"
                                    info={userInfo}
                                    onInfoChange={userInfo =>
                                      setUserInfo(userInfo)
                                    }
                                    variant="transparent"
                                    color="black"
                                    py={2}
                                  />
                                  <DonationPopUp
                                    width="100%"
                                    onlyTrigger={true}
                                    onClickTrigger={() =>
                                      setOpenDonationPopUp(true)
                                    }
                                    uid={document.owner.id}
                                    sx={{ borderLeft: 0, borderRight: 0 }}
                                  />
                                  <ReportUserButton
                                    userId={document.owner.id}
                                    onlyTrigger={true}
                                    onClickTrigger={() =>
                                      setOpenReportPopUp(true)
                                    }
                                    sx={{
                                      border: 0,
                                      textAlign: "center",
                                      color: "red"
                                    }}
                                  />
                                </>
                              )
                          ]}
                          elementId={20}
                          iconName="MoreOptions"
                          iconSize={18}
                          optionsWidth={["139px", "179px"]}
                          backgroundAlwaysActive={true}
                          sx={{
                            borderRadius: 0,
                            bg: "transparent",
                            borderColor: "black.0",
                            borderRight: "none",
                            "& svg path": { fill: "transparent" }
                          }}
                          popupSx={{
                            position: "relative",
                            top: "26px",
                            color: "black",
                            zIndex: 1,
                            left: ["-130px", "-170px"]
                          }}
                        />
                      </Flex>
                    )
                  );
                }}
              </AuthUserContext.Consumer>
            </Flex>
          )}
      </>
    );
  };

  return (
    <Box
      pb={customPb ?? 0}
      sx={{ position: "relative" }}
      onMouseEnter={() => (isHover === false ? setHover(true) : null)}
      onMouseLeave={() => (isHover === true ? setHover(false) : null)}>
      <AuthUserContext.Consumer>
        {({ authUser }) => {
          const isSameUser =
            authUser &&
            document.owner &&
            checkSameUser(document.owner.id, authUser);
          documentLink = isSameUser ? documentLink + "/edit" : documentLink;
          return (
            <DocumentClickWrapper
              href={documentLink}
              onClick={e => {
                const eventTarget = e.target.id || e.target.parentNode.id;
                if (eventTarget.startsWith(IMAGE_LINK_ID)) {
                  // history.push(documentLink);
                  setOpenPreview(true);
                }
              }}
              width={variant === "flex" ? width : undefined}
              height={variant === "flex" ? height : undefined}
              minHeight={
                hideLoading ? "0px" : (customMinHeight ?? "200px") || minHeight
              }
              sx={{
                position: "relative",
                "&:hover": { opacity: "initial !important" } // overrides button opacity on hover
              }}>
              {variant === "image" && (
                <Image
                  src={
                    largeThumbnail
                      ? document.thumbnail.replace("small", "large")
                      : document.thumbnail
                  }
                  width={width}
                  height={height}
                  onLoad={setMinHeight("0px")}
                  {...(rest as any)}
                />
              )}
              {variant === "flex" && (
                <BackgroundImage
                  src={
                    largeThumbnail
                      ? document.thumbnail.replace("small", "large")
                      : document.thumbnail
                  }
                  width="100%"
                  height="100%"
                  onLoad={setMinHeight("0px")}
                  {...(rest as any)}
                />
              )}
              {!hideLoading && (
                <Box
                  width="100%"
                  sx={{ position: "absolute", zIndex: -1, bottom: "40%" }}>
                  <LoadingFF showIcon={true} hideBar={true} />
                </Box>
              )}
              <Flex
                id={`${IMAGE_LINK_ID}-1`} //to work href only in the thumbnail area
                width="100%"
                height="100%"
                sx={{
                  position: "absolute",
                  top: 0
                }}></Flex>
              <ManageIcons />
              <DocumentInfoBox />
            </DocumentClickWrapper>
          );
        }}
      </AuthUserContext.Consumer>
      {(showInfoBottom || (forceShowOnHover ? isHover : showInfoBottom)) && (
        <Box
          mr={sideIcons ? 0 : 2}
          mt={2}
          sx={{
            position: sideIcons ? "static" : "absolute",
            top: 0,
            right: 0,
            zIndex: 0
          }}>
          <Flex>
            <Flex
              width="100%"
              justifyContent="space-between"
              flexDirection={sideIcons ? "row" : "column"}>
              <AuthUserContext.Consumer>
                {({ authUser }) =>
                  authUser &&
                  document.owner &&
                  !checkSameUser(document.owner?.id, authUser) && (
                    <Flex
                      bg="white"
                      sx={{ border: 1, borderColor: "blue" }}
                      mt={sideIcons ? 0 : 2}
                      p={1}>
                      <LikeButton
                        name="Like"
                        variant="icon"
                        display="block"
                        documentId={document.id}
                        documentInfo={documentInfo}
                        onInfoChange={info =>
                          setDocumentInfo(info as DocumentInfo)
                        }
                      />
                    </Flex>
                  )
                }
              </AuthUserContext.Consumer>
              <Flex mt={sideIcons ? 0 : 2} pr={sideIcons ? 5 : 0}>
                <Button
                  bg="white"
                  onClick={() => setIsShareOpen(true)}
                  p={1}
                  variant="transparent"
                  sx={{
                    border: 1,
                    borderColor: "blue",
                    "&:hover": { opacity: "1 !important" },
                    "& svg path": { fill: "transparent", stroke: "blue" }
                  }}>
                  <Icon name="Share2" size={22} />
                </Button>
              </Flex>
              <AuthUserContext.Consumer>
                {({ authUser }) =>
                  authUser && (
                    <Flex mt={sideIcons ? 0 : 2} pr={sideIcons ? 5 : 0}>
                      <Button
                        bg="white"
                        onClick={() => setIsBucketAddItemOpen(true)}
                        p={1}
                        variant="transparent"
                        sx={{
                          border: 1,
                          borderColor: "blue",
                          "&:hover": { opacity: "1 !important" }
                        }}>
                        <Icon
                          name={
                            isBucketAdded
                              ? "AddToCollectionFilled"
                              : "AddToCollection"
                          }
                          size={22}
                          color="blue"
                        />
                      </Button>
                    </Flex>
                  )
                }
              </AuthUserContext.Consumer>
              <Flex>
                <AuthUserContext.Consumer>
                  {({ authUser }) =>
                    authUser &&
                    document.owner &&
                    !checkSameUser(document.owner?.id, authUser) && (
                      <Flex mt={sideIcons ? 0 : 2}>
                        <BuyButton
                          display="block"
                          type="document"
                          documentId={document.id}
                        />
                      </Flex>
                    )
                  }
                </AuthUserContext.Consumer>
              </Flex>
            </Flex>
          </Flex>
        </Box>
      )}
      <AuthUserContext.Consumer>
        {({ authUser }) =>
          authUser &&
          ((document.owner && checkSameUser(document.owner?.id, authUser)) ||
            (bucketUid && checkSameUser(bucketUid, authUser))) &&
          (collectionId || bucketId) &&
          showManageIcons && (
            <>
              {collectionId ? (
                <RemoveCollectionPhotosButton
                  key="collection-remove-button"
                  documentIds={[document.id]}
                  collectionId={collectionId}
                  hideTrigger={true}
                  open={isOpenRemove}
                  onOpen={() => setIsOpenRemove(true)}
                  onClose={() => setIsOpenRemove(false)}
                />
              ) : bucketId ? (
                <RemoveBucketItemsButton
                  bucketItemIds={[{ type: "document", id: document.id }]}
                  bucketId={bucketId}
                  hideTrigger={true}
                  open={isOpenRemove}
                  onOpen={() => setIsOpenRemove(true)}
                  onClose={() => setIsOpenRemove(false)}
                />
              ) : (
                <></>
              )}
            </>
          )
        }
      </AuthUserContext.Consumer>
      <DocumentShareButton
        hideTrigger={true}
        open={isShareOpen}
        onClose={() => setIsShareOpen(false)}
        name="Share"
        variant="transparent"
        display="block"
        document={document}
      />
      <AuthUserContext.Consumer>
        {({ authUser }) =>
          authUser && (
            <>
              <BucketAddItemsButton
                onAdd={added => setIsBucketAdded(added)}
                open={isBucketAddItemOpen}
                onClose={() => setIsBucketAddItemOpen(false)}
                hideTrigger={true}
                bucketItemIds={[{ type: "document", id: document.id }]}
                variant="icon"
              />
              {document.owner && (
                <>
                  <DonationPopUp
                    width="100%"
                    hideTrigger={true}
                    onClose={() => setOpenDonationPopUp(false)}
                    open={openDonationPopUp}
                    uid={document.owner.id}
                  />
                  <ReportUserButton
                    userId={document.owner.id}
                    hideTrigger={true}
                    onClose={() => setOpenReportPopUp(false)}
                    open={openReportPopUp}
                  />
                </>
              )}
            </>
          )
        }
      </AuthUserContext.Consumer>
      <PreviewPopUp
        documentId={document.id}
        documentsList={documentsList as DocumentThumbnail[]}
        open={openPreview}
        onClose={() => setOpenPreview(false)}
      />
    </Box>
  );
};

DocumentViewBase.defaultProps = {
  width: "480px", // TODO: Remove this when thumbnails have at least width=480px
  variant: "image",
  showHoverInfo: false,
  showUserInfo: true,
  whiteMode: true,
  hideLoading: false,
  showInfoBottom: true,
  forceShowOnHover: true
};

export const DocumentView = compose<Props, PropsExternal>(withRouter)(
  DocumentViewBase
);
