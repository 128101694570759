import {
  Transaction,
  TransactionDocumentLicense,
  AvailableLicenseType,
  Price,
  PaymentProviderName,
  TransactionItemLicense
} from "marketplace";
import { Document, SignedUrl, UploadTask, Label } from "models";
import axios from "network";
import queryString from "query-string";
import { DocumentInfo } from "../models";
import {
  UpdateDocumentCommand,
  UpdateDocumentResponse,
  UpdateDocumentsResponse
} from "../submitContent/models";

/**
 * Tasks
 */
export const getPendingTasks = async (): Promise<UploadTask[]> => {
  return axios.get(`/documents/tasks/pending`).then(response => response.data);
};

export const createTask = async (): Promise<UploadTask> => {
  return axios.post(`/documents/tasks`).then(response => response.data);
};

export const getTask = async (taskId: number): Promise<UploadTask> => {
  return axios
    .get(`/documents/tasks/${taskId}`)
    .then(response => response.data);
};

export const submitTask = async (taskId: number): Promise<void> => {
  return axios.post(`/documents/tasks/${taskId}/submit`);
};

export const publishTask = async (taskId: number): Promise<void> => {
  return axios.post(`/documents/tasks/${taskId}/publish`);
};

export const deleteTask = async (taskId: number): Promise<void> => {
  return axios.delete(`/documents/tasks/${taskId}`);
};

/**
 * Documents
 */
export const getDocument = async (documentId: number): Promise<Document> => {
  return axios.get(`/documents/${documentId}`).then(response => response.data);
};

const creatUpdateCommandFromDocument = (
  document: Document
): UpdateDocumentCommand => ({
  takenAt: document.takenAt,
  latitude: document.latitude,
  longitude: document.longitude,
  userLabels: document.userLabels.map(it => it.id),
  userCategories: document.userCategories.map(it => it.id),
  collections: document.collections.map(it => it.id),
  attributes: document.attributes,
  credits: document.credits.map(it => it.id),
  translations: document.translations,
  prices: document.prices
});

export const updateDocument = async (
  document: Document
): Promise<UpdateDocumentResponse> => {
  try {
    const cmd = creatUpdateCommandFromDocument(document);
    await axios.put(`/documents/${document.id}`, cmd);
    return { documentId: document.id };
  } catch (err) {
    console.error("Error updating document", err);
    return { error: err };
  }
};

export const updateDocuments = async (
  documents: Document[]
): Promise<UpdateDocumentsResponse> => {
  try {
    const listCmd = documents.map(it => ({
      id: it.id,
      cmd: creatUpdateCommandFromDocument(it)
    }));
    await axios.put(`/documents`, { documents: listCmd });
    return documents.map(it => ({ documentId: it.id }));
  } catch (err) {
    console.error("Error updating document", err);
    return { error: err };
  }
};

export const uploadDocuments = async (
  taskId: number,
  files: File[]
): Promise<Document[]> => {
  const mediaSource = "private";
  const data = new FormData();

  for (let i = 0; i < files.length; i++) {
    data.append("file", files[i]);
  }

  return axios
    .post(
      `/documents/tasks/${taskId}?${queryString.stringify({ mediaSource })}`,
      data
    )
    .then(response => response.data)
    .catch(error => {
      if (error?.response?.status === 400) {
        const errorMessage =
          error.response && error.response.data
            ? error.response.data.message
            : error.message;
        throw Error(errorMessage);
      } else {
        throw Error("Server error, please try again later");
      }
    });
};

export const downloadDocument = async (
  documentId: number
): Promise<SignedUrl> => {
  return axios
    .get(`/documents/${documentId}/download`)
    .then(response => response.data);
};

export const deleteDocument = async (documents: number[]) => {
  return axios.delete(`/documents`, {
    data: { documents } // axios does not support body in delete requests
  });
};

export const getDocumentPayments = async (page: number, size: number) => {
  return axios
    .get(`/documents/payments?${queryString.stringify({ page, size })}`)
    .then((response: any) => ({
      data: response.data,
      ...response.pagination
    }));
};

export const getDocumentPayouts = async (page: number, size: number) => {
  return axios
    .get(`/documents/payouts?${queryString.stringify({ page, size })}`)
    .then((response: any) => ({
      data: response.data,
      ...response.pagination
    }));
};

/**
 * Transactions
 */
export const getAvailableLicenses = async (): Promise<AvailableLicenseType[]> => {
  return axios.get(`/licenses/available`).then(response => response.data);
};

export const createTransaction = async (
  items: TransactionDocumentLicense[],
  totalChargeAmount: Price,
  paymentProviderName?: PaymentProviderName,
  paymentSource?: string
): Promise<Transaction> => {
  return axios
    .post(`/transactions`, {
      items,
      totalChargeAmount,
      paymentProviderName,
      paymentSource
    })
    .then(response => response.data);
};

export const getTransactionItemLink = async (
  transactionItemId: number
): Promise<SignedUrl> => {
  return axios
    .get(`/transactions/items/${transactionItemId}/download`)
    .then(response => response.data);
};

export const retryTransaction = async (
  transactionId: number,
  paymentProviderName: PaymentProviderName,
  paymentSource: string
) => {
  return axios.post(`/transactions/${transactionId}/charge`, {
    paymentProviderName,
    paymentSource
  });
};

export const getTransactionItemLicenseLink = async (
  transactionItemId: number
): Promise<TransactionItemLicense> => {
  return axios
    .get(`/transactions/items/${transactionItemId}/license`)
    .then(response => response.data);
};

export const getTransactionItemReceipt = async (
  transactionItemId: number
): Promise<SignedUrl> => {
  return axios
    .get(`/transactions/items/${transactionItemId}/receipt`)
    .then(response => response.data);
};

export const getTransactionItemInvoice = async (
  transactionItemId: number
): Promise<SignedUrl> => {
  return axios
    .get(`/transactions/items/${transactionItemId}/invoice`)
    .then(response => response.data);
};

/**
 * Relations
 */
export const getDocumentInfo = async (
  documentId: number
): Promise<DocumentInfo> => {
  return axios
    .get(`/relations/documents/${documentId}/info`)
    .then(response => response.data);
};

export const likeDocument = async (documentId: number) => {
  return axios.put(`/relations/documents/${documentId}/like`);
};

export const unlikeDocument = async (documentId: number) => {
  return axios.delete(`/relations/documents/${documentId}/like`);
};

export const share = async (documentId: number) => {
  return axios.put(`/relations/documents/${documentId}/share`);
};

/**
 * Reports
 */
export const reportDocument = async (
  documentId: number,
  description: string
) => {
  return axios.post(
    `/reports`,
    queryString.stringify({
      objectType: "Document",
      objectId: documentId,
      description: description
    }),
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      }
    }
  );
};

/**
 * Labels
 */
export const createLabel = async (title: string): Promise<Label> => {
  return axios.post(`/labels`, { title }).then(response => response.data);
};
