import React from "react";
import { Box, Text, Flex } from "rebass";
import { Trans } from "react-i18next";
import { compose } from "recompose";
import { TransactionItemLicense, MonetaryAmount } from "marketplace";
import { formatToStringDate } from "app/shared/formattedDate/utils";
import { ISOLanguageCode } from "i18n/resources/supportedLanguages";
import { connect } from "react-redux";
import { Document } from "models";
import { Image, BackgroundImage } from "app/shared";
import { Paragraph } from "i18n/components";

interface PropsExternal {
  license: TransactionItemLicense;
  document?: Document;
}

interface Props extends PropsExternal {
  language: ISOLanguageCode;
}

const License: React.FC<Props> = ({ license, document, language }) => {
  const createdAt = formatToStringDate(
    license.transactionItem.createdAt,
    language,
    true
  );
  const buyerAddress = `${license.buyer.address}, ${license.buyer.postalCode} ${license.buyer.city}, ${license.buyer.country}`;
  const sellerAddress = `${license.seller.address}, ${license.seller.postalCode} ${license.seller.city}, ${license.seller.country}`;
  const price = new MonetaryAmount(
    license.transactionItem.price.value,
    license.transactionItem.price.currency,
    license.transactionItem.price.precision
  );

  return (
    <Box>
      <Text textAlign="center" fontWeight="bold">
        <Trans i18nKey="documents.receipts.license.licenseStandard.title" />
      </Text>
      <Flex justifyContent="center" mt={4}>
        {license.transactionItem.thumbnail.length ? (
          <Image
            src={license.transactionItem.thumbnail}
            width="50%"
            maxWidth="500px"
          />
        ) : (
          <BackgroundImage width="500px" height="300px" />
        )}
      </Flex>
      {document && document.credits.length > 0 && (
        <Text fontWeight="bold" variant="caps" mt={2} textAlign="center">
          <Trans i18nKey="documents.receipts.license.licenseStandard.credits" />{" "}
          {document.credits.map(it => it.name).join(", ")}
        </Text>
      )}
      <Text fontWeight="bold" my={4}>
        <Trans i18nKey="documents.receipts.license.licenseStandard.inBetween" />
      </Text>
      <Box
        sx={{
          "& table": {
            width: "100%",
            borderCollapse: "collapse"
          },
          "& table, td": {
            border: "1px solid black"
          },
          "& td": {
            width: "50%",
            p: 1
          }
        }}>
        <table>
          <tbody>
            <tr>
              <td>
                <Trans i18nKey="documents.receipts.license.licenseStandard.name" />
              </td>
              <td>{license.seller.name}</td>
            </tr>
            <tr>
              <td>
                <Trans i18nKey="documents.receipts.license.licenseStandard.nationality" />
              </td>
              <td>{license.seller.nationality}</td>
            </tr>
            <tr>
              <td>
                <Trans i18nKey="documents.receipts.license.licenseStandard.address" />
              </td>
              <td>{sellerAddress}</td>
            </tr>
            <tr>
              <td>
                <Trans i18nKey="documents.receipts.license.licenseStandard.identificationCardNumber" />
              </td>
              <td>{license.seller.nationalCardNumber}</td>
            </tr>
            <tr>
              <td>
                <Trans i18nKey="documents.receipts.license.licenseStandard.taxIdentificationNumber" />
              </td>
              <td>{license.seller.vat}</td>
            </tr>
          </tbody>
        </table>
        <Text my={4}>
          <Trans i18nKey="documents.receipts.license.licenseStandard.and" />
        </Text>
        <table>
          <tbody>
            <tr>
              <td>
                <Trans i18nKey="documents.receipts.license.licenseStandard.name" />
              </td>
              <td>{license.buyer.name}</td>
            </tr>
            <tr>
              <td>
                <Trans i18nKey="documents.receipts.license.licenseStandard.nationality" />
              </td>
              <td>{license.buyer.nationality}</td>
            </tr>
            <tr>
              <td>
                <Trans i18nKey="documents.receipts.license.licenseStandard.address" />
              </td>
              <td>{buyerAddress}</td>
            </tr>
            <tr>
              <td>
                <Trans i18nKey="documents.receipts.license.licenseStandard.identificationCardNumber" />
              </td>
              <td>{license.buyer.nationalCardNumber}</td>
            </tr>
            <tr>
              <td>
                <Trans i18nKey="documents.receipts.license.licenseStandard.taxIdentificationNumber" />
              </td>
              <td>{license.buyer.vat}</td>
            </tr>
          </tbody>
        </table>
      </Box>
      <Box mt={4} sx={{ "& > *": { lineHeight: "150%" } }}>
        <Text>
          <Trans
            i18nKey="documents.receipts.license.licenseStandard.agreement"
            components={[<b />]}
            values={{ date: createdAt }}
          />
        </Text>
        <Text textAlign="center" mt={10}>
          <Trans i18nKey="documents.receipts.license.licenseStandard.commercialConditionsTitle" />
        </Text>
        <Text>
          <Trans
            i18nKey="documents.receipts.license.licenseStandard.commercialConditionsContent"
            components={[<b />]}
            values={{
              imageId: license.transactionItem.documentId,
              price: price.toLocaleString()
            }}
          />
        </Text>
      </Box>
      <Flex
        mt={8}
        sx={{
          border: 1,
          borderColor: "black"
        }}>
        <Box
          width="50%"
          sx={{
            borderRight: 1,
            borderColor: "black"
          }}>
          <Text p={1} pb={4}>
            <Trans i18nKey="documents.receipts.license.licenseStandard.licensor" />
          </Text>
          <Text p={1}>
            <Trans i18nKey="documents.receipts.license.licenseStandard.name" />:{" "}
            {license.buyer.name}
          </Text>
          <Text p={1}>
            <Trans i18nKey="documents.receipts.license.licenseStandard.date" />:{" "}
            {createdAt}
          </Text>
        </Box>
        <Box width="50%">
          <Text p={1} pb={4}>
            <Trans i18nKey="documents.receipts.license.licenseStandard.licensee" />
          </Text>
          <Text p={1}>
            <Trans i18nKey="documents.receipts.license.licenseStandard.name" />:{" "}
            {license.seller.name}
          </Text>
          <Text p={1}>
            <Trans i18nKey="documents.receipts.license.licenseStandard.date" />:{" "}
            {createdAt}
          </Text>
        </Box>
      </Flex>
      <Box mt={4} sx={{ "& > *": { lineHeight: "200%" } }}>
        <Text textAlign="center">
          <Trans i18nKey="documents.receipts.license.licenseStandard.annex1Title" />
        </Text>
        <Text mt={4}>
          <Trans
            i18nKey="documents.receipts.license.licenseStandard.annex1Content"
            components={[<b />, <Paragraph>Translation</Paragraph>]}
            values={{
              licensorName: license.seller.name,
              licensorAddress: sellerAddress,
              licensorEmail: license.seller.email,
              licenseeName: license.buyer.name,
              licenseeAddress: buyerAddress,
              licenseeEmail: license.buyer.email
            }}
          />
        </Text>
      </Box>
    </Box>
  );
};

const mapStateToProps = (state: any) => ({
  language: state.language.code
});

export const LicenseStandard = compose<Props, PropsExternal>(
  connect(mapStateToProps)
)(License);
