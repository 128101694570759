import React from "react";
import Svg, { SvgProps } from "primitives/Svg";

const SvgPencil2 = ({ width, height, size, color }: SvgProps) => (
  <Svg
    width={size || width}
    height={size || height}
    viewBox="0 0 20 20"
    color={color}>
    <path
      d="M14.786 8.033l-.106-.106-.106.106-9.776 9.787-2.627.009.009-2.627 9.776-9.777.105-.105-.104-.106-.7-.71-.106-.107-.107.106-9.66 9.65h0l-.49.49-.044.044V19.15h4.463l.044-.045.49-.499h0l9.65-9.65.105-.106-.106-.106-.71-.71zM12.484 3.054l-.106.106.106.106.71.71.106.106.106-.106 1.334-1.334 2.617 2.618-1.343 1.334-.107.106.107.106.71.71.106.106.106-.106 2.17-2.17.106-.106-.106-.106-4.25-4.24-.106-.106-.106.106-2.16 2.16z"
      stroke="#000"
      strokeWidth={0.3}
    />
  </Svg>
);

SvgPencil2.defaultProps = {
  width: 24,
  height: 24
};
export default SvgPencil2;
