import { DocumentView } from "app/documents";
import { Button, Icon } from "app/shared";
import { Label } from "app/shared/label";
import { MonetaryText } from "app/shared/monetaryText";
import { EmptyHeader, TableLayout } from "app/shared/tableLayout";
import React from "react";
import { Trans } from "react-i18next";
import { Box, Flex, Heading, Text } from "rebass";
import store from "store";
import { useMediaQueryContext } from "styles/context";
import { CheckoutItem } from "./models";
import * as actions from "./reducers/actions";

interface Props {
  checkout: CheckoutItem[];
  onComplete: () => void;
}

export const ReviewItems: React.FC<Props> = ({ checkout, onComplete }) => {
  const { isMedium } = useMediaQueryContext();

  return (
    <Box>
      <Text textAlign="center" mb={3}>
        <Trans i18nKey="documents.reviewItems.pleaseReview" />
      </Text>
      <Box
        px={4}
        py={3}
        sx={{
          border: "1px solid #000"
        }}>
        <Heading mb={3}>
          <Trans i18nKey="documents.reviewItems.itemsInCart" />
        </Heading>
        {checkout.length ? (
          <TableLayout
            width="100%"
            columns={4}
            py={[1, 2, 3]}
            mb={[1, 2, 3]}
            tableNodes={[
              <Label
                key="mediaHeader"
                i18n="documents.reviewItems.mediaHeader"
                fontSize={1}
                sx={{ borderBottom: 1, borderColor: "black.0" }}
              />,
              <Label
                key="idHeader"
                i18n="documents.reviewItems.idHeader"
                fontSize={1}
                sx={{ borderBottom: 1, borderColor: "black.0" }}
              />,
              <Label
                key="priceHeader"
                i18n="documents.reviewItems.priceHeader"
                fontSize={1}
                sx={{ borderBottom: 1, borderColor: "black.0" }}
              />,
              <EmptyHeader
                key="emptyHeader-2"
                sx={{ borderBottom: 1, borderColor: "black.0" }}
              />,
              ...checkout.flatMap((it: CheckoutItem) => [
                <DocumentView
                  key={`${it.documentId}-view`}
                  document={{
                    id: it.documentId,
                    thumbnail: it.thumbnail
                  }}
                  width={isMedium ? "100px" : "200px"}
                />,
                <Text key={`${it.documentId}-id`}>{it.documentId}</Text>,
                <MonetaryText
                  key={`${it.documentId}-monetaryText`}
                  price={{
                    value: it.licensePrice.basePrice.value,
                    currency: it.licensePrice.basePrice.currency,
                    precision: it.licensePrice.basePrice.precision
                  }}
                />,
                <Flex key={`${it.documentId}-flex`} justifyContent="flex-end">
                  <Button
                    bg="fill.0"
                    onClick={() =>
                      store.dispatch(actions.removeCheckoutItem(it))
                    }
                    variant="inlineList"
                    display="grid">
                    <Icon name="Close" size={isMedium ? 16 : 24} />
                  </Button>
                </Flex>
              ])
            ]}
          />
        ) : (
          <Text>
            <Trans i18nKey="documents.reviewItems.noItems" />
          </Text>
        )}
      </Box>
      <Button
        display="block"
        width="100%"
        p={3}
        mt={3}
        onClick={() => onComplete()}
        disabled={checkout.length === 0}>
        <Text fontWeight="bold">
          <Trans i18nKey="documents.reviewItems.next" />
        </Text>
      </Button>
    </Box>
  );
};
