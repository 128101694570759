import React, { useState } from "react";
import i18next from "i18next";
import { Popup } from "app/shared";
import { Trans } from "react-i18next";
import { Text, Box } from "rebass";
import { compose } from "recompose";
import { RouteComponentProps, withRouter } from "react-router";
import queryString from "query-string";

interface PropsExternal {
  trigger?: any;
}

interface Props extends PropsExternal, RouteComponentProps {}

export const WelcomePopupBase: React.FC<Props> = ({
  trigger,
  location,
  history
}) => {
  const query = queryString
    .parse(location.search, { decode: false })
    ?.["unverified"]?.toString();

  const [open] = useState<boolean>(query ? true : false);

  const submitBecomeVerified = (close: () => void) => {
    history.push("/submit-content");
    close();
  };

  return (
    <Popup
      open={open}
      trigger={trigger}
      contentPadding={0}
      heading={i18next.t("account.popups.welcomePopup.heading")}
      cancel={close => close()}
      cancelText={i18next.t("account.popups.welcomePopup.cancel")}
      submit={close => submitBecomeVerified(close)}
      submitText={i18next.t("account.popups.welcomePopup.submit")}>
      <>
        <Box>
          <Text textAlign="center" p={2} variant="grotText">
            <Trans i18nKey="account.popups.welcomePopup.content1" />
          </Text>
          <Text textAlign="center" pb={2} variant="grotText">
            <Trans i18nKey="account.popups.welcomePopup.content2" />
          </Text>
        </Box>
      </>
    </Popup>
  );
};

export const WelcomePopup = compose<Props, PropsExternal>(withRouter)(
  WelcomePopupBase
);
