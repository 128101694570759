import { Select } from "app/shared";
import React from "react";
import { connect } from "react-redux";
import { Flex, SxStyleProp } from "rebass";
import { compose } from "recompose";
import { Action, Dispatch } from "redux";
import * as actions from "i18n/reducers/actions";
import {
  SupportedLanguages,
  ISOLanguageCode
} from "i18n/resources/supportedLanguages";
import i18n from "../../i18n/i18n";

interface PropsExternal {
  menuMarginTop?: number;
  width?: number;
  sx?: SxStyleProp;
}

interface Props extends PropsExternal {
  language: ISOLanguageCode;
  setLanguage: (code: ISOLanguageCode) => Promise<any>;
}

const LanguageSelectBase: React.FC<Props> = ({
  language,
  setLanguage,
  menuMarginTop,
  width,
  sx
}) => {
  const supportedLanguage = SupportedLanguages.find(
    it => it.code === language ?? i18n.language
  )!;

  return (
    <Flex alignItems="center" sx={sx}>
      <Select
        marginArrow={-40}
        defaultValue={{
          value: supportedLanguage.code,
          label: supportedLanguage.name
        }}
        onChange={options => {
          setLanguage(options.value).then(() => window.location.reload());
        }}
        options={SupportedLanguages.map(language => ({
          value: language.code,
          label: language.name
        }))}
        width={width ?? 80}
        menuMarginTop={menuMarginTop ?? 20}
      />
    </Flex>
  );
};

const mapStateToProps = (state: any) => ({
  language: state.language.code
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  setLanguage: async (code: ISOLanguageCode) =>
    dispatch(actions.setLanguage(code))
});

export const LanguageSelect = compose<Props, PropsExternal>(
  connect(mapStateToProps, mapDispatchToProps)
)(LanguageSelectBase);
