import { SearchProvider, WithSearch } from "@elastic/react-search-ui";
import AppSearchAPIConnector from "@elastic/search-ui-app-search-connector";
import { BucketItemsView, BucketItemView } from "app/buckets";
import { BucketItem } from "app/buckets/models";
import { ResultField, SearchFilter } from "app/search/models";
import { CachedPagination, PageEmptyState } from "app/shared";
import environment from "configurations";
import React, { Component, ReactNode } from "react";
import { Trans } from "react-i18next";
import { mapBucketItemResults } from "../utils/mapResults";

interface Props {
  filters: SearchFilter[];
  resultFields?: ResultField[];
  sortField: string;
  sortDirection: "asc" | "desc";
  resultsPerPage: number;
  maxResults?: number;
  view?: (
    viewable: BucketItem[],
    setPage: (mode: "next" | "previous") => void
  ) => React.ReactElement;
  scrollListenerEnabled?: boolean;
  showLoadButtons?: boolean;
  noResultsView?: ReactNode;
}

interface WithSearchProps {
  wasSearched: boolean;
  results: any[];
  totalPages: number;
  setCurrent: (page: number) => void;
  current: number;
  isLoading: boolean;
}

/**
 * When maxResults > resultsPerPage results are wrap with Pagination that will search for more results in page bottom scroll.
 */
export class BucketItemSearch extends Component<Props> {
  static defaultProps = {
    filters: [],
    resultFields: [],
    resultsPerPage: 20,
    sortField: "id",
    sortDirection: "desc"
  };

  /**
   * Use view from props or use the default.
   */
  view(viewable: BucketItem[], setPage: (mode: "next" | "previous") => void) {
    const { view } = this.props;

    if (view) {
      return view(viewable, setPage);
    } else {
      return (
        <BucketItemsView>
          {viewable.map(bucketItem => (
            <BucketItemView bucketItem={bucketItem} bucketsList={viewable} />
          ))}
        </BucketItemsView>
      );
    }
  }

  render() {
    const {
      filters,
      maxResults,
      resultsPerPage,
      scrollListenerEnabled,
      showLoadButtons,
      sortField,
      sortDirection,
      noResultsView
    } = this.props;

    return (
      <SearchProvider
        config={{
          apiConnector: new AppSearchAPIConnector(environment.search),
          alwaysSearchOnInitialLoad: true,
          trackUrlState: false,
          initialState: {
            resultsPerPage
          },
          searchQuery: {
            filters: [
              {
                field: "class_name",
                values: ["document", "collection"],
                type: "any"
              },
              ...filters
            ],
            sort: { [sortField]: sortDirection },
            result_fields: {
              real_id: { raw: {} },
              class_name: { raw: {} },
              created_at: { raw: {} },
              uid: { raw: {} },
              owner: { raw: {} },
              // Document fields
              thumbnail: { raw: {} },
              thumbnail_large: { raw: {} },
              width: { raw: {} },
              height: { raw: {} },
              city: { raw: {} },
              country: { raw: {} },
              taken_at: { raw: {} },
              translations: { raw: {} },
              owner_picture: { raw: {} },
              // Collections fields
              privacy: { raw: {} },
              title: { raw: {} },
              description: { raw: {} },
              total_documents: { raw: {} },
              documents: { raw: {} }
            }
          }
        }}>
        <WithSearch
          mapContextToProps={({
            wasSearched,
            results,
            totalPages,
            setCurrent,
            current,
            isLoading
          }: WithSearchProps) => ({
            wasSearched,
            results,
            totalPages,
            setCurrent,
            current,
            isLoading
          })}>
          {({
            wasSearched,
            results,
            totalPages,
            setCurrent,
            current,
            isLoading
          }: WithSearchProps) => {
            if (results.length) {
              const mappedResults: BucketItem[] = mapBucketItemResults(results);

              // Wrap results around Pagination if maxResults > resultsPerPage
              if (maxResults === undefined || maxResults > resultsPerPage) {
                return (
                  <CachedPagination<BucketItem>
                    disabled={isLoading}
                    scrollListenerEnabled={scrollListenerEnabled}
                    showLoadButtons={showLoadButtons}
                    firstPageNumber={1}
                    results={mappedResults}
                    page={current}
                    totalPages={totalPages}
                    setPage={setCurrent}>
                    {(viewable, setPage) => this.view(viewable, setPage)}
                  </CachedPagination>
                );
              } else {
                return this.view(mappedResults, (mode: "next" | "previous") => {
                  if (mode === "next") {
                    setCurrent(current + 1);
                  } else {
                    setCurrent(current - 1);
                  }
                });
              }
            } else {
              return noResultsView ? (
                noResultsView
              ) : (
                <PageEmptyState
                  loading={!wasSearched || isLoading}
                  header={
                    <Trans i18nKey="search.buckets.BucketItemSearch.noItems" />
                  }
                />
              );
            }
          }}
        </WithSearch>
      </SearchProvider>
    );
  }
}
