import React, { Component, ReactNode } from "react";
import { Box, Text, Flex } from "rebass";
import { Label } from "../label";
import { Select } from "../select";
import { SelectOption } from "./models";
import { Trans } from "react-i18next";
import { TOptions } from "i18next";
import { Tooltip } from "app/shared";

interface Props {
  options: SelectOption[];
  onChange: (options: SelectOption[]) => void;
  defaultValues?: string[];
  onInputChange?: (value: string) => void;
  isMulti?: boolean;
  disabled?: boolean;
  errorI18n?: string;
  errorI18nOptions?: TOptions;
  labelI18n?: string;
  infoI18n?: string;
  placeholder?: string;
  noOptions?: string | ReactNode;
  fullBorder?: boolean;
  hideLabels?: boolean;
}

export class MultiSelect extends Component<Props, any> {
  static defaultProps = {
    isMulti: true,
    disabled: false
  };

  handleSelectChange = (options: any) => {
    const values = Array.isArray(options) ? options : options ? [options] : [];
    this.props.onChange(values);
  };

  handleInputChange = (value: string) => {
    const { onInputChange, disabled } = this.props;

    if (onInputChange && !disabled) {
      onInputChange(value);
    }
  };

  render() {
    const {
      disabled,
      isMulti,
      labelI18n,
      infoI18n,
      errorI18nOptions,
      errorI18n,
      placeholder,
      options,
      defaultValues,
      noOptions,
      fullBorder,
      hideLabels
    } = this.props;

    return (
      <Box>
        {(infoI18n || labelI18n) && (
          <Flex alignItems="center" pb={2}>
            {labelI18n && <Label i18n={labelI18n} pb={0} />}
            {infoI18n && <Tooltip i18nKey={infoI18n} ml={1} />}
          </Flex>
        )}
        {errorI18n && (
          <Text color="text.danger">
            <Trans i18nKey={errorI18n} tOptions={errorI18nOptions} />
          </Text>
        )}
        <Select
          fullBorder={fullBorder}
          isMulti={isMulti}
          options={options}
          onChange={this.handleSelectChange}
          onInputChange={this.handleInputChange}
          hideLabels={hideLabels}
          value={
            defaultValues?.map(
              it => options.find(option => option.value === it)!
            ) ?? []
          }
          disabled={disabled}
          placeholder={placeholder}
          borderBottom={1}
          noOptions={noOptions}
        />
      </Box>
    );
  }
}
