import * as type from "./constants";

const initialState = {
  unreadMessages: 0
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case type.SET_UNREAD_MESSAGES: {
      return {
        ...state,
        unreadMessages: action.count
      };
    }
    case type.INCREMENT_UNREAD_MESSAGES: {
      return {
        ...state,
        unreadMessages: state.unreadMessages + 1
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
