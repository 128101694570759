import React from "react";
import Svg, { SvgProps } from "primitives/Svg";

const SvgAboutGraph = ({ width, height, size, color }: SvgProps) => (
  <Svg
    width={size || width}
    height={size || height}
    viewBox="0 0 392 392"
    color={color}>
    <path d="M313 210.5a147.496 147.496 0 01-116.101 144.119A147.5 147.5 0 11261.95 98.904L165.5 210.5H313z" />
    <mask id="AboutGraph_svg__a">
      <path d="M295.35 86.942a147.5 147.5 0 0147.599 102.366l-147.366 6.275L295.35 86.942z" />
    </mask>
    <path
      d="M295.35 86.942a147.5 147.5 0 0147.599 102.366l-147.366 6.275L295.35 86.942z"
      stroke="#fff"
      strokeWidth={14}
      mask="url(#AboutGraph_svg__a)"
    />
  </Svg>
);

SvgAboutGraph.defaultProps = {
  width: 24,
  height: 24
};
export default SvgAboutGraph;
