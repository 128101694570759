import React from "react";
import { getLocationLabel } from "./utils";
import { Text, TextProps } from "rebass";
import { LocationViewPopup } from "app/shared";

interface Props extends TextProps {
  city?: string;
  country?: string;
  latitude?: string;
  longitude?: string;
  hideIfNoLocation?: boolean;
  onClickOpenMap?: boolean;
}

export const LocationLabel: React.FC<Props> = ({
  city,
  country,
  latitude,
  longitude,
  hideIfNoLocation,
  onClickOpenMap,
  ...rest
}) => {
  const label = getLocationLabel(city, country, hideIfNoLocation);

  if (label?.length) {
    if (onClickOpenMap && latitude && longitude) {
      return (
        <LocationViewPopup
          center={{ lat: parseFloat(latitude), lng: parseFloat(longitude) }}
          trigger={
            <Text {...(rest as any)} sx={{ cursor: "pointer" }}>
              {label}
            </Text>
          }
          defaultZoom={city && country ? 13 : 8}
        />
      );
    } else {
      return <Text {...(rest as any)}>{label}</Text>;
    }
  }

  return null;
};

LocationLabel.defaultProps = {
  hideIfNoLocation: false,
  onClickOpenMap: false
};
