import { DocumentsView } from "app/documents";
import { PhotosListSearch } from "app/search/documents/PhotosListSearch";
import { createSearchUrl, getRaw } from "app/search/utils";
import { Button } from "app/shared";
import i18next from "i18next";
import { Document, DocumentThumbnail } from "models";
import React from "react";
import { Trans } from "react-i18next";
import { Box, Flex, Text } from "rebass";

interface Props {
  document: Document;
}

const DocumentMoreFromBlocks: React.FC<Props> = ({ document }) => {
  /**
   * Contruct filters used in "More from" blocks.
   */
  const getDocumentRelatedBlocks = (document: Document) => {
    const blocks = [];

    // The current document should not be returned
    const filterCurrentDocument = {
      field: "real_id",
      values: [`${document.id}`],
      type: "none"
    };

    // If there are labels
    if (document.userLabels.length) {
      const filters = [
        filterCurrentDocument,
        {
          field: "labels",
          values: document.userLabels.map(it => it.title),
          type: "any"
        }
      ];
      blocks.push({
        key: `${document.id}-labels`,
        searchUrl: createSearchUrl("document", ["document"], filters),
        label: i18next.t(
          "documents.documentDetails.documentMoreFromBlocks.similarPhotos"
        ),
        filters
      });
    }

    return blocks;
  };

  return (
    <Box px={[2, 2, 0]}>
      {getDocumentRelatedBlocks(document).map(it => (
        <PhotosListSearch<DocumentThumbnail>
          key={it.key}
          filters={it.filters}
          resultFields={[
            { uid: { raw: {} } },
            { owner: { raw: {} } },
            { city: { raw: {} } },
            { country: { raw: {} } },
            { taken_at: { raw: {} } },
            { title: { raw: {} } }
          ]}
          mapResults={(results: any[]) =>
            results.map(result => ({
              id: parseInt(getRaw(result, "real_id") ?? "0", 10),
              thumbnail: getRaw(result, "thumbnail") ?? "",
              width: parseInt(getRaw(result, "width") ?? "1", 10),
              height: parseInt(getRaw(result, "height") ?? "1", 10),
              city: getRaw(result, "city"),
              country: getRaw(result, "country"),
              takenAt: getRaw(result, "taken_at"),
              title: ((getRaw(
                result,
                "translations"
              ) as unknown) as string[])?.[0]?.split("=")?.[1],
              owner: {
                id: getRaw(result, "uid") ?? "",
                name: getRaw(result, "owner") ?? ""
              }
            }))
          }
          resultsPerPage={6}
          maxResults={6}
          view={documents => (
            <Box>
              <Flex
                alignItems="center"
                mb={[1, 1, 3]}
                pb={[1, 1, 0]}
                sx={{ borderBottom: 1, borderColor: "black.0" }}>
                <Flex width="100%">
                  <Text
                    fontSize={[3, 8]}
                    variant="grotText"
                    sx={{ textTransform: "uppercase" }}>
                    {it.label}
                  </Text>
                </Flex>
                <Flex justifyContent="flex-end" width="100%">
                  <Button
                    ml={3}
                    variant="link.button"
                    sx={{ borderColor: "black.0" }}
                    href={it.searchUrl}>
                    <Trans i18nKey="documents.documentDetails.documentMoreFromBlocks.viewAll" />
                  </Button>
                </Flex>
              </Flex>
              <DocumentsView documents={documents} />
            </Box>
          )}
          noResultsView={<></>}
        />
      ))}
    </Box>
  );
};

export default DocumentMoreFromBlocks;
