import React from "react";
import Svg, { SvgProps } from "primitives/Svg";

const SvgHash = ({ width, height, size, color }: SvgProps) => (
  <Svg
    width={size || width}
    height={size || height}
    viewBox="0 0 128 128"
    color={color}>
    <path d="M5.473 77.18h22.639l8.85-26.002H14.254l5.007-14.822h22.76L54.229.5h22.239L64.26 36.356h18.31L94.67.5h22.222l-12.119 35.856H127.5l-4.99 14.822H99.784l-8.78 26.002h22.742l-4.99 14.823h-22.76L73.997 127.5H51.776l11.998-35.497H45.326L33.24 127.5H11.002l12.085-35.497H.5L5.508 77.18h-.035zm63.274 0l8.78-26.002H59.185l-8.85 26.002h18.413z" />
  </Svg>
);

SvgHash.defaultProps = {
  width: 24,
  height: 24
};

export default SvgHash;
