import { DocumentView } from "app/documents";
import { Button, PageEmptyState, Toast, FormattedDate } from "app/shared";
import { Label } from "app/shared/label";
import { MonetaryText } from "app/shared/monetaryText";
import { EmptyHeader, TableLayout } from "app/shared/tableLayout";
import { AuthUserContext } from "authentication/session";
import environment from "configurations";
import { DocumentTransaction } from "marketplace";
import React, { Component } from "react";
import { Trans } from "react-i18next";
import { Flex } from "rebass";
import { CardPaymentPopup } from "./CardPaymentPopup";
import {
  getTransactionItemLink,
  retryTransaction,
  getTransactionItemReceipt,
  getTransactionItemInvoice
} from "./services";

interface Props {
  items: DocumentTransaction[];
  refresh?: () => void;
}

interface State {
  disabled: boolean;
}

export class DocumentTransactionsViewer extends Component<Props, State> {
  state = {
    disabled: false
  };

  downloadTransaction(transactionItemId: number) {
    this.setState({ disabled: true }, () => {
      getTransactionItemLink(transactionItemId)
        .then(signedUrl => {
          this.setState({ disabled: false }, () => {
            window.open(signedUrl.url);
          });
        })
        .catch(error => {
          Toast.apiError(error);
          this.setState({ disabled: false });
        });
    });
  }

  retryTransaction(transactionId: number, cardToken: string) {
    const { refresh } = this.props;

    this.setState({ disabled: true }, () => {
      retryTransaction(transactionId, environment.paymentProvider, cardToken)
        .then(() =>
          this.setState({ disabled: false }, () => refresh && refresh())
        )
        .catch(error => {
          Toast.apiError(error);
          this.setState({ disabled: false });
        });
    });
  }

  licenseTransaction(transactionItemId: number) {
    window.open(
      `${environment.url}/receipts/licenses/${transactionItemId}`,
      "license",
      "scrollbars=yes,width=1024,height=768"
    );
  }

  receiptTransaction(transactionItemId: number) {
    this.setState({ disabled: true }, () => {
      getTransactionItemReceipt(transactionItemId)
        .then(signedUrl => {
          this.setState({ disabled: false }, () => {
            window.open(signedUrl.url);
          });
        })
        .catch(error => {
          Toast.apiError(error);
          this.setState({ disabled: false });
        });
    });
  }

  invoiceTransaction(transactionItemId: number) {
    this.setState({ disabled: true }, () => {
      getTransactionItemInvoice(transactionItemId)
        .then(signedUrl => {
          this.setState({ disabled: false }, () => {
            window.open(signedUrl.url);
          });
        })
        .catch(error => {
          Toast.error({
            title: { key: "documents.transactionItemsViewer.noInvoice" }
          });
          this.setState({ disabled: false });
        });
    });
  }

  render() {
    const { items } = this.props;
    const { disabled } = this.state;

    if (items.length === 0) {
      return (
        <PageEmptyState
          loading={disabled}
          header={<Trans i18nKey="documents.transactionItemsViewer.noItems" />}
        />
      );
    } else {
      return (
        <>
          <TableLayout
            width="100%"
            columns={4}
            columnSizeArray={["20%", "15%", "15%", "50%"]}
            py={[1, 2, 3]}
            mb={[1, 2, 3]}
            tableNodes={[
              <Label
                key="mediaHeader"
                i18n="documents.transactionItemsViewer.mediaHeader"
                fontSize={1}
                sx={{ borderBottom: 1, borderColor: "black.0" }}
              />,
              <Label
                key="dateHeader"
                i18n="documents.transactionItemsViewer.dateHeader"
                fontSize={1}
                sx={{ borderBottom: 1, borderColor: "black.0" }}
              />,
              <Label
                key="priceHeader"
                i18n="documents.transactionItemsViewer.priceHeader"
                fontSize={1}
                sx={{ borderBottom: 1, borderColor: "black.0" }}
              />,
              <EmptyHeader
                key="emptyHeader"
                sx={{ borderBottom: 1, borderColor: "black.0" }}
              />,
              ...items.flatMap((item: DocumentTransaction) => [
                <DocumentView
                  key={`${item.transactionItemId}-view`}
                  document={{
                    id: item.documentId,
                    thumbnail: item.thumbnail
                  }}
                  showInfoBottom={false}
                  forceShowOnHover={false}
                  customPb={0}
                  width="100px"
                  hideLoading={true}
                />,
                <FormattedDate
                  key={`${item.transactionItemId}-date`}
                  format="number"
                  date={item.createdAt}
                />,
                <MonetaryText
                  key={`${item.transactionItemId}-monetary`}
                  price={{
                    value: item.price.value,
                    currency: item.price.currency,
                    precision: item.price.precision
                  }}
                />,
                <Flex
                  key={`${item.transactionItemId}-flex`}
                  justifyContent="flex-end">
                  {item.type === "payment" && item.status === "error" && (
                    <AuthUserContext.Consumer>
                      {({ authUser }) =>
                        authUser &&
                        authUser.uid === item.buyer && (
                          <CardPaymentPopup
                            trigger={
                              <Button disabled={disabled} mr={3}>
                                <Trans i18nKey="documents.transactionItemsViewer.retry" />
                              </Button>
                            }
                            disabled={disabled}
                            onComplete={cardToken =>
                              this.retryTransaction(
                                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                item.transactionId!,
                                cardToken
                              )
                            }
                          />
                        )
                      }
                    </AuthUserContext.Consumer>
                  )}
                  {((item.type === "payment" && item.status === "completed") ||
                    item.type === "payout") && (
                    <>
                      <Button
                        onClick={() =>
                          this.downloadTransaction(item.transactionItemId)
                        }
                        disabled={disabled}>
                        <Trans i18nKey="documents.transactionItemsViewer.download" />
                      </Button>
                      <Button
                        onClick={() =>
                          this.licenseTransaction(item.transactionItemId)
                        }
                        disabled={disabled}
                        sx={{ borderLeft: 0 }}>
                        <Trans i18nKey="documents.transactionItemsViewer.license" />
                      </Button>
                      {item.price.value > 0 && (
                        <>
                          {item.type === "payment" && (
                            <Button
                              onClick={() =>
                                this.receiptTransaction(item.transactionItemId)
                              }
                              disabled={disabled}
                              sx={{ borderLeft: 0 }}>
                              <Trans i18nKey="documents.transactionItemsViewer.receipt" />
                            </Button>
                          )}
                          <Button
                            onClick={() =>
                              this.invoiceTransaction(item.transactionItemId)
                            }
                            disabled={disabled}
                            sx={{ borderLeft: 0 }}>
                            <Trans i18nKey="documents.transactionItemsViewer.invoice" />
                          </Button>
                        </>
                      )}
                    </>
                  )}
                </Flex>
              ])
            ]}
          />
        </>
      );
    }
  }
}
