import { Input, Toast, Button } from "app/shared";
import { Firebase, withFirebase } from "authentication/firebase";
import { User, USER_VERIFIED_ROLE } from "models";
import firebase from "firebase";
import React, { Component, Dispatch } from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { compose } from "recompose";
import { Action } from "redux";
import * as actions from "../reducers/actions";
import AuthLayout from "./AuthLayout";
import RedirectButton from "./RedirectButton";
import AuthButton from "./AuthButton";
import { PageParser } from "app/pages";
import { Text, Box, Flex } from "rebass";
import { Trans } from "react-i18next";
import queryString from "query-string";
import i18next from "i18next";
import { IdToken } from "authentication/firebase/models";
import { SocialProviderButton } from "./SocialProviderButton";
import { FederatedUser } from "../models/social";
import { createUser } from "../services";
import { ISOLanguageCode } from "i18n/resources/supportedLanguages";
import { v4 as uuidv4 } from "uuid";
import { axiosNoAuthentication } from "network";

interface PropsExternal {
  backgroundImage?: string;
  backgroundImageAuthor?: string;
}

interface Props extends PropsExternal, RouteComponentProps {
  firebase: Firebase;
  setLoggedInUser: (user: User) => void;
  language: ISOLanguageCode;
}

interface State {
  email: string;
  password: string;
  redirect?: string;
  credentials?: firebase.auth.OAuthCredential;
}

const INITIAL_STATE: State = {
  email: "",
  password: "",
  credentials: undefined
};

class SignInBase extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      ...INITIAL_STATE,
      redirect: queryString
        .parse(props.location.search, { decode: false })
        ?.["redirect"]?.toString()
    };
  }

  createUserFromFirebase = (
    firebaseUser: firebase.User,
    roles: string[]
  ): User => ({
    id: firebaseUser.uid,
    status: roles.includes(USER_VERIFIED_ROLE) ? "verified" : "unverified",
    email: firebaseUser?.email || "",
    professionalName: firebaseUser?.displayName || "",
    picture: firebaseUser?.photoURL || "",
    fullName: "",
    username: "",
    country: "",
    city: "",
    userTypes: [],
    websites: [],
    publishedWorks: [],
    translations: [],
    roles,
    billing: undefined,
    skills: [],
    collaboration: [],
    social: []
  });

  private setFirebaseUser = async (redirect?: string) => {
    const { firebase, setLoggedInUser, history } = this.props;

    const firebaseUser: firebase.User = firebase.getCurrentUser();
    const firebaseUserToken: IdToken = await firebase.getIdToken();
    const user: User = this.createUserFromFirebase(
      firebaseUser,
      firebaseUserToken.roles
    );

    setLoggedInUser(user);
    this.setState({ ...INITIAL_STATE });

    // Present popup for user to verify account
    if (!user.roles.includes(USER_VERIFIED_ROLE)) {
      history.push(
        redirect ? decodeURIComponent(redirect) : "/?unverified=true"
      );
    } else {
      // Redirect user to location before login
      history.push(redirect ? decodeURIComponent(redirect) : "/");
    }
  };

  async handleOnSubmit() {
    const { email, password, credentials, redirect } = this.state;
    const { firebase } = this.props;

    try {
      await firebase.doSignInWithEmailAndPassword(email, password);
      if (credentials) {
        await firebase.linkAccountWithCredential(credentials);
      }
      this.setFirebaseUser(redirect);
    } catch (error) {
      Toast.apiError(error);
    }
  }

  handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState<never>({ [event.target.name]: event.target.value });
  };

  onSocialProviderPasswordRequired = (
    email: string,
    credentials?: firebase.auth.OAuthCredential
  ) => {
    this.setState({ email, credentials });
  };

  downloadFederatedUserPhoto = async (
    photoURL?: string
  ): Promise<File | undefined> => {
    if (photoURL && photoURL?.length > 0) {
      const response = await axiosNoAuthentication.get(photoURL, {
        responseType: "arraybuffer"
      });
      return new File([response.data], "profilePhoto.jpg", {
        type: response.headers["content-type"]
      });
    }
    return undefined;
  };

  onSocialProviderRegistrationRequired = async (
    federatedUser: FederatedUser
  ) => {
    const { language, firebase } = this.props;
    const { credentials } = this.state;
    const subscribeNewsletters = true;
    const photoURL = await this.downloadFederatedUserPhoto(
      federatedUser.photoURL
    );
    const randomPassword = uuidv4();

    createUser(
      federatedUser.email,
      randomPassword,
      federatedUser.displayName,
      { subscribeNewsletters, language },
      photoURL,
      federatedUser.uid
    )
      .then(async () => {
        const redirect = this.state.redirect ?? "/edit-my-info";
        await firebase.doSignInWithEmailAndPassword(
          federatedUser.email,
          randomPassword
        );
        if (credentials) {
          await firebase.linkAccountWithCredential(credentials);
        }
        this.setFirebaseUser(redirect);
        Toast.success({
          title: {
            key: "account.authentication.register.confirmationToast"
          }
        });
      })
      .catch(error => {
        Toast.apiError(error);
      });
  };

  onSocialProviderSuccess = () => {
    this.setFirebaseUser(this.state.redirect);
  };

  render() {
    const { backgroundImage, backgroundImageAuthor } = this.props;
    const { email, password, redirect } = this.state;
    const isInvalid = password === "" || email === "";

    return (
      <AuthLayout
        backgroundImage={backgroundImage}
        backgroundImageAuthor={backgroundImageAuthor}
        backgroundImageAuthorPosition="top"
        headerI18n="account.authentication.signIn.login"
        mainSection={
          <Flex width="100%" flexDirection="column" sx={{ flexGrow: 1 }}>
            <Input
              name="email"
              type="email"
              labelI18n="account.authentication.signIn.email"
              labelFontSize={2}
              placeholder={i18next.t(
                "account.authentication.signIn.emailPlaceholder"
              )}
              onChange={this.handleOnChange}
              value={email}
              mb={2}
              onKeyUp={event => {
                if (!isInvalid && event.key === "Enter") {
                  this.handleOnSubmit();
                }
              }}
            />
            <Input
              name="password"
              type="password"
              labelI18n="account.authentication.signIn.password"
              labelFontSize={2}
              infoI18n="account.authentication.register.passwordInfo"
              placeholder={i18next.t(
                "account.authentication.signIn.passwordPlaceholder"
              )}
              onChange={this.handleOnChange}
              value={password}
              onKeyUp={event => {
                if (!isInvalid && event.key === "Enter") {
                  this.handleOnSubmit();
                }
              }}
              mb={2}
            />
            <Button
              mt={4}
              mb={4}
              href={`/recover-password${
                redirect ? `?redirect=${redirect}` : ""
              }`}>
              <Text fontSize={[0, 1]} variant="grotTextCaps">
                <Trans i18nKey="account.authentication.signIn.forgotPassword" />
              </Text>
            </Button>
            <AuthButton
              onClick={() => this.handleOnSubmit()}
              variant="blue"
              disabled={isInvalid}
              i18nKey="account.authentication.signIn.loginEmail"
              mb={7}
            />
            <Box>
              <Flex justifyContent="center" mb={3}>
                <Text variant="grotTextCaps">
                  <Trans i18nKey="account.authentication.signIn.loginWith" />
                </Text>
              </Flex>
              <Flex justifyContent="center">
                <Box>
                  <Button variant="transparent" pr={3}>
                    <SocialProviderButton
                      size={41}
                      variant="transparent"
                      provider="facebook"
                      icon="Facebook"
                      onPasswordRequired={this.onSocialProviderPasswordRequired}
                      onRegistrationRequired={
                        this.onSocialProviderRegistrationRequired
                      }
                      onSuccess={this.onSocialProviderSuccess}
                    />
                  </Button>
                  <Button variant="transparent" pr={3}>
                    <SocialProviderButton
                      variant="transparent"
                      provider="google"
                      icon="Google"
                      onPasswordRequired={this.onSocialProviderPasswordRequired}
                      onRegistrationRequired={
                        this.onSocialProviderRegistrationRequired
                      }
                      onSuccess={this.onSocialProviderSuccess}
                    />
                  </Button>
                </Box>
              </Flex>
            </Box>
            <Box sx={{ flexGrow: 1 }} />
            <RedirectButton
              i18nKey="account.authentication.signIn.register"
              href={`/register${redirect ? `?redirect=${redirect}` : ""}`}
              variant="link.button.brand"
              headerI18nKey="account.authentication.signIn.registerHeader"
            />
          </Flex>
        }
      />
    );
  }
}

const mapStateToProps = (state: any) => ({
  language: state.language.code
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  setLoggedInUser: async (user: User) => dispatch(actions.setLoggedInUser(user))
});

const SignInCompose = compose<Props, PropsExternal>(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withFirebase
)(SignInBase);

/**
 * This components overrides <Random /> component and uses props.values to calculate a random image.
 * Note that only <Random /> component is being implemented, so other components defined in the backoffice will be ignored.
 * We are using a container to wrap <SignIn /> to prevent rendering <PageParser /> again that would result in another random image.
 */
export const SignIn = () => (
  <PageParser
    pageId={3}
    components={{
      Random: (props: any) => {
        const randomValue =
          props.values?.[Math.floor(Math.random() * props.values?.length)] ??
          undefined;

        return (
          <SignInCompose
            backgroundImage={randomValue?.photo}
            backgroundImageAuthor={randomValue?.author}
          />
        );
      }
    }}
    placeholder={<SignInCompose />}
  />
);
