import { Button, Image } from "app/shared";
import React from "react";
import { ImageProps } from "rebass";
import logo from "./static/Logo.svg";

export const SiteImageButton: React.FC<ImageProps> = ({ ...rest }) => (
  <Button href="/" variant="link.nav">
    <Image src={logo} alt="site-logo" {...(rest as any)} />
  </Button>
);
