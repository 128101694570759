import React from "react";
import Svg, { SvgProps } from "primitives/Svg";

const SvgGoogle = ({ width, height, size, color }: SvgProps) => (
  <Svg
    viewBox="0 0 512 512"
    width={size || width}
    height={size || height}
    color={color}>
    <path d="M491.5 0h-471C9.2 0 0 9.2 0 20.5v471C0 502.8 9.2 512 20.5 512h471c11.3 0 20.5-9.2 20.5-20.5v-471C512 9.2 502.8 0 491.5 0zM362.9 374.8c-26 23.9-61.4 38-103.7 38-61.2 0-114.2-35.1-140-86.3-11-21.9-16.7-46-16.7-70.5 0-25.3 6.1-49.3 16.7-70.5 25.8-51.3 78.8-86.4 140-86.4 42.2 0 77.7 15.6 104.9 40.8l-44.9 45c-16.3-15.6-36.9-23.4-59.9-23.4-40.8 0-75.4 27.6-87.7 64.6-3.1 9.4-4.9 19.5-4.9 29.8 0 10.4 1.8 20.4 4.9 29.8 12.4 37.1 46.9 64.6 87.7 64.6 21.1 0 39-5.6 53.1-15 16.6-11.1 27.6-27.7 31.3-47.4h-84.4v-60.7h147.6c1.9 10.3 2.8 21 2.8 32.1-.1 48-17.2 88.2-46.8 115.5z" />
  </Svg>
);

SvgGoogle.defaultProps = {
  width: 24,
  height: 24
};
export default SvgGoogle;
