import React from "react";
import Svg, { SvgProps } from "primitives/Svg";

const SvgCart2 = ({ width, height, size, color }: SvgProps) => (
  <Svg
    width={size || width}
    height={size || height}
    viewBox="0 0 21 22"
    color={color}>
    <circle cx={6.632} cy={19.5} r={1.5} stroke="#fff" strokeWidth={1.421} />
    <circle cx={16.58} cy={19.5} r={1.5} stroke="#fff" strokeWidth={1.421} />
    <mask
      id="Cart2_svg__a"
      maskUnits="userSpaceOnUse"
      x={0.105}
      y={-0.289}
      width={21}
      height={17}>
      <path d="M.105-.289h21v17h-21z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.105.71H4.79l.884 3.317h14.221l-2.947 7.737H7.406L6.41 15.08H18.79v1.105H4.42l1.6-5.331-2.41-9.038H1.105V.711zm14.79 9.948L18 5.133H5.97l1.473 5.525h8.453z"
      />
    </mask>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.105.71H4.79l.884 3.317h14.221l-2.947 7.737H7.406L6.41 15.08H18.79v1.105H4.42l1.6-5.331-2.41-9.038H1.105V.711zm14.79 9.948L18 5.133H5.97l1.473 5.525h8.453z"
    />
    <path
      d="M1.105.71v-.3h-.3v.3h.3zm3.685 0l.29-.076L5.02.41h-.23v.3zm.884 3.317l-.29.078.06.222h.23v-.3zm14.22 0l.281.107.155-.407h-.435v.3zm-2.946 7.737v.3h.206l.074-.193-.28-.107zm-1.474 0l-.28-.107-.156.407h.436v-.3zm0 0l.28.106.156-.406h-.436v.3zm-7.737 0v-.3h-.391l.101.377.29-.077zm0 0v.3h.391l-.101-.378-.29.078zm-.331 0v-.3h-.223l-.065.213.288.087zM6.41 15.08l-.288-.086-.115.386h.403v-.3zm12.379 0h.3v-.3h-.3v.3zm0 1.105v.3h.3v-.3h-.3zm-12.71 0v-.3h-.224l-.064.214.287.086zm0 0v.3h.222l.064-.213-.287-.086zm-1.659 0l-.287-.086-.116.386h.403v-.3zm1.6-5.331l.287.086.025-.081-.022-.082-.29.077zM3.61 1.816l.29-.077-.06-.223h-.23v.3zm-2.506 0h-.3v.3h.3v-.3zm14.79 8.842v.3h.207l.073-.193-.28-.107zM18 5.133l.28.106.155-.406H18v.3zm-12.031 0v-.3h-.39l.1.377.29-.077zm1.473 5.525l-.29.078.06.222h.23v-.3zM3.316.411h-2.21v.6h2.21v-.6zm1.105 0H3.316v.6H4.42v-.6zm.369 0H4.42v.6h.369v-.6zM5.964 3.95L5.079.634 4.5.788l.885 3.317.58-.155zm12.457-.223H5.674v.6h12.747v-.6zm0 .6h.369v-.6h-.369v.6zm.369 0h1.105v-.6H18.79v.6zm.825-.406l-2.948 7.736.56.214 2.948-7.737-.56-.213zm-2.667 7.543h-1.474v.6h1.474v-.6zm-1.194.407l-.56-.215v.001l.56.214zm-8.017.192h7.737v-.6H7.737v.6zm.29-.377l-.58.155.58-.155zm-.621.378h.331v-.6h-.331v.6zm-.708 3.102l.995-3.316-.575-.173-.995 3.317.575.172zm12.092-.386H6.41v.6h12.38v-.6zm.3 1.405V15.08h-.6v1.105h.6zm-13.01.3h12.71v-.6H6.08v.6zm.286-.213l-.574-.173.574.173zm-1.945.213H6.08v-.6H4.421v.6zm1.312-5.717l-1.6 5.331.576.173 1.6-5.332-.576-.172zM3.321 1.893l2.41 9.038.58-.154L3.9 1.739l-.58.154zm-2.216.223h2.506v-.6H1.105v.6zM.805.711v1.105h.6V.711h-.6zm15.37 10.054l2.105-5.526-.56-.213-2.105 5.525.56.214zM5.97 5.433H18v-.6H5.97v.6zm1.763 5.148L6.26 5.055l-.58.155 1.473 5.526.58-.155zm8.163-.223H7.442v.6h8.453v-.6z"
      mask="url(#Cart2_svg__a)"
    />
  </Svg>
);

SvgCart2.defaultProps = {
  width: 24,
  height: 24
};
export default SvgCart2;
