import React from "react";
import Svg, { SvgProps } from "primitives/Svg";

const SvgCart = ({ width, height, size, color }: SvgProps) => (
  <Svg
    width={size || width}
    height={size || height}
    viewBox="0 0 24 24"
    color={color}>
    <circle cx={8} cy={20} r={1.5} stroke="#000" />
    <circle cx={17} cy={20} r={1.5} stroke="#000" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 3H3v1h2.267l.8 3h12.219l-1.905 5H8.733L7.667 8H6.333l1.114 4.177L6 17h13v-1H7.8l.9-3H17.333L20 6H7.133l-.8-3H5z"
    />
  </Svg>
);

SvgCart.defaultProps = {
  width: 24,
  height: 24
};
export default SvgCart;
