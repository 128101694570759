import React, { useState } from "react";
import { Map, Label, Tooltip } from "app/shared";
import { Trans } from "react-i18next";
import { Box, BoxProps, Flex, Text } from "rebass";
import { GoogleSearchBox } from "./GoogleSearchBox";
import { GooglePlace } from "./models";

interface Props extends BoxProps {
  name: string;
  onPlacesChange: (name: string, places: GooglePlace[]) => void;
  value?: string;
  labelI18n?: string;
  errorI18n?: string;
  placeholder?: string;
  disabled?: boolean;
  variant?: string;
  infoI18n?: string;
  labelFontSize?: string | number | string[] | number[];
}

export const InputPlaceSearch: React.FC<Props> = ({
  name,
  value,
  labelI18n,
  errorI18n,
  placeholder,
  onPlacesChange,
  disabled,
  variant,
  infoI18n,
  labelFontSize,
  ...rest
}) => {
  const [googleMapsApi, setGoogleMapsApi] = useState<any>(undefined);

  return (
    <Box {...(rest as any)}>
      {(infoI18n || labelI18n) && (
        <Flex alignItems="center" pb={1}>
          {labelI18n && (
            <Label i18n={labelI18n} fontSize={labelFontSize ?? [1, 5]} pb={0} />
          )}
          {infoI18n && <Tooltip i18nKey={infoI18n} ml={1} />}
        </Flex>
      )}
      {errorI18n && (
        <Text color="text.danger" pt={1}>
          <Trans i18nKey={errorI18n} />
        </Text>
      )}
      <GoogleSearchBox
        maps={googleMapsApi}
        placeholder={placeholder}
        value={value}
        disabled={disabled}
        variant={variant}
        onChange={places => onPlacesChange(name, places)}
      />
      {/* Hide map */}
      <Box width={0} height={0} sx={{ visibility: "hidden" }}>
        <Map onGoogleApiLoaded={maps => setGoogleMapsApi(maps)} />
      </Box>
    </Box>
  );
};
