import React, { ReactNode } from "react";
import { Box, Flex, BoxProps } from "rebass";

interface Props extends BoxProps {
  children: ReactNode;
}

const Steps: React.FC<Props> = ({ children, ...rest }) => (
  <Box py={[1, 2, 3]} width="100%" {...(rest as any)}>
    <Flex
      flexWrap="wrap"
      sx={{
        "& > :first-of-type": {
          borderLeft: 1
        }
      }}>
      {children}
    </Flex>
  </Box>
);

export default Steps;
