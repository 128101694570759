/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-function */
import { Checkbox, CollapsableButton } from "app/shared";
import { getCommonTranslation } from "app/shared/translations/utils";
import React, { useState } from "react";
import { Trans } from "react-i18next";
import { Box, Flex, Text } from "rebass";
import ExpandFacetButton from "../buttons/ExpandFacetButton";
import { getFilterValueDisplay } from "../helpers";

interface Props {
  label: string;
  onMoreClick: () => void;
  onRemove: (value: string) => void;
  onSelect: (value: string) => void;
  options: any[];
  showMore: boolean;
}

/**
 * This component renders the available options in a MultiCheckboxFacet component.
 *
 * Notes:
 * - Make sure to add all the available options in the translations files:
 * search.filters.views.multiCheckboxFacetView.<optionName>
 */
const MultiCheckboxFacetView: React.FC<Props> = ({
  label,
  onMoreClick,
  onSelect,
  onRemove,
  options,
  showMore
}) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  // Search framework is returning non string values when an element is an empty string or is nullable, e.g. `{count: 3, value: {}}`.
  // As this view can only present string values, other options are being filtered out.
  // If other views also only support string views, this should be moved to a common place.
  const validOptions = options.filter(
    option =>
      !(
        typeof option.value === "object" &&
        Object.keys(option.value).length === 0
      )
  );

  return (
    <Box
      sx={{
        borderRight: 1,
        borderLeft: 1,
        borderBottom: 1,
        borderColor: "black.0"
      }}>
      <Box px={[1, 1, 1, 1, 3]} py={2}>
        <CollapsableButton
          fontSize={[2, 4, 5, 6, 6]}
          mb={!isCollapsed ? 2 : 0}
          sx={{ fontFamily: "heading", textTransform: "uppercase" }}
          onClick={() => setIsCollapsed(!isCollapsed)}
          collapsed={isCollapsed}>
          {label}
        </CollapsableButton>

        {!isCollapsed && (
          <Box>
            {validOptions.length < 1 ? (
              <Trans i18nKey="search.filters.views.noMatchingOptions" />
            ) : (
              validOptions.map(option => {
                const optionChecked = option.selected;
                const optionValue = getFilterValueDisplay(option.value);

                return (
                  <Flex
                    key={`wrapper_checkbox_${optionValue}`}
                    justifyContent="space-between"
                    alignItems="center"
                    my={2}>
                    <Checkbox
                      size={20}
                      key={`filter_checkbox_${optionValue}`}
                      name={optionValue}
                      checked={optionChecked}
                      onChange={() =>
                        optionChecked
                          ? onRemove(option.value)
                          : onSelect(option.value)
                      }>
                      {getCommonTranslation(optionValue)}
                    </Checkbox>
                    <Text
                      fontSize={[1, 2, 3, 4, 4]}
                      paddingRight={[1, 1, 1, 1, 2]}
                      opacity={optionChecked ? undefined : 0.65}>
                      {option.count ?? 0}
                    </Text>
                  </Flex>
                );
              })
            )}
          </Box>
        )}
      </Box>
      {showMore && !isCollapsed && (
        <ExpandFacetButton
          onClick={onMoreClick}
          i18n="search.filters.views.more"
          label={label}
        />
      )}
    </Box>
  );
};

export default MultiCheckboxFacetView;
