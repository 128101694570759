/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from "react";
import { Box, Flex, FlexProps } from "rebass";
import { UserRelationInfo } from "./models";
import { FollowButton } from "./FollowButton";
import { NewMessageButton } from "app/messages/NewMessageButton";
import { User } from "models";
import { DonationPopUp } from "app/donations/DonationPopup";
import { AuthUserContext } from "authentication/session";
import { checkSameUser } from "authentication/utils";
import queryString from "query-string";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { compose } from "recompose";

interface PropsExternal extends FlexProps {
  user: User;
  info?: UserRelationInfo;
  variant?: "blue" | "white";
  followBorderLeft?: number;
  followBorderRight?: number;
  followBorderBottom?: number;
  chatText?: string;
  mode?: "full" | "half";
  donationIcon?: boolean;
  borderBottom?: number;
  followWidth?: string | string[];
  textButtonFontSize?: number | number[];
  hideFollowButton?: boolean;
}

interface Props extends PropsExternal, RouteComponentProps {}

export const FollowMessageButtonBase: React.FC<Props> = ({
  user,
  info,
  variant,
  followBorderLeft,
  followBorderRight,
  followBorderBottom,
  chatText,
  location,
  mode,
  donationIcon,
  borderBottom,
  followWidth,
  textButtonFontSize,
  hideFollowButton,
  ...rest
}) => {
  const query = queryString
    .parse(location.search, { decode: false })
    ?.["donate"]?.toString();

  const [open] = useState<boolean>(query ? true : false);

  return (
    <>
      {mode === "full" ? (
        <>
          <Flex width="100%" {...(rest as any)}>
            <NewMessageButton
              buttonText={chatText}
              width={hideFollowButton ? "100%" : chatText ? "50%" : ""}
              user={user}
              backgroundColor={variant === "blue" ? "blue" : undefined}
              color={variant === "blue" ? "white" : undefined}
              sx={{
                borderRight: 0,
                borderLeft: followBorderLeft,
                borderBottom: borderBottom
              }}
            />
            <AuthUserContext.Consumer>
              {({ authUser }) => {
                const disabled =
                  !authUser || (authUser && checkSameUser(user.id, authUser)); // disable if not logged in or if own user
                return (
                  <DonationPopUp
                    open={open}
                    disableButton={disabled}
                    icon={donationIcon}
                    width={
                      hideFollowButton ? "100%" : donationIcon ? "" : "50%"
                    }
                    uid={user.id}
                    backgroundColor={variant === "blue" ? "blue" : undefined}
                    color={variant === "blue" ? "white" : undefined}
                    sx={{
                      borderRight: 0,
                      borderBottom: borderBottom,
                      borderColor: variant === "blue" ? "white" : "black"
                    }}
                  />
                );
              }}
            </AuthUserContext.Consumer>
            {!hideFollowButton && (
              <Box
                width={followWidth ?? "50%"}
                sx={{
                  borderBottom: borderBottom,
                  borderRight: followBorderRight
                }}>
                <FollowButton
                  width="100%"
                  uid={user.id}
                  info={info}
                  fontSize={textButtonFontSize}
                  backgroundColor={variant === "blue" ? "blue" : undefined}
                  color={variant === "blue" ? "white" : undefined}
                  borderBottom={followBorderBottom}
                  borderRight={0}
                />
              </Box>
            )}
          </Flex>
        </>
      ) : (
        <>
          <Flex width="100%" flexWrap="wrap">
            <Flex width="50%" alignItems="flex-end" pr={2}>
              <NewMessageButton
                buttonText={chatText}
                width={chatText ? "100%" : ""}
                user={user}
                backgroundColor={variant === "blue" ? "blue" : undefined}
                color={variant === "blue" ? "white" : undefined}
              />
            </Flex>
            <Flex width="50%" alignItems="flex-end" pl={2}>
              <FollowButton
                width="100%"
                uid={user.id}
                info={info}
                backgroundColor={variant === "blue" ? "blue" : undefined}
                color={variant === "blue" ? "white" : undefined}
              />
            </Flex>
            <Flex
              width={donationIcon ? "" : "100%"}
              alignItems="flex-start"
              pt={3}>
              <AuthUserContext.Consumer>
                {({ authUser }) => {
                  const disabled =
                    !authUser || (authUser && checkSameUser(user.id, authUser)); // disable if not logged in or if own user
                  return (
                    <DonationPopUp
                      open={open}
                      width="100%"
                      disableButton={disabled}
                      uid={user.id}
                      backgroundColor={variant === "blue" ? "blue" : undefined}
                      color={variant === "blue" ? "white" : undefined}
                    />
                  );
                }}
              </AuthUserContext.Consumer>
            </Flex>
          </Flex>
        </>
      )}
    </>
  );
};

FollowMessageButtonBase.defaultProps = {
  variant: "white",
  followBorderLeft: 1,
  followBorderRight: 1,
  mode: "full"
};

export const FollowMessageButton = compose<Props, PropsExternal>(withRouter)(
  FollowMessageButtonBase
);
