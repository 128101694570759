import { PageEmptyState, PageHeader } from "app/shared";
import { Firebase } from "authentication/firebase";
import { AuthUserContext, withAuthorization } from "authentication/session";
import i18next from "i18next";
import { ContentArea } from "layout";
import { Subscription } from "messaging";
import React, { Component } from "react";
import { Trans } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Flex } from "rebass";
import { compose } from "recompose";
import { Chat, Message } from "../../messaging/MessageService";
import { ChatBody } from "./ChatBody";
import { ChatsList } from "./ChatsList";
import { NewMessageButton } from "./NewMessageButton";
import "./scrollbar.css";

interface Props extends RouteComponentProps {
  firebase: Firebase;
}

interface State {
  chats: Chat[];
  messages: Message[];
  disabled: boolean;
  chatSelected: number;
}

class MessagesBase extends Component<Props, State> {
  subscription?: Subscription;

  constructor(props: Props) {
    super(props);

    this.state = {
      chats: [],
      messages: [],
      disabled: false,
      chatSelected: -1
    };
  }

  async componentDidMount() {
    this.getChats();
  }

  async componentWillUnmount() {
    const { firebase } = this.props;

    await firebase.messageService.disconnect();

    this.subscription?.unsubscribe();
  }

  getChats = () => {
    const { firebase } = this.props;
    this.subscription = firebase.messageService
      .getChats()
      .subscribe((chats: Array<Chat>) => {
        this.setState({ chats }, async () => {
          if (this.state.chatSelected === -1) {
            await this.setChatSelected(0);
          }
        });
      });
  };

  setChatSelected = async (chatSelected: number) => {
    if (
      chatSelected !== this.state.chatSelected &&
      this.state.chats.length > 0
    ) {
      const { firebase } = this.props;
      this.setState({ chatSelected });

      await firebase.messageService.setChatRead(
        this.state.chats[chatSelected].id
      );
    }
  };

  render() {
    const { chats, chatSelected } = this.state;
    const { firebase } = this.props;

    return (
      <AuthUserContext.Consumer>
        {({ authUser }) => (
          <ContentArea>
            <PageHeader
              variant="2"
              title={i18next.t("messages.pageTitle")}
              mb={4}
            />
            <NewMessageButton buttonText="messages.compose" />
            <Flex marginTop={4}>
              {chats.length !== 0 ? (
                <>
                  <ChatsList
                    chats={chats}
                    chatSelected={chatSelected}
                    setChatSelected={this.setChatSelected}
                  />
                  <ChatBody
                    chats={chats}
                    messageService={firebase.messageService}
                    authUser={authUser}
                    chatSelected={chatSelected}
                    deselectChat={() =>
                      this.setState({
                        chatSelected: -1
                      })
                    }
                  />
                </>
              ) : (
                <PageEmptyState
                  width="100%"
                  header={<Trans i18nKey="messages.noChats" />}
                  description={
                    <NewMessageButton buttonText="messages.newMessageButton.newMsg" />
                  }
                />
              )}
            </Flex>
          </ContentArea>
        )}
      </AuthUserContext.Consumer>
    );
  }
}

export const Messages = compose<Props, any>(
  withRouter,
  withAuthorization()
)(MessagesBase);
