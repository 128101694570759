/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Button,
  GridLabel,
  Icon,
  Input,
  Popup,
  ShareButton,
  Tabs,
  Toast
} from "app/shared";
import i18next from "i18next";
import { Donation, DonationFrequency } from "./models";
import { User } from "models";
import React, { Component, SyntheticEvent } from "react";
import { Trans } from "react-i18next";
import { Box, BoxProps, Flex, Text } from "rebass";
import { createDonation } from "./services";
import { UserPicture } from "app/account";
import { getUserProfile } from "app/account/services";
import { Currency, Price } from "marketplace";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  Elements,
  injectStripe,
  StripeProvider
} from "react-stripe-elements";
import environment from "configurations";

interface PropsExternal extends BoxProps {
  uid?: string;
  donation?: Donation;
  mobileView?: boolean;
  open?: boolean;
  hideTrigger?: boolean;
  onClose?: () => void;
  onOpen?: () => void;
  isLogged?: boolean;
  disableButton?: boolean;
  icon?: boolean;
  onlyTrigger?: boolean;
  onClickTrigger?: () => void;
}

interface Props extends PropsExternal {
  stripe: any;
}

interface State {
  disabled: boolean;
  user?: User;
  frequency?: DonationFrequency;
  price: Price;
  otherPrice: boolean;
}

const INITIAL_STATE: State = {
  disabled: false,
  frequency: undefined,
  price: {
    value: 0,
    precision: 0,
    currency: "EUR"
  },
  otherPrice: false
};

class DonationPopupForm extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      ...INITIAL_STATE
    };
  }

  handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState<any>({ [event.target.name]: event.target.value });
  };

  handleOnChangeOther = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState<any>({ [event.target.name]: event.target.value });
    this.setState({
      price: {
        value: parseInt(event.target.value),
        precision: 1,
        currency: "EUR"
      }
    });
  };

  componentDidMount = () => {
    const { uid, onOpen } = this.props;
    if (uid && this.state.user?.id !== uid) {
      getUserProfile(uid).then(user => this.setState({ user }));
    }
    onOpen?.();
  };

  async payDonate(close: () => void) {
    const { stripe, uid, isLogged } = this.props;

    if (!uid && isLogged === false) {
      window.location.href = "/register";
    } else {
      const { frequency, price } = this.state;

      this.setState({ disabled: true }, async () => {
        const { token, error } = await stripe.createToken();

        if (token && token.id) {
          this.setState({ disabled: true }, () => {
            createDonation(
              frequency as DonationFrequency,
              price,
              environment.paymentProvider,
              token.id,
              uid
            )
              .then(() => {
                close();
                Toast.success({
                  title: {
                    key: "donations.successDonate"
                  }
                });
              })
              .catch(error => {
                Toast.apiError(error);
                this.setState({ disabled: false });
              });
          });
        } else {
          Toast.error({ title: error.message });
          this.setState({ disabled: false });
        }
      });
    }
  }

  limitPrice = (e: SyntheticEvent<HTMLInputElement>) => {
    e.currentTarget.value = Math.max(0, parseInt(e.currentTarget.value))
      .toString()
      .slice(0, 6);
  };

  render() {
    const {
      disableButton,
      mobileView,
      open,
      hideTrigger,
      onClose,
      onOpen,
      isLogged,
      uid,
      icon,
      onlyTrigger,
      onClickTrigger,
      ...rest
    } = this.props;
    const { disabled, user, price, otherPrice, frequency } = this.state;
    const trigger = hideTrigger ? (
      undefined
    ) : (
      <Button
        width="fit-content"
        {...(rest as any)}
        onClick={() => onClickTrigger?.()}
        disabled={
          disableButton || environment.featureFlags.donationButtonDisabled
        }>
        {!icon ? (
          <Text fontSize={[0, 3]} variant="grotText">
            <Trans
              i18nKey={
                uid ? "donations.buttonTitle" : "donations.buttonTitleFF"
              }
            />
          </Text>
        ) : (
          <Flex
            width="100%"
            height="100%"
            justifyContent="center"
            alignItems="center">
            <Icon name="Donate" size={[12, 22]} />
          </Flex>
        )}
      </Button>
    );

    const prices = [
      {
        label: "5€",
        value: 5,
        currency: "EUR",
        precision: 2
      },
      {
        label: "10€",
        value: 10,
        currency: "EUR",
        precision: 2
      },
      {
        label: "20€",
        value: 20,
        currency: "EUR",
        precision: 2
      },
      {
        label: "8€",
        value: 8,
        currency: "EUR",
        precision: 2
      },
      {
        label: "15€",
        value: 15,
        currency: "EUR",
        precision: 2
      },
      {
        label: "25€",
        value: 25,
        currency: "EUR",
        precision: 2
      },
      {
        label: i18next.t("donations.other"),
        value: 0,
        currency: "EUR",
        precision: 2
      }
    ];
    const userVerified = user?.status === "verified";

    return (
      <>
        {onlyTrigger ? (
          trigger
        ) : (
          <Popup
            open={open}
            trigger={trigger}
            hideCancelButtons={true}
            heading={
              user
                ? i18next.t("donations.popUpHeader") +
                  " " +
                  user?.professionalName?.split(" ")?.[0]
                : i18next.t("donations.popUpHeaderFF")
            }
            submit={close => this.payDonate(close)}
            submitText={
              isLogged === false
                ? i18next.t("donations.noAccount")
                : i18next.t("donations.submitText")
            }
            submitDisabled={
              disabled || price.value === 0 || frequency === undefined
            }
            cancelDisabled={disabled}
            contentPadding={0}
            onClose={() => this.setState({ ...INITIAL_STATE })}>
            <>
              {user && (
                <>
                  {uid && (
                    <Flex
                      sx={{
                        borderBottom: userVerified ? 1 : 0,
                        borderColor: "black"
                      }}
                      mb={userVerified ? 2 : 0}>
                      <UserPicture
                        minWidth={["69px", "80px", "180px"]}
                        picture={user.picture}
                        height={["69px", "80px", "180px"]}
                      />
                      <Flex flexDirection="column" width="100%">
                        <Flex>
                          <Box
                            py={3}
                            width="100%"
                            sx={{ borderBottom: "1px solid black" }}>
                            <Flex
                              alignItems="center"
                              justifyContent="space-between">
                              <Flex>
                                <Text
                                  variant="grotText"
                                  pl={2}
                                  sx={{ textTransform: "uppercase" }}>
                                  {user.professionalName}
                                </Text>
                              </Flex>
                              <Flex>
                                {userVerified && (
                                  <ShareButton
                                    mr={2}
                                    width="100%"
                                    bg="blue"
                                    color="white"
                                    textWithIcon={true}
                                    noBorder={true}
                                    title={
                                      i18next.t("donations.popUpHeader") +
                                      " " +
                                      user?.professionalName?.split(" ")?.[0]
                                    }
                                    path={`/users/${uid}?donate=true`}
                                    photo={user.picture}
                                  />
                                )}
                              </Flex>
                            </Flex>
                          </Box>
                        </Flex>
                        <Flex
                          flexDirection="column"
                          sx={{ wordBreak: "break-all" }}>
                          {userVerified ? (
                            <>
                              <Text
                                fontSize={1}
                                pl={2}
                                py={2}
                                pb={4}
                                variant="grotTextCaps">
                                <Trans i18nKey="donations.supportLabel" />
                              </Text>
                              <Text
                                pl={2}
                                fontSize={4}
                                height="64px"
                                sx={{ overflow: "auto" }}>
                                {user.translations?.[0]?.donations ?? (
                                  <Trans i18nKey="donations.noDonationDescription" />
                                )}
                              </Text>
                            </>
                          ) : (
                            <>
                              <Text
                                fontSize={1}
                                pl={2}
                                py={2}
                                pb={4}
                                variant="grotTextCaps">
                                <Trans i18nKey="donations.userUnverifiedTitle" />
                              </Text>
                              <Text pl={2} fontSize={6} variant="caps">
                                <Trans i18nKey="donations.userUnverifiedContent" />
                              </Text>
                            </>
                          )}
                        </Flex>
                      </Flex>
                    </Flex>
                  )}
                </>
              )}
              {(userVerified || user === undefined) && (
                <>
                  <Box sx={{ borderBottom: "1px solid black" }} pb={3}>
                    <GridLabel
                      fontSize={1}
                      pl={2}
                      py={2}
                      i18nKey="donations.contributePeriodHeader"
                    />
                    <Flex justifyContent="center">
                      <Tabs
                        px={2}
                        activeName="frequency"
                        fontSize={[3, 6]}
                        tabs={[
                          {
                            name: "OneTime",
                            label: i18next.t("donations.oneTime"),
                            onClick: () =>
                              this.setState({ frequency: "OneTime" })
                          },
                          {
                            name: "Monthly",
                            label: i18next.t("donations.monthly"),
                            onClick: () =>
                              this.setState({ frequency: "Monthly" })
                          },
                          {
                            name: "Yearly",
                            label: i18next.t("donations.yearly"),
                            onClick: () =>
                              this.setState({ frequency: "Yearly" })
                          }
                        ]}
                      />
                    </Flex>
                  </Box>
                  <GridLabel
                    fontSize={1}
                    pl={2}
                    py={2}
                    i18nKey="donations.priceHeader"
                  />
                  <Flex justifyContent="center">
                    <Tabs
                      mb={2}
                      wrapTabs={true}
                      activeName="prices"
                      flexBasis={["33%"]}
                      customMx={2}
                      fontSize={[3, 6]}
                      tabs={prices.map((it, index) => ({
                        name: prices[index].label,
                        label: prices[index].label,
                        onClick: () =>
                          this.setState({
                            price: {
                              value: prices[index].value,
                              precision: prices[index].precision,
                              currency: prices[index].currency as Currency
                            },
                            otherPrice:
                              prices[index] === prices[prices.length - 1]
                                ? true
                                : false
                          })
                      }))}
                    />
                  </Flex>
                  {otherPrice && (
                    <Box px={2} py={2}>
                      <Input
                        type="number"
                        max={999999}
                        onChange={this.handleOnChangeOther}
                        name="otherInput"
                        onInput={this.limitPrice}
                      />
                    </Box>
                  )}
                  {isLogged && (
                    <>
                      <Text
                        sx={{ borderBottom: "1px solid black" }}
                        variant="grotText"
                        pl={2}>
                        <Trans i18nKey="donations.paymentOptions" />
                      </Text>
                      <Text py={3} pl={2} fontSize={4}>
                        <Trans i18nKey="donations.paymentDescription" />
                      </Text>
                      <Flex px={2} pb={4}>
                        <Flex flexDirection="column" width="100%" mr={4}>
                          <Text variant="grotTextCaps" fontSize={1} pb={2}>
                            <Trans i18nKey="donations.creditCard.number" />
                          </Text>
                          <Box
                            sx={{ borderBottom: "1px solid black" }}
                            width="100%">
                            <CardNumberElement />
                          </Box>
                        </Flex>
                        <Flex flexDirection="column" width="100%" mr={4}>
                          <Text variant="grotTextCaps" fontSize={1} pb={2}>
                            <Trans i18nKey="donations.creditCard.date" />
                          </Text>
                          <Box
                            sx={{ borderBottom: "1px solid black" }}
                            width="100%">
                            <CardExpiryElement />
                          </Box>
                        </Flex>
                        <Flex flexDirection="column" width="100%">
                          <Text variant="grotTextCaps" fontSize={1} pb={2}>
                            <Trans i18nKey="donations.creditCard.cvv" />
                          </Text>
                          <Box
                            sx={{ borderBottom: "1px solid black" }}
                            width="100%">
                            <CardCvcElement />
                          </Box>
                        </Flex>
                      </Flex>
                    </>
                  )}
                </>
              )}
            </>
          </Popup>
        )}
      </>
    );
  }
}

const InjectedCardPaymentPopupForm = injectStripe(DonationPopupForm as any);

export const DonationPopUp: React.FC<PropsExternal> = props => {
  return (
    <StripeProvider apiKey={environment.stripeKey}>
      <Elements>
        <InjectedCardPaymentPopupForm {...(props as any)} />
      </Elements>
    </StripeProvider>
  );
};
