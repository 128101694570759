import styled from "@emotion/styled";
import themeGet from "@styled-system/theme-get";
import { GridProps } from "primitives/Grid";
import React, { ReactNode } from "react";
import { StickyScrollUp } from "react-stickup";
import { BoxProps } from "rebass";
import { position, PositionProps } from "styled-system";
import Area from "./Area";

interface Props extends BoxProps {
  children?: ReactNode;
  isStickyActive?: (isSticky: boolean) => void;
  zIndex?: number;
}

export const NavbarAreaStyle = styled(Area)<GridProps & PositionProps>`
  ${position};
  border-bottom: ${(props: any) => {
    return `${props.theme.borders[1]} ${props.theme.colors.black}`;
  }};
  height: ${themeGet("sizes.navbarHeight.sm")};
  ${themeGet("mediaQueries.lg")} {
    height: ${themeGet("sizes.navbarHeight.lg")};
  }
`;

export const NavbarArea: React.FC<Props> = ({
  children,
  isStickyActive,
  zIndex,
  ...rest
}) => {
  return (
    <NavbarAreaStyle {...(rest as any)}>
      <StickyScrollUp style={{ zIndex: zIndex ?? 99 }}>
        {({ isSticky }) => {
          isStickyActive?.(isSticky);
          return (
            <NavbarAreaStyle {...(rest as any)}>{children}</NavbarAreaStyle>
          );
        }}
      </StickyScrollUp>
    </NavbarAreaStyle>
  );
};

NavbarAreaStyle.defaultProps = {
  gridArea: "navbar",
  bg: "fill.0",
  px: [0, 0, 0, 0, 0],
  // Styles for fixed navbar
  position: undefined,
  top: 0,
  left: 0,
  zIndex: 99,
  width: "100%"
};

export default NavbarArea;
