import { Button, Toast, Icon } from "app/shared";
import { Firebase, withFirebase } from "authentication/firebase";
import React, { Component, BaseSyntheticEvent } from "react";
import { Trans } from "react-i18next";
import { compose } from "recompose";
import { DocumentInfo } from "../models";
import { getDocumentInfo, likeDocument, unlikeDocument } from "../services";
import { BoxProps } from "rebass";
import { CollectionInfo } from "app/collections/models";
import {
  getCollectionInfo,
  likeCollection,
  unlikeCollection
} from "app/collections/services";
import { BucketInfo } from "app/buckets/models";
import { getBucketInfo, likeBucket, unlikeBucket } from "app/buckets/services";

interface PropsExternal extends BoxProps {
  documentId: number;
  documentInfo?: DocumentInfo;
  collectionInfo?: CollectionInfo;
  bucketInfo?: BucketInfo;
  variant?: string;
  onInfoChange?: (info: DocumentInfo | CollectionInfo | BucketInfo) => void;
  mode?: "document" | "collection" | "bucket";
}

interface Props extends PropsExternal {
  firebase: Firebase;
}

interface State {
  likes: boolean;
}

class LikeButtonBase extends Component<Props, State> {
  static defaultProps = {
    variant: "text",
    mode: "document" as const
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      likes:
        props.mode === "document"
          ? props.documentInfo?.like ?? false
          : props.mode === "collection"
          ? props.collectionInfo?.like ?? false
          : props.mode === "bucket"
          ? props.bucketInfo?.like ?? false
          : false
    };
  }

  componentDidMount() {
    const {
      firebase,
      documentInfo,
      collectionInfo,
      bucketInfo,
      documentId,
      onInfoChange,
      mode
    } = this.props;

    if (mode === "document") {
      if (documentInfo === undefined && firebase.isLogged()) {
        getDocumentInfo(documentId).then(info => {
          this.setState({ likes: info.like });
          onInfoChange?.(info);
        });
      }
    } else if (mode === "collection") {
      if (collectionInfo === undefined && firebase.isLogged()) {
        getCollectionInfo(documentId).then(info => {
          this.setState({ likes: info.like });
          onInfoChange?.(info);
        });
      }
    } else if (mode === "bucket") {
      if (bucketInfo === undefined && firebase.isLogged()) {
        getBucketInfo(documentId).then(info => {
          this.setState({ likes: info.like });
          onInfoChange?.(info);
        });
      }
    }
  }

  handleOnClick = async (event: BaseSyntheticEvent) => {
    const { firebase, documentId, onInfoChange, mode } = this.props;
    const { likes } = this.state;
    if (firebase.isLogged()) {
      const keyMode =
        mode === "collection"
          ? "collections.collectionDetails"
          : mode === "bucket"
          ? "buckets.bucketDetails"
          : "documents.documentDetails";
      try {
        if (!likes) {
          const call =
            mode === "collection"
              ? likeCollection(documentId)
              : mode === "bucket"
              ? likeBucket(documentId)
              : likeDocument(documentId);
          await call;
          Toast.success({
            title: { key: `${keyMode}.likeButton.likeToast` }
          });
        } else {
          const call =
            mode === "collection"
              ? unlikeCollection(documentId)
              : mode === "bucket"
              ? unlikeBucket(documentId)
              : unlikeDocument(documentId);
          await call;
          Toast.success({
            title: { key: `${keyMode}.likeButton.unlikeToast` }
          });
        }
        this.setState({ likes: !likes });
        onInfoChange?.({
          buy: false,
          view: false,
          like: !likes,
          share: false
        });
      } catch (err) {
        console.error(err);
        Toast.apiError(err);
      }
    } else {
      Toast.error({
        title: { key: "documents.documentDetails.likeButton.notLoggedInToast" }
      });
    }
  };

  render() {
    const { likes } = this.state;
    const {
      documentId,
      documentInfo,
      collectionInfo,
      bucketInfo,
      firebase,
      variant,
      mode,
      onInfoChange,
      ...rest
    } = this.props;
    return (
      <Button
        variant={variant === "icon" ? "transparent" : likes ? "blue" : "white"}
        sx={{ "& svg path": { fill: "blue" } }}
        onClick={e => this.handleOnClick(e)}
        {...(rest as any)}>
        {variant === "icon" && (
          <Icon name={likes ? "HeartActive" : "Heart"} size={22} />
        )}
        {variant === "text" && (
          <Trans i18nKey="documents.documentDetails.likeButton.like" />
        )}
      </Button>
    );
  }
}

export const LikeButton = compose<Props, PropsExternal>(withFirebase)(
  LikeButtonBase
);
