import { SearchTabs } from "../models";
import { DEFAULT_CENTER, MAX_DIAMETER_KM } from "app/shared/maps/models";

export const UserTabs: SearchTabs[] = [
  {
    name: "user",
    filters: [{ field: "class_name", values: ["user"], type: "any" }],
    i18n: "search.users.sellers",
    view: "user",
    classNames: ["user"]
  },
  {
    name: "user-location",
    filters: [
      { field: "class_name", values: ["user"], type: "any" },
      {
        field: "location",
        values: [
          {
            center: `${DEFAULT_CENTER.lat},${DEFAULT_CENTER.lng}`,
            distance: MAX_DIAMETER_KM,
            unit: "km"
          } as any
        ],
        type: "any"
      }
    ],
    i18n: "search.users.locations",
    view: "user-location",
    classNames: ["user"]
  }
];
