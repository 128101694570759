import {
  ErrorBoundary,
  SearchProvider,
  WithSearch
} from "@elastic/react-search-ui";
import AppSearchAPIConnector from "@elastic/search-ui-app-search-connector";
import MultiCheckboxFacet from "app/search/filters/facets/MultiCheckboxFacet";
import environment from "configurations";
import i18next from "i18next";
import React, { PureComponent } from "react";
import { Box } from "rebass";
import { MapSearchResults } from "../locations/MapSearchResults";
import { SearchHeader, SearchLayout } from "../shared";
import { UserTabs } from "./constants";

interface WithSearchProps {
  totalResults: number;
}

export class UserLocationSearch extends PureComponent {
  getConfig = () => {
    return {
      apiConnector: new AppSearchAPIConnector(environment.search),
      alwaysSearchOnInitialLoad: false,
      trackUrlState: false,
      initialState: {
        filters: [{ field: "class_name", values: ["user"], type: "any" }],
        resultsPerPage: 100
      },
      searchQuery: {
        result_fields: {
          // Shared
          real_id: { raw: {} },
          class_name: { raw: {} },
          location: { raw: {} },
          city: { raw: {} },
          country: { raw: {} },
          picture: { raw: {} },
          professional_name: { raw: {} },
          skills: { raw: {} }
        },
        disjunctiveFacets: [],
        facets: {
          city: { type: "value", size: 30 },
          country: { type: "value", size: 30 },
          skills: { type: "value", size: 30 },
          collaboration: { type: "value", size: 30 }
        }
      }
    };
  };

  render() {
    return (
      <SearchProvider config={this.getConfig()}>
        <WithSearch
          mapContextToProps={({ totalResults }: WithSearchProps) => ({
            totalResults
          })}>
          {({ totalResults }: WithSearchProps) => {
            return (
              <Box>
                <ErrorBoundary>
                  <SearchLayout
                    totalResults={totalResults}
                    sideContent={
                      <>
                        <MultiCheckboxFacet
                          field="city"
                          label={i18next.t("search.users.city")}
                          filterType="any"
                        />
                        <MultiCheckboxFacet
                          field="country"
                          label={i18next.t("search.users.country")}
                          filterType="any"
                        />
                        <MultiCheckboxFacet
                          field="skills"
                          label={i18next.t("search.users.skills")}
                          filterType="any"
                        />
                        <MultiCheckboxFacet
                          field="collaboration"
                          label={i18next.t("search.users.collaboration")}
                          filterType="any"
                        />
                      </>
                    }
                    bodyContent={<MapSearchResults />}
                    bodyHeader={
                      <SearchHeader
                        tabs={UserTabs}
                        activeTabName="user-location"
                        hideViewButton={true}
                        hideShowHideButton={true}
                      />
                    }
                  />
                </ErrorBoundary>
              </Box>
            );
          }}
        </WithSearch>
      </SearchProvider>
    );
  }
}
