import i18next from "i18next";
import { getCommonTranslation } from "app/shared/translations/utils";

export const getLocationLabel = (
  city?: string,
  country?: string,
  hideIfNoLocation?: boolean
): string | undefined => {
  let text = "";

  if (city === undefined && country === undefined && !hideIfNoLocation) {
    text += i18next.t("shared.locationLabel.noLocation");
  }

  if (city?.length) {
    text += city;
  }

  if (country?.length) {
    if (city?.length) {
      text += ", ";
    }

    text += getCommonTranslation(country);
  }

  return text.length ? text : undefined;
};
