import { ProposalsField } from "app/proposals";
import { Icon, Select, Button } from "app/shared";
import { MonetaryText } from "app/shared/monetaryText";
import { AuthUserContext } from "authentication/session";
import i18next from "i18next";
import {
  AvailableLicenseType,
  LicensePrice,
  MonetaryAmount
} from "marketplace";
import { AuthUser, Document, User } from "models";
import React, { useState } from "react";
import { Box, Flex, Text } from "rebass";
import { useMediaQueryContext } from "styles/context";
import GetPhotoButton from "./GetPhotoButton";
import environment from "configurations";

interface Props {
  document: Document;
  documentUser: User;
  availableLicenseTypes: AvailableLicenseType[];
}

const DocumentLicensesProposals: React.FC<Props> = ({
  document,
  documentUser,
  availableLicenseTypes
}) => {
  const [selectedLicense, setSelectedLicense] = useState<string>("royaltyfree");

  const applyDocumentPriceFee = (licensePrice: LicensePrice): LicensePrice => {
    // Calculate basePrice * (1 + buyerFee)
    const basePrice = new MonetaryAmount(
      licensePrice.basePrice.value,
      licensePrice.basePrice.currency,
      licensePrice.basePrice.precision
    );
    const buyerFee =
      availableLicenseTypes.find(it => it.license === licensePrice.license)
        ?.buyerFee ?? 0;
    const finalPrice = basePrice.multiply(1 + buyerFee);
    return {
      ...licensePrice,
      basePrice: {
        value: finalPrice.getAmount(),
        currency: finalPrice.getCurrency(),
        precision: finalPrice.getPrecision()
      }
    };
  };

  const renderDocumentLicenses = (documentUser: User, authUser?: AuthUser) => {
    const licensePrices: LicensePrice[] = document.prices.map(
      basePriceLicense => applyDocumentPriceFee(basePriceLicense)
    );
    const licensePrice: LicensePrice[] = licensePrices.filter(
      licensePrice => licensePrice.license === selectedLicense
    );

    return (
      <Flex
        width="100%"
        alignItems="center"
        flexDirection={["column", "row"]}
        justifyContent="center">
        <Flex width="100%" justifyContent="center" pl={2} pr={2}>
          <Box width="100%" pt={[4, 0]}>
            <Select
              labeli18n="documents.documentDetails.documentLicenses.chooseYourLicense"
              labelFontSize={1}
              labelPaddingBottom={2}
              defaultValue={
                availableLicenseTypes[1].license
                  ? {
                      value: availableLicenseTypes[1].license,
                      label: i18next.t(
                        `documents.documentDetails.documentLicense.${availableLicenseTypes[1].license}.label`
                      )
                    }
                  : {
                      value: availableLicenseTypes[0].license,
                      label: i18next.t(
                        `documents.documentDetails.documentLicense.${availableLicenseTypes[0].license}.label`
                      )
                    }
              }
              disabled={
                documentUser.status !== "verified" ||
                authUser?.uid === document.uid
              }
              onChange={options => setSelectedLicense(options.value)}
              sx={{ borderBottom: 1 }}
              options={availableLicenseTypes
                .sort((a, b) => b.license.localeCompare(a.license))
                .map(availableLicenseType => ({
                  value: availableLicenseType.license,
                  label: i18next.t(
                    `documents.documentDetails.documentLicense.${availableLicenseType.license}.label`
                  )
                }))}
            />
          </Box>
          {licensePrice && licensePrice[0] !== undefined && (
            <Flex
              alignItems="flex-end"
              ml={4}
              minWidth={["45%", "25%"]}
              maxWidth={["100%", "50%"]}>
              <Button
                width="100%"
                py={1}
                variant="link.button"
                sx={{ borderColor: "black" }}
                href="/about#licenses">
                <Flex justifyContent="center" width="100%" alignItems="center">
                  <Icon name="Plus" size={15} />{" "}
                  <Text variant="caps" pl={1} fontSize={1} py={1}>
                    {i18next.t(
                      "documents.documentDetails.documentLicenses.info"
                    )}
                  </Text>
                </Flex>
              </Button>
            </Flex>
          )}
        </Flex>
        <Flex
          width={["100% ", "70%"]}
          justifyContent="center"
          sx={{ borderLeft: [0, 1] }}>
          <Text fontSize={[4, 8]} variant="grotTextCaps" py={4}>
            {licensePrice && licensePrice[0] !== undefined ? (
              <MonetaryText price={licensePrice[0].basePrice} fontSize={[]} />
            ) : (
              i18next.t(
                "documents.documentDetails.documentLicense.notApplicable"
              )
            )}
          </Text>
        </Flex>
      </Flex>
    );
  };

  const getSelectedLicensePrice = () => {
    const licensePrices: LicensePrice[] = document.prices.map(
      basePriceLicense => applyDocumentPriceFee(basePriceLicense)
    );

    return (
      licensePrices.filter(
        licensePrice => licensePrice.license === selectedLicense
      )?.[0] ?? null
    );
  };

  const { isSmallMedium } = useMediaQueryContext();

  return (
    <Box width={["100%"]} px={[2, 2, 0]}>
      <AuthUserContext.Consumer>
        {({ authUser }) => (
          <>
            <Flex flexDirection="column">
              <Box backgroundColor="black.0" sx={{ border: 1 }}>
                <Flex>
                  <Flex width="100%">
                    <Text
                      fontSize={[3, 5, 7]}
                      color="white.0"
                      variant="grotDisplayCaps"
                      pl={[1, 2, 3]}
                      my={1}>
                      {i18next.t(
                        "documents.documentDetails.documentLicenses.chooseYourLicense"
                      )}
                    </Text>
                  </Flex>
                  {!isSmallMedium && (
                    <Flex width="70%">
                      <Text
                        fontSize={[3, 5, 7]}
                        color="white.0"
                        variant="grotDisplayCaps"
                        pl={[1, 2, 3]}
                        my={1}>
                        {i18next.t(
                          "documents.documentDetails.documentLicenses.price"
                        )}
                      </Text>
                    </Flex>
                  )}
                </Flex>
              </Box>
              <Flex
                flexDirection={["column", "column", "row"]}
                mb={2}
                sx={{
                  border: 1
                }}>
                {renderDocumentLicenses(documentUser, authUser)}
              </Flex>
              <GetPhotoButton
                document={document}
                licensePrice={getSelectedLicensePrice()}
              />
            </Flex>
            {authUser && !environment.featureFlags.specialOfferDisabled && (
              <Box mt={7}>
                <ProposalsField userId={authUser.uid} document={document} />
              </Box>
            )}
          </>
        )}
      </AuthUserContext.Consumer>
    </Box>
  );
};

export default DocumentLicensesProposals;
