import React from "react";
import Svg, { SvgProps } from "primitives/Svg";

const SvgPlusWhite = ({ width, height, size, color }: SvgProps) => (
  <Svg
    width={size || width}
    height={size || height}
    viewBox="0 0 24 24"
    color={color}>
    <path d="M20 12H4m8 8V4" stroke="#fff" />
  </Svg>
);

SvgPlusWhite.defaultProps = {
  width: 24,
  height: 24
};
export default SvgPlusWhite;
