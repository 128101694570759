import { Button, Icon } from "app/shared";
import React, { ReactNode, useState } from "react";
import { Box, Flex } from "rebass";

interface Props {
  title: ReactNode;
  icon: ReactNode;
  isLast?: boolean;
  collapsed?: boolean;
  variant?: "blue" | "white";
  id?: string;
}

export const CollapsableSection: React.FC<Props> = ({
  title,
  children,
  icon,
  id,
  collapsed = false,
  isLast = false
}) => {
  const [isCollapsed, setCollapsed] = useState(collapsed);

  return (
    <Box
      id={id}
      sx={{
        border: "1px solid black",
        borderBottom: isLast ? "1p solid black" : "none"
      }}
      pl={4}
      pr={4}
      pb={4}
      pt={4}>
      <Button
        width="100%"
        variant="transparent"
        backgroundColor="white"
        onClick={() => {
          setCollapsed(!isCollapsed);
        }}>
        <Flex justifyContent="center" sx={{ gap: "30px" }} alignItems="center">
          {icon}
          {title}
          {!isCollapsed ? (
            <Box sx={{ alignSelf: "flex-end" }}>
              <Icon name="ArrowDown2" size={57} />
            </Box>
          ) : (
            <Box sx={{ alignSelf: "flex-end", transform: "rotate(180deg)" }}>
              <Icon name="ArrowDown2" size={57} />
            </Box>
          )}
        </Flex>
      </Button>
      <Box pt={4} display={!isCollapsed ? "none" : undefined}>
        {children}
      </Box>
    </Box>
  );
};
