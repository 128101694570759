type Url = string;
type I18nDotSeparatedString = string;

/**
 * Social Media
 */
export type SocialMediaTypes = "facebook" | "twitter" | "instagram" | "none";

export interface SocialMedia {
  name: SocialMediaTypes;
  link: Url;
  i18n: I18nDotSeparatedString;
}

export const SocialMedias: SocialMedia[] = [
  {
    name: "facebook",
    link: "facebook.com/",
    i18n: "account.editMyInfo.socialProfiles.socialMedia.facebook"
  },
  {
    name: "twitter",
    link: "twitter.com/",
    i18n: "account.editMyInfo.socialProfiles.socialMedia.twitter"
  },
  {
    name: "instagram",
    link: "instagram.com/",
    i18n: "account.editMyInfo.socialProfiles.socialMedia.instagram"
  }
];
