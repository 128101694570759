import styled from "@emotion/styled";
import Area from "./Area";

const HeaderArea = styled(Area)``;

HeaderArea.defaultProps = {
  gridArea: "header"
};

export default HeaderArea;
