import React, { Component, ReactNode } from "react";
import { Trans } from "react-i18next";
import {
  Button,
  Toast,
  Input,
  Label,
  Checkbox,
  CountrySelect,
  GridLabel,
  Tooltip
} from "app/shared";
import { User, ValidationError } from "models";
import { updateUser } from "app/account/services";
import { UpdateUserDTO } from "app/account/dto";
import { Box, Flex, Text } from "rebass";
import { getMarketplaceSettings, updateMarketplaceSettings } from "../services";
import environment from "configurations";
import i18next from "i18next";
import { validateZipCode } from "app/shared/zipCode/utils";
import { BecomeVerifiedPopup } from "../popups";
import { FormFieldSet } from "app/shared/forms";
import { Textarea } from "app/shared/input/Textarea";
import { LocationInputSearch } from "../editMyInfo/LocationInputSearch";

interface Props {
  user?: User;
  showBilling: boolean;
  showPayout: boolean;
  onComplete?: (user?: User) => void;
  onSkip?: () => void;
  viewMode?: boolean;
  submiti18n?: string;
  submitVariant?: string;
  submitWidth?: string | string[];
  submitPadding?: number | number[];
  children?: ReactNode;
  hideHeader?: boolean;
}

interface State {
  name: string;
  nationality: string;
  nationalCardNumber: string;
  vat: string;
  address: string;
  postalCode: string;
  payoutReceiver: string;
  receiveDonations: boolean;
  disabled: boolean;
  errors: ValidationError[];
  crowdfundingUserDescription?: string;
  city: string;
  country: string;
}

export class BusinessInfoForm extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      name: props.user?.billing?.name ?? props.user?.professionalName ?? "",
      nationality: props.user?.billing?.nationality ?? "",
      nationalCardNumber: props.user?.billing?.nationalCardNumber ?? "",
      vat: props.user?.billing?.vat ?? "",
      address: props.user?.billing?.address ?? "",
      postalCode: props.user?.billing?.postalCode ?? "",
      payoutReceiver: "",
      receiveDonations: true,
      disabled: false,
      crowdfundingUserDescription:
        props.user?.translations[0]?.donations ?? undefined,
      city: props.user?.billing?.city
        ? props.user?.billing?.city
        : props.user?.city ?? "",
      country: props.user?.billing?.country
        ? props.user?.billing?.country
        : props.user?.country ?? "",
      errors: []
    };
  }

  componentDidMount() {
    const { showPayout } = this.props;

    if (showPayout) {
      getMarketplaceSettings().then(settings =>
        this.setState({
          payoutReceiver: settings.payoutReceiver,
          receiveDonations: settings.receiveDonations
        })
      );
    }
  }

  //to update user donation description in PayoutInfo
  componentDidUpdate(prevProps: Props) {
    if (this.props.showPayout) {
      if (this.props.user !== undefined) {
        if (prevProps.user !== this.props.user) {
          this.setState({
            crowdfundingUserDescription: this.props.user?.translations[0]
              ?.donations
          });
        }
      }
    }
  }

  validateForm(): ValidationError[] {
    const { showBilling, showPayout, user } = this.props;
    const {
      name,
      nationality,
      nationalCardNumber,
      vat,
      address,
      postalCode,
      payoutReceiver,
      city,
      country
    } = this.state;
    const errors: ValidationError[] = [];

    if (showBilling) {
      if (
        !user ||
        user.professionalName.length === 0 ||
        user.city?.length === 0 ||
        user.country?.length === 0
      ) {
        errors.push({ i18nKey: "shared.forms.invalidField", field: "user" });
      }
      if (name.length === 0 || name.length > 128) {
        errors.push({ i18nKey: "shared.forms.invalidField", field: "name" });
      }
      if (nationality.length === 0 || nationality.length > 24) {
        errors.push({
          i18nKey: "shared.forms.invalidField",
          field: "nationality"
        });
      }
      if (nationalCardNumber.length === 0 || nationalCardNumber.length > 24) {
        errors.push({
          i18nKey: "shared.forms.invalidField",
          field: "nationalCardNumber"
        });
      }
      if (vat.length === 0 || vat.length > 24) {
        errors.push({ i18nKey: "shared.forms.invalidField", field: "vat" });
      }
      if (address.length === 0 || address.length > 128) {
        errors.push({ i18nKey: "shared.forms.invalidField", field: "address" });
      }
      if (country.length === 0 || city.length === 0) {
        errors.push({ i18nKey: "shared.forms.invalidField", field: "city" });
      }
      if (
        postalCode.length === 0 ||
        postalCode.length > 24 ||
        !validateZipCode(country ?? "", postalCode)
      ) {
        errors.push({
          i18nKey: "shared.forms.invalidField",
          field: "postalCode"
        });
      }
    }

    if (showPayout) {
      if (payoutReceiver.length === 0 || payoutReceiver.length > 64) {
        errors.push({
          i18nKey: "shared.forms.invalidField",
          field: "payoutReceiver"
        });
      }
    }

    return errors;
  }

  onSubmit() {
    const { onComplete, user, showBilling, showPayout } = this.props;
    const {
      name,
      nationality,
      nationalCardNumber,
      vat,
      address,
      postalCode,
      payoutReceiver,
      receiveDonations,
      crowdfundingUserDescription,
      city,
      country
    } = this.state;
    const errors = this.validateForm();

    if (errors.length === 0) {
      this.setState({ disabled: true, errors: [] }, async () => {
        try {
          let updatedUser;

          if (showBilling && user) {
            const updateUserDTO: UpdateUserDTO = {
              userId: user.id,
              fullName: name,
              billing: {
                name,
                nationality,
                nationalCardNumber,
                vat,
                address,
                postalCode,
                city,
                country
              },
              translations: [
                {
                  language: user?.translations?.[0]?.language ?? "en",
                  about: user?.translations?.[0]?.about ?? "",
                  donations: crowdfundingUserDescription ?? undefined
                }
              ]
            };
            updatedUser = await updateUser(updateUserDTO);
          }

          if (showPayout) {
            await updateMarketplaceSettings(
              environment.payoutProvider,
              payoutReceiver,
              receiveDonations
            );
            if (!showBilling && user) {
              const updateUserDTO: UpdateUserDTO = {
                userId: user.id,
                translations: [
                  {
                    language: user?.translations?.[0]?.language ?? "en",
                    about: user?.translations?.[0]?.about ?? "",
                    donations: crowdfundingUserDescription
                  }
                ]
              };
              await updateUser(updateUserDTO);
            }
          }
          Toast.success({
            title: {
              key: "account.onboarding.billingInfoForm.confirmationToast"
            }
          });
          this.setState({ disabled: false });
          onComplete?.(updatedUser ?? user);
        } catch (error) {
          Toast.apiError(error);
          this.setState({ disabled: false });
        }
      });
    } else {
      this.setState({ errors });
    }
  }

  handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState<any>({ [event.target.name]: event.target.value });
  };

  render() {
    const {
      onSkip,
      showBilling,
      showPayout,
      submiti18n,
      submitVariant,
      submitWidth,
      submitPadding,
      viewMode,
      children,
      hideHeader
    } = this.props;
    const {
      name,
      nationality,
      nationalCardNumber,
      vat,
      address,
      postalCode,
      payoutReceiver,
      receiveDonations,
      disabled,
      errors,
      crowdfundingUserDescription,
      city,
      country
    } = this.state;

    return (
      <>
        {showPayout && showBilling && !hideHeader && (
          <Box sx={{ textAlign: "center" }} mb={8}>
            <Text
              fontSize={[3]}
              sx={{
                display: "inline-block",
                textAlign: "left",
                lineHeight: "24px"
              }}>
              <Trans i18nKey="account.onboarding.billingInfoForm.headerText" />
            </Text>
          </Box>
        )}
        {showPayout && (
          <FormFieldSet
            titleTop={i18next.t("")}
            contentPadding={[]}
            sx={{ border: 1, px: [2, 4], pb: [2, 3], mb: 4 }}>
            {showBilling && (
              <>
                <Flex>
                  <Text fontSize={[1, 3, 5]} variant="caps" mb={3} pr={1}>
                    <Trans i18nKey="account.onboarding.billingInfoForm.payoutTitle" />
                  </Text>
                  <Tooltip i18nKey="account.onboarding.billingInfoForm.payoutTooltip" />
                </Flex>
                {!viewMode && (
                  <Label
                    i18n="account.onboarding.billingInfoForm.payoutDescription"
                    variant="grotTextCaps"
                    mb={3}
                    fontSize={[0, 1]}
                  />
                )}
              </>
            )}
            <Checkbox
              name="payoutProviderName"
              checked={true}
              disabled={viewMode}
              size={18}
              onChange={() => null}
              mb={4}>
              <Text fontSize={[1, 3]} variant="grotText">
                <Trans i18nKey="account.onboarding.billingInfoForm.paypal" />
              </Text>
            </Checkbox>
            <Input
              name="payoutReceiver"
              type="text"
              labelI18n="account.onboarding.billingInfoForm.paypalEmail"
              errorI18n={
                errors.find(it => it.field === "payoutReceiver")?.i18nKey
              }
              placeholder={i18next.t(
                "account.onboarding.billingInfoForm.paypalEmailPlaceholder"
              )}
              onChange={this.handleOnChange}
              value={payoutReceiver}
              disabled={viewMode}
              variant={viewMode ? "input.borderless" : undefined}
              mb={4}
            />
            <Text fontSize={[1, 3, 5]} variant="caps" mb={3}>
              <Trans i18nKey="account.onboarding.billingInfoForm.crowdfundingTitle" />
            </Text>
            <Flex>
              <Flex>
                <Checkbox
                  name="crowdfunding"
                  disabled={viewMode}
                  size={18}
                  checked={receiveDonations}
                  onChange={() => this.setState({ receiveDonations: true })}
                  mb={4}>
                  <Text fontSize={[1, 3]} variant="grotText">
                    <Trans i18nKey="account.onboarding.billingInfoForm.enableCrowdfunding" />
                  </Text>
                </Checkbox>
              </Flex>
              <Flex ml={10}>
                <Checkbox
                  name="crowfunding"
                  disabled={viewMode}
                  size={18}
                  checked={!receiveDonations}
                  onChange={() => this.setState({ receiveDonations: false })}
                  mb={4}>
                  <Text fontSize={[1, 3]} variant="grotText">
                    <Trans i18nKey="account.onboarding.billingInfoForm.disableCrowdfunding" />
                  </Text>
                </Checkbox>
              </Flex>
            </Flex>
            <GridLabel
              mb={2}
              i18nKey="account.onboarding.billingInfoForm.crowdfundingUserDescription"
            />
            <Textarea
              name="crowdfundingUserDescription"
              type="text"
              placeholder={i18next.t(
                "account.onboarding.billingInfoForm.crowdfundingUserDescriptionPlaceholder"
              )}
              onChange={this.handleOnChange}
              value={crowdfundingUserDescription}
              disabled={viewMode}
              variant={viewMode ? "input.borderless" : undefined}
            />
          </FormFieldSet>
        )}
        {showBilling && (
          <>
            {children && <Box mx={[-2, 0]}>{children}</Box>}
            <Box my={4}>
              <Input
                name="name"
                type="text"
                labelI18n="account.onboarding.billingInfoForm.fullName"
                errorI18n={errors.find(it => it.field === "name")?.i18nKey}
                placeholder={i18next.t(
                  "account.onboarding.billingInfoForm.fullNamePlaceholder"
                )}
                onChange={this.handleOnChange}
                value={name}
                disabled={viewMode}
                variant={viewMode ? "input.borderless" : undefined}
              />
            </Box>
            <CountrySelect
              name="nationality"
              labelI18n="account.onboarding.billingInfoForm.nationality"
              errorI18n={errors.find(it => it.field === "nationality")?.i18nKey}
              placeholder={i18next.t(
                "account.onboarding.billingInfoForm.nationalityPlaceholder"
              )}
              onChange={this.handleOnChange}
              value={nationality}
              disabled={viewMode}
              borderBottom={viewMode ? 0 : undefined}
              hideDropdownIndicator={viewMode ? true : undefined}
              mb={4}
            />
            <Flex flexDirection={["column", "row"]} mb={4}>
              <Flex width={["100%", "70%"]} mb={[4, 0]}>
                <Input
                  name="address"
                  type="text"
                  width="100%"
                  labelI18n="account.onboarding.billingInfoForm.street"
                  errorI18n={errors.find(it => it.field === "address")?.i18nKey}
                  placeholder={i18next.t(
                    "account.onboarding.billingInfoForm.streetPlaceholder"
                  )}
                  onChange={this.handleOnChange}
                  value={address}
                  disabled={viewMode}
                  variant={viewMode ? "input.borderless" : undefined}
                />
              </Flex>
              <Flex width={["100%", "30%"]} pl={[0, 4]}>
                <Input
                  name="postalCode"
                  type="text"
                  width="100%"
                  labelI18n="account.onboarding.billingInfoForm.zipCode"
                  errorI18n={
                    errors.find(it => it.field === "postalCode")?.i18nKey
                  }
                  placeholder={i18next.t(
                    "account.onboarding.billingInfoForm.zipCodePlaceholder"
                  )}
                  onChange={this.handleOnChange}
                  value={postalCode}
                  disabled={viewMode}
                  variant={viewMode ? "input.borderless" : undefined}
                />
              </Flex>
            </Flex>
            <LocationInputSearch
              labelI18n="account.onboarding.billingInfoForm.addressLocation"
              name="city"
              value={[city, country].filter(it => it).join(", ")}
              errorI18n={errors.find(it => it.field === "city")?.i18nKey}
              placeholder={i18next.t(
                "account.onboarding.personalInfo.locationPlaceholder"
              )}
              onLocationChange={(city, country) => {
                if (city?.length && country?.length) {
                  this.handleOnChange({
                    target: { name: "city", value: city }
                  } as React.ChangeEvent<HTMLInputElement>);
                  return this.handleOnChange({
                    target: { name: "country", value: country }
                  } as React.ChangeEvent<HTMLInputElement>);
                } else {
                  // If there is no value or there is an error use the old value
                  return this.handleOnChange({
                    target: {
                      name: "city",
                      value: city
                    }
                  } as React.ChangeEvent<HTMLInputElement>);
                }
              }}
              disabled={viewMode}
              variant={viewMode ? "input.borderless" : undefined}
              mb={4}
            />

            <Flex flexDirection={["column", "row"]} mb={4}>
              <Flex width={["100%", "50%"]} mb={[4, 0]}>
                <Input
                  name="nationalCardNumber"
                  type="text"
                  labelI18n="account.onboarding.billingInfoForm.nationalCardNumber"
                  width="100%"
                  errorI18n={
                    errors.find(it => it.field === "nationalCardNumber")
                      ?.i18nKey
                  }
                  infoI18n="account.onboarding.billingInfoForm.nationalCardNumberInfo"
                  placeholder={i18next.t(
                    "account.onboarding.billingInfoForm.nationalCardNumberPlaceholder"
                  )}
                  onChange={this.handleOnChange}
                  value={nationalCardNumber}
                  disabled={viewMode}
                  variant={viewMode ? "input.borderless" : undefined}
                />
              </Flex>
              <Flex width={["100%", "50%"]} pl={[0, 4]}>
                <Input
                  width="100%"
                  name="vat"
                  type="text"
                  labelI18n="account.onboarding.billingInfoForm.vat"
                  errorI18n={errors.find(it => it.field === "vat")?.i18nKey}
                  infoI18n="account.onboarding.billingInfoForm.vatInfo"
                  placeholder={i18next.t(
                    "account.onboarding.billingInfoForm.vatPlaceholder"
                  )}
                  onChange={this.handleOnChange}
                  value={vat}
                  disabled={viewMode}
                  variant={viewMode ? "input.borderless" : undefined}
                  mb={4}
                />
              </Flex>
            </Flex>
          </>
        )}
        {!viewMode && (
          <Flex justifyContent="space-between" mt={8}>
            {onSkip && (
              <BecomeVerifiedPopup
                trigger={
                  <Flex
                    justifyContent="flex-end"
                    alignItems="center"
                    width="100%">
                    <Button
                      variant="white"
                      width={["100%", "70%"]}
                      p={3}
                      disabled={disabled}>
                      <Trans i18nKey="account.onboarding.billingInfoForm.skip" />
                    </Button>
                  </Flex>
                }
                onSkip={close => {
                  onSkip();
                  close();
                }}
                onComplete={close => close()}
              />
            )}
            <Flex
              justifyContent={onSkip ? "left" : "center"}
              alignItems="center"
              width="100%"
              ml={onSkip ? 4 : 0}>
              <Button
                variant={submitVariant}
                width={onSkip ? ["100%", "70%"] : submitWidth}
                p={onSkip ? 3 : submitPadding}
                disabled={disabled}
                onClick={() => this.onSubmit()}>
                <Trans
                  i18nKey={
                    submiti18n ?? "account.onboarding.billingInfoForm.submit"
                  }
                />
              </Button>
            </Flex>
          </Flex>
        )}
      </>
    );
  }
}
