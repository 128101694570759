import React, { ReactNode } from "react";
import { CardGridLayout } from "layout";
import { GridProps } from "primitives/Grid";

interface Props extends GridProps {
  children: ReactNode;
  column?: number;
}

export const BucketsView: React.FC<Props> = ({ children, column, ...rest }) => (
  <CardGridLayout
    gridTemplateColumns={[
      "1fr",
      null,
      `repeat(${column?.toString() ?? "2"}, 1fr)`
    ]}
    gridGap={4}
    {...rest}>
    {children}
  </CardGridLayout>
);
