import { BucketAddItemsButton } from "app/buckets";
import { DocumentShareButton } from "app/documents/documentDetails/DocumentShareButton";
import { LikeButton } from "app/documents/documentDetails/LikeButton";
import { DocumentInfo } from "app/documents/models";
import { getDocument } from "app/documents/services";
import { AuthUserContext } from "authentication/session";
import { checkSameUser } from "authentication/utils";
import Icon from "icons/Icon";
import { Document, DocumentThumbnail } from "models";
import React, { useEffect, useState } from "react";
import { Trans } from "react-i18next";
import { Box, BoxProps, Flex, Text } from "rebass";
import { useMediaQueryContext } from "styles/context";
import { Image, Popup, Button, LoadingFF } from "..";
import { SwiperView } from "../swiperView";

interface Props extends BoxProps {
  open?: boolean;
  documentId: number;
  onClose?: () => void;
  documentsList?: DocumentThumbnail[];
}

export const PreviewPopUp: React.FC<Props> = ({
  open,
  documentId,
  onClose,
  documentsList,
  ...rest
}) => {
  const [document, setDocument] = useState<Document | undefined>(undefined);

  const [documentInfo, setDocumentInfo] = useState<DocumentInfo | undefined>(
    undefined
  ); // used to cache info for the next hover

  const { isSmallMedium } = useMediaQueryContext();
  const [isZoom, setIsZoom] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const updateDocument = async (mode: "next" | "back") => {
    if (document && documentsList) {
      setImageLoaded(false);
      getDocument(
        documentsList[
          mode === "next" && currentIndex === documentsList.length - 1
            ? 0
            : mode === "back" && currentIndex === 0
            ? documentsList.length - 1
            : mode === "next"
            ? currentIndex + 1
            : currentIndex - 1
        ].id
      ).then(document => {
        setDocument(document);
        setCurrentIndex(
          documentsList.findIndex(item => item.id === document.id)
        );
      });
    }
  };
  //On open the popup set document and current index
  useEffect(() => {
    if (open && documentId) {
      getDocument(documentId).then(document => {
        setDocument(document);
        if (documentsList) {
          setCurrentIndex(
            documentsList.findIndex(item => item.id === document.id)
          );
        }
      }); // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  }, [open, documentId, documentsList]);

  const DocumentDetails: React.FC = () => (
    <>
      {!isZoom && document && imageLoaded && (
        <>
          <Flex
            mt={1}
            width="100%"
            alignItems="center"
            justifyContent="space-between">
            <Flex>
              <Button
                variant="link.button.blue"
                px={5}
                href={`/documents/${document.id}`}>
                <Trans i18nKey="documents.documentView.photoDetail" />
              </Button>
            </Flex>
            <Flex>
              <AuthUserContext.Consumer>
                {({ authUser }) =>
                  authUser && (
                    <Flex pr={5}>
                      <BucketAddItemsButton
                        bucketItemIds={[{ type: "document", id: document.id }]}
                        variant="icon"
                      />
                    </Flex>
                  )
                }
              </AuthUserContext.Consumer>
              <Flex>
                <DocumentShareButton
                  name="Share"
                  variant="transparent"
                  display="block"
                  document={document}
                />
              </Flex>
              <AuthUserContext.Consumer>
                {({ authUser }) =>
                  authUser &&
                  document.owner &&
                  !checkSameUser(document.owner?.id, authUser) && (
                    <Flex pl={5}>
                      <LikeButton
                        name="Like"
                        variant="icon"
                        display="block"
                        documentId={document.id}
                        documentInfo={documentInfo}
                        onInfoChange={info =>
                          setDocumentInfo(info as DocumentInfo)
                        }
                      />
                    </Flex>
                  )
                }
              </AuthUserContext.Consumer>
            </Flex>
          </Flex>
          <Flex
            flexDirection="column"
            width="100%"
            justifyContent="flex-start"
            mt={2}>
            <Flex>
              <Text
                variant="grotText"
                color="black"
                fontSize={[3, 6]}
                maxWidth="100%"
                sx={{
                  textTransform: "uppercase",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap"
                }}>
                {document?.translations[0]?.title ? (
                  document?.translations[0]?.title
                ) : (
                  <Trans i18nKey="documents.documentView.noTitle" />
                )}
              </Text>
            </Flex>
            <Flex
              mt={2}
              fontSize={[2, 5]}
              maxHeight="80px"
              sx={{ overflow: "auto", wordBreak: "break-all" }}>
              <Text color="black">
                {document?.translations[0]?.description ?? (
                  <Trans i18nKey="documents.documentView.noDescription" />
                )}
              </Text>
            </Flex>
          </Flex>
        </>
      )}
    </>
  );

  const closePopUp = () => {
    setIsZoom(false);
    onClose?.();
  };

  return (
    <Popup
      open={open}
      onClose={closePopUp}
      closeOnEscape={true}
      hideCloseButton={true}
      disableNavigation={false}
      transparent={true}
      hideActionButtons={true}
      hideOverflow={isSmallMedium ? true : true}
      contentPadding={isSmallMedium && isZoom ? 0 : 2}
      marginY={isZoom ? 0 : undefined}
      maxWidth="100%"
      maxHeight={isSmallMedium ? "100vh" : undefined}>
      <>
        {document && (
          <>
            <Box mx="auto" width="100%" {...(rest as any)}>
              <Flex>
                <Flex
                  flexDirection="column"
                  width="100%"
                  justifyContent="center"
                  alignItems="center">
                  <Box
                    mb={isZoom ? 0 : "130px"}
                    sx={{
                      flexGrow: 1,
                      position: "relative"
                    }}>
                    {!isSmallMedium ? (
                      <>
                        <Image
                          sx={{
                            cursor: isZoom ? "zoom-out" : "zoom-in",
                            transition: "0.5s",
                            objectFit: "contain",
                            visibility: "hidden"
                          }}
                          src={document.thumbnailLarge}
                          draggable={false}
                          height={isZoom ? "98vh" : "70vh"}
                          onClick={() => setIsZoom(!isZoom)}
                          onLoad={() =>
                            imageLoaded ? null : setImageLoaded(true)
                          }
                        />
                      </>
                    ) : (
                      <Box
                        my={isZoom ? "50px" : ""}
                        width="100%"
                        maxHeight="90vh"
                        sx={{ overflow: "auto" }}>
                        <Image
                          sx={{
                            cursor: isZoom ? "zoom-out" : "zoom-in",
                            visibility: isSmallMedium && isZoom ? "" : "hidden"
                          }}
                          src={document.thumbnailLarge}
                          draggable={false}
                          maxWidth={isZoom ? "none !important" : ""}
                          width="200%"
                          onMouseOver={() => null}
                          onClick={() => setIsZoom(!isZoom)}
                          onLoad={() =>
                            imageLoaded ? null : setImageLoaded(true)
                          }
                        />
                      </Box>
                    )}
                    {documentsList &&
                      documentsList.length > 0 &&
                      (!isZoom || !isSmallMedium) && (
                        <Flex>
                          <Flex
                            height="100%"
                            width="100%"
                            sx={{ position: "absolute", top: "0" }}>
                            <SwiperView
                              height={"100%"}
                              slides={Array.from({
                                length: documentsList.length
                              }).map((val, index) => (
                                <Box sx={{ position: "relative" }}>
                                  {!isSmallMedium ? (
                                    <>
                                      {imageLoaded && (
                                        <Image
                                          sx={{
                                            cursor: isZoom
                                              ? "zoom-out"
                                              : "zoom-in",
                                            transition: "0.5s",
                                            objectFit: "contain",
                                            visibility: imageLoaded
                                              ? undefined
                                              : "hidden"
                                          }}
                                          src={document.thumbnailLarge}
                                          draggable={false}
                                          height={isZoom ? "98vh" : "70vh"}
                                          onClick={() => setIsZoom(!isZoom)}
                                          onLoad={() =>
                                            imageLoaded
                                              ? null
                                              : setImageLoaded(true)
                                          }
                                        />
                                      )}
                                    </>
                                  ) : (
                                    <Box
                                      my={isZoom ? "50px" : ""}
                                      width="100%"
                                      maxHeight="90vh"
                                      sx={{ overflow: "auto" }}>
                                      <Image
                                        sx={{
                                          cursor: isZoom
                                            ? "zoom-out"
                                            : "zoom-in",
                                          visibility: imageLoaded
                                            ? undefined
                                            : "hidden"
                                        }}
                                        src={document.thumbnailLarge}
                                        draggable={false}
                                        maxWidth={
                                          isZoom ? "none !important" : ""
                                        }
                                        width="200%"
                                        onMouseOver={() => null}
                                        onClick={() => setIsZoom(!isZoom)}
                                        onLoad={() =>
                                          imageLoaded
                                            ? null
                                            : setImageLoaded(true)
                                        }
                                      />
                                    </Box>
                                  )}
                                  {imageLoaded && (
                                    <Flex
                                      width="100%"
                                      alignItems="start"
                                      justifyContent="flex-end"
                                      sx={{ zIndex: 1 }}
                                      pr={1}>
                                      <Button
                                        variant="transparent"
                                        bg="fill.0"
                                        color="text.default"
                                        sx={{
                                          position: "absolute",
                                          top: 1
                                        }}
                                        onClick={() => onClose?.()}>
                                        <Icon name="Close" size={24} />
                                      </Button>
                                    </Flex>
                                  )}
                                </Box>
                              ))}
                              onClick={() => setIsZoom(!isZoom)}
                              onSlideChange={swiper => {
                                if (swiper.previousIndex < swiper.realIndex) {
                                  updateDocument("next");
                                } else if (
                                  swiper.previousIndex > swiper.realIndex
                                ) {
                                  updateDocument("back");
                                }
                              }}
                              initialSlide={0}
                              navigation={true}
                            />
                          </Flex>
                        </Flex>
                      )}
                    <Flex
                      sx={{ position: "absolute", zIndex: -1, top: 0 }}
                      width="100%"
                      height="100%"
                      justifyContent="center"
                      alignItems="center">
                      <LoadingFF showIcon={true} hideBar={true} />
                    </Flex>
                    <Box width="100%" sx={{ position: "absolute" }}>
                      <DocumentDetails />
                    </Box>
                  </Box>
                </Flex>
              </Flex>
            </Box>
          </>
        )}
      </>
    </Popup>
  );
};
