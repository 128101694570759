import React, { ComponentType } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { compose } from "recompose";
import AuthUserContext from "./context";
import { withFirebase, Firebase } from "../firebase/index";
import { AuthUser } from "models";
import { checkSameUser, isAdmin, containsRole } from "../utils";

interface Props extends RouteComponentProps {
  firebase: Firebase;
}

interface AuthorizationProps {
  name: string;
  role?: string;
  uid?: string;
}

const withAuthorization = (authProps?: AuthorizationProps) => (
  Component: ComponentType<{ firebase: Firebase }>
) => {
  class WithAuthorization extends React.Component<Props> {
    listener: any;

    componentDidMount() {
      this.listener = this.props.firebase.auth.onAuthStateChanged(
        (authUser: firebase.User | null) => {
          if (
            authUser === null ||
            !this.condition({ uid: authUser.uid, roles: [] })
          ) {
            const { history, location } = this.props;
            const redirect = encodeURIComponent(
              `${location.pathname}${location.search}`
            );
            history.push(`/login?redirect=${redirect}`);
          }
        }
      );
    }

    componentWillUnmount() {
      this.listener();
    }

    condition(authUser: AuthUser, authProps?: AuthorizationProps): boolean {
      if (!authProps) return true;

      if (authProps.name === "isAdmin") {
        return isAdmin(authUser);
      } else if (authProps.name === "containsRole") {
        return containsRole(authProps.role!, authUser);
      } else if (authProps.name === "checkSameUser") {
        return checkSameUser(authProps.uid!, authUser);
      }

      throw new Error(`Condition ${authProps.name} not defined`);
    }

    render() {
      return (
        <AuthUserContext.Consumer>
          {({ authUser }) =>
            authUser && this.condition(authUser, authProps) ? (
              <Component {...this.props} firebase={this.props.firebase} />
            ) : null
          }
        </AuthUserContext.Consumer>
      );
    }
  }

  return compose<Props, any>(withRouter, withFirebase)(WithAuthorization);
};

export default withAuthorization;
