module.exports = {
  name: "staging",
  url: "https://beta.frontfiles.com",
  api: "https://api.frontfiles.waterdog.mobi",
  firebase: {
    apiKey: "AIzaSyAae5lvCGXk-zEfkd7ih08syXCR54Bw7I8",
    authDomain: "frontfiles.firebaseapp.com",
    databaseURL: "https://frontfiles.firebaseio.com",
    projectId: "frontfiles",
    storageBucket: "",
    messagingSenderId: "25133783686",
    appId: "1:25133783686:web:1b85dcabbca81c64888f07",
    measurementId: "G-EL3ZDW9G0E"
  },
  search: {
    searchKey: "search-qwoe4bpncjstuixufmntbrxs",
    engineName: "frontfiles-staging",
    hostIdentifier: "",
    endpointBase:
      "https://49e9a8869142430da17d0ac2cd6a73a2.app-search.europe-west1.gcp.cloud.es.io"
  },
  toast: {
    autoCloseTime: 5000
  },
  festivalEventBucket: 39,
  stripeKey: "pk_live_8AzWVAK5c7c27tX5FmBcal2h00pyIZSEtI",
  paymentProvider: "stripe",
  payoutProvider: "paypal",
  googleMapsKey: "AIzaSyCbXQlheSpcpVeODo7EYcljRHHgCfDERyM",
  featureFlags: {
    donationButtonDisabled: true,
    specialOfferDisabled: true
  }
};
