import React from "react";
import Svg, { SvgProps } from "primitives/Svg";

const SvgMegaphone = ({ width, height, size, color }: SvgProps) => (
  <Svg
    width={size || width}
    height={size || height}
    viewBox="0 0 40 35"
    color={color}>
    <path
      d="M9.172 27.23c-.233 0-.467-.05-.689-.147a1.663 1.663 0 01-.881-.926l-3.146-8.215a1.648 1.648 0 01.037-1.271c.184-.406.516-.715.935-.874l10.906-4.099 4.34 11.322-10.903 4.1a1.68 1.68 0 01-.599.11zm6.452-13.956l-9.753 3.669a.447.447 0 00-.263.573l3.146 8.214a.449.449 0 00.578.26l9.754-3.668-3.462-9.047z"
      stroke="#000"
    />
    <path
      d="M5.144 26.8a2.199 2.199 0 01-2.055-1.407l-1.497-3.912a2.174 2.174 0 011.276-2.807l2.793-1.048 3.06 7.979-2.801 1.052a2.16 2.16 0 01-.776.142zm-.205-7.602l-1.64.618a.958.958 0 00-.562 1.235l1.497 3.912a.972.972 0 001.247.556l1.64-.617-2.182-5.704zM33.343 25.608a.824.824 0 01-.173-.016l-13.736-2.385-4.155-10.83 8.68-10.81a1.014 1.014 0 011.734.268l8.589 22.42a.997.997 0 01-.94 1.353zm-13.01-3.481l12.665 2.198-8.314-21.698-8.002 9.97 3.65 9.53zM14.808 34.411a.826.826 0 01-.739-.45l-4.204-8.178 6.92-2.6.82 2.137a2.497 2.497 0 01-.32 2.372l-.418.573 1.964 3.709c.111.207.127.455.041.674a.836.836 0 01-.484.48l-3.285 1.235a.918.918 0 01-.295.048zm-3.224-7.974L15 33.09l2.531-.95-2.112-3.982.87-1.186a1.27 1.27 0 00.163-1.223l-.381-.995-4.487 1.682zM37.526 10.862l-.436-1.14-3.64 1.369.437 1.139 3.64-1.368zM33.384 4.033l-.888-.844L29.8 5.975l.889.844 2.696-2.786zM38.561 17.56l-3.88-.312-.099 1.215 3.88.312.1-1.215zM14.71 16.98l-.437-1.14-6.024 2.264.437 1.14 6.024-2.265z"
      stroke="#000"
    />
  </Svg>
);

SvgMegaphone.defaultProps = {
  width: 24,
  height: 24
};
export default SvgMegaphone;
