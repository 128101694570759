import React, { Component } from "react";
import { Box, Flex, Text, BoxProps } from "rebass";
import { Input, Label } from "app/shared";
import { Trans } from "react-i18next";

interface Props extends BoxProps {
  name: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string;
  labelI18n?: string;
  disabled?: boolean;
  multiple?: boolean;
  accept?: string;
  height?: string;
}

interface State {
  fileDropInProgress: boolean;
}

export class FileDragInput extends Component<Props, State> {
  static defaultProps = {
    height: "150px"
  };

  constructor(props: Props) {
    super(props);
    this.state = { fileDropInProgress: false };
  }

  render() {
    const {
      name,
      id,
      value,
      labelI18n,
      onChange,
      disabled,
      multiple,
      height,
      accept,
      ...rest
    } = this.props;

    const { fileDropInProgress } = this.state;
    const normalStyle = {
      // Border with custom dashed space
      // https://kovart.github.io/dashed-border-generator/
      backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='black' stroke-width='2' stroke-dasharray='20%2c 20' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
      "& input": { height },
      position: "relative"
    };

    const dropInProgressStyle = {
      // Border with custom dashed space
      // https://kovart.github.io/dashed-border-generator/
      backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='blue' stroke-width='2' stroke-dasharray='20%2c 20' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
      "& input": { height },
      position: "relative"
    };

    return (
      <Box {...(rest as any)}>
        {labelI18n && <Label fontSize={1} i18n={labelI18n} mb={2} />}
        <Box
          height={height}
          sx={fileDropInProgress ? dropInProgressStyle : normalStyle}>
          <Input
            id={id}
            name={name}
            accept={accept}
            type="file"
            value={value}
            onChange={onChange}
            onDragEnter={() => {
              this.setState({ fileDropInProgress: true });
            }}
            onDragLeave={() => {
              this.setState({ fileDropInProgress: false });
            }}
            onDrop={() => this.setState({ fileDropInProgress: false })}
            disabled={disabled}
            multiple={multiple}
            width="100%"
            height="100%"
            opacity={0}
            sx={{ position: "absolute", zIndex: 1 }}
          />
          <Flex
            p={3}
            width="100%"
            height="100%"
            justifyContent="center"
            alignItems="center"
            sx={{ position: "absolute", zIndex: 0 }}>
            <Text fontSize={0} variant="grotTextCaps" textAlign="center">
              {fileDropInProgress ? (
                <Trans i18nKey="shared.input.fileInput.dropInProgress" />
              ) : (
                <Trans i18nKey="shared.input.fileInput.label" />
              )}
            </Text>
          </Flex>
        </Box>
      </Box>
    );
  }
}
