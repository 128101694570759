import React from "react";
import Svg, { SvgProps } from "primitives/Svg";
const SvgBigArrowRight = ({ width, height, size, color }: SvgProps) => (
  <Svg
    width={size || width}
    height={size || height}
    viewBox="0 0 290 16"
    color={color}>
    <path d="M289.707 8.707a1 1 0 000-1.414L283.343.929a1 1 0 10-1.414 1.414L287.586 8l-5.657 5.657a1 1 0 101.414 1.414l6.364-6.364zM0 9h289V7H0v2z" />
  </Svg>
);
SvgBigArrowRight.defaultProps = {
  width: 24,
  height: 24
};
export default SvgBigArrowRight;
