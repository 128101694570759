import { SearchProvider, WithSearch } from "@elastic/react-search-ui";
import AppSearchAPIConnector from "@elastic/search-ui-app-search-connector";
import { CollectionsView, CollectionView } from "app/collections";
import { SearchFilter } from "app/search/models";
import environment from "configurations";
import { Collection } from "models";
import React, { Component, ReactNode } from "react";
import { Trans } from "react-i18next";
import { CachedPagination, PageEmptyState } from "app/shared";
import { mapCollectionResults } from "../utils/mapResults";

interface Props {
  filters: SearchFilter[];
  sortField: string;
  sortDirection: "asc" | "desc";
  resultsPerPage: number;
  maxResults?: number;
  view?: (collections: Collection[]) => React.ReactElement;
  scrollListenerEnabled?: boolean;
  noResultsView?: ReactNode;
}

interface WithSearchProps {
  wasSearched: boolean;
  results: any[];
  totalPages: number;
  setCurrent: (page: number) => void;
  current: number;
  isLoading: boolean;
}

/**
 * When maxResults > resultsPerPage results are wrap with Pagination that will search for more results in page bottom scroll.
 */
export class CollectionsListSearch extends Component<Props> {
  static defaultProps = {
    filters: [],
    resultsPerPage: 20,
    sortField: "id",
    sortDirection: "desc"
  };

  /**
   * Use view from props or use the default.
   */
  view(collections: Collection[]) {
    const { view } = this.props;

    if (view) {
      return view(collections);
    } else {
      return (
        <CollectionsView>
          {collections.map(collection => (
            <CollectionView key={collection.id} collection={collection} />
          ))}
        </CollectionsView>
      );
    }
  }

  render() {
    const {
      filters,
      maxResults,
      resultsPerPage,
      scrollListenerEnabled,
      sortField,
      sortDirection,
      noResultsView
    } = this.props;

    return (
      <SearchProvider
        config={{
          apiConnector: new AppSearchAPIConnector(environment.search),
          alwaysSearchOnInitialLoad: true,
          trackUrlState: false,
          initialState: {
            resultsPerPage
          },
          searchQuery: {
            filters: [
              { field: "class_name", values: ["collection"], type: "any" },
              { field: "total_documents", values: ["0"], type: "none" },
              // this component should only show collections
              ...filters
            ],
            sort: { [sortField]: sortDirection },
            result_fields: {
              real_id: { raw: {} },
              uid: { raw: {} },
              owner: { raw: {} },
              title: { raw: {} },
              description: { raw: {} },
              total_documents: { raw: {} },
              documents: { raw: {} },
              created_at: { raw: {} },
              privacy: { raw: {} },
              city: { raw: {} },
              country: { raw: {} }
            }
          }
        }}>
        <WithSearch
          mapContextToProps={({
            wasSearched,
            results,
            totalPages,
            setCurrent,
            current,
            isLoading
          }: WithSearchProps) => ({
            wasSearched,
            results,
            totalPages,
            setCurrent,
            current,
            isLoading
          })}>
          {({
            wasSearched,
            results,
            totalPages,
            setCurrent,
            current,
            isLoading
          }: WithSearchProps) => {
            if (results.length) {
              const collections: Collection[] = mapCollectionResults(results);

              // Wrap results around Pagination if maxResults > resultsPerPage
              if (maxResults === undefined || maxResults > resultsPerPage) {
                return (
                  <CachedPagination<Collection>
                    disabled={isLoading}
                    scrollListenerEnabled={scrollListenerEnabled}
                    firstPageNumber={1}
                    results={collections}
                    page={current}
                    totalPages={totalPages}
                    setPage={setCurrent}>
                    {collections => this.view(collections)}
                  </CachedPagination>
                );
              } else {
                return this.view(collections);
              }
            } else {
              return noResultsView ? (
                noResultsView
              ) : (
                <PageEmptyState
                  loading={!wasSearched || isLoading}
                  header={
                    <Trans i18nKey="search.collections.collectionsListSearch.noCollections" />
                  }
                />
              );
            }
          }}
        </WithSearch>
      </SearchProvider>
    );
  }
}
