import { Input as RebassInput } from "@rebass/forms";
import { TOptions } from "i18next";
import React, { useState } from "react";
import { Trans } from "react-i18next";
import { Box, BoxProps, Text, Flex } from "rebass";
import { Label } from "../label";
import { Tooltip, Button, Icon } from "app/shared";

interface Props extends BoxProps {
  name: string;
  type: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  errorI18n?: string;
  errorI18nOptions?: TOptions;
  labelI18n?: string;
  labelAlignment?: "left" | "center" | "right";
  value?: string;
  placeholder?: string;
  disabled?: boolean;
  multiple?: boolean;
  infoI18n?: string;
  variant?: string;
  labelFontSize?: string | number | string[] | number[];
}

export const Input: React.FC<Props> = ({
  name,
  type,
  onChange,
  errorI18n,
  errorI18nOptions,
  labelI18n,
  value,
  placeholder,
  disabled,
  multiple,
  variant,
  infoI18n,
  labelAlignment,
  labelFontSize,
  ...rest
}) => {
  const [showText, setShowText] = useState(false);
  return (
    <Box {...(rest as any)}>
      {(infoI18n || labelI18n) && (
        <Flex alignItems="center" pb={1}>
          {labelI18n && (
            <Label
              i18n={labelI18n}
              fontSize={labelFontSize ?? [1, 4]}
              pb={0}
              sx={{ textAlign: labelAlignment }}
            />
          )}
          {infoI18n && <Tooltip i18nKey={infoI18n} ml={1} />}
        </Flex>
      )}
      {errorI18n && (
        <Text color="text.danger">
          <Trans i18nKey={errorI18n} tOptions={errorI18nOptions} />
        </Text>
      )}
      <Box sx={{ position: "relative" }}>
        <RebassInput
          {...(rest as any)}
          q
          id={`${name}-${Math.random()
            .toString(36)
            .substring(7)}`} // Appending random string at the end so ids are unique
          name={name}
          type={showText ? "text" : type}
          onChange={onChange}
          placeholder={placeholder}
          value={value}
          disabled={disabled}
          multiple={multiple}
          variant={variant}
          sx={{ cursor: type === "file" ? "pointer" : "text" }}
        />
        {type === "password" && (
          <Button
            variant="transparent"
            onClick={() => setShowText(!showText)}
            sx={{
              position: "absolute",
              top: "10px",
              right: 3
            }}>
            <Icon name="Eye" size={20} />
          </Button>
        )}
      </Box>
    </Box>
  );
};

Input.defaultProps = {
  variant: "input"
};
