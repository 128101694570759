import React from "react";
import Svg, { SvgProps } from "primitives/Svg";

const SvgFacebook = ({ width, height, size, color }: SvgProps) => (
  <Svg
    width={size || width}
    height={size || height}
    viewBox="0 0 49 49"
    color={color}>
    <g clipPath="url(#Facebook_svg__clip0_9841_156693)">
      <path d="M45.863.398H3.165a2.649 2.649 0 00-2.65 2.65v42.699a2.65 2.65 0 002.65 2.65h22.987V29.81h-6.254v-7.246h6.254v-5.342c0-6.199 3.785-9.576 9.316-9.576 2.652 0 4.925.198 5.588.284v6.48l-3.836.001c-3.007 0-3.587 1.43-3.587 3.527v4.624h7.174l-.938 7.244h-6.237v18.59h12.23a2.652 2.652 0 002.652-2.65v-42.7a2.65 2.65 0 00-2.65-2.648z" />
      <path d="M.516.398h48v7.273h-48z" />
      <path d="M.515 48.398v-48h7.5v48zM41.015 48.398v-48h7.5v48z" />
    </g>
    <defs>
      <clipPath id="Facebook_svg__clip0_9841_156693">
        <path transform="translate(.516 .398)" d="M0 0h48v48H0z" />
      </clipPath>
    </defs>
  </Svg>
);

SvgFacebook.defaultProps = {
  width: 24,
  height: 24
};
export default SvgFacebook;
