import React, { ComponentType } from "react";
import Firebase from "./firebase";

const FirebaseContext = React.createContext<Firebase | null>(null);

export const withFirebase = (
  Component: ComponentType<{ firebase: Firebase }>
) => (props: any) => (
  <FirebaseContext.Consumer>
    {firebase => <Component {...props} firebase={firebase} />}
  </FirebaseContext.Consumer>
);

export default FirebaseContext;
