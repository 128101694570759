import React from "react";
import Svg, { SvgProps } from "primitives/Svg";

const SvgUpload = ({ width, height, size, color }: SvgProps) => (
  <Svg
    width={size || width}
    height={size || height}
    viewBox="0 0 24 24"
    color={color}>
    <path d="M20 12v6H4v-6H3v7h18v-7h-1z" />
    <path d="M12.5 6.01h-1v10h1v-10z" />
    <path d="M12 6.41L14.62 9l.69-.69L12 5 8.69 8.31l.69.69L12 6.41z" />
  </Svg>
);

SvgUpload.defaultProps = {
  width: 24,
  height: 24
};
export default SvgUpload;
