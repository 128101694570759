import { BackButton, Button, Icon, Image, Metadata } from "app/shared";
import { Firebase, withFirebase } from "authentication/firebase";
import { AuthUserContext } from "authentication/session";
import { checkSameUser } from "authentication/utils";
import { ContentArea } from "layout";
import { AvailableLicenseType } from "marketplace";
import { Document, User } from "models";
import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Box, Flex } from "rebass";
import { compose } from "recompose";
import { DocumentInfo } from "../models";
import {
  getAvailableLicenses,
  getDocument,
  getDocumentInfo
} from "../services";
import DocumentData from "./DocumentData";
import DocumentMoreFromBlocks from "./DocumentMoreFromBlocks";
import DocumentUserInfo from "./DocumentUserInfo";
import { getUserProfile } from "app/account/services";
import { LikeButton } from "./LikeButton";
import { DocumentShareButton } from "./DocumentShareButton";
import { BucketAddItemsButton } from "app/buckets";
import DocumentLicensesProposals from "./DocumentLicensesProposals";
import { Trans } from "react-i18next";

interface Params {
  id: string;
}

interface Props extends RouteComponentProps<Params> {
  firebase: Firebase;
}

interface State {
  document?: Document;
  info?: DocumentInfo;
  documentUser?: User;
  // TODO: Move this to store?
  availableLicenseTypes: AvailableLicenseType[];
}

export class DocumentDetailsBase extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      document: undefined,
      info: undefined,
      availableLicenseTypes: []
    };
  }

  async componentDidMount() {
    this.getDocument();
  }

  async getDocument() {
    const { firebase, history, match } = this.props;
    const id = parseInt(match.params.id, 10);

    // Await for all so that component is only rendered in the end.
    // Document info will only be asked for logged users.
    const document = await getDocument(id).catch(err => {
      if (err.response.status === 404) {
        history.push("/not-found"); // redirect to not found page
      }
      throw err;
    });
    const documentUser = await getUserProfile(document.uid);
    const availableLicenseTypes = await getAvailableLicenses();

    if (firebase.isLogged()) {
      //const info = await getDocumentInfo(id);
      this.setState({ document, documentUser, availableLicenseTypes });
    } else {
      this.setState({ document, documentUser, availableLicenseTypes });
    }
  }

  /**
   * Force document update when clicked in another document id in the same page.
   */
  componentDidUpdate(prevProps: Props) {
    if (this.props.match.params.id !== prevProps.match.params.id) {
      this.getDocument();
    }
  }

  render() {
    const { document, documentUser, info, availableLicenseTypes } = this.state;

    // const DocumentAction = () => (
    //   <>
    //   {document && documentUser && &&(
    //   <DocumentActions
    //             actions={[
    //               <DocumentFullScreen src={document.thumbnailLarge} />,
    //               <AuthUserContext.Consumer>
    //                 {({ authUser }) => (
    //                   <ElementOptions
    //                     closeMenuOnClick={false}
    //                     options={
    //                       authUser && checkSameUser(document.uid, authUser)
    //                         ? [
    //                             () => (
    //                               <EditButton
    //                                 href={`/documents/${document.id}/edit`}
    //                                 sx={{
    //                                   border: 0,
    //                                   textAlign: "right"
    //                                 }}
    //                               />
    //                             ),
    //                             () => (
    //                               <DownloadButton
    //                                 documentId={document.id}
    //                                 sx={{
    //                                   border: 0,
    //                                   textAlign: "right"
    //                                 }}
    //                               />
    //                             ),
    //                             () => (
    //                               <CopyrightButton
    //                                 documentId={document.id}
    //                                 sx={{
    //                                   border: 0,
    //                                   textAlign: "right"
    //                                 }}
    //                               />
    //                             ),
    //                             () => (
    //                               <CopyLinkButton
    //                                 sx={{ border: 0, textAlign: "right" }}
    //                               />
    //                             )
    //                           ]
    //                         : [
    //                             () => (
    //                               <ReportPhotoButton
    //                                 documentId={document.id}
    //                                 sx={{
    //                                   border: 0,
    //                                   textAlign: "right",
    //                                   color: "red"
    //                                 }}
    //                               />
    //                             ),
    //                             () => (
    //                               <CopyLinkButton
    //                                 sx={{ border: 0, textAlign: "right" }}
    //                               />
    //                             )
    //                           ]
    //                     }
    //                     elementId={document.id}
    //                     iconName="MoreOptions"
    //                     iconSize={24}
    //                     optionsWidth={["120px", "179px"]}
    //                     backgroundAlwaysActive={false}
    //                     sx={{ padding: 0 }}
    //                     popupSx={{
    //                       position: "absolute",
    //                       top: "49px",
    //                       left: ["-96px", "-154px"]
    //                     }}
    //                   />
    //                 )}
    //               </AuthUserContext.Consumer>
    //             ]}
    //           />
    //           )}
    //           </>
    // )

    return (
      <>
        <ContentArea py={8}>
          {document && (
            <>
              <BackButton />
              <Flex justifyContent="center" alignItems="center" my={7}>
                <Box px={[2, 0]}>
                  {/* alignSelf="flex-start" fixes height scretch in Safari: https://stackoverflow.com/a/61196907 */}
                  <Image
                    src={document.thumbnailLarge}
                    maxHeight="80vh"
                    draggable={false}
                    alignSelf="flex-start"
                  />
                  <Metadata image={document.thumbnailLarge} />
                  <Flex alignItems="center" justifyContent="space-between">
                    <Flex>
                      <AuthUserContext.Consumer>
                        {({ authUser }) =>
                          authUser &&
                          checkSameUser(document.uid, authUser) && (
                            <Button
                              p={2}
                              href={`/documents/${document.id}/edit`}
                              variant="link.button.black">
                              <Flex alignItems="center">
                                <Flex pr={1}>
                                  <Icon name="Pencil" size={18} />
                                </Flex>
                                <Flex>
                                  <Trans i18nKey="documents.documentDetails.editButton" />
                                </Flex>
                              </Flex>
                            </Button>
                          )
                        }
                      </AuthUserContext.Consumer>
                    </Flex>
                    <Flex>
                      <AuthUserContext.Consumer>
                        {({ authUser }) =>
                          authUser && (
                            <Flex pr={5}>
                              <BucketAddItemsButton
                                bucketItemIds={[
                                  { type: "document", id: document.id }
                                ]}
                                variant="icon"
                              />
                            </Flex>
                          )
                        }
                      </AuthUserContext.Consumer>
                      <Flex>
                        <DocumentShareButton
                          name="Share"
                          variant="transparent"
                          display="block"
                          document={document}
                        />
                      </Flex>
                      <AuthUserContext.Consumer>
                        {({ authUser }) =>
                          authUser &&
                          document.owner &&
                          !checkSameUser(document.owner?.id, authUser) && (
                            <Flex pl={5}>
                              <LikeButton
                                name="Like"
                                variant="icon"
                                display="block"
                                documentId={document.id}
                                documentInfo={info}
                                onInfoChange={info =>
                                  this.setState({ info: info as DocumentInfo })
                                }
                              />
                            </Flex>
                          )
                        }
                      </AuthUserContext.Consumer>
                    </Flex>
                  </Flex>
                </Box>
              </Flex>
            </>
          )}
          {document && documentUser && (
            <>
              <Flex flexDirection="column" justifyContent="space-between">
                <DocumentData document={document} />
                <DocumentLicensesProposals
                  document={document}
                  documentUser={documentUser}
                  availableLicenseTypes={availableLicenseTypes}
                />
                <DocumentUserInfo user={documentUser} photoBy={true} />
              </Flex>
              <DocumentMoreFromBlocks document={document} />
            </>
          )}
        </ContentArea>
      </>
    );
  }
}

export const DocumentDetails = compose<Props, any>(
  withRouter,
  withFirebase
)(DocumentDetailsBase);
