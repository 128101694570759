import { CollectionView } from "app/collections";
import { getCollection } from "app/collections/services";
import environment from "configurations";
import { Collection } from "models";
import React, { useEffect, useState } from "react";
import { Box, Flex } from "rebass";

interface Props {
  id: number;
  marginTop?: number | number[];
  marginBottom?: number | number[];
  height?: string | string[];
  variant?: string;
}

const CollectionParser: React.FC<Props> = ({
  id,
  marginTop,
  marginBottom,
  height,
  variant
}) => {
  const [collection, setCollection] = useState<Collection>();
  useEffect(() => {
    const fetchData = async () => {
      setCollection(
        await getCollection(id).then(collection => {
          return {
            ...collection,
            owner: {
              ...collection.owner,
              picture:
                environment.url + "/users/" + collection.owner.id + "/picture"
            }
          };
        })
      );
    };
    fetchData();
  }, [id]);
  if (!collection) return null;

  return (
    <Flex justifyContent="center" alignItems="center" flexDirection="column">
      <Box width={["100%", "50%"]}>
        <CollectionView
          collection={collection}
          marginTop={marginTop}
          marginBottom={marginBottom}
          height={height}
          variant={variant}
        />
      </Box>
    </Flex>
  );
};

CollectionParser.defaultProps = {
  marginTop: 0,
  marginBottom: 0,
  height: ["270px", "450px"]
};

export default CollectionParser;
