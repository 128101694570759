import { Popup, Toast } from "app/shared";
import React, { Component } from "react";
import {
  StripeProvider,
  Elements,
  CardElement,
  injectStripe
} from "react-stripe-elements";
import environment from "configurations";
import i18next from "i18next";

interface PropsExternal {
  trigger: any;
  disabled: boolean;
  onComplete: (cardToken: string, cardLast4: string, cardBrand: string) => void;
}

interface Props extends PropsExternal {
  stripe: any;
}

interface State {
  disabled: boolean;
}

class CardPaymentPopupForm extends Component<Props, State> {
  state = {
    disabled: false
  };

  async handleSubmit(close: () => void) {
    const { stripe, onComplete } = this.props;

    this.setState({ disabled: true }, async () => {
      const { token, error } = await stripe.createToken();

      if (token && token.id) {
        this.setState({ disabled: false }, () => {
          onComplete(token.id, token.card.last4, token.card.brand);
          close();
        });
      } else {
        Toast.error({ title: error.message });
        this.setState({ disabled: false });
      }
    });
  }

  render() {
    const { trigger } = this.props;

    return (
      <Popup
        trigger={trigger}
        heading={i18next.t("documents.cardPaymentPopup.card")}
        submit={close => this.handleSubmit(close)}
        submitDisabled={this.state.disabled}
        cancelDisabled={this.state.disabled}
        disabled={this.props.disabled}>
        <>
          <CardElement />
        </>
      </Popup>
    );
  }
}

const InjectedCardPaymentPopupForm = injectStripe(CardPaymentPopupForm as any);

export const CardPaymentPopup: React.FC<PropsExternal> = props => {
  return (
    <StripeProvider apiKey={environment.stripeKey}>
      <Elements>
        <InjectedCardPaymentPopupForm {...props} />
      </Elements>
    </StripeProvider>
  );
};
