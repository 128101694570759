import React from "react";
import Svg, { SvgProps } from "primitives/Svg";

const SvgArrowDown2 = ({ width, height, size, color, rotate }: SvgProps) => (
  <Svg
    width={size || width}
    height={size || height}
    viewBox="0 0 58 59"
    color={color}>
    <path
      d="M34.204 38.122V.872H22.859v37.25L7.403 24.001.031 33.164 28.53 59 57.03 33.164 49.66 24 34.204 38.122z"
      transform={rotate ? `rotate(${rotate}, 29, 29)` : ""}
    />
  </Svg>
);

SvgArrowDown2.defaultProps = {
  width: 24,
  height: 24
};
export default SvgArrowDown2;
