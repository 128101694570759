import React from "react";
import { AuthUser } from "models";

export interface AuthUserContextProps {
  authUser?: AuthUser;
  authLoaded: boolean;
}

const AuthUserContext = React.createContext<AuthUserContextProps>({
  authUser: undefined,
  authLoaded: false
});

export default AuthUserContext;
