import { PageParser } from "app/pages";
import { Input, Toast } from "app/shared";
import { Firebase, withFirebase } from "authentication/firebase";
import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { compose } from "recompose";
import AuthLayout from "./AuthLayout";
import RedirectButton from "./RedirectButton";
import AuthButton from "./AuthButton";
import queryString from "query-string";
import { Box, Flex, Text } from "rebass";
import i18next from "i18next";
import { Trans } from "react-i18next";

interface PropsExternal {
  backgroundImage?: string;
  backgroundImageAuthor?: string;
}

interface Props extends PropsExternal, RouteComponentProps {
  firebase: Firebase;
}

interface State {
  email: string;
  submitted: boolean;
  redirect?: string;
}

class RecoverPasswordBase extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      email: "",
      submitted: false,
      redirect: queryString
        .parse(props.location.search, { decode: false })
        ?.["redirect"]?.toString()
    };
  }

  handleOnSubmit = () => {
    const { firebase } = this.props;
    const { email } = this.state;

    firebase
      .doPasswordReset(email)
      .then(() => this.setState({ submitted: true }))
      .catch(error => {
        Toast.apiError(error);
      });
  };

  handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState<never>({ [event.target.name]: event.target.value });
  };

  render() {
    const { backgroundImage, backgroundImageAuthor } = this.props;
    const { email, submitted, redirect } = this.state;
    const isInvalid = email === "";

    return (
      <AuthLayout
        backgroundImage={backgroundImage}
        backgroundImageAuthor={backgroundImageAuthor}
        backgroundImageAuthorPosition="top"
        headerI18n="account.authentication.recoverPassword.label"
        headerFontSize={[7, 8, 10]}
        mainSection={
          <Flex width="100%" flexDirection="column" sx={{ flexGrow: 1 }}>
            <Input
              name="email"
              type="email"
              labelI18n="account.authentication.recoverPassword.email"
              labelFontSize={2}
              placeholder={i18next.t(
                "account.authentication.recoverPassword.emailPlaceholder"
              )}
              onChange={this.handleOnChange}
              value={email}
              mb={2}
            />
            <Text fontSize={[0, 1]} variant="grotTextCaps" mb={[3, 6]}>
              <Trans i18nKey="account.authentication.recoverPassword.instructionsInfo" />
            </Text>
            {!submitted ? (
              <AuthButton
                onClick={() => this.handleOnSubmit()}
                variant="black"
                disabled={isInvalid}
                i18nKey="account.authentication.recoverPassword.recover"
                mb={[2, 3]}
              />
            ) : (
              <AuthButton
                variant="whiteBlue"
                i18nKey="account.authentication.recoverPassword.instructionsSent"
                mb={[2, 3]}
              />
            )}
            <Box sx={{ flexGrow: 1 }} />
            <RedirectButton
              i18nKey="account.authentication.recoverPassword.returnLogin"
              href={`/login${redirect ? `?redirect=${redirect}` : ""}`}
              variant="link.button.brand"
            />
          </Flex>
        }
      />
    );
  }
}

const RecoverPasswordCompose = compose<Props, PropsExternal>(
  withRouter,
  withFirebase
)(RecoverPasswordBase);

/**
 * This components overrides <Random /> component and uses props.values to calculate a random image.
 * Note that only <Random /> component is being implemented, so other components defined in the backoffice will be ignored.
 * We are using a container to wrap <RecoverPassword /> to prevent rendering <PageParser /> again that would result in another random image.
 */
export const RecoverPassword = () => (
  <PageParser
    pageId={3}
    components={{
      Random: (props: any) => {
        const randomValue =
          props.values?.[Math.floor(Math.random() * props.values?.length)] ??
          undefined;

        return (
          <RecoverPasswordCompose
            backgroundImage={randomValue?.photo}
            backgroundImageAuthor={randomValue?.author}
          />
        );
      }
    }}
    placeholder={<RecoverPasswordCompose />}
  />
);
