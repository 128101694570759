import React, { useState } from "react";
import { Trans } from "react-i18next";
import { Box, Flex, Text } from "rebass";
import { ContentArea } from "layout";
import { Section } from "./Section";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { compose } from "recompose";
import { Img, Paragraph } from "i18n/components";
import {
  Button,
  FullWidthContainer,
  Icon,
  TextLink,
  Image,
  Separator
} from "app/shared";
import Team1 from "./img/team/Barbara.png";
import Team4 from "./img/team/Joao.png";
import Team7 from "./img/team/PedroC.png";
import Team8 from "./img/team/PedroH.png";
import Team10 from "./img/team/Shawkan.png";
import Team11 from "./img/team/Susana.png";
import Team13 from "./img/team/Victor.png";
import { useMediaQueryContext } from "styles/context";
import styled from "@emotion/styled";
import { CollapsableSection } from "./CollapsableSection";
import i18n from "i18n/i18n";
import logo from "app/shared/siteImageButton/static/Logo.svg";
import { SocialLinks } from "./SocialLinks";
import { HOW_IT_WORKS_ANCHOR_ID, HowItWorks } from "./HowItWorks";

type Props = RouteComponentProps;

const lineThrough = {
  position: "relative",
  ":after": {
    content: '""',
    position: "absolute",
    width: "100%",
    left: 0,
    top: "47%",
    border: ["1px solid blue", "3px solid blue"],
    zIndex: 1
  }
};

enum NavigationTab {
  About,
  HowItWorks
}

interface NewlineTextProps {
  text: string;
}

const NewlineText: React.FC<NewlineTextProps> = ({ text }) => {
  const newText = text.split("\n").map((str, index, array) => (
    <React.Fragment key={index}>
      {str}
      {index === array.length - 1 ? null : <br />}
    </React.Fragment>
  ));

  return <>{newText}</>;
};

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(418px, 1fr));
  grid-gap: 20px;
`;

const BlueText = styled.span`
  color: blue;
`;

const HighlightText = styled.span`
  color: blue;
  text-decoration: underline;
`;

const Faq: React.FC = () => {
  return (
    <>
      <Box width="100%" pb={[4, 8]}>
        <Text color="blue" fontSize={[8, 15]} sx={{ textAlign: "center" }}>
          <Trans i18nKey="about.faq.faqHeader" />
        </Text>
      </Box>
      <Section variant="white" titleI18n="about.faq.faq1Title" id="faqs" px={0}>
        <Text fontSize={[4, 5, 6]}>
          <Trans i18nKey="about.faq.faq1Content" />
        </Text>
      </Section>
      <Section variant="white" titleI18n="about.faq.faq2Title" px={0}>
        <Text fontSize={[4, 5, 6]}>
          <Trans i18nKey="about.faq.faq2Content" />
        </Text>
      </Section>
      <Section
        variant="white"
        titleI18n="about.faq.faq3Title"
        id="licenses"
        px={0}>
        <Flex
          flexWrap="wrap"
          justifyContent="center"
          sx={{ flexGrow: 1 }}
          pb={[4, 8]}>
          <Box
            pb={[4, 8]}
            sx={{ border: "1px solid black" }}
            width={["338px", "438px"]}>
            <Box bg="blue" py="4">
              <Text
                sx={{ textAlign: "center" }}
                color="white"
                variant="grotText"
                fontSize={6}>
                <Trans i18nKey={"about.faq.license1"} />
              </Text>
            </Box>
            <Box pl={2}>
              <Text color="blue" pt={3} variant="grotText" fontSize={6}>
                <Trans i18nKey={"about.faq.license1Title"} />
              </Text>
              <Text fontSize={1} pt={3} pr={2} variant="grotTextCaps">
                <Trans i18nKey={"about.faq.license1description"} />
              </Text>
            </Box>
          </Box>
          <Box
            pb={[4, 8]}
            sx={{ border: "1px solid black" }}
            width={["338px", "438px"]}
            mt={[4, 0]}
            ml={[0, 4]}>
            <Box bg="blue" py="4">
              <Text
                sx={{ textAlign: "center" }}
                color="white"
                variant="grotText"
                fontSize={6}>
                <Trans i18nKey={"about.faq.license2"} />
              </Text>
            </Box>
            <Box pl={2}>
              <Text color="blue" pt={3} variant="grotText" fontSize={6}>
                <Trans i18nKey={"about.faq.license2Title"} />
              </Text>
              <Text
                fontSize={1}
                pt={3}
                pr={2}
                sx={{ wordBreak: "break-word" }}
                variant="grotTextCaps">
                <Trans i18nKey={"about.faq.license2description"} />
              </Text>
            </Box>
          </Box>
        </Flex>
      </Section>
      <Section variant="white" titleI18n="about.faq.faq4Title" px={0}>
        <Text fontSize={[4, 5, 6]}>
          <Trans i18nKey="about.faq.faq4Content" />
        </Text>
      </Section>
      <Section variant="white" titleI18n="about.faq.faq5Title" px={0}>
        <Text fontSize={[4, 5, 6]}>
          <Trans i18nKey="about.faq.faq5Content" />
        </Text>
      </Section>
      <Section variant="white" titleI18n="about.faq.faq6Title" px={0}>
        <Text fontSize={[4, 5, 6]}>
          <Trans i18nKey="about.faq.faq6Content" />
        </Text>
      </Section>
      <Section variant="white" titleI18n="about.faq.faq7Title" px={0}>
        <Text fontSize={[4, 5, 6]}>
          <Trans i18nKey="about.faq.faq7Content" />
        </Text>
      </Section>
      <Section variant="white" titleI18n="about.faq.faq8Title" px={0}>
        <Text fontSize={[4, 5, 6]}>
          <Trans i18nKey="about.faq.faq8Content" />
        </Text>
      </Section>
      <Section variant="white" titleI18n="about.faq.faq9Title" px={0}>
        <Text fontSize={[4, 5, 6]}>
          <Trans i18nKey="about.faq.faq9Content" />
        </Text>
      </Section>
      <Section variant="white" titleI18n="about.faq.faq10Title" px={0}>
        <Text fontSize={[4, 5, 6]}>
          <Trans i18nKey="about.faq.faq10Content" />
        </Text>
      </Section>
      <Section variant="white" titleI18n="about.faq.faq11Title" px={0}>
        <Text fontSize={[4, 5, 6]}>
          <Trans i18nKey="about.faq.faq11Content" />
        </Text>
      </Section>
      <Section variant="white" titleI18n="about.faq.faq12Title" px={0}>
        <Text fontSize={[4, 5, 6]}>
          <Trans i18nKey="about.faq.faq12Content" />
        </Text>
      </Section>
      <Section variant="white" titleI18n="about.faq.faq13Title" px={0}>
        <Text fontSize={[4, 5, 6]}>
          <Trans i18nKey="about.faq.faq13Content" />
        </Text>
      </Section>
      <Section variant="white" titleI18n="about.faq.faq14Title" px={0}>
        <Text fontSize={[4, 5, 6]}>
          <Trans i18nKey="about.faq.faq14Content" />
        </Text>
      </Section>
      <Section variant="white" titleI18n="about.faq.faq15Title" px={0}>
        <Text fontSize={[4, 5, 6]}>
          <Trans i18nKey="about.faq.faq15Content" />
        </Text>
      </Section>
      <Section variant="white" titleI18n="about.faq.faq16Title" px={0}>
        <Text fontSize={[4, 5, 6]}>
          <Trans i18nKey="about.faq.faq16Content" />
        </Text>
      </Section>
      <Section variant="white" titleI18n="about.faq.faq17Title" px={0}>
        <Text fontSize={[4, 5, 6]}>
          <Trans i18nKey="about.faq.faq17Content" />
        </Text>
      </Section>
      <Section variant="white" titleI18n="about.faq.faq18Title" px={0}>
        <Text fontSize={[4, 5, 6]}>
          <Trans i18nKey="about.faq.faq18Content" />
        </Text>
      </Section>
      <Section variant="white" titleI18n="about.faq.faq19Title" px={0}>
        <Text fontSize={[4, 5, 6]}>
          <Trans i18nKey="about.faq.faq19Content" />
        </Text>
      </Section>
    </>
  );
};

interface PropsMemberCard {
  imageUrl: string;
  name: string;
  location: string;
  role: string;
}

const MemberCard: React.FC<PropsMemberCard> = props => {
  return (
    <>
      <Flex sx={{ border: "1px solid black" }} width="418px" height="170px">
        <Box width="170px" height="170px" style={{ position: "relative" }}>
          <Img src={props.imageUrl} width="100%" height="99%" />
          <Box
            sx={{
              "& svg path": { fill: "blue" },
              position: "absolute",
              top: 0,
              right: 0,
              zIndex: 1
            }}>
            <Icon name="UserVerifiedIcon" size={32} />
          </Box>
        </Box>
        <Flex
          width="248px"
          pl={2}
          flexDirection="column"
          justifyContent="space-between"
          height="100%">
          <Text
            py={2}
            variant="grotText"
            fontSize={6}
            sx={{ textTransform: "uppercase" }}>
            <Trans i18nKey={props.name} />
          </Text>
          <Text
            py={2}
            variant="grotTextCaps"
            fontSize={0}
            sx={{ textTransform: "uppercase" }}>
            <Trans i18nKey={props.location} />
          </Text>
          <Text py={2} variant="grotText" fontSize={6}>
            <Trans i18nKey={props.role} />
          </Text>
        </Flex>
      </Flex>
    </>
  );
};

const Team: React.FC = () => {
  const team = [
    {
      name: "about.header.team.barbara.name",
      city: "about.header.team.barbara.city",
      job: "about.header.team.barbara.job",
      description: "about.header.team.barbara.description",
      image: Team1
    },
    {
      name: "about.header.team.joao.name",
      city: "about.header.team.joao.city",
      job: "about.header.team.joao.job",
      description: "about.header.team.joao.description",
      image: Team4
    },
    {
      name: "about.header.team.pedroC.name",
      city: "about.header.team.pedroC.city",
      job: "about.header.team.pedroC.job",
      description: "about.header.team.pedroC.description",
      image: Team7
    },
    {
      name: "about.header.team.pedroH.name",
      city: "about.header.team.pedroH.city",
      job: "about.header.team.pedroH.job",
      description: "about.header.team.pedroH.description",
      image: Team8
    },
    {
      name: "about.header.team.shawkan.name",
      city: "about.header.team.shawkan.city",
      job: "about.header.team.shawkan.job",
      description: "about.header.team.shawkan.description",
      image: Team10
    },
    {
      name: "about.header.team.susana.name",
      city: "about.header.team.susana.city",
      job: "about.header.team.susana.job",
      description: "about.header.team.susana.description",
      image: Team11
    },
    {
      name: "about.header.team.victor.name",
      city: "about.header.team.victor.city",
      job: "about.header.team.victor.job",
      description: "about.header.team.victor.description",
      image: Team13
    }
  ];

  return (
    <>
      <Text color="blue" fontSize={[8, 16]} mb={[4, 9]}>
        <Trans i18nKey="about.header.team.headerTitle" />
      </Text>
      <GridContainer>
        {team.map((item, index) => (
          <MemberCard
            key={index}
            imageUrl={item.image}
            location={item.city}
            name={item.name}
            role={item.job}
          />
        ))}
      </GridContainer>
    </>
  );
};

const NoMore: React.FC = () => {
  return (
    <>
      <Box pl={[2, 0]} sx={{ textAlign: "center" }} pb={[4, 8]} mt={[5, 10]}>
        <Text fontSize={[8, 15]} variant="caps">
          <Trans i18nKey="home.noMore" />
        </Text>
        <Box mt={3} sx={lineThrough}>
          <Text fontSize={[6, 8]} variant="caps">
            <Trans i18nKey="home.agencies" />
          </Text>
        </Box>
        <Box mt={3} sx={lineThrough}>
          <Text fontSize={[6, 8]} variant="caps">
            <Trans i18nKey="home.middleman" />
          </Text>
        </Box>
        <Box mt={3} sx={lineThrough}>
          <Text fontSize={[6, 8]} variant="caps">
            <Trans i18nKey="home.abusiveFees" />
          </Text>
        </Box>
        <Box mt={3} sx={lineThrough}>
          <Text fontSize={[6, 8]} variant="caps">
            <Trans i18nKey="home.opacity" />
          </Text>
        </Box>
        <Box mt={3}>
          <Icon name="ArrowDownBig" size={60} />
        </Box>
        <Text fontSize={[8, 15]}>
          <Trans i18nKey="home.titleBottom" />
          <br />
          <Trans i18nKey="home.titleBottom2" />
        </Text>
      </Box>
    </>
  );
};

const Advantages: React.FC = () => {
  const advantages = [
    {
      icon: "Plus2",
      title: "about.header.advantages.easyNetworking.title",
      body: "about.header.advantages.easyNetworking.body"
    },
    {
      icon: "Circle",
      title: "about.header.advantages.simpleShow.title",
      body: "about.header.advantages.simpleShow.body"
    },
    {
      icon: "Lightning",
      title: "about.header.advantages.youKnow.title",
      body: "about.header.advantages.youKnow.body"
    },
    {
      icon: "Hash",
      title: "about.header.advantages.marketStandards.title",
      body: "about.header.advantages.marketStandards.body"
    },
    {
      icon: "Dollar",
      title: "about.header.advantages.marketConditions.title",
      body: "about.header.advantages.marketConditions.body"
    }
  ];

  return (
    <Box mt={[5, 10]}>
      {advantages.map((entry, index) => (
        <CollapsableSection
          key={index}
          isLast={index === advantages.length - 1}
          title={
            <Box width={"100%"}>
              <Text fontSize={[6, 14]} variant="grotText" textAlign="left">
                <Trans i18nKey={entry.title} components={[<HighlightText />]} />
              </Text>
            </Box>
          }
          icon={<Icon name={entry.icon} color="blue" size={[65, 114]} />}>
          <Text mt={6} variant="grotText" fontSize={[5, 14]}>
            <NewlineText text={i18n.t(entry.body)} />
          </Text>
        </CollapsableSection>
      ))}
    </Box>
  );
};

const TabBar = (props: {
  activeTab: NavigationTab;
  setActiveTab: (tab: NavigationTab) => void;
}) => {
  return (
    <>
      <Flex
        width={"100%"}
        sx={{
          border: "1px solid black"
        }}>
        <Button
          variant="transparent"
          backgroundColor="white"
          onClick={() => {
            props.setActiveTab(NavigationTab.About);
          }}>
          <Box
            px={56}
            sx={{ borderRight: "1px solid black", flex: "0 0 auto" }}>
            <Text
              fontSize={[2, 2, 16]}
              sx={{ textTransform: "uppercase" }}
              color={props.activeTab == 0 ? "blue" : "black"}>
              About
            </Text>
          </Box>
        </Button>
        <Button
          variant="transparent"
          backgroundColor="white"
          onClick={() => {
            props.setActiveTab(NavigationTab.HowItWorks);
          }}
          sx={{ flex: "1" }}>
          <Box px={56}>
            <Text
              fontSize={[2, 2, 16]}
              sx={{ textTransform: "uppercase" }}
              textAlign={"center"}
              color={props.activeTab == 1 ? "blue" : "black"}>
              How it works
            </Text>
          </Box>
        </Button>
      </Flex>
    </>
  );
};

export const Intro = () => {
  return (
    <>
      <Box mt={[5, 10]}>
        <Image src={logo} height={[27, 37]} />
        <Text fontSize={[6, 6, 9]} mt={2}>
          <Trans i18nKey="about.intro.subtitle" />
        </Text>
        <Text fontSize={[6, 6, 9]} mt={[5, 10]}>
          <Trans i18nKey="about.intro.body1" components={[<BlueText />]} />
        </Text>
        <Text fontSize={[6, 6, 9]} mt={[6]} mx={[0, 0, 9]} mb={[5, 10]}>
          <Trans i18nKey="about.intro.body2" components={[<BlueText />]} />
        </Text>
      </Box>
    </>
  );
};

export const AboutBase: React.FC<Props> = ({ history }) => {
  const anchor = history.location.hash.replace("#", "");

  const [activeTab, setActiveTab] = useState<NavigationTab>(
    anchor === HOW_IT_WORKS_ANCHOR_ID
      ? NavigationTab.HowItWorks
      : NavigationTab.About
  );

  return (
    <ContentArea>
      <TabBar activeTab={activeTab} setActiveTab={setActiveTab} />
      {activeTab === NavigationTab.About ? (
        <>
          <Intro />
          <Box mb={[5, 10]}>
            <SocialLinks />
          </Box>
          <Separator />
          <Advantages />
          <NoMore />
          <Team />
          <Flex
            mt={[5, 10]}
            flexDirection={"column"}
            sx={{ gap: "32px" }}
            alignItems={"center"}>
            <Text fontSize={60}>Follow us</Text>
            <SocialLinks />
          </Flex>
        </>
      ) : (
        <>
          <HowItWorks />
          <SocialLinks />
        </>
      )}
    </ContentArea>
  );
};

export const About = compose<Props, any>(withRouter)(AboutBase);
