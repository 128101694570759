import { Icon } from "app/shared";
import React, { FC, ReactNode } from "react";
import { Box, Text, Flex, SxStyleProp } from "rebass";

interface FormFieldSetProps {
  title?: string | ReactNode;
  titleTop?: string;
  description?: string;
  children: ReactNode;
  headerPadding?: number[] | number;
  contentPadding?: number[] | number;
  sx?: SxStyleProp;
  iconName?: string;
}
export const FormFieldSet: FC<FormFieldSetProps> = (
  props: FormFieldSetProps
) => {
  const defaultStyle = {
    backgroundColor: "fill.0",
    borderBottom: 1,
    borderColor: "black.0"
  };
  const style = props.sx ? { ...props.sx, defaultStyle } : defaultStyle;

  return (
    <>
      {props.titleTop && (
        <>
          <Text
            fontSize={[2, 3, 7]}
            variant="grotTextCaps"
            ml={props.iconName ? [1, 2] : 0}>
            {props.titleTop}
          </Text>
        </>
      )}
      <Box sx={style}>
        {/* Main title for the form field set*/}
        {props.title && (
          <Flex
            pl={props.headerPadding}
            pb={1}
            alignItems="center"
            sx={{
              borderBottom: props.title ? 1 : 0,
              borderColor: props.title ? "black.0" : ""
            }}>
            {props.iconName && <Icon name={props.iconName} size={16} />}
            {typeof props.title === "string" ? (
              <Text
                fontSize={[1, 3, 7]}
                variant="grotTextCaps"
                ml={props.iconName ? [1, 2] : 0}>
                {props.title}
              </Text>
            ) : (
              props.title
            )}
          </Flex>
        )}
        {/* FieldsetBody */}
        <Box p={props.contentPadding}>
          {props.description && (
            <Text fontSize={[1, 2, 6]}>{props.description}</Text>
          )}
          <Box my={[1, 2, 3]}>{props.children}</Box>
        </Box>
      </Box>
    </>
  );
};

FormFieldSet.defaultProps = {
  headerPadding: [1, 2, 3],
  contentPadding: [1, 2, 3]
};
