import React, { useState } from "react";
import { Trans } from "react-i18next";
import { Box, Flex, Text } from "rebass";
import { useMediaQueryContext } from "styles/context";
import { BackgroundImage, Button, Image } from "app/shared";
import logo from "./image/FrontTalksLogo_brazil.svg";
import background from "./image/Background-13-01-22.png";

export const FrontTalksCard2 = () => {
  const { isSmallMedium } = useMediaQueryContext();
  const [imageLoaded, setImageLoaded] = useState(false);

  return (
    <>
      <Box sx={{ position: "relative" }} mx={[0, 8]} mt={6}>
        {!isSmallMedium ? (
          <Image
            src={background}
            width="100%"
            height="100%"
            onLoad={() => setImageLoaded(true)}
          />
        ) : (
          <BackgroundImage src={background} width="100%" height={"100vh"} />
        )}
        {((imageLoaded && !isSmallMedium) || isSmallMedium) && (
          <Flex>
            <Box
              pl={[2, 5]}
              sx={{
                position: "absolute",
                top: "4%",
                "& svg path": { fill: "blue" }
              }}>
              <Image
                pl={2}
                src={logo}
                width="100%"
                height="100%"
                pt={isSmallMedium ? 1 : 0}
              />
            </Box>
            <Flex
              width="100%"
              alignItems="start"
              justifyContent="flex-start"
              pl={[3, 6]}>
              <Box
                sx={{
                  position: "absolute",
                  top: ["35%", "35%"],
                  "& svg path": { fill: "blue" }
                }}>
                <Text py={3} variant="grotText" color="blue" fontSize={[4, 10]}>
                  <Trans i18nKey="events.frontTalks.womanEvent.text1" />
                </Text>

                <Box
                  mt={3}
                  p={2}
                  width={["100%", "100%", "100%", "70%", "50%"]}
                  sx={{ background: "rgba(0, 0, 0, .2)" }}>
                  <Text color="white" fontSize={[3, 6]}>
                    <Trans i18nKey="events.frontTalks.womanEvent.text2" />
                  </Text>
                </Box>
                {isSmallMedium && (
                  <Box width="100%" mt={3} pr={6}>
                    <Button
                      width="100%"
                      variant="link.button.blue"
                      href="/fronttalks/brazil/13-01-2022"
                      target="_blank">
                      <Trans i18nKey="events.frontTalks.infoButton" />
                    </Button>
                  </Box>
                )}
              </Box>
            </Flex>
            {!isSmallMedium && (
              <Flex
                width="100%"
                alignItems="start"
                justifyContent="flex-end"
                pr={6}>
                <Box
                  sx={{
                    position: "absolute",
                    top: "85%",
                    "& svg path": { fill: "blue" }
                  }}>
                  <Box width="100%">
                    <Button
                      px={7}
                      variant="link.button.blue"
                      href="/fronttalks/brazil/13-01-2022">
                      <Trans i18nKey="events.frontTalks.infoButton" />
                    </Button>
                  </Box>
                </Box>
              </Flex>
            )}
          </Flex>
        )}
      </Box>
    </>
  );
};
