import React from "react";
import { Trans } from "react-i18next";
import { Box, Text } from "rebass";

interface Props {
  totalResults: number;
}

export const FilteredResultsCounter: React.FC<Props> = ({ totalResults }) => (
  <Box
    sx={{
      borderRight: 1,
      borderLeft: 1,
      borderBottom: 1,
      borderColor: "black.0"
    }}>
    <Text fontSize={0} px={3} py={2} variant="caps">
      <Trans i18nKey="search.filters.buttons.filteredResultsCounter" />
      {": "}
      {totalResults}
    </Text>
  </Box>
);
