import {
  EditMyInfo,
  Insights,
  Notifications,
  Profile,
  RecoverPassword,
  ResetPassword,
  Register,
  Settings,
  SignIn
} from "app/account";
import { CollectionDetails, CollectionEdit } from "app/collections";
import {
  Checkout,
  DocumentDetails,
  DocumentEdit,
  SubmitContent,
  Transactions
} from "app/documents";
import { BucketDetails, BucketEdit } from "app/buckets";
import Footer from "app/footer";
import { Homepage, NotFound } from "app/home";
import { Messages } from "app/messages";
import { Navbar } from "app/navbar";
import { Search } from "app/search";
import { withAuthentication } from "authentication/session";
import { ThemeProvider } from "emotion-theming";
import { FooterArea, MainLayout, NavbarArea } from "layout";
import React from "react";
import { ToastContainer } from "app/shared/toast";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { GlobalStyles, theme } from "styles";
import { MediaQueryProvider } from "styles/context";
import { About } from "app/about";
import { License, LicenseExample, Copyright } from "app/documents/receipts";
import { Metadata } from "app/shared";
import { FrontTalksPage } from "app/home/events/FrontTalksCardPage";
import { FrontTalksHome } from "app/home/events/FrontTalksHome";
import { StickyProvider } from "react-stickup";
import { CollectionMagazine } from "app/collections/CollectionMagazine";
import { PrivacyPolicy } from "app/privacyPolicy";
import { TermsConditions } from "app/termsConditions";

const RENDER_PATH = "r";

const ScrollToTop = () => {
  window.scrollTo(0, 0);
  return null;
};

/**
 * (/r)? is the path used by crawlers bypassing the cloud function.
 */
const createPath = (path: string) => `(/${RENDER_PATH})?${path}`;

const App: React.FC = () => {
  const locationUrl = `${
    window.location.origin
  }${window.location.pathname.replace(`/${RENDER_PATH}`, "")}${
    window.location.search
  }`;

  return (
    <ThemeProvider theme={theme}>
      <MediaQueryProvider>
        <BrowserRouter>
          <Metadata
            title="Frontfiles"
            description="FF is a global community of photographers and image buyers determined to build an equitable labour market for visual journalism."
            url={locationUrl}
            canonical={locationUrl}
            type="website"
          />
          <Route component={ScrollToTop} />
          <GlobalStyles />
          <ToastContainer />
          <Switch>
            {/* Full page without main layout */}
            <Route path={createPath("/login")} component={SignIn} />
            <Route path={createPath("/register")} component={Register} />
            <Route
              path={createPath("/recover-password")}
              component={RecoverPassword}
            />
            <Route
              path={createPath("/reset-password")}
              component={ResetPassword}
            />
            <Route
              exact
              path={createPath("/receipts/licenses/:id")}
              component={License}
            />
            <Route
              path={createPath("/receipts/licenses/examples/:type")}
              component={LicenseExample}
            />
            <Route
              path={createPath("/receipts/documents/:id")}
              component={Copyright}
            />
            {/* Pages with main layout: navbar, footer, etc */}
            <Route>
              <MainLayout>
                <StickyProvider>
                  {/* For sticky elements work */}
                  <NavbarArea children={<Navbar />} />
                  <Switch>
                    <Route
                      path={createPath("/users/:id/:filter?")}
                      component={Profile}
                    />
                    <Route
                      path={createPath("/edit-my-info")}
                      component={EditMyInfo}
                    />
                    <Route
                      path={createPath("/settings/:filter?")}
                      component={Settings}
                    />
                    <Route
                      path={createPath("/notifications")}
                      component={Notifications}
                    />
                    <Route
                      path={createPath("/submit-content")}
                      component={SubmitContent}
                    />
                    <Route
                      path={createPath("/search/:view?")}
                      component={Search}
                    />
                    <Route
                      path={createPath("/transactions/:filter?")}
                      component={Transactions}
                    />
                    <Route
                      exact
                      path={createPath("/buckets/:id")}
                      component={BucketDetails}
                    />
                    <Route
                      path={createPath("/buckets/:id/edit")}
                      component={BucketEdit}
                    />
                    <Route
                      exact
                      path={createPath("/documents/:id")}
                      component={DocumentDetails}
                    />
                    <Route
                      path={createPath("/documents/:id/edit")}
                      component={DocumentEdit}
                    />
                    <Route
                      exact
                      path={createPath("/collections/:id")}
                      component={CollectionDetails}
                    />
                    <Route
                      path={createPath("/collections/:id/edit")}
                      component={CollectionEdit}
                    />
                    <Route
                      exact
                      path={createPath("/magazine/:id")}
                      component={CollectionMagazine}
                    />
                    <Route
                      path={createPath("/checkout")}
                      component={Checkout}
                    />
                    <Route
                      path={createPath("/messages")}
                      component={Messages}
                    />
                    <Route path={createPath("/metrics")} component={Insights} />
                    <Route path={createPath("/about")} component={About} />
                    <Route
                      path={createPath("/privacy-policy")}
                      component={PrivacyPolicy}
                    />
                    <Route
                      path={createPath("/terms-conditions")}
                      component={TermsConditions}
                    />
                    <Route
                      exact
                      path={createPath("/fronttalks")}
                      component={FrontTalksHome}
                    />
                    <Route
                      path={createPath("/fronttalks/:country/:date")}
                      component={FrontTalksPage}
                    />
                    <Route exact path={createPath("/")} component={Homepage} />
                    <Route component={NotFound} />
                  </Switch>
                  <FooterArea
                    mb={["150px", 0]}
                    sx={{ borderTop: "1px solid black" }}>
                    <Footer />
                  </FooterArea>
                </StickyProvider>
              </MainLayout>
            </Route>
          </Switch>
        </BrowserRouter>
      </MediaQueryProvider>
    </ThemeProvider>
  );
};

export default withAuthentication(App);
