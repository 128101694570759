import { Price } from "marketplace";
import axios from "network";
import { BuyProposal } from "../models";

export const createProposal = async (
  documents: number[], // list of document ids for the proposal. At this point it will be an array of size 1
  price: Price,
  buyer: string, // buyer id
  seller: string, // seller id
  license: string
): Promise<BuyProposal> => {
  const response = await axios.post(`/proposals`, {
    documents,
    price,
    buyer,
    seller,
    license
  });
  return response.data;
};

export const getProposal = async (id: number) => {
  return axios.get(`/proposals/${id}`);
};

export const acceptProposal = async (id: number) => {
  return axios.put(`/proposals/${id}/accept`);
};

export const declineProposal = async (id: number) => {
  return axios.put(`/proposals/${id}/reject`);
};

export const deleteProposal = async (id: number) => {
  return axios.delete(`/proposals/${id}`);
};
