import { Button, MobileTriggerButton, Popup, Toast } from "app/shared";
import i18next from "i18next";
import debounce from "lodash.debounce";
import React, { Component } from "react";
import { Trans } from "react-i18next";
import { Text } from "rebass";
import { deleteDocument } from "./services";

interface Props {
  documentIds: number[];
  mobileView?: boolean;
}

interface State {
  disabled: boolean;
}

export class DocumentDeletePhotosButton extends Component<Props, State> {
  state = {
    disabled: false
  };

  deleteDocuments(documentIds: number[], close: () => void) {
    this.setState({ disabled: true }, () => {
      deleteDocument(documentIds)
        .then(() => {
          Toast.success({
            title: {
              key: "documents.documentDeletePhotosButton.deleteSelected"
            }
          });
          close();

          // Waiting a few for backend re-index changes.
          // TODO: This can be replaced if we manually change affected elements locally.
          debounce(
            () =>
              this.setState({ disabled: false }, () =>
                window.location.reload()
              ),
            1000
          )();
        })
        .catch(error => {
          Toast.apiError(error);
          this.setState({ disabled: false });
        });
    });
  }

  render() {
    const { disabled } = this.state;
    const { documentIds, mobileView } = this.props;
    const buttonEnabled = documentIds.length !== 0;

    return (
      <Popup
        trigger={
          mobileView ? (
            <Button variant="buttons.mobileButton">
              <MobileTriggerButton
                iconName="RemoveFrom"
                disabled={disabled || !buttonEnabled}
                buttonNameI18n="documents.documentDeletePhotosButton.deleteSelected"
              />
            </Button>
          ) : (
            <Button width="100%" disabled={disabled || !buttonEnabled}>
              <Text fontSize={[1, 2, 3]}>
                <Trans i18nKey="documents.documentDeletePhotosButton.deleteSelected" />
              </Text>
            </Button>
          )
        }
        heading={i18next.t("documents.documentDeletePhotosButton.deletePhotos")}
        submit={close => this.deleteDocuments(documentIds, close)}
        submitText={i18next.t("documents.documentDeletePhotosButton.confirm")}
        submitDisabled={disabled}
        cancelText={i18next.t("documents.documentDeletePhotosButton.cancel")}
        cancelDisabled={disabled}
        disabled={!buttonEnabled}>
        <>
          <Text textAlign="center">
            {i18next.t(
              "documents.documentDeletePhotosButton.shouldSelectedBeDeleted",
              { value: documentIds.length }
            )}
          </Text>
        </>
      </Popup>
    );
  }
}
