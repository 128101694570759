import { Button, MobileTriggerButton, Popup, Toast } from "app/shared";
import { AuthUserContext } from "authentication/session";
import i18next from "i18next";
import debounce from "lodash.debounce";
import React, { Component } from "react";
import { Trans } from "react-i18next";
import { BoxProps, Text } from "rebass";
import { getBucket, updateBucket } from "./services";
import { deleteBuckets } from "./services";

interface BucketItemRemove {
  type: "document" | "collection";
  id: number;
}

interface Props extends BoxProps {
  bucketItemIds: BucketItemRemove[];
  bucketId: number;
  mobileView?: boolean;
  hideTrigger?: boolean;
  open?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
}

interface State {
  disabled: boolean;
}

export class RemoveBucketItemsButton extends Component<Props, State> {
  state = {
    disabled: false
  };

  deleteBuckets() {
    const { bucketId } = this.props;
    if ([bucketId].length) {
      deleteBuckets([bucketId]).catch(error => {
        Toast.apiError(error);
        this.setState({ disabled: false });
      });
    }
  }

  removeBucketPhotos(close: () => void) {
    const { bucketItemIds, bucketId } = this.props;
    if (bucketItemIds.length && bucketId !== undefined) {
      this.setState({ disabled: true }, async () => {
        updateBucket(
          bucketId,
          undefined,
          undefined,
          {
            mode: "remove",
            list: bucketItemIds
              .filter(it => it.type === "document")
              .map(it => it.id)
          },
          {
            mode: "remove",
            list: bucketItemIds
              .filter(it => it.type === "collection")
              .map(it => it.id)
          }
        )
          .then(() => {
            // Waiting a few for backend re-index changes.
            // TODO: This can be replaced if we manually change affected elements locally.
            Toast.success({
              title: {
                key: "buckets.removeBucketItemsButton.removeSuccess",
                options: { value: bucketItemIds.length }
              }
            });
            let afterDelete: boolean | string = true;
            getBucket(bucketId).then(bucket => {
              if (bucket.documents.length === 0) {
                deleteBuckets([bucketId]);
                afterDelete = `/users/${bucket.owner.id}/buckets`;
              }
            });
            close();
            debounce(
              () =>
                this.setState({ disabled: false }, () =>
                  afterDelete === true
                    ? window.location.reload()
                    : (document.location.href = afterDelete.toString())
                ),
              1000
            )();
          })
          .catch(error => {
            Toast.apiError(error);
            this.setState({ disabled: false });
          });
      });
    }
  }

  render() {
    const { disabled } = this.state;
    const {
      bucketItemIds,
      mobileView,
      bucketId,
      hideTrigger,
      onOpen,
      onClose,
      open,
      ...rest
    } = this.props;
    const buttonEnabled = bucketItemIds.length !== 0 && bucketId !== undefined;

    return (
      <Popup
        trigger={
          hideTrigger ? (
            undefined
          ) : mobileView ? (
            <Button variant="buttons.mobileButton">
              <MobileTriggerButton
                iconName="RemoveFrom"
                disabled={disabled || !buttonEnabled}
                buttonNameI18n="buckets.removeBucketItemsButton.removeFromBucket"
              />
            </Button>
          ) : (
            <Button
              width="100%"
              disabled={disabled || !buttonEnabled}
              {...(rest as any)}>
              <Text fontSize={[1, 2, 3]}>
                <Trans i18nKey="buckets.removeBucketItemsButton.removeFromBucket" />
              </Text>
            </Button>
          )
        }
        heading={i18next.t("buckets.removeBucketItemsButton.removePhotos")}
        submit={close => this.removeBucketPhotos(close)}
        submitText={i18next.t("buckets.removeBucketItemsButton.confirm")}
        submitDisabled={disabled}
        cancelText={i18next.t("buckets.removeBucketItemsButton.cancel")}
        cancelDisabled={disabled}
        disabled={!buttonEnabled}
        open={open}
        onOpen={onOpen}
        onClose={onClose}>
        <>
          <AuthUserContext.Consumer>
            {({ authUser }) =>
              authUser && (
                <Text textAlign="center">
                  {i18next.t("buckets.removeBucketItemsButton.popupText", {
                    value: bucketItemIds.length
                  })}
                </Text>
              )
            }
          </AuthUserContext.Consumer>
        </>
      </Popup>
    );
  }
}
