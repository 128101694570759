import React from "react";
import Svg, { SvgProps } from "primitives/Svg";

const SvgRemoveFromDisabled = ({ width, height, size, color }: SvgProps) => (
  <Svg
    width={size || width}
    height={size || height}
    viewBox="0 0 24 24"
    color={color}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 6h13v12h1V5H4v15h13v-1H5V6z"
      fillOpacity={0.4}
    />
    <path d="M16 12H7" stroke="#000" strokeOpacity={0.4} />
  </Svg>
);

SvgRemoveFromDisabled.defaultProps = {
  width: 24,
  height: 24
};
export default SvgRemoveFromDisabled;
