/* eslint-disable @typescript-eslint/no-explicit-any */
import { MultiSelectSearch } from "app/search/shared";
import { getRaw } from "app/search/utils";
import {
  Button,
  CategorySelect,
  Checkbox,
  DatePicker,
  Input,
  MultiSelect,
  Popup,
  Label as Label2,
  Tooltip,
  Toast,
  Icon,
  Image
} from "app/shared";
import { FormFieldSet } from "app/shared/forms";
import { Textarea } from "app/shared/input/Textarea";
import { Location } from "app/shared/maps/models";
import { MonetaryText } from "app/shared/monetaryText";
import { SelectOption } from "app/shared/multiSelect/models";
import { withAuthorization } from "authentication/session";
import i18next from "i18next";
import {
  AvailableLicenseType,
  LicensePrice,
  LicenseType,
  MonetaryAmount
} from "marketplace";
import {
  Category,
  Document,
  documentAttributes,
  Owner,
  DocumentTranslation,
  User,
  ValidationError,
  Label,
  Collection
} from "models";
import React, { Component, SyntheticEvent } from "react";
import { Trans } from "react-i18next";
import { connect } from "react-redux";
import { Box, Flex, Text } from "rebass";
import { compose } from "recompose";
import { DocumentTranslationsField } from "./models";
import { getAvailableLicenses } from "./services";
import { ISOLanguageCode } from "i18n/resources/supportedLanguages";
import { CollectionEditPopup } from "app/collections";
import { createLabel } from "./services";
import i18n from "../../i18n/i18n";
import { LocationInputSearch } from "../account/editMyInfo/LocationInputSearch";

interface PropsExternal {
  document: Document;
  version: number;
  errors: ValidationError[];
  onChange: (document: Document) => void;
  applyToAll?: (document: Document, basePriceAmount: MonetaryAmount) => void;
  removeAllLabels?: () => void;
  basePriceAmount?: MonetaryAmount; // this is provided by applyToAll
  showCollectionsDropdown?: boolean;
  checkError?: () => void;
}

interface Props extends PropsExternal {
  language: ISOLanguageCode;
  // Logged user to fill in credits
  loggedInUser: User;
}

interface State {
  basePriceAmount: MonetaryAmount;
  collectionPopupOpen: boolean;
  labelPopupOpen: boolean;
  // TODO: Move this to store?
  availableLicenseTypes: AvailableLicenseType[];
  specificLabelTitle: string;
  manualLabels: Label[];
  isSubmitting: boolean;
}

class DocumentEditorBase extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    // Get base price from saved licenses or use a base price of 0
    const basePrice = props.document.prices.find(it => it.basePrice.value > 0)
      ?.basePrice ?? {
      value: 0,
      currency: "EUR",
      precision: 2
    };

    this.state = {
      basePriceAmount: new MonetaryAmount(
        basePrice.value,
        basePrice.currency,
        basePrice.precision
      ),
      collectionPopupOpen: false,
      labelPopupOpen: false,
      availableLicenseTypes: [],
      specificLabelTitle: "",
      manualLabels: [],
      isSubmitting: false
    };
  }

  shouldComponentUpdate(nextProps: Props, nextState: State) {
    const keysAreTheSame = this.props.errors
      .map(it => it.i18nKey)
      .every(curentKey =>
        nextProps.errors.find(it => it.i18nKey === curentKey)
      );

    const relevantPropsUpdated =
      this.props.version < nextProps.version ||
      this.props.errors.length !== nextProps.errors.length ||
      !keysAreTheSame;

    const relevantStateUpdated =
      this.state.availableLicenseTypes.length !==
        nextState.availableLicenseTypes.length ||
      this.state.collectionPopupOpen !== nextState.collectionPopupOpen ||
      this.state.labelPopupOpen !== nextState.labelPopupOpen ||
      this.state.specificLabelTitle !== nextState.specificLabelTitle ||
      this.state.manualLabels !== nextState.manualLabels ||
      this.state.basePriceAmount.getAmount() !==
        nextState.basePriceAmount.getAmount();

    return relevantPropsUpdated || relevantStateUpdated;
  }

  componentDidMount() {
    getAvailableLicenses().then(availableLicenseTypes => {
      this.setState({ availableLicenseTypes });
    });
  }

  componentDidUpdate(prevProps: Props) {
    const previousbasePriceAmount = prevProps.basePriceAmount;
    const nextbasePriceAmount = this.props.basePriceAmount;

    // Change basePriceAmount if applyToAll from other documents change it
    if (
      nextbasePriceAmount !== undefined &&
      previousbasePriceAmount?.getAmount() !== nextbasePriceAmount?.getAmount()
    ) {
      this.setState({ basePriceAmount: nextbasePriceAmount });
    }
  }

  render() {
    const { document, applyToAll, checkError } = this.props;
    const { basePriceAmount } = this.state;
    if (!document) return null;
    return (
      <Box>
        <Flex
          flexDirection={["column", "row"]}
          width="100%"
          px={[1, 3]}
          pt={3}
          mb={6}>
          <Image
            src={document.thumbnailLarge}
            width="100%"
            sx={{ pointerEvents: "none" }}
          />
          <Flex pl={[0, 4]} width="100%" mt={[4, 0]}>
            <Flex
              flexDirection="column"
              justifyContent="space-between"
              height="100%"
              width="100%">
              {/* Title & Description*/}
              {this.renderTitleBlock()}
              {this.renderDescriptionBlock()}
              <Flex>
                <Box width="100%">
                  {/* Location and Date */}
                  {this.renderLocationBlock()}
                </Box>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
        <FormFieldSet sx={{ borderBottom: 0 }}>
          {/* Labels and Manual Labels */}
          {this.renderLabelsBlock()}
          {/* Categories */}
          {this.renderCategoriesBlock()}
          {/* Image attributes */}
          {/* {this.renderAttributesBlock()} */}
          {/* Credits */}
          {this.renderCreditsBlock()}
          {/* Collections */}
          {this.renderCollectionsBlock()}
          {/* Price and License */}
          {this.renderPriceAndLicensesBlock()}
          {/* Share Income */}
          {/* {this.shareIncome()} */}
        </FormFieldSet>

        {applyToAll && (
          <Flex
            my={3}
            px={[1, 3]}
            justifyContent="space-between"
            sx={{
              // :not() important for popups have 100% width
              "& > div:not([class*='popup'])": {
                width: "calc(100% / 3 - 1px)"
              }
            }}>
            <Box />
            <Popup
              trigger={
                <Button center={true} py={3} mx={[3, 10]} variant="blue">
                  <Text fontSize={[1, 2, 3]} variant="caps">
                    <Trans i18nKey="documents.documentEditor.popup.applyToAll" />
                  </Text>
                </Button>
              }
              heading={i18next.t("documents.documentEditor.popup.header")}
              cancelText={i18next.t("documents.documentEditor.popup.cancel")}
              submit={close => {
                applyToAll(document, basePriceAmount);
                close();
              }}
              submitText={i18next.t("documents.documentEditor.popup.submit")}>
              <>
                <Text textAlign="center">
                  {i18next.t("documents.documentEditor.popup.content")}
                </Text>
              </>
            </Popup>
            <Flex justifyContent="flex-end">
              <Button
                center={true}
                px={[4, 8]}
                variant="blue"
                py={3}
                onClick={() => checkError?.()}>
                <Text>
                  <Trans i18nKey="documents.fillInformation.saveButton" />
                </Text>
              </Button>
            </Flex>
          </Flex>
        )}
      </Box>
    );
  }

  private handleOnCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { document } = this.props;

    // Check selected licenses
    const selectedLicenses = document.prices
      .map(it => it.license)
      .filter(it => it !== event.target.name);

    if (event.target.checked) {
      selectedLicenses.push(event.target.name as LicenseType);
    }

    // Update licenses
    this.setLicensePrices(selectedLicenses);
  };

  private setLicensePrices(licenses: LicenseType[]) {
    const { basePriceAmount } = this.state;
    const { document, onChange } = this.props;

    const prices: LicensePrice[] = licenses.map(license => ({
      license,
      basePrice: {
        value: license === "creativecommons" ? 0 : basePriceAmount.getAmount(),
        currency: basePriceAmount.getCurrency(),
        precision: basePriceAmount.getPrecision()
      }
    }));

    onChange({ ...document, prices });
  }

  private onChangeBasePrice = (newPrice: string, licenses: LicenseType[]) => {
    this.setState(
      previousState => ({
        ...previousState,
        basePriceAmount: new MonetaryAmount(
          parseFloat(newPrice ? newPrice : "0"),
          previousState.basePriceAmount.getCurrency(),
          previousState.basePriceAmount.getPrecision()
        )
      }),
      () => this.setLicensePrices(licenses)
    );
  };

  private onChangeCategory = (options: SelectOption[]) => {
    const { onChange, document, language } = this.props;

    const userCategories: Category[] = options.map(option => ({
      id: parseInt(option.value, 10),
      taxonomy: "iptc", // TODO: Change logic when taxonomy is not iptc
      translations: [{ language, title: option.label }]
    }));

    onChange({ ...document, userCategories });
  };

  private onChangeCollections = (options: SelectOption[]) => {
    const { onChange, document, loggedInUser } = this.props;

    const collections: Collection[] = options.map(option => ({
      id: parseInt(option.value, 10),
      createdAt: new Date().toISOString(),
      privacy: "public",
      uid: loggedInUser.id,
      owner: {
        id: loggedInUser.id,
        name: loggedInUser.id
      },
      title: option.label,
      description: "",
      shareWithLink: false,
      city: "",
      country: "",
      totalDocuments: 0,
      documents: []
    }));

    onChange({ ...document, collections });
  };

  private onAddCollection = (collection: Collection) => {
    const { onChange, document } = this.props;

    // Add collecion
    onChange({
      ...document,
      collections: [...document.collections, collection]
    });
  };

  private onChangeLabels = (options: SelectOption[]) => {
    const { onChange, document } = this.props;

    const userLabels: Label[] = options.map(option => ({
      id: parseInt(option.value, 10),
      status: "active",
      title: option.label
    }));
    onChange({ ...document, userLabels });
  };

  private onAddLabel = (label: Label) => {
    const { onChange, document } = this.props;

    // Add label
    onChange({
      ...document,
      userLabels: [...document.userLabels, label]
    });
  };

  private onChangeAttributes = (options: SelectOption[]) => {
    const { onChange, document } = this.props;

    const attributes: string[] = options.map(option => option.value);

    onChange({ ...document, attributes });
  };

  private onChangeCredits = (options: SelectOption[]) => {
    const { onChange, document, loggedInUser } = this.props;

    const credits: Owner[] = options
      .map(option => ({
        id: option.value,
        name: option.label
      }))
      .filter(user => user.id !== loggedInUser.id);

    onChange({
      ...document,
      credits: [
        { id: loggedInUser.id, name: loggedInUser.professionalName }, // always set the owner as first in credits
        ...credits
      ]
    });
  };

  private onChangeLocation = (location: Location) => {
    const { onChange, document } = this.props;
    const hasCountry = location.country;
    onChange({
      ...document,
      latitude: hasCountry ? location.lat.toString() : undefined,
      longitude: hasCountry ? location.lng.toString() : undefined,
      city: location.city,
      country: location.country
    });
  };

  private onChangeDate = (day?: Date) => {
    const { onChange, document } = this.props;

    day && onChange({ ...document, takenAt: day.toISOString() });
  };

  private getCategoriesDefaultValues = (): SelectOption[] => {
    const { document } = this.props;

    return document.userCategories.map(category => ({
      value: `${category.id}`,
      label: category.translations[0].title
    }));
  };

  private getCollectionsDefaultValues = (): SelectOption[] => {
    const { document } = this.props;

    return document.collections.map(collection => ({
      value: `${collection.id}`,
      label: collection.title
    }));
  };

  private getAttributesDefaultValues = (): string[] => {
    const { document } = this.props;

    return document.attributes.map(attribute => attribute);
  };

  private getLabelsDefaultValues = (): SelectOption[] => {
    const { document } = this.props;

    return document.userLabels.map(label => ({
      value: label.id.toString(),
      label: label.title
    }));
  };

  private getCreditsDefaultValues = (): SelectOption[] => {
    const { document, loggedInUser } = this.props;

    const credits = document.credits
      .filter(user => user.id !== loggedInUser.id)
      .map(user => ({
        value: user.id,
        label: user.name
      }));

    return [
      { value: loggedInUser.id, label: loggedInUser.professionalName }, // always set the owner as first in credits
      ...credits
    ];
  };

  private changeDocumentTranslations = (
    value: string,
    documentField: DocumentTranslationsField
  ): void => {
    const { document, onChange, language } = this.props;

    // Here it's assumed we will only change the first created translation.
    // When we add support for multiple language translations this have to be changed.
    const translation = document.translations?.[0] ?? { language, title: "" };

    translation[documentField] = value;
    const translations: DocumentTranslation[] = [translation];

    const updatedDocument = { ...document, translations };
    onChange(updatedDocument);
  };

  private renderTitleBlock() {
    const { document, errors } = this.props;
    // Here it's assumed we will only present the first created translation.
    // When we add support for multiple language translations this have to be changed.
    const translation = document.translations?.[0];
    const translationError = errors.find(err => err.field === "translations");
    return (
      <Box mb={[3, 0]}>
        <Input
          name="title"
          type="text"
          errorI18n={translationError ? translationError.i18nKey : undefined}
          labelI18n="documents.documentEditor.title"
          infoI18n="documents.documentEditor.titleTooltip"
          placeholder={i18next.t("documents.documentEditor.titlePlaceholder")}
          onChange={event => {
            this.changeDocumentTranslations(event.target.value, "title");
          }}
          value={translation?.title}
        />
      </Box>
    );
  }

  private renderDescriptionBlock() {
    const { document } = this.props;
    // Here it's assumed we will only present the first created translation.
    // When we add support for multiple language translations this have to be changed.
    const translation = document.translations?.[0];
    return (
      <Box mb={[3, 0]}>
        {/* Description */}
        <Textarea
          name="description"
          type="text"
          labelI18n="documents.documentEditor.description"
          infoI18n="documents.documentEditor.descriptionTooltip"
          placeholder={i18next.t(
            "documents.documentEditor.descriptionPlaceholder"
          )}
          onChange={event => {
            this.changeDocumentTranslations(event.target.value, "description");
          }}
          value={translation?.description}
          minHeight="100px"
          sx={{
            "::placeholder": {
              fontSize: "10px",
              fontFamily: "Haas Grot Text Pro",
              letterSpacing: "1.9px",
              textTransform: "uppercase"
            }
          }}
        />
      </Box>
    );
  }

  private renderDateBlock() {
    const { document, errors } = this.props;
    const dateError = errors.find(err => err.field === "takenAt");

    return (
      <DatePicker
        name="date"
        errorI18n={dateError ? dateError.i18nKey : undefined}
        labelI18n="documents.documentEditor.date"
        infoI18n="documents.documentEditor.dateTooltip"
        onDayChange={day => this.onChangeDate(day)}
        value={document.takenAt}
        disabled={document.metadata.date !== undefined}
      />
    );
  }

  private renderLocationBlock() {
    const { document, errors } = this.props;
    const locationDisabled =
      document.metadata.latitude !== undefined ||
      document.metadata.longitude !== undefined;

    return (
      <Box>
        <Flex alignItems={["", "flex-end"]} flexDirection={["column", "row"]}>
          <Box mr={[0, 6]}>{this.renderDateBlock()}</Box>
          <Box width="100%" my={[3, 0]} pl={[0, 5]}>
            <LocationInputSearch
              labelI18n="documents.documentEditor.location"
              name="city"
              value={[document.city, document.country]
                .filter(it => it)
                .join(", ")}
              errorI18n={errors.find(it => it.field === "city")?.i18nKey}
              placeholder={i18next.t("documents.documentEditor.select")}
              infoI18n="documents.documentEditor.locationTooltip"
              disabled={locationDisabled}
              onLocationChange={(city, country, lat, lng) => {
                if (city?.length && country?.length) {
                  this.onChangeLocation({
                    city,
                    country,
                    lat,
                    lng
                  } as Location);
                  return this.onChangeLocation({
                    city,
                    country,
                    lat,
                    lng
                  } as Location);
                } else {
                  // If there is no value or there is an error use the old value
                  this.onChangeLocation({
                    city,
                    country,
                    lat,
                    lng
                  } as Location);
                }
              }}
            />
          </Box>
        </Flex>
      </Box>
    );
  }

  private shareIncome() {
    return (
      <Box
        mt={6}
        width="100%"
        sx={{
          border: "1px solid #000",
          pointerEvents: "none",
          opacity: 0.65
        }}>
        <Flex
          alignItems="center"
          py={1}
          px={2}
          sx={{ borderBottom: "1px solid black" }}>
          <Flex width="100%" alignItems="center">
            <Label2
              variant="grotTextCaps"
              i18n={"documents.documentEditor.shareIncome.title"}
              pb={0}
            />
            <Tooltip
              i18nKey="documents.documentEditor.shareIncome.titleTooltip"
              ml={1}
            />
          </Flex>
          <Flex width="30%">
            <Label2
              variant="grotTextCaps"
              i18n="documents.documentEditor.shareIncome.share"
              pb={0}
            />
          </Flex>
        </Flex>
        <FormFieldSet sx={{ borderBottom: "none" }}>
          <Flex
            flexDirection={["row"]}
            justifyContent="center"
            alignItems="flex-end"
            mt={[0, 0, -4]}
            mb={[0, 0, -2]}>
            <Flex width={"100%"} mr={4}>
              <Box width="100%">
                <Flex alignItems="flex-end">
                  <Flex pr={4}>
                    <Text>
                      <Trans i18nKey="documents.documentEditor.shareIncome.selectUser" />
                    </Text>
                  </Flex>
                  <Flex width="60%">
                    <Box width="100%">
                      <MultiSelect
                        placeholder=""
                        options={[]}
                        disabled
                        onChange={options => this.onChangeCategory(options)}
                        isMulti={true}
                      />
                    </Box>
                  </Flex>
                </Flex>
              </Box>
            </Flex>
            <Flex width={"30%"}>
              <Box width={["80%", "50%"]}>
                <Flex alignItems="flex-end">
                  <Flex>
                    <Input
                      disabled
                      type="number"
                      name=""
                      onChange={() => null}
                    />
                  </Flex>
                  <Flex alignItems="flex-end">
                    <Text pl={2}>%</Text>
                  </Flex>
                </Flex>
              </Box>
            </Flex>
          </Flex>
        </FormFieldSet>
      </Box>
    );
  }

  private renderPriceAndLicensesBlock() {
    const { document, errors } = this.props;
    const { basePriceAmount, availableLicenseTypes } = this.state;

    const selectedLicenses = document.prices.map(it => it.license);
    const pricingErrors = errors.find(err => err.field === "prices");

    const limitPrice = (e: SyntheticEvent<HTMLInputElement>) => {
      e.currentTarget.value = Math.max(0, parseInt(e.currentTarget.value))
        .toString()
        .slice(0, 10);
    };

    const temporaryLicenses = ["personal", "comercial", "advertising"];

    const editorialRight = i18n.language.toLowerCase() !== "en";

    return (
      <>
        <Flex flexDirection={["column", "row"]} mt={6}>
          <Flex width={["100%", "70%"]}>
            <Box
              width="100%"
              sx={{
                border: "1px solid #000"
              }}>
              <Flex
                alignItems="center"
                py={1}
                px={2}
                sx={{ borderBottom: "1px solid black" }}>
                <Label2
                  i18n="documents.documentEditor.photoPrice"
                  variant="grotTextCaps"
                  pb={0}
                />
                <Tooltip
                  i18nKey={"documents.documentEditor.photoPriceTooltip"}
                  ml={1}
                />
              </Flex>
              <FormFieldSet sx={{ borderBottom: "none" }}>
                <Flex
                  flexDirection="column"
                  justifyContent="center"
                  my={[0, -3]}>
                  <Flex width="100%">
                    <Flex
                      flexDirection="row"
                      alignItems="flex-end"
                      width="100%">
                      <Flex width="100%">
                        <Box width="100%">
                          <Input
                            max={9999999999}
                            width="100%"
                            name="basePrice"
                            color="blue"
                            type="number"
                            onInput={limitPrice}
                            onChange={event => {
                              this.onChangeBasePrice(
                                event.target.value,
                                selectedLicenses
                              );
                            }}
                            value={`${basePriceAmount.getAmount()}`}
                          />
                        </Box>
                      </Flex>
                      <Flex pl={1}>
                        <Text variant="grotText">
                          <Trans i18nKey="documents.documentEditor.ImagePriceCurrency" />
                        </Text>
                      </Flex>
                    </Flex>
                  </Flex>
                  <Flex pt={1}>
                    <Box>
                      <Text variant="grotText">
                        <Trans i18nKey="documents.documentEditor.imagePrice1" />{" "}
                        {!editorialRight && (
                          <>
                            <Text color="blue" sx={{ display: "Inline" }}>
                              <Trans i18nKey="documents.documentEditor.imagePrice2" />
                            </Text>{" "}
                          </>
                        )}
                        <Trans i18nKey="documents.documentEditor.imagePrice3" />
                        {editorialRight && (
                          <>
                            {" "}
                            <Text color="blue" sx={{ display: "Inline" }}>
                              <Trans i18nKey="documents.documentEditor.imagePrice2" />
                            </Text>
                          </>
                        )}
                      </Text>
                    </Box>
                  </Flex>
                </Flex>
              </FormFieldSet>
            </Box>
          </Flex>
          <Flex width="100%" ml={[0, 4]} mt={[4, 0]}>
            <Box
              width="100%"
              sx={{
                border: "1px solid #000"
              }}>
              <Flex width="100%" sx={{ borderBottom: "1px solid black" }}>
                <Flex width="100%" alignItems="center">
                  <Label2
                    i18n="documents.documentEditor.license"
                    variant="grotTextCaps"
                    py={1}
                    px={2}></Label2>
                </Flex>
                <Flex justifyContent="center" width="100%" alignItems="center">
                  <Text fontSize="10px">
                    <Trans i18nKey="documents.documentEditor.youEarn" />
                  </Text>
                </Flex>
                <Flex justifyContent="center" width="100%" alignItems="center">
                  <Text fontSize="10px">
                    <Trans i18nKey="documents.documentEditor.frontFilesFee" />
                  </Text>
                </Flex>
                <Flex justifyContent="center" width="100%" alignItems="center">
                  <Text fontSize="10px">
                    <Trans i18nKey="documents.documentEditor.sellAmount" />
                  </Text>
                </Flex>
              </Flex>
              <Box
                mt={pricingErrors ? 0 : [0, 3]}
                sx={{
                  "& > *": {
                    alignItems: "center",
                    marginBottom: 2,
                    "& > *": {
                      width: "calc(100% / 4)"
                    }
                  },
                  "& > :last-child": { marginBottom: 0 }
                }}>
                {pricingErrors ? (
                  <Text color="red" pl={1} pt={1}>
                    <Trans i18nKey={pricingErrors.i18nKey} />
                  </Text>
                ) : (
                  undefined
                )}
                {/* Available License Types */}
                {availableLicenseTypes
                  .sort((a, b) => b.license.localeCompare(a.license))
                  .map(availableLicenseType => {
                    // basePriceAmount * (1 - sellerFee)
                    // Multiplying by zero for cases like CC where documents are free
                    const earnAmount = basePriceAmount.multiply(
                      availableLicenseType.sellerFee === 0
                        ? 0
                        : 1 - availableLicenseType.sellerFee
                    );
                    // basePriceAmount * sellerFee
                    const marketplaceFee = basePriceAmount.multiply(
                      availableLicenseType.sellerFee === 0
                        ? 0
                        : availableLicenseType.sellerFee
                    );
                    // basePriceAmount * (1 + buyerFee)
                    const sellAmount = basePriceAmount.multiply(
                      availableLicenseType.buyerFee === 0 ? 0 : 1
                    );
                    const isChecked = selectedLicenses.includes(
                      availableLicenseType.license
                    );

                    return (
                      <Flex
                        key={availableLicenseType.license}
                        pt={2}
                        px={[1, 1, 2, 2]}>
                        <Flex>
                          <Checkbox
                            pr={2}
                            size={16}
                            name={availableLicenseType.license}
                            checked={isChecked}
                            onChange={this.handleOnCheckboxChange}>
                            <Text fontSize={1} width="100%" variant="grotText">
                              <Trans
                                i18nKey={`documents.documentEditor.${availableLicenseType.license}`}
                              />
                            </Text>
                          </Checkbox>
                          <Flex alignItems="center" width="0%">
                            <Tooltip
                              i18nKey={`documents.documentEditor.licenseTooltip.${availableLicenseType.license}`}
                            />{" "}
                          </Flex>
                        </Flex>
                        {availableLicenseType.license !== "creativecommons" && (
                          <>
                            <Flex justifyContent="center">
                              <Text variant="grotText">
                                <MonetaryText
                                  monetaryAmount={earnAmount}
                                  fontSize={1}
                                  sx={{ opacity: isChecked ? undefined : 0.65 }}
                                />
                              </Text>
                            </Flex>
                            <Flex justifyContent="center">
                              <Text variant="grotText">
                                <MonetaryText
                                  monetaryAmount={marketplaceFee}
                                  i18n={
                                    "documents.documentEditor.frontFilesFeePrice"
                                  }
                                  i18nOptions={{
                                    percentage:
                                      availableLicenseType.sellerFee * 100
                                  }}
                                  fontSize={1}
                                  sx={{ opacity: isChecked ? undefined : 0.65 }}
                                />
                              </Text>
                            </Flex>
                            <Flex justifyContent="center">
                              <Text variant="grotText">
                                <MonetaryText
                                  monetaryAmount={sellAmount}
                                  fontSize={1}
                                  sx={{ opacity: isChecked ? undefined : 0.65 }}
                                />
                              </Text>
                            </Flex>
                          </>
                        )}
                        {availableLicenseType.license === "creativecommons" && (
                          <>
                            <Flex justifyContent="center">
                              <Text
                                variant="grotText"
                                fontSize={1}
                                sx={{ opacity: isChecked ? undefined : 0.65 }}>
                                <Trans
                                  i18nKey={`documents.documentEditor.creativecommonsFree`}
                                />
                              </Text>
                            </Flex>
                            <Flex justifyContent="center">
                              <Text
                                variant="grotText"
                                fontSize={1}
                                sx={{ opacity: isChecked ? undefined : 0.65 }}>
                                <Trans
                                  i18nKey={`documents.documentEditor.creativecommonsFree`}
                                />
                              </Text>
                            </Flex>
                            <Flex justifyContent="center">
                              <Text
                                variant="grotText"
                                fontSize={1}
                                sx={{ opacity: isChecked ? undefined : 0.65 }}>
                                <Trans
                                  i18nKey={`documents.documentEditor.creativecommonsFree`}
                                />
                              </Text>
                            </Flex>
                          </>
                        )}
                      </Flex>
                    );
                  })}

                {/* Temporary Licenses to show the next licenses */}
                {false && (
                  <>
                    {temporaryLicenses.map((temporaryLicense, index) => {
                      return (
                        <Flex
                          pt={2}
                          pb={index === temporaryLicenses.length - 1 ? 1 : 0}
                          px={[1, 1, 2, 2]}
                          sx={{ opacity: 0.65, pointerEvents: "none" }}>
                          <Flex>
                            <Checkbox
                              pr={2}
                              size={16}
                              name="checkbox"
                              disabled
                              onChange={this.handleOnCheckboxChange}>
                              <Text
                                fontSize={1}
                                width="100%"
                                variant="grotText">
                                <Trans
                                  i18nKey={`documents.documentEditor.${temporaryLicense}`}
                                />
                              </Text>
                            </Checkbox>
                            <Flex alignItems="center" width="0%">
                              <Tooltip
                                i18nKey={`documents.documentEditor.licenseTooltip.${temporaryLicense}`}
                              />{" "}
                            </Flex>
                          </Flex>
                          <>
                            <Flex justifyContent="center">
                              <Text variant="grotText" fontSize={1}>
                                -
                              </Text>
                            </Flex>
                            <Flex justifyContent="center">
                              <Text variant="grotText" fontSize={1}>
                                -
                              </Text>
                            </Flex>
                            <Flex justifyContent="center">
                              <Text variant="grotText" fontSize={1}>
                                -
                              </Text>
                            </Flex>
                          </>
                        </Flex>
                      );
                    })}
                  </>
                )}
              </Box>
            </Box>
          </Flex>
        </Flex>
      </>
    );
  }

  /**
   * This will use machineLabels to list available options and toggle state to userLabels.
   */
  private renderLabelsBlock() {
    const { errors, removeAllLabels } = this.props;
    const labelError = errors.find(err => err.field === "userLabels");
    const removeLabel = (key: SelectOption) => {
      this.onChangeLabels(
        this.getLabelsDefaultValues().filter(
          option => option.value !== key.value
        )
      );
    };
    return (
      <Box width="100%" mt={-6}>
        <Flex
          my={this.getLabelsDefaultValues().length === 0 ? 2 : 3}
          flexWrap="wrap">
          {this.getLabelsDefaultValues().map(it => {
            return (
              <Box
                mr={3}
                py={0}
                my={1}
                bg="blue"
                sx={{ display: "inline", border: "1px solid blue" }}>
                <Flex>
                  <Text
                    px={1}
                    color="white"
                    variant="grotText"
                    sx={{ textTransform: "uppercase" }}>
                    {it.label}{" "}
                  </Text>
                  <Button
                    ml={1}
                    variant="transparent"
                    sx={{
                      "& svg path": { stroke: "white", strokeWidth: 3 }
                    }}
                    onClick={() => removeLabel(it)}>
                    <Icon name="Close" size={20} />
                  </Button>
                </Flex>
              </Box>
            );
          })}
        </Flex>

        <Flex alignItems="center" flexDirection={["column", "row"]}>
          <Box width="100%">
            <Flex alignItems="center">
              <Label2 i18n="documents.documentEditor.labels" />
              <Tooltip
                i18nKey="documents.documentEditor.labelsTooltip"
                ml={1}
              />
            </Flex>
          </Box>
          {removeAllLabels && (
            <Popup
              trigger={
                <Button mb={3} variant="black" width={["60%", "25%"]}>
                  <Text fontSize={[1, 2, 3]} variant="caps">
                    <Trans i18nKey="documents.documentEditor.deleteAllLabels.buttonTitle" />
                  </Text>
                </Button>
              }
              heading={i18next.t(
                "documents.documentEditor.deleteAllLabels.header"
              )}
              cancelText={i18next.t(
                "documents.documentEditor.deleteAllLabels.cancel"
              )}
              submit={close => {
                removeAllLabels();
                close();
              }}
              submitText={i18next.t(
                "documents.documentEditor.deleteAllLabels.submit"
              )}>
              <>
                <Text textAlign="center">
                  {i18next.t(
                    "documents.documentEditor.deleteAllLabels.content"
                  )}
                </Text>
              </>
            </Popup>
          )}
        </Flex>
        <Flex
          width="100%"
          alignItems="flex-end"
          flexDirection={["column", "row"]}>
          <Box width="100%">
            <Text variant="grotTextCaps" fontSize={0} pb={["6px", "11px"]}>
              <Trans i18nKey="documents.documentEditor.selectLabels" />
            </Text>
            <MultiSelectSearch
              filters={[
                { field: "class_name", values: ["label"], type: "any" }
              ]}
              resultFields={{
                real_id: { raw: {} },
                title: { raw: {} }
              }}
              placeholder={i18next.t(
                "search.shared.multiSelectSearch.placeholder"
              )}
              defaultValues={this.getLabelsDefaultValues()}
              hideLabels={true}
              errorI18n={labelError ? labelError.i18nKey : undefined}
              mapResults={results =>
                results.map((result: any) => ({
                  value: getRaw(result, "real_id"),
                  label: getRaw(result, "title")
                }))
              }
              onChange={this.onChangeLabels}
            />
          </Box>
          <Box width="100%" ml={[0, 4]} mt={[3, 0]}>
            {this.rendermanualLabelsBlock()}
          </Box>
        </Flex>
      </Box>
    );
  }

  private rendermanualLabelsBlock() {
    const { document } = this.props;
    const { specificLabelTitle } = this.state;

    const createNewLabel = async () => {
      const newmanualLabels = document.userLabels.filter(
        it => specificLabelTitle === it.title
      );

      if (!(newmanualLabels.length > 0)) {
        //check if dont exist then add or error message
        if (specificLabelTitle && specificLabelTitle.length > 0) {
          this.setState({ isSubmitting: true });
          try {
            const label = await createLabel(specificLabelTitle);
            this.onAddLabel(label);
            this.setState({ specificLabelTitle: "" });
          } catch (error) {
            Toast.apiError(error);
          }
          this.setState({ isSubmitting: false });
        }
      } else {
        Toast.error({
          title: { key: "documents.documentEditor.manualLabelsError" }
        });
      }
    };
    return (
      <>
        <Text variant="grotTextCaps" fontSize={0}>
          <Trans i18nKey="documents.documentEditor.createLabels" />
        </Text>
        <Box
          width="100%"
          sx={{
            position: "relative",
            "& svg path": { fill: "black", strokeWidth: 3 }
          }}>
          <Input
            width="100%"
            name="label"
            type="text"
            onChange={event =>
              this.setState({ specificLabelTitle: event.target.value })
            }
            value={specificLabelTitle}
            onKeyDown={event => {
              // Check if event is enter
              if (event.keyCode === 13) {
                event.stopPropagation();
                createNewLabel();
              }
            }}
          />
          <Button
            sx={{
              position: "absolute",
              right: 2,
              top: 2
            }}
            variant="transparent"
            onClick={() => createNewLabel()}
            disabled={!specificLabelTitle}>
            <Icon name="Plus" size={24} color="blue" />
          </Button>
        </Box>
      </>
    );
  }

  private renderCategoriesBlock() {
    const { errors } = this.props;
    const categoryError = errors.find(err => err.field === "userCategories");
    return (
      <Box mt={6}>
        <CategorySelect
          defaultValues={this.getCategoriesDefaultValues()}
          onChange={options => this.onChangeCategory(options)}
          errorI18n={categoryError ? categoryError.i18nKey : undefined}
          labelI18n="documents.documentEditor.categories"
          infoI18n="documents.documentEditor.categoriesTooltip"
          isMulti={true}
        />
      </Box>
    );
  }

  private renderCollectionsBlock() {
    const { errors, loggedInUser, showCollectionsDropdown } = this.props;
    const { collectionPopupOpen } = this.state;
    const collectionError = errors.find(err => err.field === "collections");

    if (showCollectionsDropdown) {
      return (
        <Box my={3}>
          <MultiSelectSearch
            filters={[
              { field: "class_name", values: ["collection"], type: "any" },
              { field: "uid", values: [loggedInUser.id], type: "any" }
            ]}
            resultFields={{
              real_id: { raw: {} },
              privacy: { raw: {} },
              uid: { raw: {} },
              title: { raw: {} }
            }}
            isMulti={false}
            defaultValues={this.getCollectionsDefaultValues()}
            labelI18n="documents.documentEditor.collections"
            infoI18n="documents.documentEditor.collectionsTooltip"
            errorI18n={collectionError ? collectionError.i18nKey : undefined}
            mapResults={results =>
              results.map((result: any) => ({
                value: getRaw(result, "real_id"),
                label: getRaw(result, "title")
              }))
            }
            noOptions={
              <Button
                variant="black"
                onClick={() => this.setState({ collectionPopupOpen: true })}>
                <Trans i18nKey="documents.documentEditor.addCollection" />
              </Button>
            }
            onChange={this.onChangeCollections}
          />
          <CollectionEditPopup
            open={collectionPopupOpen}
            hideTrigger={true}
            onCompleted={collection => this.onAddCollection(collection)}
            onClose={() => this.setState({ collectionPopupOpen: false })}
          />
        </Box>
      );
    }

    return null;
  }

  private renderAttributesBlock = () => (
    <Box>
      <MultiSelect
        isMulti={true}
        options={documentAttributes.map(it => ({
          value: it,
          label: i18next.t(`iptc.scene.${it}`)
        }))}
        onChange={this.onChangeAttributes}
        defaultValues={this.getAttributesDefaultValues()}
        labelI18n="documents.documentEditor.attributes"
        infoI18n="documents.documentEditor.attributesTooltip"
        placeholder={i18next.t("search.shared.multiSelectSearch.placeholder")}
      />
    </Box>
  );

  private renderCreditsBlock = () => (
    <Box mt={6}>
      <MultiSelectSearch
        filters={[{ field: "class_name", values: ["user"], type: "any" }]}
        resultFields={{
          real_id: { raw: {} },
          professional_name: { raw: {} }
        }}
        defaultValues={this.getCreditsDefaultValues()}
        labelI18n="documents.documentEditor.credits"
        infoI18n="documents.documentEditor.creditsTooltip"
        mapResults={results =>
          results.map((result: any) => ({
            value: getRaw(result, "real_id"),
            label: getRaw(result, "professional_name")
          }))
        }
        onChange={options => {
          this.onChangeCredits(options);
        }}
      />
    </Box>
  );
}

const mapStateToProps = (state: any) => ({
  loggedInUser: state.loggedInUser,
  language: state.language.code
});

export const DocumentEditor = compose<Props, PropsExternal>(
  connect(mapStateToProps),
  withAuthorization()
)(DocumentEditorBase);
