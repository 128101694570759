import React from "react";
import { compose } from "recompose";
import { Tabs, PageHeader } from "app/shared";
import { Management } from "./Management";
import i18next from "i18next";
import { withAuthorization } from "authentication/session";
import { ContentArea } from "layout";
import { Account } from "./account";
import { Billing } from "./billing";
import { RouteComponentProps, withRouter } from "react-router-dom";

interface Params {
  filter?: string;
}

type Props = RouteComponentProps<Params>;

const SettingsBase: React.FC<Props> = ({ match }) => {
  const filter = match.params.filter ?? "account";

  return (
    <ContentArea>
      <PageHeader
        variant="1"
        title={i18next.t("account.settings.menu.pageTitle")}
        subtitle={i18next.t("account.settings.menu.pageSubtitle")}
      />
      <Tabs
        my={4}
        variant="right"
        activeName={filter}
        tabs={[
          {
            name: "account",
            label: i18next.t("account.settings.menu.login"),
            href: "/settings/account"
          },
          {
            name: "billing",
            label: i18next.t("account.settings.menu.billing"),
            href: "/settings/billing"
          },
          {
            name: "management",
            label: i18next.t("account.settings.menu.management"),
            href: "/settings/management"
          }
        ]}
      />
      {filter === "management" && <Management />}
      {filter === "billing" && <Billing />}
      {filter === "account" && <Account />}
    </ContentArea>
  );
};

export const Settings = compose<Props, any>(
  withRouter,
  withAuthorization()
)(SettingsBase);
