import { CollectionView } from "app/collections";
import { DocumentView } from "app/documents";
import { BucketView } from "app/buckets";
import { Collection, DocumentThumbnail, Bucket } from "models";
import React from "react";
import { LatestItem } from "./models";

interface Props {
  latestItem: LatestItem;
  latestList: DocumentThumbnail[];
  onClick?: () => void;
  showDocumentHoverInfo?: boolean;
}

export const LatestItemView: React.FC<Props> = ({
  latestItem,
  latestList,
  onClick,
  showDocumentHoverInfo
}) => {
  if (latestItem.className === "document") {
    return (
      <DocumentView
        document={(latestItem as unknown) as DocumentThumbnail}
        documentsList={latestList}
        showHoverInfo={showDocumentHoverInfo}
        onClick={onClick}
        width="100%"
        height="425px"
        variant="flex"
        whiteMode={true}
      />
    );
  } else if (latestItem.className === "collection") {
    return (
      <CollectionView
        collection={(latestItem as unknown) as Collection}
        onClick={onClick}
        width="100%"
        height="425px"
        whiteMode={true}
      />
    );
  } else {
    return (
      <BucketView
        bucket={(latestItem as unknown) as Bucket}
        onClick={onClick}
        width="100%"
        height="425px"
        whiteMode={true}
      />
    );
  }
};
