import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import * as Translations from "./resources";
import environment from "configurations";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // we init with resources
    resources: {
      en: {
        translations: Translations.EN
      },
      pt: {
        translations: Translations.PT
      },
      "pt-BR": {
        translations: Translations.PTBR
      },
      es: {
        translations: Translations.ES
      },
      fr: {
        translations: Translations.FR
      }
    },
    fallbackLng: "en",
    debug: environment.name === "development",
    ns: ["translations"],
    defaultNS: "translations",
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
