import * as type from "./constants";

export function setUnreadMessages(count: number) {
  return {
    count,
    type: type.SET_UNREAD_MESSAGES
  };
}

export function incrementUnreadMessages() {
  return {
    type: type.INCREMENT_UNREAD_MESSAGES
  };
}
