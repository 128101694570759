import { CollapsableButton } from "app/shared";
import i18next from "i18next";
import React, { ReactNode } from "react";
import { Sticky } from "react-stickup";
import { Box, BoxProps } from "rebass";
import { ResetFiltersButton } from "../filters/buttons/ResetFiltersButton";

interface Props extends BoxProps {
  className?: string;
  children: ReactNode;
  toggleSidebar: () => void;
  isSidebarCollapsed: boolean;
  isMobile?: boolean;
  marginTop?: number | number[];
  disableSticky?: boolean;
}

const Filters: React.FC<Props> = ({
  children,
  isSidebarCollapsed,
  toggleSidebar,
  isMobile,
  marginTop,
  disableSticky,
  ...rest
}) => (
  <Box paddingBottom={isMobile ? 1 : 0} {...(rest as any)}>
    <Sticky disabled={disableSticky} style={{ zIndex: 2 }}>
      <CollapsableButton
        variant="black"
        fontSize={[1, 2, 3]}
        px={[1, 1, 1, 1, 3]}
        py="8px"
        mt={marginTop}
        sx={{
          border: 1,
          borderColor: "black.0"
        }}
        onClick={toggleSidebar}
        collapsed={isSidebarCollapsed}>
        {i18next.t("search.filters.btn")}
      </CollapsableButton>

      {!isSidebarCollapsed && (
        <Box bg="white">
          {children}
          <ResetFiltersButton />
        </Box>
      )}
    </Sticky>
  </Box>
);

export default Filters;
