import { Notification } from "app/account/models";
import { MenuWrapper } from "app/navbar/MenuWrapper";
import { Button, Icon } from "app/shared";
import React from "react";
import { Trans } from "react-i18next";
import { Box, Flex, Text } from "rebass";
import { NotificationsViewer } from "./NotificationsViewer";

interface Props {
  notifications: Notification[];
}

export const NotificationsMenu: React.FC<Props> = ({ notifications }) => (
  <MenuWrapper maxWidth="350px" backgroundColor="white">
    <Flex p={3} alignItems="center" justifyContent="space-between">
      <Text fontSize={7} variant="caps">
        <Trans i18nKey="navbar.notifications.title" />
      </Text>
      <Button href="/settings/management">
        <Icon name="Settings" size={18} />
      </Button>
    </Flex>
    <Box overflowY="auto" height="237px">
      {notifications.length ? (
        <NotificationsViewer
          notifications={notifications}
          sideBorders={false}
        />
      ) : (
        <Text fontSize={6} p={3} pt={0}>
          <Trans i18nKey="navbar.notifications.noNotifications" />
        </Text>
      )}
    </Box>
    <Button
      variant="link.button"
      width="100%"
      href="/notifications"
      sx={{
        border: 0,
        borderTop: 1
      }}>
      <Trans i18nKey="navbar.notifications.viewAll" />
    </Button>
  </MenuWrapper>
);
