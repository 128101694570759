import React, { createContext, useContext, useMemo } from "react";
import useMedia from "use-media";
import { breakpoints } from "./theme";

interface Props {
  children: React.ReactNode;
}

export interface MediaQueryContextProps {
  isSmall: boolean;
  isSmallMedium: boolean;
  isMedium: boolean;
  isMediumLarge: boolean;
  isLarge: boolean;
}

export const MediaQueryContext = createContext<MediaQueryContextProps>({
  isSmall: false,
  isSmallMedium: false,
  isMedium: false,
  isMediumLarge: false,
  isLarge: false
});

export const MediaQueryProvider: React.FC<Props> = ({ children }) => {
  const mediaQueries = {
    small: `(max-width: ${breakpoints[0]})`,
    smallMedium: `(max-width: ${breakpoints[1]})`,
    medium: `(max-width: ${breakpoints[2]})`,
    mediumLarge: `(max-width: ${breakpoints[3]})`,
    large: `(max-width: ${breakpoints[4]})`
  };

  const isSmall = useMedia(mediaQueries.small);
  const isSmallMedium = useMedia(mediaQueries.smallMedium);
  const isMedium = useMedia(mediaQueries.medium);
  const isMediumLarge = useMedia(mediaQueries.mediumLarge);
  const isLarge = useMedia(mediaQueries.large);

  const value = useMemo(
    () => ({ isSmall, isSmallMedium, isMedium, isMediumLarge, isLarge }),
    [isSmall, isSmallMedium, isMedium, isMediumLarge, isLarge]
  );

  return (
    <MediaQueryContext.Provider value={value}>
      {children}
    </MediaQueryContext.Provider>
  );
};

export const useMediaQueryContext = () => useContext(MediaQueryContext);
