import { Icon } from "app/shared";
import React from "react";
import { Box } from "rebass";

type ArrowDirection = "left" | "right";

interface Props {
  onClick: () => void;
  direction: ArrowDirection;
  disabled: boolean;
}

const Arrow: React.FC<Props> = ({ onClick, direction, disabled }) => {
  const isLeft = direction === "left";
  return !disabled ? (
    <Box
      backgroundColor="darkGrey.0"
      px={3}
      py={2}
      display="grid"
      sx={{
        cursor: "pointer",
        position: "fixed",
        top: ["25vh", "25vh", "25vh", "30vh"],
        right: isLeft ? undefined : ["5%", "5%", "5%", "5%", "12.5%"],
        left: isLeft ? ["5%", "5%", "5%", "5%", "12.5%"] : undefined,
        zIndex: 4
      }}
      onClick={disabled ? undefined : onClick}>
      <Icon
        name={isLeft ? "ArrowLeft" : "ArrowRight"}
        size={[16, 32, 64]}
        sx={{ zIndex: 5 }}
      />
    </Box>
  ) : null;
};

export default Arrow;
