import { SocialMediaInput } from "app/account/models";
import { TOptions } from "i18next";
import { LicensePrice } from "marketplace";
import { ISOLanguageCode } from "i18n/resources/supportedLanguages";

export interface JsonObject<T> {
  [key: string]: T;
}

export type Status = "pending" | "active" | "deleted";

export type Privacy = "public" | "private";

/**
 * Document service
 */
export interface CategoryTranslation {
  language: ISOLanguageCode;
  title: string;
  description?: string;
}

export interface Category {
  id: number;
  taxonomy: string;
  translations: CategoryTranslation[];
}

export interface Label {
  id: number;
  status: Status;
  title: string;
}

export type DocumentType = "image" | "document";

export interface Owner {
  id: string;
  name: string;
  picture?: string;
}

export type DocumentId = number;
export type VersionNumber = number;

export interface VersionedDocument {
  document: Document;
  version: VersionNumber;
}

export interface DocumentTranslation {
  language: ISOLanguageCode;
  title?: string;
  description?: string;
}

export interface Document
  extends JsonObject<
    | DocumentId
    | string
    | string[]
    | undefined
    | Owner
    | Owner[]
    | Category[]
    | Label[]
    | Collection[]
    | FileMetadata
    | DocumentTranslation[]
    | LicensePrice[]
  > {
  id: DocumentId;
  type: DocumentType;
  createdAt: string;
  takenAt: string;
  uid: string;
  owner: Owner;
  hash: string;
  shortHash: string;
  mediaSource: string;
  latitude?: string;
  longitude?: string;
  city?: string;
  country?: string;
  metadata: FileMetadata;
  machineCategories: Category[];
  userCategories: Category[];
  collections: Collection[];
  machineLabels: Label[];
  userLabels: Label[];
  attributes: string[];
  credits: Owner[];
  translations: DocumentTranslation[];
  prices: LicensePrice[];
  thumbnail: string;
  thumbnailNormal: string;
  thumbnailLarge: string;
}

export interface ValidationError {
  i18nKey: string;
  options?: TOptions;
  field: string;
}

export interface FileMetadata {
  contentType: string;
  extension: string;
  size: number;
  name?: string;
  width?: number;
  height?: number;
  orientation?: "horizontal" | "vertical" | "square";
  date?: string;
  latitude?: string;
  longitude?: string;
}

export interface DocumentThumbnail {
  id: number;
  thumbnail: string;
  width?: number;
  height?: number;
  owner?: Owner;
  city?: string;
  country?: string;
  createdAt?: string;
  takenAt?: string;
  title?: string;
  description?: string;
  owner_picture?: string;
}

export interface ExtendedDocumentThumbnail extends DocumentThumbnail {
  uid: string;
  original: string;
}

export interface UploadTask {
  id: number;
  status: "created" | "submitted" | "processed" | "published" | "deleted";
  uid: string;
  documents: Document[];
  total: number;
  published: number;
  processed: number;
  submitted: number;
  pending: number;
  deleted: number;
}

export interface Dictionary {
  id: number;
  name: string;
  taxonomy: string;
  words: string[];
}

export interface Collection
  extends JsonObject<
    | number
    | Privacy
    | Owner
    | string
    | boolean
    | undefined
    | DocumentThumbnail[]
  > {
  id: number;
  createdAt: string;
  privacy: Privacy;
  uid: string;
  owner: Owner;
  title: string;
  description: string;
  shareWithLink: boolean;
  city?: string;
  country?: string;
  latitude?: string;
  longitude?: string;
  totalDocuments: number;
  documents: DocumentThumbnail[];
}

export interface Bucket
  extends JsonObject<
    number | Privacy | Owner | string | undefined | DocumentThumbnail[]
  > {
  id: number;
  createdAt: string;
  privacy: Privacy;
  uid: string;
  owner: Owner;
  title: string;
  totalDocuments: number;
  totalCollections: number;
  totalItems: number;
  documents: DocumentThumbnail[];
}

export type ListUpdateMode = "append" | "remove";

export interface ListIntUpdate {
  mode: ListUpdateMode;
  list: number[];
}

/**
 * User service
 */
export const PORTFOLIO_VERIFIED_ROLE = "users:portfolioVerified";
export const USER_VERIFIED_ROLE = "users:verified";

export interface AuthUser {
  uid: string;
  roles: string[];
}

//User: model with the properties stored on backend
export interface UserTranslation {
  language: ISOLanguageCode;
  about: string;
  donations?: string;
}

export interface User
  extends JsonObject<
    | string
    | string[]
    | undefined
    | UserBilling
    | SocialMediaInput[]
    | UserTranslation[]
  > {
  id: string;
  status: "unverified" | "pendingReview" | "verified";
  professionalName: string;
  fullName: string;
  username: string;
  nationality?: string;
  picture?: string;
  latitude?: string;
  longitude?: string;
  country?: string;
  city?: string;
  userTypes: string[];
  websites: string[];
  publishedWorks: string[];
  translations: UserTranslation[];
  roles: string[];
  billing?: UserBilling;
  skills: string[];
  collaboration: string[];
  social: SocialMediaInput[];
}

export interface UserBilling {
  name: string;
  nationality: string;
  nationalCardNumber: string;
  vat: string;
  address: string;
  postalCode: string;
  city: string;
  country: string;
}

export interface UserThumbnail {
  id: string;
  name: string;
  picture?: string;
  city?: string;
  country?: string;
  status?: string;
}

export interface NamedBlob {
  name: string;
  file: File;
}

export interface SignedUrl {
  url: string;
}

export interface ColumnItem {
  isCollapsed: string;
  notCollapsed: string;
  titleFontSize?: number | number[];
  textBottomFontSize?: number | number[];
  ownerNameFontSize?: number | number[];
}

// Adapted from: http://cv.iptc.org/newscodes/scene/
export const documentAttributes = [
  "headshot",
  "halfLength",
  "fullLength",
  "profile",
  "rearView",
  "single",
  "couple",
  "two",
  "group",
  "generalView",
  "underWater",
  "nightScene",
  "satellite",
  "exteriorView",
  "interiorView",
  "closeUp",
  "action",
  "performing",
  "posing",
  "symbolic",
  "offBeat",
  "movieScene"
];
