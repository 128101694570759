import React from "react";
import Svg, { SvgProps } from "primitives/Svg";

const SvgDonate = ({ width, height, size, color }: SvgProps) => (
  <Svg
    viewBox="0 0 24 24"
    width={size || width}
    height={size || height}
    color={color}>
    <path d="M12 4c2.1-3 5.8-3.4 8.1-1.7-.4.4-.7.8-1.1 1.2-.4-.2-.8-.4-1.3-.5-1.4-.3-2.6.1-3.7 1-.5.4-.9.9-1.1 1.5-.2.5-.5.7-.9.7s-.7-.2-.9-.6c-.5-1-1.3-1.8-2.3-2.3-1.9-.8-4-.2-5.2 1.4-.7 1-1 2-1 3.2.1 2.2.8 4.2 2 6 .6.9 1.3 1.8 2.1 2.6l.1.1c-.4.4-.7.8-1.1 1.1-.2-.2-.4-.5-.7-.7-1-1.1-1.8-2.3-2.5-3.6-.9-1.8-1.5-3.8-1.4-5.8.1-2.2.9-4 2.8-5.3 2.1-1.4 5-1.2 6.9.4.4.4.8.8 1.1 1.2 0 .1.1.1.1.1zM21.8 4c.3.4.5.9.7 1.3.4 1.1.5 2.2.4 3.3-.2 2.6-1.2 4.8-2.7 6.9-1.3 1.8-2.9 3.3-4.6 4.6-.8.6-1.7 1.2-2.6 1.7-.6.3-1.2.3-1.8 0-1.2-.7-2.4-1.5-3.5-2.3v-.1l1.1-1.1c.2.1.3.3.5.4.8.6 1.7 1.2 2.6 1.7.2.1.3.1.5 0 1.5-.8 2.9-1.8 4.1-2.9 1.5-1.3 2.8-2.9 3.7-4.7.8-1.5 1.2-3.2 1.3-4.9 0-.9-.2-1.7-.6-2.5-.1-.1 0-.2 0-.2.2-.5.5-.8.9-1.2-.1 0 0 0 0 0z" />
    <path d="M12.2 8.4V11.1c0 .1 0 .2.2.2.5.1 1.1.2 1.6.5.7.4 1 1 .9 1.8 0 1.1-.8 1.8-1.9 2.1-.2.1-.5.1-.8.1v.9h-.6v-1c-.9-.1-1.7-.3-2.3-1.1-.4-.5-.5-1-.6-1.6h1c.1.6.2 1.1.7 1.4.3.3.8.4 1.2.4v-3c-.4-.1-.7-.2-1.1-.3-.4-.1-.8-.4-1.1-.8-.7-1-.3-2.4.8-2.9.3-.2.7-.3 1.1-.3h.3v-.6h.6v.6c.3 0 .5.1.7.1 1 .2 1.5.9 1.7 1.8v.2h-.9l-.1-.1c0-.7-.6-1.1-1.4-1.1zm0 3.7V15c.1 0 .3 0 .4-.1.5-.1.9-.4 1.2-.9.2-.5.1-1.1-.2-1.4-.4-.3-.9-.4-1.4-.5zm-.6-3.8c-.5 0-.8.2-1.1.4-.6.4-.6 1.3-.1 1.8.3.3.8.4 1.2.5V8.3z" />
  </Svg>
);

SvgDonate.defaultProps = {
  width: 24,
  height: 24
};
export default SvgDonate;
