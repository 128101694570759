import React from "react";
import Svg, { SvgProps } from "primitives/Svg";

const SvgDollar = ({ width, height, size, color }: SvgProps) => (
  <Svg
    width={size || width}
    height={size || height}
    viewBox="0 0 100 152"
    color={color}>
    <path d="M55.246 152h-9.594v-16.569c-12.984-1.223-22.667-3.611-29.048-7.166C5.337 121.89-.196 111 .005 95.615h26.683c.915 6.997 2.21 11.699 3.86 14.069 2.566 3.724 7.608 6.131 15.126 7.241V81.36l-8.009-1.993c-12.583-3.104-21.462-7.448-26.638-12.996-5.176-5.548-7.764-12.244-7.764-20.068 0-5.172 1.004-9.855 3.011-14.067a32.097 32.097 0 018.456-10.909c4.685-3.95 9.883-6.657 15.617-8.162 3.48-.94 8.59-1.636 15.305-2.087V0h9.594v11.247c10.686.714 19.075 2.953 25.188 6.657 11.088 5.944 16.822 15.573 17.201 28.907H71.644c-.514-4.946-1.495-8.557-2.923-10.833-2.477-3.95-6.96-6.112-13.475-6.507v31.728c15.617 4.551 26.103 8.576 31.48 12.074C95.581 79.103 100 87.322 100 97.929c0 13.993-6.09 24.149-18.272 30.487-7.452 3.893-16.264 6.169-26.482 6.827V152zM45.652 29.64c-5.734.113-9.973 1.41-12.74 3.874-2.765 2.483-4.149 5.85-4.149 10.119 0 4.664 2.075 8.35 6.225 11.077 2.298 1.505 5.867 2.896 10.664 4.157V29.62v.02zm9.594 87.04c5.153-.564 8.879-1.561 11.244-2.99 4.105-2.558 6.158-6.978 6.158-13.241 0-4.777-1.92-8.557-5.757-11.322-2.275-1.598-6.157-3.272-11.623-5.002v32.574l-.022-.019z" />
  </Svg>
);

SvgDollar.defaultProps = {
  width: 24,
  height: 24
};

export default SvgDollar;
