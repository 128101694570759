import { Notification } from "app/account/models";
import { Button, FormattedDate } from "app/shared";
import React, { ReactNode } from "react";
import { Box, Flex, Text } from "rebass";
import i18next from "i18next";
import { PortfolioStatusPopup } from "../popups/PortfolioStatusPopup";
import { ProposalSellerPopUp } from "app/proposals/ProposalSellerPopUp";
import { ProposalBuyerPopUp } from "app/proposals/ProposalBuyerPopUp";

interface Props {
  notifications: Notification[];
  sideBorders?: boolean;
}

export const NotificationsViewer: React.FC<Props> = ({
  notifications,
  sideBorders
}) => {
  /**
   * TODO: In the future include username additional fields in the notification object to prevent retrieving info from subject
   */
  const getNotificationComponent = (notification: Notification): ReactNode => {
    if (notification.subject && notification.entityId) {
      switch (notification.subject.toLowerCase()) {
        case "documents processed": {
          // We've processed your content and it's now ready for you to fill in the details
          const href = `/submit-content`;
          const label = i18next.t(
            "account.notificationsViewer.documentsProcessed"
          );
          return (
            <Button href={href}>
              <Text fontSize={4}>{label}</Text>
            </Button>
          );
        }
        case "new buy": {
          // You bought an item
          const href = `/documents/${notification.entityId}`;
          const label = i18next.t("account.notificationsViewer.newBuy", {
            id: notification.entityId
          });
          return (
            <Button href={href}>
              <Text fontSize={4}>{label}</Text>
            </Button>
          );
        }
        case "new sell": {
          // User {{NAME}} bought you an item
          const href = `/users/${notification.entityId}`;
          const user =
            notification.shortDescription
              ?.replace("User ", "")
              ?.replace(" bought you an item", "") ?? "User";
          const label = i18next.t("account.notificationsViewer.newSell", {
            user,
            id: notification.entityId
          });
          return (
            <Button href={href}>
              <Text fontSize={4}>{label}</Text>
            </Button>
          );
        }
        case "portfolio review": {
          // Your portfolio was {{STATUS}}
          const status = notification.shortDescription
            ?.toLowerCase()
            ?.includes("approved")
            ? "approved"
            : "rejected";
          const key =
            status === "approved"
              ? "account.notificationsViewer.portfolioReviewApproved"
              : "account.notificationsViewer.portfolioReviewRejected";
          const label = i18next.t(key);
          return (
            <PortfolioStatusPopup
              trigger={
                <Button variant="transparent">
                  <Text fontSize={4} textAlign="left">
                    {label}
                  </Text>
                </Button>
              }
              status={status}
            />
          );
        }
        case "new follow": {
          // User {{NAME}} started following you
          const href = `/users/${notification.entityId}`;
          const user =
            notification.shortDescription
              ?.replace("User ", "")
              ?.replace(" started following you", "") ?? "User";
          const label = i18next.t("account.notificationsViewer.newFollow", {
            user
          });
          return (
            <Button href={href}>
              <Text fontSize={4}>{label}</Text>
            </Button>
          );
        }
        case "new received donation": {
          const href = `/transactions`;
          const user = notification.shortDescription
            ?.replace(
              "made a donation",
              i18next.t("account.notificationsViewer.newDonationReceived1")
            )
            ?.replace("User", "");
          const label = i18next.t(
            "account.notificationsViewer.newDonationReceived2",
            {
              user,
              id: notification.entityId
            }
          );
          return (
            <Button href={href}>
              <Text fontSize={4}>{label}</Text>
            </Button>
          );
        }
        case "new made donation": {
          const href = `/transactions`;
          const user = notification.shortDescription
            ?.replace(
              "You made a donation of",
              i18next.t("account.notificationsViewer.newDonationSended1")
            )
            ?.replace(
              "to",
              i18next.t("account.notificationsViewer.newDonationSended2")
            );
          const label = user;
          return (
            <Button href={href}>
              <Text fontSize={4}>{label}</Text>
            </Button>
          );
        }
        case "new buy proposal": {
          return (
            <ProposalSellerPopUp
              width="100%"
              variant="transparent"
              title={notification.shortDescription!}
              proposalId={Number(notification.entityId)}
            />
          );
        }
        case "buy proposal accepted": {
          return (
            <ProposalBuyerPopUp
              width="100%"
              variant="transparent"
              title={notification.shortDescription!}
              proposalId={Number(notification.entityId)}
            />
          );
        }
        case "buy proposal rejected": {
          return (
            <ProposalBuyerPopUp
              width="100%"
              variant="transparent"
              title={notification.shortDescription!}
              proposalId={Number(notification.entityId)}
            />
          );
        }
        default: {
          return (
            <Button href="#">
              <Text fontSize={4}>
                {notification.shortDescription ?? notification.subject}
              </Text>
            </Button>
          );
        }
      }
    }

    return <></>;
  };

  return (
    <>
      {notifications.map((notification: Notification) => {
        return (
          <Flex
            key={notification.id}
            mb={2}
            sx={{
              borderTop: 1,
              borderBottom: 1,
              borderLeft: sideBorders ? 1 : 0,
              borderRight: sideBorders ? 1 : 0,
              borderColor: "black.0"
            }}>
            <Box
              backgroundColor={notification.read ? undefined : "blue"}
              minWidth="15px"
            />
            <Box p={3} sx={{ flexGrow: 1 }}>
              <Text fontSize={1} mb={1}>
                <FormattedDate
                  format="number"
                  date={notification.createdAt}
                  showHours
                />
              </Text>
              {getNotificationComponent(notification)}
            </Box>
          </Flex>
        );
      })}
    </>
  );
};

NotificationsViewer.defaultProps = {
  sideBorders: true
};
