/* eslint-disable react-hooks/exhaustive-deps */
import { UserPicture } from "app/account";
import { Button } from "app/shared";
import { User } from "models";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Box } from "rebass";
import { compose } from "recompose";
import { AuthUserContext } from "authentication/session";

interface Props extends RouteComponentProps {
  user: User;
}

const UserPictureMenuBase: React.FC<Props> = ({ history, user }) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    history.listen(() => {
      open && setOpen(false);
    });
  });

  const picture = user.picture;

  return (
    <AuthUserContext.Consumer>
      {({ authUser }) => (
        <Box sx={{ borderLeft: 1, borderColor: "black.0" }}>
          {authUser && (
            <Button
              variant="transparent"
              px={[0, 1, 2]}
              pt={[1, 2, 3]}
              href={`/users/${authUser.uid}/collections`}>
              <UserPicture
                picture={picture ?? undefined}
                userVerified={user.status}
                verifiedIconSize={24}
                height="48px"
              />
            </Button>
          )}
        </Box>
      )}
    </AuthUserContext.Consumer>
  );
};

const mapStateToProps = ({ loggedInUser }: User) => ({
  user: loggedInUser
});

export const UserPictureMenu = compose<Props, any>(
  connect(mapStateToProps),
  withRouter
)(UserPictureMenuBase);
