import { BackButton, PageHeader, Tabs } from "app/shared";
import { Firebase } from "authentication/firebase";
import { withAuthorization } from "authentication/session";
import { ContentArea } from "layout";
import { User } from "models";
import React, { Dispatch, PureComponent } from "react";
import { connect } from "react-redux";
import { Box } from "rebass";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { compose } from "recompose";
import { Action } from "redux";
import { SocialMediaInput, WebsiteInput } from "../models";
import * as actions from "../reducers/actions";
import { getUser } from "../services";
import i18next from "i18next";
import { ISOLanguageCode } from "i18n/resources/supportedLanguages";
import { BusinessInfo, Collab, PromoTools, SkillRoles } from "../onboarding";

export const DEFAULT_EMPTY_SOCIAL_MEDIA: SocialMediaInput = {
  name: "none",
  value: ""
};

export const DEFAULT_EMPTY_WEBSITE: WebsiteInput = {
  value: ""
};

interface Props extends RouteComponentProps {
  firebase: Firebase;
  user?: User;
  language: ISOLanguageCode;
  setLoggedInUser: (user: User) => void;
}

interface State {
  currentTab?: string;
  user?: User;
}

class EditMyInfoBase extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      user: undefined,
      currentTab: "businessInfo"
    };
  }

  loadUser = () => {
    const { firebase, setLoggedInUser } = this.props;

    if (firebase.isLogged()) {
      getUser(firebase.getCurrentUser().uid).then(user => {
        setLoggedInUser(user);
        this.setState({
          user: user
        });
      });
    }
  };

  componentDidMount() {
    this.loadUser();
  }

  render() {
    const { user, currentTab } = this.state;

    return (
      <ContentArea>
        <BackButton />
        <PageHeader
          variant="1"
          title={i18next.t("account.editMyInfo.pageTitle")}
          subtitle={i18next.t("account.editMyInfo.pageSubtitle")}
          mb={4}
        />
        {user && (
          <>
            <Tabs
              activeName="businessInfo"
              wrapTabs={true}
              fontSize={[1, 4]}
              tabs={[
                {
                  name: "businessInfo",
                  label: i18next.t("documents.submitContent.businessInfoTitle"),
                  onClick: () => this.setState({ currentTab: "businessInfo" })
                },
                {
                  name: "skillRoles",
                  label: i18next.t("documents.submitContent.skillRolesTitle"),
                  onClick: () => this.setState({ currentTab: "skillRoles" })
                },
                {
                  name: "promoTools",
                  label: i18next.t("documents.submitContent.promoToolsTitle"),
                  onClick: () => this.setState({ currentTab: "promoTools" })
                },
                {
                  name: "collab",
                  label: i18next.t("documents.submitContent.collabTitle"),
                  onClick: () => this.setState({ currentTab: "collab" })
                }
              ]}
            />
            {currentTab === "businessInfo" && (
              <Box mt={6}>
                <BusinessInfo
                  hideHeader={true}
                  user={user}
                  onComplete={() => this.loadUser()}
                  submiti18n="account.onboarding.save"
                />
              </Box>
            )}
            {currentTab === "skillRoles" && (
              <Box mt={6}>
                <SkillRoles
                  hideHeader={true}
                  user={user}
                  onComplete={() => this.loadUser()}
                />
              </Box>
            )}
            {currentTab === "promoTools" && (
              <Box mt={6}>
                <PromoTools user={user} onComplete={() => this.loadUser()} />
              </Box>
            )}
            {currentTab === "collab" && (
              <Box mt={6}>
                <Collab
                  hideHeader={true}
                  user={user}
                  onComplete={() => this.loadUser()}
                />
              </Box>
            )}
          </>
        )}
      </ContentArea>
    );
  }
}

const mapStateToProps = (state: any) => ({
  user: state.loggedInUser,
  language: state.language.code
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  setLoggedInUser: async (user: User) => dispatch(actions.setLoggedInUser(user))
});

export const EditMyInfo = compose<Props, any>(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withAuthorization()
)(EditMyInfoBase);
