import { UserPicture, FollowMessageButton } from "app/account";
import { FollowsCounter } from "app/account/profile/ProfileInfo";
import { Button, GridLabel, LocationLabel } from "app/shared";
import { User } from "models";
import React from "react";
import { Trans } from "react-i18next";
import { Box, Flex, Text } from "rebass";
import { useMediaQueryContext } from "styles/context";

interface Props {
  user: User;
  photoBy?: boolean;
}

const DocumentUserInfo: React.FC<Props> = ({ user, photoBy }) => {
  const { isSmallMedium } = useMediaQueryContext();
  return (
    <Box mr={[2, 0]} my={7}>
      {photoBy && (
        <Text variant="grotText" pb={3} pl={[2, 0]}>
          <Trans i18nKey="documents.documentDetails.documentUserInfo.photoBy" />
        </Text>
      )}
      <Flex flexDirection="row" width="100%">
        {user && (
          <Button href={`/users/${user.id}`} pl={[2, 2, 0]}>
            <UserPicture
              picture={user.picture}
              verifiedIconSize={isSmallMedium ? 24 : undefined}
              userVerified={user.status}
              height={["76px", "76px", "156px"]}
            />
          </Button>
        )}
        <Flex flexDirection="column" pl={[2, 2, 6]} sx={{ flexGrow: 1 }}>
          <Button href={`/users/${user.id}`}>
            <Flex>
              <Text
                fontSize={[2, "22px"]}
                variant="caps"
                sx={{ wordBreak: "break-word" }}>
                {user.professionalName.length > 100
                  ? `${user.professionalName.substr(0, 100).substr(0, 100)}..`
                  : user.professionalName}
              </Text>
              {user.status === "verified" && !isSmallMedium && (
                <Flex alignItems="flex-end">
                  <Text color="blue" variant="grotTextCaps" fontSize={1} pl={2}>
                    <Trans i18nKey="documents.documentDetails.documentUserInfo.verified" />
                  </Text>
                </Flex>
              )}
            </Flex>
            {user && (
              <LocationLabel
                fontSize={[0, 0, 2]}
                pt="12px"
                variant="grotText"
                city={user.city}
                country={user.country}
                hideIfNoLocation={true}
              />
            )}
          </Button>
          <Flex justifyContent="flex-end" flexDirection="column" height="100%">
            {!isSmallMedium ? (
              <>
                <GridLabel
                  i18nKey="documents.documentDetails.documentUserInfo.description"
                  pb="12px"
                  variant="caps"
                  fontSize={3}
                />
                <Text
                  sx={{ overflow: "auto" }}
                  maxHeight="65px"
                  maxWidth="700px"
                  mr={2}
                  variant="grotText">
                  {user?.translations[0]?.about ?? (
                    <Trans i18nKey="account.profile.userProfileAbout.noDescription" />
                  )}
                </Text>
              </>
            ) : (
              <FollowMessageButton user={user} />
            )}
          </Flex>
        </Flex>
        {!isSmallMedium && (
          <Flex justifyContent="flex-end" flexDirection="column">
            <Flex>
              <GridLabel
                i18nKey="documents.documentDetails.documentUserInfo.connections"
                pb={3}
                variant="caps"
                fontSize={3}
              />
              <Flex>
                <Text
                  pl={3}
                  sx={{ fontWeight: 800 }}
                  variant="caps"
                  fontSize={3}>
                  {FollowsCounter("followers", user.id) +
                    FollowsCounter("following", user.id)}
                </Text>
              </Flex>
            </Flex>
            {user && (
              <Flex width="80%" mt={2}>
                <FollowMessageButton
                  mode="half"
                  user={user}
                  chatText="account.chat.label"
                />
              </Flex>
            )}
          </Flex>
        )}
      </Flex>
    </Box>
  );
};

export default DocumentUserInfo;
