type I18nDotSeparatedString = string;

interface CheckoutStep {
  stepNumber: number;
  i18n: I18nDotSeparatedString;
}

export const CheckoutSteps: CheckoutStep[] = [
  { stepNumber: 1, i18n: "documents.checkout.businessInfoTitle" },
  { stepNumber: 2, i18n: "documents.checkout.verifyAccountTitle" },
  { stepNumber: 3, i18n: "documents.checkout.reviewItems" },
  { stepNumber: 4, i18n: "documents.checkout.selectPayment" },
  { stepNumber: 5, i18n: "documents.checkout.result" }
];
