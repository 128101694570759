import { NavbarIconCounter } from "app/shared";
import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { CheckoutItem } from "../../app/documents/checkout/models";
import NavbarButton from "./NavbarButton";

interface Props {
  checkout: CheckoutItem[];
}

const CheckoutButtonBase: React.FC<Props> = props => {
  return (
    <NavbarButton
      href={props.checkout.length ? "/checkout" : undefined}
      borderLeft={0}
      borderRight={1}>
      <NavbarIconCounter counter={props.checkout.length} iconName="Cart" />
    </NavbarButton>
  );
};

const mapStateToProps = (state: any) => ({
  checkout: state.checkout
});

export const CheckoutButton = compose<Props, any>(connect(mapStateToProps))(
  CheckoutButtonBase
);
