import React from "react";
import { Trans } from "react-i18next";
import { BoxProps, Text } from "rebass";

type I18nDotSeparatedString = string;

interface Props extends BoxProps {
  i18n: I18nDotSeparatedString;
}

export const Label: React.FC<Props> = ({ i18n, ...rest }) => (
  <Text fontSize={[1, 5]} mb={0} variant="caps" {...(rest as any)}>
    <Trans i18nKey={i18n} />
  </Text>
);
