import React from "react";
import Svg, { SvgProps } from "primitives/Svg";

const SvgMessage = ({ width, height, size, color }: SvgProps) => (
  <Svg
    width={size || width}
    height={size || height}
    viewBox="0 0 24 24"
    color={color}>
    <path d="M3 9h1.8l7.2 2.813L19.2 9v.875L12 12.688 4.8 9.874H3V9z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 6v13H4V9.167H3V20h18V5H5v1h15z"
    />
  </Svg>
);

SvgMessage.defaultProps = {
  width: 24,
  height: 24
};
export default SvgMessage;
