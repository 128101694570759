import { Button, Toast } from "app/shared";
import React, { Component } from "react";
import { Trans } from "react-i18next";
import { Flex, Box, Text } from "rebass";
import { deleteTask, getTask } from "../services";
import { UploadTask } from "models";
import moment from "moment";

interface Props {
  task: UploadTask;
  onComplete: (task: UploadTask) => void;
}

interface State {
  disabled: boolean;
  task: UploadTask;
  interval?: number;
}

export class ProcessingContent extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      disabled: false,
      task: props.task,
      interval: undefined
    };
  }

  componentWillUnmount() {
    const { interval } = this.state;

    if (interval) {
      clearInterval(interval);
      this.setState({ interval: undefined });
    }
  }

  componentDidMount() {
    // Poll backend for task change
    const interval = setInterval(() => this.checkTaskStatus(), 1000);
    this.setState({ interval });
  }

  /**
   * This method will be responsible to poll backend and check task processed status
   */
  async checkTaskStatus() {
    const { task } = this.state;

    if (task) {
      getTask(task.id).then(task =>
        this.setState({ task }, () => {
          if (task.status === "processed") {
            this.props.onComplete(task);
          }
        })
      );
    }
  }

  async cancelTask() {
    const { task } = this.state;

    if (task) {
      deleteTask(task.id)
        .then(() => {
          // TODO: Change this so we dont have to use hard refresh
          window.location.reload();
        })
        .catch(error => {
          Toast.apiError(error);
          this.setState({ disabled: false });
        });
    }
  }

  /**
   * We will only present cancel button if task is created at least one hour ago.
   */
  render() {
    const { task } = this.state;
    const taskCreatedHours = moment
      .duration(moment().diff(moment(task.documents[0]?.createdAt)))
      .asHours();

    return (
      <Flex justifyContent="center">
        <Box
          width="500px"
          sx={{
            textAlign: "center"
          }}>
          <Text fontWeight="bold" mb={3}>
            <Trans i18nKey="documents.submitContent.processingContent.processing" />{" "}
            {task.total > 0 && `${task.processed}/${task.total}`}
          </Text>
          <Text mb={3}>
            <Trans i18nKey="documents.submitContent.processingContent.contentBeingProcessed" />
          </Text>
          <Text mb={3}>
            <Trans i18nKey="documents.submitContent.processingContent.weWillNotify" />
          </Text>
          {taskCreatedHours >= 1 && (
            <Button onClick={() => this.cancelTask()}>Cancel</Button>
          )}
        </Box>
      </Flex>
    );
  }
}
