import { SiteImageButton } from "app/shared";
import { AuthUserContext } from "authentication/session";
import React from "react";
import { Trans } from "react-i18next";
import { Flex } from "rebass";
import NavbarButton from "../NavbarButton";
import { MobileMenu } from "./MobileMenu";
import { MobileSearchDropdown } from "./MobileSearchDropdown";
import { MobileUpload } from "./MobileUpload";

interface Props {
  isMedium?: boolean;
}

export const MobileNavbar: React.FC<Props> = ({ isMedium }) => (
  <Flex justifyContent="space-between">
    <Flex alignItems="center" pl={2} pr={[2, 0]}>
      <SiteImageButton width={isMedium ? "120px" : "144px"} display="grid" />
    </Flex>
    <Flex>
      <NavbarButton px={24} href="/search/collection">
        <Trans i18nKey="navbar.explore" />
      </NavbarButton>
      <MobileSearchDropdown />
      <AuthUserContext.Consumer>
        {({ authUser }) => authUser && <MobileUpload />}
      </AuthUserContext.Consumer>
      <MobileMenu />
    </Flex>
  </Flex>
);
