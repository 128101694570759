import React, { ReactNode } from "react";
import { Box, BoxProps, Flex } from "rebass";
import { useMediaQueryContext } from "styles/context";

interface ItemDimensions {
  width: number;
  height: number;
}

interface Props extends BoxProps {
  columns: number;
  gap: number;
  items: ReactNode[];
  itemDimensions: ItemDimensions[];
}

const MasonryLayout: React.FC<Props> = ({
  columns,
  gap,
  items,
  itemDimensions,
  ...rest
}) => {
  const { isSmallMedium } = useMediaQueryContext();
  const numColumns = isSmallMedium ? 1 : columns;
  const MIN_IMAGE_WIDTH = 480;

  const columnWrapper: any = {};
  const columnHeights: any = {};
  const result = [];

  const getSmallestColumn = (): string =>
    Object.keys(columnHeights).reduce((key, v) =>
      columnHeights[v] < columnHeights[key] ? v : key
    );

  //initializing column array
  for (let i = 0; i < numColumns; i++) {
    columnWrapper[i] = [];
    columnHeights[i] = 0;
  }

  for (let i = 0; i < items.length; i++) {
    const columnIndex = getSmallestColumn();
    columnWrapper[columnIndex].push(
      <Box key={`masonry_item_${i}`} marginBottom={gap}>
        {items[i]}
      </Box>
    );

    columnHeights[columnIndex] +=
      (MIN_IMAGE_WIDTH * itemDimensions[i].height) / itemDimensions[i].width;
  }

  for (let i = 0; i < numColumns; i++) {
    result.push(
      <Box
        key={`masonry_column_${i}`}
        sx={{
          marginLeft: i > 0 ? gap : undefined,
          flex: 1
        }}>
        {columnWrapper[i]}
      </Box>
    );
  }

  return (
    <Box {...(rest as any)}>
      <Flex>{result}</Flex>
    </Box>
  );
};

export default MasonryLayout;
