import { Button, Icon, MobileTriggerButton } from "app/shared";
import React from "react";
import { Trans } from "react-i18next";
import { Box, BoxProps, Flex, Text } from "rebass";

interface Props extends BoxProps {
  mobileView?: boolean;
}

export const DocumentAddPhotoButton: React.FC<Props> = ({
  mobileView,
  ...rest
}) =>
  mobileView ? (
    <Button variant="link.mobileButton" href="/submit-content">
      <MobileTriggerButton
        iconName="Plus"
        buttonNameI18n="documents.documentAddPhotoButton.addPhoto"
      />
    </Button>
  ) : (
    <Button
      variant="link.button.black"
      width="100%"
      href="/submit-content"
      {...(rest as any)}>
      <Flex flexDirection="row" alignItems="center" justifyContent="center">
        <Box paddingRight={[0, 1, 2]} display="flex">
          <Icon name="PlusWhite" />
        </Box>
        <Text fontSize={[1, 2, 3]}>
          <Trans i18nKey="documents.documentAddPhotoButton.addPhoto" />
        </Text>
      </Flex>
    </Button>
  );
