import { Price } from "marketplace";
import axios from "network";
import { Donation, DonationFrequency } from "../models";
import queryString from "query-string";
import { SignedUrl } from "models";

export const getDonations = async (
  filter: "made" | "received",
  page: number,
  size: number
) => {
  return axios
    .get(`/donations/${filter}?${queryString.stringify({ page, size })}`)
    .then((response: any) => ({
      data: response.data,
      ...response.pagination
    }));
};

export const createDonation = async (
  frequency: DonationFrequency,
  price: Price,
  paymentProviderName: "stripe" | "test", // get this from environment.paymentProvider
  paymentSource: string, // this is the token generated by stripe
  seller?: string // if the donation is to user this should be set to his userId
): Promise<Donation> => {
  const response = await axios.post(`/donations`, {
    frequency,
    price,
    paymentProviderName,
    paymentSource,
    seller
  });
  return response.data;
};

export const deleteDonation = async (
  donationId: number
): Promise<Donation[]> => {
  const response = await axios.delete(`/donations/${donationId}`);
  return response.data;
};

export const getDonationReceipt = async (
  donateId: number
): Promise<SignedUrl> => {
  return axios
    .get(`/donations/${donateId}/receipt`)
    .then(response => response.data);
};

export const getDonationInvoice = async (
  donateId: number
): Promise<SignedUrl> => {
  return axios
    .get(`/donations/${donateId}/invoice`)
    .then(response => response.data);
};
