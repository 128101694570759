import React, { Component } from "react";
import { Flex } from "rebass";
import { compose } from "recompose";
import { RouteComponentProps } from "react-router-dom";
import { Firebase, withFirebase } from "authentication/firebase";
import { Trans } from "react-i18next";
import { Button, Toast } from "app/shared";
import { FormFieldSet } from "app/shared/forms";
import i18next from "i18next";

interface Provider {
  name: string;
  id: string;
  linked?: boolean;
}

interface Props extends RouteComponentProps {
  firebase: Firebase;
}

interface State {
  providers: Provider[];
}

class SocialProvidersBase extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      providers: [
        {
          name: "Google",
          id: "google.com",
          linked: undefined
        },
        {
          name: "Facebook",
          id: "facebook.com",
          linked: undefined
        }
      ]
    };
  }

  async getConnectedAccounts() {
    const { firebase } = this.props;

    return firebase.getLinkedAccounts();
  }

  async loadProviders() {
    const enabledProviders = await this.getConnectedAccounts();

    const providers = this.state.providers.map(entry => ({
      name: entry.name,
      id: entry.id,
      linked:
        enabledProviders.findIndex(provider => entry.id === provider) !== -1
    }));

    this.setState({
      providers
    });
  }

  async componentDidMount() {
    await this.loadProviders();
  }

  async linkAccount(provider: string, index: number) {
    const { firebase } = this.props;

    await firebase.linkAccount(provider);

    const items = [...this.state.providers];

    items[index] = {
      ...items[index],
      linked: true
    };

    Toast.success({
      title: {
        key: "account.settings.account.socialProviders.confirmationToast"
      }
    });

    this.setState({
      providers: items
    });
  }

  async unlinkAccount(provider: string, index: number) {
    const { firebase } = this.props;

    await firebase.unlinkAccount(provider);

    const items = [...this.state.providers];

    items[index] = {
      ...items[index],
      linked: false
    };

    Toast.success({
      title: {
        key: "account.settings.account.socialProviders.confirmationToast"
      }
    });

    this.setState({
      providers: items
    });
  }

  render() {
    const { providers } = this.state;
    if (!providers.find(it => it.linked !== undefined)) return null; // only show after loading providers to prevent name flicker

    return (
      <FormFieldSet
        title={i18next.t("account.settings.account.socialProviders.title")}
        headerPadding={[]}
        contentPadding={[]}
        sx={{ borderBottom: 0 }}>
        <Flex>
          {providers.map((provider, index) => (
            <Button
              maxWidth="250px"
              key={provider.id}
              mr={3}
              onClick={() => {
                if (provider.linked) {
                  this.unlinkAccount(provider.id, index);
                } else {
                  this.linkAccount(provider.id, index);
                }
              }}>
              <Trans
                i18nKey={`account.settings.account.socialProviders.${
                  provider.linked ? "unlink" : "link"
                }`}
              />{" "}
              {provider.name}
            </Button>
          ))}
        </Flex>
      </FormFieldSet>
    );
  }
}

export const SocialProviders = compose<Props, any>(withFirebase)(
  SocialProvidersBase
);
