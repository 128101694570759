import { withSearch } from "@elastic/react-search-ui";
import React from "react";
import { compose } from "recompose";
import { Directions, SortOption } from "../constants";
import SortingView from "../views/SortingView";

interface PropsExternal {
  label?: string;
  sortOptions: SortOption[];
}

interface Props extends PropsExternal {
  sortDirection: Directions;
  sortField: string;
  setSort: (option: string, direction: string) => void;
}

const SortingContainer: React.FC<Props> = ({
  label,
  sortOptions,
  sortDirection,
  sortField,
  setSort
}) => {
  const findSortOption = (sortOptions: SortOption[], sortString: string) => {
    const [value, direction] = sortString.split("|||");
    return sortOptions.find(
      option => option.value === value && option.direction === direction
    );
  };

  const formatValue = (sortField: string, sortDirection: Directions) => {
    return `${sortField}|||${sortDirection}`;
  };

  const formatOption = (sortOption: SortOption) => {
    return formatValue(sortOption.value, sortOption.direction);
  };

  return (
    <SortingView
      label={label}
      onChange={o => {
        const sortOption = findSortOption(sortOptions, o.target.name);
        setSort(sortOption?.value ?? "", sortOption?.direction ?? "");
      }}
      options={sortOptions.map(formatOption)}
      sortOptions={sortOptions}
      selectedValue={formatValue(sortField, sortDirection)}
    />
  );
};

export default compose<Props, PropsExternal>(
  withSearch(({ sortDirection, sortField, setSort }: Props) => ({
    sortDirection,
    sortField,
    setSort
  }))
)(SortingContainer);
