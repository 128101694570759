import codes from "../static/patterns.json";

export const validateZipCode = (country: string, zipCode: string): boolean => {
  const countryPattern = codes.find(it => it.code === country);

  // Return true if country is not found
  if (!countryPattern) {
    return true;
  }

  return RegExp(`^${countryPattern.pattern}$`).test(zipCode);
};
