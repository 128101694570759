import React from "react";
import Svg, { SvgProps } from "primitives/Svg";

const SvgSearch = ({ width, height, size, color }: SvgProps) => (
  <Svg
    width={size || width}
    height={size || height}
    viewBox="0 0 24 24"
    color={color}>
    <path d="M12.314 13.243l.707-.707 6.364 6.364-.707.707z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.02 5.464a5 5 0 01-.777 7.708l.72.72c.268-.192.524-.408.765-.65a6 6 0 10-2.689 1.554l-.846-.846a5 5 0 112.828-8.486z"
    />
  </Svg>
);

SvgSearch.defaultProps = {
  width: 24,
  height: 24
};
export default SvgSearch;
