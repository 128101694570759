import React from "react";
import { Box, Text, Flex } from "rebass";
import { Trans } from "react-i18next";
import { TransactionItemLicense } from "marketplace";
import { Document } from "models";
import { Image, BackgroundImage } from "app/shared";
import Image1 from "./static/1.png";
import Image2 from "./static/2.png";
import Image3 from "./static/3.png";
import Image4 from "./static/4.png";
import { BoldUnderline, Img, Link } from "i18n/components";

interface Props {
  license: TransactionItemLicense;
  document?: Document;
}

export const LicenseCreativeCommons: React.FC<Props> = ({
  license,
  document
}) => {
  return (
    <Box>
      <Text textAlign="center" fontWeight="bold">
        <Trans i18nKey="documents.receipts.license.licenseCreativeCommons.title" />
      </Text>
      <Flex justifyContent="center" mt={4}>
        {license.transactionItem.thumbnail.length ? (
          <Image
            src={license.transactionItem.thumbnail}
            width="50%"
            maxWidth="500px"
          />
        ) : (
          <BackgroundImage width="500px" height="300px" />
        )}
      </Flex>
      {document && document.credits.length > 0 && (
        <Text fontWeight="bold" variant="caps" mt={2} textAlign="center">
          <Trans i18nKey="documents.receipts.license.licenseCreativeCommons.credits" />{" "}
          {document.credits.map(it => it.name).join(", ")}
        </Text>
      )}
      <Text mt={4}>
        <Trans
          i18nKey="documents.receipts.license.licenseCreativeCommons.content"
          components={[
            <b />,
            <u />,
            <BoldUnderline>Translation</BoldUnderline>,
            <Link>Link</Link>,
            <Img src={Image1} width="100px" />,
            <Img src={Image2} width="40px" />,
            <Img src={Image3} width="40px" />,
            <Img src={Image4} width="40px" />
          ]}
        />
      </Text>
    </Box>
  );
};
