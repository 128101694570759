import { SearchProvider, WithSearch } from "@elastic/react-search-ui";
import AppSearchAPIConnector from "@elastic/search-ui-app-search-connector";
import { BucketItemsView } from "app/buckets";
import { LatestItemView } from "./LatestItemView";
import { LatestItem } from "./models";
import { ResultField, SearchFilter } from "app/search/models";
import { CachedPagination, PageEmptyState } from "app/shared";
import environment from "configurations";
import React, { Component, ReactNode } from "react";
import { Trans } from "react-i18next";
import {
  mapDocumentItemResults,
  mapLatestItemResults
} from "../search/utils/mapResults";
import { DocumentThumbnail } from "models";

interface Props {
  filters: SearchFilter[];
  resultFields?: ResultField[];
  sortField: string;
  sortDirection: "asc" | "desc";
  resultsPerPage: number;
  maxResults?: number;
  view?: (
    viewable: LatestItem[],
    setPage: (mode: "next" | "previous") => void
  ) => React.ReactElement;
  scrollListenerEnabled?: boolean;
  showLoadButtons?: boolean;
  noResultsView?: ReactNode;
}

interface WithSearchProps {
  wasSearched: boolean;
  results: any[];
  totalPages: number;
  setCurrent: (page: number) => void;
  current: number;
  isLoading: boolean;
}

/**
 * When maxResults > resultsPerPage results are wrap with Pagination that will search for more results in page bottom scroll.
 */
export class LatestGrid extends Component<Props> {
  static defaultProps = {
    filters: [],
    resultFields: [],
    resultsPerPage: 20,
    sortField: "created_at",
    sortDirection: "desc"
  };

  /**
   * Use view from props or use the default.
   */
  view(
    viewable: LatestItem[],
    latestList: DocumentThumbnail[],
    setPage: (mode: "next" | "previous") => void
  ) {
    const { view } = this.props;
    if (view) {
      return view(viewable as LatestItem[], setPage);
    } else {
      return (
        <BucketItemsView>
          {viewable.map(bucketItem => (
            <LatestItemView
              latestItem={bucketItem as LatestItem}
              latestList={latestList.filter(it => it !== undefined)}
              showDocumentHoverInfo={true}
            />
          ))}
        </BucketItemsView>
      );
    }
  }

  render() {
    const {
      filters,
      maxResults,
      resultsPerPage,
      scrollListenerEnabled,
      showLoadButtons,
      sortField,
      sortDirection,
      noResultsView
    } = this.props;

    return (
      <SearchProvider
        config={{
          apiConnector: new AppSearchAPIConnector(environment.search),
          alwaysSearchOnInitialLoad: true,
          trackUrlState: false,
          initialState: {
            resultsPerPage
          },
          searchQuery: {
            filters: [
              {
                field: "class_name",
                values: ["document", "collection", "bucket"],
                type: "any"
              },
              { field: "privacy", values: ["private"], type: "none" },
              { field: "total_documents", values: ["0"], type: "none" },
              ...filters
            ],
            sort: { [sortField]: sortDirection },
            result_fields: {
              real_id: { raw: {} },
              class_name: { raw: {} },
              created_at: { raw: {} },
              uid: { raw: {} },
              owner: { raw: {} },
              owner_picture: { raw: {} },
              // Document fields
              thumbnail: { raw: {} },
              thumbnail_large: { raw: {} },
              width: { raw: {} },
              height: { raw: {} },
              city: { raw: {} },
              country: { raw: {} },
              taken_at: { raw: {} },
              translations: { raw: {} },
              // Collections fields
              privacy: { raw: {} },
              title: { raw: {} },
              total_documents: { raw: {} },
              documents: { raw: {} }
            }
          }
        }}>
        <WithSearch
          mapContextToProps={({
            wasSearched,
            results,
            totalPages,
            setCurrent,
            current,
            isLoading
          }: WithSearchProps) => ({
            wasSearched,
            results,
            totalPages,
            setCurrent,
            current,
            isLoading
          })}>
          {({
            wasSearched,
            results,
            totalPages,
            setCurrent,
            current,
            isLoading
          }: WithSearchProps) => {
            if (results.length) {
              const mappedResults: LatestItem[] = mapLatestItemResults(results);
              const mappedDocumentResults = mapDocumentItemResults(
                results
              ) as DocumentThumbnail[];

              // Wrap results around Pagination if maxResults > resultsPerPage
              if (maxResults === undefined || maxResults > resultsPerPage) {
                return (
                  <CachedPagination<LatestItem | DocumentThumbnail>
                    disabled={isLoading}
                    scrollListenerEnabled={scrollListenerEnabled}
                    showLoadButtons={showLoadButtons}
                    firstPageNumber={1}
                    results={mappedResults}
                    page={current}
                    totalPages={totalPages}
                    setPage={setCurrent}>
                    {(viewable, setPage) =>
                      this.view(
                        viewable as LatestItem[],
                        mappedDocumentResults,
                        setPage
                      )
                    }
                  </CachedPagination>
                );
              } else {
                return this.view(
                  mappedResults,
                  mappedDocumentResults,
                  (mode: "next" | "previous") => {
                    if (mode === "next") {
                      setCurrent(current + 1);
                    } else {
                      setCurrent(current - 1);
                    }
                  }
                );
              }
            } else {
              return noResultsView ? (
                noResultsView
              ) : (
                <PageEmptyState
                  loading={!wasSearched || isLoading}
                  header={
                    <Trans i18nKey="search.buckets.BucketItemSearch.noItems" />
                  }
                />
              );
            }
          }}
        </WithSearch>
      </SearchProvider>
    );
  }
}
