import React from "react";
import Svg, { SvgProps } from "primitives/Svg";

const SvgUser = ({ width, height, size, color }: SvgProps) => (
  <Svg
    width={size || width}
    height={size || height}
    viewBox="0 0 81 81"
    color={color}>
    <path d="M0 0h81v81H0z" />
    <path
      fill="white"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M39.054 40.21c7.03 0 12.728-5.698 12.728-12.728 0-7.03-5.699-12.728-12.728-12.728-7.03 0-12.729 5.698-12.729 12.728 0 7.03 5.699 12.729 12.729 12.729zm0 3.183c8.787 0 15.91-7.124 15.91-15.91 0-8.788-7.123-15.912-15.91-15.912-8.788 0-15.911 7.124-15.911 15.911 0 8.787 7.123 15.91 15.91 15.91z"
    />
    <path
      fill="white"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M67.982 69.429s-.044-1.244-.354-3.215C66.44 58.678 61.356 40.5 39.926 40.5c-16.574 0-23.798 10.872-26.908 19.286h3.416c.44-1.076.95-2.179 1.546-3.278 3.499-6.456 9.851-12.794 21.946-12.794 6.163 0 10.657 1.637 13.985 3.953 3.358 2.337 5.704 5.488 7.345 8.753 1.645 3.272 2.54 6.581 3.021 9.098.046.24.088.473.127.696h-40.95v3.215H67.982z"
    />
  </Svg>
);

SvgUser.defaultProps = {
  width: 24,
  height: 24
};
export default SvgUser;
