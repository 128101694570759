import React from "react";
import Svg, { SvgProps } from "primitives/Svg";

const SvgFfSimpleLogo = ({ width, height, size, color }: SvgProps) => (
  <Svg
    width={size || width}
    height={size || height}
    viewBox="0 0 56 33"
    color={color}>
    <path d="M55.404.46c-.021-.003-.045-.003-.067-.003h-23.2c-.024 0-.045 0-.07.003-.031.002-.058.018-.087.026-.04.011-.08.019-.114.035-.038.016-.07.04-.102.061-.032.024-.066.045-.093.072-.029.027-.048.059-.069.09-.024.035-.048.067-.064.104-.016.035-.024.075-.032.112-.008.032-.024.059-.026.09-.006.025-.006.049-.006.07v31.085a.666.666 0 00.662.666h5.242a.66.66 0 00.66-.596c.003-.024.005-.046.005-.07V19.95h17.292c.024 0 .045 0 .066-.003a.663.663 0 00.596-.663V14.04a.664.664 0 00-.662-.665H38.046V7.026h17.291c.024 0 .046 0 .067-.003a.664.664 0 00.596-.66V1.12a.664.664 0 00-.596-.66z" />
    <path d="M23.93.462c-.022-.005-.046-.005-.067-.005H.662c-.023 0-.045 0-.069.005-.029 0-.056.016-.085.024-.04.011-.08.019-.114.035C.354.537.324.56.29.582.258.606.223.627.197.657c-.03.026-.048.058-.07.09C.105.782.08.814.062.851A.495.495 0 00.03.963c-.008.032-.024.058-.026.09C0 1.075 0 1.1 0 1.12v31.085a.666.666 0 00.662.666h5.242a.66.66 0 00.665-.666v-12.25H23.86c.024 0 .046 0 .067-.006a.663.663 0 00.596-.663v-5.244a.664.664 0 00-.663-.666H6.572V7.032h17.291c.024 0 .045 0 .067-.005a.664.664 0 00.596-.66V1.123a.662.662 0 00-.596-.66z" />
  </Svg>
);

SvgFfSimpleLogo.defaultProps = {
  width: 24,
  height: 24
};
export default SvgFfSimpleLogo;
