import React, { ReactNode } from "react";
import { Flex, BoxProps } from "rebass";

interface Props extends BoxProps {
  lat: number;
  lng: number;
  children: ReactNode;
}

export const Marker: React.FC<Props> = ({ children, ...rest }) => (
  <Flex
    alignItems="center"
    justifyContent="center"
    color="#fff"
    backgroundColor="blue"
    px={2}
    py={1}
    sx={{
      cursor: "pointer"
    }}
    {...(rest as any)}>
    {children}
  </Flex>
);
