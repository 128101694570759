import React from "react";
import { useMediaQueryContext } from "styles/context";
import { FullWidthNavbar } from "./FullWidthNavbar";
import { MobileNavbar } from "./mobile/MobileNavbar";

export const Navbar: React.FC = () => {
  const { isMedium, isMediumLarge } = useMediaQueryContext();
  const isMobile = isMediumLarge;

  return isMobile ? <MobileNavbar isMedium={isMedium} /> : <FullWidthNavbar />;
};
