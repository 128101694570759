import React from "react";
import Svg, { SvgProps } from "primitives/Svg";

const SvgArticleIcon = ({ width, height, size, color }: SvgProps) => (
  <Svg
    width={size || width}
    height={size || height}
    viewBox="0 0 25 16"
    color={color}>
    <path stroke="#fff" d="M0 .5h25M0 5.5h25M0 10.5h25M0 15.5h25" />
  </Svg>
);

SvgArticleIcon.defaultProps = {
  width: 24,
  height: 24
};
export default SvgArticleIcon;
