import React from "react";
import Svg, { SvgProps } from "primitives/Svg";

const SvgArrowRight2 = ({ width, height, size, color }: SvgProps) => (
  <Svg
    width={size || width}
    height={size || height}
    viewBox="0 0 20 34"
    color={color}>
    <path
      d="M4.176 1L1 4.2 12.647 17 1 29.8 4.176 33 19 17 4.176 1z"
      stroke="#00F"
    />
  </Svg>
);

SvgArrowRight2.defaultProps = {
  width: 24,
  height: 24
};
export default SvgArrowRight2;
