import React from "react";
import Svg, { SvgProps } from "primitives/Svg";

const SvgCircle = ({ width, height, size, color }: SvgProps) => (
  <Svg
    width={size || width}
    height={size || height}
    viewBox="0 0 114 114"
    color={color}>
    <path d="M114 57c0 31.478-25.522 57-57 57S0 88.478 0 57 25.522 0 57 0s57 25.522 57 57z" />
  </Svg>
);

SvgCircle.defaultProps = {
  width: 24,
  height: 24
};

export default SvgCircle;
