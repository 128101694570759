import {
  Button,
  ColumnButton,
  GridLabel,
  Icon,
  Metadata,
  ShowHideInfoButton
} from "app/shared";
import { AuthUserContext } from "authentication/session";
import { checkSameUser } from "authentication/utils";
import { Bucket } from "models";
import React, { useState } from "react";
import { Trans } from "react-i18next";
import { Box, BoxProps, Flex, Text } from "rebass";
import { useMediaQueryContext } from "styles/context";
import { BucketEditPopup } from ".";
import { CollectionInfo } from "../collections/models";
import { LikeButton } from "../documents/documentDetails/LikeButton";
import { BucketShareButton } from "./bucketDetails/BucketShareButton";

interface Props extends BoxProps {
  bucket: Bucket;
  showEditButton?: boolean;
  onColumnChange?: (column: number) => void;
  onShowInfoChange?: (showInfo: boolean) => void;
}

export const BucketDetailsHeader: React.FC<Props> = ({
  bucket,
  showEditButton,
  onColumnChange,
  onShowInfoChange,
  ...rest
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { isSmall, isSmallMedium } = useMediaQueryContext();
  const [bucketInfo, setBucketInfo] = useState<CollectionInfo | undefined>(
    undefined
  );

  return (
    <Box mb={6} {...(rest as any)}>
      <Metadata title={bucket.title} image={bucket.documents?.[0]?.thumbnail} />
      <Box
        sx={{
          border: "1px solid #000"
        }}>
        <Box
          p={2}
          sx={{
            borderBottom: "1px solid #000"
          }}>
          <Flex>
            <Flex width="100%">
              <Box>
                <GridLabel i18nKey="buckets.bucketDetailsHeader.bucket" />
                <Text
                  fontSize={[4, 7]}
                  variant="grotText"
                  sx={{ textTransform: "uppercase", wordBreak: "break-word" }}>
                  {bucket.title}
                </Text>
              </Box>
            </Flex>
            <AuthUserContext.Consumer>
              {({ authUser }) =>
                authUser &&
                checkSameUser(bucket.uid, authUser) && (
                  <Flex
                    width={["10%", "20%"]}
                    justifyContent="flex-end"
                    alignItems="center">
                    <Button
                      p={[1, 2]}
                      onClick={() => setIsOpen(true)}
                      variant="black">
                      <Flex alignItems="center">
                        <Flex pr={isSmall ? 0 : 2}>
                          <Icon name="Pencil" size={isSmall ? 15 : 20} />
                        </Flex>
                        {!isSmall && (
                          <Flex>
                            <Trans i18nKey="buckets.bucketEditPopup.editBucket" />
                          </Flex>
                        )}
                      </Flex>
                    </Button>
                    <BucketEditPopup
                      bucket={bucket}
                      open={isOpen}
                      onOpen={() => setIsOpen(true)}
                      onClose={() => setIsOpen(false)}
                      hideTrigger={true}
                    />
                  </Flex>
                )
              }
            </AuthUserContext.Consumer>
          </Flex>
        </Box>
        {bucket.description && (
          <Box
            p={2}
            sx={{
              borderBottom: "1px solid #000"
            }}>
            <Text variant="grotText" fontSize={[3, 6]}>
              {bucket.description}
            </Text>
          </Box>
        )}
        <Box
          p={2}
          sx={{
            borderBottom: "1px solid #000"
          }}>
          <Text variant="grotText" fontSize={[2, 4]}>
            <Trans i18nKey="buckets.bucketDetailsHeader.bucketBy" />
            &nbsp;
            <Button
              href={`/users/${bucket.uid}/`}
              sx={{ color: "blue", textDecoration: "underline !important" }}>
              {bucket.owner.name}
            </Button>
          </Text>
        </Box>
        <Flex>
          <Flex
            p={2}
            alignItems="center"
            minWidth="50%"
            justifyContent="center"
            sx={{
              borderRight: "1px solid #000"
            }}>
            <Flex flexDirection="row" alignItems="center">
              <Icon name="Photos" size={[16, 20]} />
              <Text ml={2} fontSize={[2, 4]} variant="caps" pl={1} pr={[1, 2]}>
                {bucket.totalItems}
              </Text>
            </Flex>
          </Flex>
          <Flex
            p={2}
            minWidth="50%"
            alignItems="center"
            justifyContent="center">
            <Icon name="Lock2" size={16} color="black" />
            <Text fontSize={[2, 4]} variant="caps" ml={2}>
              <Trans
                i18nKey={`buckets.bucketDetailsHeader.${bucket.privacy}`}
              />
            </Text>
          </Flex>
        </Flex>
      </Box>
      <Flex mt={6} justifyContent="space-between" alignItems="center">
        <Flex>
          <Flex>
            <BucketShareButton
              name="Share"
              variant="transparent"
              display="block"
              bucket={bucket}
            />
          </Flex>
          <Flex pl={4}>
            <AuthUserContext.Consumer>
              {({ authUser }) =>
                authUser &&
                bucket.owner &&
                !checkSameUser(bucket.owner?.id, authUser) && (
                  <>
                    <LikeButton
                      name="Like"
                      variant="icon"
                      display="block"
                      mode="bucket"
                      documentId={bucket.id}
                      bucketInfo={bucketInfo}
                      onInfoChange={info =>
                        setBucketInfo(info as CollectionInfo)
                      }
                    />
                  </>
                )
              }
            </AuthUserContext.Consumer>
          </Flex>
        </Flex>
        <Flex justifyContent="flex-end" alignItems="center">
          {!isSmallMedium && (
            <ColumnButton
              mr={3}
              onColumnChange={column => onColumnChange?.(column)}
            />
          )}
          <ShowHideInfoButton
            defaultInfo={false}
            onShowInfoChange={showInfo => onShowInfoChange?.(showInfo)}
          />
        </Flex>
      </Flex>
    </Box>
  );
};
