import { SearchProvider, WithSearch } from "@elastic/react-search-ui";
import AppSearchAPIConnector from "@elastic/search-ui-app-search-connector";
import { SearchFilter } from "app/search/models";
import environment from "configurations";
import React from "react";

interface Props {
  searchTerm?: string;
  filters: SearchFilter[];
}

interface WithSearchProps {
  wasSearched: boolean;
  totalResults: number;
}

/**
 * Counter component that returns the totalResults for the provided filters.
 */
export const ResultsCounter: React.FC<Props> = ({ filters, searchTerm }) => (
  <SearchProvider
    config={{
      apiConnector: new AppSearchAPIConnector(environment.search),
      alwaysSearchOnInitialLoad: true,
      trackUrlState: false,
      initialState: {
        searchTerm: searchTerm?.length ? searchTerm : "",
        resultsPerPage: 0 // we just want to get the totalResults
      },
      searchQuery: {
        filters
      }
    }}>
    <WithSearch
      mapContextToProps={({ wasSearched, totalResults }: WithSearchProps) => ({
        wasSearched,
        totalResults
      })}>
      {({ wasSearched, totalResults }: WithSearchProps) =>
        wasSearched && `(${totalResults})`
      }
    </WithSearch>
  </SearchProvider>
);
