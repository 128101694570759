import { BucketView } from "app/buckets";
import { getBucket } from "app/buckets/services";
import environment from "configurations";
import { Bucket } from "models";
import React, { useEffect, useState } from "react";
import { Box, Flex } from "rebass";

interface Props {
  id: number;
  marginTop?: number | number[];
  marginBottom?: number | number[];
  height?: string | string[];
  variant?: string;
}

const BucketParser: React.FC<Props> = ({
  id,
  marginTop,
  marginBottom,
  height,
  variant
}) => {
  const [bucket, setBucket] = useState<Bucket>();
  useEffect(() => {
    const fetchData = async () => {
      setBucket(
        await getBucket(id).then(bucket => {
          return {
            ...bucket,
            owner: {
              ...bucket.owner,
              picture:
                environment.url + "/users/" + bucket.owner.id + "/picture"
            }
          };
        })
      );
    };
    fetchData();
  }, [id]);
  if (!bucket) return null;

  return (
    <Flex justifyContent="center" alignItems="center" flexDirection="column">
      <Box width={["100%", "50%"]}>
        <BucketView
          bucket={bucket}
          marginTop={marginTop}
          marginBottom={marginBottom}
          height={height}
          variant={variant}
        />
      </Box>
    </Flex>
  );
};

BucketParser.defaultProps = {
  marginTop: 0,
  marginBottom: 0,
  height: ["270px", "450px"]
};

export default BucketParser;
