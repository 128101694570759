import { withSearch } from "@elastic/react-search-ui";
import {
  SliderMarks,
  SLIDER_MAXIMUM_VALUE,
  SLIDER_MINIMUM_VALUE,
  SLIDER_STEP
} from "app/search/documents/constants";
import React from "react";
import { compose } from "recompose";
import { SliderOption } from "../constants";
import SliderFacetView from "../views/SliderFacetView";

type FilterType = "all" | "any" | "none";

interface PropsExternal {
  field: string;
  label: string;
  filterType: FilterType;
  marks: SliderMarks<string>;
}

interface Props extends PropsExternal {
  filters: any;
  setFilter: any;
  removeFilter: any;
  maximumValue: number;
  minimumValue: number;
  sliderStep: number;
}

const SliderFacetContainer: React.FC<Props> = ({
  filters,
  field,
  label,
  filterType,
  removeFilter,
  setFilter,
  marks,
  maximumValue,
  minimumValue,
  sliderStep
}) => {
  // Check selected values from filters (or use default min/max)
  const filterValues = filters.find((it: any) => it.field === field)
    ?.values?.[0];
  const storedSlider = [
    filterValues?.from ?? minimumValue,
    filterValues?.to ?? maximumValue
  ];

  const createOptionFromSliderValue = (value: number[]): SliderOption => {
    const [from, to] = value;

    if (from !== undefined && to !== undefined) {
      return from === maximumValue
        ? { from }
        : from === to
        ? { from, to: to + 0.01 } //from elastic search doc, to is exclusive so if ranges are equal we wouldn't get any result ex: [0,0[ returns no results
        : to === maximumValue
        ? { from }
        : { from, to };
    }

    //in case there's an error return all values
    return { from: minimumValue };
  };

  const updateSlider = (value: number[]) => {
    const [from, to] = value;

    const option: SliderOption = createOptionFromSliderValue(value);

    if (from === minimumValue && to === maximumValue) {
      removeFilter(field); //removing filter since when from = 0 and to = 40 there's no filter and it should show all results
    } else {
      setFilter(field, option, filterType);
    }
  };

  return (
    <SliderFacetView
      label={label}
      onChange={updateSlider}
      storedSlider={storedSlider}
      marks={marks}
      min={minimumValue}
      max={maximumValue}
      step={sliderStep}
    />
  );
};

SliderFacetContainer.defaultProps = {
  filterType: "all",
  minimumValue: SLIDER_MINIMUM_VALUE,
  maximumValue: SLIDER_MAXIMUM_VALUE,
  sliderStep: SLIDER_STEP
};

export default compose<Props, PropsExternal>(
  withSearch(({ filters, setFilter, removeFilter }: Props) => ({
    filters,
    setFilter,
    removeFilter
  }))
)(SliderFacetContainer);
