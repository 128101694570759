import React from "react";
import { Box, Flex } from "rebass";
import { BackButton, Image, Tabs } from "app/shared";
import logo from "./image/FrontTalksLogo_global.svg";
import { ContentArea } from "layout";
import i18next from "i18next";
import { FrontTalksCard } from "./FrontTalksCard";
import { FrontTalksCard2 } from "./FrontTalksCard-13-01-22";
import { FrontTalksCardFestival } from "./FrontTalksCardFestival";

export const FrontTalksHome = () => {
  return (
    <ContentArea px={[2, 0]}>
      <Box mx={[0, 8]}>
        <BackButton href="/" i18n="events.frontTalks.backHome" />
      </Box>
      <Image
        my={8}
        src={logo}
        width={["200px", "292px"]}
        height={["30px", "48px"]}
        mx={[0, 8]}
      />
      <Tabs
        mx={[0, 8]}
        activeName="brazil"
        fontSize={[1, 3]}
        width={["100%", "33%"]}
        tabs={[
          {
            name: "brazil",
            label: i18next.t("events.frontTalks.countries.brazil"),
            onClick: () => null
          }
        ]}
      />
      <Box my={8}>
        <Flex justifyContent="center" mx={-2} mb={8}>
          <Box width={["100%", "80%"]}>
            <FrontTalksCardFestival />
          </Box>
        </Flex>
        <FrontTalksCard2 />
        <FrontTalksCard />
      </Box>
    </ContentArea>
  );
};
