import { LicensePrice } from "marketplace";
import { Category, Document, ValidationError, Label, Collection } from "models";
import moment from "moment";

export class UpdateDocumentAndLicensesValidator {
  private document: Document;

  constructor(document: Document) {
    this.document = document;
  }

  validate(): ValidationError[] {
    const errors: ValidationError[] = [];

    const dateErrors = this.validateDate(this.document.takenAt);
    if (dateErrors) {
      errors.push(dateErrors);
    }

    const latErrors = this.validateLat(this.document.latitude);
    if (latErrors) {
      errors.push(latErrors);
    }

    const lngErrors = this.validateLng(this.document.longitude);
    if (lngErrors) {
      errors.push(lngErrors);
    }

    const userLabelErrors = this.validateUserLabels(this.document.userLabels);
    if (userLabelErrors) {
      errors.push(userLabelErrors);
    }

    const collectionsErrors = this.validateCollections(
      this.document.collections
    );
    if (collectionsErrors) {
      errors.push(collectionsErrors);
    }

    const userCategoryErrors = this.validateUserCategories(
      this.document.userCategories
    );
    if (userCategoryErrors) {
      errors.push(userCategoryErrors);
    }

    //removed call to validate translations

    const priceErrors = this.validateLicensingOptions(this.document.prices);
    if (priceErrors) {
      priceErrors.forEach(pe => errors.push(pe));
    }

    return errors;
  }

  validateDraft(): ValidationError[] {
    const errors: ValidationError[] = [];
    //validate the stories of document draft
    const collectionsErrors = this.validateCollections(
      this.document.collections
    );
    if (collectionsErrors) {
      errors.push(collectionsErrors);
    }
    return errors;
  }

  private validateDate(date: string): ValidationError | undefined {
    if (date === "") {
      return { i18nKey: "documents.error.takenAt.required", field: "takenAt" };
    } else {
      const validFormats = [
        moment(date, "YYYY-MM-DD[T]HH:mm:ss.sss[Z]", true).isValid(),
        moment(date, "YYYY-MM-DD[T]HH:mm:ss[Z]", true).isValid()
      ];
      return !validFormats.includes(true)
        ? { i18nKey: "documents.error.takenAt.invalidFormat", field: "takenAt" }
        : undefined;
    }
  }

  private validateLat(lat?: string): ValidationError | undefined {
    if (!lat || lat === "") {
      return {
        i18nKey: "documents.error.location.required",
        field: "location"
      };
    } else {
      const latAsNumber = parseFloat(lat);
      if (isNaN(latAsNumber)) {
        return {
          i18nKey: "documents.error.latitude.numberRequired",
          field: "latitude"
        };
      } else if (latAsNumber > 90 || latAsNumber < -90) {
        return {
          i18nKey: "documents.error.latitude.rangeExceeded",
          field: "latitude"
        };
      } else {
        return undefined;
      }
    }
  }

  private validateLng(lng?: string): ValidationError | undefined {
    if (!lng || lng === "") {
      return undefined;
    } else {
      const lngAsNumber = parseFloat(lng);
      if (isNaN(lngAsNumber)) {
        return {
          i18nKey: "documents.error.longitude.numberRequired",
          field: "longitude"
        };
      } else if (lngAsNumber > 180 || lngAsNumber < -180) {
        return {
          i18nKey: "documents.error.longitude.rangeExceeded",
          field: "longitude"
        };
      } else {
        return undefined;
      }
    }
  }

  private validateUserLabels(userLabels: Label[]): ValidationError | undefined {
    return userLabels.length === 0 || userLabels.length > 20
      ? {
          i18nKey: "documents.error.userLabels.required",
          field: "userLabels"
        }
      : undefined;
  }

  private validateCollections(
    collections: Collection[]
  ): ValidationError | undefined {
    return collections.length === 0 ||
      collections[0].title.length === 0 ||
      collections[0].description.length === 0 ||
      (collections[0].country && collections[0].country.length === 0) ||
      collections[0].country === undefined
      ? {
          i18nKey: "documents.error.collections.required",
          field: "collections"
        }
      : undefined;
  }

  private validateUserCategories(
    userCategories: Category[]
  ): ValidationError | undefined {
    return userCategories.length === 0
      ? {
          i18nKey: "documents.error.userCategories.required",
          field: "userCategories"
        }
      : undefined;
  }

  //removed function to validate translations here

  private validateLicensingOptions(prices: LicensePrice[]): ValidationError[] {
    if (prices.length === 0) {
      return [{ i18nKey: "documents.error.prices.required", field: "prices" }];
    } else {
      const errors: ValidationError[] = [];
      prices.forEach(p => {
        if (p.license === "creativecommons" && p.basePrice.value !== 0) {
          errors.push({
            i18nKey: "documents.error.prices.creativecommons.invalidPrice",
            field: "prices"
          });
        } else if (p.license === "royaltyfree" && p.basePrice.value <= 0) {
          errors.push({
            i18nKey: "documents.error.prices.royaltyfree.invalidPrice",
            field: "prices"
          });
        }
      });
      return errors;
    }
  }
}
