import React, { ReactNode } from "react";
import { Box, BoxProps, Text } from "rebass";

interface Props extends BoxProps {
  header: string | ReactNode;
  description?: string | ReactNode;
  loading?: boolean;
  textAlign?: "left" | "center" | "right";
}

export const PageEmptyState: React.FC<Props> = ({
  header,
  description,
  loading,
  textAlign,
  ...rest
}) => {
  if (loading) return null;

  return (
    <Box {...(rest as any)}>
      <Box
        p={2}
        sx={{
          border: 1,
          borderColor: "black.0"
        }}>
        <Text fontSize={[3, 8, 14]} textAlign={textAlign} variant="caps">
          {header}
        </Text>
      </Box>
      {description && (
        <Box
          p={2}
          sx={{
            border: 1,
            borderTop: 0,
            borderColor: "black.0"
          }}>
          <Text fontSize={[2, 4, 7]} textAlign={textAlign} variant="caps">
            {description}
          </Text>
        </Box>
      )}
    </Box>
  );
};

PageEmptyState.defaultProps = {
  textAlign: "center"
};
