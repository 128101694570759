import { getUserProfile } from "app/account/services";
import { ContentArea } from "layout";
import { User } from "models";
import React, { useEffect, useState } from "react";
import { Flex } from "rebass";
import { useMediaQueryContext } from "styles/context";
import Carousel from "./Carousel";
import ImageSlide from "./ImageSlide";
import { GalleryObject } from "./models";

interface Props {
  items: GalleryObject[];
  documentInfoView?: (
    documentId: number,
    user: User,
    description?: string,
    trackClickThrough?: () => void
  ) => React.ReactElement;
  setPage: (mode: "next" | "previous") => void;
  totalDocuments: number;
  numberOfThumbnails: number;
  selectedDocumentId?: number;
}

export const GalleryLayout: React.FC<Props> = ({
  items,
  documentInfoView,
  setPage,
  totalDocuments,
  numberOfThumbnails,
  selectedDocumentId
}) => {
  const selectedDocumentIndex =
    selectedDocumentId && items.findIndex(it => it.id === selectedDocumentId); // used to find selected document on startup
  const [currentIndex, setCurrentIndex] = useState(selectedDocumentIndex ?? 0);
  const [user, setUser] = useState<User | undefined>(undefined);
  const [isFetching, setIsFetching] = useState(false);
  const lastIndex = items.length - 1;
  const { isSmall, isMedium } = useMediaQueryContext();

  useEffect(() => {
    if (isFetching) return;
    const userId = items?.[currentIndex]?.uid;

    if (userId && userId !== user?.id) {
      setIsFetching(true);

      getUserProfile(userId).then(user => {
        setUser(user);
        setIsFetching(false);
      });
    }

    if (currentIndex >= lastIndex) {
      setIsFetching(true);
      setPage("next");
      setIsFetching(false);
    }
  }, [isFetching, currentIndex, lastIndex, items, user, setPage]);

  if (!items[currentIndex]?.id) return null;

  return (
    <>
      <ImageSlide
        disabled={isFetching}
        url={items[currentIndex].original}
        previousImage={() => setCurrentIndex(currentIndex - 1)}
        nextImage={() => setCurrentIndex(currentIndex + 1)}
        currentIndex={currentIndex}
        lastIndex={lastIndex}
        totalDocuments={totalDocuments}
        height={["60vh", "60vh", "55vh", "70vh"]}
      />
      <ContentArea py={0}>
        <Flex
          flexDirection="column"
          justifyContent="center"
          height={["35vh", "35vh", "40vh", "30vh"]}>
          {documentInfoView &&
            user &&
            documentInfoView(
              items[currentIndex].id,
              user,
              items[currentIndex].description,
              items[currentIndex].trackClickThrough
            )}
          <Carousel
            disabled={isFetching}
            items={items}
            numberOfThumbnails={isSmall ? 3 : isMedium ? 5 : numberOfThumbnails}
            currentIndex={currentIndex}
            setCurrentIndex={(index: number) => setCurrentIndex(index)}
          />
        </Flex>
      </ContentArea>
    </>
  );
};
