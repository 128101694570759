import React from "react";
import Svg, { SvgProps } from "primitives/Svg";

const SvgSelectAll = ({ width, height, size, color }: SvgProps) => (
  <Svg
    width={size || width}
    height={size || height}
    viewBox="0 0 24 24"
    color={color}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 6h13v12h1V5H5v15h13v-1H6V6z"
    />
    <path d="M11.188 14.637L8.57 11.786 8 12.38l3.188 3.356 6.844-7.204-.622-.654-6.222 6.76z" />
  </Svg>
);

SvgSelectAll.defaultProps = {
  width: 24,
  height: 24
};
export default SvgSelectAll;
