import React, { useEffect, useState } from "react";
import { Box, BoxProps, Flex } from "rebass";
import { Button, Icon, Tooltip } from "..";
import OutsideClickWrapper from "app/shared/wrapper/OutsideClickWrapper";

interface Props extends BoxProps {
  titlei18n?: string;
  popUpOpen?: boolean;
  titleFont?: number | number[] | string | string[];
  onColumnChange?: (column: number) => void;
  defaultColumn?: number;
  customPy?: number | number[] | string | string[];
}

export const ColumnButton: React.FC<Props> = ({
  titlei18n,
  popUpOpen,
  titleFont,
  onColumnChange,
  defaultColumn,
  customPy,
  ...rest
}) => {
  const [collapsed, setCollapsed] = useState(popUpOpen ? false : true);
  const [column, setColumn] = useState<number | undefined>(
    defaultColumn ?? undefined
  );

  useEffect(() => {
    if (column) {
      onColumnChange?.(column);
      localStorage.setItem("column", column.toString());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [column]);

  useEffect(() => {
    const columnStorage = localStorage.getItem("column");
    if (columnStorage) {
      setColumn(parseInt(columnStorage));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box {...(rest as any)}>
      <Box
        sx={{
          position: "relative"
        }}>
        <OutsideClickWrapper
          isOpen={!collapsed}
          callback={() => {
            if (!popUpOpen) {
              setCollapsed(true);
            }
          }}>
          <Tooltip
            mode="button"
            i18nKey="columnButton.buttonTitle"
            variant={collapsed ? undefined : "blue"}
            width="100%"
            py={customPy ?? undefined}
            color={collapsed ? "blue" : "white"}
            onClick={() => {
              setCollapsed(!collapsed);
            }}>
            <Flex
              width="100%"
              justifyContent="center"
              sx={{
                "& svg path": {
                  stroke: !collapsed ? "white" : "black",
                  fill: !collapsed ? "blue" : ""
                }
              }}
              fontSize={titleFont ?? [3, 4]}>
              {/* <Trans i18nKey={titlei18n ?? "columnButton.buttonTitle"} /> */}
              <Icon name="GalleryIcon" color="white" size={22} />
            </Flex>
          </Tooltip>
          {!collapsed && (
            <Box
              width="200px"
              backgroundColor="white"
              sx={{
                position: "absolute",
                zIndex: 2,
                right: 0,
                border: 1
              }}>
              <Flex width="100%" flexDirection="column" px={2} py={2}>
                <Button
                  variant="transparent"
                  onClick={() => {
                    setColumn(4);
                    setCollapsed(true);
                  }}>
                  <Box>
                    <Flex mt={2} justifyContent="space-between">
                      <Flex
                        mr={2}
                        alignItems="center"
                        width="100%"
                        height="30px"
                        bg={column === 4 ? "blue" : "white"}
                        sx={{ border: 1 }}></Flex>
                      <Flex
                        mr={2}
                        alignItems="center"
                        width="100%"
                        height="30px"
                        bg={column === 4 ? "blue" : "white"}
                        sx={{ border: 1 }}></Flex>
                      <Flex
                        mr={2}
                        alignItems="center"
                        width="100%"
                        height="30px"
                        bg={column === 4 ? "blue" : "white"}
                        sx={{ border: 1 }}></Flex>
                      <Flex
                        alignItems="center"
                        width="100%"
                        height="30px"
                        bg={column === 4 ? "blue" : "white"}
                        sx={{ border: 1 }}></Flex>
                    </Flex>
                  </Box>
                </Button>
                <Button
                  variant="transparent"
                  onClick={() => {
                    setColumn(2);
                    setCollapsed(true);
                  }}>
                  <Box>
                    <Flex mt={2} justifyContent="space-between">
                      <Flex
                        mr={2}
                        alignItems="center"
                        width="100%"
                        height="65px"
                        bg={column === 2 ? "blue" : "white"}
                        sx={{ border: 1 }}></Flex>
                      <Flex
                        alignItems="center"
                        width="100%"
                        height="65px"
                        bg={column === 2 ? "blue" : "white"}
                        sx={{ border: 1 }}></Flex>
                    </Flex>
                  </Box>
                </Button>
                <Button
                  variant="transparent"
                  onClick={() => {
                    setColumn(1);
                    setCollapsed(true);
                  }}>
                  <Box
                    mt={2}
                    width="100%"
                    height="140px"
                    bg={column === 1 ? "blue" : "white"}
                    sx={{ border: 1 }}></Box>
                </Button>
              </Flex>
            </Box>
          )}
        </OutsideClickWrapper>
      </Box>
    </Box>
  );
};
