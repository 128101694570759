import React from "react";
import Svg, { SvgProps } from "primitives/Svg";

const SvgCreateCollection = ({ width, height, size, color }: SvgProps) => (
  <Svg
    width={size || width}
    height={size || height}
    viewBox="0 0 24 24"
    color={color}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17 4H4v13h1v1H3V3h15v2h-1V4zm3 3H7v13h12v1H6V6h15v13h-1V7z"
    />
    <path d="M18 13.5H9m4.5 4.5V9" stroke="#000" />
  </Svg>
);

SvgCreateCollection.defaultProps = {
  width: 24,
  height: 24
};
export default SvgCreateCollection;
