import { getBucket } from "app/buckets/services";
import { FormattedDate, GridLabel, LocationLabel, Button } from "app/shared";
import { Document } from "models";
import React, { useEffect, useState } from "react";
import { Trans } from "react-i18next";
import { Box, Flex, Text } from "rebass";
import { useMediaQueryContext } from "styles/context";

interface Props {
  document: Document;
}

export const DocumentDataGrid: React.FC<Props> = ({ document }) => {
  const { isSmallMedium } = useMediaQueryContext();

  const [bucketsInfo, setBucketsInfo] = useState<any[]>([]);

  const maxReqs = 10; //limit the number of requisitions to api

  useEffect(() => {
    if (document && document.buckets) {
      (document.buckets as [])
        .filter((item, index) => index < maxReqs)
        .map(bucket =>
          getBucket(bucket as number).then(bucket =>
            setBucketsInfo(bucketsInfo => [...bucketsInfo, bucket])
          )
        );
    }
  }, [document]);

  return (
    <Box
      sx={{
        border: 1,
        borderColor: "black.0",
        display: "grid",
        gridTemplateAreas: isSmallMedium
          ? `
          'head head head'
          'middle middle middle'
          'body body2 body3'
          'bodyBottom bodyBottom2 bodyBottom3'
          'footer footer footer'
          `
          : `
          'head head head side'
          'middle middle middle side'
          'body body2 body3 side'
          'bodyBottom bodyBottom2 bodyBottom3 side'
          `,
        "& > *": {
          px: [1, 2, 3],
          minHeight: "70px"
        }
      }}>
      <Flex
        flexDirection="column"
        justifyContent="center"
        sx={{
          gridArea: "head",
          borderBottom: 1,
          borderColor: "black.0"
        }}>
        <GridLabel i18nKey="documents.documentDetails.documentData.location" />
        <Box pt={2} color="blue" sx={{ textDecoration: "underline" }}>
          <LocationLabel
            fontSize={[2, 2, 2, 4]}
            city={document.city}
            country={document.country}
            latitude={document.latitude}
            longitude={document.longitude}
            onClickOpenMap={true}
          />
        </Box>
      </Flex>
      <Flex
        flexDirection="column"
        justifyContent="flex-start"
        sx={{
          gridArea: isSmallMedium ? "footer" : "side",
          borderLeft: isSmallMedium ? 0 : 1,
          borderTop: isSmallMedium ? 1 : 0,
          borderColor: "black.0",
          "& > *": {
            marginBottom: "2px",
            wordBreak: "break-all"
          }
        }}>
        <Text pt={4} variant="caps">
          <Trans i18nKey="documents.documentDetails.documentData.photoAppears" />
        </Text>
        <Flex
          pt={4}
          height="100%"
          maxHeight="160px"
          flexDirection="column"
          sx={{ overflow: "auto" }}>
          {document.collections.map(collection => (
            <Button href={`/collections/${collection.id}`} variant="blue">
              <Text variant="grotText">{collection.title}</Text>
            </Button>
          ))}
          {bucketsInfo &&
            bucketsInfo.map(bucket => (
              <Button href={`/buckets/${bucket.id}`} variant="blue">
                <Text variant="grotText">{bucket.title}</Text>
              </Button>
            ))}
        </Flex>
      </Flex>
      <Flex
        flexDirection="column"
        justifyContent="center"
        sx={{
          gridArea: "body",
          borderRight: 1,
          borderColor: "black.0",
          borderBottom: 1
        }}>
        <GridLabel i18nKey="documents.documentDetails.documentData.date" />
        <FormattedDate
          pt={2}
          format="string"
          date={document.takenAt}
          fontSize={[4]}
        />
      </Flex>
      <Flex
        flexDirection="column"
        justifyContent="center"
        sx={{ gridArea: "body2", borderRight: 1, borderBottom: 1 }}>
        <GridLabel i18nKey="documents.documentDetails.documentData.uploaded" />
        <Text pt={2} fontSize={[4]}>
          <FormattedDate format="string" date={document.createdAt} />
        </Text>
      </Flex>
      <Flex
        flexDirection="column"
        justifyContent="center"
        sx={{ gridArea: "body3", borderBottom: 1 }}>
        <GridLabel i18nKey="documents.documentDetails.documentData.id" />
        <Text pt={2} fontSize={[4]}>
          {document.shortHash}
        </Text>
      </Flex>
      <Flex
        flexDirection="column"
        justifyContent="center"
        sx={{ gridArea: "bodyBottom", borderRight: 1, borderColor: "black.0" }}>
        <GridLabel i18nKey="documents.documentDetails.documentData.pixelDimension" />
        <Text pt={2} fontSize={[4]}>
          {document.metadata.width + " x " + document.metadata.height}
        </Text>
      </Flex>
      <Flex
        flexDirection="column"
        justifyContent="center"
        sx={{ gridArea: "bodyBottom2", borderRight: 1 }}>
        <GridLabel i18nKey="documents.documentDetails.documentData.fileSize" />
        <Text pt={2} fontSize={[4]}>
          {(document.metadata.size / Math.pow(10, 6)).toFixed(2) + " MB"}
        </Text>
      </Flex>
      <Flex
        flexDirection="column"
        justifyContent="center"
        sx={{ gridArea: "bodyBottom3" }}>
        <GridLabel i18nKey="documents.documentDetails.documentData.fileType" />
        <Text pt={2} fontSize={[4]}>
          {document.metadata.extension}
        </Text>
      </Flex>
    </Box>
  );
};
