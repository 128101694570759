import styled from "@emotion/styled";
import i18next from "i18next";
import React from "react";
import { Box, Text } from "rebass";

interface Props {
  onClick: () => void;
  i18n: string;
  label: string;
}

const BoxWrapper = styled(Box)<Props & any>`
  outline: none;
  cursor: ${props => !props.disabled && "pointer"};
  ${props =>
    props.disabled &&
    `
    pointer-events: none;
    opacity: 0.5;
  `}
`;

const ExpandFacetButton: React.FC<Props> = ({ onClick, i18n, label }) => (
  <BoxWrapper
    py={[0, 1, 2]}
    ml={[2, 2, 2, 2, 3]}
    sx={{ borderTop: 1, borderColor: "black.0" }}
    onClick={onClick}>
    <Text
      fontSize={[2, 2, 2, 2, 4]}
      sx={{ textAlign: "center", textTransform: "uppercase" }}>
      {i18next.t(i18n, {
        label
      })}
    </Text>
  </BoxWrapper>
);

export default ExpandFacetButton;
