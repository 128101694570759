import * as type from "./constants";

const initialState = {
  terms: "",
  className: "document"
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case type.SET_SEARCH_TERMS: {
      return {
        ...state,
        terms: action.terms
      };
    }
    case type.SET_SEARCH_CLASSNAME: {
      return {
        ...state,
        className: action.className
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
