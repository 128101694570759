import React, { Component } from "react";
import { NamedBlob, User, ValidationError } from "models";
import { PersonalInfo } from "./PersonalInfo";
import { Flex, Box, Text } from "rebass";
import { Trans } from "react-i18next";
import { BusinessInfoForm } from "./BusinessInfoForm";
import { Button, FileInput, Toast } from "app/shared";
import {
  checkFileEventMaxSelected,
  checkFileEventMimeType,
  checkFileEventSize
} from "utils/upload";
import {
  confirmInvite,
  createVerificationFiles,
  requestUserVerification
} from "../services";
import { Invite } from "../models";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { compose } from "recompose";
import { BecomeVerifiedPopup } from "../popups";

type FileDocumentType = "identity" | "address" | "vat";

interface FileDocument {
  type: FileDocumentType;
  file: NamedBlob;
}

interface PropsExternal {
  user: User;
  onSkip?: () => void;
  onComplete: (user: User) => void;
  invite?: Invite;
  submitWidth?: string | string[];
  submitPadding?: number | number[];
}

interface Props extends PropsExternal, RouteComponentProps {}

interface State {
  user: User;
  files: FileDocument[];
  disabled: boolean;
  errors: ValidationError[];
}

class VerifyAccountBase extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      user: props.user,
      files: [],
      disabled: false,
      errors: []
    };
  }

  componentDidMount() {
    const { invite } = this.props;

    // Check if there is a identity validation invite and confirm it
    if (invite && invite.type === "IdentityValidation") {
      if (invite.id > 0 && invite.code.length) {
        this.confirmIdentityInvite(invite.id, invite.code);
      }
    }
  }

  private confirmIdentityInvite(inviteId: number, inviteCode: string) {
    const { history } = this.props;

    this.setState({ disabled: true }, () => {
      confirmInvite(inviteId, inviteCode)
        .then(() => {
          history.push(history.location.pathname); // remove invite from path
          window.location.reload(); // force the page to reload to refresh the token
        })
        .catch(err => {
          Toast.apiError(err);
        });
    });
  }

  handleOnFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = this.state;
    const errors = [
      ...checkFileEventMaxSelected(event),
      ...checkFileEventMimeType(event),
      ...checkFileEventSize(event)
    ];

    if (event.target.files && errors.length === 0) {
      for (let i = 0; i < event.target.files.length; i++) {
        files.push({
          type: event.target.name as FileDocumentType,
          file: {
            name: `${i}-${event.target.files[i].name}`,
            file: event.target.files[i]
          }
        });
      }

      this.setState({ files });
    } else {
      Toast.error({ title: errors.join(", ") });
    }
  };

  handleOnFileRemove = (type: FileDocumentType, file: NamedBlob) => {
    const { files } = this.state;
    const newFiles = files.filter(
      it => !(it.type === type && it.file.name === file.name)
    );

    this.setState({ files: newFiles });
  };

  validateForm(): ValidationError[] {
    const errors: ValidationError[] = [];
    // const { files } = this.state;
    // const identityFiles = files.filter(it => it.type === "identity");
    // const addressFiles = files.filter(it => it.type === "address");
    // const vatFiles = files.filter(it => it.type === "vat");

    // if (identityFiles.length === 0 || identityFiles.length > 5) {
    //   errors.push({ i18nKey: "shared.forms.invalidField", field: "identity" });
    // }
    // if (addressFiles.length === 0 || addressFiles.length > 5) {
    //   errors.push({ i18nKey: "shared.forms.invalidField", field: "address" });
    // }
    // if (vatFiles.length === 0 || vatFiles.length > 5) {
    //   errors.push({ i18nKey: "shared.forms.invalidField", field: "vat" });
    // }

    return errors;
  }

  onSubmit() {
    const { onComplete, user } = this.props;
    const files = this.state.files.map(it => it.file);
    const errors = this.validateForm();

    if (user && errors.length === 0) {
      this.setState({ disabled: true, errors: [] }, () => {
        createVerificationFiles(user.id, files)
          .then(() => requestUserVerification(user.id))
          .then(user => {
            this.setState({ disabled: false, user });
            onComplete(user);
          })
          .catch(error => {
            Toast.apiError(error);
            this.setState({ disabled: false });
          });
      });
    } else {
      this.setState({ errors });
    }
  }

  render() {
    const {
      onSkip,
      onComplete,
      invite,
      submitWidth,
      submitPadding
    } = this.props;
    const { user, files, disabled, errors } = this.state;
    if (invite) return null;

    return (
      <Box px={[2, 0]}>
        <Box sx={{ textAlign: "center" }} mb={8}>
          <Text
            fontSize={[3]}
            sx={{
              display: "inline-block",
              textAlign: "left",
              lineHeight: "24px"
            }}>
            <Trans i18nKey="account.onboarding.verifyAccount.headerText" />
          </Text>
        </Box>
        <Flex
          justifyContent="space-between"
          flexDirection={["column", "row"]}
          alignItems="flex-end"
          my={4}
          sx={{
            "& > *": {
              width: ["100%", "calc(100% / 3 - 16px)"],
              my: 3
            }
          }}>
          <FileInput
            name="identity"
            multiple={true}
            buttonNameI18n="account.onboarding.verifyAccount.uploadFile"
            labelI18n="account.onboarding.verifyAccount.identityDocument"
            errorI18n={errors.find(it => it.field === "identity")?.i18nKey}
            infoI18n="account.onboarding.verifyAccount.identityDocumentInfo"
            onChange={this.handleOnFileChange}
            files={files
              .filter(it => it.type === "identity")
              .map(it => it.file)}
            onFileRemove={file => this.handleOnFileRemove("identity", file)}
          />
          <FileInput
            name="address"
            multiple={true}
            buttonNameI18n="account.onboarding.verifyAccount.uploadFile"
            labelI18n="account.onboarding.verifyAccount.addressDocument"
            errorI18n={errors.find(it => it.field === "address")?.i18nKey}
            infoI18n="account.onboarding.verifyAccount.addressDocumentInfo"
            onChange={this.handleOnFileChange}
            files={files.filter(it => it.type === "address").map(it => it.file)}
            onFileRemove={file => this.handleOnFileRemove("address", file)}
          />
          <FileInput
            name="vat"
            multiple={true}
            buttonNameI18n="account.onboarding.verifyAccount.uploadFile"
            labelI18n="account.onboarding.verifyAccount.vatDocument"
            errorI18n={errors.find(it => it.field === "vat")?.i18nKey}
            infoI18n="account.onboarding.verifyAccount.vatDocumentInfo"
            onChange={this.handleOnFileChange}
            files={files.filter(it => it.type === "vat").map(it => it.file)}
            onFileRemove={file => this.handleOnFileRemove("vat", file)}
          />
        </Flex>
        <BusinessInfoForm
          hideHeader={true}
          user={user}
          onSkip={onSkip}
          onComplete={updatedUser => onComplete(updatedUser ?? user)}
          showBilling={true}
          showPayout={true}
          viewMode={true}
          submitVariant="blue"
          children={
            <PersonalInfo
              user={user}
              onComplete={user => this.setState({ user })}
              submiti18n="account.onboarding.verifyAccount.submitPersonalInfo"
              viewMode={true}
            />
          }
        />
        <Flex justifyContent="space-between" mt={8}>
          {onSkip && (
            <BecomeVerifiedPopup
              trigger={
                <Flex
                  justifyContent="flex-end"
                  alignItems="center"
                  width="100%">
                  <Button
                    variant="white"
                    width={["100%", "70%"]}
                    p={3}
                    disabled={disabled}>
                    <Trans i18nKey="account.onboarding.verifyAccount.skip" />
                  </Button>
                </Flex>
              }
              onSkip={close => {
                onSkip();
                close();
              }}
              onComplete={close => close()}
            />
          )}
          <Flex justifyContent="left" alignItems="center" width="100%" ml={4}>
            <Button
              variant="blue"
              width={onSkip ? ["100%", "70%"] : submitWidth}
              p={onSkip ? 3 : submitPadding}
              disabled={disabled}
              onClick={() => this.onSubmit()}>
              <Trans i18nKey="account.onboarding.verifyAccount.submit" />
            </Button>
          </Flex>
        </Flex>
      </Box>
    );
  }
}

export const VerifyAccount = compose<Props, PropsExternal>(withRouter)(
  VerifyAccountBase
);
