import React from "react";
import { Box, BoxProps, Text } from "rebass";

interface Props extends BoxProps {
  title: string;
  subtitle?: string;
  variant?: string;
}

export const PageHeader: React.FC<Props> = ({
  title,
  subtitle,
  variant,
  ...rest
}) => {
  if (variant === "1") {
    return (
      <Box {...(rest as any)}>
        <Box
          width={["100%", "300px", "400px"]}
          p={2}
          sx={{
            border: "1px solid #000",
            borderBottom: 0
          }}>
          <Text fontSize={[2, 4, 8]} variant="grotTextCaps">
            {title}
          </Text>
        </Box>
        {subtitle && (
          <Box
            p={2}
            sx={{
              border: "1px solid #000"
            }}>
            <Text fontSize={[3, 8, 14]} variant="grotDisplayCaps">
              {subtitle}
            </Text>
          </Box>
        )}
      </Box>
    );
  }
  if (variant === "2") {
    return (
      <Box
        p={2}
        sx={{
          border: "1px solid #000"
        }}
        {...(rest as any)}>
        <Text fontSize={[3, 8, 14]} variant="grotDisplayCaps">
          {title}
        </Text>
      </Box>
    );
  } else {
    return null;
  }
};

PageHeader.defaultProps = {
  variant: "1"
};
