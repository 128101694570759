import React from "react";
import { connect } from "react-redux";
import { Text, TextProps } from "rebass";
import { compose } from "recompose";
import {
  FormatDateType,
  formatToNumberDate,
  formatToStringDate,
  ZuluTimeString
} from "./utils";
import { ISOLanguageCode } from "i18n/resources/supportedLanguages";

interface PropsExternal extends TextProps {
  format?: FormatDateType;
  showHours?: boolean;
  date: ZuluTimeString;
}

interface Props extends PropsExternal {
  language: ISOLanguageCode;
}

const FormattedDateBase: React.FC<Props> = ({
  format,
  date,
  language,
  showHours,
  ...rest
}) => {
  return (
    <Text {...(rest as any)}>
      {format === "number"
        ? formatToNumberDate(date, language, !!showHours)
        : formatToStringDate(date, language, !!showHours)}
    </Text>
  );
};

FormattedDateBase.defaultProps = {
  format: "number",
  showHours: false
};

const mapStateToProps = (state: any) => ({
  language: state.language.code
});

export const FormattedDate = compose<Props, PropsExternal>(
  connect(mapStateToProps)
)(FormattedDateBase);
