import { default as Axios, AxiosResponse } from "axios";
import environment from "configurations";
import app from "firebase/app";

export interface Pagination {
  first: boolean;
  last: boolean;
  page: number;
  size: number;
  totalElements: number;
  totalPages: number;
}

export interface AxiosResponsePagination extends AxiosResponse {
  pagination: Pagination | null;
}

// Create axios instances
const configuration = {
  baseURL: `${environment.api}`,
  headers: {
    "Content-Type": "application/json"
  }
};

// Axios with interceptor that fills authorization token / parses response headers
export const axios = Axios.create(configuration);

axios.interceptors.request.use(
  config => {
    return new Promise(resolve => {
      const currentUser = app.auth().currentUser;

      if (currentUser) {
        return currentUser.getIdToken().then(token => {
          config.headers.Authorization = `Bearer ${token}`;
          return resolve(config);
        });
      } else {
        return resolve(config);
      }
    });
  },
  err => {
    return Promise.reject(err);
  }
);

axios.interceptors.response.use(
  (response): AxiosResponsePagination => {
    // Parse pagination if headers are present
    const headers = response.headers;
    const pagination: Pagination | null = headers[
      "vnd.frontfiles.pagination.size"
    ]
      ? {
          first: !!headers["vnd.frontfiles.pagination.first"],
          last: !!headers["vnd.frontfiles.pagination.last"],
          page: parseInt(headers["vnd.frontfiles.pagination.page"], 10),
          size: parseInt(headers["vnd.frontfiles.pagination.size"], 10),
          totalElements: parseInt(
            headers["vnd.frontfiles.pagination.total_elements"],
            10
          ),
          totalPages: parseInt(
            headers["vnd.frontfiles.pagination.total_pages"],
            10
          )
        }
      : null;

    return {
      ...response,
      pagination
    };
  },
  err => {
    // Show toast for backend errors if enabled on configurations
    const statusCode = err?.response?.status;

    if (statusCode >= 400 && statusCode <= 599) {
      console.log("Network error", err);
    }

    return Promise.reject(err);
  }
);

// Axios with no interceptor
export const axiosNoAuthentication = Axios.create(configuration);

export default axios;
