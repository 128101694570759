import { BucketAddItemsButton } from "app/buckets";
import { CollectionsView, CollectionView } from "app/collections";
import { DeleteCollectionsButton } from "app/collections/DeleteCollectionsButton";
import { CollectionsListSearch } from "app/search/collections/CollectionsListSearch";
import { BulkActionLayout } from "app/shared";
import ConditionalWrapper from "app/shared/wrapper/ConditionalWrapper";
import { AuthUserContext } from "authentication/session";
import { checkSameUser } from "authentication/utils";
import React, { useEffect, useState } from "react";
import { ColumnItem } from "../../../models";

interface Props {
  uid: string;
  sidebarOpen?: boolean;
  column?: number;
  showInfo?: boolean;
}

export const UserProfileCollections: React.FC<Props> = ({
  uid,
  sidebarOpen,
  column,
  showInfo
}) => {
  const [collectionHeight, setCollectionHeight] = useState<ColumnItem>({
    isCollapsed: "425px",
    notCollapsed: "270px",
    titleFontSize: undefined,
    textBottomFontSize: undefined,
    ownerNameFontSize: undefined
  });

  useEffect(() => {
    if (column) {
      if (column === 1) {
        setCollectionHeight({
          isCollapsed: "825px",
          notCollapsed: "570px",
          titleFontSize: undefined,
          textBottomFontSize: undefined,
          ownerNameFontSize: undefined
        });
      } else if (column === 2) {
        setCollectionHeight({
          isCollapsed: "425px",
          notCollapsed: "270px",
          titleFontSize: undefined,
          textBottomFontSize: undefined,
          ownerNameFontSize: undefined
        });
      } else if (column === 4) {
        setCollectionHeight({
          isCollapsed: "230px",
          notCollapsed: "170px",
          titleFontSize: [2, 3, 3],
          textBottomFontSize: 1,
          ownerNameFontSize: 1
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [column]);

  return (
    <AuthUserContext.Consumer>
      {({ authUser }) => (
        <CollectionsListSearch
          key={uid} // Using key={uid} to force refresh when user changes
          filters={[
            { field: "uid", values: [uid], type: "any" },
            {
              field: "privacy",
              values:
                authUser && checkSameUser(uid, authUser)
                  ? ["public", "private"]
                  : ["public"],
              type: "any"
            }
          ]}
          view={collections => (
            <ConditionalWrapper
              wrapper={() => (
                <BulkActionLayout
                  defaultSelectActions={true}
                  elementOptions={[]}
                  actions={[
                    (ids: any[]) =>
                      authUser && (
                        <BucketAddItemsButton
                          key="bucket-add-collection"
                          bucketItemIds={ids.map(id => ({
                            type: "collection",
                            id: parseInt(id, 10)
                          }))}
                          forcePageReload={true}
                        />
                      ),
                    (ids: any[]) =>
                      authUser &&
                      checkSameUser(uid, authUser) && (
                        <DeleteCollectionsButton
                          key="collection-remove"
                          collectionIds={ids.map(id => parseInt(id, 10))}
                        />
                      )
                  ]}
                  nodes={collections.map(collection => (
                    <CollectionView
                      showUserInfo={false}
                      key={collection.id}
                      collection={collection}
                      height={
                        !sidebarOpen
                          ? collectionHeight.isCollapsed
                          : ["425px", collectionHeight.notCollapsed]
                      }
                      whiteMode={true}
                      titleFontSize={collectionHeight.titleFontSize}
                      textBottomFontSize={collectionHeight.textBottomFontSize}
                      ownerNameFontSize={collectionHeight.ownerNameFontSize}
                      showInfoBottom={showInfo}
                      customPb={!showInfo ? 2 : undefined}
                      largeThumbnail={column === 1 ? true : undefined}
                    />
                  ))}
                  view={children => (
                    <CollectionsView column={column}>
                      {children}
                    </CollectionsView>
                  )}
                />
              )}
              condition={authUser ? true : false}>
              <CollectionsView column={column}>
                {collections.map(collection => (
                  <CollectionView
                    key={collection.id}
                    collection={collection}
                    height={
                      !sidebarOpen
                        ? collectionHeight.isCollapsed
                        : ["425px", collectionHeight.notCollapsed]
                    }
                    whiteMode={true}
                    titleFontSize={collectionHeight.titleFontSize}
                    textBottomFontSize={collectionHeight.textBottomFontSize}
                    ownerNameFontSize={collectionHeight.ownerNameFontSize}
                    showInfoBottom={showInfo}
                    customPb={!showInfo ? 2 : undefined}
                    largeThumbnail={column === 1 ? true : undefined}
                    showUserInfo={false}
                  />
                ))}
              </CollectionsView>
            </ConditionalWrapper>
          )}
        />
      )}
    </AuthUserContext.Consumer>
  );
};
