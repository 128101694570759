/* eslint-disable no-restricted-globals */
import { Button } from "app/shared";
import React from "react";
import { BoxProps, Text } from "rebass";
import { Popup } from "../popup";

interface Props extends BoxProps {
  onClickButton(close?: () => void): void;
  headingText: string;
  buttonText: string;
  submitText: string;
  popupText: string;
}

export const PopupButton: React.FC<Props> = ({
  onClickButton,
  headingText,
  buttonText,
  submitText,
  popupText,
  ...rest
}) => (
  <Popup
    heading={headingText}
    trigger={
      <Button width="100%" {...(rest as any)}>
        <Text fontSize={[1, 2, 3]}>{buttonText}</Text>
      </Button>
    }
    submitText={submitText}
    submit={close => onClickButton(close)}>
    <Text textAlign="center">{popupText}</Text>
  </Popup>
);
