import { Range } from "rc-slider";
import "rc-slider/assets/index.css";
import React from "react";
import { colors } from "styles/theme";
import "./dragSlider.css";

interface Marks<T> {
  [key: number]: T;
}

interface Props {
  marks: Marks<string>;
  min: number;
  max: number;
  step: number;
  defaultValue: number[];
  onChange: (value: number[]) => void;
}

const styles = {
  railStyle: { background: "rgba(0, 0, 0, 0.4)" },
  trackStyle: [{ backgroundColor: colors.brand[0] }],
  dotStyle: {
    borderRadius: 0,
    background: colors.black[0],
    borderColor: colors.black[0]
  },
  activeDotStyle: {
    background: colors.brand[0],
    borderColor: colors.brand[0]
  },
  handleStyle: [
    {
      borderRadius: 0,
      borderColor: colors.brand[0],
      backgroundColor: colors.brand[0]
    },
    {
      borderRadius: 0,
      borderColor: colors.brand[0],
      backgroundColor: colors.brand[0]
    }
  ]
};

export const Slider: React.FC<Props> = ({
  marks,
  min,
  max,
  step,
  defaultValue,
  onChange
}) => (
  <Range
    min={min}
    max={max}
    marks={marks}
    step={step}
    defaultValue={defaultValue}
    onAfterChange={onChange}
    {...styles}
  />
);
