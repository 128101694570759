import {
  ErrorBoundary,
  SearchProvider,
  WithSearch
} from "@elastic/react-search-ui";
import AppSearchAPIConnector from "@elastic/search-ui-app-search-connector";
import { CollectionsView, CollectionView } from "app/collections";
import MultiCheckboxFacet from "app/search/filters/facets/MultiCheckboxFacet";
import { CachedPagination, PageEmptyState } from "app/shared";
import environment from "configurations";
import i18next from "i18next";
import { Collection, ColumnItem } from "models";
import React, { PureComponent, useEffect, useState } from "react";
import { Box } from "rebass";
import { DEFAULT_RESULTS_PER_PAGE, DocumentTabs } from "../models";
import { SearchHeader, SearchLayout } from "../shared";
import { Trans } from "react-i18next";
import moment from "moment";
import { mapCollectionResults } from "../utils/mapResults";

interface Props {
  resultsPerPage?: number;
}

interface WithSearchProps {
  wasSearched: boolean;
  trackClickThrough: (documentId: string) => void;
  results: any[];
  totalPages: number;
  totalResults: number;
  setCurrent: (page: number) => void;
  current: number;
  isLoading: boolean;
}

export class CollectionSearch extends PureComponent<Props, any> {
  static defaultProps = {
    resultsPerPage: DEFAULT_RESULTS_PER_PAGE
  };

  getConfig = () => {
    const { resultsPerPage } = this.props;

    return {
      apiConnector: new AppSearchAPIConnector(environment.search),
      alwaysSearchOnInitialLoad: true,
      initialState: {
        resultsPerPage,
        filters: [
          { field: "class_name", values: ["collection"], type: "any" },
          { field: "privacy", values: ["public"], type: "any" },
          { field: "total_documents", values: ["0"], type: "none" }
        ]
      },
      searchQuery: {
        result_fields: {
          real_id: { raw: {} },
          created_at: { raw: {} },
          uid: { raw: {} },
          owner: { raw: {} },
          owner_picture: { raw: {} },
          privacy: { raw: {} },
          title: { raw: {} },
          description: { raw: {} },
          total_documents: { raw: {} },
          country: { raw: {} },
          city: { raw: {} },
          documents: { raw: {} }
        },
        disjunctiveFacets: [],
        facets: {
          created_at: {
            type: "range",
            ranges: [
              {
                from: moment()
                  .subtract(100, "years")
                  .toISOString(),
                name: i18next.t("search.collections.anyDate")
              },
              {
                from: moment()
                  .subtract(24, "hours")
                  .toISOString(),
                name: i18next.t("search.collections.last24Hours")
              },
              {
                from: moment()
                  .subtract(1, "weeks")
                  .toISOString(),
                name: i18next.t("search.collections.lastWeek")
              },
              {
                from: moment()
                  .subtract(1, "months")
                  .toISOString(),
                name: i18next.t("search.collections.lastMonth")
              },
              {
                from: moment()
                  .subtract(1, "years")
                  .toISOString(),
                name: i18next.t("search.collections.lastYear")
              }
            ]
          }
        }
      }
    };
  };

  render() {
    return (
      <SearchProvider config={this.getConfig()}>
        <WithSearch
          mapContextToProps={({
            wasSearched,
            trackClickThrough,
            results,
            totalPages,
            totalResults,
            setCurrent,
            current,
            isLoading
          }: WithSearchProps) => ({
            wasSearched,
            trackClickThrough,
            results,
            totalPages,
            totalResults,
            setCurrent,
            current,
            isLoading
          })}>
          {(props: WithSearchProps) => {
            return (
              <Box>
                <ErrorBoundary>
                  <SearchBodyContent {...props} />
                </ErrorBoundary>
              </Box>
            );
          }}
        </WithSearch>
      </SearchProvider>
    );
  }
}

/**
 * Using component with its own state to present results to prevent re-render of SearchProvider
 * when state changes resulting in CachedPagination being constructed again.
 */

const SearchBodyContent: React.FC<WithSearchProps> = ({
  results,
  isLoading,
  current,
  totalPages,
  setCurrent,
  totalResults,
  trackClickThrough,
  wasSearched
}) => {
  const [isCollapsed, setCollapsed] = useState(true);
  const [column, setColumn] = useState<number | undefined>(undefined);
  const [showInfo, setShowInfo] = useState<boolean | undefined>(undefined);

  const [collectionHeight, setCollectionHeight] = useState<ColumnItem>({
    isCollapsed: "425px",
    notCollapsed: "270px",
    titleFontSize: undefined,
    textBottomFontSize: undefined,
    ownerNameFontSize: undefined
  });

  useEffect(() => {
    if (column) {
      if (column === 1) {
        setCollectionHeight({
          isCollapsed: "825px",
          notCollapsed: "570px",
          titleFontSize: undefined,
          textBottomFontSize: undefined,
          ownerNameFontSize: undefined
        });
      } else if (column === 2) {
        setCollectionHeight({
          isCollapsed: "425px",
          notCollapsed: "270px",
          titleFontSize: undefined,
          textBottomFontSize: undefined,
          ownerNameFontSize: undefined
        });
      } else if (column === 4) {
        setCollectionHeight({
          isCollapsed: "230px",
          notCollapsed: "170px",
          titleFontSize: [2, 3, 3],
          textBottomFontSize: 1,
          ownerNameFontSize: 1
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [column]);

  return (
    <SearchLayout
      onCollapseChange={collapsed => setCollapsed(collapsed)}
      totalResults={totalResults}
      sideContent={
        <>
          <MultiCheckboxFacet
            field="created_at"
            label={i18next.t("search.collections.dateRange")}
            filterType="any"
          />
        </>
      }
      bodyContent={
        <CachedPagination<Collection>
          disabled={isLoading}
          firstPageNumber={1}
          results={mapCollectionResults(results)}
          page={current}
          totalPages={totalPages}
          setPage={setCurrent}>
          {collections =>
            collections.length ? (
              <CollectionsView column={column}>
                {collections.map(collection => (
                  <CollectionView
                    whiteMode={true}
                    height={
                      isCollapsed
                        ? collectionHeight.isCollapsed
                        : ["425px", collectionHeight.notCollapsed]
                    }
                    key={collection.id}
                    collection={collection}
                    titleFontSize={collectionHeight.titleFontSize}
                    textBottomFontSize={collectionHeight.textBottomFontSize}
                    ownerNameFontSize={collectionHeight.ownerNameFontSize}
                    onClick={() => trackClickThrough(collection.id.toString())}
                    showInfoBottom={showInfo}
                    showUserInfo={showInfo}
                    customPb={!showInfo ? 2 : undefined}
                    largeThumbnail={column === 1 ? true : undefined}
                  />
                ))}
              </CollectionsView>
            ) : (
              <PageEmptyState
                mt={6}
                loading={!wasSearched || isLoading}
                header={<Trans i18nKey="search.shared.noResults" />}
                description={
                  <Trans i18nKey="search.shared.noResultsDescription" />
                }
              />
            )
          }
        </CachedPagination>
      }
      bodyHeader={
        <SearchHeader
          onColumnChange={column => setColumn(column)}
          onShowInfoChange={showInfo => setShowInfo(showInfo)}
          tabs={DocumentTabs}
          activeTabName="collection"
        />
      }
    />
  );
};
