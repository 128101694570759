import styled from "@emotion/styled";
import { size, color } from "styled-system";
import { Box, BoxProps } from "rebass";

export interface SvgProps extends BoxProps {
  viewBox?: string;
  rotate?: string;
}

const Svg = styled(Box)<SvgProps>(
  {
    display: "inline-block",
    flex: "none",
    fill: "currentColor"
  },
  size,
  color
);

Svg.displayName = "Svg";
Svg.defaultProps = {
  as: "svg"
};

export default Svg;
