import firebase from "firebase/app";
import "firebase/firestore";
import { collectionData, doc } from "rxfire/firestore";
import { combineLatest, Observable, of } from "rxjs";
import { map, switchMap } from "rxjs/operators";

/**
 * This service will act as a proxy to firefly-web (https://github.com/chat-sdk/firefly-web)
 */
export class MessageService {
  readonly firestore: firebase.firestore.Firestore;
  currentUserId?: string;

  constructor(readonly app: firebase.app.App) {
    this.firestore = app.firestore();
  }

  connect = () => {
    this.currentUserId = this.app.auth().currentUser?.uid;
  };

  disconnect = async (): Promise<void> => {
    await this.firestore
      .collection("presence")
      .doc(this.currentUserId)
      .set({
        chatId: ""
      });
  };

  getChats = (): Observable<Array<Chat>> => {
    const loggedUser = this.app.auth().currentUser?.uid;

    const chatsQuery = this.firestore
      .collection("chats")
      .where("uid", "==", loggedUser);

    return this.joinUsers(collectionData(chatsQuery, "id"));
  };

  setChatRead = async (chatId: string): Promise<void> => {
    const loggedUser = this.currentUserId ?? "unknown user";

    await this.firestore
      .collection("presence")
      .doc(loggedUser)
      .set({
        chatId: chatId
      });

    await this.firestore
      .collection("chats")
      .doc(chatId)
      .set(
        {
          unread: false
        },
        { merge: true }
      );
  };

  getUnreadMessages = (): Observable<number> => {
    const query = this.firestore
      .collection("chats")
      .where("uid", "==", this.currentUserId)
      .where("unread", "==", true);

    return collectionData(query).pipe(map(array => array.length));
  };

  private joinUsers(chats$: Observable<Array<Chat>>): Observable<Array<Chat>> {
    let chats: Chat[];
    const joinKeys: { [fieldPath: string]: any } = {};

    const loggedUser = this.app.auth().currentUser?.uid;

    return chats$.pipe(
      switchMap(c => {
        chats = c;
        const uids: Array<string> = Array.from(
          new Set(
            c.flatMap(entry => {
              return [entry.to];
            })
          )
        );

        const userDocs = uids.map(u => {
          return doc(this.app.firestore().doc(`users/${u}`));
        });

        return userDocs.length ? combineLatest(userDocs) : of([]);
      }),
      map(arr => {
        arr.forEach(v => (joinKeys[v.id] = v.data()));

        if (loggedUser) {
          joinKeys[loggedUser] = {
            displayName: this.app.auth().currentUser?.displayName
          };
        }

        chats.forEach(chat => {
          chat.userMetadata = joinKeys[chat.to];
          chat.userMetadata.id = chat.to;

          chat.messages = chat.messages.map(v => {
            return { ...v, user: joinKeys[v.uid] };
          });
        });

        return chats;
      })
    );
  }

  unblockUser = async (userId: string): Promise<void> => {
    const loggedUser = this.app.auth().currentUser?.uid ?? "Unknown user";

    const records = await this.firestore
      .collection("blocked")
      .where(firebase.firestore.FieldPath.documentId(), "==", loggedUser)
      .where("users", "array-contains", userId)
      .get();

    const [blockedRecord] = records.docs;

    if (blockedRecord) {
      await this.firestore
        .collection("blocked")
        .doc(blockedRecord.id)
        .set(
          {
            users: firebase.firestore.FieldValue.arrayRemove(userId)
          },
          { merge: true }
        );
    }
  };

  getBlockedUsers = async (): Promise<Array<FireUser>> => {
    const record = await this.firestore
      .collection("blocked")
      .doc(this.currentUserId)
      .get();

    if (!record.exists) {
      return [];
    }

    const results = new Array<FireUser>();

    const blockedUsersIds: Array<string> = record.data()?.users ?? [];

    for (const uid of blockedUsersIds) {
      const user = await this.app
        .firestore()
        .doc(`users/${uid}`)
        .get();
      const snapshot = user.data();
      results.push({
        id: uid,
        displayName: snapshot?.displayName,
        photoUrl: snapshot?.photoUrl
      });
    }

    return results;
  };

  blockUser = async (userId: string): Promise<void> => {
    const blockedRecord = await this.firestore
      .collection("blocked")
      .where(
        firebase.firestore.FieldPath.documentId(),
        "==",
        this.currentUserId
      )
      .where("users", "array-contains", userId)
      .get();

    if (blockedRecord.size > 0) {
      // User is already blocked
      return;
    }

    // Add user to blocked records
    await this.firestore
      .collection("blocked")
      .doc(this.currentUserId)
      .set(
        {
          users: firebase.firestore.FieldValue.arrayUnion(userId)
        },
        { merge: true }
      );

    // Delete chat if this user had interactions
    const results = await this.firestore
      .collection("chats")
      .where("uid", "==", this.currentUserId)
      .where("to", "==", userId)
      .get();

    if (results.docs.length > 0) {
      const [chatFound] = results.docs;

      await this.deleteChat(chatFound.id);
    }
  };

  private isLoggedUserBlocked = async (userId: string): Promise<boolean> => {
    const blockedRecord = await this.firestore
      .collection("blocked")
      .where(firebase.firestore.FieldPath.documentId(), "==", userId)
      .where("users", "array-contains", this.currentUserId)
      .get();

    return blockedRecord.size > 0;
  };

  composeMessage = async (userId: string, text: string): Promise<void> => {
    const isBlocked = await this.isLoggedUserBlocked(userId);

    const results = await this.firestore
      .collection("chats")
      .where("uid", "==", this.currentUserId)
      .where("to", "==", userId)
      .get();

    const loggedUser = this.currentUserId ?? "unknown user";

    // If there's an ongoing chat
    if (results.docs.length > 0) {
      const [chatFound] = results.docs;

      await this.sendMessage(chatFound.id, userId, text);
    } else {
      await this.firestore.collection("chats").add({
        createdAt: firebase.firestore.Timestamp.now(),
        messages: [
          {
            content: text,
            createdAt: firebase.firestore.Timestamp.now(),
            uid: this.app.auth().currentUser?.uid ?? "No user"
          }
        ],
        uid: loggedUser,
        to: userId
      });

      if (!isBlocked) {
        await this.messageTargetUser(userId, text);
      }
    }
  };

  private userReadingChat = async (
    userId: string,
    chatId: string
  ): Promise<boolean> => {
    const onlinePresence = await this.firestore
      .collection("presence")
      .where(firebase.firestore.FieldPath.documentId(), "==", userId)
      .where("chatId", "==", chatId)
      .get();

    return onlinePresence.size > 0;
  };

  private messageTargetUser = async (
    userId: string,
    text: string
  ): Promise<void> => {
    // Fetch chatId on the side of the target userId
    const results = await this.firestore
      .collection("chats")
      .where("uid", "==", userId)
      .where("to", "==", this.currentUserId)
      .get();

    if (results.docs.length > 0) {
      const [chatFound] = results.docs;

      const userReading = await this.userReadingChat(userId, chatFound.id);

      await this.firestore
        .collection("chats")
        .doc(chatFound.id)
        .set(
          {
            messages: firebase.firestore.FieldValue.arrayUnion({
              content: text,
              createdAt: firebase.firestore.Timestamp.now(),
              uid: this.app.auth().currentUser?.uid ?? "No user"
            }),
            unread: !userReading
          },
          { merge: true }
        );
    } else {
      await this.firestore.collection("chats").add({
        createdAt: firebase.firestore.Timestamp.now(),
        messages: firebase.firestore.FieldValue.arrayUnion({
          content: text,
          createdAt: firebase.firestore.Timestamp.now(),
          uid: this.app.auth().currentUser?.uid ?? "No user"
        }),
        to: this.currentUserId,
        uid: userId
      });
    }
  };

  sendMessage = async (
    chatId: string,
    userId: string,
    text: string
  ): Promise<void> => {
    const isBlocked = await this.isLoggedUserBlocked(userId);

    if (!isBlocked) {
      await this.messageTargetUser(userId, text);
    }

    await this.firestore
      .collection("chats")
      .doc(chatId)
      .set(
        {
          messages: firebase.firestore.FieldValue.arrayUnion({
            content: text,
            createdAt: firebase.firestore.Timestamp.now(),
            uid: this.app.auth().currentUser?.uid ?? "No user"
          })
        },
        { merge: true }
      );
  };

  deleteChat = async (chatId: string): Promise<void> => {
    await this.firestore
      .collection("chats")
      .doc(chatId)
      .delete();
  };
}

export default MessageService;

export interface Chat {
  id: string;
  createdAt: firebase.firestore.Timestamp;
  uid: string;
  to: string;
  userMetadata: FireUser;
  messages: Array<Message>;
  unread: boolean;
}

export interface FireUser {
  id: string;
  displayName: string;
  photoUrl?: string;
}

export interface Message {
  content: string;
  createdAt: firebase.firestore.Timestamp;
  uid: string;
  user: FireUser;
}
