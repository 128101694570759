import { Firebase, withFirebase } from "authentication/firebase";
import { ContentArea } from "layout";
import { User } from "models";
import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { compose } from "recompose";
import { UserRelationInfo } from "../models";
import { getUserInfo, getUserProfile } from "../services";
import { ProfileInfo } from "./ProfileInfo";
import { ProfileTabs } from "./ProfileTabs";

interface Params {
  id: string;
  filter?: string;
}

interface Props extends RouteComponentProps<Params> {
  firebase: Firebase;
}

interface State {
  user?: User;
  info?: UserRelationInfo;
  filter: string;
}

class ProfileBase extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      user: undefined,
      info: undefined,
      filter: props.match.params.filter ?? "collections"
    };
  }

  async getUserProfile() {
    const { firebase, history, match } = this.props;
    const uid = match.params.id;

    // Await for both so that component is only rendered in the end.
    // User info will only be asked for logged users.
    const user = await getUserProfile(uid).catch(err => {
      if (err.response.status === 404) {
        history.push("/not-found"); // redirect to not found page
      }
      throw err;
    });

    if (firebase.isLogged()) {
      const info = await getUserInfo(uid);
      this.setState({ user, info });
    } else {
      this.setState({ user });
    }
  }

  componentDidMount() {
    this.getUserProfile();
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.match.params.id !== prevProps.match.params.id) {
      this.setState({ filter: "photos" }, () => {
        this.getUserProfile();
      });
    }
    if (this.props.match.params.filter !== prevProps.match.params.filter) {
      this.setState({ filter: this.props.match.params.filter ?? "photos" });
    }
  }

  render() {
    const { user, info, filter } = this.state;

    return (
      <ContentArea>
        {user && (
          <>
            <ProfileInfo user={user} info={info} />
            <ProfileTabs user={user} filter={filter} />
          </>
        )}
      </ContentArea>
    );
  }
}

export const Profile = compose<Props, any>(
  withRouter,
  withFirebase
)(ProfileBase);
