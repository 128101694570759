import { BackgroundImage } from "app/shared";
import React from "react";
import { Box, Flex } from "rebass";

interface Props {
  thumbnail?: string;
}

export const ThumbnailCardOne: React.FC<Props> = ({ thumbnail }) => {
  return (
    <Flex justifyContent="space-between" height="100%">
      <Box width="100%">
        <BackgroundImage src={thumbnail} height="100%" />
      </Box>
    </Flex>
  );
};
