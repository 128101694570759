import { ColumnButton, ShowHideInfoButton, Tabs } from "app/shared";
import queryString from "query-string";
import React from "react";
import { Trans } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Box, Flex } from "rebass";
import { compose } from "recompose";
import { useMediaQueryContext } from "styles/context";
import { SearchTabs } from "../models";
import { createSearchUrl } from "../utils";

interface PropsExternal {
  tabs: SearchTabs[];
  activeTabName: string;
  onColumnChange?: (column: number) => void;
  onShowInfoChange?: (showInfo: boolean) => void;
  hideViewButton?: boolean;
  hideShowHideButton?: boolean;
  defaultShowHideInfo?: boolean;
}

interface Props extends RouteComponentProps, PropsExternal {}

const SearchHeaderBase: React.FC<Props> = ({
  history,
  tabs,
  activeTabName,
  onColumnChange,
  onShowInfoChange,
  hideViewButton,
  hideShowHideButton,
  defaultShowHideInfo
}) => {
  const query =
    queryString.parse(history.location.search)?.["q"]?.toString() ?? "";
  const { isSmallMedium } = useMediaQueryContext();

  return (
    <Box height="100%" mb={hideShowHideButton ? 3 : 2}>
      <Flex>
        <Tabs
          wrapTabs={isSmallMedium ? true : false}
          flexBasis={["32%", "", "", "", "10%"]}
          onlyOneFlexBasis={isSmallMedium ? false : true}
          customMx={2}
          px={0}
          customPy="11.2px"
          activeName={activeTabName}
          tabs={tabs.map(tab => ({
            name: tab.name,
            label: (
              <>
                <Trans i18nKey={tab.i18n} />
              </>
            ),
            href: createSearchUrl(tab.view, tab.classNames, tab.filters, query)
          }))}
        />
        {!isSmallMedium && !hideViewButton && (
          <ColumnButton
            customPy="10.2px"
            ml={2}
            onColumnChange={column => onColumnChange?.(column)}
          />
        )}
        {!hideShowHideButton && (
          <ShowHideInfoButton
            customPy="10.2px"
            ml={3}
            defaultInfo={defaultShowHideInfo}
            onShowInfoChange={showInfo => onShowInfoChange?.(showInfo)}
          />
        )}
      </Flex>
    </Box>
  );
};

export const SearchHeader = compose<Props, PropsExternal>(withRouter)(
  SearchHeaderBase
);
