import React from "react";
import Svg, { SvgProps } from "primitives/Svg";

const SvgUser3 = ({ width, height, size, color }: SvgProps) => (
  <Svg
    width={size || width}
    height={size || height}
    viewBox="0 0 24 24"
    color={color}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.428 11.886a5.029 5.029 0 100-10.058 5.029 5.029 0 000 10.058zm0 1.257a6.286 6.286 0 100-12.572 6.286 6.286 0 000 12.572z"
      stroke="#000"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.857 23.429s-.017-.492-.14-1.27C22.247 19.18 20.239 12 11.773 12c-6.548 0-9.402 4.295-10.63 7.619h1.35c.173-.425.375-.86.61-1.295 1.382-2.55 3.892-5.054 8.67-5.054 2.435 0 4.21.647 5.525 1.562 1.327.923 2.254 2.167 2.902 3.457.65 1.293 1.003 2.6 1.193 3.595.018.095.035.186.05.275H5.265v1.27h17.592z"
      stroke="#000"
    />
  </Svg>
);

SvgUser3.defaultProps = {
  width: 24,
  height: 24
};
export default SvgUser3;
