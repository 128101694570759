import React, { ReactNode } from "react";
import { Cluster } from "./models";
import { Marker } from "app/shared";

interface Props {
  lat: number;
  lng: number;
  children?: ReactNode;
  cluster: Cluster;
}

export const ClusterPoint: React.FC<Props> = ({
  children,
  lat,
  lng,
  cluster
}) => {
  const classNames = [...new Set(cluster.markers.map(it => it.className))];
  let markerType = "none";

  // Draw depending on the marker type
  if (classNames.length > 1) {
    markerType = "multiple";
  } else if (classNames?.[0] === "document") {
    markerType = "document";
  } else if (classNames?.[0] === "event") {
    markerType = "event";
  } else if (classNames?.[0] === "user") {
    markerType = "user";
  }

  return (
    <Marker
      lat={lat}
      lng={lng}
      backgroundColor="blue"
      sx={{
        cursor: "pointer",
        borderRadius: markerType === "event" ? "50%" : 0
      }}>
      {children}
    </Marker>
  );
};
