import { SearchProvider, WithSearch } from "@elastic/react-search-ui";
import AppSearchAPIConnector from "@elastic/search-ui-app-search-connector";
import { UsersView, UserView } from "app/account";
import { SearchFilter } from "app/search/models";
import environment from "configurations";
import { UserThumbnail } from "models";
import React, { Component, ReactNode } from "react";
import { Trans } from "react-i18next";
import { CachedPagination, PageEmptyState } from "app/shared";
import { mapUserResults } from "../utils/mapResults";

interface Props {
  filters: SearchFilter[];
  sortField: string;
  sortDirection: "asc" | "desc";
  resultsPerPage: number;
  maxResults?: number;
  view?: (users: UserThumbnail[]) => React.ReactElement;
  scrollListenerEnabled?: boolean;
  noResultsView?: ReactNode;
}

interface WithSearchProps {
  wasSearched: boolean;
  results: any[];
  totalPages: number;
  setCurrent: (page: number) => void;
  current: number;
  isLoading: boolean;
}

/**
 * When maxResults > resultsPerPage results are wrap with Pagination that will search for more results in page bottom scroll.
 */
export class UsersListSearch extends Component<Props> {
  static defaultProps = {
    filters: [],
    resultsPerPage: 20,
    sortField: "created_at",
    sortDirection: "desc"
  };

  /**
   * Use view from props or use the default.
   */
  view(users: UserThumbnail[]) {
    const { view } = this.props;

    if (view) {
      return view(users);
    } else {
      return (
        <UsersView>
          {users.map(user => (
            <UserView key={user.id} user={user} />
          ))}
        </UsersView>
      );
    }
  }

  render() {
    const {
      filters,
      maxResults,
      resultsPerPage,
      scrollListenerEnabled,
      sortField,
      sortDirection,
      noResultsView
    } = this.props;

    return (
      <SearchProvider
        config={{
          apiConnector: new AppSearchAPIConnector(environment.search),
          alwaysSearchOnInitialLoad: true,
          trackUrlState: false,
          initialState: {
            resultsPerPage
          },
          searchQuery: {
            filters: [
              { field: "class_name", values: ["user"], type: "any" }, // this component should only show users
              ...filters
            ],
            sort: { [sortField]: sortDirection },
            result_fields: {
              real_id: { raw: {} },
              class_name: { raw: {} },
              professional_name: { raw: {} },
              country: { raw: {} },
              city: { raw: {} },
              picture: { raw: {} }
            }
          }
        }}>
        <WithSearch
          mapContextToProps={({
            wasSearched,
            results,
            totalPages,
            setCurrent,
            current,
            isLoading
          }: WithSearchProps) => ({
            wasSearched,
            results,
            totalPages,
            setCurrent,
            current,
            isLoading
          })}>
          {({
            wasSearched,
            results,
            totalPages,
            setCurrent,
            current,
            isLoading
          }: WithSearchProps) => {
            if (results.length) {
              const users: UserThumbnail[] = mapUserResults(results);

              // Wrap results around Pagination if maxResults > resultsPerPage
              if (maxResults === undefined || maxResults > resultsPerPage) {
                return (
                  <CachedPagination<UserThumbnail>
                    disabled={isLoading}
                    scrollListenerEnabled={scrollListenerEnabled}
                    firstPageNumber={1}
                    results={users}
                    page={current}
                    totalPages={totalPages}
                    setPage={setCurrent}>
                    {users => this.view(users)}
                  </CachedPagination>
                );
              } else {
                return this.view(users);
              }
            } else {
              return noResultsView ? (
                noResultsView
              ) : (
                <PageEmptyState
                  loading={!wasSearched || isLoading}
                  header={
                    <Trans i18nKey="search.users.usersListSearch.noUsers" />
                  }
                />
              );
            }
          }}
        </WithSearch>
      </SearchProvider>
    );
  }
}
