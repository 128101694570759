import styled from "@emotion/styled";
import { Text, TextProps } from "rebass";

type SpanProps = TextProps;

const Span = styled(Text)<SpanProps>``;

Span.displayName = "Span";
Span.defaultProps = {
  as: "span"
};

export default Span;
