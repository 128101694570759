import { Button, Input, Icon, Label } from "app/shared";
import React from "react";
import { Trans } from "react-i18next";
import { Flex } from "rebass";

interface Props {
  websites: string[];
  handleWebsiteChange: (index: number, website: string) => void;
  addWebsite: () => void;
  removeWebsite: (idx: number) => void;
  disabled?: boolean;
  labelI18n?: string;
}

const WebsiteInputs: React.FC<Props> = ({
  websites,
  handleWebsiteChange,
  addWebsite,
  removeWebsite,
  disabled,
  labelI18n
}) => (
  <>
    {labelI18n && <Label i18n={labelI18n} fontSize={[1, 4]} variant="caps" />}
    {/* Websites list */}
    {websites?.map((website, index) => {
      const websiteId = `websiteId-${index}`,
        websiteKey = `key-${index}`;
      return (
        <Flex flexDirection="row" mt="1" mb="3" key={`container-${websiteKey}`}>
          <Input
            width="100%"
            name={"website"}
            key={websiteKey}
            type="text"
            id={websiteId}
            value={website}
            disabled={disabled}
            placeholder="urlexample.com"
            onChange={e => handleWebsiteChange(index, e.target.value)}
          />
          {index > 0 && (
            <Button
              key={`remove-btn-${websiteKey}`}
              variant="inlineList"
              disabled={disabled}
              onClick={() => removeWebsite(index)}>
              <Icon name="Close" size={24} />
            </Button>
          )}
        </Flex>
      );
    })}
    {/* Button to add new website to the list */}
    <Flex flexDirection="row" justifyContent="left" mt="3">
      <Button onClick={addWebsite} mr={1} mt={[1, 2, 3]} disabled={disabled}>
        <Trans i18nKey="account.editMyInfo.websiteInputs.add" />
      </Button>
    </Flex>
  </>
);

export default WebsiteInputs;
