import { Button } from "app/shared";
import React from "react";
import { Trans } from "react-i18next";
import { Box, BoxProps, Text } from "rebass";

interface Props extends BoxProps {
  i18nKey: string;
  href?: string;
  headerI18nKey?: string;
}

const RedirectButton: React.FC<Props> = ({
  i18nKey,
  href,
  headerI18nKey,
  variant,
  ...rest
}) => (
  <Box width="100%">
    {headerI18nKey && (
      <Box mb={[2, 3]} {...(rest as any)}>
        <Text
          fontSize={[0, "11px"]}
          variant="grotTextCaps"
          sx={{ textAlign: "center" }}>
          <Trans i18nKey={headerI18nKey} />
        </Text>
      </Box>
    )}
    <Button
      width="100%"
      py={[3, "13px"]}
      variant={variant ? variant : undefined}
      href={href}
      sx={{ border: "1px solid #0000FF" }}
      {...(rest as any)}>
      <Text variant="grotTextCaps" fontSize={[1, 2, 3]}>
        <Trans i18nKey={i18nKey} />
      </Text>
    </Button>
  </Box>
);

export default RedirectButton;
