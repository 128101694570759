import React from "react";
import { Box, Text } from "rebass";

interface Props {
  currentIndex: number;
  totalDocuments: number;
}

const ImageCounter: React.FC<Props> = ({ currentIndex, totalDocuments }) => (
  <Box
    backgroundColor="black.0"
    px={3}
    py={2}
    display="grid"
    sx={{
      position: "fixed",
      top: ["50vh", "40vh", "50vh", "63vh"],
      left: ["5%", "5%", "5%", "7%", "13%"],
      zIndex: 4
    }}>
    <Text
      fontSize={[1, 2, 3]}
      color="white.0">{`${currentIndex} / ${totalDocuments}`}</Text>
  </Box>
);

export default ImageCounter;
