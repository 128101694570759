import { DEFAULT_CENTER, MAX_DIAMETER_KM } from "app/shared/maps/models";

export interface SearchFilter {
  field: string;
  values: string[] | number[];
  type: string;
}

interface RawResult {
  raw: any;
}

export interface ResultField {
  [key: string]: RawResult;
}

export interface SearchTabs {
  name: string;
  filters: SearchFilter[];
  i18n: string;
  view: string;
  classNames: string[];
}

export const DocumentTabs: SearchTabs[] = [
  {
    name: "collection",
    filters: [
      { field: "class_name", values: ["collection"], type: "any" },
      { field: "privacy", values: ["public"], type: "any" }
    ],
    i18n: "search.documents.collections",
    view: "collection",
    classNames: ["collection"]
  },
  {
    name: "document",
    filters: [{ field: "class_name", values: ["document"], type: "any" }],
    i18n: "search.documents.photos",
    view: "document",
    classNames: ["document"]
  },
  {
    name: "bucket",
    filters: [
      { field: "class_name", values: ["bucket"], type: "any" },
      { field: "privacy", values: ["public"], type: "any" }
    ],
    i18n: "search.documents.buckets",
    view: "bucket",
    classNames: ["bucket"]
  },
  {
    name: "user",
    filters: [{ field: "class_name", values: ["user"], type: "any" }],
    i18n: "search.documents.frontfilers",
    view: "user",
    classNames: ["user"]
  },
  {
    name: "document-location",
    filters: [
      { field: "class_name", values: ["document"], type: "any" },
      {
        field: "location",
        values: [
          {
            center: `${DEFAULT_CENTER.lat},${DEFAULT_CENTER.lng}`,
            distance: MAX_DIAMETER_KM,
            unit: "km"
          } as any
        ],
        type: "any"
      }
    ],
    i18n: "search.documents.locations",
    view: "document-location",
    classNames: ["document"]
  }
];

export const DEFAULT_RESULTS_PER_PAGE = 40;
export const DEFAULT_NUM_THUMBNAILS = 6;
