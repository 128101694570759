import { Select } from "@rebass/forms";
import { Button, Icon, Input } from "app/shared";
import i18next from "i18next";
import React from "react";
import { Box, Flex, Text } from "rebass";
import { SocialMedias } from "../constants";
import { REGEX_LETTERS_DIGITS_DOTS, UserInfo } from "../models";

interface Props {
  userInfo: UserInfo;
  handleOnSocialMediaChange: (
    index: number,
    type: "select" | "value",
    value: string
  ) => void;
  validateSocialMedia: (
    event: React.KeyboardEvent<HTMLDivElement>,
    regex: RegExp
  ) => void;
  deleteSocialMediaRow: (index: number) => void;
}

const SocialMediaInputs: React.FC<Props> = ({
  userInfo,
  handleOnSocialMediaChange,
  validateSocialMedia,
  deleteSocialMediaRow
}) => (
  <>
    {userInfo.social?.map((socialMedia, index) => {
      const socialMediaId = `socialMediaId-${index}`,
        value = `value-${index}`,
        socialMediaKey = `key-${index}`;

      const current = SocialMedias.find(it => it.name === socialMedia.name);
      const allowedSocialMedias = SocialMedias.filter(
        media =>
          userInfo.social?.find(it => it.name === media.name) === undefined
      );
      const inputDisabled: boolean = socialMedia.name === "none";

      if (current) {
        allowedSocialMedias.push(current);
      }

      return (
        <Flex key={socialMediaKey} pb={[1, 2, 3]} width={1}>
          <Select
            mr={1}
            name={socialMediaId}
            id={socialMediaId}
            value={socialMedia.name}
            onChange={(e: any) =>
              handleOnSocialMediaChange(index, "select", e.target.value)
            }>
            <option value="none">
              {i18next.t("account.editMyInfo.socialProfiles.none")}
            </option>
            {allowedSocialMedias.map(allowedSocialMedia => (
              <option
                key={allowedSocialMedia.name}
                value={allowedSocialMedia.name}>
                {i18next.t(allowedSocialMedia.i18n)}
              </option>
            ))}
          </Select>
          <Flex
            alignItems="center"
            sx={{ borderLeft: 1, borderColor: "grey.0" }}>
            <Flex width={["auto", "1000px"]} alignItems="center">
              <Text
                pl={[1, 2, 3]}
                color="text.muted"
                fontWeight="bold"
                fontSize={[1, 2, 3]}>
                {(() => {
                  return SocialMedias.filter(
                    media => media.name === socialMedia.name
                  )
                    .map(media => media.link)
                    .pop();
                })()}
              </Text>
              <Box
                p={0}
                sx={{
                  flexGrow: 1
                }}>
                <Input
                  name={value}
                  id={value}
                  p={0}
                  type="text"
                  variant="input.noPadding"
                  disabled={inputDisabled}
                  onChange={e =>
                    handleOnSocialMediaChange(index, "value", e.target.value)
                  }
                  onKeyPress={e =>
                    validateSocialMedia(e, REGEX_LETTERS_DIGITS_DOTS)
                  }
                  value={socialMedia.value}
                />
              </Box>
            </Flex>
            {index > 0 && (
              <Button
                bg="fill.0"
                onClick={() => deleteSocialMediaRow(index)}
                display="grid"
                variant="inlineList">
                <Icon name="Close" size={24} />
              </Button>
            )}
          </Flex>
        </Flex>
      );
    })}
  </>
);

export default SocialMediaInputs;
