import React from "react";
import Svg, { SvgProps } from "primitives/Svg";

const SvgEyeOpen = ({ width, height, size, color }: SvgProps) => (
  <Svg
    width={size || width}
    height={size || height}
    viewBox="0 0 23 16"
    color={color}>
    <path d="M11.5 2.25c3.948 0 7.469 2.219 9.187 5.73a10.177 10.177 0 01-9.187 5.728A10.177 10.177 0 012.312 7.98 10.177 10.177 0 0111.5 2.25zm0-2.083C6.292.167 1.844 3.407.042 7.979c1.802 4.573 6.25 7.813 11.458 7.813s9.656-3.24 11.458-7.813C21.156 3.406 16.708.167 11.5.167zm0 5.208a2.605 2.605 0 010 5.208 2.605 2.605 0 010-5.208zm0-2.083a4.695 4.695 0 00-4.688 4.687 4.695 4.695 0 004.688 4.688 4.695 4.695 0 004.687-4.688A4.695 4.695 0 0011.5 3.292z" />
  </Svg>
);

SvgEyeOpen.defaultProps = {
  width: 24,
  height: 24
};
export default SvgEyeOpen;
