import { UserPicture } from "app/account";
import { Button } from "app/shared";
import { Chat } from "messaging/MessageService";
import moment from "moment";
import React from "react";
import i18next from "i18next";
import { Box, Flex, Text } from "rebass";
import { truncateString } from "./utils";

interface Props {
  chats: Chat[];
  setChatSelected(chatSelected: number): void;
  chatSelected: number;
}

export const ChatsList: React.FC<Props> = ({
  chats,
  setChatSelected,
  chatSelected
}) => (
  <Box
    overflowY="auto"
    marginRight={[2, 3, 4]}
    sx={{ border: 1, borderColor: "black.0" }}
    height="60vh"
    width="40%">
    {chats
      .sort((chatA: Chat, chatB: Chat) => {
        const chatAMillis = chatA.messages[
          chatA.messages.length - 1
        ].createdAt.toMillis();
        const chatBMillis = chatB.messages[
          chatB.messages.length - 1
        ].createdAt.toMillis();
        return chatAMillis < chatBMillis ? 1 : -1;
      })
      .map((chat: Chat, index: number) => {
        const lastMessage = chat.messages[chat.messages.length - 1];
        const isIndexSelected = index === chatSelected;
        const bgColor = isIndexSelected ? "brand.0" : "white.0";
        const textColor = isIndexSelected ? "white.0" : "black.0";
        const borderColor = isIndexSelected ? "white.0" : "black.0";

        return (
          <Button
            display="block"
            key={chat.id}
            width="100%"
            variant="transparent"
            mb={[1, 2]}
            sx={{
              borderBottom: 1,
              borderColor: "black.0"
            }}
            onClick={() => setChatSelected(index)}>
            <Flex flexDirection="row">
              <Box
                pr={[1, 2, 3]}
                backgroundColor={
                  isIndexSelected || chat.unread ? "brand.0" : "white.0"
                }
                sx={{
                  borderTop: index !== 0 ? 1 : 0,
                  borderBottom: 1,
                  borderColor: borderColor
                }}
              />
              <Flex
                flexDirection="row"
                justifyContent="space-between"
                pr={[1, 2, 3]}
                pl={1}
                py={1}
                backgroundColor={bgColor}
                color={textColor}
                sx={{
                  flexGrow: 1,
                  borderTop: index !== 0 ? 1 : 0,
                  borderBottom: 1,
                  borderColor: borderColor
                }}>
                <Text textAlign="left" fontSize={[0, 1]} variant="grotTextCaps">
                  {chat.userMetadata.displayName}
                </Text>
                <Text fontSize={[0, 1]} variant="grotTextCaps">
                  {moment(lastMessage.createdAt.toDate()).calendar(undefined, {
                    sameDay: `[${i18next.t(
                      "messages.relativeDay.today"
                    )}], HH:mm`,
                    lastDay: `[${i18next.t(
                      "messages.relativeDay.yesterday"
                    )}], HH:mm`,
                    sameElse: "DD/MM/YYYY, HH:mm"
                  })}
                </Text>
              </Flex>
            </Flex>
            <Flex backgroundColor={bgColor} color={textColor}>
              <Box pr={[1, 2, 3]} />
              <Box pr={[1, 2, 3]} pl={1} py={2}>
                <UserPicture
                  picture={chat.userMetadata.photoUrl ?? undefined}
                  height={["48px", "56px"]}
                />
              </Box>
              <Flex alignItems="center">
                <Text fontSize={[2, 4, 6]} variant="grotText">
                  {truncateString(lastMessage.content, 30)}
                </Text>
              </Flex>
            </Flex>
          </Button>
        );
      })}
  </Box>
);
