/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactNode, useState } from "react";
import GoogleMapReact, {
  ClickEventValue,
  ChangeEventValue
} from "google-map-react";
import environment from "configurations";
import { Coords, DEFAULT_CENTER } from "./models";
import { mapStyles } from "./models/styles";

interface Props {
  children?: ReactNode;
  center?: Coords;
  zoom?: number;
  minZoom?: number;
  maxZoom?: number;
  onClick?: (value: ClickEventValue) => void;
  onChildClick?: (hoverKey: any, childProps: any) => void;
  onChange?: (value: ChangeEventValue) => void;
  onGoogleApiLoaded?: (maps: any) => void; // callback proprietary to google api
}

/**
 * Google maps returns coordinates outside [85, 180] if the noWrap method is not used:
 * https://developers.google.com/maps/documentation/javascript/reference/coordinates
 * Each time there is a change in map center we create a new location inside [85, 180]
 * before passing it to the component consumer.
 */
export const Map: React.FC<Props> = ({
  children,
  center,
  zoom,
  minZoom,
  maxZoom,
  onClick,
  onChildClick,
  onChange,
  onGoogleApiLoaded
}) => {
  const [googleMapsApi, setGoogleMapsApi] = useState<any>(undefined);
  const wrapCoordinate = (coord: Coords): Coords => {
    const newCoord = new googleMapsApi.LatLng(coord.lat, coord.lng, false);
    return {
      lat: newCoord.lat(),
      lng: newCoord.lng()
    };
  };

  return (
    <GoogleMapReact
      bootstrapURLKeys={{
        key: environment.googleMapsKey,
        libraries: ["places"] // load always places library so SearchBox places can be used
      }}
      center={center}
      zoom={zoom}
      options={{
        minZoom,
        maxZoom,
        styles: mapStyles,
        fullscreenControl: false
      }}
      onClick={onClick}
      onChildClick={onChildClick}
      onChange={event => {
        // Create new locations inside [85, 180] coordinates using noWrap=false
        if (googleMapsApi) {
          onChange?.({
            ...event,
            center: wrapCoordinate(event.center),
            bounds: {
              nw: wrapCoordinate(event.bounds.nw),
              se: wrapCoordinate(event.bounds.se),
              sw: wrapCoordinate(event.bounds.sw),
              ne: wrapCoordinate(event.bounds.ne)
            },
            marginBounds: {
              nw: wrapCoordinate(event.marginBounds.nw),
              se: wrapCoordinate(event.marginBounds.se),
              sw: wrapCoordinate(event.marginBounds.sw),
              ne: wrapCoordinate(event.marginBounds.ne)
            }
          });
        } else {
          onChange?.(event);
        }
      }}
      yesIWantToUseGoogleMapApiInternals={true}
      onGoogleApiLoaded={({ maps }) => {
        setGoogleMapsApi(maps);
        onGoogleApiLoaded?.(maps);
      }}>
      {children}
    </GoogleMapReact>
  );
};

Map.defaultProps = {
  center: DEFAULT_CENTER,
  zoom: 3,
  minZoom: 3,
  maxZoom: 15
};
