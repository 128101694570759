import { Firebase } from "authentication/firebase";
import { withAuthorization } from "authentication/session";
import { ContentArea } from "layout";
import moment, { Moment } from "moment";
import React, { Component } from "react";
import { Trans } from "react-i18next";
import { Flex, Heading, Text } from "rebass";
import { compose } from "recompose";
import {
  RelationDocument,
  RelationUser,
  RelationDocumentSale
} from "../models";
import {
  getRelationDocuments,
  getRelationUser,
  getRelationSales,
  getRelationBuys
} from "../services";
import { Point } from "app/shared/graph";
import { HistoryGraph } from "./HistoryGraph";
import { ContentPerformance } from "./ContentPerformance";
import i18next from "i18next";
import { Tabs, PageHeader } from "app/shared";

interface Props {
  firebase: Firebase;
}

interface State {
  from: Moment;
  to: Moment;
  user?: RelationUser;
  sales: Point[];
  buys: Point[];
  documents: RelationDocument[];
  documentsGraphData: Point[][];
}

class InsightBase extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      from: moment().subtract(6, "month"),
      to: moment(),
      user: undefined,
      sales: [],
      buys: [],
      documents: [],
      documentsGraphData: []
    };
  }

  componentDidMount() {
    const { firebase } = this.props;
    const { from, to } = this.state;

    getRelationUser(firebase.getCurrentUser().uid).then(user =>
      this.setState({ user })
    );
    this.getPageData(from, to);
  }

  getPageData(from: Moment, to: Moment) {
    this.setState({ from, to }, () => {
      getRelationSales(from.toISOString(), to.toISOString()).then(sales =>
        this.setState(
          { sales: this.constructGraphPoints(from, to, sales, "price") },
          () => {
            // Waiting for sales to construct documents graph data
            getRelationDocuments(from.toISOString(), to.toISOString()).then(
              documents =>
                this.setState({
                  documents,
                  documentsGraphData: documents.map(document =>
                    this.constructGraphPoints(
                      from,
                      to,
                      sales.filter(it => it.id === document.id),
                      "items"
                    )
                  )
                })
            );
          }
        )
      );
      getRelationBuys(from.toISOString(), to.toISOString()).then(buys =>
        this.setState({
          buys: this.constructGraphPoints(from, to, buys, "price")
        })
      );
    });
  }

  constructGraphPoints(
    d1: Moment,
    d2: Moment,
    data: RelationDocumentSale[],
    dataYLabel: string
  ): Point[] {
    let from = d1.clone();
    const to = d2.clone();
    const dateFormat = "MMM DD, YYYY";

    // Group items by day
    const formatedData: any = {};
    data.forEach(data => {
      const date = moment(data.createdAt).format(dateFormat);

      if (formatedData[date]) {
        formatedData[date].items += 1;
        formatedData[date].price += data.price;
      } else {
        formatedData[date] = {};
        formatedData[date].date = date;
        formatedData[date].items = 1;
        formatedData[date].price = data.price;
        formatedData[date].currency = data.currency;
      }
    });

    // Build histogram
    const histogram = [];

    while (!from.isAfter(to)) {
      histogram.push(from.format(dateFormat));
      from = from.add(1, "day");
    }

    return histogram.map((date, index) => ({
      x: index,
      y: formatedData[date]?.[dataYLabel] ?? 0,
      xAxisLabel: date,
      data: formatedData[date] && { ...formatedData[date] }
    }));
  }

  render() {
    const { documents, user, sales, buys, documentsGraphData } = this.state;

    return (
      <ContentArea>
        <PageHeader
          variant="1"
          title={i18next.t("account.insights.pageTitle")}
          subtitle={i18next.t("account.insights.pageSubtitle")}
          mb={4}
        />
        {user && (
          <>
            <Flex
              flexDirection={["column", "row"]}
              sx={{
                border: "1px solid #000",
                "& > *": {
                  borderRight: [0, "1px solid #000"],
                  borderBottom: ["1px solid #000", 0]
                },
                "& > :last-child": {
                  borderRight: 0,
                  borderBottom: 0
                }
              }}>
              {["profileViews", "documentViews", "likes", "likesGiven"].map(
                title => (
                  <Flex key={title} width={["100%", "calc(100% / 4)"]} p={3}>
                    <Text width="30%" fontSize={0} variant="caps">
                      <Trans i18nKey={`account.insights.${title}`} />
                    </Text>
                    <Text
                      fontSize={7}
                      fontWeight="bold"
                      textAlign="right"
                      sx={{
                        flexGrow: 1
                      }}>
                      {user?.[title] ?? 0}
                    </Text>
                  </Flex>
                )
              )}
            </Flex>
            <Tabs
              mt={3}
              tabs={[
                {
                  name: "3",
                  label: i18next.t("account.insights.last3Months"),
                  onClick: () =>
                    this.getPageData(moment().subtract(3, "month"), moment())
                },
                {
                  name: "6",
                  label: i18next.t("account.insights.last6Months"),
                  onClick: () =>
                    this.getPageData(moment().subtract(6, "month"), moment())
                },
                {
                  name: "12",
                  label: i18next.t("account.insights.last12Months"),
                  onClick: () =>
                    this.getPageData(moment().subtract(12, "month"), moment())
                }
              ]}
            />
            <HistoryGraph
              title={i18next.t("account.insights.sellHistory")}
              value={`${user?.earnings.toFixed(2) ?? 0}€`}
              items={user?.sold ?? 0}
              itemsLabel={i18next.t("account.insights.itemsSold")}
              graphData={sales}
              link="/transactions/sell"
              my={3}
            />
            <HistoryGraph
              title={i18next.t("account.insights.buyHistory")}
              value={`${user?.spent.toFixed(2) ?? 0}€`}
              items={user?.bought ?? 0}
              itemsLabel={i18next.t("account.insights.itemsBought")}
              graphData={buys}
              link="/transactions/buy"
            />
            <Heading
              variant="caps"
              p={2}
              mt={3}
              sx={{
                border: "1px solid #000"
              }}>
              <Trans i18nKey="account.insights.contentPerformance.label" />
            </Heading>
            {documents.length > 0 ? (
              documents.map((document, index) => (
                <ContentPerformance
                  key={document.id}
                  document={document}
                  documentsList={documents}
                  graphData={documentsGraphData?.[index] ?? []}
                />
              ))
            ) : (
              <Text
                p={2}
                sx={{
                  border: "1px solid #000"
                }}>
                <Trans i18nKey="account.insights.contentPerformance.noResults" />
              </Text>
            )}
          </>
        )}
      </ContentArea>
    );
  }
}

export const Insights = compose<Props, any>(withAuthorization())(InsightBase);
