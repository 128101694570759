import React from "react";
import i18next from "i18next";
import { Popup } from "app/shared";
import { Trans } from "react-i18next";
import { Text } from "rebass";
import { compose } from "recompose";
import { RouteComponentProps, withRouter } from "react-router-dom";

interface PropsExternal {
  trigger: any;
  status: "approved" | "rejected";
}

interface Props extends PropsExternal, RouteComponentProps {}

const PortfolioStatusPopupBase: React.FC<Props> = ({
  trigger,
  status,
  history
}) => (
  <Popup
    trigger={trigger}
    heading={i18next.t("account.popups.portfolioStatusPopup.heading")}
    cancel={close => close()}
    submit={close => {
      if (status === "approved") {
        history.push("/submit-content");
      }
      close();
    }}
    submitText={i18next.t("account.popups.portfolioStatusPopup.submit")}>
    <>
      <Text textAlign="center" variant="grotTextCaps" fontSize={[6, 7, 8]}>
        <Trans
          i18nKey={`account.popups.portfolioStatusPopup.${status}Header`}
        />
      </Text>
      <Text textAlign="center" variant="grotText">
        <Trans
          i18nKey={`account.popups.portfolioStatusPopup.${status}Description`}
        />
      </Text>
    </>
  </Popup>
);

export const PortfolioStatusPopup = compose<Props, PropsExternal>(withRouter)(
  PortfolioStatusPopupBase
);
