import React from "react";
import Svg, { SvgProps } from "primitives/Svg";

const SvgFup = ({ width, height, size, color }: SvgProps) => (
  <Svg
    width={size || width}
    height={size || height}
    viewBox="0 0 37 14"
    color={color}>
    <path d="M19.266 1.085v12.202c0 .016.008.032.012.044.008.02.008.04.02.06.008.02.02.035.032.051.012.016.023.036.04.048.015.016.031.024.047.036a.355.355 0 00.052.032c.02.008.04.012.06.016.016.004.032.012.048.016h16.341a.35.35 0 00.312-.347V10.5a.35.35 0 00-.312-.346H29.468V1.112v-.035a.347.347 0 00-.347-.311h-2.746a.35.35 0 00-.348.31v9.078h-3.32V1.112v-.035a.344.344 0 00-.348-.311h-2.746a.35.35 0 00-.347.31v.009z" />
    <path d="M.29 1.085v12.202c0 .016.007.032.011.044.004.02.008.04.016.06.008.02.02.035.032.051.012.016.024.036.04.048.016.016.032.024.048.036.02.012.036.024.056.032.02.008.04.012.06.016.015.004.031.012.047.016H16.946a.35.35 0 00.311-.347V10.5a.35.35 0 00-.311-.346h-6.451V1.112v-.035a.347.347 0 00-.347-.311H7.402a.35.35 0 00-.347.31v9.078H3.734V1.112v-.035a.344.344 0 00-.348-.311H.64a.35.35 0 00-.347.31l-.004.009z" />
  </Svg>
);

SvgFup.defaultProps = {
  width: 24,
  height: 24
};
export default SvgFup;
