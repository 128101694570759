import styled from "@emotion/styled";
import { Flex } from "rebass";
import {
  grid,
  GridProps as GridPropsBase,
  FlexboxProps,
  SpaceProps
} from "styled-system";

export interface GridProps extends GridPropsBase, FlexboxProps, SpaceProps {}

const Grid = styled(Flex)<GridProps>`
  display: grid;
  ${grid}
`;

Grid.displayName = "Grid";

Grid.defaultProps = {};

export default Grid;
