import React from "react";
import { Helmet } from "react-helmet";
import { JsonObject } from "models";

type OtherMetaProperties = JsonObject<string>;

interface Props {
  title?: string;
  description?: string;
  image?: string;
  url?: string;
  canonical?: string;
  type?: string;
  otherMetaProperties?: OtherMetaProperties;
  prev?: string;
  next?: string;
}

export const Metadata: React.FC<Props> = ({
  title,
  description,
  image,
  url,
  canonical,
  type,
  otherMetaProperties,
  prev,
  next
}) => {
  const maxDescriptionLength = 250;
  const titleParsed =
    title &&
    `Frontfiles${title.length && title !== "Frontfiles" ? ` - ${title}` : ""}`;
  const descriptionParsed =
    description && description.length > maxDescriptionLength
      ? `${description.substr(0, maxDescriptionLength)}...`
      : description;

  return (
    <Helmet>
      {titleParsed && <title>{titleParsed}</title>}
      {descriptionParsed && (
        <meta name="description" content={descriptionParsed} />
      )}
      {titleParsed && <meta property="og:title" content={titleParsed} />}
      {descriptionParsed && (
        <meta property="og:description" content={descriptionParsed} />
      )}
      {image && <meta property="og:image" content={image} />}
      {url && <meta property="og:url" content={url} />}
      {canonical && <link rel="canonical" href={canonical} />}
      {type && <meta property="og:type" content={type} />}
      {otherMetaProperties &&
        Object.keys(otherMetaProperties).length &&
        Object.keys(otherMetaProperties).map((name, key) => (
          <meta property={name} content={otherMetaProperties[name]} key={key} />
        ))}
      {prev && <link rel="prev" href={prev} />}
      {next && <link rel="next" href={next} />}
    </Helmet>
  );
};
