import React from "react";
import { BoxProps, Flex } from "rebass";
import { Image } from "../image";
import Arrow from "./Arrow";
import ImageCounter from "./ImageCounter";
import { useKeyPress } from "./utils";

interface Props extends BoxProps {
  url: string;
  currentIndex: number;
  lastIndex: number;
  totalDocuments: number;
  previousImage: () => void;
  nextImage: () => void;
  disabled?: boolean;
}

const ImageSlide: React.FC<Props> = ({
  url,
  previousImage,
  nextImage,
  currentIndex,
  lastIndex,
  totalDocuments,
  disabled,
  ...rest
}) => {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const noop = () => {};

  useKeyPress(37, disabled || currentIndex <= 0 ? noop : previousImage);
  useKeyPress(39, disabled || currentIndex >= lastIndex ? noop : nextImage);

  return (
    <Flex backgroundColor="black.0" justifyContent="center" {...(rest as any)}>
      <ImageCounter
        currentIndex={currentIndex + 1}
        totalDocuments={totalDocuments}
      />
      <Arrow
        disabled={disabled || currentIndex === 0}
        direction="left"
        onClick={previousImage}
      />
      <Image src={url} sx={{ objectFit: "contain" }} />
      <Arrow
        disabled={disabled || currentIndex === lastIndex}
        direction="right"
        onClick={nextImage}
      />
    </Flex>
  );
};

export default ImageSlide;
