import { CollectionAddPhotoButton } from "app/collections";
import { DocumentsView, DocumentView } from "app/documents";
import { DocumentAddPhotoButton } from "app/documents/DocumentAddPhotoButton";
import { DocumentDeletePhotosButton } from "app/documents/DocumentDeletePhotosButton";
import { PhotosListSearch } from "app/search/documents/PhotosListSearch";
import { getRaw } from "app/search/utils";
import { BulkActionLayout } from "app/shared";
import MasonryLayout from "app/shared/masonryLayout";
import ConditionalWrapper from "app/shared/wrapper/ConditionalWrapper";
import { AuthUserContext } from "authentication/session";
import { checkSameUser } from "authentication/utils";
import { ColumnItem, DocumentThumbnail } from "models";
import React, { useEffect, useState } from "react";
import { BucketAddItemsButton } from "app/buckets";

interface Props {
  uid: string;
  resultsPerPage?: number;
  column?: number;
  showInfo?: boolean;
}

export const UserProfilePhotos: React.FC<Props> = ({
  uid,
  resultsPerPage,
  column,
  showInfo
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [ids, setIds] = useState<any>([]);
  const [documentHeight, setDocumentHeight] = useState<ColumnItem>({
    isCollapsed: "",
    notCollapsed: "",
    titleFontSize: undefined,
    textBottomFontSize: undefined,
    ownerNameFontSize: undefined
  });

  useEffect(() => {
    if (column) {
      if (column === 1) {
        setDocumentHeight({
          isCollapsed: "",
          notCollapsed: "",
          titleFontSize: undefined,
          textBottomFontSize: undefined,
          ownerNameFontSize: undefined
        });
      } else if (column === 2) {
        setDocumentHeight({
          isCollapsed: "",
          notCollapsed: "",
          titleFontSize: undefined,
          textBottomFontSize: undefined,
          ownerNameFontSize: undefined
        });
      } else if (column === 4) {
        setDocumentHeight({
          isCollapsed: "",
          notCollapsed: "",
          titleFontSize: [2, 3, 3],
          textBottomFontSize: 1,
          ownerNameFontSize: 1
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [column]);

  return (
    <AuthUserContext.Consumer>
      {({ authUser }) => (
        <PhotosListSearch<DocumentThumbnail>
          key={uid} // Using key={uid} to force refresh when user changes
          filters={[{ field: "uid", values: [uid], type: "any" }]}
          mapResults={(results: any[]) =>
            results.map(result => ({
              id: parseInt(getRaw(result, "real_id") ?? "0", 10),
              thumbnail: getRaw(result, "thumbnail") ?? "",
              height: parseInt(getRaw(result, "height") ?? "1", 10),
              width: parseInt(getRaw(result, "width") ?? "1", 10),
              city: getRaw(result, "city") ?? "",
              country: getRaw(result, "country") ?? "",
              takenAt: getRaw(result, "taken_at") ?? "",
              owner: {
                id: getRaw(result, "uid") ?? "",
                name: getRaw(result, "owner") ?? ""
              },
              title: ((getRaw(
                result,
                "translations"
              ) as unknown) as string[])?.[0]?.split("=")?.[1]
            }))
          }
          view={documents => (
            <ConditionalWrapper
              wrapper={() => (
                <BulkActionLayout
                  popUpOpen={isOpen}
                  selectedIds={ids}
                  onSelectedIdsChange={ids => setIds(ids)}
                  defaultSelectActions={true}
                  elementOptions={[]}
                  actions={[
                    (ids: any[]) =>
                      authUser &&
                      checkSameUser(uid, authUser) && (
                        <CollectionAddPhotoButton
                          open={isOpen}
                          onOpen={() => setIsOpen(true)}
                          onClose={() => setIsOpen(false)}
                          key="collection-add-photo"
                          documentIds={ids}
                          forcePageReload={true}
                        />
                      ),
                    (ids: any[]) =>
                      authUser && (
                        <BucketAddItemsButton
                          key="bucket-add-photo"
                          bucketItemIds={ids.map(id => ({
                            type: "document",
                            id: parseInt(id, 10)
                          }))}
                          forcePageReload={true}
                        />
                      ),
                    (ids: any[]) =>
                      authUser &&
                      checkSameUser(uid, authUser) && (
                        <DocumentDeletePhotosButton
                          key="delete-photo"
                          documentIds={ids}
                        />
                      ),
                    () =>
                      authUser &&
                      checkSameUser(uid, authUser) && (
                        <DocumentAddPhotoButton key="add-new-photo" />
                      )
                  ]}
                  nodes={documents.map(document => (
                    <DocumentView
                      showUserInfo={false}
                      showHoverInfo={true}
                      whiteMode={true}
                      key={document.id}
                      document={document}
                      documentsList={documents}
                      titleFontSize={documentHeight.titleFontSize}
                      textBottomFontSize={documentHeight.textBottomFontSize}
                      ownerNameFontSize={documentHeight.ownerNameFontSize}
                      customMinHeight={column === 4 ? "100px" : undefined}
                      largeThumbnail={column === 1 ? true : undefined}
                      showInfoBottom={showInfo}
                      showManageIcons={showInfo}
                      width="100%"
                      customPb={!showInfo ? 0 : undefined}
                    />
                  ))}
                  view={children => (
                    <MasonryLayout
                      columns={column ?? 2}
                      gap={4}
                      items={children}
                      itemDimensions={documents.map(document => ({
                        height: document.height ?? 0,
                        width: document.width ?? 0
                      }))}
                    />
                  )}
                />
              )}
              condition={authUser ? true : false}>
              <DocumentsView
                columnCount={column}
                documents={documents}
                showUserInfo={false}
                showHoverInfo={true}
                titleFontSize={documentHeight.titleFontSize}
                textBottomFontSize={documentHeight.textBottomFontSize}
                ownerNameFontSize={documentHeight.ownerNameFontSize}
                customMinHeight={column === 4 ? "100px" : undefined}
                largeThumbnail={column === 1 ? true : undefined}
                showInfoBottom={showInfo}
                showManageIcons={showInfo}
              />
            </ConditionalWrapper>
          )}
        />
      )}
    </AuthUserContext.Consumer>
  );
};

UserProfilePhotos.defaultProps = {
  resultsPerPage: 20
};
