import React, { useState } from "react";
import { Coords } from "./models";
import { Marker, Popup, Map } from "app/shared";
import i18next from "i18next";
import { Box } from "rebass";

interface Props {
  center: Coords;
  trigger: JSX.Element;
  defaultZoom?: number;
  disabled?: boolean;
}

export const LocationViewPopup: React.FC<Props> = ({
  center,
  trigger,
  defaultZoom,
  disabled
}) => {
  const [isOpen, setOpen] = useState(false);

  return (
    <Popup
      trigger={trigger}
      heading={i18next.t("shared.locationViewPopup.location")}
      disabled={disabled}
      onOpen={() => setOpen(true)}
      hideActionButtons={true}
      contentPadding={0}>
      <Box height="500px">
        {isOpen && (
          <Map center={center} zoom={defaultZoom}>
            <Marker lat={center.lat} lng={center.lng}>
              X
            </Marker>
          </Map>
        )}
      </Box>
    </Popup>
  );
};

LocationViewPopup.defaultProps = {
  defaultZoom: 10
};
