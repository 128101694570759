import React from "react";
import Svg, { SvgProps } from "primitives/Svg";

const SvgBurgerMenu = ({ width, height, size, color }: SvgProps) => (
  <Svg
    width={size || width}
    height={size || height}
    viewBox="0 0 24 24"
    color={color}>
    <path d="M4 6h16M4 11.714h16M4 17.43h16" stroke="#000" />
  </Svg>
);

SvgBurgerMenu.defaultProps = {
  width: 24,
  height: 24
};
export default SvgBurgerMenu;
