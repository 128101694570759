import axios from "network";
import { RequestToken, AccessToken } from "../models";

/**
 * Flickr
 */
export const getRequestToken = async (): Promise<RequestToken> => {
  return axios
    .get(`/oauth/flickr/request-token`)
    .then(response => response.data);
};

export const getAccessToken = async (
  token: AccessToken,
  verifier: string
): Promise<AccessToken> => {
  return axios
    .post(`/oauth/flickr/access-token`, { token, verifier })
    .then(response => {
      return {
        token: response.data.token,
        tokenSecret: response.data.tokenSecret
      };
    });
};

export const executeRequest = async (
  token: AccessToken,
  method: string,
  parameters: any
) => {
  return axios.post(`/oauth/flickr/execute-request`, {
    token,
    method,
    parameters
  });
};
