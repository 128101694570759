import React from "react";
import { ToastContainer as ReactToastContainer, toast } from "react-toastify";
import { ClassNames } from "@emotion/core";
import { Flex, Text } from "rebass";
import { Button } from "app/shared";
import { Trans } from "react-i18next";
import environment from "configurations";

interface I18nResource {
  key: string;
  options?: Record<string, unknown>;
}

interface ButtonProps {
  title: string | I18nResource;
  href?: string;
  onClick?: () => void;
}

interface ToastComponentProps {
  title?: string | I18nResource;
  button?: ButtonProps;
  color?: string;
  backgroundColor?: string;
  dismissible?: boolean;
  border?: number;
}

export const ToastComponent: React.FC<ToastComponentProps> = ({
  title,
  button,
  color,
  backgroundColor,
  border
}) => (
  <Flex
    justifyContent="space-between"
    alignItems="center"
    width={["100%", "500px"]}
    height="100%"
    px={3}
    py={2}
    backgroundColor={backgroundColor}
    sx={{
      border,
      borderColor: "black.0"
    }}>
    <Text fontSize={1} color={color} variant="caps">
      {typeof title === "object" ? (
        <Trans i18nKey={title.key} tOptions={title.options} />
      ) : (
        title
      )}
    </Text>
    {button?.href && (
      <Button variant="link.button.black" href={button.href}>
        <Text fontSize={1} color={color} variant="caps">
          {typeof button.title === "object" ? (
            <Trans i18nKey={button.title.key} tOptions={button.title.options} />
          ) : (
            button.title
          )}
        </Text>
      </Button>
    )}
    {button?.onClick && (
      <Button variant="black" onClick={button.onClick}>
        <Text fontSize={1} color={color} variant="caps">
          {typeof button.title === "object" ? (
            <Trans i18nKey={button.title.key} tOptions={button.title.options} />
          ) : (
            button.title
          )}
        </Text>
      </Button>
    )}
  </Flex>
);

ToastComponent.defaultProps = {
  backgroundColor: "blue",
  color: "white",
  border: 0
};

/**
 * This component presents the toasts and should be used somewhere like <App />
 * Options: https://github.com/fkhadra/react-toastify
 */
export const ToastContainer: React.FC = () => (
  <ClassNames>
    {({ css, cx }) => (
      <ReactToastContainer
        closeButton={false}
        hideProgressBar={true}
        pauseOnHover={true}
        autoClose={environment.toast.autoCloseTime}
        position="bottom-left"
        toastClassName={cx(
          css`
            padding: 0;
            min-height: 50px;
          `
        )}
        className={cx(
          css`
            width: inherit;
          `
        )}
        bodyClassName={cx(
          css`
            margin: 0;
          `
        )}
      />
    )}
  </ClassNames>
);

/**
 * Create toast with custom component.
 * Usage with Toast.success({ title: { key: "account.notifications.noNotifications" } })
 */
export class Toast {
  constructor(options: ToastComponentProps) {
    return toast(<ToastComponent {...options} />, {
      autoClose: options.dismissible ? undefined : false // with this set to false user has to manually click it to close
    });
  }

  static success(options: ToastComponentProps) {
    return new Toast({
      ...options,
      backgroundColor: "blue",
      dismissible: true
    });
  }

  static error(options: ToastComponentProps) {
    return new Toast({
      ...options,

      backgroundColor: "white",
      color: "red",
      border: 1,
      dismissible: true
    });
  }

  static apiError(error: any) {
    console.log("error", error);
    const errorMessage =
      error.response?.data?.message ?? error.message ?? "API Error";
    return Toast.error({ title: errorMessage });
  }
}
