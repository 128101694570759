import React from "react";
import Svg, { SvgProps } from "primitives/Svg";

const SvgDownload = ({ width, height, size, color }: SvgProps) => (
  <Svg
    width={size || width}
    height={size || height}
    viewBox="0 0 19 19"
    color={color}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.565 13.008a1.036 1.036 0 001.482-.006l4.892-4.98c.795-.797-.397-1.991-1.192-1.195l-3.318 3.329c-.164.163-.296.106-.296-.126V.846a.843.843 0 10-1.686 0v9.184c0 .233-.133.29-.296.126L4.833 6.827c-.795-.796-1.987.398-1.192 1.194l4.924 4.987z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.889 16.891c0 .233-.192.421-.422.421H2.11a.423.423 0 01-.422-.42v-2.957a.844.844 0 10-1.689 0v3.801a1.27 1.27 0 001.27 1.265h16.037c.7 0 1.27-.564 1.27-1.265v-3.802a.844.844 0 10-1.688 0v2.957z"
    />
  </Svg>
);

SvgDownload.defaultProps = {
  width: 24,
  height: 24
};
export default SvgDownload;
