import React, { useEffect, useState } from "react";
import { Box, BoxProps } from "rebass";
import { DocumentThumbnail } from "models";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
// import { DocumentView } from "app/documents";
// import MasonryLayout from "app/shared/masonryLayout";
import { Image } from "app/shared";

interface Props extends BoxProps {
  documents: DocumentThumbnail[];
}

export const CollectionDragPhotos: React.FC<Props> = ({
  documents,
  ...rest
}) => {
  const [documentsList, setDocumentsList] = useState(documents);
  // const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    setDocumentsList(documents);
  }, [documents]); //if more documents are loaded update documentList

  const SortableListItem = SortableElement(({ item }: any) => {
    return (
      // <MasonryLayout
      //   columns={1}
      //   gap={1}
      //   items={[

      <Image
        src={item.thumbnail}
        onMouseEnter={() => (document.body.style.cursor = "grab")}
        onMouseLeave={() => (document.body.style.cursor = "default")}
      />

      // <DocumentView
      // 	key={item.id}
      // 	document={item}
      // 	documentsList={documents}
      // 	showInfoBottom={false}
      // 	hideLoading={true}
      // 	pr={2}
      //
      // />

      //   ]}
      //   itemDimensions={[
      //     {
      //       height: item.height ? (item.height as number) : 0,
      //       width: item.width ? (item.width as number) : 0
      //     }
      //   ]}
      // />
    );
  });

  const SortableList = SortableContainer(({ items }: any) => {
    return (
      // <MasonryLayout
      // 	columns={3}
      // 	gap={1}
      // 	items={items.map(((item: any, index: number) =>
      // 	(
      // 		<SortableListItem disabled={disabled}  axis="xy" key={index} index={index} item={item} />
      // 	)
      // 	))}
      // 	itemDimensions={documents.map(document => ({
      // 		height: document.height ?? 0,
      // 		width: document.width ?? 0
      // 	}))} />

      <Box sx={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr" }}>
        {items.map((item: any, index: number) => {
          return (
            <SortableListItem
              axis="xy"
              key={item.id}
              index={index}
              item={item}
            />
          );
        })}
      </Box>
    );
  });

  const onSortEnd = ({ oldIndex, newIndex }: any) => {
    console.log(oldIndex, newIndex);
    setDocumentsList(arrayMoveImmutable(documentsList, oldIndex, newIndex));
  };

  return (
    <Box {...(rest as any)}>
      <SortableList axis={"xy"} items={documentsList} onSortEnd={onSortEnd} />
    </Box>
  );
};
